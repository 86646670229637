import { Action } from "../../../../core/interfaces";
import { SurveyAddResultActions } from "../actions";
import { AddResultState } from "../interfaces";

export const surveyAddResultReducer = (state: AddResultState, action: Action): AddResultState => {
    const { type, payload } = action;
    switch (type) {
        case SurveyAddResultActions.SET_SURVEY_SELECTED:
            return {
                ...state,
                surveySelected: payload.surveySelected
            };
        case SurveyAddResultActions.SET_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case SurveyAddResultActions.SET_OPTIMIZED:
            return {
                ...state,
                canBeOptimized: payload.canBeOptimized
            };
        case SurveyAddResultActions.SET_LOAD_SURVEYLIST:
            return {
                ...state,
                surveyList: payload.surveyList
            };
        default:
            return {
                ...state
            };
    }
};

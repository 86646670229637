import React, { ReactElement, PropsWithChildren } from "react";

import { ReportsHeader } from "./ReportsHeader";
import { ReportsSubheader } from "./ReportsSubheader";
import { makeStyles } from "@mui/styles";
import EmptyPage from "components/shared/EmptyPage";
import NoSurveySelected from "assets/images/emptyPages/ReportingEmptyState.svg";
import { useLang } from "core/hooks";
import { Filter } from "components/filters/interfaces";
import { ReportRoutes } from "routes/enums";
import { useLocation } from "react-router-dom";
import { TabOptions } from "components/reports/comment/Comment";
import { HrisFieldInfo } from "managerPortal/interfaces";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    header: {
        height: 73,
        boxSizing: "border-box"
    },
    subheader: {
        height: 50
    },
    content: ({ hasFilterApplied }: { hasFilterApplied: boolean }) => ({
        height: hasFilterApplied ? "calc(100% - 123px)" : "calc(100% - 73px)"
    }),
    emptyPage: {
        gridArea: "responseContent",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    }
}));

export type MapSurveyToAutocompleteOption = {
    id: number;
    label: string;
};

type Props = {
    mapSurveys: MapSurveyToAutocompleteOption[];
    selectedSurvey: MapSurveyToAutocompleteOption;
    exportDisabled: boolean;
    filterDisabled: boolean;
    selectDiabled?: boolean;
    exportDataTestId: string;
    filter: Filter;
    isEmptyPage: boolean;
    extraAction?: ReactElement;
    onDeleteFilterItem: (filter: Filter) => void;
    handleSurveySelected: (id: number) => void;
    handleStartExport: (isBulkReport: boolean) => void;
    handleOpenFilter: () => void;
    startSnapshotExcelExport?: () => void;
    startCommentExcelExport?: () => void;
    commentTabValue?: TabOptions;
    contactFields: HrisFieldInfo[];
};

export const ReportsLayout = (props: PropsWithChildren<Props>): ReactElement => {
    const location = useLocation();

    const hasFilterApplied =
        props.filter.items.length > 0 &&
        // TODO: This is a workround as snapshot and comment have some extra features, should be fixed to accept the extra features
        !location.pathname.includes(ReportRoutes.snapshot) &&
        !location.pathname.includes(ReportRoutes.userDashboard) &&
        !location.pathname.includes(ReportRoutes.comment);

    const classes = useStyles({ hasFilterApplied });
    const { lang } = useLang();

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <ReportsHeader
                    mapSurveys={props.mapSurveys}
                    selectedSurvey={props.selectedSurvey}
                    handleSurveySelected={props.handleSurveySelected}
                    handleStartExport={props.handleStartExport}
                    handleOpenFilter={props.handleOpenFilter}
                    exportDisabled={props.exportDisabled}
                    filterDisabled={props.filterDisabled}
                    selectDisabled={props.selectDiabled ?? false}
                    filter={props.filter}
                    extraAction={props.extraAction}
                    startSnapshotExcelExport={props.startSnapshotExcelExport}
                    startCommentExcelExport={props.startCommentExcelExport}
                    commentTabValue={props.commentTabValue}
                    contactFields={props.contactFields}
                />
            </div>
            {hasFilterApplied && (
                <div className={classes.subheader}>
                    <ReportsSubheader filter={props.filter} onDeleteFilterItem={props.onDeleteFilterItem} />
                </div>
            )}
            {props.isEmptyPage && (
                <div className={classes.emptyPage}>
                    <EmptyPage
                        image={NoSurveySelected}
                        message={lang.noSurveyInformationSelected}
                        subMessage={lang.responseRateEmptyMessage}
                    />
                </div>
            )}
            {!props.isEmptyPage && <div className={classes.content}>{props.children}</div>}
        </div>
    );
};

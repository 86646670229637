import React, { useState, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { Redirect } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Typography } from "lib/typography";
import { ReactComponent as ErrorSVG } from "../../assets/images/error/500Error.svg";
import { textColorGrey } from "core/styles/TextPalette";
import { useLang } from "core/hooks";
import { userSettingMutation } from "api/mutations";
import { initUserSettings } from "components/admin/users/initialVariables";
import { Button } from "lib/button";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 40
    },
    header1: {
        marginBottom: 32,
        textAlign: "center"
    },
    header3: {
        color: textColorGrey,
        textAlign: "center",
        margin: 4
    },
    actionContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 28,
        marginBottom: 60
    }
}));

const Error500 = (): ReactElement => {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);
    const { user, setUser } = useUser();
    const { lang } = useLang();
    const [updateUserSetting] = useMutation(userSettingMutation);
    const redirectToHome = (): void => {
        setRedirect(!redirect);
    };

    const reload = (): void => {
        //reload cleared out user setting so user won't stuck on an error page
        const settings = {
            ...initUserSettings,
            languageCode: user.settings.languageCode,
            selectedOrganization: user.settings.selectedOrganization
        };
        updateUserSetting({
            variables: { settings: JSON.stringify(settings) }
        });
        setUser({
            settings
        });
        window.location.reload();
    };

    return redirect ? (
        <Redirect to={{ pathname: "/" }} />
    ) : (
        <div data-testid="error500" className={classes.container}>
            <Typography className={classes.header1} variant="h1">
                {lang.internalServerError}
            </Typography>
            <Typography className={classes.header3} variant="h6">
                {lang.somethingWentWrong}
            </Typography>
            <Typography className={classes.header3} variant="h6">
                {lang.workingToFix}
            </Typography>
            <div className={classes.actionContainer}>
                <Button data-testid="btn-refreshPage" onClick={reload}>
                    {lang.refreshPage}
                </Button>
                <Button data-testid="btn-go-homepage" onClick={redirectToHome}>
                    {lang.goHomepage}
                </Button>
            </div>
            <ErrorSVG />
        </div>
    );
};

export default Error500;

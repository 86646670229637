import { useEffect, useCallback, useRef, Dispatch } from "react";
import { Column } from "../interfaces";

export const useColumnWidth = (
    node: HTMLDivElement | null,
    preState: Column[],
    index: number,
    setColumns: Dispatch<React.SetStateAction<Column[]>>
): void => {
    const widthRef = useRef<number>(100);
    const cordRef = useRef<{ startPoint: number; updatePoint: number }>({ startPoint: 0, updatePoint: 0 });

    const setWidth = (w: number): void => {
        widthRef.current = w;
    };

    const setCord = (cord: { startPoint: number; updatePoint: number }): void => {
        cordRef.current = cord;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnsResize = (): Column[] => {
        const { startPoint, updatePoint }: { startPoint: number; updatePoint: number } = cordRef.current;
        const widthUpdate: number =
            updatePoint > startPoint
                ? updatePoint - startPoint + widthRef.current
                : widthRef.current - (startPoint - updatePoint);
        const updateColumns = [...preState];
        updateColumns[index].maxWidth = widthUpdate;
        updateColumns[index].width = widthUpdate;
        return updateColumns;
    };

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            const cordX = e.pageX < window.innerWidth ? e.pageX : window.innerWidth;
            const cord: { startPoint: number; updatePoint: number } = { ...cordRef.current, updatePoint: cordX };
            setCord(cord);
            setColumns(handleColumnsResize());
            document.addEventListener("mouseup", (): void =>
                document.removeEventListener("mousemove", handleMouseMove)
            );
        },
        [setColumns, handleColumnsResize]
    );
    const handleMouseUp = useCallback(() => {
        document.removeEventListener("mousemove", handleMouseMove);
    }, [handleMouseMove]);

    const handleMouseDown = useCallback(
        (e: MouseEvent) => {
            document.addEventListener("mousemove", handleMouseMove);
            const cord = { startPoint: e.pageX, updatePoint: 0 };
            setCord(cord);
        },
        [handleMouseMove]
    );

    useEffect(() => {
        if (node) {
            node.addEventListener("mousedown", handleMouseDown);
            if (node.parentNode) {
                //cast node type
                const par = node.parentNode as HTMLDivElement;
                //set state
                setWidth(par.offsetWidth);
            }
        }

        return (): void => {
            if (node) {
                node.removeEventListener("mousedown", handleMouseDown);
            }
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseUp, handleMouseDown, node]);
};

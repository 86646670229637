import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { Popover } from "lib/popover";

import { Checkbox } from "lib/checkbox";
import { useLang } from "core/hooks";
import { PreSelection } from "./Insight";
import { Button } from "lib/button";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    popoverContainer: {
        width: 380,
        height: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        boxSizing: "border-box"
    },
    popover_row: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    popover_row_action: {
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingBottom: "4px",
        paddingTop: "6px"
    }
}));

type Props = {
    comparisonAnchorEl: Element | null;
    showPP: boolean;
    handleCloseComparisonPopover: () => void;
    preSelection: PreSelection;
    setShowOrgOverall: () => void;
    toggleCustomizedPP: () => void;
    handleApplyComparison: () => void;
    disableRule: boolean;
    enableComparison: boolean;
};

const InsightComparisonPopover = (props: Props): ReactElement => {
    const classes = useStyles({ showHidePPInComparsion: props.showPP });
    const { lang } = useLang();

    return (
        <Popover
            open={props.comparisonAnchorEl !== null}
            onClose={props.handleCloseComparisonPopover}
            anchorEl={props.comparisonAnchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            onKeyUp={e => {
                if (e.key === "Enter" && props.enableComparison) {
                    props.handleApplyComparison();
                }
            }}
        >
            <div className={classes.popoverContainer}>
                {props.showPP && (
                    <div className={classes.popover_row}>
                        <Checkbox
                            checked={!props.preSelection.customizedShowPP}
                            onChange={props.toggleCustomizedPP}
                            data-testid="insight-org-hide-pp-checkbox"
                            label={lang.hidePPData}
                        />
                    </div>
                )}
                <div className={classes.popover_row_action}>
                    <Button
                        variant="text"
                        onClick={props.handleApplyComparison}
                        data-testid="btn-insight-apply-comparison"
                        disabled={props.disableRule}
                    >
                        {lang.apply}
                    </Button>
                </div>
            </div>
        </Popover>
    );
};

export default InsightComparisonPopover;

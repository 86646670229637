import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { theme } from "lib/theme";

interface Props {
    content: string[];
    subtitle: string;
}

const useStyles = makeStyles(() => ({
    container: {
        height: "49px",
        padding: theme.spacing(2),
        paddingBottom: 0
    },
    content: {
        lineHeight: "40px"
    }
}));

const CustomTypography = (props: Props): JSX.Element => {
    const { content, subtitle } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="subtitle2">{subtitle}</Typography>
            {content.map((c, index) => (
                <Typography variant="body1" key={index} className={classes.content}>
                    {c}
                </Typography>
            ))}
        </div>
    );
};

export default CustomTypography;

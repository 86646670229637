import React, { FunctionComponent, ChangeEvent, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import HelpIcon from "@mui/icons-material/Help";
import Dialog from "@mui/material/Dialog";
import { Tooltip, Zoom } from "@mui/material";
import { DemographicOptions } from "components/reports/heatmap/interface";
import { SurveySection } from "managerPortal/context/interfaces";
import { useLang } from "core/hooks";
import { isStringEmptyOrSpaces } from "core/helpers";
import LookdownList from "./LookdownList";
import HeatmapLookdownList from "./HeatmapLookdownList";
import { UserSettings } from "components/admin/users/interface";
import { userSettingMutation } from "api/mutations";
import { emptySurveyInfo } from "components/admin/results/init";
import RRLookdownList from "./RRLookdownList";
import KeyDriversLookdownList from "./KeyDriversLookdownList";
import { Box } from "lib/box";
import { initFilter } from "components/filters/inits";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Divider } from "lib/divider";
import { TextField } from "lib/text-field";
import { useUser } from "core/context/user/useUser";
import {
    LookdownItem,
    heatmapLookdownItem,
    rrLookdownItem,
    LookdownItemExport,
    KeyDriversLookdownItem,
    keyDriversExport,
    HeatmapCompareOption
} from "managerPortal/components/shared/DialogExportSlideWithLookdown";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSubmitCallback: (
        mainTitle: string,
        secondaryTitle: string,
        lookdownItems: LookdownItemExport[],
        heatmapFieldIds: number[],
        rrFieldIds: number[],
        keyDrivers: keyDriversExport[]
    ) => void;
    compareOptions: DemographicOptions[];
    surveySections: SurveySection[];
};

export const PreviewDialogExportSlideWithLookdown: FunctionComponent<Props> = props => {
    const { user, setUser } = useUser();
    const { lang } = useLang();
    const routeParams = useParams<{ id: string }>();
    const { isOpen, onClose, onSubmitCallback } = props;
    const [mainTitle, setMainTitle] = useState(lang.previewSnapshotExport);
    const [secondaryTitle, setSecondaryTitle] = useState("");
    const [error, setError] = useState<boolean>(false);
    const [mainTitleHelperText, setMainTitleHelperText] = useState<string>("");
    const [canExport, setCanExport] = useState<boolean>(true);
    const defaulHeatmapCompareOption = HeatmapCompareOption.overall;
    const [lookdownItems, setLookdownItems] = useState<LookdownItem[]>([
        {
            type: -1,
            demographicFieldId: -1,
            measureId: -1,
            demographicField: "",
            measureField: "",
            lookupIds: [-1, -1]
        }
    ]);
    const [heatmapLookdownItems, setHeatmapLookdownItems] = useState<heatmapLookdownItem[]>([
        {
            demographicFieldId: -1,
            demographicField: "",
            compareOption: defaulHeatmapCompareOption
        }
    ]);
    const [rrLookdownItems, setRRLookdownItems] = useState<rrLookdownItem[]>([
        {
            demographicFieldId: -1,
            demographicField: ""
        }
    ]);
    const [keyDriversLookdownItems, setKeyDriversLookdownItems] = useState<KeyDriversLookdownItem[]>([
        {
            isSection: false,
            measureId: -1,
            measureField: "",
            columnLabel: ""
        }
    ]);
    const labelRefs = useRef<HTMLDivElement[]>([]);

    const [updateUserSetting] = useMutation(userSettingMutation);
    const updateUserSettings = (previewSnapshotSetting: UserSettings["previewSnapshotExport"]): void => {
        const settings = {
            ...user.settings,
            previewSnapshotExport: previewSnapshotSetting
        };

        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };

        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const validation = (): boolean => {
        if (isStringEmptyOrSpaces(mainTitle)) {
            setError(true);
            setMainTitleHelperText(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }
        return true;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        if (name === "mainTitle") {
            const helperText = !value ? lang.dialogExportSlide.controls.errorMainTitle : "";
            setMainTitleHelperText(helperText);
            setError(!value);
            setMainTitle(value);
            return;
        }
        if (name === "subtitle") {
            setSecondaryTitle(value);
            return;
        }
    };

    const onExportClick = (): void => {
        const lookdownItemsExport = lookdownItems
            .filter(item => item.measureField !== "")
            .map(item => ({
                type: item.type,
                demographicFieldId: item.demographicFieldId,
                measureId: item.measureId,
                lookupIds: item.lookupIds.filter(id => id !== -1)
            }));
        const heatmapFieldIds = heatmapLookdownItems
            .filter(item => item.demographicFieldId !== -1)
            .map(item => item.demographicFieldId);
        const rrFieldIds = rrLookdownItems
            .filter(item => item.demographicFieldId !== -1)
            .map(item => item.demographicFieldId);
        const keyDriversExport = keyDriversLookdownItems
            .filter(item => item.measureId !== -1)
            .map(item => ({
                isSection: item.isSection,
                id: item.measureId,
                columnLabel: item.columnLabel
            }));
        validation() &&
            onSubmitCallback(
                mainTitle,
                secondaryTitle,
                lookdownItemsExport,
                heatmapFieldIds,
                rrFieldIds,
                keyDriversExport
            );
    };

    const saveUserLookdownItemSettings = (items: LookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.previewSnapshotExport,
            previewLookdownItems: items.filter(item => item.measureId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserHeatmapLookdownSettings = (items: heatmapLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.previewSnapshotExport,
            previewHeatmapLookdownItems: items.filter(item => item.demographicFieldId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserKeyDriversLookdownSettings = (items: KeyDriversLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.previewSnapshotExport,
            previewKeyDriversLookdownItems: items.filter(item => item.measureId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserRRLookdownSettings = (items: rrLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.previewSnapshotExport,
            previewRRLookdownItems: items.filter(item => item.demographicFieldId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const clearUserLookdownItemSettings = (): void => {
        const updatedSettings = {
            ...user.settings.previewSnapshotExport,
            previewLookdownItems: [],
            previewHeatmapLookdownItems: [],
            previewRRLookdownItems: [],
            previewKeyDriversLookdownItems: []
        };
        updateUserSettings(updatedSettings);
    };

    const onClearAll = (): void => {
        setMainTitle("");
        setMainTitleHelperText(lang.dialogExportSlide.controls.errorMainTitle);
        setSecondaryTitle("");
        setLookdownItems([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
        setHeatmapLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
        setRRLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
        setKeyDriversLookdownItems([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
        setError(true);
        clearUserLookdownItemSettings();
    };

    const handleUpdateLookdownItem = (item: LookdownItem, index: number): void => {
        const items = [...lookdownItems];
        items[index] = item;
        setLookdownItems([...items]);
        saveUserLookdownItemSettings([...items]);
    };

    const handleUpdateheatMapLookdownItem = (item: heatmapLookdownItem, index: number): void => {
        const items = [...heatmapLookdownItems];
        items[index] = item;
        setHeatmapLookdownItems([...items]);
        saveUserHeatmapLookdownSettings([...items]);
    };
    const handleUpdateRRLookdownItem = (item: rrLookdownItem, index: number): void => {
        const items = [...rrLookdownItems];
        items[index] = item;
        setRRLookdownItems([...items]);
        saveUserRRLookdownSettings([...items]);
    };

    const handleUpdateKeyDriversLookdownItem = (item: KeyDriversLookdownItem, index: number): void => {
        const items = [...keyDriversLookdownItems];
        items[index] = item;
        setKeyDriversLookdownItems([...items]);
        saveUserKeyDriversLookdownSettings([...items]);
    };
    const handleUpdateColumnLabelValue = (label: string, index: number): void => {
        const items = [...keyDriversLookdownItems];
        items[index].columnLabel = label;
        setKeyDriversLookdownItems([...items]);
    };
    const handleColumnLabelOnBlur = (): void => {
        saveUserKeyDriversLookdownSettings([...keyDriversLookdownItems]);
    };

    const handleDeleteLookdownItem = (): void => {
        resetLookdownItem();
    };

    const handleDeleteheatMapLookdownItem = (): void => {
        resetHeatmapLookdownItem();
    };

    const handleDeleteRRLookdownItem = (): void => {
        resetRRLookdownItem();
    };
    const handleDeleteKeyDriversLookdownItem = (): void => {
        resetKeyDriversLookdownItem();
    };

    const resetLookdownItem = (): void => {
        setLookdownItems([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
        saveUserLookdownItemSettings([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
    };
    const resetHeatmapLookdownItem = (): void => {
        setHeatmapLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
        saveUserHeatmapLookdownSettings([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
    };
    const resetRRLookdownItem = (): void => {
        setRRLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
        saveUserRRLookdownSettings([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
    };
    const resetKeyDriversLookdownItem = (): void => {
        setKeyDriversLookdownItems([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
        saveUserKeyDriversLookdownSettings([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
    };

    const restoreUserSettings = async (
        previewSnapshotExportSettings: UserSettings["previewSnapshotExport"]
    ): Promise<void> => {
        if (
            previewSnapshotExportSettings.surveyId &&
            previewSnapshotExportSettings.surveyId !== emptySurveyInfo.id &&
            previewSnapshotExportSettings.surveyId === +routeParams.id
        ) {
            if (
                previewSnapshotExportSettings.previewLookdownItems &&
                previewSnapshotExportSettings.previewLookdownItems.length > 0
            ) {
                setLookdownItems([...previewSnapshotExportSettings.previewLookdownItems]);
            }
            if (
                previewSnapshotExportSettings.previewHeatmapLookdownItems &&
                previewSnapshotExportSettings.previewHeatmapLookdownItems.length > 0
            ) {
                setHeatmapLookdownItems([...previewSnapshotExportSettings.previewHeatmapLookdownItems]);
            }
            if (
                previewSnapshotExportSettings.previewRRLookdownItems &&
                previewSnapshotExportSettings.previewRRLookdownItems.length > 0
            ) {
                setRRLookdownItems([...previewSnapshotExportSettings.previewRRLookdownItems]);
            }
            if (
                previewSnapshotExportSettings.previewKeyDriversLookdownItems &&
                previewSnapshotExportSettings.previewKeyDriversLookdownItems.length > 0
            ) {
                setKeyDriversLookdownItems([...previewSnapshotExportSettings.previewKeyDriversLookdownItems]);
            }
        } else {
            setLookdownItems([
                {
                    type: -1,
                    demographicFieldId: -1,
                    measureId: -1,
                    demographicField: "",
                    measureField: "",
                    lookupIds: [-1, -1]
                }
            ]);
            setHeatmapLookdownItems([
                {
                    demographicFieldId: -1,
                    demographicField: "",
                    compareOption: defaulHeatmapCompareOption
                }
            ]);
            setRRLookdownItems([
                {
                    demographicFieldId: -1,
                    demographicField: ""
                }
            ]);
            setKeyDriversLookdownItems([
                {
                    isSection: false,
                    measureId: -1,
                    measureField: "",
                    columnLabel: ""
                }
            ]);
            const updatedSettings = {
                ...user.settings.previewSnapshotExport,
                previewLookdownItems: [],
                previewHeatmapLookdownItems: [],
                previewRRLookdownItems: [],
                previewKeyDriversLookdownItems: [],
                surveyId: +routeParams.id
            };
            updateUserSettings(updatedSettings);
        }
    };

    const handleOnFocus = (e: React.FocusEvent, index: number): void => {
        if (labelRefs.current[index]) {
            const textInput = labelRefs.current[index] as HTMLInputElement;
            textInput.select();
        }
    };

    //restore user settings
    useEffect(() => {
        if (user.settings.previewSnapshotExport) {
            restoreUserSettings(user.settings.previewSnapshotExport);
        }
    }, []);

    useEffect(() => {
        let canExportLookdown = true;
        if (lookdownItems[0].demographicField === "" && lookdownItems[0].measureField === "") {
            canExportLookdown = true;
        } else if (lookdownItems[0].demographicField !== "" && lookdownItems[0].measureField !== "") {
            canExportLookdown = true;
        } else {
            canExportLookdown = false;
        }

        setCanExport(canExportLookdown);
    }, [lookdownItems]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    height: 800,
                    minWidth: 1000,
                    maxWidth: 1000
                }
            }}
            onKeyUp={e => {
                if (e.key === "Enter" && !error && canExport) {
                    onExportClick();
                }
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between" height={60} p={3}>
                <Typography variant="h4">{lang.previewSnapshotExport}</Typography>
                <Button data-testid="btn-export-clearAll" onClick={onClearAll} variant="text">
                    {lang.clearAll}
                </Button>
            </Box>
            <Divider />
            <Box height="calc(100% - 120px)" overflow="auto">
                <Box display="flex" gap={2} p={2} pb={2} pl={4}>
                    <TextField
                        autoFocus
                        data-testid="text-field-export-title"
                        name="mainTitle"
                        error={error}
                        label={lang.dialogExportSlide.controls.labelMainTitle}
                        onChange={handleChange}
                        value={mainTitle}
                        helperText={mainTitleHelperText}
                        style={{ width: 384 }}
                        FormHelperTextProps={{
                            style: {
                                marginBottom: "0px"
                            }
                        }}
                    />
                    <TextField
                        data-testid="text-field-export-subtitle"
                        name="subtitle"
                        label={lang.dialogExportSlide.controls.labelSubTitle}
                        onChange={handleChange}
                        value={secondaryTitle}
                        multiline
                        style={{ width: 384 }}
                    />
                </Box>
                <Divider />
                <Box p={2} pl={3}>
                    <Typography>{lang.addResponseRateLookdown}</Typography>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {rrLookdownItems &&
                        rrLookdownItems.length > 0 &&
                        rrLookdownItems.map((item: rrLookdownItem, index) => {
                            return (
                                <RRLookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    rrLookdownItem={item}
                                    rrLookdownItems={rrLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateRRLookdownItem}
                                    deleteLookdownItem={handleDeleteRRLookdownItem}
                                    currentFilter={initFilter}
                                />
                            );
                        })}
                </Box>
                <Divider />
                <Box p={1} pl={3} gap={3} display="flex" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Typography>{lang.addLookdown}</Typography>
                        <Tooltip TransitionComponent={Zoom} title={lang.lookdownTooltip} placement="right">
                            <IconButton size={"small"}>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {lookdownItems &&
                        lookdownItems.length > 0 &&
                        lookdownItems.map((item: LookdownItem, index) => {
                            return (
                                <LookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    surveySections={props.surveySections}
                                    lookdownItem={item}
                                    index={index}
                                    updateLookdownItem={handleUpdateLookdownItem}
                                    deleteLookdownItem={handleDeleteLookdownItem}
                                    showLookup={false}
                                    disabledDemographicIds={[]}
                                    bulkDemographicFieldId={-1}
                                />
                            );
                        })}
                </Box>
                <Divider />
                <Box p={2} pl={3}>
                    <Typography>{lang.addHeatmapLookdown}</Typography>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {heatmapLookdownItems &&
                        heatmapLookdownItems.length > 0 &&
                        heatmapLookdownItems.map((item: heatmapLookdownItem, index) => {
                            return (
                                <HeatmapLookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    heatmapLookdownItem={item}
                                    heatmapLookdownItems={heatmapLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateheatMapLookdownItem}
                                    deleteLookdownItem={handleDeleteheatMapLookdownItem}
                                    currentFilter={initFilter}
                                    isBulkExport={false}
                                    showPreviousPeriodOptionInHeatmap={false}
                                    disablePreviousPeriodOptionInHeatmap={true}
                                    previewMode={true}
                                />
                            );
                        })}
                </Box>
                <Divider />
                <Box p={1} pl={3} display="flex" alignItems="center">
                    <Typography>{lang.addKeyDriversLookdown}</Typography>
                    <Tooltip TransitionComponent={Zoom} title={lang.keyDriverslookdownTooltip} placement="right">
                        <IconButton size={"small"}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={2}>
                    {keyDriversLookdownItems &&
                        keyDriversLookdownItems.length > 0 &&
                        keyDriversLookdownItems.map((item: KeyDriversLookdownItem, index) => {
                            return (
                                <KeyDriversLookdownList
                                    key={item.measureId}
                                    surveySections={props.surveySections}
                                    keyDriversLookdownItem={item}
                                    keyDriversLookdownItems={keyDriversLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateKeyDriversLookdownItem}
                                    deleteLookdownItem={handleDeleteKeyDriversLookdownItem}
                                    responseCount={100}
                                    updateColumnLabelValue={handleUpdateColumnLabelValue}
                                    columnLabelOnBlur={handleColumnLabelOnBlur}
                                    inputRef={(el: HTMLDivElement): HTMLDivElement => (labelRefs.current[index] = el)}
                                    handleOnFocus={handleOnFocus}
                                    previewMode={true}
                                />
                            );
                        })}
                </Box>
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="flex-end" height={60} p={3} gap={2}>
                <Button
                    onClick={onExportClick}
                    disabled={error || !canExport}
                    variant="text"
                    data-testid={"btn-dialog-export-slide"}
                >
                    {lang.ok}
                </Button>
                <Button data-testid="btn-export-cancel" onClick={onClose} variant="text">
                    {lang.cancel}
                </Button>
            </Box>
        </Dialog>
    );
};

export enum SentimentCalculationStatus {
    notCalculated,
    calculating,
    completed
}

export enum AutoTranslationCalculationStatus {
    notCalculated,
    calculating,
    completed
}

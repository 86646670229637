import React, { ReactElement, useState, useEffect } from "react";

import { Checkbox } from "lib/checkbox";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Chip } from "lib/chip";
import { OrganizationInfo as IOrganizationInfo, OrganizationLogo } from "./interfaces";
import { useLang, useOrgChartService } from "core/hooks";
import { ValidationError } from "core/interfaces";
import DropArea from "components/shared/DropArea";
import ImageHolder from "components/shared/ImageHolder";
import { useOrganizationInfoStyles } from "./styles";
import { OrgDataKey } from "./enums";
import { Tag } from "components/admin/tags/interface";
import { sortArray } from "core/helpers";
import { TextField } from "lib/text-field";
import { Typography } from "lib/typography";
import { Paper } from "lib/paper";
import { theme } from "lib/theme";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";
import { ButtonLink } from "lib/button-link";

type Props = {
    organization: IOrganizationInfo;
    isNewOrganization: boolean;
    onChangeField: (
        key: keyof IOrganizationInfo,
        newValue: string | number | boolean | OrganizationLogo,
        shouldSave: boolean
    ) => void;
    onBlur?: () => void;
    onDeactivateOrg: () => void;
    validation: ValidationError;
    onUploadLogo: (fileBase64: string, file: File) => void;
    onThirdPartyUploadLogo: (fileBase64: string, file: File) => void;
    onDeleteLogo: () => void;
    onDeleteThirdPartyLogo: () => void;
    addCreatedTag?: (tag: Tag) => void;
    deleteCreatedTag?: (tag: Tag) => void;
    editMode: boolean;
    redirectURL?: string;
    handleSaveButton?: () => void;
};

const surveyTagsPaperRoot = {
    display: "flex",
    gap: 1,
    minHeight: 53,
    minWidth: 500,
    maxWidth: 500,
    justifyContent: "flex-start",
    alignContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    boxSizing: "border-box",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
};

const OrganizationInfo = ({
    organization,
    isNewOrganization,
    onChangeField,
    onBlur,
    onDeactivateOrg,
    validation,
    onUploadLogo,
    onDeleteLogo,
    addCreatedTag,
    deleteCreatedTag,
    editMode,
    redirectURL,
    handleSaveButton,
    onThirdPartyUploadLogo,
    onDeleteThirdPartyLogo
}: Props): ReactElement => {
    const classes = useOrganizationInfoStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const orgChartService = useOrgChartService();
    const [fileTypeMessage, setFileTypeMessage] = useState(lang.pngTransparent);
    const [thirdPartyFileTypeMessage, setThirdPartFileTypeMessage] = useState(lang.pngTransparent);
    const [tags, setTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([{ name: "", id: -1 }]);

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if (event.target.type === "text") {
            onChangeField(event.target.name as keyof IOrganizationInfo, newValue, false);
        } else {
            onChangeField(event.target.name as keyof IOrganizationInfo, newValue, !isNewOrganization);
        }
    };

    function setFileTypeValid(fileValid: boolean): void {
        const message = fileValid ? lang.pngTransparent : lang.errorMustUploadPNG;
        setFileTypeMessage(message);
    }

    function setThirdPartyFileTypeValid(fileValid: boolean): void {
        const message = fileValid ? lang.pngTransparent : lang.errorMustUploadPNG;
        setThirdPartFileTypeMessage(message);
    }

    const setLogoPreview = (file: File): void => {
        const reader = new FileReader();
        reader.onload = (): void => {
            onUploadLogo(reader.result as string, file);
        };
        reader.readAsDataURL(file);
    };

    const setThirdPartyLogoPreview = (file: File): void => {
        const reader = new FileReader();
        reader.onload = (): void => {
            const base64ToSave = reader.result as string;
            onThirdPartyUploadLogo(base64ToSave.split(",")[1], file);
        };
        reader.readAsDataURL(file);
    };

    const handleFileDrop = (files: File[]): void => {
        if (files && files[0]) {
            setFileTypeValid(true);
            setLogoPreview(files[0]);
        }
    };

    const handleThirdPartyLogoFileDrop = (files: File[]): void => {
        if (files && files[0]) {
            setThirdPartyFileTypeValid(true);
            setThirdPartyLogoPreview(files[0]);
        }
    };

    const renderActivationButton = (): ReactElement => {
        return (
            <Box mr={2}>
                <Button
                    color={organization.active ? "error" : "primary"}
                    onClick={onDeactivateOrg}
                    disabled={organization.id === 1}
                    data-testid="btn-deactivate-reactivate-org"
                >
                    {organization.active ? lang.deactivate : lang.reactivate}
                </Button>
            </Box>
        );
    };

    const getTags = async (): Promise<void> => {
        if (organization.id !== -1) {
            const allTags = await orgChartService.getTags();
            const tagsByOrganization = await orgChartService.getTags(false, organization.id);
            setTags(allTags);
            setSelectedTags(tagsByOrganization);
        } else {
            // for add organization page
            const allTags = await orgChartService.getTags();
            setTags(allTags);
        }
    };
    const sortTagsArray = <T extends Tag>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");

    const handleTagSelected = (value: AutocompleteValue | null): void => {
        if (value !== null && value !== undefined) {
            const selectedTag = { name: value.label, id: value.id };
            if (organization.id !== -1 && !selectedTags.map(t => t.id).includes(value.id)) {
                orgChartService.addOrganizationTag(organization.id, value.id).then(() => {
                    if (!selectedTags.map(t => t.id).includes(value.id)) {
                        setSelectedTags([...selectedTags, selectedTag]);
                    }
                });
            } else {
                if (!selectedTags.map(t => t.id).includes(value.id)) {
                    setSelectedTags([...selectedTags, selectedTag]);
                    addCreatedTag && addCreatedTag(selectedTag);
                }
            }
            return;
        }
    };

    const handleDeleteChip = (tag: Tag) => (): void => {
        if (organization.id !== -1) {
            orgChartService.deleteOrganizationTag(organization.id, tag.id).then(() => {
                const tags = [...selectedTags].filter(t => t.id !== tag.id);
                setSelectedTags(tags);
            });
        } else {
            // for add organization page
            const tags = [...selectedTags].filter(t => t.id !== tag.id);
            setSelectedTags(tags);
            deleteCreatedTag && deleteCreatedTag(tag);
        }
    };

    useEffect(() => {
        getTags();
    }, [organization.id]);

    return (
        <div className={classes.container}>
            <div className={classes.contentInfo}>
                <Typography fontWeight="medium">{lang.organizationInformationLabel}</Typography>
                {!isNewOrganization && renderActivationButton()}
            </div>
            <div className={classes.formControl}>
                <TextField
                    label={lang.name}
                    name={OrgDataKey.name}
                    value={organization.name}
                    onBlur={onBlur}
                    onChange={handleChangeField}
                    error={validation.key === OrgDataKey.name}
                    helperText={validation.key === OrgDataKey.name ? validation.message : lang.required}
                    data-testid="text-field-org-name"
                />
            </div>
            <Box display="flex" alignItems="center" gap={2}>
                <Box width={400} pl={3}>
                    <Autocomplete
                        id="organization-tag-combo-box"
                        noOptionsText={lang.noOptionsAvailable}
                        options={sortTagsArray(tags).map(s => {
                            return {
                                id: s.id,
                                label: s.name
                            };
                        })}
                        onChange={handleTagSelected}
                        getOptionDisabled={(option): boolean => selectedTags.map(t => t.id).includes(option.id)}
                        placeholder={lang.addTag}
                    />
                </Box>
                <Paper component="ul" sx={surveyTagsPaperRoot} data-testid="survey-tag-paper">
                    <Typography variant="body2">{lang.tags}</Typography>
                    {sortTagsArray(selectedTags)
                        .filter(t => t.id !== -1)
                        .map(tag => {
                            return (
                                <li key={tag.id}>
                                    <Box ml={1}>
                                        <Chip label={tag.name} onDelete={handleDeleteChip(tag)} />
                                    </Box>
                                </li>
                            );
                        })}
                </Paper>
            </Box>
            <Divider />
            <div className={classes.permission}>
                <Typography fontWeight="medium">{lang.permissions}</Typography>
                {organization.name !== "TalentMap" && (
                    <Checkbox
                        checked={organization.isDashboardEnabled}
                        name={OrgDataKey.isDashboardEnabled}
                        onChange={handleChangeField}
                        label={lang.dashboard}
                        data-testid={`org-premissions-${lang.dashboard}`}
                    />
                )}
                {user.isTalentMapAdmin && (
                    <Checkbox
                        checked={organization.isAIEnabled}
                        name={OrgDataKey.isAIEnabled}
                        onChange={handleChangeField}
                        label={lang.aiInsights}
                        data-testid={`org-premissions-${lang.aiInsights}`}
                        style={organization.name !== "TalentMap" ? { paddingLeft: 16 } : { paddingLeft: 0 }}
                    />
                )}
            </div>
            <div className={classes.dropContainer}>
                <div className={classes.dropAreaContainer} data-testid="org-logo-drop-area">
                    <Typography fontWeight={500} mb={3}>
                        {lang.logo}
                    </Typography>
                    <DropArea
                        fileDrop={handleFileDrop}
                        title="uploadLogo"
                        label={lang.uploadLogo}
                        acceptFiles={"image/png"}
                        helperText={fileTypeMessage}
                        width={360}
                        onRejectFiles={(): void => {
                            setFileTypeValid(false);
                        }}
                    />
                </div>
                <div className={classes.logoContainer} data-testid="org-logo">
                    {organization.organizationLogo.logoUrl ? (
                        <>
                            <ImageHolder maxHeight={140} maxWidth={500} src={organization.organizationLogo.logoUrl} />
                            <div className={classes.deleteLogoButton}>
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={onDeleteLogo}
                                    data-testid="btn-org-delete-logo"
                                >
                                    {lang.deleteLogo}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Typography variant="subtitle1" fontWeight={500} mb={1}>
                            {lang.noLogoUploaded}
                        </Typography>
                    )}
                </div>
                <div className={classes.dropAreaContainer} data-testid="org-third-party-logo-drop-area">
                    <Typography fontWeight={500} mb={3}>
                        {lang.thirdPartyLogo}
                    </Typography>
                    <DropArea
                        fileDrop={handleThirdPartyLogoFileDrop}
                        title="uploadThirdPartyLogo"
                        label={lang.uploadLogo}
                        acceptFiles={"image/png"}
                        helperText={thirdPartyFileTypeMessage}
                        width={360}
                        onRejectFiles={(): void => {
                            setThirdPartyFileTypeValid(false);
                        }}
                    />
                </div>
                <div className={classes.logoContainer} data-testid="org-third-party-logo">
                    {organization.thirdPartyLogoBase64 ? (
                        <>
                            <ImageHolder
                                maxHeight={140}
                                maxWidth={500}
                                src={`data:image/jpeg;base64,${organization.thirdPartyLogoBase64}`}
                            />
                            <div className={classes.deleteLogoButton}>
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={onDeleteThirdPartyLogo}
                                    data-testid="btn-org-delete-third-party-logo"
                                >
                                    {lang.deleteLogo}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Typography variant="subtitle1" fontWeight={500} mb={1}>
                            {lang.noLogoUploaded}
                        </Typography>
                    )}
                </div>
            </div>

            {editMode ? (
                <></>
            ) : (
                <Box display="flex" gap={4} pl={3} pt={2}>
                    <Button
                        variant="outlined"
                        onClick={handleSaveButton}
                        data-testid="btn-add-org-save"
                        disabled={organization.name.trim() == ""}
                    >
                        {lang.ok}
                    </Button>
                    <ButtonLink
                        variant="outlined"
                        link={redirectURL ?? "/admin/organizations"}
                        data-testid="btn-add-org-cancel"
                    >
                        {lang.cancel}
                    </ButtonLink>
                </Box>
            )}
        </div>
    );
};

export default OrganizationInfo;

import { gql } from "@apollo/client";
export const editSurveyComparisonValueMapMutation = gql`
    mutation editSurveyComparisonValueMap(
        $surveyComparisonId: Int!
        $sourceFieldId: Int!
        $sourceValue: String!
        $filters: String!
    ) {
        editSurveyComparisonValueMap(
            surveyComparisonId: $surveyComparisonId
            sourceFieldId: $sourceFieldId
            sourceValue: $sourceValue
            filters: $filters
        )
    }
`;

import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { organizationQuery, organizationsQuery } from "api/queries";
import { OrganizationInfo } from "components/admin/organizations/interfaces";
import { emptyOrganization } from "components/admin/organizations/initialVariables";
import { useUser } from "../user/useUser";
import Error500 from "components/errorPage/Error500";

type Context = {
    organizations: OrganizationInfo[];
    selectedOrganization: OrganizationInfo;
    addOrganization: (newOrg: OrganizationInfo) => void;
    updateOrganization: (orgInfo: OrganizationInfo) => void;
    selectOrganization: (orgInfo: OrganizationInfo) => void;
};

const OrganizationContext = createContext<Context>({
    organizations: [],
    selectedOrganization: emptyOrganization,
    addOrganization: () => null,
    updateOrganization: () => null,
    selectOrganization: () => null
});

const OrganizationProvider = ({ children }: PropsWithChildren) => {
    const { user } = useUser();
    const [organizations, setOrganizations] = useState<OrganizationInfo[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInfo>(emptyOrganization);

    const [getOrganizationById, { loading: loadingOrgById, error: errorGettingOrgById }] = useLazyQuery(
        organizationQuery,
        {
            onCompleted: data => {
                setOrganizations([data.organization]);
                setSelectedOrganization(data.organization);
            }
        }
    );

    const [getAllOrganizations, { loading: loadingAllOrgs, error: errorGettingAllOrgs }] = useLazyQuery(
        organizationsQuery,
        {
            onCompleted: data => {
                setOrganizations(data.organizations);
                const selectedOrgId = user.settings.selectedOrganization.id || 1;
                const found = data.organizations.find((org: OrganizationInfo) => org.id === selectedOrgId);
                if (found) {
                    setSelectedOrganization(found);
                }
            }
        }
    );

    useEffect(() => {
        if (user.isTalentMapAdmin) {
            getAllOrganizations();
        } else if (process.env.JEST_WORKER_ID) {
            getOrganizationById({ variables: { id: 1 } });
        } else {
            getOrganizationById({ variables: { id: user.settings.selectedOrganization.id } });
        }
    }, []);

    const addOrganization = (newOrg: OrganizationInfo) => {
        setOrganizations(prevState => {
            return [...prevState, newOrg];
        });
    };

    const updateOrganization = (orgInfo: OrganizationInfo) => {
        const allOrgs = [...organizations];
        const index = allOrgs.findIndex((org: OrganizationInfo) => org.id === orgInfo.id);
        if (index !== -1) {
            allOrgs[index] = orgInfo;
        } else {
            allOrgs.push(orgInfo);
        }
        setOrganizations(allOrgs);
    };

    const selectOrganization = (orgInfo: OrganizationInfo) => {
        setSelectedOrganization(orgInfo);
    };

    if (loadingAllOrgs || loadingOrgById) {
        return <></>;
    }

    if (errorGettingAllOrgs || errorGettingOrgById) {
        return <Error500 />;
    }

    return (
        <OrganizationContext.Provider
            value={{
                organizations,
                selectedOrganization,
                selectOrganization,
                addOrganization,
                updateOrganization
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export { OrganizationProvider, OrganizationContext };

import React, { forwardRef, ForwardedRef } from "react";
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";

const IconButton = forwardRef(function IconButton(props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
    const { children, color, ...rest } = props;
    return (
        <MuiIconButton ref={ref} autoFocus={false} color={`${color || "default"}`} {...rest}>
            {children}
        </MuiIconButton>
    );
});

export { IconButton };

import React, { FC, ReactElement, useEffect, useState } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";
import { useLang } from "../../../core/hooks";
import TruncateDisplay from "../../../managerPortal/components/shared/TruncateDisplay";
import { Translation } from "core/languages/interfaces";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 12
    }
}));

type Props = {
    text: string;
    withTranslation?: boolean;
    truncate: boolean;
    width?: number;
};

const CellPlainText: FC<Props> = ({ text, withTranslation, truncate, width }): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    const showText = withTranslation ? (lang[text as keyof Translation] as string) : text;
    const [maxLabelLength, setMaxLabelLength] = useState<number>(25);

    useEffect(() => {
        const columnWidth = width === 0 ? width : 0;
        setMaxLabelLength(columnWidth === 0 ? 25 : Math.floor(columnWidth / 6));
    }, [width]);

    return truncate ? (
        <TruncateDisplay title={showText} maxLabelLength={maxLabelLength}>
            <Typography variant="body2" className={classes.text} noWrap>
                {showText}
            </Typography>
        </TruncateDisplay>
    ) : (
        <Typography variant="body2" className={classes.text} noWrap>
            {showText}
        </Typography>
    );
};

export default CellPlainText;

import { gql } from "@apollo/client";

export const uploadLogoMutation = gql`
    mutation uploadLogo($id: Int!, $file: Upload) {
        uploadLogo(id: $id, file: $file) {
            id
            logoUrl
            originalFileName
        }
    }
`;

import React, { useState, ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { ReactComponent as ErrorSVG } from "../../assets/images/error/404Error.svg";
import { useLang } from "core/hooks";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Typography } from "lib/typography";

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 5
};

const Error404 = (): ReactElement => {
    const [redirect, setRedirect] = useState(false);
    const { lang } = useLang();

    const redirectToHome = (): void => {
        setRedirect(!redirect);
    };

    return redirect ? (
        <Redirect to={{ pathname: "/" }} />
    ) : (
        <Box data-testid="error404" sx={containerStyle}>
            <Box mb={4}>
                <Typography variant="h1">{lang.pageNotFound}</Typography>
            </Box>
            <Box m={1}>
                <Typography variant="h6" color="GrayText">
                    {lang.sorryCantSeemPage}
                </Typography>
            </Box>
            <Typography variant="h6" color="GrayText">
                {lang.pageNotExistOrRemoved}
            </Typography>
            <Box mt={3.5} mb={7}>
                <Button data-testid="btn-go-homepage" onClick={redirectToHome}>
                    {lang.goHomepage}
                </Button>
            </Box>
            <ErrorSVG />
        </Box>
    );
};

export default Error404;

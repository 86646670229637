import { gql } from "@apollo/client";

export const editSurveyComparisonItemMapMutation = gql`
    mutation editSurveyComparisonItemMap($surveyComparisonId: Int!, $sourceFieldId: Int!, $targetFieldId: Int) {
        editSurveyComparisonItemMap(
            surveyComparisonId: $surveyComparisonId
            sourceFieldId: $sourceFieldId
            targetFieldId: $targetFieldId
        ) {
            sourceFieldId
            targetFieldId
        }
    }
`;

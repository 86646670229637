import React, { createContext, forwardRef, CSSProperties, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FixedSizeList as List, FixedSizeListProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { DialogLayout } from "components/shared";
import { useLang } from "core/hooks";
import { backgroundLightGrey } from "core/styles";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";

const cellWidth = 120;

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCompleteClick: () => void;
    responses: string[][];
};

const useStyles = makeStyles(() => ({
    content: {
        height: "calc(100% - 80px)",
        width: "100%"
    },
    tableCell: {
        minWidth: cellWidth,
        maxWidth: cellWidth,
        boxSizing: "border-box",
        paddingLeft: 8,
        paddingRight: 8
    },
    stickyRow: ({ numOfColumns }: { numOfColumns: number }) => ({
        position: "sticky !important" as CSSProperties["position"],
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        borderBottom: theme.border.main,
        backgroundColor: backgroundLightGrey,
        boxSizing: "border-box",
        width: numOfColumns * cellWidth + cellWidth,
        height: 48,
        left: 0,
        top: 0
    }),
    row: {
        position: "absolute !important" as CSSProperties["position"],
        display: "flex",
        alignItems: "center",
        borderBottom: theme.border.main,
        boxSizing: "border-box"
    }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StickyListContext = createContext({} as any);
StickyListContext.displayName = "StickyListContext";

const ItemWrapper = ({
    data,
    index,
    style
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { stickyIndices: number[]; ItemRenderer: any };
    index: number;
    style: CSSProperties;
}): ReactElement => {
    const { ItemRenderer, stickyIndices } = data;
    if (stickyIndices && stickyIndices.includes(index)) {
        return <></>;
    }
    return <ItemRenderer index={index} style={style} />;
};

type StickyListProps = FixedSizeListProps & {
    stickyIndices: number[];
};
const StickyList: React.FC<StickyListProps> = ({ children, stickyIndices, ...rest }) => (
    <StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices }}>
        <List itemData={{ ItemRenderer: children, stickyIndices }} {...rest}>
            {ItemWrapper}
        </List>
    </StickyListContext.Provider>
);

const SurveyPreviewDialog = (props: Props): ReactElement => {
    const numOfColumns = props.responses[0].length;
    const classes = useStyles({ numOfColumns });
    const { lang } = useLang();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));

    const responsiveValue = (): number => {
        const width = matchesSM ? 550 : matchesMD ? 850 : matchesLG ? 1100 : 1400;
        return width;
    };

    const Row = ({ index, style }: { index: number; style: CSSProperties }): ReactElement => {
        const rowStyle = {
            ...style,
            width: numOfColumns * cellWidth + cellWidth
        };
        return (
            <div className={classes.row} style={rowStyle}>
                <div className={classes.tableCell}>{index}</div>
                {props.responses[index].map((item: string, i: number) => (
                    <Typography noWrap className={classes.tableCell} key={i}>
                        {item}
                    </Typography>
                ))}
            </div>
        );
    };

    const StickyRow = ({ index }: { index: number }): ReactElement => (
        <div className={classes.stickyRow}>
            <div className={classes.tableCell}>line number</div>
            {props.responses[index].map((item: string, i: number) => (
                <Typography noWrap className={classes.tableCell} key={i}>
                    {item}
                </Typography>
            ))}
        </div>
    );

    // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
    const innerElementType = forwardRef(({ children, ...rest }: any, ref: unknown) => (
        <StickyListContext.Consumer>
            {({ stickyIndices }): ReactElement => (
                <div ref={ref} {...rest}>
                    {stickyIndices.map((index: number) => (
                        <StickyRow index={index} key={index} />
                    ))}
                    {children}
                </div>
            )}
        </StickyListContext.Consumer>
    ));

    return (
        <DialogLayout
            open={props.isOpen}
            onClose={props.onClose}
            title={lang.uploadSurveyPreview}
            buttonLabelAction={lang.ok}
            buttonLabelCancel={lang.cancel}
            onClick={props.onCompleteClick}
            width={responsiveValue()}
            suppressContentPadding
            hiddenOverflow
        >
            <Typography padding={1}>{lang.responses}</Typography>
            <Divider />
            <Typography padding={1}>
                {lang.numberOfRows}: {props.responses.length - 1}
            </Typography>
            <Divider />
            <div className={classes.content}>
                <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                        <StickyList
                            height={height - 2}
                            innerElementType={innerElementType}
                            itemCount={props.responses.length}
                            itemSize={48}
                            stickyIndices={[0]}
                            width={width}
                        >
                            {Row}
                        </StickyList>
                    )}
                </AutoSizer>
            </div>
        </DialogLayout>
    );
};

export default SurveyPreviewDialog;

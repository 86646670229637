import React, { ReactElement, useReducer, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import { UserMutateDetail, UserMutate, UserEditInput } from "./interface";
import { initEditUserInput } from "./initialVariables";
import { UserMutateReducer } from "./reducers";
import { UserMutateActions } from "./actions";
import { editUserMutation } from "api/mutations";
import { userQuery } from "api/queries";
import { useAdminService, useLang, useLoading } from "core/hooks";
import Error500 from "../../errorPage/Error500";
import { isStringEmptyOrSpaces } from "core/helpers";
import UserInfo from "./UserDetail";
import { Snackbar } from "lib/snackbar";
import { UserRole } from "./enums";
import { IconButton } from "lib/icon-button";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";

const init: UserMutate = {
    user: initEditUserInput,
    snackbar: { isOpen: false, message: "" },
    validation: { message: "", key: "" }
};

const EditUser = (): ReactElement => {
    const redirectURL = "/admin/users";
    const [state, dispatch] = useReducer(UserMutateReducer, init);
    const { lang } = useLang();
    const { setLoading } = useLoading();
    const routeParams = useParams<{ id: string }>();
    const history = useHistory();
    const { user, setUser } = useUser();
    const adminService = useAdminService();

    const { loading, error } = useQuery(userQuery, {
        variables: { ...routeParams },
        onCompleted: data => {
            const userFetched = {
                ...data.user
            };

            dispatch({
                type: UserMutateActions.SET_USER,
                payload: { user: userFetched }
            });
        }
    });

    const [editUser] = useMutation(editUserMutation, {
        onCompleted: data => {
            const userUpdated = {
                ...data.editUser
            };
            if (user.id && userUpdated.id && user.id === userUpdated.id) {
                const UpdatedUser = {
                    ...user,
                    id: userUpdated.id,
                    active: userUpdated.active,
                    email: userUpdated.email,
                    isCommentViewer: userUpdated.isCommentViewer,
                    isRestricted: userUpdated.isRestricted,
                    isSiteAdmin: userUpdated.isSiteAdmin,
                    isSurveyCreator: userUpdated.isSurveyCreator,
                    byPassSimilarityCheck: userUpdated.byPassSimilarityCheck,
                    isInsightsEnabled: userUpdated.isInsightsEnabled,
                    participantEnabled: userUpdated.isSiteAdmin ? userUpdated.participantEnabled : false,
                    name: userUpdated.name
                };
                setUser(UpdatedUser);
            }
            if (userUpdated.active && !state.user.active) {
                dispatch({
                    type: UserMutateActions.SET_SNACKBAR_STATUS,
                    payload: { snackbar: { isOpen: true, message: lang.userHasBeenReactivated } }
                });
                dispatch({ type: UserMutateActions.SET_USER, payload: { user: userUpdated } });
            } else if (!userUpdated.active && state.user.active) {
                dispatch({
                    type: UserMutateActions.SET_SNACKBAR_STATUS,
                    payload: { snackbar: { isOpen: true, message: lang.userHasBeenDeactivated } }
                });
                dispatch({ type: UserMutateActions.SET_USER, payload: { user: userUpdated } });
            }
        },
        onError: ({ networkError }) => {
            if (networkError) {
                if (networkError) {
                    try {
                        if (networkError.message) {
                            dispatch({
                                type: UserMutateActions.SET_SNACKBAR_STATUS,
                                payload: { snackbar: { isOpen: true, message: networkError.message } }
                            });
                        } else {
                            dispatch({
                                type: UserMutateActions.SET_SNACKBAR_STATUS,
                                payload: { snackbar: { isOpen: true, message: lang.errorUpdatingContactUs } }
                            });
                        }
                    } catch (err) {
                        dispatch({
                            type: UserMutateActions.SET_SNACKBAR_STATUS,
                            payload: { snackbar: { isOpen: true, message: lang.errorUpdatingContactUs } }
                        });
                    }
                }
            }
        }
    });

    const handleSave = (editedUser: UserMutateDetail): void => {
        const userModifiedForUpdate: UserEditInput = {
            id: editedUser.id!,
            name: editedUser.name,
            isSiteAdmin: editedUser.isSiteAdmin,
            isSurveyCreator: editedUser.isSurveyCreator,
            isCommentViewer: editedUser.isCommentViewer,
            isRestricted: editedUser.isRestricted,
            byPassSimilarityCheck: editedUser.byPassSimilarityCheck,
            isInsightsEnabled: editedUser.isInsightsEnabled,
            participantEnabled: editedUser.isSiteAdmin ? editedUser.participantEnabled : false,
            active: editedUser.active!
        };

        const queryOptions = {
            variables: { user: { ...userModifiedForUpdate } }
        };
        editUser(queryOptions);
    };

    const onChangeField = (key: keyof UserMutateDetail, newValue: string | number | boolean): void => {
        let updateUser: UserMutateDetail = {
            ...state.user,
            [key]: newValue
        };

        if (updateUser.isSiteAdmin) {
            updateUser = { ...updateUser, [UserRole.isRestricted]: false };
        }

        if (!updateUser.isSiteAdmin) {
            updateUser = { ...updateUser, [UserRole.participantEnabled]: false };
        }

        if (
            key === UserRole.isSurveyCreator ||
            key === UserRole.isSiteAdmin ||
            key === UserRole.isCommentViewer ||
            key === UserRole.isRestricted ||
            key === UserRole.byPassSimilarityCheck ||
            key === UserRole.isInsightsEnabled ||
            key === UserRole.participantEnabled
        ) {
            handleSave(updateUser);
        }

        dispatch({ type: UserMutateActions.SET_USER, payload: { user: updateUser } });
    };

    const inputValidation = (): boolean => {
        if (isStringEmptyOrSpaces(state.user.name)) {
            dispatch({
                type: UserMutateActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: lang.userNameCannotBeBlank, key: "name" }
                }
            });
            return false;
        }

        return true;
    };

    const onSaveChanges = (): void => {
        if (inputValidation()) {
            handleSave(state.user);
        }
        return;
    };
    const handleDeactivateUser = (): void => {
        const updateUser: UserMutateDetail = {
            ...state.user
        };
        updateUser.active = !updateUser.active;

        if (inputValidation()) {
            handleSave(updateUser);
        }
    };

    const handleResetPassword = (): void => {
        adminService
            .resetPassword(state.user.id as number)
            .then(() => {
                dispatch({
                    type: UserMutateActions.SET_SNACKBAR_STATUS,
                    payload: { snackbar: { isOpen: true, message: lang.passwordReset } }
                });
            })
            .catch(() => {
                dispatch({
                    type: UserMutateActions.SET_SNACKBAR_STATUS,
                    payload: { snackbar: { isOpen: true, message: lang.passwordResetError } }
                });
            });
    };

    const handleGoBackNSave = (): void => {
        onSaveChanges();
        setTimeout(() => {
            history.push(redirectURL);
        }, 1000);
    };

    const setLoadingToFalse = useCallback(() => {
        setLoading(false);
    }, [setLoading]);

    const setLoadingToTrue = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {
        setLoadingToTrue();

        if (!loading) {
            setLoadingToFalse();
        }
        return (): void => setLoadingToFalse();
    }, [loading, setLoadingToFalse, setLoadingToTrue]);

    if (loading) return <></>;

    if (error) return <Error500 />;

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: UserMutateActions.SET_SNACKBAR_STATUS,
            payload: { snackbar: init.snackbar }
        });
    };

    const canEdit = user.isTalentMapAdmin || user.isSiteAdmin;

    return (
        <Box height="100%">
            <Box display="flex" height={56} alignItems="center" pl={3} justifyContent="space-between">
                <Box display="flex">
                    <IconButton size="small" onClick={handleGoBackNSave}>
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    <Typography variant="h6" ml={1}>
                        {state.user.name}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box height={"calc(100% - 56px)"}>
                <UserInfo
                    user={state.user}
                    editMode={true}
                    onChangeField={onChangeField}
                    validation={state.validation}
                    onBlur={onSaveChanges}
                    canEdit={canEdit}
                    handleResetPassword={handleResetPassword}
                    handleDeactivateUser={handleDeactivateUser}
                />
            </Box>
            <Snackbar open={state.snackbar.isOpen} handleClose={handleCloseSnackbar} message={state.snackbar.message} />
        </Box>
    );
};

export default EditUser;

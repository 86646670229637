import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { AdminParticipantUploadStatus, UploadStatus } from "components/admin/participants/interfaces";
import { Box } from "lib/box";
import { useHistory } from "react-router-dom";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Divider } from "lib/divider";
import { LinearProgress, LinearProgressProps } from "lib/linear-progress";

type Props = {
    isOpen: boolean;
    uploadStatus: AdminParticipantUploadStatus | undefined;
};

const useStyles = makeStyles(() => ({
    container: {
        width: 440,
        height: 280,
        overflow: "auto"
    },
    head: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        paddingRight: 24
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: "8px 24px 8px 24px",
        overflow: "auto",
        height: "100%"
    },
    progressBar: {
        display: "flex",
        flexDirection: "column"
    },
    progressBox: {
        display: "flex",
        alignItems: "center",
        paddingTop: 24,
        paddingBottom: 12
    },
    text: {
        paddingTop: 8,
        fontSize: 14
    },
    textLine: {
        paddingTop: 4,
        fontSize: 14
    }
}));

const AdminParticipantsProgressStatusDialog = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const history = useHistory();

    const onHomePageButtonClick = (): void => {
        history.push("/");
    };

    const tooltipHelpText = (
        <div className={classes.text}>
            {lang.uploadMayTakesTime.map((l, index) => (
                <div key={index} className={classes.textLine}>
                    {l}
                </div>
            ))}
        </div>
    );
    const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }): ReactElement => {
        return (
            <div className={classes.progressBox}>
                <Box width={350}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box width={35} ml={2}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </div>
        );
    };

    return (
        <Dialog open={props.isOpen} classes={{ paper: classes.container }}>
            <DialogTitle className={classes.head}>
                <Typography variant="h6">{lang.uploadStatus}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.content }}>
                {props.uploadStatus && props.uploadStatus.status === UploadStatus.InProgress ? (
                    <div className={classes.progressBar}>
                        <LinearProgressWithLabel value={props.uploadStatus?.progress ?? 0} />
                        <Typography variant="body2">{lang.loadingParticipants}</Typography>
                    </div>
                ) : (
                    <Typography variant="body2" className={classes.text}>
                        {`${lang.reportQueued} (${lang.queuePositions} - ${props.uploadStatus?.queuePosition})`}
                    </Typography>
                )}
                {tooltipHelpText}
            </DialogContent>
            <DialogActions>
                <Button onClick={onHomePageButtonClick} variant="text" data-testid="btn-homePage">
                    {lang.goHomepage}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminParticipantsProgressStatusDialog;

import { gql } from "@apollo/client";

export const surveyContactFieldsQuery = gql`
    query surveyContactFields($surveyId: ID!) {
        surveyContactFields(surveyId: $surveyId) {
            fieldId
            title
        }
    }
`;

import { useContext } from "react";
import { SnackbarContext } from "../context/SnackbarContext";

export const useSnackbar = (): { setMessage: (message: string, autoHideOff?: boolean) => void } => {
    const { setSnackbarMessage } = useContext(SnackbarContext);

    const setMessage = (message: string, autoHideOff?: boolean): void => {
        setSnackbarMessage(message, autoHideOff);
    };
    return { setMessage };
};

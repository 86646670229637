import { useState } from "react";
import { useQuery } from "@apollo/client";

import { usersQuery } from "api/queries";
import { UserInput } from "components/admin/users/interface";
import { useUser } from "core/context/user/useUser";

export const useOrgUsers = (): { orgUsers: UserInput[] } => {
    const { user } = useUser();
    const [orgUsers, setOrgUsers] = useState<UserInput[]>([]);

    useQuery(usersQuery, {
        variables: { orgId: user.settings.selectedOrganization.id },
        onCompleted: (data: { users: UserInput[] }): void => {
            if (data) {
                setOrgUsers(data.users);
            }
        }
    });

    return { orgUsers };
};

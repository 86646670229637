import { Action } from "core/interfaces";
import { SurveyAdminActions } from "../actions";
import { SurveyAdminState } from "../interfaces";

export const surveyAdminReducer = (state: SurveyAdminState, action: Action): SurveyAdminState => {
    const { type, payload } = action;
    switch (type) {
        case SurveyAdminActions.SELECT_TAB:
            return {
                ...state,
                tabSelected: payload.tabSelected
            };
        case SurveyAdminActions.IMPORT_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                isImportOpen: payload.isImportOpen
            };
        case SurveyAdminActions.CHANGE_SEARCH_STRING:
            return {
                ...state,
                searchString: payload.searchString
            };

        case SurveyAdminActions.SNACKBAR_OPEN_CLOSE:
            return {
                ...state,
                snackBar: { ...payload.snackBar }
            };
        case SurveyAdminActions.SET_SURVEY_LIST:
            return {
                ...state,
                deactivatedSurveyList: payload.deactivatedSurveyList,
                activatedSurveyList: payload.activatedSurveyList
            };
        case SurveyAdminActions.RESULT_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                isAddResultOpen: payload.isAddResultOpen
            };
        case SurveyAdminActions.SET_COMPONENT_STATUS:
            return {
                ...state,
                componentStatus: payload.componentStatus
            };
        default:
            return {
                ...state
            };
    }
};

import { makeStyles } from "@mui/styles";

export const useAutoSizerStyles = makeStyles(() => ({
    autoSizer: {
        "& div": {
            "& div": {
                pointerEvents: "all"
            }
        }
    }
}));

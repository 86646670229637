import React from "react";

import { dateFormat } from "core/constants";
import { useLang } from "core/hooks";

import { FilterParameter } from "../interfaces";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "lib/date-picker";
import { Box } from "lib/box";

type DateTarget = {
    selectedFilterItem: FilterParameter;
    updateFilterItems: (param: FilterParameter) => void;
};

export const DateTarget = ({ selectedFilterItem, updateFilterItems }: DateTarget) => {
    const { lang } = useLang();
    const handleEndDateChange = (date: Dayjs | null): void => {
        const updateStartDate =
            selectedFilterItem.target[0] !== null ? selectedFilterItem.target[0] : { id: "", text: "" };
        const updateEndDate =
            date !== null
                ? { id: dayjs(date).format(dateFormat), text: dayjs(date).format(dateFormat) }
                : { id: "", text: "" };

        const updateSelectedFilterItem = {
            ...selectedFilterItem,
            target: [updateStartDate, updateEndDate]
        };
        updateFilterItems(updateSelectedFilterItem);
    };
    const handleStartDateChange = (date: Dayjs | null): void => {
        const updateStartDate =
            date !== null
                ? { id: dayjs(date).format(dateFormat), text: dayjs(date).format(dateFormat) }
                : { id: "", text: "" };
        const updateEndDate =
            selectedFilterItem.target[1] !== null ? selectedFilterItem.target[1] : { id: "", text: "" };

        const updateSelectedFilterItem = {
            ...selectedFilterItem,
            target: [updateStartDate, updateEndDate]
        };
        updateFilterItems(updateSelectedFilterItem);
    };
    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
            <DatePicker
                data-testid="startDate-datePicker"
                label={lang.startDate}
                value={
                    selectedFilterItem.target[0] && selectedFilterItem.target[0].id !== ""
                        ? dayjs(selectedFilterItem.target[0].id)
                        : null
                }
                onChange={handleStartDateChange}
                minDate={dayjs("1900/01/01")}
                maxDate={dayjs()}
            />
            <DatePicker
                data-testid="endDate-datePicker"
                label={lang.endDate}
                value={
                    selectedFilterItem.target[1] && selectedFilterItem.target[1].id !== ""
                        ? dayjs(selectedFilterItem.target[1].id)
                        : null
                }
                onChange={handleEndDateChange}
                minDate={
                    selectedFilterItem.target[0] && selectedFilterItem.target[0].id
                        ? dayjs(selectedFilterItem.target[0].id)
                        : dayjs("1900/01/01")
                }
                maxDate={dayjs()}
            />
        </Box>
    );
};

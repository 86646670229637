// PRIMARY COLOR BLUE
export const primaryColor = "#006394";
export const primaryLightColor = "#4D90C4";
export const primaryDarkColor = "#003965";

// SECONDARY COLOR ORANGE
export const secondaryColor = "#E87424";
export const secondaryLightColor = "#FEE6D0";
export const secondaryDarkColor = "#AF4600";
export const hoverSecondaryColor = "rgba(232,116,36,0.08)";

export const textPrimaryColor = "rgba(0,0,0,0.87)"; // Grey
export const filledInputColor = "#F4F4F4"; // Grey
export const disabledInputBackgroundColor = "#E2E2E2"; // Grey
export const disabledUnderlineColor = "#000000"; // Black

export const greenColor = "#46B978";
export const yellowColor = "#FFC107";
export const redColor = "#DC3545";
export const lightGreyColor = "#F0F0F0";
export const greyColor = "#D6D6D6";
export const blueColor = "#2EACE2";
export const orangeColor = "#F48C3C";
export const darkOrange = "#D46317";
export const lightPink = "rgba(220, 53, 69, 0.08)";
export const lightBlueColor = "#99CBDD";
export const darkGreyColor = "#78787A";

//SCALE COLORS
//(reassigning the color => in the future we can change only the colors of the scale without impacting other colors on the application)
export const unfavorableColor = orangeColor;
export const neutralColor = greyColor;
export const favorableColor = blueColor;
export const emptyBarColor = lightGreyColor;
export const responseRateBackground = "rgba(0,0,0,0.08)";

//CORRELATION DIALOG COLORS
export const colorRange065To100 = "#007DAB";
export const colorRange000To049 = "#D5EEF9";
export const colorTopCorrelation = "#003965";
export const colorRange050To064 = "#99CBDD";

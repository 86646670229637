import { Action } from "core/interfaces";
import { AssignAccessState } from "../interfaces";
import { AssignAccessActions } from "../actions";

export const AssignAccessReducer = (state: AssignAccessState, action: Action): AssignAccessState => {
    const { type, payload } = action;
    switch (type) {
        case AssignAccessActions.CHANGE_SEARCH_STRING:
            return {
                ...state,
                searchString: payload.searchString
            };
        case AssignAccessActions.SET_RESTRICTED_USER_LIST:
            return {
                ...state,
                restrictedUserList: payload.restrictedUserList,
                componentStatus: payload.componentStatus
            };
        case AssignAccessActions.SET_COMPONENT_STATUS:
            return {
                ...state,
                componentStatus: payload.componentStatus
            };
        case AssignAccessActions.SET_SELECTED_USER_ID:
            return {
                ...state,
                selectedUserId: payload.userId
            };
        case AssignAccessActions.OPEN_CLOSE_FILTER:
            return {
                ...state,
                isFilterDialogOpen: payload.isFilterDialogOpen
            };
        case AssignAccessActions.SET_CONTACT_FIELD:
            return {
                ...state,
                contactFields: payload.contactFields
            };
        default:
            return {
                ...state
            };
    }
};

import { IRouteProps } from "../interfaces";
import { MainRoutes as MainRoutesEnum } from "../enums";

import Home from "components/home/Home";
import Surveys from "components/surveys/Surveys";
import Admin from "components/admin/admin/Admin";
import Downloads from "components/downloads/Downloads";
import { UserInfo } from "components/admin/users/interface";

export const MainRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        {
            path: "/",
            label: MainRoutesEnum.home,
            routeKey: MainRoutesEnum.home,
            component: Home,
            isHiddenLink: true,
            exact: true,
            isAccessGranted: true //Access to everyone
        },
        {
            path: `/${MainRoutesEnum.createSurveys}`,
            label: MainRoutesEnum.createSurveys,
            routeKey: MainRoutesEnum.createSurveys,
            component: Surveys,
            isAccessGranted: user.isTalentMapAdmin || user.isSurveyCreator
        },
        {
            path: `/${MainRoutesEnum.admin}`,
            component: Admin,
            label: MainRoutesEnum.admin,
            routeKey: MainRoutesEnum.admin,
            isAccessGranted: user.isTalentMapAdmin || user.isSiteAdmin
        },
        {
            path: `/${MainRoutesEnum.downloads}`,
            component: Downloads,
            label: MainRoutesEnum.downloads,
            routeKey: MainRoutesEnum.downloads,
            isAccessGranted: true
        }
    ];
};

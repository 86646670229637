import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { unfavorableColor, neutralColor, favorableColor } from "../../../styles/GlobalStyles";

import { ChartValues } from "./interfaces";
import { useLang } from "core/hooks";
import { Box } from "lib/box";

type Props = {
    chartValues: ChartValues;
};

const barChartStyled = {
    display: "flex",
    flexWrap: "nowrap",
    height: "100%",
    width: "100%"
};

const BarChart = (props: Props): ReactElement => {
    const { lang } = useLang();

    if (props.chartValues.favorable > 100 || props.chartValues.favorable < 0) {
        return (
            <Box sx={barChartStyled}>
                <Box width={"100%"} sx={{ background: neutralColor }}>
                    <Typography align="right" variant="body2" color="secondary" mr={1}>
                        {lang.noOverallAvailable}
                    </Typography>
                </Box>
            </Box>
        );
    }
    return (
        <Box sx={barChartStyled}>
            <Box width={`${props.chartValues.unfavorable}%`} sx={{ background: unfavorableColor }}></Box>
            <Box width={`${props.chartValues.neutral}%`} sx={{ background: neutralColor }}></Box>
            <Box width={`${props.chartValues.favorable}%`} sx={{ background: favorableColor }}>
                <Typography align="right" fontWeight="bold" mr={0.5} fontSize="14px">
                    {props.chartValues.favorable}%
                </Typography>
            </Box>
        </Box>
    );
};

export default BarChart;

export const getKeyFromObject = <T extends Record<string, string | number>>(
    obj: T,
    keyString: string | number
): keyof T => {
    const found = Object.entries(obj).find(entry => entry[1] === keyString);
    if (!found) {
        return "";
    }
    return found[0];
};

import { CustomErrorParam } from "../../interfaces/CustomErrorParam.interface";

export const composeMessageHelper = (errorParam: CustomErrorParam, translation: CustomErrorParam): string => {
    const indexArray: number[] = [];
    const result: string[] = [];

    const searchKey = Object.keys(errorParam)[0];
    if (searchKey in translation) {
        const message = translation[searchKey] as string;

        for (let i = 0; i < message.length; i++) {
            if (message[i] === ":") {
                indexArray.push(i);
            }
        }

        indexArray.forEach((arrIndex, index) => {
            result.push(
                message.substr(index === 0 ? index : indexArray[index - 1] + 1, index === 0 ? arrIndex + 1 : arrIndex)
            );
            result.push(Object.values(Object.values(errorParam)[0])[index].toString());
        });

        if (message.length > indexArray[indexArray.length - 1]) {
            result.push(message.substr(indexArray[indexArray.length - 1] + 1));
        }
    }

    return result.join("");
};

import { ComponentStatus } from "core/enums";
import { DemographicOptions } from "components/reports/heatmap/interface";
import { SurveySection } from "managerPortal/context/interfaces";

export interface CustomizeSnapshotExportState {
    isDirty: boolean;
    componentStatus: ComponentStatus;
    confirmationDialog: boolean;
    isPreviewExportDialogOpen: boolean;
    coverFileLoaded: LoadFile;
    introFileLoaded: LoadFile;
    understandResultFileLoaded: LoadFile;
    surveyResultTransFileLoaded: LoadFile;
    understandHeatmapFileLoaded: LoadFile;
    understandKeyDriverFileLoaded: LoadFile;
    outroFileLoaded: LoadFile;
    compareOptions: DemographicOptions[];
    surveySections: SurveySection[];
}

export type LoadFile = {
    id: number;
    file: File;
    fileName: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    errorMessage: string | undefined;
    isLoaded: boolean;
};

export const fileEmpty = new File([""], "");

export const uploadEmpty: LoadFile = {
    id: 0,
    file: fileEmpty,
    fileName: undefined,
    data: [],
    errorMessage: undefined,
    isLoaded: false
};

export interface SSRLayoutResponse {
    surveyId: number;
    sections: SSRLayoutSection[];
}

export interface SSRLayoutSection {
    id: number;
    fileName: string;
    slideType: SSRLayoutSlideType;
}

export interface SSRLayoutSectionPayload {
    fileName: string;
    slideType: SSRLayoutSlideType;
    file: File;
}

export enum SSRLayoutSlideType {
    CoverFile,
    IntroFile,
    DashboardFile,
    UnderstandResultFile,
    SurveyResultTransFile,
    UnderstandHeatmapFile,
    UnderstandKeyDriverFile,
    OutroFileLoaded
}

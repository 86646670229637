import { QuestionType } from "managerPortal/context/enums";
import { initSurveyQuestion } from "managerPortal/context/initialState";
import { SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";

//filter out unwanted filter values, similar filter function can be found in previous period and other places
//could use a unified solution for this
export const surveyQuestionFilter = (
    sections: SurveySection[],
    fieldOrQuetionId: number
): SurveyItem | SurveyQuestion => {
    if (!fieldOrQuetionId) return initSurveyQuestion;

    const filteredSection = sections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions.filter(
                    (question: SurveyQuestion) =>
                        question.questionType === QuestionType.PickOne ||
                        question.questionType === QuestionType.PickMany
                )
            };
        })
        .filter((section: SurveySection) => section.questions.length);
    let surveyItem: SurveyItem | SurveyQuestion | undefined = undefined;

    filteredSection.forEach((section: SurveySection) => {
        if (!surveyItem) {
            surveyItem = section.questions.find((question: SurveyQuestion) => question.questionId === fieldOrQuetionId);
            return;
        }
    });

    if (!surveyItem) {
        filteredSection.forEach((section: SurveySection) =>
            section.questions.forEach((question: SurveyQuestion) => {
                if (!surveyItem) {
                    surveyItem = question.items.find((item: SurveyItem) => item.fieldId === fieldOrQuetionId);
                    return;
                }
            })
        );
    }

    return surveyItem!;
};

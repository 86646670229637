import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useCommentSummaryStyles = makeStyles(() => ({
    summary: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "0px",
        paddingBottom: "24px",
        overflowY: "auto",
        scrollbarGutter: "stable"
    },
    summaryHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: theme.border.main,
        height: "48px",
        minHeight: "48px",
        paddingLeft: "12px",
        paddingRight: "6px"
    },
    summaryHeaderRight: {
        display: "flex",
        alignItems: "center"
    },
    summaryHeaderRightDetails: {
        display: "flex",
        alignItems: "center"
    },
    suggestionHeader: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "12px",
        borderTop: theme.border.main,
        borderBottom: theme.border.main
    },
    highlightWord: {
        backgroundColor: "#7BEDA7"
    },
    settingIcon: {
        color: theme.palette.primary.main
    },
    pivotGridWrapper: {
        "& >p": {
            fontWeight: 400
        }
    }
}));

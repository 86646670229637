import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { backgroundDarkActive } from "lib/palette";

export const useSelectOrgStyles = makeStyles(() => ({
    organizationSelected: ({ alignTo }: { alignTo: "right" | "left" }) => ({
        display: "flex",
        alignItems: "center",
        background: backgroundDarkActive,
        justifyContent: "flex-start",
        width: 318,
        paddingLeft: 16,
        paddingRight: 16,
        height: "100%",
        float: alignTo,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
            cursor: "pointer"
        }
    })
}));

import React, { PropsWithChildren, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as LoadingLogoOrange } from "../../assets/images/loading/LoadingLogoOrange.svg";
import { ReactComponent as LoadingLogoBlue } from "../../assets/images/loading/LoadingLogoBlue.svg";

type Props = {
    secondary?: boolean;
    styleProps?: React.CSSProperties;
    zIndex?: number;
    borderLeft?: boolean;
};

const useStyles = makeStyles(() => ({
    container: (props: Props) => ({
        position: props.styleProps?.position ? props.styleProps?.position : "relative",
        height: "100%",
        width: props.borderLeft ? "calc(100% - 1px)" : "100%",
        background: props.styleProps?.background ? props.styleProps?.background : "rgba(0, 0, 0, 0.5)",
        zIndex: props.zIndex ? props.zIndex : 2000,
        borderLeft: props.borderLeft ? "1px solid #E3E3E3" : "none"
    }),
    loadingStyle: (props: Props) => ({
        position: "absolute",
        height: 70,
        width: 70,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        zIndex: props.zIndex ? props.zIndex : 2001
    }),
    loadingTitleStyle: (props: Props) => ({
        position: "relative",
        top: "55%",
        textAlign: "center",
        zIndex: props.zIndex ? props.zIndex : 2001
    })
}));

const Loading = (props: PropsWithChildren<Props>): ReactElement => {
    const classes = useStyles(props);
    return (
        <div data-testid="loading" className={classes.container}>
            <div className={classes.loadingStyle}>{props.secondary ? <LoadingLogoOrange /> : <LoadingLogoBlue />}</div>
            <div className={classes.loadingTitleStyle}>{props.children}</div>
        </div>
    );
};

export default Loading;

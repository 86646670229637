import type { FC } from "react";
import React, { memo, ReactElement } from "react";
import { mdiPencil, mdiDelete } from "@mdi/js";
import { makeStyles } from "@mui/styles";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";
import { TitleTranslation } from "./interfaces";
import { IconButtonV4 } from "lib/icon-button";

type StyleProps = {
    height: number;
    width: number;
    overlapped: boolean;
    empty: boolean;
};
export interface BoxProps {
    id: string;
    properties: string;
    type: string;
    height: number;
    width: number;
    translations: TitleTranslation[];
    overlapped: boolean;
    deletePanelItem: (id: string) => void;
    panelItemSelect: (id: string) => void;
}

const useStyles = makeStyles(() => ({
    paper: (styleProps: StyleProps) => ({
        border: styleProps.overlapped
            ? "1px solid #FF0000"
            : styleProps.empty
              ? "1px dashed #7a7a7a"
              : "1px solid #7a7a7a",
        cursor: "move",
        background: "white",
        height: 116 * styleProps.height - 2,
        minHeight: 116 * styleProps.height - 2,
        width: 116 * styleProps.width - 2,
        minWidth: 116 * styleProps.width - 2,
        padding: 0
    }),
    header: {
        paddingTop: 2,
        paddingLeft: 6,
        paddingRight: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    cellHeader: {
        paddingLeft: 6
    },
    iconButton: {
        height: 30
    },
    body: (styleProps: StyleProps) => ({
        display: "flex",
        marginTop: 50 * (styleProps.height - 1),
        justifyContent: "center",
        maxHeight: 50 * styleProps.height,
        overflow: "hidden"
    }),
    cell: (styleProps: StyleProps) => ({
        display: "block",
        width: 100 * styleProps.width,
        maxWidth: 100 * styleProps.width,
        minWidth: 100 * styleProps.width,
        textAlign: "center",
        wordWrap: "break-word"
    })
}));

export const DashboardBox: FC<BoxProps> = memo(function Box({
    id,
    type,
    properties,
    deletePanelItem,
    panelItemSelect,
    height,
    width,
    translations,
    overlapped
}) {
    const classes = useStyles({
        height,
        width,
        overlapped,
        empty: properties === ""
    });
    const { lang, languageCode } = useLang();

    const RenderPanelItemHeader = (id: string): ReactElement => {
        switch (type) {
            case "image":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.image}</Typography>
                    </div>
                );
            case "singleDimensionOrItem":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.singleScore}</Typography>
                    </div>
                );
            case "overallDimension":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.overallDimension}</Typography>
                    </div>
                );
            case "keyDrivers":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.keyDrivers}</Typography>
                    </div>
                );
            case "singleDimensionWithItems":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.singleDimensionWithItems}</Typography>
                    </div>
                );
            case "lookdown":
                return (
                    <div className={classes.cellHeader} data-testid={`dashboard-header-panel-${id}`}>
                        <Typography variant="body2">{lang.lookdown}</Typography>
                    </div>
                );
            default:
                return <></>;
        }
    };

    const getTranslatedTitle = (translations: TitleTranslation[]): string => {
        if (translations.length === 0) return "";
        return translations.filter(trans => trans.languageCode === languageCode)[0]?.name ?? "";
    };

    const RenderPanelItem = (): ReactElement => {
        switch (type) {
            case "image":
                return (
                    <div className={classes.cell}>
                        <Typography variant="body2">
                            {properties !== "" ? JSON.parse(properties)?.fileName : ""}
                        </Typography>
                    </div>
                );
            default:
                return (
                    <div className={classes.cell}>
                        <Typography variant="body2">{getTranslatedTitle(translations)}</Typography>
                    </div>
                );
        }
    };
    return (
        <div className={classes.paper} role={"Box"}>
            <div className={classes.header}>
                {RenderPanelItemHeader(id)}
                <div>
                    <IconButtonV4
                        path={mdiPencil}
                        onClick={(): void => {
                            panelItemSelect(id);
                        }}
                        dataTestid={`panel-${id}-edit-btn`}
                    />
                    <IconButtonV4
                        path={mdiDelete}
                        onClick={(): void => {
                            deletePanelItem(id);
                        }}
                        dataTestid={`panel-${id}-delete-btn`}
                    />
                </div>
            </div>
            <div className={classes.body} data-testid={`panel-${id}-body`}>
                {RenderPanelItem()}
            </div>
        </div>
    );
});

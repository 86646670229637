import React, { useState, useEffect, ReactElement, ChangeEvent } from "react";
import { makeStyles } from "@mui/styles";
import { Help } from "@mui/icons-material";

import DefaultScalesList from "./DefaultScalesList";
import { useLang, useLoading, useOrgChartService } from "core/hooks";
import { Scale, ScaleDefaultUpdateInput, ScaleItemUpdateInput } from "./interfaces";
import { initScale } from "./inits";
import { Answer, SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";
import { initSurveyItem } from "managerPortal/context/initialState";
import SelectExpandablePanel from "components/shared/SelectExpandablePanel";
import { surveySectionsToExpandableData } from "core/helpers";
import { ScaleValue } from "./interfaces";
import { theme } from "lib/theme";
import CustomizationArea from "./CustomizationArea";
import { FunScaleValue, ReportingOptions } from "./enums";
import ConvertSurveyItemArea from "./ConvertSurveyItemArea";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";
import { Tab, Tabs } from "lib/tabs";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    content: {
        display: "flex",
        height: "100%",
        width: "100%"
    },
    scalesList: {
        width: "10%",
        minWidth: 224,
        height: "100%",
        borderRight: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
    },
    surveySections: {
        width: "40%",
        minWidth: 440,
        height: "100%",
        borderRight: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
    },
    surveySectionsList: {
        height: "100%",
        flexGrow: 1
    },
    defaultScaleList: {
        maxHeight: "100%"
    },
    surveyItemsList: {
        flexGrow: 1,
        overflow: "auto"
    },
    customizationArea: {
        boxSizing: "border-box",
        width: "50%",
        height: "100%",
        overflowY: "auto"
    },
    surveySectionsHeader: {
        display: "flex",
        alignItems: "center",
        height: 48,
        boxSizing: "border-box",
        minHeight: 48,
        paddingLeft: theme.spacing(3)
    },
    surveySectionsHeaderTypography: {
        color: theme.palette.text.secondary
    },
    tabsStyles: {
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "row-reverse",
        textAlign: "center"
    },
    tooltip: {
        marginRight: theme.spacing(3)
    },
    tooltipTextLine: {
        marginBottom: theme.spacing(1)
    }
}));

type Props = {
    surveySelectedId: number;
};

export enum TabOptions {
    scales,
    items
}
interface State {
    defaultScaleSelected: Scale;
    surveyItemSelected: SurveyItem;
    surveyScales: Scale[];
    surveySections: SurveySection[];
    isOverriddenScale: boolean;
    tabValue: TabOptions;
}

const initialState: State = {
    defaultScaleSelected: initScale,
    surveyItemSelected: initSurveyItem,
    surveyScales: [],
    surveySections: [],
    isOverriddenScale: false,
    tabValue: TabOptions.scales
};

const CustomScales = (props: Props): ReactElement => {
    const classes = useStyles();
    const orgChartService = useOrgChartService();
    const { user } = useUser();
    const { lang } = useLang();
    const { setLoading, isLoading } = useLoading();
    const [state, setState] = useState<State>(initialState);

    useEffect(() => {
        setLoading(true);
        orgChartService
            .customScaleInit(props.surveySelectedId, user.settings.languageCode)
            .then(({ surveyScales, surveySections }) => {
                setState((prevState: State): State => {
                    return {
                        ...prevState,
                        surveyScales: surveyScales,
                        surveySections: surveySections
                    };
                });
                handleSelectDefaultScale(surveyScales[0]);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.surveySelectedId, orgChartService, user.settings.languageCode]);

    const updateSurveySections = (surveyItem: SurveyItem): SurveySection[] => {
        const updatedSurveySections = state.surveySections.map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions.map((question: SurveyQuestion) => {
                    return {
                        ...question,
                        items: question.items.map((item: SurveyItem) => {
                            if (item.fieldId === surveyItem.fieldId) {
                                return {
                                    ...surveyItem,
                                    answers: surveyItem.answers.map((answer: Answer) => {
                                        return {
                                            ...answer
                                        };
                                    })
                                };
                            }
                            return {
                                ...item,
                                answers: item.answers.map((answer: Answer) => {
                                    return {
                                        ...answer
                                    };
                                })
                            };
                        })
                    };
                })
            };
        });
        return updatedSurveySections;
    };

    const findSurveyItem = (itemId: string): SurveyItem => {
        let itemFound = initSurveyItem;
        state.surveySections.forEach((section: SurveySection) => {
            section.questions.forEach((question: SurveyQuestion) => {
                const findItem = question.items.find((item: SurveyItem) => item.fieldId === +itemId);
                if (findItem) {
                    itemFound = findItem;
                }
            });
        });
        return itemFound;
    };

    const getDefaultScale = (defaultScaleId: number): Scale => {
        const defaultScale = state.surveyScales.find((scale: Scale) => scale.id === defaultScaleId);
        return defaultScale ?? initScale;
    };

    const handleSelectSurveyItem = (itemId: string): void => {
        let updateItem = findSurveyItem(itemId);
        const defaultScale = getDefaultScale(updateItem.scaleDefaultId);
        const isDefaultOverriddenScale = !state.surveyScales.some((s: Scale) => s.id === updateItem.answers[0].scaleId);

        if (!isDefaultOverriddenScale) {
            updateItem = {
                ...updateItem,
                answers: updateItem.answers.map((answer: Answer) => {
                    const updateValue = defaultScale.scaleValues.find(
                        (scaleValue: ScaleValue) => scaleValue.answerValue === answer.answerValue
                    );
                    return {
                        ...answer,
                        outputValue: updateValue ? updateValue.outputValue : FunScaleValue.Ignore
                    };
                })
            };
        }
        setState((prevState: State): State => {
            return {
                ...prevState,
                surveyItemSelected: updateItem,
                isOverriddenScale: isDefaultOverriddenScale,
                defaultScaleSelected: initScale,
                tabValue: TabOptions.scales
            };
        });
    };

    const handleSelectDefaultScale = (scale: Scale): void => {
        setState((prevState: State): State => {
            return {
                ...prevState,
                defaultScaleSelected: scale,
                surveyItemSelected: initSurveyItem,
                tabValue: TabOptions.scales
            };
        });
    };

    const handleReportingOptionsChange = (key: ReportingOptions, newKeyValue: boolean): void => {
        let updateSelectedItem = { ...state.surveyItemSelected };

        if (key === ReportingOptions.excludeFromOverallScore) {
            updateSelectedItem = {
                ...updateSelectedItem,
                isIgnored: false,
                isRemovedOverallScore: true
            };

            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyItemSelected: updateSelectedItem,
                    surveySections: updateSurveySections(updateSelectedItem)
                };
            });
            orgChartService
                .updateItemRemoveFromOverall(+updateSelectedItem.itemId.substring(1), newKeyValue)
                .then(() => fetchScalesAndSections());
        }

        if (key === ReportingOptions.isHiddenForManagers) {
            updateSelectedItem = {
                ...updateSelectedItem,
                isHiddenForManagers: newKeyValue
            };

            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyItemSelected: updateSelectedItem,
                    surveySections: updateSurveySections(updateSelectedItem)
                };
            });

            orgChartService
                .hideItemForManagers(props.surveySelectedId, +updateSelectedItem.itemId.substring(1), newKeyValue)
                .then(() => fetchScalesAndSections());
        }
        if (key === ReportingOptions.removeFromReports) {
            updateSelectedItem = {
                ...updateSelectedItem,
                isIgnored: true,
                isRemovedOverallScore: false
            };

            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyItemSelected: updateSelectedItem,
                    surveySections: updateSurveySections(updateSelectedItem)
                };
            });

            orgChartService
                .updateItemIgnoreOption(
                    props.surveySelectedId,
                    +updateSelectedItem.itemId.substring(1),
                    updateSelectedItem.isIgnored
                )
                .then(() => fetchScalesAndSections());
        }

        if (key === ReportingOptions.isOverridden) {
            if (newKeyValue) {
                updateSelectedItem = {
                    ...updateSelectedItem,
                    answers: updateSelectedItem.answers.map((answer: Answer) => {
                        return {
                            ...answer,
                            scaleId: newKeyValue ? initScale.id : updateSelectedItem.scaleDefaultId
                        };
                    })
                };
            }

            if (!newKeyValue) {
                const surveyScaleDefault = state.surveyScales.find(
                    (scale: Scale) => scale.id === state.surveyItemSelected.scaleDefaultId
                );
                if (surveyScaleDefault) {
                    updateSelectedItem = {
                        ...updateSelectedItem,
                        answers: updateSelectedItem.answers.map((answer: Answer) => {
                            const scaleValueFound = surveyScaleDefault.scaleValues.find(
                                (scaleValue: ScaleValue) => scaleValue.answerValue === answer.answerValue
                            );
                            return {
                                ...answer,
                                scaleId: surveyScaleDefault.id,
                                outputValue: scaleValueFound ? scaleValueFound.outputValue : answer.outputValue
                            };
                        })
                    };
                }

                orgChartService
                    .revertItemScale(+updateSelectedItem.itemId.substring(1))
                    .then(() => fetchScalesAndSections());
            }

            const updatedSurveySections = updateSurveySections(updateSelectedItem);
            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyItemSelected: updateSelectedItem,
                    surveySections: updatedSurveySections,
                    isOverriddenScale: newKeyValue
                };
            });
        }

        if (key === ReportingOptions.includeInReports) {
            updateSelectedItem = {
                ...updateSelectedItem,
                isIgnored: false,
                isRemovedOverallScore: false
            };
            const updatedSurveySections = updateSurveySections(updateSelectedItem);
            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyItemSelected: updateSelectedItem,
                    surveySections: updatedSurveySections
                };
            });
            orgChartService
                .updateItemIgnoreOption(
                    props.surveySelectedId,
                    +updateSelectedItem.itemId.substring(1),
                    updateSelectedItem.isIgnored
                )
                .then(() => fetchScalesAndSections());
        }

        if (key === ReportingOptions.useNominalScale) {
            const updateSurveyScales = state.surveyScales;
            const updateScale = { ...state.defaultScaleSelected, isFunScale: !newKeyValue };
            const indexSurveyScale = updateSurveyScales.findIndex((scale: Scale) => scale.id === updateScale.id);
            let updateSurveySection = state.surveySections;

            updateSurveyScales[indexSurveyScale] = updateScale;

            //Items with Nominal Scale can't be set as isRemovedOverallScore
            if (newKeyValue) {
                updateSurveySection = updateSurveySection.map((section: SurveySection) => {
                    return {
                        ...section,
                        questions: section.questions.map((question: SurveyQuestion) => {
                            return {
                                ...question,
                                items: question.items.map((item: SurveyItem) => {
                                    if (
                                        updateScale.id === item.scaleDefaultId &&
                                        item.answers.some((answer: Answer) => answer.scaleId === updateScale.id)
                                    ) {
                                        return {
                                            ...item,
                                            isRemovedOverallScore: false,
                                            answers: item.answers.map((answer: Answer) => {
                                                return {
                                                    ...answer
                                                };
                                            })
                                        };
                                    }
                                    return {
                                        ...item
                                    };
                                })
                            };
                        })
                    };
                });
            }

            setState((prevState: State): State => {
                return {
                    ...prevState,
                    surveyScales: updateSurveyScales,
                    defaultScaleSelected: updateScale,
                    surveySections: updateSurveySection
                };
            });

            const updateScaleInput: ScaleDefaultUpdateInput = {
                surveyId: props.surveySelectedId,
                itemId: updateScale.id,
                scaleValues: updateSurveyScales[indexSurveyScale].scaleValues,
                isFunScale: updateScale.isFunScale
            };

            orgChartService.updateDefaultScale(updateScaleInput).then(() => fetchScalesAndSections());
        }
    };

    const funScaleValueChange = (updateSurveyItem: SurveyItem): void => {
        const updatedSurveySections = updateSurveySections(updateSurveyItem);
        setState((prevState: State): State => {
            return {
                ...prevState,
                surveyItemSelected: updateSurveyItem,
                surveySections: updatedSurveySections
            };
        });
        const updateScale: ScaleItemUpdateInput = {
            surveyId: props.surveySelectedId,
            itemId: +updateSurveyItem.itemId.substring(1),
            scaleValues: updateSurveyItem.answers.map((answer: Answer): ScaleValue => {
                return {
                    answerDescription: answer.description,
                    answerValue: answer.answerValue,
                    id: answer.answerId,
                    outputValue: answer.outputValue,
                    scaleId: answer.scaleId
                };
            })
        };
        orgChartService.updateItemScale(updateScale).then(() => fetchScalesAndSections());
    };

    const tooltipText = (
        <div>
            {lang.customScaleHelp.map((l: string, index: number) => (
                <div key={index} className={classes.tooltipTextLine}>
                    {l}
                </div>
            ))}
        </div>
    );

    const defaultScaleValueChange = (updateScale: Scale): void => {
        const updateSurveyScales = state.surveyScales;
        const indexSurveyScale = updateSurveyScales.findIndex((scale: Scale) => scale.id === updateScale.id);
        updateSurveyScales[indexSurveyScale] = updateScale;

        setState((prevState: State): State => {
            return {
                ...prevState,
                surveyScales: updateSurveyScales,
                defaultScaleSelected: updateScale
            };
        });

        const updateScaleInput: ScaleDefaultUpdateInput = {
            surveyId: props.surveySelectedId,
            itemId: updateScale.id,
            scaleValues: updateSurveyScales[indexSurveyScale].scaleValues,
            isFunScale: updateScale.isFunScale
        };

        orgChartService.updateDefaultScale(updateScaleInput).then(() => fetchScalesAndSections());
    };

    const handleTabChange = (_: ChangeEvent<unknown>, newValue: TabOptions): void => {
        setState({ ...state, tabValue: newValue });
    };

    const fetchScalesAndSections = (): void => {
        orgChartService
            .customScaleInit(props.surveySelectedId, user.settings.languageCode)
            .then(({ surveyScales, surveySections }) => {
                setState((prevState: State): State => {
                    return {
                        ...prevState,
                        surveyScales: surveyScales,
                        surveySections: surveySections
                    };
                });
            });
    };
    const fetchConvertSurveyItem = (languageCode: string): void => {
        orgChartService
            .customScaleInit(props.surveySelectedId, user.settings.languageCode)
            .then(({ surveyScales, surveySections }) => {
                let itemFound = initSurveyItem;
                surveySections.forEach((section: SurveySection) => {
                    section.questions.forEach((question: SurveyQuestion) => {
                        const findItem = question.items.find(
                            (item: SurveyItem) => item.itemId === state.surveyItemSelected.itemId
                        );
                        if (findItem) {
                            itemFound = findItem;
                        }
                    });
                });
                const updatedTranslation = itemFound.translations.find(t => t.languageCode === languageCode);
                if (updatedTranslation) {
                    const updatedSurveyItemSelected = {
                        ...state.surveyItemSelected,
                        translations: state.surveyItemSelected.translations.map(translation =>
                            translation.languageCode === languageCode ? updatedTranslation : translation
                        )
                    };
                    setState((prevState: State): State => {
                        return {
                            ...prevState,
                            surveyScales: surveyScales,
                            surveySections: surveySections,
                            surveyItemSelected: updatedSurveyItemSelected
                        };
                    });
                }
                setLoading(false);
            });
    };

    const handleConvertSurveyItemCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setLoading(true);
        const languageCode = e.target.value as string;
        const isChecked = e.target.checked as boolean;
        if (isChecked === true) {
            const index = state.surveyItemSelected.translations.findIndex(
                translation => translation.languageCode === languageCode
            );
            const newDemographicField = state.surveyItemSelected.translations[index].title;
            orgChartService
                .createItemContactField(
                    +state.surveyItemSelected.itemId.substring(1),
                    languageCode,
                    newDemographicField
                )
                .then(() => {
                    orgChartService
                        .updateItemContactField(
                            +state.surveyItemSelected.itemId.substring(1),
                            languageCode,
                            newDemographicField
                        )
                        .then(() => fetchConvertSurveyItem(languageCode));
                });
        } else {
            orgChartService
                .deleteItemContactField(+state.surveyItemSelected.itemId.substring(1), languageCode)
                .then(() => fetchConvertSurveyItem(languageCode));
        }
    };

    const handleConvertSurveyItemFieldChange = (e: React.ChangeEvent<HTMLInputElement>, languageCode: string): void => {
        const updatedSurveyItemSelected = { ...state.surveyItemSelected };
        const index = updatedSurveyItemSelected.translations.findIndex(
            translation => translation.languageCode === languageCode
        );
        if (updatedSurveyItemSelected.translations[index].contactFieldName !== null) {
            updatedSurveyItemSelected.translations[index].contactFieldName = e.target.value;
        } else {
            updatedSurveyItemSelected.translations[index].title = e.target.value;
        }
        setState((prevState: State): State => {
            return {
                ...prevState,
                surveyItemSelected: updatedSurveyItemSelected
            };
        });
    };

    const handleConvertSurveyItemOnBlur = (languageCode: string): void => {
        const updatedSurveyItemSelected = { ...state.surveyItemSelected };
        const index = updatedSurveyItemSelected.translations.findIndex(
            translation => translation.languageCode === languageCode
        );
        if (
            updatedSurveyItemSelected.translations[index].contactFieldName !== null &&
            updatedSurveyItemSelected.translations[index].contactFieldName !== ""
        ) {
            orgChartService
                .updateItemContactField(
                    +state.surveyItemSelected.itemId.substring(1),
                    languageCode,
                    updatedSurveyItemSelected.translations[index].contactFieldName
                )
                .then(() => fetchConvertSurveyItem(languageCode));
        }
    };

    if (isLoading) return <></>;

    return (
        <div className={classes.content}>
            <div className={classes.scalesList}>
                <div className={classes.defaultScaleList}>
                    <DefaultScalesList
                        defaultScaleSelected={state.defaultScaleSelected}
                        surveyScales={state.surveyScales}
                        onSelect={handleSelectDefaultScale}
                    />
                </div>
            </div>
            <div className={classes.surveySections}>
                <div className={classes.surveySectionsHeader}>
                    <Typography className={classes.surveySectionsHeaderTypography} variant="body1">
                        {lang.customizeIndividualQuestions}
                    </Typography>
                </div>
                <Divider />
                <div className={classes.surveySectionsList} data-testid="expandable-section-list">
                    <SelectExpandablePanel
                        rawData={surveySectionsToExpandableData(state.surveySections)}
                        onItemSelected={handleSelectSurveyItem}
                        selectedId={state.surveyItemSelected.fieldId}
                        isCondensed
                        isShowingFlag
                    />
                </div>
            </div>
            <div className={classes.customizationArea}>
                {state.surveyItemSelected !== initSurveyItem && (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Tabs value={state.tabValue} onChange={handleTabChange}>
                            <Tab label={lang.scales} data-testid="tab-handler-scales" />
                            <Tab label={lang.fieldsTab} data-testid="tab-handler-fields" />
                        </Tabs>
                        {state.tabValue === TabOptions.scales && (
                            <Tooltip title={tooltipText} style={{ marginRight: "68px" }}>
                                <IconButton aria-label={lang.help}>
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        )}
                        {state.tabValue === TabOptions.items && (
                            <Tooltip title={lang.convertSurveyItemHelper} style={{ marginRight: "68px" }}>
                                <IconButton aria-label={lang.help}>
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )}
                {state.surveyItemSelected !== initSurveyItem && <Divider />}
                {state.tabValue === TabOptions.scales && state.defaultScaleSelected !== initScale && (
                    <CustomizationArea
                        defaultScale={state.defaultScaleSelected}
                        updateDefaultScale={defaultScaleValueChange}
                        onReportingOptionsChange={handleReportingOptionsChange}
                    />
                )}
                {state.tabValue === TabOptions.scales && state.surveyItemSelected !== initSurveyItem && (
                    <CustomizationArea
                        surveyItemSelected={state.surveyItemSelected}
                        updateSurveyItem={funScaleValueChange}
                        isOverridden={state.isOverriddenScale}
                        onReportingOptionsChange={handleReportingOptionsChange}
                        defaultScale={getDefaultScale(state.surveyItemSelected.scaleDefaultId)}
                    />
                )}
                {state.tabValue === TabOptions.items && (
                    <ConvertSurveyItemArea
                        customizedSurveyItem={state.surveyItemSelected.translations}
                        surveySelectedId={props.surveySelectedId}
                        handleChangeField={handleConvertSurveyItemFieldChange}
                        handleCheckboxChange={handleConvertSurveyItemCheckboxChange}
                        handleFieldOnBlur={handleConvertSurveyItemOnBlur}
                    />
                )}
            </div>
        </div>
    );
};

export default CustomScales;

import { makeStyles } from "@mui/styles";

import type { HeatmapStyleProps } from "./types";
import { theme } from "lib/theme";

export const useHeatmapGridStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        position: "relative",
        boxSizing: "border-box"
    },
    tableContainer: {
        width: "100%",
        overflow: "auto",
        height: "calc(100% - 73px)",
        boxSizing: "border-box",
        zIndex: 11,
        position: "absolute",
        top: 0,
        left: 0
    },
    gridTable: (styleProps: HeatmapStyleProps) => {
        return {
            height: styleProps.height + 302,
            width: styleProps.width,
            borderBottom: theme.border.main,
            borderCollapse: "collapse",
            display: "block",
            whiteSpace: "nowrap",
            position: "absolute",
            top: 0,
            left: 0
        };
    },
    col_rowHeader: {
        width: 320,
        minWidth: 320,
        borderBottom: theme.border.main
    },
    rowHeaderCell: {
        padding: theme.spacing(1),
        width: 322,
        maxWidth: 322,
        minWidth: 322,
        whiteSpace: "normal",
        boxSizing: "border-box",
        height: 50
    },
    rowHeaderCell_overall: {
        paddingLeft: 8,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        width: 322,
        minWidth: 322,
        backgroundColor: theme.palette.background.default,
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 600
        }
    },

    rowHeaderCell_textWrap: {
        width: 290,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "& >p": { display: "inline", width: "95%" }
    },
    floatingRowHeaderCell_textWrap: {
        width: 290,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "& >p": { display: "inline", width: "95%" }
    },
    thead: {
        position: "relative",
        overflowX: "auto",
        width: "100%",
        height: 270,
        overflowY: "scroll",
        whiteSpace: "normal"
    },
    tbody: {
        position: "relative",
        width: "100%",
        overflowY: "auto",
        overflowX: "auto",
        whiteSpace: "normal"
    },
    col_dataCellHeader: { width: 50, boxSizing: "border-box" },
    tableRow: {
        boxSizing: "border-box",
        height: 50,
        borderBottom: theme.border.main,
        borderLeft: "none",
        borderRight: "none !important"
    },
    tableRow_th: {
        height: 270,
        verticalAlign: "bottom",
        textAlign: "left",
        lineHeight: 1,
        zIndex: 15,
        position: "sticky",
        top: 0
    },
    tableRow_th_vertical: (props: HeatmapStyleProps) => {
        return {
            height: props.isScrollingDown ? 271 : 270,
            boxSizing: "border-box",
            verticalAlign: "bottom",
            borderRight: theme.border.main,
            boxShadow: "inset 0 -2px 1px -2px gray",
            backgroundColor: "white !important",
            textAlign: "left",
            lineHeight: 1,
            zIndex: 15,
            position: "sticky",
            top: -1
        };
    },
    tableRow_th_div: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        width: 50
    },
    tableRow_th_div_vertical: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        backgroundColor: "white",
        width: 50
    },
    tableRow_th_vertical_res: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        backgroundColor: "white",
        width: 50,
        borderRight: "none"
    },
    tableAction: {
        borderRight: theme.border.main,
        position: "sticky"
    },
    tableRow_th_div_children: {
        boxSizing: "border-box",
        position: "sticky",
        width: 50,
        zIndex: 15
    },
    tableRow_th_div_children_vertical: {
        boxSizing: "border-box",
        position: "sticky",
        width: 50,
        zIndex: 15,
        backgroundColor: "white",
        "& > p": { color: "#333333 !important", lineHeight: 1.2 }
    },
    tableRow_th_div_span: {
        borderBottom: theme.border.main,
        width: 280,
        transformOrigin: "bottom left",
        transform: "translateX(70px) rotate(-45deg)",
        "& > p": { display: "inline" },
        "& > button": { display: "inline" },

        backgroundColor: "white"
    },
    iconButton_wraper: {
        display: "block"
    },
    tableRow_th_div_span_vertical: {
        display: "block",
        width: 220,
        transformOrigin: "bottom left",
        transform: "translateX(36px) rotate(270deg)",
        "& >p": { display: "inline", width: "95%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    tableRow_th_div_span_vertical_children: {
        display: "flex",
        width: 250,
        marginBottom: "6px",
        transformOrigin: "bottom left",
        transform: "translateX(45px) rotate(270deg)",
        backgroundColor: "white",
        overflow: "hidden"
    },
    iconButton: {
        position: "relative",
        marginLeft: "6px"
    },
    dataCell: {
        boxSizing: "border-box",
        height: 50,
        width: 50,
        textAlign: "center",
        border: theme.border.main
    },
    dataCellSection: {
        boxSizing: "border-box",
        height: 50,
        width: 50,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        border: theme.border.main,
        "& >p": {
            fontWeight: 500
        }
    },
    cell_overall: {
        backgroundColor: theme.palette.background.default,
        "& >p": {
            fontWeight: 500
        }
    },
    dataCell_tooltip: { color: "white" },
    cell_response: {
        backgroundColor: theme.palette.background.default
    },

    cell_unfav_25: {
        backgroundColor: "rgba(244,140,60,0.25)"
    },

    cell_unfav_50: {
        backgroundColor: "rgba(244,140,60,0.5)"
    },

    cell_unfav_75: {
        backgroundColor: "rgba(244,140,60,0.75)"
    },

    cell_unfav: {
        backgroundColor: "rgb(244,140,60)"
    },
    cell_neu: {
        backgroundColor: "#FFFFFF"
    },

    cell_fav_25: {
        backgroundColor: "rgba(46,172,225,0.25)"
    },

    cell_fav_50: {
        backgroundColor: "rgba(46,172,225,0.5)"
    },

    cell_fav_75: {
        backgroundColor: "rgba(46,172,225,0.75)"
    },

    cell_fav: {
        backgroundColor: "rgb(46,172,225)"
    },
    floatingRowHeader: {
        width: 372,
        position: "sticky",
        left: 0,
        backgroundColor: "white",
        borderRight: theme.border.main,
        zIndex: 30
    },
    floatingOverallRowContainer: (styleProps: HeatmapStyleProps) => {
        return {
            position: "sticky",
            marginLeft: 374,
            top: 270,
            zIndex: 20,
            width: styleProps.floatingRowWidth,
            display: "flex",
            height: 50
        };
    },

    fixedOverallRes: (styleProps: HeatmapStyleProps) => {
        return {
            position: "fixed",
            left: 2,
            top: styleProps.showFilterTags ? 527 : 476,
            zIndex: 40,
            width: 376,
            display: "flex",
            boxSizing: "border-box"
        };
    },
    fixedFloatingOverallCell: {
        height: 50,
        width: 53,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        borderLeft: theme.border.main,
        borderTop: theme.border.main,
        borderRight: theme.border.main,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 500
        }
    },
    floatingOverallCell: {
        height: 50,
        width: 53,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        borderLeft: theme.border.main,
        borderTop: theme.border.main,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 500
        }
    },

    floatingRowHeaderCell: {
        width: 322,
        minHeight: 50,
        boxSizing: "border-box",
        borderTop: theme.border.main,
        paddingLeft: 8,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    rowHeaderText: { lineHeight: "1.15 !important" },
    rowHeaderText_overall: { lineHeight: "1.15 !important" },
    floatingRowHeaderCellOverall: {
        width: 322,
        minHeight: 50,
        boxSizing: "border-box",
        borderTop: theme.border.main,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    floatingRowGroup: { display: "flex", justifyContent: "center", width: 375 },
    floatingResCountCell: {
        width: 52.5,
        minHeight: 50,
        backgroundColor: theme.palette.background.default,
        borderTop: theme.border.main,
        borderLeft: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center"
    },
    floatingResCountCellOverall: {
        width: 53,
        minHeight: 50,
        backgroundColor: theme.palette.background.default,
        borderTop: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        fontWeight: 500
    },
    placeholder: (styleProps: HeatmapStyleProps) => {
        return {
            height: styleProps.isPreviousPeriodSelected ? 270 : 320,
            width: 372,
            backgroundColor: "white"
        };
    },
    placeholderShort: {
        height: 270,
        width: 372,
        borderBottom: theme.border.main,
        backgroundColor: "white"
    },
    filterPlaceHolder: {
        height: 50,
        width: 372,
        borderBottom: theme.border.main,
        backgroundColor: "white"
    },
    floatingAction: (styleProps: HeatmapStyleProps) => {
        return {
            position: "fixed",
            width: 374,
            height: 270,
            backgroundColor: "white",
            borderRight: theme.border.main,
            borderBottom: styleProps.isPreviousPeriodSelected ? theme.border.main : "none",
            borderRadius: 5,
            left: 2,
            top: styleProps.showFilterTags ? 256 : 206,
            zIndex: 40,
            display: "flex",
            justifyContent: "center"
        };
    },

    responseHeader: (styleProps: HeatmapStyleProps) => {
        return {
            width: 52,
            height: 270,
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.default,
            borderLeft: theme.border.main,
            borderBottom: styleProps.isPreviousPeriodSelected ? "none" : theme.border.main,
            textAlign: "start",
            "& >p": {
                transformOrigin: "bottom",
                transform: "translate(0,-11px) rotate(270deg)",
                position: "absolute",
                bottom: 43
            }
        };
    }
}));

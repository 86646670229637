import React, { useState, ReactElement, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import AdminLayout from "../shared/AdminLayout";
import InfiniteTable from "../../shared/infiniteTable/InfiniteTable";
import EmptyPage from "../../shared/EmptyPage";
import { usersQuery } from "api/queries";
import { useLang, useLoading, useAdminService } from "core/hooks";
import { Translation } from "core/languages/interfaces";
import { Column } from "../../shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "../../shared/infiniteTable/enums";
import { UsersRoutes } from "routes/enums";
import { copyArrayWithObjects } from "core/helpers";
import noActiveUser from "assets/images/emptyPages/NoActiveUser.svg";
import noDeactivatedUser from "assets/images/emptyPages/NoDeactivatedUser.svg";
import { UserInput, UserInfo, AdminUserList, DeactivateAdminUserList } from "./interface";
import Error500 from "../../errorPage/Error500";
import { isStringEmptyOrSpaces } from "core/helpers";
import { Tab, Tabs } from "lib/tabs";
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";
import dayjs from "dayjs";
import { Snackbar } from "lib/snackbar";

const useStyles = makeStyles(() => ({
    listContainer: {
        height: "calc(100% - 48px)",
        borderTop: theme.border.main
    },
    tabs: {
        display: "flex",
        alignItems: "flex-end",
        height: 48,
        minHeight: 48,
        maxHeight: 48
    }
}));

const activeUsertableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.name,
            minWidth: 300,
            maxWidth: 320,
            width: "100%",
            sortable: true,
            order: TableSort.asc,
            cellType: CellType.link,
            linkTo: `/${UsersRoutes.users}/${UsersRoutes.editUser}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            minWidth: 300,
            maxWidth: 360,
            sortable: true,
            cellType: CellType.plainText,
            isResizable: true
        },
        {
            dataKey: DataKey.created,
            label: lang.dateCreated,
            width: 200,
            minWidth: 180,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.permission,
            label: lang.permission,
            width: 200,
            minWidth: 200,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.lastActivity,
            label: lang.lastAccess,
            width: 200,
            minWidth: 200,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.resetPassWord,
            label: lang.resetPassWord,
            width: 200,
            cellType: CellType.clickableButton,
            truncate: true,
            variant: "text",
            hideCol: true
        },
        {
            dataKey: DataKey.deactivate,
            label: lang.deactivate,
            width: 200,
            cellType: CellType.clickableButton,
            truncate: true,
            variant: "deleteText",
            hideCol: true
        }
    ];
};

const deactivateUsertableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.name,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            order: TableSort.asc,
            cellType: CellType.link,
            linkTo: `/${UsersRoutes.users}/${UsersRoutes.editUser}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            minWidth: 400,
            maxWidth: 500,
            sortable: true,
            cellType: CellType.plainText,
            isResizable: true
        },
        {
            dataKey: DataKey.created,
            label: lang.dateCreated,
            width: 240,
            minWidth: 240,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.permission,
            label: lang.permission,
            width: 240,
            minWidth: 240,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.lastActivity,
            label: lang.lastAccess,
            width: 240,
            minWidth: 240,
            sortable: true,
            cellType: CellType.plainText
        }
    ];
};

const AdminUsers = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const classes = useStyles();
    const [searchStr, setSearchStr] = useState("");
    const [tabSelected, setTabNumber] = useState(0);
    const [userList, setUserList] = useState<UserInput[]>([]);
    const [activeUsers, setActiveUsers] = useState<AdminUserList[]>([]);
    const [deactivatedUsers, setDeactivatedUsers] = useState<DeactivateAdminUserList[]>([]);
    const activeUsersTableColumns = copyArrayWithObjects(activeUsertableColumnsList(lang));
    const deactivatedUsersTableColumns = copyArrayWithObjects(deactivateUsertableColumnsList(lang));
    const buttonLink = `/${UsersRoutes.users}/${UsersRoutes.addUser}`;
    const { user } = useUser();
    const { setLoading } = useLoading();
    const adminService = useAdminService();
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });

    const activeUsersSearchFilter = (users: AdminUserList[]): AdminUserList[] => {
        let filtered = [...users];
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (user: AdminUserList) =>
                    user.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.permission.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.lastActivity.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.created.toLocaleLowerCase().includes(searchStr.toLowerCase())
            );
        }
        return filtered;
    };

    const deactivatedUsersSearchFilter = (users: DeactivateAdminUserList[]): DeactivateAdminUserList[] => {
        let filtered = [...users];
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (user: DeactivateAdminUserList) =>
                    user.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.permission.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.lastActivity.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.created.toLocaleLowerCase().includes(searchStr.toLowerCase())
            );
        }
        return filtered;
    };

    const [refreshUsers, { error, loading }] = useLazyQuery(usersQuery, {
        variables: { orgId: user.settings.selectedOrganization.id },
        onCompleted: (data: { users: UserInput[] }): void => {
            setUserList([...data.users]);
        }
    });

    const onSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        setTabNumber(newValue);
    };

    const resetPassword = (id: number): void => {
        adminService
            .resetPassword(id)
            .then(() => {
                setSnackbar({ isOpen: true, message: lang.passwordReset });
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
            });
    };

    const deactivateAdminUser = (id: number): void => {
        adminService
            .deactivateAdminUser(id)
            .then(() => {
                refreshUsers();
                setSnackbar({ isOpen: true, message: lang.userHasBeenDeactivated });
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
            });
    };

    const handleButtonClick = (id: number, datakey: string): void => {
        if (datakey === DataKey.resetPassWord || datakey === DataKey.resend) {
            resetPassword(id);
        } else if (datakey === DataKey.deactivate) {
            deactivateAdminUser(id);
        }
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const setLoadingToFalse = useCallback(() => {
        setLoading(false);
    }, [setLoading]);

    const setLoadingToTrue = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {
        if (user.settings.selectedOrganization.id !== -1) {
            refreshUsers();
        }
    }, [user.settings.selectedOrganization.id, refreshUsers, languageCode]);

    useEffect(() => {
        setActiveUsers(
            userList
                .filter((user: UserInfo) => user.active)
                .map(
                    (user: UserInfo): AdminUserList => ({
                        id: user.id,
                        email: user.email,
                        name: user.name,
                        created: user.created,
                        permission: user.isTalentMapAdmin
                            ? "TalentMap Admin"
                            : user.isSiteAdmin
                              ? "Site Admin"
                              : user.isRestricted
                                ? "Restricted Report Viewer"
                                : "Report Viewer",
                        lastActivity: user.lastActivity
                            ? `${dayjs(user.lastActivity).format("YYYY-MM-DD HH:mm")} UTC`
                            : lang.never,
                        resetPassWord: user.lastActivity ? lang.resetPassWord : lang.resend,
                        deactivate: lang.deactivate
                    })
                )
        );
        setDeactivatedUsers(
            userList
                .filter((user: UserInfo) => !user.active)
                .map(
                    (user: UserInfo): DeactivateAdminUserList => ({
                        id: user.id,
                        email: user.email,
                        name: user.name,
                        created: user.created,
                        permission: user.isTalentMapAdmin
                            ? lang.talentMapAdmin
                            : user.isSiteAdmin
                              ? lang.siteAdmin
                              : user.isRestricted
                                ? lang.restrictedReportViewer
                                : lang.reportViewer,
                        lastActivity: user.lastActivity
                            ? `${dayjs(user.lastActivity).format("YYYY-MM-DD HH:mm")} UTC`
                            : lang.never
                    })
                )
        );
    }, [JSON.stringify(userList), languageCode]);

    useEffect(() => {
        setLoadingToTrue();

        if (!loading) {
            setLoadingToFalse();
        }
    }, [loading, setLoadingToFalse, setLoadingToTrue]);

    if (error) return <Error500 />;
    if (loading) return <></>;

    return (
        <>
            <AdminLayout
                onSearchChange={onSearchChange}
                searchTerm={searchStr}
                addUserLabel={lang.addUser}
                buttonLink={buttonLink}
                showButton={true}
            >
                <div className={classes.tabs}>
                    <Tabs
                        value={tabSelected}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={handleTabChange}
                        aria-label="tabs"
                        TabIndicatorProps={{ style: { bottom: 0 } }}
                    >
                        <Tab
                            label={`${lang.active} (${activeUsersSearchFilter(activeUsers).length.toString()})`}
                            data-testid="tab-admin-activated-list"
                        />
                        <Tab
                            label={`${lang.deactivated} (${deactivatedUsersSearchFilter(
                                deactivatedUsers
                            ).length.toString()})`}
                            data-testid="tab-admin-deactivated-list"
                        />
                    </Tabs>
                </div>
                <div className={classes.listContainer} data-testid="adminUsers">
                    {tabSelected === 0 &&
                        (activeUsers.length > 0 ? (
                            <InfiniteTable
                                tableColumns={copyArrayWithObjects(activeUsersTableColumns)}
                                dataSource={activeUsersSearchFilter(activeUsers)}
                                rowPadding={2}
                                onClickableButtonClick={handleButtonClick}
                            />
                        ) : (
                            <EmptyPage image={noActiveUser} message={lang.noActiveUser} subMessage={lang.inviteUser} />
                        ))}
                    {tabSelected === 1 &&
                        (deactivatedUsers.length > 0 ? (
                            <InfiniteTable
                                tableColumns={copyArrayWithObjects(deactivatedUsersTableColumns)}
                                dataSource={deactivatedUsersSearchFilter(deactivatedUsers)}
                                rowPadding={2}
                            />
                        ) : (
                            <EmptyPage
                                image={noDeactivatedUser}
                                message={lang.noDeactivatedUSer}
                                subMessage={lang.usersRemovedFromOrg}
                            />
                        ))}
                </div>
            </AdminLayout>
            <Snackbar
                open={snackbar.isOpen}
                message={snackbar.message}
                handleClose={handleCloseSnackbar}
                autohideOff={false}
            />
        </>
    );
};

export default AdminUsers;

import { useContext } from "react";

import { LoadingContext } from "../context/LoadingContext";

export function useLoading(): { setLoading: (newValue: boolean) => void; isLoading: boolean } {
    const { handleSetLoading, isLoading } = useContext(LoadingContext);

    const setLoading = (newValue: boolean): void => {
        handleSetLoading(newValue);
    };

    return { setLoading, isLoading };
}

export enum UserRole {
    talentMapAdmin = "talentMapAdmin",
    isSiteAdmin = "isSiteAdmin",
    isSurveyCreator = "isSurveyCreator",
    reportViewer = "reportViewer",
    isCommentViewer = "isCommentViewer",
    canViewAllOrgReport = "canViewAllOrgReport",
    isRestricted = "isRestricted",
    byPassSimilarityCheck = "byPassSimilarityCheck",
    isInsightsEnabled = "isInsightsEnabled",
    participantEnabled = "participantEnabled"
}

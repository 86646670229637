import React from "react";

import { useLang } from "core/hooks";
import { SelectItem } from "core/interfaces";
import { SurveySection } from "managerPortal/context/interfaces";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { ComparisonOperator, ComparisonOperatorMap, FilterType } from "./enums";
import { FilterParameter } from "./interfaces";
import { Typography } from "lib/typography";
import { FormControl } from "lib/form-control";
import { Select, SelectChangeEvent } from "lib/select";
import { MenuItem } from "lib/menu-item";
import { Box } from "lib/box";
import { FilledInput } from "lib/filled-input";
import { DateTarget } from "./components/DateTarget";
import { CheckboxTarget } from "./components/CheckboxTarget";
import { Divider } from "lib/divider";

type FilterTargetList = {
    selectedFilterItem: FilterParameter;
    updateFilterItems: (param: FilterParameter) => void;
    surveySections: SurveySection[];
    fieldsList: HrisFieldInfo[];
};

export const FilterTargetList = ({
    selectedFilterItem,
    surveySections,
    fieldsList,
    updateFilterItems
}: FilterTargetList) => {
    const { lang } = useLang();

    const isShowingComparator =
        selectedFilterItem.filterType === FilterType.contactField ||
        selectedFilterItem.filterType === FilterType.surveyItemPickOne ||
        selectedFilterItem.filterType === FilterType.surveyItemPickMany;

    const handleOperatorChange = (event: SelectChangeEvent<unknown>): void => {
        const updateSelectedFilterItem = {
            ...selectedFilterItem,
            comparisonOperator: event.target.value as ComparisonOperator
        };
        updateFilterItems(updateSelectedFilterItem);
    };

    return (
        <Box height="100%" width={360}>
            <Box height="100%" display="flex" flexDirection="column">
                {isShowingComparator && (
                    <>
                        <Box paddingY={1} paddingX={2}>
                            <FormControl variant="filled" fullWidth>
                                <Select
                                    data-testid="comparisonOperator-select"
                                    value={selectedFilterItem.comparisonOperator}
                                    onChange={handleOperatorChange}
                                    input={<FilledInput hiddenLabel={true} />}
                                >
                                    {ComparisonOperatorMap(lang, selectedFilterItem.filterType).map(
                                        (item: SelectItem) => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                                data-testid={`operatorSelectItem-${item.text}`}
                                            >
                                                <Typography>{item.text}</Typography>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Divider />
                        <CheckboxTarget
                            selectedFilterItem={selectedFilterItem}
                            surveySections={surveySections}
                            fieldsList={fieldsList}
                            updateFilterItems={updateFilterItems}
                        />
                    </>
                )}
                {selectedFilterItem.filterType === FilterType.date && (
                    <DateTarget selectedFilterItem={selectedFilterItem} updateFilterItems={updateFilterItems} />
                )}
            </Box>
        </Box>
    );
};

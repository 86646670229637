import React, { useState, ReactElement, useCallback } from "react";
import { makeStyles } from "@mui/styles";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { useLoading } from "core/hooks";
import MPDialogLayout from "../../../layout/MPDialogLayout";
import CardLayoutItem from "./CardLayoutItem";
import Column from "./Column";
import {
    useLang,
    useAvailableCardContent,
    useVisibleNodes,
    useManagerPortalContext,
    useExistingHierarchies,
    useSelectedHierarchy,
    useSelectSurvey,
    useOrgChartService
} from "core/hooks";
import { CardContentType, ColumnType } from "./enums";
import { QuestionType } from "../../../context/enums";
import { CardContent, Question, Bar, Numeric, CardLayout } from "../interfaces";
import { ChartValues, DragItem, MappedCardContent } from "./interfaces";
import { Hierarchy } from "../../hierarchy/interfaces";
import ConfigureCardMenu from "./ConfigureCardMenu";
import { Card as CardType } from "managerPortal/components/cards/interfaces";
import { Translation } from "core/languages/interfaces";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";
import Card from "@mui/material/Card";

type Props = {
    onCloseConfigureCard: () => void;
};

const useStyles = makeStyles(() => ({
    tooltipTextLine: {
        marginBottom: theme.spacing(1)
    },
    typographyNote: {
        height: 24,
        fontWeight: "500",
        margin: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`
    },
    cardStyle: {
        width: 280,
        paddingBottom: 16,
        boxSizing: "border-box",
        marginBottom: 8
    },
    cardLayoutEditorContent: {
        height: "100%",
        padding: `8px 24px 8px 24px`,
        boxSizing: "border-box",
        overflowY: "auto"
    },
    cardListsContainer: {
        display: "flex",
        justifyContent: "space-between",
        height: "calc(100% - 48px)",
        width: "100%"
    },
    cardLayoutLists: {
        marginLeft: 24
    },
    cardHeader: {
        height: 72,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        alignItems: "center"
    }
}));

const getChartValues = (listItem: CardContent | Question, visibleNodesTree: CardType): ChartValues => {
    const fullId: keyof CardType = listItem.id.substring(0, 1) + "SB" + listItem.id.substring(1, listItem.id.length);

    const chartValuesArray = visibleNodesTree[fullId];
    let chartValues = { unfavorable: 0, neutral: 0, favorable: 0 };
    if (chartValuesArray && chartValuesArray.length === 3) {
        chartValues = {
            unfavorable: chartValuesArray[0],
            neutral: chartValuesArray[1],
            favorable: chartValuesArray[2]
        };
    }
    if (chartValuesArray && chartValuesArray.length === 2) {
        chartValues = { unfavorable: chartValuesArray[0], neutral: 0, favorable: chartValuesArray[1] };
    }

    return chartValues;
};

const getItemType = (listItem: CardContent | Question): CardContentType => {
    if (listItem.id.toLowerCase().includes(CardContentType.keyDriver.toLowerCase())) {
        return CardContentType.keyDriver;
    }
    if (listItem.id.toLowerCase().includes(CardContentType.responseRate.toLowerCase())) {
        return CardContentType.responseRate;
    }
    if ("children" in listItem) {
        return CardContentType.question;
    }

    return CardContentType.item;
};

const mapAvailableCardContent = (
    availableCardContent: CardContent[],
    visibleNodesTree: CardType,
    lang: Translation
): MappedCardContent[] => {
    const result: MappedCardContent[] = [];
    availableCardContent
        .filter((listItem: CardContent) => listItem.canCalculate)
        .forEach((listItem: CardContent) => {
            result.push({
                id: listItem.id,
                parentId: listItem.parentId,
                title:
                    listItem.title === "Response Rate"
                        ? lang.responseRate
                        : listItem.title === "Key Drivers"
                          ? lang.keyDrivers
                          : listItem.title,
                chartValues: getChartValues(listItem, visibleNodesTree),
                itemType: getItemType(listItem)
            });

            if (listItem.children) {
                listItem.children
                    .filter(
                        (childrenItem: Question) => childrenItem.type !== QuestionType.PickMany && childrenItem.isFun
                    )
                    .forEach((childrenItem: Question): void => {
                        result.push({
                            id: childrenItem.id,
                            parentId: childrenItem.parentId,
                            title: childrenItem.title,
                            chartValues: getChartValues(childrenItem, visibleNodesTree),
                            itemType: getItemType(childrenItem)
                        });
                    });
            }
        });

    return result;
};

const mapNewLayout = (barList: MappedCardContent[], circleList: MappedCardContent[]): CardLayout => {
    return {
        bars: barList.map((item: MappedCardContent) => {
            return { id: item.id, title: item.title };
        }),
        numerics: circleList.map((item: MappedCardContent) => {
            return { id: item.id, title: item.title };
        })
    };
};

const removeFromList = (list: MappedCardContent[], itemId: MappedCardContent["id"]): MappedCardContent[] => {
    const listUpdate = [...list];
    const itemIndex = list.findIndex(listItem => listItem.id === itemId);
    if (itemIndex > -1) {
        listUpdate.splice(itemIndex, 1);
    }
    return listUpdate;
};

const ConfigureCard = (props: Props): ReactElement => {
    const orgChartService = useOrgChartService();
    const { setLoading } = useLoading();
    const { lang } = useLang();
    const classes = useStyles();
    const { managerPortalUpdateState } = useManagerPortalContext();
    const { availableCardContent } = useAvailableCardContent();
    const { visibleNodes } = useVisibleNodes();
    const { selectedSurvey } = useSelectSurvey();
    const { existingHierarchies, updateExistingHierarchies } = useExistingHierarchies();
    const { selectedHierarchy } = useSelectedHierarchy();
    const [sourceColumn, setSourceColumn] = useState<ColumnType>(ColumnType.cardContentList);

    const mappedAvailableCardContent = mapAvailableCardContent(availableCardContent, visibleNodes.tree, lang);
    const [mainList] = useState<MappedCardContent[]>(mappedAvailableCardContent);
    const [anchorMenuElement, setAnchorMenuElement] = useState<HTMLElement | null>(null);

    const initialBarList: MappedCardContent[] = [];
    selectedHierarchy?.Layout.bars.forEach((layoutItem: Bar) => {
        const itemFound = mainList.find((mainListItem: MappedCardContent) => layoutItem.id === mainListItem.id);

        if (itemFound) initialBarList.push(itemFound);
    });
    const [barList, setBarList] = useState<MappedCardContent[]>(initialBarList);

    const initialCircleList: MappedCardContent[] = [];
    selectedHierarchy?.Layout.numerics.forEach((layoutItem: Numeric) => {
        const itemFound = mainList.find((mainListItem: MappedCardContent) => layoutItem.id === mainListItem.id);

        if (itemFound) initialCircleList.push(itemFound);
    });
    const [circleList, setCircleList] = useState<MappedCardContent[]>(initialCircleList);

    const onBegin = (columnType: ColumnType): void => {
        setSourceColumn(columnType);
    };

    const handleSaveCloseDialog = (): void => {
        setLoading(true);
        const newLayout = mapNewLayout(barList, circleList);
        orgChartService.setHierarchyCardLayout(selectedSurvey.id, selectedHierarchy.HierarchyId, newLayout).then(() => {
            const hierarchies = existingHierarchies;
            const index = hierarchies.findIndex(h => h.HierarchyId === selectedHierarchy.HierarchyId);
            const updatedHierarchy = { ...hierarchies[index], Layout: newLayout };
            hierarchies[index] = { ...updatedHierarchy };
            managerPortalUpdateState({ existingHierarchies: hierarchies, selectedHierarchy: updatedHierarchy });
            setLoading(false);
            props.onCloseConfigureCard();
        });
    };

    const moveItem = useCallback(
        (dragIndex: number, hoverIndex: number, itemId: string, isDragging: boolean, currentColumn: ColumnType) => {
            if (currentColumn === ColumnType.cardContentList) return;
            if (currentColumn === ColumnType.graphBar) {
                if (!hoverIndex && !isDragging) {
                    const dragItemIndex = barList.findIndex(itemInfo => itemId === itemInfo.id);
                    setBarList(
                        update(barList, {
                            $splice: [[dragItemIndex, 1]]
                        })
                    );
                    return;
                }
                if (hoverIndex > -1) {
                    const dragItem = barList[dragIndex];
                    if (dragItem) {
                        setBarList(
                            update(barList, {
                                $splice: [
                                    [dragIndex, 1],
                                    [hoverIndex, 0, dragItem]
                                ]
                            })
                        );
                    }
                }
            }

            if (currentColumn === ColumnType.numberCircle) {
                if (!hoverIndex && !isDragging) {
                    const dragItemIndex = circleList.findIndex(itemInfo => itemId === itemInfo.id);
                    setCircleList(
                        update(circleList, {
                            $splice: [[dragItemIndex, 1]]
                        })
                    );
                    return;
                }

                if (hoverIndex > -1) {
                    const dragItem = circleList[dragIndex];
                    if (dragItem) {
                        setCircleList(
                            update(circleList, {
                                $splice: [
                                    [dragIndex, 1],
                                    [hoverIndex, 0, dragItem]
                                ]
                            })
                        );
                    }
                }
            }
        },
        [barList, circleList]
    );

    const addNewItem = useCallback(
        (item: DragItem, currentColumn: ColumnType): void => {
            if (currentColumn === ColumnType.cardContentList && sourceColumn === ColumnType.cardContentList) return;

            if (currentColumn === ColumnType.graphBar) {
                if (!barList.some(listItem => listItem.id === item.id)) {
                    const dragItem = mainList.find(listItem => item.id === listItem.id);
                    const barListUpdate = [...barList];
                    barListUpdate.splice(item.index, 0, dragItem!);

                    if (sourceColumn !== ColumnType.cardContentList) {
                        setCircleList(
                            update(circleList, {
                                $set: removeFromList(circleList, item.id)
                            })
                        );
                    }
                    setBarList(
                        update(barList, {
                            $set: barListUpdate
                        })
                    );
                }
            }
            if (currentColumn === ColumnType.numberCircle) {
                if (!circleList.some(listItem => listItem.id === item.id)) {
                    const dragItem = mainList.find(listItem => item.id === listItem.id);
                    const circleListUpdate = [...circleList];
                    circleListUpdate.splice(item.index, 0, dragItem!);

                    if (sourceColumn !== ColumnType.cardContentList) {
                        setBarList(
                            update(barList, {
                                $set: removeFromList(barList, item.id)
                            })
                        );
                    }
                    setCircleList(
                        update(circleList, {
                            $set: circleListUpdate
                        })
                    );
                }
            }
        },
        [barList, circleList, mainList, sourceColumn]
    );

    const helpText = (
        <div>
            {lang.configureCardHelp.map((l: string) => (
                <div key={l} className={classes.tooltipTextLine}>
                    {l}
                </div>
            ))}
        </div>
    );

    const handleSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        setAnchorMenuElement(event.currentTarget);
    };

    const handleCloseMenu = (): void => {
        setAnchorMenuElement(null);
    };

    const handleClearCard = (): void => {
        setCircleList([]);
        setBarList([]);
        handleCloseMenu();
    };

    const disableSetAsDefault = (): boolean => {
        const defaultLayout = selectedHierarchy?.DefaultLayout;
        if (!defaultLayout) return false;
        if (defaultLayout.bars.length !== barList.length) return false;
        if (defaultLayout.numerics.length !== circleList.length) return false;

        const isBarListDifferent = barList
            .map((item: MappedCardContent, index: number) => {
                if (defaultLayout.bars[index].id === item.id) return true;

                return false;
            })
            .includes(false);

        if (isBarListDifferent) return false;

        const isCircleListDifferent = circleList
            .map((item: MappedCardContent) => {
                if (defaultLayout.numerics.some((d: Numeric) => d.id === item.id)) return true;

                return false;
            })
            .includes(false);

        if (isCircleListDifferent) return false;

        return true;
    };

    const disableRevertDefault = (): boolean => {
        const defaultLayout = existingHierarchies.find(h => h.HierarchyId === selectedHierarchy.HierarchyId)
            ?.DefaultLayout;

        if (!defaultLayout) return true;
        else return false;
    };

    const disableClearCard = (): boolean => {
        if (barList.length === 0 && circleList.length === 0) return true;
        else return false;
    };

    const handleSetAsDefault = (): void => {
        const bars = barList.map((bar: MappedCardContent) => {
            return { id: bar.id, title: bar.title };
        });
        const numerics = circleList.map((numeric: MappedCardContent) => {
            return { id: numeric.id, title: numeric.title };
        });
        orgChartService.setDefaultLayout(selectedHierarchy.HierarchyId, selectedSurvey.id, bars, numerics);

        const foundIndex = existingHierarchies.findIndex(
            (hierarchy: Hierarchy) => hierarchy.HierarchyId === selectedHierarchy?.HierarchyId
        );

        const newDefault = { bars, numerics };

        const newExistingHierarchies = [...existingHierarchies];
        newExistingHierarchies[foundIndex].DefaultLayout = newDefault;

        updateExistingHierarchies(newExistingHierarchies);
        handleCloseMenu();
    };

    const handleRevertToDefault = (): void => {
        orgChartService.revertToDefaultLayout(selectedHierarchy.HierarchyId, selectedSurvey.id);

        const foundIndex = existingHierarchies.findIndex(
            (hierarchy: Hierarchy) => hierarchy.HierarchyId === selectedHierarchy?.HierarchyId
        );
        const newExistingHierarchies = [...existingHierarchies];
        const newDefault = newExistingHierarchies[foundIndex].DefaultLayout!;
        newExistingHierarchies[foundIndex].Layout = newDefault;

        const newBarList: MappedCardContent[] = [];
        newDefault.bars.forEach((layoutItem: Bar) => {
            const itemFound = mainList.find((mainListItem: MappedCardContent) => layoutItem.id === mainListItem.id);

            if (itemFound) newBarList.push(itemFound);
        });
        setBarList(newBarList);

        const newCircleList: MappedCardContent[] = [];
        selectedHierarchy?.Layout.numerics.forEach((layoutItem: Numeric) => {
            const itemFound = mainList.find((mainListItem: MappedCardContent) => layoutItem.id === mainListItem.id);

            if (itemFound) newCircleList.push(itemFound);
        });
        setCircleList(newCircleList);

        updateExistingHierarchies(newExistingHierarchies);
        handleCloseMenu();
    };

    const handleKeyUp = (): void => {
        handleSaveCloseDialog();
    };

    return (
        <MPDialogLayout
            primaryTitle={lang.configureCard}
            isDialogOpen={true}
            helpContent={helpText}
            handleAction={handleSaveCloseDialog}
            handleCloseDialog={props.onCloseConfigureCard}
            dataTestId="dialog-configure-card"
            paperProps={{ style: { width: 675, height: 730 } }}
            onSubMenuClick={handleSubMenuClick}
            isDoubleAction
            buttonsLabel={{ action: lang.ok, close: lang.cancel }}
            handleKeyUp={handleKeyUp}
        >
            <div className={classes.cardLayoutEditorContent}>
                <Typography fontWeight="medium" mb={1}>
                    {lang.layoutCardLimitDrop}
                </Typography>
                <DndProvider backend={HTML5Backend}>
                    <div className={classes.cardListsContainer}>
                        <Column columnType={ColumnType.cardContentList} onDrop={addNewItem} list={mainList}>
                            {mainList
                                .filter((item: MappedCardContent) => item.itemType !== CardContentType.item)
                                .map(
                                    (item: MappedCardContent, index: number): ReactElement =>
                                        item.itemType === CardContentType.keyDriver ||
                                        item.itemType === CardContentType.responseRate ? (
                                            <CardLayoutItem
                                                key={item.id}
                                                item={item}
                                                index={index}
                                                columnType={ColumnType.cardContentList}
                                                onBegin={onBegin}
                                                moveItem={moveItem}
                                                list={mainList}
                                                onHover={addNewItem}
                                                truncateDisplay={35}
                                            />
                                        ) : (
                                            <CardLayoutItem
                                                key={item.id}
                                                isExpandable
                                                item={item}
                                                index={index}
                                                columnType={ColumnType.cardContentList}
                                                onBegin={onBegin}
                                                moveItem={moveItem}
                                                truncateDisplay={35}
                                                list={mainList}
                                                onHover={addNewItem}
                                                dataTestId={"testItem" + item.id}
                                            >
                                                {mainList
                                                    .filter(
                                                        (childrenItem: MappedCardContent) =>
                                                            childrenItem.parentId === item.id
                                                    )
                                                    .map((childrenItem: MappedCardContent) => (
                                                        <CardLayoutItem
                                                            key={childrenItem.id}
                                                            item={childrenItem}
                                                            index={index}
                                                            isSubPanel
                                                            columnType={ColumnType.cardContentList}
                                                            onBegin={onBegin}
                                                            moveItem={moveItem}
                                                            list={mainList}
                                                            onHover={addNewItem}
                                                            truncateDisplay={35}
                                                        />
                                                    ))}
                                            </CardLayoutItem>
                                        )
                                )}
                        </Column>

                        <div className={classes.cardLayoutLists}>
                            <Card elevation={4} classes={{ root: classes.cardStyle }}>
                                <div className={classes.cardHeader}>
                                    <Typography>{selectedHierarchy?.Name}</Typography>
                                </div>
                                <Divider />

                                <Column
                                    columnType={ColumnType.graphBar}
                                    emptyList={barList.length === 0}
                                    onDrop={addNewItem}
                                    list={barList}
                                    dataTestId="barTarget"
                                >
                                    {barList.map((item: MappedCardContent, index: number) => (
                                        <CardLayoutItem
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            columnType={ColumnType.graphBar}
                                            onBegin={onBegin}
                                            moveItem={moveItem}
                                            list={barList}
                                            onHover={addNewItem}
                                            truncateDisplay={35}
                                        />
                                    ))}
                                </Column>
                                <Column
                                    columnType={ColumnType.numberCircle}
                                    emptyList={circleList.length === 0}
                                    onDrop={addNewItem}
                                    list={circleList}
                                    dataTestId="circleTarget"
                                >
                                    {circleList.map((item: MappedCardContent, index: number) => (
                                        <CardLayoutItem
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            columnType={ColumnType.numberCircle}
                                            onBegin={onBegin}
                                            moveItem={moveItem}
                                            list={circleList}
                                            onHover={addNewItem}
                                        />
                                    ))}
                                </Column>
                            </Card>
                        </div>
                    </div>
                </DndProvider>
                <ConfigureCardMenu
                    anchorEl={anchorMenuElement}
                    onClose={handleCloseMenu}
                    onRevertToDefault={handleRevertToDefault}
                    onSetAsDefault={handleSetAsDefault}
                    onClearCard={handleClearCard}
                    disableRevertToDefault={disableRevertDefault()}
                    disableSetDefault={disableSetAsDefault()}
                    disableClearCard={disableClearCard()}
                />
            </div>
        </MPDialogLayout>
    );
};

export default ConfigureCard;

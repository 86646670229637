import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";

import { Chip } from "lib/chip";
import { Box } from "lib/box";
import { Checkbox } from "lib/checkbox";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { useLang, useOrgChartService } from "core/hooks";
import { isStringEmptyOrSpaces, sortArray } from "core/helpers";
import { dateFormat } from "core/constants";
import { BenchmarkInputKey } from "./enums";
import { Tag } from "components/admin/tags/interface";
import { SurveyList, AdminBenchmarkResponse } from "./interfaces";
import { Button } from "lib/button";
import { TextField } from "lib/text-field";
import { Dialog, DialogActions } from "lib/dialog";
import { Typography } from "lib/typography";
import { Paper } from "lib/paper";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "lib/date-picker";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";

type Props = {
    dialogTitle: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmitCallback: (
        englishTitle: string,
        frenchTitle: string,
        tags: Tag[],
        startDate: Dayjs,
        endDate: Dayjs,
        surveyList: SurveyList[],
        numberOfResponses: number,
        responseRate: number
    ) => void;
    benchmarks: AdminBenchmarkResponse[];
};

export const CreateAdminBenchmarkDialog: FunctionComponent<Props> = props => {
    const { lang } = useLang();
    const { user } = useUser();
    const orgChartService = useOrgChartService();
    const { dialogTitle, isOpen, onClose, onSubmitCallback } = props;
    const [englishTitle, setEnglishTitle] = useState<string>("");
    const [frenchTitle, setFrenchTitle] = useState<string>("");
    const [validationEnglishTitleError, setValidationEnglishTitleError] = useState("");
    const [validationFrenchTitleError, setValidationFrenchTitleError] = useState("");
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [tags, setTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [surveyList, setSurveyList] = useState<SurveyList[]>([]);
    const [numberOfResponses, setNumberOfResponses] = useState<number>(0);
    const [responseRate, setResponseRate] = useState<number>(0);
    const numberOfOrg = surveyList && surveyList.map(org => org.checked).filter(Boolean).length;
    const numberOfSurveys =
        surveyList &&
        surveyList
            .map(org => org.surveys)
            .reduce((a, b) => a.concat(b), [])
            .map(survey => survey.checked)
            .filter(Boolean).length;

    const validation = (): boolean => {
        if (isStringEmptyOrSpaces(englishTitle)) {
            setValidationEnglishTitleError(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }
        if (
            props.benchmarks &&
            props.benchmarks.find(
                benchmark => benchmark.name.trim().toLowerCase() === englishTitle.trim().toLowerCase()
            )
        ) {
            setValidationEnglishTitleError(lang.duplicateBenchmarkName);
            return false;
        }
        if (isStringEmptyOrSpaces(frenchTitle)) {
            setValidationFrenchTitleError(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }

        if (!startDate) {
            setValidationFrenchTitleError("missing start date");
            return false;
        }

        if (!endDate) {
            setValidationFrenchTitleError("missing end date");
            return false;
        }

        return true;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === BenchmarkInputKey.englishTitle) {
            if (!value) {
                setValidationEnglishTitleError(lang.dialogExportSlide.controls.errorMainTitle);
            } else if (
                props.benchmarks &&
                props.benchmarks.find(benchmark => benchmark.name.trim().toLowerCase() === value.trim().toLowerCase())
            ) {
                setValidationEnglishTitleError(lang.duplicateBenchmarkName);
            } else {
                setValidationEnglishTitleError("");
            }
            setEnglishTitle(value);
            return;
        }
        if (name === BenchmarkInputKey.frenchTitle) {
            if (!value) {
                setValidationFrenchTitleError(lang.dialogExportSlide.controls.errorMainTitle);
            } else {
                setValidationFrenchTitleError("");
            }
            setFrenchTitle(value);
            return;
        }
    };

    const handleStartDateChange = (date: Dayjs | null): void => {
        setStartDate(date);
    };
    const handleEndDateChange = (date: Dayjs | null): void => {
        setEndDate(date);
    };

    const onCreateClick = (): void => {
        validation() &&
            onSubmitCallback(
                englishTitle,
                frenchTitle,
                selectedTags,
                startDate!,
                endDate!,
                surveyList,
                numberOfResponses,
                responseRate
            );
    };

    const onClearAll = (): void => {
        setEnglishTitle("");
        setFrenchTitle("");
        setStartDate(null);
        setEndDate(null);
        setSelectedTags([{ name: "", id: -1 }]);
        setNumberOfResponses(0);
        setResponseRate(0);
        setValidationEnglishTitleError("");
        setValidationFrenchTitleError("");
    };
    const sortTagsArray = <T extends Tag>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");

    const handleTagSelected = (value: AutocompleteValue | null): void => {
        if (value && !selectedTags.map(t => t.id).includes(value.id)) {
            const selectedTag = { name: value.label, id: value.id };
            setSelectedTags([...selectedTags, selectedTag]);
        }
    };
    const handleDeleteChip = (tag: Tag) => (): void => {
        const tags = [...selectedTags].filter(t => t.id !== tag.id);
        setSelectedTags(tags);
    };
    const getTags = async (): Promise<void> => {
        const allTags = await orgChartService.getTags();
        setTags(allTags);
    };
    const updateResponseCountAndRate = async (surveyList: SurveyList[]): Promise<void> => {
        const selectedSurveyIds = surveyList
            .map(org => org.surveys)
            .reduce((a, b) => a.concat(b), [])
            .filter(survey => survey.checked)
            .map(survey => survey.id);
        if (selectedSurveyIds && selectedSurveyIds.length > 0) {
            const response = await orgChartService.getBenchmarkAdminRespondent(selectedSurveyIds);
            if (response) {
                setNumberOfResponses(response.numberOfResponses);
                setResponseRate(response.responseRate);
            }
        }
    };

    const getBenchmarkSurveyData = async (): Promise<void> => {
        const tagIdsString = selectedTags
            .filter(t => t.id !== -1)
            .map(t => t.id)
            .join(",");
        const inputStartDate = startDate ? startDate.format(dateFormat) : "";
        const inputEndDate = endDate ? endDate.format(dateFormat) : "";
        const benchmarkSurveyData = await orgChartService.getAdminBenchmark(inputStartDate, inputEndDate, tagIdsString);
        if (benchmarkSurveyData) {
            const surveyListDetails = benchmarkSurveyData.map(orgDetails => ({
                ...orgDetails,
                checked: true,
                surveys: orgDetails.surveys.map(s => ({
                    ...s,
                    checked: true
                }))
            }));
            //check and memorize existing survey list
            if (surveyList && surveyList.length > 0) {
                surveyListDetails.forEach(org => {
                    const previousOrg = surveyList.find(sl => sl.id === org.id);
                    if (previousOrg) {
                        const previousSurveys = previousOrg.surveys;
                        org.surveys.forEach(survey => {
                            const previousSurvey = previousSurveys.find(s => s.id === survey.id);
                            if (previousSurvey) {
                                survey.checked = previousSurvey.checked;
                            }
                        });
                        if (org.surveys.map(org => org.checked).filter(Boolean).length === 0) {
                            org.checked = false;
                        } else if (org.surveys.map(org => org.checked).filter(Boolean).length > 0) {
                            org.checked = true;
                        }
                    }
                });
            }
            setSurveyList(surveyListDetails);
            updateResponseCountAndRate(surveyListDetails);
        }
    };
    const handleOrgCheckboxChange = (e: ChangeEvent<HTMLInputElement>, orgId: number): void => {
        const updatedOrg = [...surveyList].find(sl => sl.id === orgId);
        if (updatedOrg) {
            updatedOrg.checked = e.target.checked as boolean;
            updatedOrg.surveys.forEach(s => (s.checked = e.target.checked));
            const updatedSurveyList = [...surveyList].map(sl => (sl.id === orgId ? updatedOrg : sl));
            setSurveyList(updatedSurveyList);
            updateResponseCountAndRate(updatedSurveyList);
        }
    };

    const handleSurveyCheckboxChange = (e: ChangeEvent<HTMLInputElement>, surveyId: number, orgId: number): void => {
        const updatedSurvey = [...surveyList]
            .map(sl => sl.surveys)
            .reduce((a, b) => a.concat(b), [])
            .find(s => s.id === surveyId);
        const updatedOrg = [...surveyList].find(sl => sl.id === orgId);
        if (updatedSurvey && updatedOrg) {
            updatedSurvey.checked = e.target.checked as boolean;
            //update Organization check status if all survey checked or all survey unchecked
            if (
                updatedOrg.surveys.map(survey => survey.checked).filter(Boolean).length === 0 ||
                (updatedOrg.surveys.map(survey => survey.checked).filter(Boolean).length >= 1 &&
                    e.target.checked === true)
            ) {
                updatedOrg.checked = e.target.checked as boolean;
            }
            updatedOrg.surveys = [...updatedOrg.surveys].map(s => (s.id === surveyId ? updatedSurvey : s));
            const updatedSurveyList = [...surveyList].map(sl => (sl.id === orgId ? updatedOrg : sl));
            setSurveyList(updatedSurveyList);
            updateResponseCountAndRate(updatedSurveyList);
        }
    };

    useEffect(() => {
        if (user.isTalentMapAdmin) {
            getTags();
        }
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            getBenchmarkSurveyData();
        }
        if (!startDate || !endDate || selectedTags.filter(t => t.id !== -1).length === 0) {
            setSurveyList([]);
            setNumberOfResponses(0);
            setResponseRate(0);
        }
    }, [startDate, endDate, selectedTags]);

    const paperStyled = {
        display: "flex",
        height: "auto",
        minHeight: 53,
        justifyContent: "flex-start",
        alignContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        paddingLeft: 1,
        minWidth: 500,
        maxWidth: 660
    };

    const dialogContent = {
        display: "flex",
        flexDirection: "column",
        alignContent: "space-between",
        width: "1100px",
        height: "800px",
        minHeight: "800px",
        maxHeight: "800px"
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={"lg"}
            onKeyUp={e => {
                if (
                    e.key === "Enter" &&
                    validationEnglishTitleError === "" &&
                    validationFrenchTitleError === "" &&
                    !isStringEmptyOrSpaces(englishTitle) &&
                    !isStringEmptyOrSpaces(frenchTitle) &&
                    startDate !== null &&
                    endDate !== null
                ) {
                    onCreateClick();
                }
            }}
        >
            <Box sx={dialogContent}>
                <Box display="flex" alignItems="center" justifyContent="space-between" pl={3} pr={3} height={80}>
                    <Typography variant="h4">{dialogTitle}</Typography>
                    <DialogActions>
                        <Button variant="text" data-testid="btn-bm-dialog-clearAll" onClick={onClearAll}>
                            {lang.clearAll}
                        </Button>
                    </DialogActions>
                </Box>
                <Divider />
                <Box
                    sx={{
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        height: "calc(100% - 140px)"
                    }}
                >
                    <Box pl={3} pr={3} pt={2}>
                        <Box display="flex" gap={2}>
                            <Box width="60%" display="flex" gap={2}>
                                <TextField
                                    autoFocus
                                    data-testid="text-field-bm-dialog-englishTitle"
                                    name={BenchmarkInputKey.englishTitle}
                                    error={validationEnglishTitleError !== ""}
                                    label={lang.englishTitle}
                                    onChange={handleChange}
                                    value={englishTitle}
                                    helperText={validationEnglishTitleError}
                                />
                                <TextField
                                    data-testid="text-field-bm-dialog-frenchTitle"
                                    name={BenchmarkInputKey.frenchTitle}
                                    label={lang.frenchTitle}
                                    error={validationFrenchTitleError !== ""}
                                    onChange={handleChange}
                                    value={frenchTitle}
                                    helperText={validationFrenchTitleError}
                                />
                            </Box>
                            <DatePicker
                                data-testid="data-picker-start-date"
                                label={lang.startDate}
                                value={startDate}
                                onChange={handleStartDateChange}
                                minDate={dayjs("1900/01/01")}
                                maxDate={dayjs()}
                            />
                            <DatePicker
                                data-testid="data-picker-end-date"
                                label={lang.endDate}
                                value={endDate}
                                onChange={handleEndDateChange}
                                minDate={startDate === null ? dayjs("1900/01/01", dateFormat) : startDate}
                                maxDate={dayjs()}
                            />
                        </Box>
                        <Box display="flex" width="100%" alignItems="center" gap={2}>
                            <Box width={400}>
                                <Autocomplete
                                    id="benchmark-admin-tag-combo-box"
                                    noOptionsText={lang.noOptionsAvailable}
                                    options={sortTagsArray(tags).map(s => {
                                        return {
                                            id: s.id,
                                            label: s.name
                                        };
                                    })}
                                    onChange={handleTagSelected}
                                    getOptionDisabled={(option): boolean => {
                                        return selectedTags.map(t => t.id).includes(option.id);
                                    }}
                                    placeholder={lang.addTag}
                                />
                            </Box>
                            <Paper component="ul" data-testid="benchmark-tag-paper" sx={paperStyled}>
                                <Typography variant="body2">{lang.tags}</Typography>
                                {sortTagsArray(selectedTags)
                                    .filter(t => t.id !== -1)
                                    .map(tag => {
                                        return (
                                            <li key={tag.id}>
                                                <Box ml={1}>
                                                    <Chip label={tag.name} onDelete={handleDeleteChip(tag)} />
                                                </Box>
                                            </li>
                                        );
                                    })}
                            </Paper>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" pb={1} pt={1} pl={3} pr={3}>
                        <Typography
                            variant="body2"
                            width="182px"
                            fontWeight="medium"
                        >{`${lang.numberOfOrganizations}: ${numberOfOrg}`}</Typography>
                        <Typography
                            variant="body2"
                            width="182px"
                            fontWeight="medium"
                        >{`${lang.numberOfSurveys}: ${numberOfSurveys}`}</Typography>
                        <Typography
                            variant="body2"
                            width="182px"
                            fontWeight="medium"
                        >{`${lang.numberOfResponses}: ${numberOfResponses}`}</Typography>
                        <Typography
                            variant="body2"
                            width="182px"
                            fontWeight="medium"
                        >{`${lang.responseRate}: ${responseRate}%`}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" pb={1} pt={1} pl={3} pr={3}>
                        <Typography variant="body2" fontWeight="medium" width="50%">
                            {lang.organizations}
                        </Typography>
                        <Typography variant="body2" fontWeight="medium" width="50%">
                            {lang.surveys}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            scrollbarGutter: "stable",
                            height: "calc(100% - 230px)"
                        }}
                    >
                        {surveyList.map(organization => (
                            <Box key={organization.id} display="flex" alignItems="flex-start" pl={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "50%"
                                    }}
                                    data-testid={`organization-list-${organization.name}`}
                                >
                                    {organization.name}
                                    <Checkbox
                                        value={organization.name}
                                        checked={organization.checked}
                                        data-testid={`checkbox-organization-${organization.name}`}
                                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                            handleOrgCheckboxChange(event, organization.id)
                                        }
                                    />
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%"
                                    }}
                                >
                                    {organization.surveys.map(survey => (
                                        <Box
                                            pl={2}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            key={`${organization.id}-${survey.id}`}
                                            data-testid={`survey-list-${survey.name}`}
                                        >
                                            {survey.name}
                                            <Checkbox
                                                data-testid={`checkbox-survey-${survey.name}`}
                                                value={survey.name}
                                                checked={survey.checked}
                                                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                                    handleSurveyCheckboxChange(event, survey.id, organization.id)
                                                }
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                        <Box width="100%" display={"flex"} justifyContent="center" height="100%" pl={2}>
                            <Divider orientation="vertical" flexItem />
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box height={60} display="flex" alignItems="center" justifyContent="flex-end" gap={2} pr={3}>
                    <Button
                        onClick={onCreateClick}
                        variant="text"
                        disabled={
                            validationEnglishTitleError !== "" ||
                            validationFrenchTitleError !== "" ||
                            isStringEmptyOrSpaces(englishTitle) ||
                            isStringEmptyOrSpaces(frenchTitle) ||
                            startDate === null ||
                            endDate === null
                        }
                        data-testid="btn-bm-dialog-create"
                    >
                        {lang.ok}
                    </Button>
                    <Button variant="text" data-testid="btn-bm-dialog-cancel" onClick={onClose}>
                        {lang.cancel}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

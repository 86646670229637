import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { HomeIcon } from "./interfaces";

import { theme } from "lib/theme";
import { Typography } from "lib/typography";

type Props = {
    icon: HomeIcon;
};

const useStyles = makeStyles(() => ({
    boxContent: ({ isDisabled }: { isDisabled: boolean }) => ({
        margin: "12px 36px",
        height: 232,
        width: 226,
        boxSizing: "border-box",
        background: theme.palette.background.paper,
        borderRadius: 10,
        border: "2px solid",
        borderColor: theme.palette.background.paper,
        display: "block",
        position: "relative",
        opacity: isDisabled ? 0.38 : 1,
        pointerEvents: isDisabled ? "none" : "auto",
        "&:hover": {
            borderColor: isDisabled ? "transparent" : theme.palette.primary.main,
            cursor: isDisabled ? "not-allowed" : "pointer"
        }
    }),
    title: {
        width: "100%",
        position: "absolute",
        bottom: 8,
        opacity: 1
    },
    link: ({ isDisabled }: { isDisabled: boolean }) => ({
        textDecoration: "none",
        height: "fit-content",
        cursor: isDisabled ? "not-allowed" : "pointer"
    }),
    image: {
        height: "80%",
        width: "100%",
        position: "absolute",
        top: 8
    }
}));

const IconLink: React.FC<Props> = (props): ReactElement => {
    const isDisabled = props.icon.disabled?.isDisabled ? true : false;
    const classes = useStyles({ isDisabled });
    return (
        <Link to={isDisabled ? "#" : props.icon.linkTo!} className={classes.link} data-testid={props.icon.label}>
            <div className={classes.boxContent}>
                <img alt={props.icon.label} src={props.icon.imageUrl} className={classes.image} />
                <Typography align="center" className={classes.title} variant="h6" style={{ color: "black" }}>
                    {props.icon.label}
                </Typography>
            </div>
        </Link>
    );
};

export default IconLink;

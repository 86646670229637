import { useState } from "react";
import { TitleTranslation } from "../interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";

interface LookdownSize {
    id: string;
    width: number;
    height: number;
    name: string;
}

interface Lookdown {
    isSection: boolean;
    demographicFieldId: number;
    measureId: number;
    measureField: string;
}

type LookdownState = {
    isPreviousPeriodHidden: boolean;
    setPreviousPeriodHidden: (newValue: boolean) => void;
    size: LookdownSize[];
    setSize: (newValue: LookdownSize[]) => void;
    selectedLookdownSize: string;
    setSelectedLookdownSize: (newValue: string) => void;
    lookdown: Lookdown;
    handleLookdownUpdate: (item: Lookdown) => void;
    titleTranslation: TitleTranslation[];
    setTitleTranslation: (newTitleTranslations: TitleTranslation[]) => void;
};

type InitState = {
    initPreviousPeriodHidden?: LookdownState["isPreviousPeriodHidden"];
    initSize?: LookdownState["size"];
    initLookdownSize?: LookdownState["selectedLookdownSize"];
    initLookdown?: LookdownState["lookdown"];
    initTitleTranslation?: LookdownState["titleTranslation"];
};

export const useLookdownState = ({
    initPreviousPeriodHidden,
    initSize,
    initLookdownSize,
    initLookdown,
    initTitleTranslation
}: InitState): LookdownState => {
    const [isPreviousPeriodHidden, setPreviousPeriodHidden] = useState(initPreviousPeriodHidden || false);
    const [size, setSize] = useState<LookdownSize[]>(initSize || []);
    const [selectedLookdownSize, setSelectedLookdownSize] = useState(initLookdownSize || "");
    const [lookdown, setLookdown] = useState(
        initLookdown || {
            isSection: false,
            demographicFieldId: -1,
            measureId: -1,
            measureField: ""
        }
    );

    const [titleTranslation, setTitleTranslation] = useState<TitleTranslation[]>(
        initTitleTranslation || defaultItemTitleTranslation
    );

    const handlePreviousPeriodHidden = (newValue: boolean): void => {
        setPreviousPeriodHidden(newValue);
    };

    const updateSize = (newValue: LookdownSize[]): void => {
        setSize(newValue);
    };

    const updateLookdownSize = (newValue: string): void => {
        setSelectedLookdownSize(newValue);
    };

    const handleLookdownUpdate = (item: Lookdown): void => {
        setLookdown(item);
    };

    const handleUpdateTitleTranslation = (newTitleTranslations: TitleTranslation[]): void => {
        setTitleTranslation(newTitleTranslations);
    };
    return {
        isPreviousPeriodHidden,
        setPreviousPeriodHidden: handlePreviousPeriodHidden,
        size,
        setSize: updateSize,
        selectedLookdownSize,
        setSelectedLookdownSize: updateLookdownSize,
        lookdown,
        handleLookdownUpdate,
        titleTranslation,
        setTitleTranslation: handleUpdateTitleTranslation
    };
};

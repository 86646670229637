import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { textPrimaryColor } from "../../styles/GlobalStyles";
import MPDialogLayout from "../../layout/MPDialogLayout";
import { Hierarchy, HierarchyField } from "../hierarchy/interfaces";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import FieldList from "./FieldList";
import { useLang, useExistingHierarchies, useSelectSurvey, useHrisFields } from "../../../core/hooks";
import { isStringEmptyOrSpaces } from "../../../core/helpers";
import { theme } from "lib/theme";

import { TextField } from "lib/text-field";
import { Typography } from "lib/typography";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    contentContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        overflow: "hidden",
        width: "100%",
        height: "100%",
        margin: 0,
        color: textPrimaryColor
    },
    contentItem: {
        height: "calc(100% - 96px)",
        overflow: "hidden"
    },
    tooltipTextLine: {
        marginBottom: theme.spacing(1)
    },
    isDragging: {
        border: "1.5px dashed",
        borderColor: theme.palette.action.disabledBackground
    }
}));

interface Props {
    onClose: () => void;
    open: boolean;
    handleCreateHierarchy: (a: string, b: number[], c: number) => void;
}
interface State {
    hierarchyFields: HierarchyField[];
    availableFields: HierarchyField[];
    name: string;
    error: string;
}

const CreateHierarchyDialog = (props: Props): JSX.Element => {
    const { onClose, open, handleCreateHierarchy } = props;
    const { hrisFields } = useHrisFields();
    const classes = useStyles();

    const sortFields = (fields: HierarchyField[]): HierarchyField[] => {
        return fields.sort((a: HierarchyField, b: HierarchyField) =>
            a.FieldName < b.FieldName ? -1 : a.FieldName > b.FieldName ? 1 : 0
        );
    };

    const initialState: State = {
        hierarchyFields: [],
        availableFields: sortFields(hrisFields).filter(
            (item: HierarchyField): boolean => !item.IsKeyField && !item.IsHidden
        ),
        name: "",
        error: ""
    };
    const [state, setState] = useState<State>(initialState);

    useEffect(() => {
        setState((prevState: State) => {
            return {
                ...prevState,
                availableFields: sortFields(hrisFields).filter(
                    (item: HierarchyField): boolean => !item.IsKeyField && !item.IsHidden
                )
            };
        });
    }, [hrisFields]);

    const { existingHierarchies } = useExistingHierarchies();
    const { selectedSurvey } = useSelectSurvey();
    const { lang } = useLang();

    const handleKeyUp = (): void => {
        if (!disableCreateButton) {
            handleValidation();
        }
    };

    const handleValidation = (): void => {
        const isNameDuplicated = existingHierarchies.some(
            (h: Hierarchy): boolean => h.Name.toLowerCase() === state.name.toLowerCase()
        );
        if (isNameDuplicated) {
            setState({
                ...state,
                error: lang.duplicatedHierarchyName
            });
        } else {
            let hierarchyFieldIds = state.hierarchyFields.map((f: HierarchyField): number => f.FieldId);
            const keyField = hrisFields.filter((f: HierarchyField): boolean => f.IsKeyField);
            if (keyField.length > 0) {
                hierarchyFieldIds = hierarchyFieldIds.concat([keyField[0].FieldId]);
            }
            handleCreateHierarchy(state.name, hierarchyFieldIds, selectedSurvey.id);
        }
    };

    const tooltipText = (
        <div>
            {lang.createHierarchyHelp.map(
                (l: string, index: number): JSX.Element => (
                    <div key={index} className={classes.tooltipTextLine}>
                        {l}
                    </div>
                )
            )}
        </div>
    );

    const onDragEnd = (result: DropResult): void => {
        const { destination, source, draggableId } = result;
        let newState = { ...state };

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //reorder same column
        if (source.droppableId === destination.droppableId) {
            const _sourceId = source.droppableId;
            if (_sourceId === "hierarchyFields" || _sourceId === "availableFields") {
                const newFields = state[_sourceId].filter((_field: HierarchyField) => _field.FieldId !== +draggableId);
                const field = state[_sourceId].filter((_field: HierarchyField) => _field.FieldId === +draggableId)[0];
                newFields.splice(destination.index, 0, field);
                newState = {
                    ...newState,
                    [_sourceId]: newFields
                };
                setState(newState);
                return;
            }
        }

        //move column
        const sourceId = source.droppableId;
        const destinationId = destination.droppableId;
        if (
            (sourceId === "hierarchyFields" || sourceId === "availableFields") &&
            (destinationId === "hierarchyFields" || destinationId === "availableFields")
        ) {
            const finishFields = [...state[destinationId]];
            const startFields = state[sourceId].filter((_field: HierarchyField) => _field.FieldId !== +draggableId);
            const field = state[sourceId].filter((_field: HierarchyField) => _field.FieldId === +draggableId)[0];
            finishFields.splice(destination.index, 0, field);

            newState = {
                ...newState,
                [sourceId]: startFields,
                [destinationId]: finishFields
            };
        }
        setState(newState);
    };

    const disableCreateButton = isStringEmptyOrSpaces(state.name);
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <MPDialogLayout
                primaryTitle={lang.createHierarchy}
                isDialogOpen={open}
                handleCloseDialog={onClose}
                handleAction={handleValidation}
                helpContent={tooltipText}
                isDoubleAction
                dataTestId="dialog-create-hierarchy"
                paperProps={{ style: { width: 700, height: 700 } }}
                buttonsLabel={{ action: lang.ok }}
                disableActionButton={disableCreateButton}
                handleKeyUp={handleKeyUp}
            >
                <Box id="createHierarchyContainer" p={3} height="calc(100% - 60px)">
                    <TextField
                        id="hierarchyName"
                        label={lang.hierarchyName}
                        value={state.name}
                        onChange={(event: React.FormEvent): void => {
                            const inputEl = event.currentTarget as HTMLInputElement;
                            setState({ ...state, name: inputEl.value, error: "" });
                        }}
                        error={!!state.error}
                        helperText={state.error}
                    />
                    <Box display="flex" gap={2} height="100%" mt={2}>
                        <Box width="50%">
                            <Typography variant="body1" gutterBottom={true}>
                                {lang.availableFields}
                            </Typography>
                            <FieldList
                                listType="availableFields"
                                fields={state.availableFields}
                                droppableId="availableFields"
                            />
                        </Box>
                        <Box width="50%">
                            <Typography id="hierarchyField" variant="body1" gutterBottom={true}>
                                {lang.hierarchyFields}
                            </Typography>
                            <FieldList
                                listType="hierarchyFields"
                                fields={state.hierarchyFields}
                                droppableId="hierarchyFields"
                            />
                        </Box>
                    </Box>
                </Box>
            </MPDialogLayout>
        </DragDropContext>
    );
};

export default CreateHierarchyDialog;

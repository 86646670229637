import { useContext } from "react";

import { ManagerPortalContext } from "../../managerPortal/context/ManagerPortalContext";

import { CardContent } from "../../managerPortal/components/cards/interfaces";

export const useAvailableCardContent = (): { availableCardContent: CardContent[] } => {
    const { managerPortalState } = useContext(ManagerPortalContext);
    return { availableCardContent: managerPortalState.availableCardContent };
};

import { gql } from "@apollo/client";

export const editUserMutation = gql`
    mutation editUserMutation($user: EditUserInput!) {
        editUser(user: $user) {
            id
            name
            email
            isSiteAdmin
            isSurveyCreator
            isRestricted
            isCommentViewer
            byPassSimilarityCheck
            isInsightsEnabled
            participantEnabled
            active
        }
    }
`;

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const AdminParticipantsStatusStyles = makeStyles(() => ({
    totalResponse: {
        marginTop: theme.spacing(2)
    },
    totalResponseBold: {
        marginTop: theme.spacing(2),
        fontWeight: 500
    },
    errorResponse: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2)
    }
}));

import React, { ReactElement } from "react";

import { useLang } from "core/hooks";
import { Menu } from "lib/menu";
import { MenuItem } from "lib/menu-item";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";

interface Props {
    hierarchyMenuAnchorElement: HTMLElement | null;
    handleCloseHierarchyMenu: () => void;
    handleMakeDefaultClicked: () => void;
    handleDeleteClicked: () => void;
    handleRenameHierarchy: () => void;
}

const DrawerHierarchyMenu = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();

    const userCanMakeDefault = user.isSiteAdmin || user.isTalentMapAdmin;

    return (
        <Menu
            anchorEl={props.hierarchyMenuAnchorElement}
            open={Boolean(props.hierarchyMenuAnchorElement)}
            onClose={props.handleCloseHierarchyMenu}
            MenuListProps={{
                id: "drawerMenu"
            }}
        >
            {userCanMakeDefault && (
                <MenuItem
                    data-testid="menu-item-make-default"
                    onClick={(): void => {
                        props.handleMakeDefaultClicked();
                        props.handleCloseHierarchyMenu();
                    }}
                >
                    {lang.makeDefault}
                </MenuItem>
            )}
            <MenuItem
                data-testid="menu-item-rename-hierarchy"
                onClick={(): void => {
                    props.handleRenameHierarchy();
                    props.handleCloseHierarchyMenu();
                }}
            >
                {lang.editHierarchyName}
            </MenuItem>

            <Divider />
            <MenuItem data-testid="menu-item-delete-hierarchy" onClick={props.handleDeleteClicked}>
                {lang.delete}
            </MenuItem>
        </Menu>
    );
};
export default DrawerHierarchyMenu;

import React, { CSSProperties } from "react";
import { makeStyles } from "@mui/styles";

type Props = {
    maxHeight: CSSProperties["maxHeight"];
    maxWidth: CSSProperties["maxWidth"];
    src: string;
};
const useStyles = makeStyles({
    logoWrapper: (props: Props) => ({
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
        width: "100%",
        height: props.maxHeight
    }),
    logoPlaceholder: (props: Props) => ({
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${props.src})`
    })
});

const ImageHolder: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props);

    return (
        <div className={classes.logoWrapper} data-testid="logo-wrapper">
            <div className={classes.logoPlaceholder} />
        </div>
    );
};

export default ImageHolder;

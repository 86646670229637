import React from "react";

import MuiInputLabel, { InputLabelProps } from "@mui/material/InputLabel";

const InputLabel = (props: InputLabelProps) => {
    const { children, ...rest } = props;
    return <MuiInputLabel {...rest}>{children}</MuiInputLabel>;
};

export { InputLabel };

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { ActivityLogInfo } from "components/admin/participants/interfaces";
import AdminParticipantsActivityLogDetails from "./AdminParticipantsActivityLogDetails";
import { useLang } from "core/hooks";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    activityLogContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        borderTop: theme.border.main
    },
    noActivity: {
        paddingTop: 24,
        paddingLeft: 24
    }
}));

type Props = {
    activityLog: ActivityLogInfo[];
    searchTerm: string;
};

const AdminParticipantsActivityLog = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    return (
        <div className={classes.activityLogContainer}>
            {props.activityLog.length > 0 ? (
                props.activityLog.map((activity, index) => (
                    <React.Fragment key={index}>
                        <AdminParticipantsActivityLogDetails
                            activityLog={activity}
                            index={index}
                            searchTerm={props.searchTerm}
                        />
                    </React.Fragment>
                ))
            ) : (
                <div className={classes.noActivity}>{lang.noActivityLog}</div>
            )}
        </div>
    );
};

export default AdminParticipantsActivityLog;

import { CommentSettings, WordCloudSettings } from "../interface";

export const initWordCloudSettings: WordCloudSettings = {
    surveyWordCloudStopWords: [],
    defaultWordCloudStopWords: []
};

export const initCommentSettings: CommentSettings = {
    hiddenWords: []
};

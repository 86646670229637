import { sortArray } from "./SortArray.helper";
import { SurveyInfo } from "components/admin/results/interfaces";
import { TargetSurvey } from "components/admin/results/previousPeriod/interfaces";

export const mapToTargetSurvey = (surveys: SurveyInfo[], currentSurveyId: number, isSorted = false): TargetSurvey[] => {
    const targetSurveys = surveys
        .filter((survey: SurveyInfo) => currentSurveyId !== survey.id && survey.active)
        .map((survey: SurveyInfo) => {
            return {
                id: survey.id,
                name: survey.translations[0].name
            };
        });

    if (isSorted) {
        return sortArray(targetSurveys, "name", "asc");
    }
    return targetSurveys;
};

import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Search, ClearOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { TextField } from "lib/text-field";
import { InputAdornment } from "lib/input-adornment";
import { IconButton } from "lib/icon-button";
import { Select, SelectChangeEvent } from "lib/select-custom2";
import { useLang } from "core/hooks";
import { whiteBackground } from "lib/palette";
import { SearchType } from "components/reports/comment/Comment";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    root: {
        height: "46px",
        backgroundColor: whiteBackground,
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.border.main
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${orangeColor}`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: orangeColor
        }
    },
    input: {
        paddingLeft: "8px",
        color: "black !important",
        backgroundColor: `${whiteBackground} !important`,
        "& .MuiOutlinedInput-input:focused": {
            backgroundColor: `${whiteBackground} !important`
        },
        "& .MuiOutlinedInput-input:hover": {
            backgroundColor: `${whiteBackground} !important`
        },
        "& .MuiOutlinedInput-input:active": {
            backgroundColor: `${whiteBackground} !important`
        },
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px white inset",
            WebkitTextFillColor: "default"
        }
    },
    adornedEndStyle: {
        color: "grey",
        paddingRight: "0px !important",
        backgroundColor: whiteBackground
    },
    adornedStartStyle: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important"
    }
}));
interface ISemanticSearchBarProps {
    searchType: SearchType;
    setSearchType: (type: SearchType) => void;
    onchange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    delay: number;
    onDelayChange: (value: string) => void;
    searchTerm: string;
    isAIEnabled: boolean;
}

export type SemanticSearchBarHandle = {
    setInputField: (i: string) => void;
};

const SemanticSearchBar = forwardRef<SemanticSearchBarHandle, ISemanticSearchBarProps>(
    (props: ISemanticSearchBarProps, ref): JSX.Element => {
        const { lang } = useLang();
        const { onchange, delay, onDelayChange, searchTerm, searchType, setSearchType } = props;
        const [input, setInput] = useState(searchTerm);
        const [timerId, setTimerId] = useState(0);
        const classes = useStyles();

        const handleChangeSearchType = (event: SelectChangeEvent<unknown>): void => {
            const { value } = event.target;
            if (value !== undefined && value !== null) {
                setSearchType(value as SearchType);
            }
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setInput(event.target.value);
            if (!delay && onchange) {
                onchange(event);
            }
            if (delay && onDelayChange) {
                if (timerId > 0) {
                    window.clearTimeout(timerId);
                }
                setTimerId(
                    window.setTimeout(() => {
                        setTimerId(0);
                        onDelayChange(event.target.value);
                    }, delay)
                );
            }
        };

        useImperativeHandle(ref, () => ({
            setInputField(i: string): void {
                setInput(i);
            }
        }));

        useEffect(() => {
            setInput(searchTerm);
        }, [searchTerm]);

        return (
            <TextField
                id="standard-search"
                label={""}
                variant="outlined"
                margin="none"
                onChange={handleInputChange}
                color="primary"
                fullWidth
                size={"medium"}
                inputProps={{ value: input }}
                InputProps={{
                    classes: {
                        root: classes.root,
                        input: classes.input,
                        adornedEnd: classes.adornedEndStyle,
                        adornedStart: classes.adornedStartStyle
                    },
                    startAdornment: (
                        <Select
                            variant="outlined"
                            value={searchType}
                            onChange={handleChangeSearchType}
                            data-testid="searchType"
                            items={[SearchType.exactMatch, SearchType.anyWord, SearchType.semanticSearch]
                                .map(option => {
                                    return {
                                        value: option,
                                        name: lang[option] as string
                                    };
                                })
                                .filter(option => {
                                    if (props.isAIEnabled) {
                                        return option;
                                    } else {
                                        return option.value !== SearchType.semanticSearch;
                                    }
                                })}
                        />
                    ),
                    endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: "2px", display: "flex" }}>
                            {input && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setInput("");
                                        if (delay && onDelayChange) {
                                            if (timerId > 0) {
                                                window.clearTimeout(timerId);
                                            }
                                            setTimerId(
                                                window.setTimeout(() => {
                                                    setTimerId(0);
                                                    onDelayChange("");
                                                }, delay)
                                            );
                                        }
                                    }}
                                >
                                    <ClearOutlined />
                                </IconButton>
                            )}
                            <IconButton size="small">
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        );
    }
);
SemanticSearchBar.displayName = "SemanticSearchBar";

export default SemanticSearchBar;

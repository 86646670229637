import { gql } from "@apollo/client";

export const surveyQuery = gql`
    query survey($id: ID!) {
        survey(id: $id) {
            active
            created
            closeDate
            id
            source
            confidentialityThreshold
            sgSurveyName
            createdByName
            translations {
                name
            }
            allowInReports
            lockMappings
        }
    }
`;

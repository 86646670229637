import { useState } from "react";

export const useToggle = (initValue?: boolean): [boolean, () => void] => {
    const [toggle, setToggle] = useState(initValue || false);

    const updateToggle = (): void => {
        setToggle(!toggle);
    };

    return [toggle, updateToggle];
};

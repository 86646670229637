import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { Button } from "lib/button";
import { TitleTranslation } from "./interfaces";
import { theme } from "lib/theme";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";

const useStyles = makeStyles(() => ({
    popover: {
        boxSizing: "border-box",
        "&.MuiPopover-root": {
            boxSizing: "border-box",
            marginTop: 16,
            marginLeft: 60
        }
    },
    popoverContainer: {
        width: 500,
        height: 320,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box"
    },
    popover_header: {
        boxSizing: "border-box",
        display: "flex",
        height: 56,
        minHeight: 56,
        width: "100%",
        alignItems: "center",
        paddingLeft: 24,
        borderBottom: theme.border.main
    },
    popover_row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "flex-start",
        paddingRight: 24
    },
    popover_row_action: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        width: "500px",
        justifyContent: "flex-end",
        borderTop: theme.border.main,
        height: "56px",
        minHeight: "56px",
        maxHeight: "56px",
        overflow: "hidden",
        paddingRight: 24,
        gap: 8
    },
    popover_row_textField: {
        boxSizing: "border-box",
        display: "flex",
        paddingLeft: 24,
        paddingBottom: 8,
        alignItems: "center",
        justifyContent: "flex-start"
    },
    popover_row_text: {
        boxSizing: "border-box",
        width: 80,
        minWidth: 80
    }
}));

export enum DashboardInputKey {
    englishTitle = "englishTitle",
    frenchTitle = "frenchTitle",
    spanishTitle = "spanishTitle"
}

type Props = {
    anchorEl: Element | null;
    dashboardTitle: TitleTranslation[];
    defaultDashboardTitle: string;
    handleClosePopover: () => void;
    applyTitle: (englishTitle: string, frenchTitle: string, spanishTitle: string) => void;
    cancelClicked: () => void;
};

export const DashboardTitlePopup = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [englishTitle, setEnglishTitle] = useState<string>("");
    const [frenchTitle, setFrenchTitle] = useState<string>("");
    const [spanishTitle, setSpanishTitle] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === DashboardInputKey.englishTitle) {
            setEnglishTitle(value);
            return;
        }
        if (name === DashboardInputKey.frenchTitle) {
            setFrenchTitle(value);
            return;
        }
        if (name === DashboardInputKey.spanishTitle) {
            setSpanishTitle(value);
            return;
        }
    };

    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const setTitleInitialState = (): void => {
        const englishT = props.dashboardTitle.filter(t => t.languageCode === "en")[0]?.name
            ? props.dashboardTitle.filter(t => t.languageCode === "en")[0].name
            : props.defaultDashboardTitle;
        const frenchT = props.dashboardTitle.filter(t => t.languageCode === "fr")[0]?.name
            ? props.dashboardTitle.filter(t => t.languageCode === "fr")[0].name
            : props.defaultDashboardTitle;
        const spanishT = props.dashboardTitle.filter(t => t.languageCode === "es")[0]?.name
            ? props.dashboardTitle.filter(t => t.languageCode === "es")[0].name
            : props.defaultDashboardTitle;
        setEnglishTitle(englishT);
        setFrenchTitle(frenchT);
        setSpanishTitle(spanishT);
    };

    const handleCancelClicked = (): void => {
        setTitleInitialState();
        props.cancelClicked();
    };

    const handleApplyTitle = (): void => {
        props.applyTitle(englishTitle, frenchTitle, spanishTitle);
    };

    useEffect(() => {
        setTitleInitialState();
    }, [props.dashboardTitle, props.anchorEl]);

    return (
        <Popover
            className={classes.popover}
            open={props.anchorEl !== null}
            onClose={props.handleClosePopover}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            onKeyUp={e => {
                if (e.key === "Enter" && englishTitle !== "" && frenchTitle !== "" && spanishTitle !== "") {
                    handleApplyTitle();
                }
            }}
        >
            <div className={classes.popoverContainer}>
                <div className={classes.popover_header}>
                    <Typography>{lang.dashboardTitle}</Typography>
                </div>
                <div className={classes.popover_row}>
                    <div className={classes.popover_row_textField}>
                        <Typography className={classes.popover_row_text}>{lang.english}</Typography>
                        <TextField
                            data-testid="dashboard-popup-englishTitle"
                            name={DashboardInputKey.englishTitle}
                            onChange={handleChange}
                            onFocus={handleOnFocus}
                            value={englishTitle}
                        />
                    </div>
                    <div className={classes.popover_row_textField}>
                        <Typography className={classes.popover_row_text}>{lang.spanish}</Typography>
                        <TextField
                            data-testid="dashboard-popup-spanishTitle"
                            name={DashboardInputKey.spanishTitle}
                            onChange={handleChange}
                            onFocus={handleOnFocus}
                            value={spanishTitle}
                        />
                    </div>
                    <div className={classes.popover_row_textField}>
                        <Typography className={classes.popover_row_text}>{lang.french}</Typography>
                        <TextField
                            data-testid="dashboard-popup-frenchTitle"
                            name={DashboardInputKey.frenchTitle}
                            label={""}
                            onChange={handleChange}
                            onFocus={handleOnFocus}
                            value={frenchTitle}
                        />
                    </div>
                </div>
                <div className={classes.popover_row_action}>
                    <Button
                        variant="text"
                        onClick={handleApplyTitle}
                        disabled={englishTitle === "" || frenchTitle === "" || spanishTitle === ""}
                        data-testid="btn-dashboard-apply-title"
                    >
                        {lang.ok}
                    </Button>
                    <Button onClick={handleCancelClicked} variant="text" data-testid="btn-dashboard-cancel-title">
                        {lang.cancel}
                    </Button>
                </div>
            </div>
        </Popover>
    );
};

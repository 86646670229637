import { Action } from "core/interfaces";
import { CustomizeSnapshotExportState } from "../interfaces";
import { CustomizeSnapshotExportActions } from "../actions";

export const CustomizeSnapshotExportReducer = (
    state: CustomizeSnapshotExportState,
    action: Action
): CustomizeSnapshotExportState => {
    const { type, payload } = action;
    switch (type) {
        case CustomizeSnapshotExportActions.SET_COMPONENT_STATUS:
            return {
                ...state,
                componentStatus: payload.componentStatus
            };
        case CustomizeSnapshotExportActions.SET_CONFIRMATION_STATUS:
            return {
                ...state,
                confirmationDialog: payload.status
            };
        case CustomizeSnapshotExportActions.SET_CLEAR_ALL:
            return {
                ...state,
                componentStatus: payload.componentStatus,
                confirmationDialog: payload.confirmationDialog,
                coverFileLoaded: payload.coverFileLoaded,
                introFileLoaded: payload.introFileLoaded,
                understandResultFileLoaded: payload.understandResultFileLoaded,
                surveyResultTransFileLoaded: payload.surveyResultTransFileLoaded,
                understandHeatmapFileLoaded: payload.understandHeatmapFileLoaded,
                understandKeyDriverFileLoaded: payload.understandKeyDriverFileLoaded,
                outroFileLoaded: payload.outroFileLoaded,
                isDirty: payload.isDirty
            };
        case CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED:
            return {
                ...state,
                coverFileLoaded: payload.coverFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED:
            return {
                ...state,
                introFileLoaded: payload.introFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED:
            return {
                ...state,
                understandResultFileLoaded: payload.understandResultFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED:
            return {
                ...state,
                surveyResultTransFileLoaded: payload.surveyResultTransFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED:
            return {
                ...state,
                understandHeatmapFileLoaded: payload.understandHeatmapFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED:
            return {
                ...state,
                understandKeyDriverFileLoaded: payload.understandKeyDriverFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED:
            return {
                ...state,
                outroFileLoaded: payload.outroFileLoaded
            };
        case CustomizeSnapshotExportActions.SET_IS_DIRTY:
            return {
                ...state,
                isDirty: payload.isDirty
            };
        case CustomizeSnapshotExportActions.OPEN_PREVIEW:
            return {
                ...state,
                isPreviewExportDialogOpen: payload.isPreviewExportDialogOpen
            };
        case CustomizeSnapshotExportActions.SET_COMPARE_OPTIONS:
            return {
                ...state,
                compareOptions: payload.compareOptions
            };
        case CustomizeSnapshotExportActions.SET_SURVEY_SECTIONS:
            return {
                ...state,
                surveySections: payload.surveySections
            };
        default:
            return {
                ...state
            };
    }
};

import { gql } from "@apollo/client";

export const createSurveyMutation = gql`
    mutation createSurvey($survey: SurveyInput!, $itemsFile: Upload!, $valuesFile: Upload!, $responsesFile: Upload!) {
        createSurvey(survey: $survey, itemsFile: $itemsFile, valuesFile: $valuesFile, responsesFile: $responsesFile) {
            active
            created
            closeDate
            allowInReports
            lockMappings
            id
            sgSurveyName
            source
            createdByName
            translations {
                name
            }
        }
    }
`;

import React, { ReactElement, useState } from "react";

import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import SelectExpandablePanel from "components/shared/SelectExpandablePanel";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { theme } from "lib/theme";
import { InputAdornment } from "lib/input-adornment";
import { Popover } from "lib/popover";
import { FormControl } from "lib/form-control";
import { InputLabel } from "lib/input-label";
import { FilledInput } from "lib/filled-input";

type Props = {
    selectOptions: SelectOptionExpandablePanel[];
    disabledItemsIds: SelectOptionExpandablePanel["id"][];
    selectedItem: { id: string; name: string };
    onSelectItem: (id: string, name: string) => void;
    inputLabel?: string;
};

const useStyles = makeStyles(() => ({
    popoverPaper: {
        maxHeight: 450,
        height: 450,
        minWidth: 320,
        maxWidth: 700,
        width: 700
    }
}));

const selectStyled = {
    width: "100%"
};

const selectInputStyled = {
    height: 49,
    paddingLeft: theme.spacing(1),
    "&:hover": {
        background: theme.palette.action.hover,
        cursor: "pointer"
    },
    borderRadius: 0
};

const selectInputProps = {
    padding: 0,
    "&:hover": {
        cursor: "pointer"
    }
};

const CustomSelectExpandablePanel = (props: Props): ReactElement => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        const element = event.target as HTMLElement;
        setAnchorEl(element);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleSelectItem = (id: string, name: string): void => {
        setAnchorEl(null);
        props.onSelectItem(id, name);
    };

    return (
        <>
            <FormControl variant="filled" sx={selectStyled}>
                <InputLabel htmlFor="my-input">{props.inputLabel}</InputLabel>
                <FilledInput
                    readOnly
                    onClick={handleOnClick}
                    value={props.selectedItem.name}
                    sx={selectInputStyled}
                    inputProps={{
                        sx: selectInputProps
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowDropDownIcon style={{ fill: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                    }
                    data-testid="select-expandable-panel"
                />
            </FormControl>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                classes={{ paper: classes.popoverPaper }}
            >
                <SelectExpandablePanel
                    rawData={props.selectOptions}
                    onItemSelected={handleSelectItem}
                    disabledItemsIds={props.disabledItemsIds}
                />
            </Popover>
        </>
    );
};

export default CustomSelectExpandablePanel;

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useCorrelationStyles = makeStyles(() => ({
    infoHeader: {
        height: 56,
        display: "flex",
        borderBottom: theme.border.main,
        boxSizing: "border-box"
    },
    totalResponseHeader: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 22,
        borderRight: theme.border.main,
        width: 300
    },
    infoHeaderItemMsg: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 550,
        minWidth: 550,
        overflow: "hidden"
    },
    dataContent: {
        display: "grid",
        boxSizing: "border-box",
        height: "100%"
    },
    pivotGridWrapper: {
        justifyContent: "center"
    },
    infoHeaderText: {
        fontWeight: 500
    },
    colorsReferenceContainer: {
        width: 280,
        minWidth: 280,
        maxWidth: 280,
        paddingLeft: 28,
        paddingRight: 9,
        display: "flex",
        borderRight: theme.border.main
    },
    colorsReference: {
        width: 70,
        minWidth: 70,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    colorsReferenceMiddle: {
        width: 90,
        minWidth: 90,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    boxStyle: {
        width: 15,
        height: 15,
        marginRight: theme.spacing(1)
    }
}));

import { gql } from "@apollo/client";

export const auth0UserIdQuery = gql`
    query auth0UserId($auth0UserId: String!) {
        auth0UserId(auth0UserId: $auth0UserId) {
            active
            id
            email
            isSiteAdmin
            isSurveyCreator
            isCommentViewer
            isTalentMapAdmin
            isRestricted
            isInsightsEnabled
            participantEnabled
            name
            created
            organizationId
            settings
            userFilters {
                surveyId
                filters
            }
            lastActivity
        }
    }
`;

import React, { ReactElement, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { HeatmapDisplayValue, ResponseCount } from "./interface";
import { heatmapCellClassMapper } from "./helper";
import { useHeatmapGridStyles } from "./heatmapGrid.style";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { ExpandCollapseBtnContainer } from "./HeatmapAction";
import { colorClasses } from "./heatmap.init";
import { useLoading, useLang } from "core/hooks";
import { Filter } from "components/filters/interfaces";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";

interface Props {
    gridData: HeatmapDisplayValue[];
    sectionData: HeatmapDisplayValue[];
    sectionOverall: HeatmapDisplayValue[];
    itemOverall: HeatmapDisplayValue[];
    responseCount: ResponseCount[];
    selectedTarget: string;
    isFilteredOverallSelected: boolean;
    shouldUpdate: boolean;
    switchAxis: () => void;
    currentFilter: Filter;
    showDeltaValue: boolean;
    switchDelta: (a: boolean) => void;
    isPreviousPeriodSelected: boolean;
    updateStatus: () => void;
}

interface heatmapColumnCell {
    title: string;
    id: number;
}

const HeatGrid = (props: Props): ReactElement => {
    const { setLoading } = useLoading();
    const { lang } = useLang();
    const { gridData, sectionData, sectionOverall, itemOverall, responseCount, switchAxis } = props;
    const [displayHeader, setDisplayHeader] = useState<heatmapColumnCell[]>([{ title: lang.responses, id: -1 }]);
    const [displayRows, setDisplayRows] = useState<string[]>([]);
    const [expandedColumns, setExpandedColumns] = useState<heatmapColumnCell[]>([]);
    const [firstLevelHeader, setFirstLevelHeader] = useState<heatmapColumnCell[]>([]);
    const [tableDimension, setTableDimension] = useState<{ height: number; width: number }>({
        height: displayRows.length * 70 + 500,
        width: displayHeader.length * 80 + 250
    });
    const [nestedHeader, setNestedHeader] = useState<
        { firstLevel: heatmapColumnCell; children: heatmapColumnCell[] }[]
    >([]);

    const responseTotalHeader = props.selectedTarget ? `${props.selectedTarget} ` : lang.overallSS;
    const valueNotFound = "-";
    const [scrollingStatus, setScrollingStatus] = useState<{ left: boolean; top: boolean }>({
        left: false,
        top: false
    });

    const classes = useHeatmapGridStyles({
        width: tableDimension.width,
        height: tableDimension.height,
        floatingRowWidth: displayHeader.length * 53 + 220,
        isScrollingDown: scrollingStatus.top === true,
        showFilterTags: props.currentFilter.items.length > 0,
        isPreviousPeriodSelected: props.isPreviousPeriodSelected
    });

    useEffect(() => {
        //the first cell is response count
        const header: heatmapColumnCell[] = [{ title: lang.responses, id: -1 }];
        //the first row is demogrphic group or overall
        // if compare to previous period, hide overall row
        const rowHeaders = props.isPreviousPeriodSelected
            ? [...new Set(gridData.map(d => d.source))].sort()
            : [responseTotalHeader, ...[...new Set(gridData.map(d => d.source))].sort()];
        //is to group section and its questions for expanding headers
        const nestedHeaders: { firstLevel: heatmapColumnCell; children: heatmapColumnCell[] }[] = [];
        gridData.forEach(d => {
            //push all avaliable headers
            if (header.findIndex(i => i.id === d.targetId) === -1) {
                header.push({ title: d.target, id: d.targetId });
            }
        });
        setDisplayHeader(header);
        //first level headers are sections
        setFirstLevelHeader(header);

        gridData.forEach(d => {
            const header: { firstLevel: heatmapColumnCell; children: heatmapColumnCell[] } = {
                firstLevel: { title: d.target, id: d.targetId },
                children: []
            };
            //get all question nodes
            const children = props.gridData.filter(f => f.target === d.target && f.subTarget);

            if (nestedHeaders.length > 0 && nestedHeaders.findIndex(i => i.firstLevel.id === d.targetId) === -1) {
                if (children.length > 0) {
                    children.forEach(c => {
                        if (
                            header.children.findIndex(h => h.id === c.subTargetId) === -1 &&
                            c.subTarget &&
                            c.subTargetId
                        ) {
                            header.children.push({ title: c.subTarget, id: c.subTargetId });
                        }
                    });
                }
                nestedHeaders.push(header);
            }

            if (nestedHeaders.length === 0) {
                if (children.length > 0) {
                    children.forEach(c => {
                        if (
                            header.children.findIndex(h => h.id === c.subTargetId) === -1 &&
                            c.subTarget &&
                            c.subTargetId
                        ) {
                            header.children.push({ title: c.subTarget, id: c.subTargetId });
                        }
                    });
                }
                nestedHeaders.push(header);
            }
        });

        setNestedHeader(nestedHeaders);
        setDisplayRows(rowHeaders);

        props.updateStatus();

        return (): void => {
            setDisplayHeader([]);
            setDisplayRows([]);
            setExpandedColumns([]);
            setFirstLevelHeader([]);
            setNestedHeader([]);
        };
    }, [props.shouldUpdate]);

    useEffect(() => {
        setTableDimension({
            height: displayRows.length * 60 + 255,
            width: displayHeader.length * 80 + 250
        });
    }, [displayHeader, displayRows]);

    const isFirstLevelHeader = (id: number): boolean => {
        if (id === -1) return true;
        const headerFound = firstLevelHeader.findIndex(h => h.id === id);
        return headerFound !== -1;
    };

    const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
        const div = e.target as HTMLDivElement;

        const isScrolling = { left: div.scrollLeft !== 0, top: div.scrollTop !== 0 };

        //flag scrolling event
        if (scrollingStatus.left !== isScrolling.left || scrollingStatus.top !== isScrolling.top) {
            setScrollingStatus(isScrolling);
        }
    };

    const responseCountFinder = (rowHeader: string): number | string => {
        let lookupRow = rowHeader;
        if (rowHeader === responseTotalHeader) lookupRow = lang.overallSS;
        const count = responseCount.find(re => re.fieldName === lookupRow);

        return count ? count.count : valueNotFound;
    };

    const valueFinder = (column: heatmapColumnCell, rowHeader: string, onlyDelta: boolean): number | string => {
        const lookupHeader = rowHeader === responseTotalHeader ? lang.overallSS : rowHeader;

        let lookup = gridData.find(f => f.source === lookupHeader && f.subTargetId === column.id);

        const sectionValue = sectionData.find(f => f.targetId === column.id && f.source === lookupHeader);

        const sectionOverallValue = sectionOverall.find(f => f.targetId === column.id);

        const itemOverallValue = itemOverall.find(f => f.targetId === column.id);

        if (rowHeader === responseTotalHeader) {
            // indicate it is overall row
            if (lookup === undefined) {
                lookup = sectionOverallValue;
            }
            if (lookup === undefined) {
                lookup = itemOverallValue;
            }
        } else {
            if (lookup === undefined) {
                lookup = sectionValue;
            }
        }
        if (lookup) {
            if (column.title === lookup.target) {
                if (lookup.delta === null && lookup.favorable === null) {
                    return valueNotFound;
                }
                if (sectionOverallValue && sectionOverallValue?.delta === 0) {
                    return valueNotFound;
                } else {
                    if (onlyDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (lookup.showDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (!lookup.showDelta && lookup.favorable !== null) {
                        return lookup.favorable;
                    } else {
                        return valueNotFound;
                    }
                }
            }
            if (column.title === lookup.subTarget) {
                if (itemOverallValue && itemOverallValue?.delta === 0) {
                    return valueNotFound;
                } else {
                    if (onlyDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (lookup.showDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (!lookup.showDelta && lookup.favorable !== null) {
                        return lookup.favorable;
                    } else {
                        return valueNotFound;
                    }
                }
            }
            return valueNotFound;
        } else return valueNotFound;
    };

    const handleExpandAll = (): void => {
        setLoading(true);
        let expandAllHeader: heatmapColumnCell[] = [{ title: lang.responses, id: -1 }];
        nestedHeader.forEach(n => {
            const headerGroup = [n.firstLevel, ...n.children];
            expandAllHeader = [...expandAllHeader, ...headerGroup];
        });
        setDisplayHeader(expandAllHeader);
        setExpandedColumns([...firstLevelHeader]);
        setLoading(false);
    };

    const handleCollapseAll = (): void => {
        const updatedHeaders = [...firstLevelHeader];
        setDisplayHeader(updatedHeaders);
        setExpandedColumns([]);
    };

    const handleColumnHeaderClick = (headerId: number): void => {
        const isRowExpanded = expandedColumns.find(e => e.id === headerId) !== undefined;
        const isRowFirstLevel = firstLevelHeader.find(f => f.id === headerId) !== undefined;

        if (isRowFirstLevel) {
            const headerFound = nestedHeader.find(n => n.firstLevel.id === headerId);
            if (headerFound) {
                const children = headerFound.children;
                if (children && children.length > 0) {
                    if (!isRowExpanded) {
                        const motherIndex = displayHeader.findIndex(h => h.id === headerId);
                        const firstHalf = [...displayHeader].slice(0, motherIndex + 1);
                        const secondHalf = [...displayHeader].slice(motherIndex + 1, displayHeader.length);

                        setDisplayHeader([...firstHalf, ...children, ...secondHalf]);

                        setExpandedColumns([...expandedColumns, headerFound.firstLevel]);
                    } else {
                        const headers = [...displayHeader].filter(f => {
                            return children.findIndex(c => c.id === f.id) === -1;
                        });

                        setDisplayHeader([...headers]);
                        const expanded = expandedColumns.filter(ex => ex.id !== headerId);

                        setExpandedColumns([...expanded]);
                    }
                }
            }
        }
    };
    const isHeaderExpanded = (id: number): boolean => {
        return expandedColumns.find(e => e.id === id) !== undefined;
    };
    const isHeaderExpandable = (id: number): boolean => {
        return nestedHeader.find(h => h.firstLevel.id === id && h.children.length > 0) !== undefined;
    };

    const tooltipTitle = (h: string, r: string): ReactElement => {
        return (
            <>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {h}
                </Typography>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {r}
                </Typography>
            </>
        );
    };
    if (displayHeader.length === 0) {
        return <></>;
    }

    return (
        <div className={classes.container}>
            <div
                className={classes.tableContainer}
                data-testid={"tableContainer"}
                onScroll={(e): void => {
                    scrollHandler(e);
                }}
            >
                {(scrollingStatus.top || scrollingStatus.left) && !props.isPreviousPeriodSelected && (
                    <div className={classes.fixedOverallRes}>
                        <div className={classes.floatingRowHeaderCellOverall} data-testid={"Overall"}>
                            <TruncateDisplay maxLabelLength={23} title={responseTotalHeader}>
                                <Typography variant="body1" className={classes.rowHeaderText}>
                                    {props.isFilteredOverallSelected
                                        ? lang.overallFiltered
                                        : props.isPreviousPeriodSelected
                                          ? lang.overallSS
                                          : responseTotalHeader}
                                </Typography>
                            </TruncateDisplay>
                        </div>
                        <div
                            className={classes.fixedFloatingOverallCell}
                            data-testid={`floatingResCell_${displayRows[0]}`}
                        >
                            <Typography variant="body1">{responseCountFinder(responseTotalHeader)}</Typography>
                        </div>
                    </div>
                )}
                {scrollingStatus.top && !props.isPreviousPeriodSelected && (
                    <div className={classes.floatingOverallRowContainer}>
                        {displayHeader.slice(1).map((h: heatmapColumnCell, i: number) => (
                            <div
                                className={classes.floatingOverallCell}
                                key={i}
                                data-testid={`floating_row_${h.title}`}
                            >
                                <Typography variant="body1">{valueFinder(h, lang.overallSS, false)}%</Typography>
                            </div>
                        ))}
                    </div>
                )}
                {scrollingStatus.left && (
                    <div className={classes.floatingRowHeader}>
                        <div className={scrollingStatus.top ? classes.placeholderShort : classes.placeholder}></div>
                        {props.isPreviousPeriodSelected &&
                            displayRows.map((r, i) => (
                                <div className={classes.floatingRowGroup} key={i}>
                                    <div className={classes.floatingRowHeaderCell} data-testid={`rowHeader+${r}`}>
                                        <div className={classes.floatingRowHeaderCell_textWrap}>
                                            <Tooltip title={r} placement={"bottom"}>
                                                <Typography variant="body1" className={classes.rowHeaderText}>
                                                    {r}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={classes.floatingResCountCell} data-testid={`floatingResCell_${r}`}>
                                        <Typography variant="body1"> {responseCountFinder(r)}</Typography>
                                    </div>
                                </div>
                            ))}
                        {!props.isPreviousPeriodSelected &&
                            displayRows.slice(1).map((r, i) => (
                                <div className={classes.floatingRowGroup} key={i}>
                                    <div
                                        className={
                                            r === responseTotalHeader
                                                ? classes.floatingRowHeaderCellOverall
                                                : classes.floatingRowHeaderCell
                                        }
                                        data-testid={r === responseTotalHeader ? "Overall" : `rowHeader+${r}`}
                                    >
                                        <div className={classes.floatingRowHeaderCell_textWrap}>
                                            <Tooltip title={r} placement={"bottom"}>
                                                <Typography variant="body1" className={classes.rowHeaderText}>
                                                    {r}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            r === responseTotalHeader
                                                ? classes.floatingResCountCellOverall
                                                : classes.floatingResCountCell
                                        }
                                        data-testid={`floatingResCell_${r}`}
                                    >
                                        <Typography variant="body1"> {responseCountFinder(r)}</Typography>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}

                <div className={classes.floatingAction}>
                    <ExpandCollapseBtnContainer
                        handleCollapseAll={handleCollapseAll}
                        handleExpandAll={handleExpandAll}
                        handleSwitchAxis={switchAxis}
                        originalLength={firstLevelHeader.length - 1}
                        currentLength={displayHeader.length - 1}
                        expandedAllLength={
                            nestedHeader.length + nestedHeader.map(h => h.children.length).reduce((a, b) => a + b, 0)
                        }
                        showDeltaValue={props.showDeltaValue}
                        switchDelta={props.switchDelta}
                    />
                    <div className={classes.responseHeader}>
                        <Typography variant="body1">{displayHeader[0].title}</Typography>
                    </div>
                </div>

                <table className={classes.gridTable} data-testid="heatmap-table">
                    <colgroup>
                        <col className={classes.col_rowHeader}></col>
                        {displayHeader.map((d: heatmapColumnCell, i: number) => (
                            <col className={classes.col_dataCellHeader} key={i}></col>
                        ))}
                    </colgroup>
                    <thead className={classes.thead}>
                        <tr className={classes.tableRow}>
                            <th className={classes.tableAction}></th>
                            {displayHeader.map((d: heatmapColumnCell, i: number) => {
                                return isFirstLevelHeader(d.id) ? (
                                    <th
                                        className={
                                            d.id === -1
                                                ? classes.tableRow_th_vertical_res
                                                : classes.tableRow_th_vertical
                                        }
                                        key={i}
                                        onClick={(): void => {
                                            handleColumnHeaderClick(d.id);
                                        }}
                                    >
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={classes.tableRow_th_div_span_vertical}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                <Tooltip title={d.title} placement={"bottom"}>
                                                    <Typography variant="body1">{d.title}</Typography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={classes.iconButton_wraper}>
                                            {isHeaderExpandable(d.id) && (
                                                <IconButton
                                                    onClick={(): void => {
                                                        handleColumnHeaderClick(d.id);
                                                    }}
                                                    data-testid={
                                                        isHeaderExpanded(d.id)
                                                            ? `collapse-${d.title}`
                                                            : `expand-${d.title}`
                                                    }
                                                    className={classes.iconButton}
                                                    disableFocusRipple
                                                    disableRipple
                                                    edge={false}
                                                    size="small"
                                                    sx={{ paddingLeft: "12px" }}
                                                >
                                                    {isHeaderExpanded(d.id) ? <ChevronLeft /> : <ChevronRight />}
                                                </IconButton>
                                            )}
                                        </div>
                                    </th>
                                ) : (
                                    <th className={classes.tableRow_th_vertical} key={i}>
                                        <div className={classes.tableRow_th_div_children_vertical}>
                                            <div
                                                className={classes.tableRow_th_div_span_vertical_children}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={d.title} alwaysShowTooltip>
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            paddingLeft: 5,
                                                            color: "grey",
                                                            maxHeight: 40,
                                                            minHeight: 30
                                                        }}
                                                    >
                                                        {d.title}
                                                    </Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className={classes.tbody} data-testid="heatmap-data-grid">
                        {displayRows.map((r: string, i: number) => (
                            <tr className={classes.tableRow} key={i}>
                                <td
                                    className={
                                        r === responseTotalHeader
                                            ? classes.rowHeaderCell_overall
                                            : classes.rowHeaderCell
                                    }
                                    data-testid={r === responseTotalHeader ? "Overall" : `rowHeader+${r}`}
                                >
                                    <div className={classes.rowHeaderCell_textWrap}>
                                        {r === responseTotalHeader ? (
                                            <TruncateDisplay
                                                maxLabelLength={23}
                                                title={
                                                    props.isFilteredOverallSelected
                                                        ? lang.overallFiltered
                                                        : props.isPreviousPeriodSelected
                                                          ? lang.overallSS
                                                          : r
                                                }
                                            >
                                                <Typography variant="body1" className={classes.rowHeaderText_overall}>
                                                    {props.isFilteredOverallSelected
                                                        ? lang.overallFiltered
                                                        : props.isPreviousPeriodSelected
                                                          ? lang.overallSS
                                                          : r}
                                                </Typography>
                                            </TruncateDisplay>
                                        ) : (
                                            <Tooltip title={r} placement={"bottom"}>
                                                <Typography variant="body1" className={classes.rowHeaderText}>
                                                    {r}
                                                </Typography>
                                            </Tooltip>
                                        )}
                                    </div>
                                </td>
                                {displayHeader.map((h: heatmapColumnCell, i: number) => {
                                    const valueFound = valueFinder(h, r, false);
                                    const deltaValueFound = valueFinder(h, r, true);
                                    const styleClass = heatmapCellClassMapper(deltaValueFound) as colorClasses;

                                    return (
                                        <td
                                            key={i}
                                            data-testid={
                                                r === lang.responses
                                                    ? "responseHeader"
                                                    : r === responseTotalHeader
                                                      ? `overall-data-cell-${h.title}`
                                                      : `data-cell-${h.title}-${r}`
                                            }
                                            className={
                                                r === responseTotalHeader
                                                    ? `${classes.dataCell} ${classes.cell_overall}`
                                                    : h.title === lang.responses
                                                      ? `${classes.dataCell} ${classes.cell_response}`
                                                      : isFirstLevelHeader(h.id)
                                                        ? `${classes.dataCellSection} ${classes[styleClass]}`
                                                        : `${classes.dataCell} ${classes[styleClass]}`
                                            }
                                        >
                                            {h.title === lang.responses ? (
                                                <Typography variant="body1">{responseCountFinder(r)} </Typography>
                                            ) : r === responseTotalHeader && valueFound !== valueNotFound ? (
                                                <Typography variant="body1">{valueFound}%</Typography>
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        valueFound === valueNotFound
                                                            ? lang.noMeaningfulInfo
                                                            : tooltipTitle(h.title, r)
                                                    }
                                                    placement={"bottom"}
                                                >
                                                    <Typography variant="body1">{valueFound}</Typography>
                                                </Tooltip>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HeatGrid;

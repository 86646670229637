import React from "react";

import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";

import Zoom from "@mui/material/Zoom";

const Tooltip = (props: TooltipProps) => {
    const { children, title, placement } = props;
    return (
        <MuiTooltip placement={placement || "bottom-end"} TransitionComponent={Zoom} {...props} title={title}>
            <div>{children}</div>
        </MuiTooltip>
    );
};

export { Tooltip };
export type { TooltipProps };

import { gql } from "@apollo/client";

export const demographicFieldsAndValuesQuery = gql`
    query demographicFieldsAndValues($surveyId: ID!, $languageCode: String!, $hierarchyId: ID, $cardId: ID) {
        demographicFieldsAndValues(
            surveyId: $surveyId
            languageCode: $languageCode
            hierarchyId: $hierarchyId
            cardId: $cardId
        ) {
            fieldId
            fieldName
            fieldValues
            isHidden
        }
    }
`;

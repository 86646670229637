import React, { useReducer, useEffect, ReactElement, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import initApollo from "../../../api/ApolloClient";
import { createSurveyMutation } from "api/mutations";
import { useLang, useSurveys, useOrgChartService, useLoading, useAdminService } from "core/hooks";
import { Translation } from "core/languages/interfaces";
import { copyArrayWithObjects, isStringEmptyOrSpaces } from "core/helpers";
import { emptySelectedOrganization } from "core/context/inits";
import SurveyImportDialog from "./SurveyImportDialog";
import AdminLayout from "../shared/AdminLayout";
import { Snackbar } from "lib/snackbar";
import InfiniteTable from "../../shared/infiniteTable/InfiniteTable";
import { Column } from "../../shared/infiniteTable/interfaces";
import { TableData } from "../../shared/infiniteTable/types";
import { DataKey, CellType, TableSort } from "../../shared/infiniteTable/enums";
import { mapTableSource } from "../../shared/infiniteTable/hooks";
import EmptyPage from "../../shared/EmptyPage";
import { surveyAdminReducer } from "./reducers";
import { useAdminSurveyStyles } from "./styles";
import { CreateSurveyInput, SurveyAdminState, SurveyGz } from "./interfaces";
import { AdminSurveysRoutes } from "routes/enums";
import { SurveyAdminActions } from "./actions";
import AddResultDialog from "./AddResultDialog";
import { ComponentStatus } from "core/enums";
import { errorMessageParser } from "core/languages/errorMessages/helper/errorMessageParser";
import { Tab, Tabs } from "lib/tabs";
import { Typography } from "lib/typography";
import { useUser } from "core/context/user/useUser";
import { AdminParticipantSurveyInfo, AdminParticipantSurveyResponse } from "components/admin/participants/interfaces";
import dayjs from "dayjs";

const initialState = (): SurveyAdminState => {
    return {
        tabSelected: 0,
        isImportOpen: false,
        isAddResultOpen: false,
        searchString: "",
        snackBar: { isOpen: false, message: "" },
        activatedSurveyList: [],
        deactivatedSurveyList: [],
        surveyOptimization: { surveySelected: null },
        componentStatus: ComponentStatus.idle
    };
};

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.survey,
            minWidth: 170,
            maxWidth: 470,
            width: "100%",
            sortable: true,
            cellType: CellType.link,
            linkTo: `/${AdminSurveysRoutes.results}/${AdminSurveysRoutes.survey}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.participantCount,
            label: lang.participants,
            width: 200,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.responseCount,
            label: lang.participants,
            width: 200,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.lastResponseSubmittedTime,
            label: lang.dateResponseSubmitted,
            width: 250,
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.source,
            label: lang.source,
            width: 200,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.createdTime,
            label: lang.dateCreated,
            width: 200,
            sortable: true,
            cellType: CellType.time,
            isResizable: false,
            order: TableSort.desc
        }
    ];
};

const AdminResults = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const { user } = useUser();
    const { setSurveyList } = useSurveys();
    const adminService = useAdminService();
    const [state, dispatch] = useReducer(surveyAdminReducer, null, initialState);
    const classes = useAdminSurveyStyles();
    const tableColumns = tableColumnsList(lang);
    const orgChartService = useOrgChartService();
    const history = useHistory();

    const graphClient = initApollo(orgChartService.getBearer()).uploadClient;
    const { setLoading } = useLoading();

    const [createSurvey] = useMutation(createSurveyMutation, {
        client: graphClient,
        onCompleted: data => {
            if (data.createSurvey) {
                setSurveyList(data.createSurvey);
                setLoading(true);
                getSurveyLists();
                dispatch({
                    type: SurveyAdminActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.success }
                });
            }
        },
        onError: ({ graphQLErrors }) => {
            if (graphQLErrors) {
                const message = errorMessageParser(graphQLErrors[0].message, languageCode, graphQLErrors[0].extensions);

                dispatch({
                    type: SurveyAdminActions.SNACKBAR_OPEN_CLOSE,
                    payload: { snackBar: { isOpen: true, message, autohideOff: false } }
                });
            }
        }
    });

    const surveyProcessor = useCallback(
        (surveyList: AdminParticipantSurveyInfo[]): TableData[] => {
            let filtered = surveyList;

            if (surveyList && state) {
                if (!isStringEmptyOrSpaces(state.searchString)) {
                    filtered = filtered.filter((s: AdminParticipantSurveyInfo) =>
                        s.name.toLowerCase().includes(state.searchString.toLowerCase())
                    );
                }

                return mapTableSource(filtered, tableColumns);
            } else {
                const emt: TableData[] = [];
                return emt;
            }
        },
        [state, tableColumns]
    );

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        dispatch({
            type: SurveyAdminActions.SELECT_TAB,
            payload: { tabSelected: newValue }
        });
    };

    const handleOpenImportDialog = (): void => {
        dispatch({
            type: SurveyAdminActions.IMPORT_DIALOG_OPEN_CLOSE,
            payload: { isImportOpen: true }
        });
    };

    const handleOpenResultDialog = (): void => {
        dispatch({
            type: SurveyAdminActions.RESULT_DIALOG_OPEN_CLOSE,
            payload: { isAddResultOpen: true }
        });
    };

    const handleCloseResultDialog = (): void => {
        dispatch({
            type: SurveyAdminActions.RESULT_DIALOG_OPEN_CLOSE,
            payload: { isAddResultOpen: false }
        });
    };

    const handleCompleteUploadSurvey = (surveyVariables: CreateSurveyInput): void => {
        dispatch({
            type: SurveyAdminActions.SET_COMPONENT_STATUS,
            payload: { componentStatus: ComponentStatus.loading }
        });
        dispatch({
            type: SurveyAdminActions.IMPORT_DIALOG_OPEN_CLOSE,
            payload: { isImportOpen: false }
        });
        createSurvey({
            variables: { ...surveyVariables }
        });
    };

    const handleSubmitOptimization = (surveySelected: SurveyGz): void => {
        dispatch({
            type: SurveyAdminActions.RESULT_DIALOG_OPEN_CLOSE,
            payload: { isAddResultOpen: false }
        });

        history.push({
            pathname: `/${AdminSurveysRoutes.results}/${AdminSurveysRoutes.survey}/optimization`,
            state: { selectedSurvey: surveySelected }
        });
    };

    const handleCloseImportDialog = (): void => {
        dispatch({
            type: SurveyAdminActions.IMPORT_DIALOG_OPEN_CLOSE,
            payload: { isImportOpen: false }
        });
    };

    const onSearchChange = (searchStr: string): void => {
        dispatch({
            type: SurveyAdminActions.CHANGE_SEARCH_STRING,
            payload: { searchString: searchStr }
        });
    };

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: SurveyAdminActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: false, message: "" } }
        });
    };

    const getSurveyLists = async () => {
        if (user.settings.selectedOrganization.id) {
            adminService
                .getAdminSurveys(user.settings.selectedOrganization.id, languageCode)
                .then(surveys => {
                    const mappedSurveys = surveys.map(
                        (survey: AdminParticipantSurveyResponse): AdminParticipantSurveyInfo => ({
                            id: survey.surveyId,
                            name: survey.name,
                            active: survey.active,
                            participantCount: survey.participantCount,
                            responseCount: survey.responseCount,
                            createdTime: survey.createdTime,
                            lastResponseSubmittedTime: survey.lastResponseSubmittedTime,
                            source: survey.source
                        })
                    );
                    dispatch({
                        type: SurveyAdminActions.SET_SURVEY_LIST,
                        payload: {
                            activatedSurveyList: surveyProcessor(
                                mappedSurveys
                                    .filter((s: AdminParticipantSurveyInfo) => s.active)
                                    .sort((a, b) => (dayjs(b.createdTime).isBefore(dayjs(a.createdTime)) ? -1 : 1))
                            ),
                            deactivatedSurveyList: surveyProcessor(
                                mappedSurveys
                                    .filter((s: AdminParticipantSurveyInfo) => !s.active)
                                    .sort((a, b) => (dayjs(b.createdTime).isBefore(dayjs(a.createdTime)) ? -1 : 1))
                            )
                        }
                    });
                })
                .catch(() => {
                    dispatch({
                        type: SurveyAdminActions.SET_SURVEY_LIST,
                        payload: {
                            activatedSurveyList: [],
                            deactivatedSurveyList: []
                        }
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        setLoading(true);
        getSurveyLists();
    }, [state.searchString, state.isAddResultOpen]);

    if (user.settings.selectedOrganization.id === emptySelectedOrganization.id) {
        return (
            <div className={classes.noOrgSelected}>
                <Typography variant="body2">{lang.noOrganizationSelected}</Typography>
                <Typography variant="body2">{lang.selectBeforeManagingSurveys}</Typography>
            </div>
        );
    }

    return (
        <AdminLayout
            onSearchChange={onSearchChange}
            searchTerm={state.searchString}
            buttonLabel={lang.import}
            buttonOnClick={handleOpenImportDialog}
            addResultLabel={lang.addSurveyResult}
            addResultOnClick={handleOpenResultDialog}
            showButton={user.isTalentMapAdmin}
        >
            <div className={classes.tabs}>
                <Tabs
                    value={state.tabSelected}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleTabChange}
                    aria-label="tabs"
                >
                    <Tab
                        label={`${lang.active} (${state.activatedSurveyList.length.toString()})`}
                        data-testid="tab-admin-activated-list"
                    />
                    <Tab
                        label={`${lang.deactivated} (${state.deactivatedSurveyList.length.toString()})`}
                        data-testid="tab-admin-deactivated-list"
                    />
                </Tabs>
            </div>
            <div className={classes.listContainer} data-testid="admin-result">
                {state.tabSelected === 0 &&
                    (state.activatedSurveyList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={state.activatedSurveyList}
                            defaultOrderByColumn={DataKey.createdTime}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage
                            image={EmptyResults}
                            message={lang.noResultsCreated}
                            subMessage={lang.noResultsCreatedMessage}
                        />
                    ))}
                {state.tabSelected === 1 &&
                    (state.deactivatedSurveyList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={state.deactivatedSurveyList}
                            defaultOrderByColumn={DataKey.createdTime}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage
                            image={EmptyResults}
                            message={lang.noDeactivatedResults}
                            subMessage={lang.noDeactivatedResultsMessage}
                        />
                    ))}
            </div>
            {state.isImportOpen && (
                <SurveyImportDialog
                    isOpen={state.isImportOpen}
                    onClose={handleCloseImportDialog}
                    onCompleteUpload={handleCompleteUploadSurvey}
                />
            )}
            {state.isAddResultOpen && (
                <AddResultDialog
                    isOpen={state.isAddResultOpen}
                    onClose={handleCloseResultDialog}
                    onCompleteOptimization={handleSubmitOptimization}
                />
            )}
            {state.snackBar.isOpen && (
                <Snackbar
                    open={true}
                    handleClose={handleCloseSnackbar}
                    message={state.snackBar.message}
                    autohideOff={state.snackBar.autohideOff}
                />
            )}
        </AdminLayout>
    );
};

export default AdminResults;

import React, { FC, ReactElement } from "react";
import { ReportFilterInput } from "components/filters/interfaces";
import { ContactField } from "components/admin/results/previousPeriod/interfaces";
import { filterConnectorFromBackEnd } from "components/filters/helper";
import FilterChip from "components/shared/FilterChip";
import { initFilter } from "components/filters/inits";

type Props = {
    filter: ReportFilterInput[];
    contactFields: ContactField[];
};

const CellFilterLabel: FC<Props> = ({ filter, contactFields }): ReactElement => {
    const filters =
        filter && filter.length > 0 && contactFields.length > 0
            ? filterConnectorFromBackEnd(filter, contactFields)
            : initFilter.items;
    if (!filters || (filters && filters.length === 0)) return <></>;
    return <FilterChip items={filters} withoutFilterTitle />;
};

export default CellFilterLabel;

import { emptySurveyInfo } from "components/admin/results/init";
import { ResponseRateStoredData } from "components/admin/users/interface";
import { ReportBreakdownType } from "../enums";

export const initBreakdownItem = { id: -1, name: "Select..." };
export const initSurveyOption = { id: emptySurveyInfo.id, name: emptySurveyInfo.translations[0].name };
export const initBreakdownItems = {
    type: ReportBreakdownType.contactField,
    items: [initBreakdownItem]
};

export const initResponseRateStoredData: ResponseRateStoredData = {
    surveySelected: emptySurveyInfo.id,
    breakdownItems: initBreakdownItems
};

import React, { ReactElement, PropsWithChildren } from "react";
import { Typography } from "lib/typography";
import { useLang } from "core/hooks";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

type Props = {
    responseCount: number;
};

export const useStyles = makeStyles(() => ({
    confidentialityContainer: {
        display: "flex",
        flexDirection: "column"
    },
    confidentialityTotalCount: {
        display: "flex",
        alignItems: "center",
        height: 56,
        borderBottom: theme.border.main
    },
    confidentialityTotalCountWrap: {
        display: "flex",
        alignItems: "center",
        width: 380,
        minWidth: 380,
        maxWidth: 380,
        height: 56,
        borderRight: theme.border.main
    }
}));

const InsightConfidentialityCheck = (props: PropsWithChildren<Props>): ReactElement => {
    const { responseCount } = props;
    const classes = useStyles();
    const { lang } = useLang();

    return (
        <div className={classes.confidentialityContainer}>
            <div className={classes.confidentialityTotalCount}>
                <div className={classes.confidentialityTotalCountWrap}>
                    <Typography variant="h6" style={{ paddingLeft: 20 }}>
                        {lang.totalComments} - {responseCount}
                    </Typography>
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default InsightConfidentialityCheck;

import { makeStyles } from "@mui/styles";

import { LanguageCode } from "core/languages/LanguageOptions";
import type { OrderBy } from "./types";
import { theme } from "lib/theme";

export type styleProps = {
    columnWidth: number;
    legendPaddingRight: number;
    isInDialog: boolean;
    orderBy: OrderBy;
    languageCode: LanguageCode;
    stackbarWidth: number;
};

export const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: theme.border.main,
        alignItems: "center",
        height: 50,
        boxSizing: "border-box",
        justifyContent: "space-between"
    },
    headerInfo: { display: "flex", gap: theme.spacing(2), alignItems: "center", width: "calc(100% - 260px)" },
    headerActions: { display: "flex", alignItems: "center" },
    listContainer: (props: styleProps) => {
        return {
            boxSizing: "border-box",
            height: "calc(100% - 106px)",
            overflowY: "auto",
            overflowX: "hidden",
            marginLeft: props.isInDialog ? 0 : 120,
            marginRight: props.isInDialog ? 0 : 120
        };
    },
    content: (props: styleProps) => {
        return {
            display: "grid",
            height: "calc(100% -56px)",
            gridTemplateRows: "56px",
            gridTemplateColumns: "1fr auto auto auto auto auto auto",
            marginLeft: props.isInDialog ? 0 : 120,
            marginRight: props.isInDialog ? 0 : 120
        };
    },
    heardSort: {
        gridRow: "1 / 2",
        gridColumn: "1 / 2",
        boxSizing: "border-box",
        borderLeft: theme.border.main,
        borderBottom: theme.border.main,
        height: "100%",
        paddingLeft: theme.spacing(1.5),
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.16)"
        },
        display: "flex",
        alignItems: "center"
    },
    arrowIcon: ({ orderBy }: styleProps) => {
        return {
            fontSize: "16px",
            color: theme.palette.text.secondary,
            transform: orderBy === "asc" ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all .3s linear"
        };
    },
    previousPeriodSort: {
        display: "flex"
    },
    cellInfo: {
        gridRow: "1 / 2",
        gridColumn: "2 / 3"
    },
    cellOverallTitle: {
        gridRow: "1 / 2",
        gridColumn: "6 / 7",
        width: 78,
        maxWidth: 78,
        minWidth: 78,
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.16)"
        }
    },
    cellPreviousPeriod: {
        gridRow: "1 / 2",
        gridColumn: "3 / 5",
        minWidth: 78,
        maxWidth: 157,
        boxSizing: "border-box"
    },
    individualCellPreviousPeriod: {
        boxSizing: "border-box",
        borderLeft: theme.border.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 78,
        minWidth: 78,
        maxWidth: 78,
        height: "100%",
        paddingLeft: theme.spacing(1),
        "&:hover": {
            cursor: "pointer",
            background: theme.palette.action.hover
        }
    },
    cellBenchmark: (props: styleProps) => ({
        gridRow: "1 / 2",
        gridColumn: "7 / 8",
        width: 78,
        minWidth: 78,
        maxWidth: 78,
        "& >h6": {
            fontSize: props.languageCode === LanguageCode.en ? "11px" : "inherit"
        },
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.16)"
        }
    }),
    cellIcon: (props: styleProps) => {
        return {
            gridRow: "1 / 2",
            gridColumn: "8 / 9",
            width: props.columnWidth,
            maxWidth: 17
        };
    },
    cellIconWhite: (props: styleProps) => {
        return {
            gridRow: "1 / 2",
            gridColumn: "8 / 9",
            width: props.columnWidth,
            maxWidth: 17,
            boxSizing: "border-box",
            borderBottom: theme.border.main,
            borderRight: theme.border.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "0.75rem",
            textAlign: "center"
        };
    },
    cellDemoTitle: (props: styleProps) => ({
        gridRow: "1 / 2",
        gridColumn: "5 / 6",
        width: 78,
        maxWidth: 78,
        wordWrap: "break-word",
        textAlign: "center",
        boxSizing: "border-box",
        "& >h6": {
            fontSize:
                props.languageCode === LanguageCode.es || props.languageCode === LanguageCode.fr ? "10px" : "inherit"
        },
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.16)"
        }
    }),
    cell: {
        boxSizing: "border-box",
        borderLeft: theme.border.main,
        background: theme.palette.action.disabledBackground,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.75rem",
        textAlign: "center"
    },
    legendCell: (props: styleProps) => ({
        width: props.stackbarWidth,
        maxWidth: props.stackbarWidth,
        boxSizing: "border-box",
        borderBottom: theme.border.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.75rem",
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.16)"
        }
    }),
    chartActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        boxSizing: "border-box"
    },
    selected: {
        boxSizing: "border-box",
        border: theme.border.main,
        borderColor: "black"
    },
    favorableUnfavorableItem: {
        boxSizing: "border-box",
        borderLeft: theme.border.main
    }
}));

import React, { PropsWithChildren, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { useUser } from "core/context/user/useUser";
import Search from "../../shared/Search";
import { ButtonLink } from "lib/button-link";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { theme } from "lib/theme";

type Props = {
    onSearchChange: (str: string) => void;
    searchTerm: string;
    showButton: boolean;
    buttonLink?: string;
    buttonLabel?: string;
    addResultLabel?: string;
    addResultDisabled?: boolean;
    buttonOnClick?: () => void;
    addResultOnClick?: () => void;
    addUserLabel?: string;
    addUserOnClick?: () => void;
};

const useStyles = makeStyles(() => ({
    adminLayoutContainer: {
        boxSizing: "border-box",
        height: "100%",
        display: "grid",
        gridTemplateRows: "104px calc(100% - 104px)",
        gridTemplateAreas: `
            "adminHeader"
            "adminContent"
        `
    },
    adminLayoutHeader: {
        boxSizing: "border-box",
        gridArea: "adminHeader",
        width: "100%"
    },
    adminLayoutContent: {
        boxSizing: "border-box",
        gridArea: "adminContent",
        overflowX: "auto",
        overflowY: "hidden"
    },
    adminLayoutActions: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: 34
    },
    adminLayoutActionsLeft: {
        display: "flex",
        alignItems: "center"
    }
}));

const AdminLayout = (props: PropsWithChildren<Props>): ReactElement => {
    const { user } = useUser();
    const classes = useStyles();

    const handleSearchChange = (searchString: string): void => {
        props.onSearchChange(searchString);
    };

    const buttonOnClick = (): void => {
        props.buttonOnClick && props.buttonOnClick();
    };

    const addResultOnClick = (): void => {
        props.addResultOnClick && props.addResultOnClick();
    };

    return (
        <div className={classes.adminLayoutContainer}>
            <div className={classes.adminLayoutHeader}>
                <div className={classes.adminLayoutActions}>
                    <div className={classes.adminLayoutActionsLeft}>
                        <Search onSearchChange={handleSearchChange} searchTerm={props.searchTerm} width={280} />
                        {props.buttonLink && props.addUserLabel && (
                            <ButtonLink
                                variant="text"
                                style={{ marginLeft: "16px" }}
                                link={props.buttonLink}
                                data-testid={props.addUserLabel.replace(" ", "-")}
                            >
                                {props.addUserLabel}
                            </ButtonLink>
                        )}
                    </div>
                    {props.buttonLink && props.buttonLabel ? (
                        <ButtonLink link={props.buttonLink} data-testid={props.buttonLabel.replace(" ", "-")}>
                            {props.buttonLabel}
                        </ButtonLink>
                    ) : props.addResultLabel && props.buttonLabel && user.isTalentMapAdmin ? (
                        <Box display="flex" gap={2}>
                            <Button
                                onClick={addResultOnClick}
                                disabled={props.addResultDisabled}
                                data-testid="btn-add-result"
                            >
                                {props.addResultLabel}
                            </Button>

                            <Button onClick={buttonOnClick} data-testid="btn-import">
                                {props.buttonLabel}
                            </Button>
                        </Box>
                    ) : props.buttonLabel && props.showButton ? (
                        <Button onClick={buttonOnClick} data-testid={props.buttonLabel}>
                            {props.buttonLabel}
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className={classes.adminLayoutContent}>{props.children}</div>
        </div>
    );
};

export default AdminLayout;

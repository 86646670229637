export enum BulkReportType {
    Comment,
    Correlation,
    Heatmap,
    Snapshot,
    Dashboard
}

export interface BulkReportStatus {
    id: number;
    fieldName: string;
    reportType: BulkReportType;
    progress: number;
    fileName: string;
    statusDetails: string;
    queuePosition: number;
    lastModified: string;
    status: OverallBulkStatus;
    title: string;
}

export interface OverallBulkReportStatus {
    id: number;
    progress: number;
    reportType: BulkReportType;
    fieldName: string;
    fileName: string;
    statusDetails: string;
    status: OverallBulkStatus;
    queuePosition: number;
    lastModified: string;
    createdDate: string;
    organizationName: string;
    title: string;
    email: string;
}

export enum OverallBulkStatus {
    New,
    InProgress,
    Complete,
    Canceled,
    Errored
}

export interface OverallBulkReportResponse {
    completed: OverallBulkReportStatus[];
    failed: OverallBulkReportStatus[];
    queued: OverallBulkReportStatus[];
}

export interface OverallBulkReportForUser {
    title: string;
    status: OverallBulkStatus;
    progress: number;
    queuePosition: number;
    completedDate: string;
    createdDate: string;
}

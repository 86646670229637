import { CombinationOperator, ComparisonOperator, FilterType } from "components/filters/enums";
import { Filter, FilterParameter } from "components/filters/interfaces";

export const DemographicCompareFilterMapper = (
    optionId: number,
    targetName: string,
    filter: Filter
): FilterParameter[] | null => {
    const defaultIdHM = 0;
    const defaultIDSN = -1;
    const filteredOverallId = -2;

    if (optionId === undefined || optionId === defaultIdHM || optionId === defaultIDSN) {
        return null;
    }

    if (optionId === filteredOverallId && filter) {
        return filter.items;
    }

    const compareFilter: FilterParameter[] = [
        {
            filterType: FilterType.contactField,
            comparisonOperator: ComparisonOperator.equals,
            combinationOperator: CombinationOperator.and,
            field: { id: optionId, fieldName: "" },
            target: [{ id: targetName }],
            order: 0
        }
    ];

    return compareFilter;
};

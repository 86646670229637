import { Action } from "../../../../core/interfaces";
import { UserMutate } from "../interface";
import { UserMutateActions } from "../actions";

export const UserMutateReducer = (state: UserMutate, action: Action): UserMutate => {
    const { type, payload } = action;

    switch (type) {
        case UserMutateActions.SET_USER:
            return {
                ...state,
                user: payload.user
            };
        case UserMutateActions.SET_SNACKBAR_STATUS:
            return {
                ...state,
                snackbar: payload.snackbar
            };
        case UserMutateActions.SET_VALIDATION_ERROR:
            return {
                ...state,
                validation: payload.validation
            };
        default:
            return { ...state };
    }
};

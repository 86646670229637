import React, { ReactElement } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    heatmapLookdownItem,
    HeatmapCompareOption
} from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { DemographicOptions } from "components/reports/heatmap/interface";
import { useLang, useSnackbar } from "core/hooks";
import { Filter } from "components/filters/interfaces";
import { Box } from "lib/box";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { IconButton } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";

type Props = {
    compareOptions: DemographicOptions[];
    heatmapLookdownItem: heatmapLookdownItem;
    heatmapLookdownItems: heatmapLookdownItem[];
    updateLookdownItem: (item: heatmapLookdownItem, index: number) => void;
    index: number;
    deleteLookdownItem: (index: number) => void;
    currentFilter: Filter;
    isBulkExport: boolean;
    previewMode: boolean;
    showPreviousPeriodOptionInHeatmap: boolean;
    disablePreviousPeriodOptionInHeatmap: boolean;
};

const HeatMapLookdownList = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();
    const { setMessage } = useSnackbar();
    const displayWarningThreshold = 16;
    const filterCompareOptions = props.previewMode
        ? [{ id: HeatmapCompareOption.overall, name: lang.overall }]
        : props.showPreviousPeriodOptionInHeatmap
          ? [
                { id: HeatmapCompareOption.overall, name: lang.overall },
                { id: HeatmapCompareOption.filtered, name: lang.overallFiltered },
                { id: HeatmapCompareOption.previousPeriod, name: lang.previousPeriod }
            ]
          : [
                { id: HeatmapCompareOption.overall, name: lang.overall },
                { id: HeatmapCompareOption.filtered, name: lang.overallFiltered }
            ];

    const handleFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            props.updateLookdownItem(
                { ...props.heatmapLookdownItem, demographicFieldId: -1, demographicField: "" },
                props.index
            );
            return;
        }
        const { id, label } = value;

        //check if too many columns in heatmap lookdown
        if (
            props.currentFilter &&
            props.currentFilter.items &&
            props.currentFilter.items.filter(item => item.field.id === id).length > 0
        ) {
            const filteredField = props.currentFilter.items.filter(item => item.field.id === id)[0];
            if (filteredField && filteredField.target.length > displayWarningThreshold) {
                setMessage(`${lang.heatmapWarningPrefix}: ${label} ${lang.heatmapWarningSuffix}`, true);
            }
        } else {
            const heatmapLookdownValues = props.compareOptions.find(option => option.id === id)?.values;
            //threshold set as 16 columns
            if (heatmapLookdownValues && heatmapLookdownValues.length > displayWarningThreshold) {
                setMessage(`${lang.heatmapWarningPrefix}: ${label} ${lang.heatmapWarningSuffix}`, true);
            }
        }
        props.updateLookdownItem(
            { ...props.heatmapLookdownItem, demographicFieldId: id, demographicField: label },
            props.index
        );
    };

    const handleSelectFilterOption = (value: AutocompleteValue | null): void => {
        if (!value) return;

        props.updateLookdownItem({ ...props.heatmapLookdownItem, compareOption: value.id }, props.index);
    };

    const selectedDemographic = (id: number) => {
        const found = props.compareOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    const selectedFilterCompare = (id: number) => {
        const found = filterCompareOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    return (
        <Box display="flex" gap={2} pl={2}>
            <Box width={400}>
                <Autocomplete
                    id={`ss-export-hm-combo-box-${props.index}`}
                    options={props.compareOptions
                        .filter(option => option.id !== -1)
                        .map(s => {
                            return {
                                id: s.id,
                                label: s.name
                            };
                        })}
                    noOptionsText={lang.noOptionsAvailable}
                    placeholder={lang.demographics}
                    value={selectedDemographic(props.heatmapLookdownItem.demographicFieldId)}
                    onChange={handleFieldSelected}
                />
            </Box>
            <Box width={400} mr={1}>
                <Autocomplete
                    id={`ss-export-hm-compare-option-${props.index}`}
                    options={filterCompareOptions.map(s => {
                        return {
                            id: s.id,
                            label: s.name
                        };
                    })}
                    getOptionDisabled={(option): boolean => {
                        if (
                            props.disablePreviousPeriodOptionInHeatmap &&
                            option.id === HeatmapCompareOption.previousPeriod
                        ) {
                            return true;
                        }
                        if (
                            props.currentFilter &&
                            props.currentFilter.items &&
                            props.currentFilter.items.length == 0 &&
                            !user.isRestricted &&
                            !props.isBulkExport &&
                            option.id === HeatmapCompareOption.filtered
                        ) {
                            return true;
                        }

                        return false;
                    }}
                    noOptionsText={lang.noOptionsAvailable}
                    placeholder={lang.compareTo}
                    value={selectedFilterCompare(props.heatmapLookdownItem.compareOption)}
                    onChange={handleSelectFilterOption}
                    label={lang.compareTo}
                />
            </Box>
            <IconButton
                aria-label="delete"
                data-testid={`heatmspLookdown-delete-${props.index}`}
                onClick={(event): void => {
                    event.stopPropagation();
                    props.deleteLookdownItem(props.index);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

export default HeatMapLookdownList;

import React, { ReactElement, ChangeEvent, useRef } from "react";

import { surveyItemTranslation } from "managerPortal/context/interfaces";
import { useLang } from "core/hooks";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
import { Checkbox } from "lib/checkbox";
import { Box } from "lib/box";

type Props = {
    customizedSurveyItem: surveyItemTranslation[];
    surveySelectedId: number;
    handleChangeField: (e: React.ChangeEvent<HTMLInputElement>, languageCode: string) => void;
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleFieldOnBlur: (languageCode: string) => void;
};

const ConvertSurveyItemArea = (props: Props): ReactElement => {
    const { lang } = useLang();
    const textRefs = useRef<HTMLDivElement[]>([]);

    const checkboxDisabled = (languageCode: string): boolean => {
        const index = props.customizedSurveyItem.findIndex(translation => translation.languageCode === languageCode);
        return props.customizedSurveyItem[index].title === "";
    };

    const getLanguageTranslation = (languageCode: string): string => {
        switch (languageCode) {
            case "en":
                return lang.english;
            case "es":
                return lang.spanish;
            case "fr":
                return lang.french;
            default:
                return lang.english;
        }
    };
    const handleOnFocus = (index: number): void => {
        if (textRefs.current[index]) {
            const textInput = textRefs.current[index] as HTMLInputElement;
            textInput.select();
        }
    };

    return (
        <Box pl={3} pr={3} pt={2}>
            <Box display="flex" alignItems="center">
                <Typography variant="body1" maxWidth={120} width={120}>
                    {lang.language}
                </Typography>
                <Typography variant="body1" flexGrow={1} mr={1}>
                    {lang.reportLabel}
                </Typography>
                <Typography variant="body1" textAlign="center" maxWidth={120} width={120}>
                    {lang.addToDemographics}
                </Typography>
            </Box>

            {props.customizedSurveyItem.map((item, index) => (
                <Box key={item.languageCode} display="flex" alignItems="center" mt={2}>
                    <Typography variant="body1" maxWidth={120} width={120}>
                        {getLanguageTranslation(item.languageCode)}
                    </Typography>
                    <Box flexGrow={1} mr={1}>
                        <TextField
                            name={"surveyItemField"}
                            value={item.contactFieldName !== null ? item.contactFieldName : item.title}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                props.handleChangeField(e, item.languageCode);
                            }}
                            onBlur={(): void => {
                                props.handleFieldOnBlur(item.languageCode);
                            }}
                            onFocus={(): void => {
                                handleOnFocus(index);
                            }}
                            inputProps={{ style: { fontSize: 16 } }}
                            hiddenLabel={true}
                            data-testid={`text-field-survey-item-${item.languageCode}`}
                            inputRef={(el: HTMLDivElement): HTMLDivElement => (textRefs.current[index] = el)}
                        />
                    </Box>
                    <Box
                        maxWidth={120}
                        width={120}
                        data-testid={`checkbox-survey-item-${item.languageCode}`}
                        display={"flex"}
                        justifyContent="center"
                    >
                        <Checkbox
                            value={item.languageCode}
                            checked={item.contactFieldName !== null}
                            disabled={checkboxDisabled(item.languageCode)}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => props.handleCheckboxChange(event)}
                        />
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default ConvertSurveyItemArea;

import React, { useState, ReactElement, ChangeEvent } from "react";
import { makeStyles } from "@mui/styles";

import MPDialogLayout from "../../layout/MPDialogLayout";
import { Hierarchy } from "../hierarchy/interfaces";
import { useLang, useExistingHierarchies } from "core/hooks";
import { theme } from "lib/theme";
import { TextField } from "lib/text-field";

interface Props {
    hierarchy: Hierarchy;
    onClose: () => void;
    onSave: (a: Hierarchy) => void;
}

const useStyles = makeStyles(() => ({
    content: {
        padding: theme.spacing(3)
    }
}));

const RenameHierarchy = (props: Props): ReactElement => {
    const { existingHierarchies } = useExistingHierarchies();
    const { lang } = useLang();
    const classes = useStyles();
    const { hierarchy, onSave, onClose } = props;
    const [name, setName] = useState(hierarchy.Name);
    const [error, setError] = useState("");

    const handleChangeName = (): void => {
        const isDuplicatedName =
            existingHierarchies.some((h: Hierarchy) => name.toLowerCase() === h.Name.toLowerCase()) &&
            hierarchy.Name !== name;

        const isNameEmpty = !name || !name.trim();

        !isDuplicatedName && !isNameEmpty
            ? onSave({ ...hierarchy, Name: name })
            : isNameEmpty
              ? setError(lang.nameCannotBeEmpty)
              : setError(lang.duplicatedHierarchyName);
    };

    const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const text = event.target.value;
        if (!text || !text.trim()) {
            setError(lang.nameCannotBeEmpty);
        } else {
            setError("");
        }

        setName(text);
    };

    const handleKeyUp = (): void => {
        if (!error) {
            handleChangeName();
        }
    };

    return (
        <MPDialogLayout
            primaryTitle={lang.editHierarchyName}
            isDialogOpen={true}
            handleCloseDialog={onClose}
            handleAction={handleChangeName}
            dataTestId="dialog-rename-hierarchy"
            paperProps={{ style: { width: 444, height: "auto" } }}
            disableCloseButton={!!error}
            buttonsLabel={{ action: lang.ok, close: lang.cancel }}
            isDoubleAction
            handleKeyUp={handleKeyUp}
            disableActionButton={error !== ""}
        >
            <div className={classes.content}>
                <TextField
                    id="hierarchyRename"
                    label={lang.hierarchyName}
                    value={name}
                    onChange={handleTextFieldChange}
                    error={!!error}
                    helperText={error}
                />
            </div>
        </MPDialogLayout>
    );
};

export default RenameHierarchy;

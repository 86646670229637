import React from "react";

import MuiMenu, { MenuProps } from "@mui/material/Menu";

const Menu = (props: MenuProps) => {
    const { children, ...rest } = props;
    return <MuiMenu {...rest}>{children}</MuiMenu>;
};

export { Menu };

import React, { ReactElement } from "react";
import { useLang } from "core/hooks";
import { makeStyles } from "@mui/styles";
import { PanelItem, DashboardData } from "./interface";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { itemHeight, itemWidth } from "./DashboardItem";
import { emptyBarColor, favorableColor, neutralColor, unfavorableColor } from "managerPortal/styles/GlobalStyles";
import StackedBar from "managerPortal/components/stackedBar/StackedBar";
import { darkGreyColor } from "managerPortal/styles/GlobalStyles";
import { dashboardRowLimits } from "./UserDashboard";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

type CellTypeStyleProps = {
    xPosition: number;
    yPosition: number;
    numberOfColumn: number;
    numberOfRow: number;
    height: number;
    width: number;
    numberOfshowSideBar: number;
    numberOfRows: number;
};

interface Props {
    testid: number;
    item: PanelItem;
    dashboardData: DashboardData;
}

const useStyles = makeStyles(() => ({
    cell: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`,
            height: itemHeight * styleProps.height,
            width: itemWidth * styleProps.width
        };
    },
    cellText: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "10%",
            minHeight: "10%",
            maxHeight: "10%",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            justifyContent: "space-between"
        };
    },
    title: () => {
        return {
            display: "flex",
            justifyContent: "center",
            fontSize: 16,
            fontWeight: 500
        };
    },
    titleSide: (styleProps: CellTypeStyleProps) => {
        return {
            width: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12
        };
    },
    chart: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "90%",
        maxHeight: "90%",
        overflowY: "hidden",
        overflowX: "hidden"
    },
    overallDimension: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: `${90 / styleProps.numberOfRows}%`,
            maxHeight: "30%",
            width: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth
        };
    },
    overallDimensionName: (styleProps: CellTypeStyleProps) => {
        return {
            display: "-webkit-box",
            lineHeight: "1.2em",
            height: "2.4em",
            width: (styleProps.width * itemWidth) / 3,
            minWidth: (styleProps.width * itemWidth) / 3,
            maxWidth: (styleProps.width * itemWidth) / 3,
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            whiteSpace: "initial",
            textOverflow: "ellipsis"
        };
    },
    overallDimensionNameOverall: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.width * itemWidth) / 3,
            minWidth: (styleProps.width * itemWidth) / 3,
            maxWidth: (styleProps.width * itemWidth) / 3,
            justifyContent: "flex-end"
        };
    },
    OverallDimensionChartWrapper: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            minWidth: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            maxWidth: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            justifyContent: "center"
        };
    },
    overallDimensionChart: {
        display: "flex",
        height: "80%",
        alignItems: "center",
        width: "95%",
        maxWidth: "95%",
        justifyContent: "flex-end"
    },
    displayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            justifyContent: "center"
        };
    },
    singleDisplayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: (styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12
        };
    },
    singleTitleDisplayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: (styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12
        };
    },
    valueNA: {
        color: darkGreyColor,
        fontWeight: "bold"
    },
    valuePositive: {
        color: favorableColor,
        fontWeight: "bold"
    },
    valueNegative: {
        color: unfavorableColor,
        fontWeight: "bold"
    },
    noData: {
        fontSize: 12
    },
    noDataCondense: {
        fontSize: 10
    },
    tooManyRows: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const CellSingleDimensionWithItem = (props: Props): ReactElement => {
    const { item, dashboardData, testid } = props;

    const surveySectionsArray = Object.values(dashboardData.sections);
    const sectionFound = surveySectionsArray.find(section => section.id === JSON.parse(item.props)?.id);
    const itemsArray = sectionFound ? Object.values(sectionFound.items) : [];

    const getNumberOfSideBar = (): number => {
        if (JSON.parse(item.props).hideBMDelta && JSON.parse(item.props).hidePPDelta) return 0;
        let resultNumber = 0;
        const BMValue = surveySectionsArray.map(section => section.benchmarkDelta);
        if (!JSON.parse(item.props).hideBMDelta && BMValue.some(v => v !== null)) {
            resultNumber += 1;
        }
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            resultNumber += dashboardData.previousPeriodLabels.length;
        }
        return resultNumber;
    };

    const getNumberOfRows = (): number => {
        const sectionFound = surveySectionsArray.find(section => section.id === JSON.parse(item.props)?.id);
        if (!sectionFound) return 0;
        return Object.values(sectionFound.items).length + 1;
    };

    const classes = useStyles({
        xPosition: item.xPosition,
        yPosition: item.yPosition,
        numberOfColumn: GRID_COL_LENGTH,
        numberOfRow: GRID_ROW_LENGTH,
        height: item.height,
        width: item.width,
        numberOfshowSideBar: getNumberOfSideBar(),
        numberOfRows: getNumberOfRows()
    });
    const { lang, languageCode } = useLang();
    const isCondensed = getNumberOfRows() > 15;
    const getDimensionOrItemName = (id: number, isSection: boolean): string => {
        if (isSection) {
            const sectionFound = surveySectionsArray.find(section => section.id === id);
            return sectionFound ? sectionFound.text : "";
        } else {
            const itemFound = itemsArray.find(item => item.id === id);
            return itemFound ? itemFound.text : "";
        }
    };

    const calculateTotal = (unfavorablePercent: number, neutralPercent: number, favorablePercent: number): number => {
        let total = 0;
        total = [unfavorablePercent, neutralPercent, favorablePercent].reduce(
            (a: number, b: number): number => a + b,
            0
        );
        return total;
    };

    const getDimensionOrItemDisplayValues = (id: number, isSection: boolean): number[] => {
        let sectionOrItemFound;
        if (isSection) {
            sectionOrItemFound = surveySectionsArray.find(section => section.id === id);
        } else {
            sectionOrItemFound = itemsArray.find(item => item.id === id);
        }
        if (!sectionOrItemFound) return [0];
        if (
            calculateTotal(
                sectionOrItemFound.unfavorablePercent,
                sectionOrItemFound.neutralPercent,
                sectionOrItemFound.favorablePercent
            ) <= 0
        )
            return [0];
        return [
            sectionOrItemFound.unfavorablePercent,
            sectionOrItemFound.neutralPercent,
            sectionOrItemFound.favorablePercent
        ];
    };

    const getDimensionOrItemColors = (id: number, isSection: boolean): string[] => {
        let sectionOrItemFound;
        if (isSection) {
            sectionOrItemFound = surveySectionsArray.find(section => section.id === id);
        } else {
            sectionOrItemFound = itemsArray.find(item => item.id === id);
        }
        if (!sectionOrItemFound) return [emptyBarColor];
        if (
            calculateTotal(
                sectionOrItemFound.unfavorablePercent,
                sectionOrItemFound.neutralPercent,
                sectionOrItemFound.favorablePercent
            ) <= 0
        )
            return [emptyBarColor];
        return [unfavorableColor, neutralColor, favorableColor];
    };
    const getDimensionOrItemDisplayPercentage = (id: number, isSection: boolean): boolean => {
        let sectionOrItemFound;
        if (isSection) {
            sectionOrItemFound = surveySectionsArray.find(section => section.id === id);
        } else {
            sectionOrItemFound = itemsArray.find(item => item.id === id);
        }
        if (!sectionOrItemFound) return false;
        return (
            calculateTotal(
                sectionOrItemFound.unfavorablePercent,
                sectionOrItemFound.neutralPercent,
                sectionOrItemFound.favorablePercent
            ) > 0
        );
    };
    const getSideBarDiplayNumber = (delta: number | null): string => {
        if (delta === undefined) return "n/a";
        if (delta === null) return "n/a";
        if (delta === 0) return "0";
        if (delta > 0) {
            return `+${delta}`;
        } else return delta.toString();
    };

    const getSideBar = (id: number, isSection: boolean): ReactElement => {
        let sectionOrItem;
        if (isSection) {
            sectionOrItem = dashboardData.sections[id];
        } else {
            sectionOrItem = dashboardData.items[id];
        }
        if (!sectionOrItem) return <></>;
        const displayBar = [] as (number | null)[];
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            for (let i = 0; i < dashboardData.previousPeriodLabels.length; i++) {
                if (sectionOrItem.previousPeriodDeltas) {
                    displayBar.push(sectionOrItem.previousPeriodDeltas[i]);
                } else {
                    displayBar.push(null);
                }
            }
        }
        const BMValue = [] as (number | null)[];
        if (sectionFound) {
            BMValue.push(sectionFound.benchmarkDelta);
        }
        for (let i = 0; i < itemsArray.length; i++) {
            if (itemsArray[i].benchmarkDelta) {
                BMValue.push(itemsArray[i].benchmarkDelta);
            } else {
                BMValue.push(null);
            }
        }
        if (!JSON.parse(item.props).hideBMDelta && BMValue.some(v => v !== null)) {
            displayBar.push(sectionOrItem.benchmarkDelta);
        }
        return (
            <div className={classes.displayBar}>
                {displayBar.map((delta, index) => (
                    <div
                        key={index}
                        className={classes.singleDisplayBar}
                        data-testid={
                            isSection
                                ? `overall-index-${index}`
                                : `${getDimensionOrItemName(id, isSection)}-index-${index}`
                        }
                    >
                        <Typography
                            variant={isCondensed ? "caption" : "body2"}
                            className={
                                delta && delta > 0
                                    ? classes.valuePositive
                                    : delta && delta < 0
                                      ? classes.valueNegative
                                      : classes.valueNA
                            }
                        >
                            {getSideBarDiplayNumber(delta)}
                        </Typography>
                    </div>
                ))}
            </div>
        );
    };

    const getSideBarTitle = (): ReactElement => {
        const displayTitle = [] as string[];
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            for (let i = 0; i < dashboardData.previousPeriodLabels.length; i++) {
                displayTitle.push(dashboardData.previousPeriodLabels[i]);
            }
        }
        const BMValue = [] as (number | null)[];
        if (sectionFound) {
            BMValue.push(sectionFound.benchmarkDelta);
        }
        for (let i = 0; i < itemsArray.length; i++) {
            if (itemsArray[i].benchmarkDelta) {
                BMValue.push(itemsArray[i].benchmarkDelta);
            } else {
                BMValue.push(null);
            }
        }
        if (!JSON.parse(item.props).hideBMDelta && BMValue.some(v => v !== null)) {
            displayTitle.push(lang.bm);
        }
        return (
            <div className={classes.displayBar}>
                {displayTitle.map((title, index) => (
                    <div key={index} className={classes.singleTitleDisplayBar}>
                        <Typography style={{ fontWeight: "bold", fontSize: 12, marginBottom: -4 }}>+/-</Typography>
                        <Typography
                            variant={isCondensed ? "caption" : "body2"}
                            style={{ fontWeight: "bold", fontSize: 12 }}
                        >
                            {title}
                        </Typography>
                    </div>
                ))}
            </div>
        );
    };

    const getChart = (): ReactElement => {
        const sectionId = JSON.parse(item.props)?.id;
        return (
            <div className={classes.chart}>
                <div className={classes.overallDimension} data-testid={`singleDimensionWithItems-overall`}>
                    <div className={classes.overallDimensionNameOverall}>
                        <Typography variant={isCondensed ? "caption" : "body2"} style={{ paddingRight: 4 }}>
                            {lang.dashboardOverall}
                        </Typography>
                    </div>
                    <div className={classes.OverallDimensionChartWrapper}>
                        <div className={classes.overallDimensionChart}>
                            {getDimensionOrItemDisplayValues(sectionId, true) ? (
                                <StackedBar
                                    values={getDimensionOrItemDisplayValues(sectionId, true)}
                                    colors={getDimensionOrItemColors(sectionId, true)}
                                    displayAllPercentage={getDimensionOrItemDisplayPercentage(sectionId, true)}
                                    percentageFontSize={isCondensed ? 10 : 12}
                                    sectionLevel
                                />
                            ) : (
                                <Typography className={isCondensed ? classes.noDataCondense : classes.noData}>
                                    {lang.noData}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {getSideBar(sectionId, true)}
                </div>
                {itemsArray.length > 0 ? (
                    itemsArray.map(item => (
                        <div
                            key={item.id}
                            className={classes.overallDimension}
                            data-testid={`singleDimensionWithItems-${getDimensionOrItemName(item.id, false)}`}
                        >
                            <div className={classes.overallDimensionName}>
                                <Tooltip title={getDimensionOrItemName(item.id, false)} placement={"right"}>
                                    <Typography variant={isCondensed ? "caption" : "body2"}>
                                        {getDimensionOrItemName(item.id, false)}
                                    </Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.OverallDimensionChartWrapper}>
                                <div className={classes.overallDimensionChart}>
                                    {getDimensionOrItemDisplayValues(item.id, false) ? (
                                        <StackedBar
                                            values={getDimensionOrItemDisplayValues(item.id, false)}
                                            colors={getDimensionOrItemColors(item.id, false)}
                                            displayAllPercentage={getDimensionOrItemDisplayPercentage(item.id, false)}
                                            percentageFontSize={isCondensed ? 10 : 12}
                                            sectionLevel
                                        />
                                    ) : (
                                        <Typography className={isCondensed ? classes.noDataCondense : classes.noData}>
                                            {lang.noData}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            {getSideBar(item.id, false)}
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <div className={classes.cell} data-testid={`panel-${testid}`}>
            <div className={classes.cellText}>
                <div className={classes.titleSide}></div>
                <Tooltip
                    title={item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    placement={"bottom"}
                >
                    <Typography variant="body2" className={classes.title} style={{ fontWeight: 500 }}>
                        {item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    </Typography>
                </Tooltip>
                {getNumberOfRows() > dashboardRowLimits ? (
                    <div className={classes.titleSide}></div>
                ) : (
                    <div className={classes.titleSide}>{getSideBarTitle()}</div>
                )}
            </div>
            {getNumberOfRows() > dashboardRowLimits ? (
                <div className={classes.tooManyRows}>{lang.tooManyRowsWarning}</div>
            ) : (
                getChart()
            )}
        </div>
    );
};

export default CellSingleDimensionWithItem;

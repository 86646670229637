import React, { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Popover } from "lib/popover";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import BulkStatusDialog from "components/shared/BulkStatusDialog";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { useLang, useLoading } from "core/hooks";
import { Filter, UserFilterInput } from "components/filters/interfaces";
import { CombinationOperatorBackEndConnector, ComparisonOperatorBackEndConnector } from "components/filters/enums";
import { emptySurveyInfo } from "components/admin/results/init";
import { sortArray } from "core/helpers";
import { MapSurveyToAutocompleteOption } from "./ReportsLayout";
import { ReportRoutes } from "routes/enums";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { TabOptions } from "components/reports/comment/Comment";
import { Chip } from "lib/chip";
import { Typography } from "lib/typography";
import { colorRange000To049 } from "managerPortal/styles/GlobalStyles";
import { initRestrictedUserFilterGroup } from "components/admin/users/initialVariables/InitUsers";
import { RestrictedUserFilterGroup } from "components/admin/users/interface/AdminUser.interfaces";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: 12
    },
    headerAction: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        borderBottom: theme.border.main,
        boxSizinh: "border-box",
        height: 72
    },
    headerButtons: {
        display: "flex",
        alignItems: "center"
    },
    popoverContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px 12px"
    },
    popoverItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}));

type Props = {
    mapSurveys: MapSurveyToAutocompleteOption[];
    selectedSurvey: MapSurveyToAutocompleteOption;
    exportDisabled: boolean;
    filterDisabled: boolean;
    selectDisabled: boolean;
    filter: Filter;
    extraAction?: ReactElement;
    handleSurveySelected: (id: number) => void;
    handleStartExport: (isBulkReport: boolean) => void;
    handleOpenFilter: () => void;
    startSnapshotExcelExport?: () => void;
    startCommentExcelExport?: () => void;
    commentTabValue?: TabOptions;
    contactFields: HrisFieldInfo[];
};

export const ReportsHeader = ({
    mapSurveys,
    selectedSurvey,
    exportDisabled,
    filterDisabled,
    selectDisabled,
    filter,
    extraAction,
    handleSurveySelected,
    handleStartExport,
    handleOpenFilter,
    startSnapshotExcelExport,
    startCommentExcelExport,
    commentTabValue,
    contactFields
}: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [restrictedUserFilterGroup, setRestrictedUserFilterGroup] =
        useState<RestrictedUserFilterGroup>(initRestrictedUserFilterGroup);
    const { isLoading } = useLoading();
    const { user } = useUser();
    const handleOpenExportOptions = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopOver = (): void => {
        setAnchorEl(null);
    };

    const onSurveySelect = (value: AutocompleteValue | null) => {
        if (value !== null && value !== undefined) {
            handleSurveySelected(value.id);
            return;
        }
    };

    const getContactField = (fieldId?: number): string => {
        if (fieldId) {
            const found = contactFields.find((field: HrisFieldInfo) => field.fieldId === fieldId);
            if (found) return found.fieldName;
        }
        return "";
    };

    const makeChipLabel = (item: UserFilterInput): string => {
        return `${getContactField(item.id)} ${makeComparisonLabel(item)}
            ${makeTargetValueList(item)}`;
    };

    const makeComparisonLabel = (item: UserFilterInput): string => {
        return item.comparisonOperator === ComparisonOperatorBackEndConnector.EQUALS
            ? "="
            : item.comparisonOperator === ComparisonOperatorBackEndConnector.NOTEQUALS
              ? "≠"
              : "";
    };

    const makeTargetValueList = (item: UserFilterInput): string => {
        return item.target.join(", ");
    };

    useEffect(() => {
        if (
            selectedSurvey.id &&
            selectedSurvey.id !== emptySurveyInfo.id &&
            user.userFilters &&
            user.userFilters.length > 0
        ) {
            const selected = user.userFilters.filter(group => group.surveyId === selectedSurvey.id)[0];
            if (selected) {
                setRestrictedUserFilterGroup(selected);
            }
        }
    }, [selectedSurvey]);

    return (
        <div className={classes.headerAction}>
            <Box display="flex" alignItems={"center"} maxWidth={"calc(100% - 300px)"} overflow={"hidden"}>
                <Box width={400} minWidth={400}>
                    <Autocomplete
                        id="page-reports-select-survey"
                        noOptionsText={lang.noOptionsAvailable}
                        value={selectedSurvey}
                        options={sortArray(
                            mapSurveys.filter(s => s.id !== -1),
                            "label",
                            "asc"
                        )}
                        defaultValue={{ id: emptySurveyInfo.id, label: "" }}
                        placeholder={lang.survey}
                        onChange={onSurveySelect}
                        getOptionDisabled={(option): boolean => selectedSurvey.id === option.id}
                        disabled={selectDisabled}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                <span>{option.label}</span>
                            </li>
                        )}
                    />
                </Box>
                {user.isRestricted && (
                    <Box ml={2} mr={2} display={"flex"} alignItems={"center"}>
                        <Typography mr={1} variant="body1">
                            {`${lang.group}: `}
                        </Typography>
                        {restrictedUserFilterGroup?.filters ? (
                            JSON.parse(restrictedUserFilterGroup.filters).map((item: UserFilterInput, i: number) => (
                                <Box key={i} display={"flex"} alignItems={"center"}>
                                    {i > 0 && (
                                        <Typography variant="body2" ml={1} mr={1}>
                                            {item.combinationOperator === CombinationOperatorBackEndConnector.AND
                                                ? `${lang.and}`
                                                : `${lang.or}`}
                                        </Typography>
                                    )}
                                    <Chip sx={{ backgroundColor: colorRange000To049 }} label={makeChipLabel(item)} />
                                </Box>
                            ))
                        ) : (
                            <></>
                        )}
                    </Box>
                )}
            </Box>
            <Box pr={3} display="flex" gap={2}>
                {extraAction && extraAction}
                <Box>
                    <Button
                        variant="outlined"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                            if (
                                location.pathname.includes(ReportRoutes.responseRate) ||
                                location.pathname.includes(ReportRoutes.insight)
                            ) {
                                handleClosePopOver();
                                handleStartExport(false);
                            } else {
                                handleOpenExportOptions(e);
                            }
                        }}
                        data-testid="reports-layout-export-btn"
                        disabled={exportDisabled || isLoading}
                        endIcon={
                            location.pathname.includes(ReportRoutes.responseRate) ||
                            location.pathname.includes(ReportRoutes.insight) ? null : (
                                <ArrowDropDown
                                    color={isLoading || exportDisabled ? "disabled" : "primary"}
                                    style={{ marginLeft: -4 }}
                                />
                            )
                        }
                        style={{ marginRight: 18 }}
                    >
                        {lang.export}
                    </Button>
                </Box>
                <Popover
                    classes={{
                        paper: classes.paper
                    }}
                    open={anchorEl !== null}
                    onClose={handleClosePopOver}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <div className={classes.popoverContainer} data-testid="export-options">
                        {location.pathname.includes(ReportRoutes.userDashboard) && (
                            <div className={classes.popoverItem}>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        handleClosePopOver();
                                        handleStartExport(false);
                                    }}
                                    data-testid="dashboard-export-button"
                                >
                                    {lang.powerpoint}
                                </Button>
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(true);
                                        }}
                                        data-testid={"dashboard-bulk-export-button"}
                                    >
                                        {lang.bulkPPT}
                                    </Button>
                                    <BulkStatusDialog closePopover={handleClosePopOver} />
                                </div>
                            </div>
                        )}
                        {location.pathname.includes(ReportRoutes.snapshot) && (
                            <div className={classes.popoverItem}>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        handleClosePopOver();
                                        handleStartExport(false);
                                    }}
                                    data-testid="snapshot-export-button"
                                >
                                    {lang.powerpoint}
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        handleClosePopOver();
                                        startSnapshotExcelExport && startSnapshotExcelExport();
                                    }}
                                    data-testid="snapshot-excel-export-button"
                                >
                                    {lang.excel}
                                </Button>
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(true);
                                        }}
                                        data-testid={"snapshot-bulk-export-button"}
                                    >
                                        {lang.bulkPPT}
                                    </Button>
                                    <BulkStatusDialog closePopover={handleClosePopOver} />
                                </div>
                            </div>
                        )}
                        {location.pathname.includes(ReportRoutes.heatmap) && (
                            <div className={classes.popoverItem}>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        handleClosePopOver();
                                        handleStartExport(false);
                                    }}
                                    data-testid="heatmap-export-button"
                                >
                                    {lang.excel}
                                </Button>
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(true);
                                        }}
                                        data-testid={"heatmap-bulk-export-button"}
                                    >
                                        {lang.bulkEXCEL}
                                    </Button>
                                    <BulkStatusDialog closePopover={handleClosePopOver} />
                                </div>
                            </div>
                        )}
                        {location.pathname.includes(ReportRoutes.correlation) && (
                            <div className={classes.popoverItem}>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        handleClosePopOver();
                                        handleStartExport(false);
                                    }}
                                    data-testid="correlation-export-button"
                                >
                                    {lang.excel}
                                </Button>
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(true);
                                        }}
                                        data-testid={"correlation-bulk-export-button"}
                                    >
                                        {lang.bulkEXCEL}
                                    </Button>
                                    <BulkStatusDialog closePopover={handleClosePopOver} />
                                </div>
                            </div>
                        )}
                        {location.pathname.includes(ReportRoutes.comment) &&
                            commentTabValue !== undefined &&
                            commentTabValue === TabOptions.comments && (
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(false);
                                        }}
                                        data-testid="comment-export-button"
                                    >
                                        {lang.pdf}
                                    </Button>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            startCommentExcelExport && startCommentExcelExport();
                                        }}
                                        data-testid="comment-excel-export-button"
                                    >
                                        {lang.excel}
                                    </Button>
                                    <div className={classes.popoverItem}>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                handleClosePopOver();
                                                handleStartExport(true);
                                            }}
                                            data-testid={"comment-bulk-export-button"}
                                        >
                                            {lang.bulkPDF}
                                        </Button>
                                        <BulkStatusDialog closePopover={handleClosePopOver} />
                                    </div>
                                </div>
                            )}
                        {location.pathname.includes(ReportRoutes.comment) &&
                            commentTabValue !== undefined &&
                            commentTabValue === TabOptions.summary && (
                                <div className={classes.popoverItem}>
                                    <Button
                                        variant="text"
                                        onClick={(): void => {
                                            handleClosePopOver();
                                            handleStartExport(false);
                                        }}
                                        data-testid="comment-export-button"
                                    >
                                        {lang.powerpoint}
                                    </Button>
                                </div>
                            )}
                    </div>
                </Popover>
                <Button
                    onClick={handleOpenFilter}
                    data-testid="btn-filters"
                    badgeValue={filter.items.length ?? undefined}
                    disabled={filterDisabled || isLoading}
                >
                    {lang.filters}
                </Button>
            </Box>
        </div>
    );
};

import { Card } from "../interfaces";

export const initCard: Card = {
    title: "",
    response_count: 0,
    kids: [],
    id: -1,
    showPreviousPeriod: false,
    showBenchmark: false,
    previousPeriod: []
};

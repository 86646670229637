import { Correlation } from "./interface";

export const initCorrelationDisplayValue: Correlation = {
    sections: {
        "-1": {
            sections: {
                "-1": 0
            },
            items: {
                "-1": 0
            }
        }
    },
    items: {
        "-1": {
            sections: {
                "-1": 0
            },
            items: {
                "-1": 0
            }
        }
    }
};

export enum colorClasses {
    cell_above_60 = "cell_above_60",

    cell_above_40 = "cell_above_40",

    cell_below_40 = "cell_below_40"
}

import { UserInfo } from "../../components/admin/users/interface";
import { IRouteProps } from "../interfaces";
import { AdminSurveysRoutes } from "../enums";

import SurveyEdit from "components/admin/results/SurveyEdit";

export const SurveysRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        /**
         * Creating and Editing Survey need it own route to render its own sidebar
         * It can't be part of admin's route
         */
        {
            path: `/${AdminSurveysRoutes.results}/${AdminSurveysRoutes.survey}/:id`,
            exact: true,
            label: AdminSurveysRoutes.results,
            routeKey: AdminSurveysRoutes.results,
            component: SurveyEdit,
            isAccessGranted: user.isTalentMapAdmin || user.isSurveyCreator || user.isSiteAdmin
        }
    ];
};

import React, { ReactElement, useState, useEffect, CSSProperties } from "react";
import { FixedSizeList as List } from "react-window";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { AutoSizer } from "react-virtualized";

import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { useAutoSizerStyles } from "core/styles";
import { nameToIdentifier } from "core/helpers";
import { theme } from "lib/theme";

type Props = {
    rawData: SelectOptionExpandablePanel[];
    disabledItemsIds?: SelectOptionExpandablePanel["id"][];
    disabledSectionIds?: SelectOptionExpandablePanel["id"][];
    onItemSelected: (id: SelectOptionExpandablePanel["id"], level: number, name: string) => void;
    selectedId?: string | number;
    isCondensed?: boolean;
    isFocused?: boolean;
    focusedItemId?: SelectOptionExpandablePanel["id"];
};

type PanelData = {
    builtId: string;
    parentId: string;
    level: number;
    title: string;
    id: SelectOptionExpandablePanel["id"];
};

type rowStyleProps = {
    isExpanded: boolean;
    level: number;
    canExpand: boolean;
    isDisabled: boolean;
};

const useStyles = makeStyles(() => ({
    autoSizerList: {
        border: theme.border.main
    }
}));

const useRowStyles = makeStyles(() => ({
    expandMoreIcon: (styleProps: rowStyleProps) => ({
        transform: styleProps.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        marginRight: theme.spacing(),
        color: theme.palette.action.active
    }),
    rowStyle: (styleProps: rowStyleProps) => ({
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: styleProps.canExpand
            ? theme.spacing((styleProps.level + 1) * 2)
            : theme.spacing((styleProps.level + 1) * 4),
        display: "flex",
        "&:hover": {
            background: styleProps.isDisabled ? "inherit" : theme.palette.action.hover,
            cursor: styleProps.isDisabled ? "inherit" : "pointer"
        },
        borderBottom: theme.border.main
    }),
    cellName: {
        display: "flex",
        alignItems: "center"
    },
    typographyName: (styleProps: rowStyleProps) => ({
        color: styleProps.isDisabled ? theme.palette.action.disabled : "inherit"
    }),
    typographyNameDisabled: {
        color: theme.palette.action.disabled
    },
    selected: {
        background: theme.palette.action.selected
    }
}));

const mapRawData = (
    obj: SelectOptionExpandablePanel[],
    level = 0,
    parentId = "",
    data: PanelData[] = []
): PanelData[] => {
    obj.forEach((item: SelectOptionExpandablePanel) => {
        const builtId: string = item.title.toLowerCase().replace(/\s/g, "") + parentId + level;
        const builtItem: PanelData = {
            builtId,
            parentId,
            level,
            title: item.title,
            id: item.id
        };

        data.push(builtItem);

        if (item.subNode && item.subNode.length > 0) {
            mapRawData(item.subNode, level + 1, builtId, data);
        }
    });

    return data;
};
const filterItemsToRender = (_openedPanels: string[], _mainPanelData: PanelData[]): PanelData[] => {
    return _mainPanelData.filter((item: PanelData) => {
        return _openedPanels.includes(item.builtId) || item.level === 0 || _openedPanels.includes(item.parentId);
    });
};

const getOpenedPanels = (_array: PanelData[], parentId: string, openedItems: string[] = []): string[] => {
    const itemFound = _array.find((item: PanelData) => item.builtId === parentId);
    if (itemFound) {
        openedItems.push(itemFound.builtId);
        getOpenedPanels(_array, itemFound.parentId, openedItems);
    }

    return openedItems;
};

const SelectExpandableList = (props: Props): ReactElement => {
    const classes = useStyles();
    const [mainPanelData, setMainPanelData] = useState<PanelData[]>(mapRawData(props.rawData));
    const [openedPanels, setOpenedPanels] = useState<string[]>([]);
    const [scrollOffset, setScrollOffset] = useState<number>(0);
    const autoSizerClass = useAutoSizerStyles();
    const [renderPanelData, setRenderPanelData] = useState(filterItemsToRender(openedPanels, mainPanelData));

    const defaultRowHeight = props.isCondensed ? 40 : 56;

    useEffect(() => {
        if (props.isFocused && props.focusedItemId) {
            handleAutoFocus(props.focusedItemId);
        }
    }, []);

    useEffect(() => {
        const updateRenderPanelData = filterItemsToRender(openedPanels, mainPanelData);
        setRenderPanelData(updateRenderPanelData);
    }, [openedPanels, mainPanelData]);

    useEffect(() => {
        const mappedRawData = mapRawData(props.rawData);
        setMainPanelData(mappedRawData);

        return (): void => {
            setMainPanelData([]);
        };
    }, [props.rawData]);

    useEffect(() => {
        const mappedRawData = mapRawData(props.rawData);

        const itemFound = mappedRawData.find((panel: PanelData) => panel.id === props.selectedId?.toString());
        if (itemFound) {
            const openedPanels = getOpenedPanels(mappedRawData, itemFound.parentId);
            openedPanels.length && setOpenedPanels(openedPanels);
        }
    }, [props.selectedId, props.rawData]);

    const handleExpand = (builtId: string, e: React.MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
        setScrollOffset(0);
        const foundId = openedPanels.find((itemOpen: string) => builtId === itemOpen);
        const foundItem = renderPanelData.find(r => r.builtId === foundId);
        if (foundId) {
            if (foundItem?.level === 0) {
                const relatedItem = mainPanelData.filter(m => m.parentId === foundId);
                setOpenedPanels(
                    openedPanels.filter((itemOpen: string) => {
                        return relatedItem.findIndex(r => r.builtId === itemOpen) < 0 && builtId !== itemOpen;
                    })
                );
            } else {
                setOpenedPanels(openedPanels.filter((itemOpen: string) => builtId !== itemOpen));
            }
        } else {
            setOpenedPanels([...openedPanels, builtId]);
        }
    };

    const handleAutoFocus = (id: SelectOptionExpandablePanel["id"]): void => {
        //find parent and siblings
        //open parent
        let flatIndex = 0;
        let sectionId = "";

        const data = [...props.rawData];

        const renderedItem = mainPanelData.find(d => d.id === id);
        if (renderedItem) {
            loop1: for (let s = 0; s < data.length; s++) {
                flatIndex++;
                if ("subNode" in data[s]) {
                    const questions = data[s].subNode!;
                    for (let q = 0; q < questions.length; q++) {
                        if ("subNode" in questions[q]) {
                            const items = questions[q].subNode!;
                            for (let i = 0; i < items.length; i++) {
                                if (items[i].id === id) {
                                    sectionId = data[s].id;
                                    //find the location to unfold
                                    flatIndex = flatIndex + q - 1;
                                    //found the section that the item belongs to, breaking the outside loop.
                                    break loop1;
                                }
                            }
                        }
                    }
                }
            }

            mainPanelData.forEach(d => {
                if (d.builtId === renderedItem.parentId || d.id === sectionId) {
                    if (openedPanels.length !== 2 && !openedPanels.find(o => o === d.builtId)) {
                        setOpenedPanels([...openedPanels, d.builtId]);
                    }
                }
            });

            setScrollOffset(flatIndex * defaultRowHeight);
        }
    };

    const handleItemSelected = (builtId: string, level: number, e: React.MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
        const foundItem = mainPanelData.find((item: PanelData) => builtId === item.builtId);
        if (props.disabledSectionIds?.some((id: string) => id === foundItem!.id.slice(1))) {
            return;
        }
        props.onItemSelected(foundItem!.id, level, foundItem!.title);
    };
    const Row = ({
        index,
        style,
        width
    }: {
        index: number;
        style: CSSProperties | undefined;
        width: number;
    }): ReactElement => {
        const item = renderPanelData[index];
        const isExpanded = openedPanels.some((builtId: string) => builtId === item.builtId);
        const canExpand = mainPanelData.some((panel: PanelData) => panel.parentId === item.builtId);
        const isDisabled = props.disabledItemsIds?.some((id: string) => id === item.id.toString());
        const isSectionDisabled = props.disabledSectionIds?.some((id: string) => id === item.id.toString().slice(1));

        let totalWidth = width - 32 - (item.level + 1) * 2 * 8;
        if (!canExpand) {
            totalWidth = width - 8 - (item.level + 1) * 4 * 8;
        }
        //10px => is the width of one letter
        const maxLetters = totalWidth / 10;

        const rowClasses = useRowStyles({
            isExpanded,
            level: item.level,
            canExpand,
            isDisabled: isDisabled ?? false
        });

        return isDisabled ? (
            <div className={rowClasses.rowStyle} style={style}>
                <div className={rowClasses.cellName}>
                    <Typography variant="body2" className={rowClasses.typographyName}>
                        {item.title}
                    </Typography>
                </div>
            </div>
        ) : (
            <div
                className={
                    item.id === props.selectedId?.toString()
                        ? `${rowClasses.selected} ${rowClasses.rowStyle}`
                        : rowClasses.rowStyle
                }
                style={style}
                onMouseDown={(e: React.MouseEvent): void => handleItemSelected(item.builtId, item.level, e)}
                data-parentid={nameToIdentifier(item.parentId.replace((item.level - 1).toString(), ""))}
                data-testid={item.title}
            >
                <div className={rowClasses.cellName}>
                    {canExpand && (
                        <ExpandMoreIcon
                            className={rowClasses.expandMoreIcon}
                            onMouseDown={(e: React.MouseEvent): void => handleExpand(item.builtId, e)}
                        />
                    )}
                    <TruncateDisplay maxLabelLength={maxLetters * 2} title={item.title}>
                        <Typography
                            variant="body2"
                            className={
                                isSectionDisabled ? rowClasses.typographyNameDisabled : rowClasses.typographyName
                            }
                        >
                            {item.title}
                        </Typography>
                    </TruncateDisplay>
                </div>
            </div>
        );
    };

    return (
        <AutoSizer className={autoSizerClass.autoSizer}>
            {({ height, width }): ReactElement => (
                <List
                    height={height}
                    itemCount={renderPanelData.length}
                    itemSize={defaultRowHeight}
                    width={width}
                    initialScrollOffset={scrollOffset}
                    className={classes.autoSizerList}
                >
                    {({ index, style }): ReactElement => Row({ index, style, width })}
                </List>
            )}
        </AutoSizer>
    );
};

export default SelectExpandableList;

import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Search, ClearOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { useLang } from "../../../core/hooks";
import { greyColor } from "../../styles/GlobalStyles";
import { TextField } from "lib/text-field";
import { InputAdornment } from "lib/input-adornment";
import { IconButton } from "lib/icon-button";
import { whiteBackground } from "lib/palette";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    adornedEndStyle: {
        paddingRight: "0px !important",
        backgroundColor: greyColor
    },
    root: {
        height: "46px",
        backgroundColor: whiteBackground,
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.border.main
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${orangeColor}`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: orangeColor
        }
    }
}));
interface ISearchBarProps {
    onchange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    labelText?: string;
    delay?: number;
    onDelayChange?: (value: string) => void;
    searchTerm: string;
    size?: string;
}
export type SearchBarHandle = {
    setInputField: (i: string) => void;
};

const SearchBar = forwardRef<SearchBarHandle, ISearchBarProps>((props: ISearchBarProps, ref): JSX.Element => {
    const { lang } = useLang();
    const { onchange, labelText, delay, onDelayChange, searchTerm, size } = props;
    const [input, setInput] = useState(searchTerm);
    const [timerId, setTimerId] = useState(0);
    const classes = useStyles();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setInput(event.target.value);
        if (!delay && onchange) {
            onchange(event);
        }
        if (delay && onDelayChange) {
            if (timerId > 0) {
                window.clearTimeout(timerId);
            }
            setTimerId(
                window.setTimeout(() => {
                    setTimerId(0);
                    onDelayChange(event.target.value);
                }, delay)
            );
        }
    };
    useImperativeHandle(ref, () => ({
        setInputField(i: string): void {
            setInput(i);
        }
    }));

    useEffect(() => {
        setInput(searchTerm);
    }, [searchTerm]);

    return (
        <TextField
            id="standard-search"
            label={""}
            placeholder={labelText && labelText !== "" ? labelText : lang.search}
            variant="outlined"
            margin="none"
            onChange={handleInputChange}
            color="primary"
            fullWidth
            size={size && size !== "" ? "small" : "medium"}
            inputProps={{ value: input }}
            InputProps={{
                classes: { root: classes.root, adornedEnd: classes.adornedEndStyle },
                endAdornment: (
                    <InputAdornment position="end" style={{ marginLeft: "2px", display: "flex" }}>
                        {input && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setInput("");
                                    if (delay && onDelayChange) {
                                        if (timerId > 0) {
                                            window.clearTimeout(timerId);
                                        }
                                        setTimerId(
                                            window.setTimeout(() => {
                                                setTimerId(0);
                                                onDelayChange("");
                                            }, delay)
                                        );
                                    }
                                }}
                            >
                                <ClearOutlined />
                            </IconButton>
                        )}
                        <IconButton size="small">
                            <Search />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
});
SearchBar.displayName = "SearchBar";

export default SearchBar;

import {
    CommentSettings,
    OverallSentimentScores,
    WordCloud,
    WordCloudSettings
} from "components/reports/comment/interface";

export interface CommentField {
    title: string;
    id: string;
    children: { title: string; id: string }[];
}

export interface Comment {
    comment: string;
    score: number;
    color: string;
    sentimentScore: number;
}

export interface CommentCount {
    sectionId: number;
    count: number;
    percent: number;
    questions: CommentQuestionCount[];
}

export interface CommentQuestionCount {
    questionId: number;
    count: number;
    percent: number;
}

export type ReadCommentsResponse = {
    comments: Comment[];
    totalRespondents: number;
    confidentiality: number;
    sections: CommentCount[];
    words: WordCloud[];
    wordCloudSettings: WordCloudSettings;
    surveyCommentSettings: CommentSettings;
    sentimentCalculationStatus: number;
    sentimentScoreNeutralThreshold: number;
    overallSentimentScores: OverallSentimentScores;
    translationStatus: number;
};

export enum CommentSummaryStatus {
    New,
    InProgress,
    Completed
}

import { initResponseRateStoredData } from "components/reports/responseRate/init";
import { emptySelectedOrganization } from "core/context/inits";
import {
    UserMutateDetail,
    UserInfo,
    UserSettings,
    SelectedSurveyStoredData,
    SnapshotRestoreData,
    CommentRestoreData,
    CorrelationRestoreData,
    UserDashboardRestoreData,
    InsightRestoreData,
    UserCommentSettings,
    RestrictedUserFilterGroup,
    previewSnapshotExport
} from "../interface";
import { LanguageCode } from "core/languages/LanguageOptions";
import { initHeatmapRestoreValue } from "components/reports/heatmap/heatmap.init";
import { initFilter } from "components/filters/inits";

export const initUserInput: UserMutateDetail = {
    email: "",
    isSiteAdmin: false,
    isSurveyCreator: false,
    isCommentViewer: false,
    isRestricted: true,
    byPassSimilarityCheck: false,
    name: "",
    organizationId: -1,
    isInsightsEnabled: false,
    participantEnabled: false,
    lastActivity: ""
};

export const initEditUserInput: UserMutateDetail = {
    id: -1,
    email: "",
    isSiteAdmin: false,
    isSurveyCreator: false,
    isCommentViewer: false,
    isRestricted: true,
    byPassSimilarityCheck: false,
    name: "",
    organizationId: -1,
    active: true,
    isInsightsEnabled: false,
    participantEnabled: false,
    lastActivity: ""
};

export const initSelectedSurvey: SelectedSurveyStoredData = {
    id: -1,
    orgId: -1
};

export const initSnapshotSettings: SnapshotRestoreData = {
    id: -1,
    orgId: -1,
    showOrgOverview: false,
    showOtherComparison: false,
    otherComparisonSurveyId: -1,
    otherComparisonFilter: initFilter.items,
    lookdownItems: [],
    heatmapLookdownItems: [],
    columnLabel: "",
    rrLookdownItems: [],
    keyDriversLookdownItems: [],
    customizedShowBenchmark: true,
    customizedShowPP: true,
    exportTitle: "",
    showOverallCommentsSlide: true,
    includeLookupOrgOverall: false
};

export const initPreviewSnapshotExport: previewSnapshotExport = {
    surveyId: -1,
    previewLookdownItems: [],
    previewHeatmapLookdownItems: [],
    previewRRLookdownItems: [],
    previewKeyDriversLookdownItems: []
};

export const initCommentSettings: CommentRestoreData = {
    surveySelected: -1,
    itemIds: [],
    typeOfSearch: 1,
    searchTerm: "",
    exportIncludeWordCloud: false
};
export const initCorrelationSettings: CorrelationRestoreData = {
    surveySelected: -1
};

export const initUserDashboarSettings: UserDashboardRestoreData = {
    surveySelected: -1
};

export const initInsightSettings: InsightRestoreData = {
    surveySelected: -1,
    selectedOutcome: -1,
    selectedKeyDriver: -1,
    selectedDemographicField: "",
    showOrgOverall: false,
    customizedShowPP: true
};

export const initCommentsSettings: UserCommentSettings = {
    commentsRandomSampleCount: null,
    minWordsPerComment: 1,
    showSectionScore: false,
    showSentimentScores: false,
    commentsSectionId: null,
    commentsSectionScoreSortOrder: 0,
    autoTranslate: false,
    surveyId: 0,
    wordCloudTopWordsCount: 50
};

export const initUserSettings: UserSettings = {
    userDashboard: initUserDashboarSettings,
    responseRate: initResponseRateStoredData,
    selectedOrganization: emptySelectedOrganization,
    selectedSurvey: [initSelectedSurvey],
    filtersItems: initFilter.items,
    heatmap: initHeatmapRestoreValue,
    languageCode: LanguageCode.en,
    snapshot: initSnapshotSettings,
    comment: initCommentSettings,
    correlation: initCorrelationSettings,
    insight: initInsightSettings,
    commentsSettings: [],
    startTour: true,
    previewSnapshotExport: initPreviewSnapshotExport
};

export const initUserInfo: UserInfo = {
    active: true,
    id: -1,
    email: "",
    name: "",
    isSiteAdmin: false,
    isSurveyCreator: false,
    isTalentMapAdmin: false,
    isCommentViewer: false,
    isRestricted: true,
    byPassSimilarityCheck: false,
    isInsightsEnabled: false,
    participantEnabled: false,
    created: "",
    settings: initUserSettings,
    userFilters: [],
    lastActivity: ""
};

export const initRestrictedUserFilterGroup: RestrictedUserFilterGroup = {
    surveyId: -1,
    filters: ""
};

export enum CustomizeSnapshotExportActions {
    SET_CONFIRMATION_STATUS = "SET_CONFIRMATION_STATUS",
    SET_CLEAR_ALL = "SET_CLEAR_ALL",
    SET_COMPONENT_STATUS = "SET_COMPONENT_STATUS",
    SET_COVER_FILE_LOADED = "SET_COVER_FILE_LOADED",
    SET_INTRO_FILE_LOADED = "SET_INTRO_FILE_LOADED",
    SET_UNDERSTAND_RESULT_FILE_LOADED = "SET_UNDERSTAND_RESULT_FILE_LOADED",
    SET_SURVEY_RESULT_TRANS_FILE_LOADED = "SET_SURVEY_RESULT_TRANS_FILE_LOADED",
    SET_UNDERSTAND_HEATMAP_FILE_LOADED = "SET_UNDERSTAND_HEATMAP_FILE_LOADED",
    SET_UNDERSTAND_KEYDRIVER_FILE_LOADED = "SET_UNDERSTAND_KEYDRIVER_FILE_LOADED",
    SET_OUTRO_FILE_LOADED = "SET_OUTRO_FILE_LOADED",
    SET_IS_DIRTY = "SET_IS_DIRTY",
    OPEN_PREVIEW = "OPEN_PREVIEW",
    SET_COMPARE_OPTIONS = "SET_COMPARE_OPTIONS",
    SET_SURVEY_SECTIONS = "SET_SURVEY_SECTIONS"
}

import { LanguageDetail } from "../context/interfaces";

export enum LanguageCode {
    en = "en",
    es = "es",
    fr = "fr"
}

const LanguageOptions: LanguageDetail[] = [
    {
        label: "English",
        code: LanguageCode.en,
        value: 0
    },
    {
        label: "Español",
        code: LanguageCode.es,
        value: 1
    },
    {
        label: "Français",
        code: LanguageCode.fr,
        value: 2
    }
];

export default LanguageOptions;

import React, { useState, ReactElement, useRef, ChangeEvent, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { KeyDriversLookdownItem } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { useLang } from "core/hooks";
import {
    getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit,
    surveySectionsPickOneWithIsFun
} from "core/helpers";
import { QuestionType } from "managerPortal/context/enums";
import SelectExpandableList from "./SelectExpandableList";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { TextField } from "lib/text-field";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";

type Props = {
    surveySections: SurveySection[];
    keyDriversLookdownItem: KeyDriversLookdownItem;
    keyDriversLookdownItems: KeyDriversLookdownItem[];
    updateLookdownItem: (item: KeyDriversLookdownItem, index: number) => void;
    index: number;
    deleteLookdownItem: (index: number) => void;
    responseCount: number;
    updateColumnLabelValue: (label: string, index: number) => void;
    columnLabelOnBlur: () => void;
    inputRef: (el: HTMLDivElement) => HTMLDivElement;
    handleOnFocus: (e: React.FocusEvent, index: number) => void;
    previewMode: boolean;
};

const useStyles = makeStyles(() => ({
    surveyItemContainer: {
        display: "flex",
        flexDirection: "column"
    },
    surveyItemList: {
        paddingLeft: 12,
        height: 392,
        width: 405
    },
    surveyItemButton: {
        display: "flex",
        textTransform: "none",
        width: 405,
        height: 53,
        borderBottom: "solid thin darkgrey",
        borderRadius: 0,
        marginBottom: 5,
        marginLeft: 12,
        backgroundColor: "#fafafa"
    },
    surveyItemButtonTextWrapper: {
        display: "flex",
        width: 380,
        alignItems: "center",
        paddingLeft: 10
    },
    surveyItemButtonText: {
        flexBasis: "90%",
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 14,
        textAlign: "left"
    },
    surveyItemButtonIcon: {
        flexBasis: "10%"
    },
    helperText: {
        bottom: "-12px"
    }
}));

const KeyDriversLookdownList = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const [showSurveyItemList, setShowSurveyItemList] = useState<boolean>(false);
    const [labelError, setLabelError] = useState<boolean>(false);
    const lookdownContactContainerRef = useRef<HTMLDivElement>(null);
    const responseCountLimit = 30;
    const maxCharacterForLabel = 30;

    const handleSurveyItemSelect = (id: string, level: number, title: string): void => {
        setShowSurveyItemList(false);
        if (title.length >= maxCharacterForLabel && !props.previewMode) {
            setLabelError(true);
        } else {
            setLabelError(false);
        }
        if (level === 0) {
            props.updateLookdownItem(
                {
                    ...props.keyDriversLookdownItem,
                    isSection: true,
                    measureId: +id.slice(1),
                    measureField: title,
                    columnLabel: title
                },
                props.index
            );
        } else if (level === 1) {
            props.updateLookdownItem(
                {
                    ...props.keyDriversLookdownItem,
                    isSection: false,
                    measureId: +id,
                    measureField: title,
                    columnLabel: title
                },
                props.index
            );
        }
    };

    const surveySections = (): SurveySection[] => {
        return props.surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };
    const handleOpenSurveyItemList = (): void => {
        setShowSurveyItemList(!showSurveyItemList);
        autoScrollLookdownContactContainer();
    };

    const autoScrollLookdownContactContainer = (): void => {
        setTimeout(() => {
            if (lookdownContactContainerRef && lookdownContactContainerRef.current) {
                lookdownContactContainerRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            }
        }, 100);
    };
    const handleChangeField = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > maxCharacterForLabel && !props.previewMode) {
            setLabelError(true);
        } else {
            setLabelError(false);
        }
        props.updateColumnLabelValue(event.target.value as string, props.index);
    };
    const getDisabledSectionIds = (): SelectOptionExpandablePanel["id"][] => {
        const selectedSectionIds = props.keyDriversLookdownItems
            .filter(item => item.isSection === true && item.measureId !== -1)
            .map(item => item.measureId.toString());
        const allItemsRemovedOverallScoreSectionIds = [] as SelectOptionExpandablePanel["id"][];
        const availableSurveySections = getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit(
            surveySections(),
            user
        );
        availableSurveySections.forEach(section => {
            const items = section.questions.map(question => question.items).reduce((a, b) => a.concat(b));
            if (items.every(item => item.isRemovedOverallScore === true)) {
                allItemsRemovedOverallScoreSectionIds.push(section.sectionId.slice(1));
            }
        });
        return selectedSectionIds.concat(allItemsRemovedOverallScoreSectionIds);
    };
    const handleListOnBlur = (): void => {
        if (showSurveyItemList) {
            setShowSurveyItemList(false);
        }
    };
    // check column label validation
    useEffect(() => {
        if (
            props.keyDriversLookdownItem &&
            props.keyDriversLookdownItem.columnLabel !== "" &&
            props.keyDriversLookdownItem.columnLabel.length > maxCharacterForLabel &&
            !props.previewMode
        ) {
            setLabelError(true);
        }
    }, []);
    if (props.responseCount < responseCountLimit) {
        return <></>;
    }
    return (
        <Box display="flex" gap={2}>
            <div
                className={classes.surveyItemContainer}
                tabIndex={0}
                onBlur={handleListOnBlur}
                ref={lookdownContactContainerRef}
            >
                <div
                    onClick={handleOpenSurveyItemList}
                    className={classes.surveyItemButton}
                    data-testid={`kd-btn-dialog-export-surveyItem-${props.index}`}
                >
                    <div className={classes.surveyItemButtonTextWrapper}>
                        <div
                            className={classes.surveyItemButtonText}
                            data-testid={
                                props.keyDriversLookdownItem.columnLabel
                                    ? `kd-btn-dialog-export-label-${props.keyDriversLookdownItem.columnLabel}`
                                    : `kd-btn-dialog-export-label-surveyItem`
                            }
                        >
                            {props.keyDriversLookdownItem.measureField
                                ? props.keyDriversLookdownItem.measureField
                                : `${lang.surveyDimensionOrItem}`}
                        </div>
                        <div className={classes.surveyItemButtonIcon}>
                            <ArrowDropDownIcon style={{ color: "grey" }} />
                        </div>
                    </div>
                </div>
                {showSurveyItemList && (
                    <div className={classes.surveyItemList} data-testid={`kd-survey-item-list-${props.index}`}>
                        <SelectExpandableList
                            rawData={surveySectionsPickOneWithIsFun(
                                getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit(surveySections(), user)
                            )}
                            onItemSelected={handleSurveyItemSelect}
                            disabledItemsIds={props.keyDriversLookdownItems
                                .filter(item => item.isSection === false && item.measureId !== -1)
                                .map(item => item.measureId.toString())}
                            disabledSectionIds={getDisabledSectionIds()}
                        />
                    </div>
                )}
            </div>
            <Box display="flex" gap={3} width={460} maxHeight={"52px"}>
                <TextField
                    inputRef={props.inputRef}
                    onChange={handleChangeField}
                    onBlur={props.columnLabelOnBlur}
                    onFocus={(e: React.FocusEvent): void => props.handleOnFocus(e, props.index)}
                    data-testid={`kd-lookdown-textField-${props.index}`}
                    label={lang.columnLabel}
                    name={"kd-lookdown-textField"}
                    value={props.keyDriversLookdownItem.columnLabel}
                    error={labelError}
                    helperText={
                        props.previewMode ? "" : labelError ? lang.columnLabelErrorText24 : lang.columnLabelHelperText24
                    }
                    FormHelperTextProps={{
                        classes: {
                            root: classes.helperText
                        }
                    }}
                />
                <IconButton
                    aria-label="delete"
                    data-testid={`kd-lookdown-delete-${props.index}`}
                    style={{ maxHeight: 58 }}
                    onClick={(event): void => {
                        event.stopPropagation();
                        props.deleteLookdownItem(props.index);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default KeyDriversLookdownList;

import React, { useReducer, ReactElement, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { organizationQuery } from "api/queries";
import { editOrgMutation, deleteLogoMutation, uploadLogoMutation, userSettingMutation } from "api/mutations";
import Error500 from "../../errorPage/Error500";
import {
    OrganizationDetailState,
    OrganizationInfo as IOrganizationInfo,
    OrganizationInput,
    OrganizationLogo
} from "./interfaces";
import { emptyOrganization, emptyOrgInput } from "./initialVariables";
import SidebarHandleState from "../../shared/SidebarHandleState";
import { useLang, useLoading, useSnackbar, useOrgChartService, useAdminService } from "core/hooks";
import { OrganizationDetailReducer } from "./reducers";
import { OrganizationDetailActions } from "./actions";
import OrganizationInfo from "./OrganizationInfo";
import initApollo from "../../../api/ApolloClient";
import { emptySelectedOrganization } from "core/context/inits";
import { formValidation } from "./helpers";
import { OrgDataKey } from "./enums";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";
import { useOrganization } from "core/context/organization/useOrganization";

const useStyles = makeStyles(() => ({
    container: { height: "100%" },
    head: {
        height: 56,
        width: "100%",
        paddingLeft: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        borderBottom: theme.border.main
    },
    typographyHead: { marginLeft: theme.spacing(2), height: 26 },
    content: { display: "flex", height: "calc(100% - 56px)" },
    newOrganizationHead: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    newOrganizationButton: { display: "flex" }
}));

const init = (): OrganizationDetailState => {
    return {
        organization: { ...emptyOrganization },
        selectedSidebarItem: "information",
        validation: { message: "", key: "" },
        createdTags: []
    };
};

const EditOrg = (): ReactElement => {
    const classes = useStyles();
    const routeParams = useParams<{ id: string }>();
    const history = useHistory();
    const { lang } = useLang();
    const { setLoading } = useLoading();
    const { user, setUser } = useUser();
    const [updateUserSetting] = useMutation(userSettingMutation);
    const { organizations, updateOrganization } = useOrganization();
    const [state, dispatch] = useReducer(OrganizationDetailReducer, null, init);

    const sidebarItems = [{ id: "information", title: lang.information }];
    const redirectURL = "/admin/organizations";
    const { setMessage } = useSnackbar();
    const orgChartService = useOrgChartService();
    const adminService = useAdminService();

    const graphClient = initApollo(orgChartService.getBearer()).uploadClient;

    const updateOrgState = (newValue: Partial<IOrganizationInfo>): void => {
        const updateOrg = {
            ...state.organization,
            ...newValue
        };

        updateOrganization(updateOrg);

        if (
            user.settings.selectedOrganization.id === updateOrg.id &&
            user.settings.selectedOrganization.name !== updateOrg.name &&
            updateOrg.active
        ) {
            const settings = {
                ...user.settings,
                selectedOrganization: {
                    id: updateOrg.id,
                    name: updateOrg.name
                }
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions);
            setUser({ settings });
        } else if (user.settings.selectedOrganization.id === updateOrg.id && !updateOrg.active) {
            const settings = {
                ...user.settings,
                selectedOrganization: {
                    ...emptySelectedOrganization
                }
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions);
            setUser({ settings });
        } else {
            dispatch({
                type: OrganizationDetailActions.UPDATE_ORG,
                payload: { organization: updateOrg }
            });
        }
    };

    const [editOrganization, { error: editOrgError }] = useMutation(editOrgMutation, {
        onCompleted: () => {
            updateOrgState({});
        }
    });

    const [uploadLogo, { error: uploadLogoError }] = useMutation(uploadLogoMutation, {
        client: graphClient,
        onCompleted: data => updateOrgState({ organizationLogo: data.uploadLogo })
    });

    const validation = (organization: IOrganizationInfo): boolean => {
        const valid = formValidation(organization, organizations, lang);
        if (!valid.isValid) {
            dispatch({
                type: OrganizationDetailActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: valid.errorMessage, key: valid.validationKey }
                }
            });
            return false;
        }
        return true;
    };

    const [getOrganization, { error: errorOrgQuery, loading: loadingOrgQuery }] = useLazyQuery(organizationQuery, {
        variables: { ...routeParams },
        onCompleted: data => {
            orgChartService.getTags(false, parseInt(routeParams.id)).then(tags => {
                if (data) {
                    let orgData = data.organization;
                    orgData = {
                        id: orgData.id,
                        name: orgData.name,
                        active: orgData.active,
                        created: orgData.created,
                        organizationLogo: orgData.organizationLogo
                            ? orgData.organizationLogo
                            : emptyOrganization.organizationLogo,
                        isDashboardEnabled: orgData.isDashboardEnabled,
                        isAIEnabled: orgData.isAIEnabled,
                        thirdPartyLogoBase64: orgData.thirdPartyLogoBase64,
                        thirdPartyLogoFileName: orgData.thirdPartyLogoFileName,
                        tags: tags
                    };
                    updateOrgState({ ...orgData });
                }
            });
        }
    });

    const [deleteLogo, { error: deleteLogoError }] = useMutation(deleteLogoMutation, {
        onCompleted: () => updateOrgState({ organizationLogo: emptyOrganization.organizationLogo })
    });

    const handleSave = (organization: IOrganizationInfo, isNewFile: boolean, redirect: boolean): void => {
        const orgInput: OrganizationInput = { ...emptyOrgInput };

        orgInput.active = organization.active;
        orgInput.name = organization.name;
        orgInput.id = organization.id;
        orgInput.isDashboardEnabled = organization.isDashboardEnabled;
        orgInput.isAIEnabled = organization.isAIEnabled;
        orgInput.thirdPartyLogoBase64 = organization.thirdPartyLogoBase64;
        orgInput.thirdPartyLogoFileName = organization.thirdPartyLogoFileName;

        if (organization.organizationLogo && isNewFile) {
            if (organization.organizationLogo.logoUrl !== "") {
                orgInput.encodedLogo = organization.organizationLogo.logoUrl;
            }
            if (organization.organizationLogo.originalFileName !== "") {
                orgInput.encodedLogoFilename = organization.organizationLogo.originalFileName;
            }
        }

        const queryOptions = {
            variables: { organization: { ...orgInput } }
        };
        editOrganization(queryOptions).then(() => {
            if (redirect) {
                history.push(redirectURL);
            }
        });
    };

    useEffect(() => {
        setLoading(false);
    }, [deleteLogoError, uploadLogoError, editOrgError]);

    useEffect(() => {
        getOrganization();
    }, []);
    /**
     * ONLY SAVE AUTOMATICALLY WHEN ITS IN EDITING MODE
     */
    const handleChange = (
        key: keyof IOrganizationInfo,
        newValue: string | number | boolean | OrganizationLogo,
        shouldSave: boolean
    ): void => {
        const updateOrg: IOrganizationInfo = {
            ...state.organization,
            [key]: newValue
        };

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization: updateOrg }
        });
        dispatch({
            type: OrganizationDetailActions.SET_VALIDATION_ERROR,
            payload: { validation: { message: "", key: "" } }
        });

        if (shouldSave) {
            if (validation(updateOrg)) handleSave(updateOrg, false, false);
        }
    };

    const handleDeactivateOrg = (): void => {
        const updateOrg = { ...state.organization };
        if (!validation(updateOrg)) {
            return;
        }

        updateOrg.active = !updateOrg.active;
        let snackbarMessage = lang.organizationHasBeenReactivated;
        if (!updateOrg.active) {
            snackbarMessage = lang.organizationHasBeenDeactivated;
        }

        setMessage(snackbarMessage);
        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization: updateOrg }
        });
        handleSave(updateOrg, false, false);
    };

    const handleOnBlur = (redirect: boolean): void => {
        setTimeout(() => {
            if (validation(state.organization)) {
                handleSave(state.organization, false, redirect);
            }
        }, 0);
    };

    const handleSidebarClick = (meuItem: string): void => {
        dispatch({
            type: OrganizationDetailActions.UPDATE_SELECTED_SIDEBAR,
            payload: { selectedSidebarItem: meuItem }
        });
    };

    const handleUploadLogo = (encodedLogo: string, file: File): void => {
        const organization = { ...state.organization };

        organization.organizationLogo = {
            logoUrl: encodedLogo,
            originalFileName: file.name
        };

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization }
        });

        uploadLogo({
            variables: { id: organization.id, file: file }
        });
    };

    const handleUploadThirdPartyLogo = (encodedLogo: string, file: File): void => {
        const organization = { ...state.organization };
        adminService.uploadThirdPartyLogo(organization.id, file, file.name).then(() => {
            organization.thirdPartyLogoBase64 = encodedLogo;
            organization.thirdPartyLogoFileName = file.name;

            dispatch({
                type: OrganizationDetailActions.UPDATE_ORG,
                payload: { organization }
            });
        });
    };

    const handleDeleteLogo = (): void => {
        const org = { ...state.organization };

        deleteLogo({
            variables: { id: org.organizationLogo.id }
        });
    };

    const handleDeleteThirdPartyLogo = (): void => {
        const org = { ...state.organization };
        adminService.deleteThirdPartyLogo(org.id).then(() => {
            org.thirdPartyLogoBase64 = "";
            org.thirdPartyLogoFileName = "";

            dispatch({
                type: OrganizationDetailActions.UPDATE_ORG,
                payload: { organization: org }
            });
        });
    };

    const redirect = (): void => {
        if (state.validation.key === OrgDataKey.null) {
            handleOnBlur(true);
        }
    };

    if (loadingOrgQuery) return <></>;
    if (errorOrgQuery) return <Error500 />;

    return (
        <div className={classes.container}>
            <div className={classes.head}>
                <>
                    <IconButton size="small" onClick={redirect} data-testid="btn-edit-org-back">
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    <Typography variant="h6">{state.organization.name}</Typography>
                </>
            </div>
            <div className={classes.content}>
                <SidebarHandleState
                    itemList={sidebarItems}
                    onClick={handleSidebarClick}
                    itemSelected={state.selectedSidebarItem}
                />
                {state.selectedSidebarItem === "information" && (
                    <OrganizationInfo
                        organization={state.organization}
                        isNewOrganization={false}
                        onChangeField={handleChange}
                        onBlur={(): void => {
                            handleOnBlur(false);
                        }}
                        onDeactivateOrg={handleDeactivateOrg}
                        validation={state.validation}
                        onUploadLogo={handleUploadLogo}
                        onDeleteLogo={handleDeleteLogo}
                        onThirdPartyUploadLogo={handleUploadThirdPartyLogo}
                        onDeleteThirdPartyLogo={handleDeleteThirdPartyLogo}
                        editMode={true}
                    />
                )}
            </div>
        </div>
    );
};

export default EditOrg;

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import Highlighter from "react-highlight-words";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { Comment } from "./interfaces";
import { useLang } from "../../../core/hooks";
import { errorColor, neutralPercentageColor, successColor } from "core/styles/ColorsDefinition";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";
import { SearchType } from "components/reports/comment/Comment";

const useStyles = makeStyles(() => ({
    commentsListScrollingStyle: {
        height: "100%",
        width: "100%"
    },
    noSearchResultMessage: {
        marginLeft: 0,
        marginTop: 12,
        paddingLeft: 28,
        paddingTop: 0
    },
    commentListItemPadding: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: theme.border.main
    },
    commentItemStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    iconButtonPaddingOverride: {
        padding: 8
    },
    loader: {},
    highlightWord: {
        backgroundColor: "#7BEDA7"
    },
    scoreText: {
        display: "inline",
        paddingLeft: 12,
        fontWeight: 400
    }
}));

interface Props {
    comments: Comment[];
    hasMore: boolean;
    searchTerm?: string;
    fetchMoreData: () => void;
    sentimentScoreNeutralThreshold: number;
    showSentimentScore: boolean;
    showSectionScore: boolean;
    searchType: SearchType;
}

export const CommentsDetail = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    const getSearchWords = (): string[] => {
        if (props.searchTerm && props.searchTerm.trim() == "") {
            return [];
        }
        if (props.searchTerm && props.searchType === SearchType.exactMatch) {
            return [props.searchTerm];
        }
        if (props.searchTerm && props.searchType === SearchType.anyWord) {
            return props.searchTerm.split(" ");
        }
        if (props.searchTerm && props.searchType === SearchType.semanticSearch) {
            return props.searchTerm.split(" ");
        }

        return [];
    };

    const getHighlitedComment = (comment: Comment): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={getSearchWords()}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={comment.comment}
                />
                {comment.comment && comment.sentimentScore !== null ? getSentimentAnalysisText(comment) : ""}
            </>
        );
    };

    const getCommentDot = (comment: Comment): ReactElement => {
        if (props.showSectionScore && comment.score !== null) {
            return (
                <Tooltip title={comment.score} placement={"left"} style={{ marginTop: "4px" }}>
                    <FiberManualRecordIcon fontSize={"small"} style={{ color: comment.color }} />
                </Tooltip>
            );
        } else {
            return <></>;
        }
    };

    const getSentimentAnalysisText = (comment: Comment): ReactElement => {
        if (props.showSentimentScore) {
            if (comment.sentimentScore <= -props.sentimentScoreNeutralThreshold) {
                return (
                    <Typography className={classes.scoreText} style={{ color: errorColor }}>
                        {`${lang.negativeSentiment} (${lang.score}: ${(comment?.sentimentScore * -100).toFixed(0)}%)`}
                    </Typography>
                );
            } else if (comment.sentimentScore >= props.sentimentScoreNeutralThreshold) {
                return (
                    <Typography className={classes.scoreText} style={{ color: successColor }}>
                        {`${lang.positiveSentiment} (${lang.score}: ${(comment?.sentimentScore * 100).toFixed(0)}%)`}
                    </Typography>
                );
            } else {
                return (
                    <Typography className={classes.scoreText} style={{ color: neutralPercentageColor }}>
                        {`${lang.neutralSentiment}`}
                    </Typography>
                );
            }
        } else {
            return <></>;
        }
    };

    return (
        <div className={classes.commentsListScrollingStyle}>
            {props.comments.length === 0 && (
                <Typography pt={1} pl={3}>
                    {lang.noSearchResults}
                </Typography>
            )}
            {props.comments && (
                <InfiniteScroll
                    dataLength={props.comments.length}
                    next={props.fetchMoreData}
                    hasMore={props.hasMore}
                    loader={<div className={classes.loader}>{lang.loading}</div>}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold={"0px"}
                >
                    {props.comments.map((e: Comment, i: number) => (
                        <div
                            key={
                                e.comment
                                    ? e.comment.substr(0, Math.floor(Math.random() * e.comment.length + 1)) + i
                                    : i
                            }
                            id={i.toString()}
                            className={classes.commentListItemPadding}
                        >
                            <div className={classes.commentItemStyle}>
                                {getCommentDot(e)}
                                <Typography variant="body1" style={{ paddingLeft: 12 }}>
                                    {e.comment ? getHighlitedComment(e) : "N/A"}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            )}
        </div>
    );
};

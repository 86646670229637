import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import Icon from "@mdi/react";

import { theme } from "lib/theme";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

type Props = {
    tooltip?: string;
    path: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    squareButton?: boolean;
    dataTestid: string;
    color?: string;
    padding?: string;
    size?: string;
};

const useStyles = makeStyles(() => ({
    iconContainer: ({
        isDisabled,
        isSquare,
        color
    }: {
        isDisabled: boolean;
        isSquare: boolean;
        color: string | undefined;
    }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: isDisabled ? theme.palette.action.disabledBackground : color ? color : theme.palette.primary.main,
        borderRadius: !isSquare ? "50%" : "5px",
        width: 28,
        height: 28
    }),
    iconButton: { padding: 10 }
}));

const IconButtonV4 = (props: Props): ReactElement => {
    const classes = useStyles({
        isDisabled: props.disabled ?? false,
        isSquare: props.squareButton ?? false,
        color: props.color
    });

    return props.tooltip && !props.disabled ? (
        <Tooltip
            title={props.tooltip}
            TransitionComponent={Zoom}
            placement="bottom-end"
            data-testid={props.dataTestid ? props.dataTestid : "customBtm"}
        >
            <IconButton
                className={classes.iconButton}
                onClick={props.onClick}
                disabled={props.disabled}
                autoFocus={false}
            >
                <div className={classes.iconContainer}>
                    <Icon path={props.path} size={"20px"} color="white" />
                </div>
            </IconButton>
        </Tooltip>
    ) : (
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            disabled={props.disabled}
            data-testid={props.dataTestid ? props.dataTestid : "customBtm"}
            autoFocus={false}
        >
            <div className={classes.iconContainer}>
                <Icon path={props.path} size={props.size ? props.size : "20px"} color="white" />
            </div>
        </IconButton>
    );
};

export { IconButtonV4 };

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { Popover } from "lib/popover";
import { CorrelationInfo } from "./interface";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import SelectExpandableList from "managerPortal/components/shared/SelectExpandableList";

const useStyles = makeStyles(() => ({
    surveyItemList: {
        display: "flex",
        height: 392,
        width: 458,
        overflow: "hidden"
    }
}));
type Props = {
    anchorEl: Element | null;
    correlation: CorrelationInfo[];
    handleClosePopover: () => void;
    handleSelectOutcome: (id: string) => void;
};

const InsightOutcomePopover = (props: Props): ReactElement => {
    const classes = useStyles();

    const mapCorrelationToSelectPanelData = (correlations: CorrelationInfo[]): SelectOptionExpandablePanel[] => {
        const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
        if (correlations.length) {
            correlations.forEach((correlation: CorrelationInfo) => {
                const fieldSubNode: SelectOptionExpandablePanel[] = [];
                correlation.items.forEach(item => {
                    fieldSubNode.push({ id: item.id.toString(), title: item.label });
                });
                mapSurveyItemFields.push({
                    id: correlation.id.toString(),
                    title: correlation.label,
                    subNode: fieldSubNode
                });
            });
        }
        return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
    };

    return (
        <Popover
            open={props.anchorEl !== null}
            onClose={props.handleClosePopover}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
        >
            <div className={classes.surveyItemList} data-testid={`insight-survey-item-list`}>
                <SelectExpandableList
                    rawData={mapCorrelationToSelectPanelData(props.correlation)}
                    onItemSelected={props.handleSelectOutcome}
                />
            </div>
        </Popover>
    );
};

export default InsightOutcomePopover;

import React, { ReactElement, useReducer, useCallback, useEffect, useState } from "react";
import { Typography } from "lib/typography";
import { useLazyQuery } from "@apollo/client";

import { useLang, useAdminService } from "core/hooks";
import Search from "components/shared/Search";
import { AssignAccessState, ResponseRestrictedUserInfo } from "./interfaces";
import { AssignAccessReducer } from "./reducers";
import { AssignAccessActions } from "./actions";
import { ComponentStatus } from "core/enums";
import { Translation } from "core/languages/interfaces";
import { Column } from "components/shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "components/shared/infiniteTable/enums";
import { TableData } from "components/shared/infiniteTable/types";
import { isStringEmptyOrSpaces, copyArrayWithObjects, sortArray } from "core/helpers";
import InfiniteTable from "components/shared/infiniteTable/InfiniteTable";
import EmptyPage from "components/shared/EmptyPage";
import Filters from "components/filters/Filters";
import { FilterMode } from "components/filters/enums";
import { Filter } from "components/filters/interfaces";
import { initFilter } from "components/filters/inits";
import { demographicFieldsAndValuesQuery, loadContactFieldsAndSurveyItems } from "api/queries";
import { filterConnectorFromBackEnd } from "components/filters/helper";
import { HrisFieldInfo } from "managerPortal/interfaces";
import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import Loading from "components/shared/Loading";
import { Box } from "lib/box";
import { Divider } from "lib/divider";

const initialState = (): AssignAccessState => {
    return {
        restrictedUserList: [],
        searchString: "",
        componentStatus: ComponentStatus.idle,
        selectedUserId: -1,
        isFilterDialogOpen: false,
        contactFields: []
    };
};

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.name,
            minWidth: 200,
            maxWidth: 300,
            sortable: true,
            order: TableSort.asc,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            width: 300,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.applyFilter,
            label: lang.applyFilter,
            width: 135,
            cellType: CellType.clickableText,
            truncate: true
        },
        {
            dataKey: DataKey.responses,
            label: lang.responseCount,
            width: 135,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.filterLabel,
            label: lang.filterLabel,
            width: 600,
            cellType: CellType.filterLabel,
            truncate: true
        }
    ];
};

type Props = {
    surveySelectedId: number;
};

const AssignAccess = (props: Props): ReactElement => {
    const { lang, languageCode } = useLang();
    const [state, dispatch] = useReducer(AssignAccessReducer, null, initialState);
    const tableColumns = tableColumnsList(lang);
    const adminService = useAdminService();
    const [demographics, setDemographics] = useState<HrisFieldInfo[]>([]);

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                const hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setDemographics(sortedDemographic);
            }
            dispatch({
                type: AssignAccessActions.SET_COMPONENT_STATUS,
                payload: { componentStatus: ComponentStatus.success }
            });
        }
    });

    const [getContactFieldsAndSurveyItems] = useLazyQuery(loadContactFieldsAndSurveyItems, {
        onCompleted: data => {
            dispatch({
                type: AssignAccessActions.SET_CONTACT_FIELD,
                payload: { contactFields: data.surveyContactFields ?? [] }
            });
            loadDemographics({ variables: { surveyId: props.surveySelectedId, languageCode } });
        }
    });

    const mapTableSource = (rawDataList: ResponseRestrictedUserInfo[], tableColumns: Column[]): TableData[] => {
        const dataTable: TableData[] = [];

        rawDataList.forEach((user: ResponseRestrictedUserInfo) => {
            let obj: TableData = { id: user.userId };
            obj = { ...obj, applyFilter: lang.filtersUpperCase };
            tableColumns.forEach((col: Column) => {
                if (col.dataKey === DataKey.name) {
                    obj[col.dataKey] = user.name;
                } else if (col.dataKey === DataKey.email) {
                    obj[col.dataKey] = user.email;
                } else if (col.dataKey === DataKey.responses) {
                    obj[col.dataKey] = user.responseCount;
                } else if (col.dataKey === DataKey.filterLabel) {
                    obj[col.dataKey] = user.filters;
                }
            });
            dataTable.push(obj);
        });

        return dataTable;
    };

    const getFilterLabel = (user: ResponseRestrictedUserInfo, str: string): boolean => {
        if (user.filters && state.contactFields.length > 0 && !isStringEmptyOrSpaces(str)) {
            const userFilterIds = user.filters.map(filter => filter.id);
            const labelList = state.contactFields.filter(field => userFilterIds.includes(field.fieldId));
            return labelList
                .map(list => list.title)
                .join()
                .trim()
                .toLowerCase()
                .includes(str.trim().toLowerCase());
        } else {
            return false;
        }
    };

    const getFilterTarget = (user: ResponseRestrictedUserInfo, str: string): boolean => {
        if (user.filters && state.contactFields.length > 0 && !isStringEmptyOrSpaces(str)) {
            return user.filters
                .map(filter => filter.target)
                .reduce((a, b) => a.concat(b), [])
                .join()
                .trim()
                .toLowerCase()
                .includes(state.searchString.trim().toLowerCase());
        } else {
            return false;
        }
    };

    const restrictedUsersProcessor = useCallback(
        (restrictedUserList: ResponseRestrictedUserInfo[]): TableData[] => {
            let filtered = restrictedUserList;

            if (restrictedUserList && state) {
                if (!isStringEmptyOrSpaces(state.searchString)) {
                    filtered = filtered.filter((user: ResponseRestrictedUserInfo) => {
                        return (
                            user.name.trim().toLowerCase().includes(state.searchString.trim().toLowerCase()) ||
                            user.email.trim().toLowerCase().includes(state.searchString.trim().toLowerCase()) ||
                            getFilterTarget(user, state.searchString) ||
                            getFilterLabel(user, state.searchString)
                        );
                    });
                }
                return mapTableSource(filtered, tableColumns);
            } else {
                const emt: TableData[] = [];
                return emt;
            }
        },
        [state, tableColumns]
    );

    const handleSearchChange = (searchStr: string): void => {
        dispatch({
            type: AssignAccessActions.CHANGE_SEARCH_STRING,
            payload: { searchString: searchStr }
        });
    };

    const handleFilterClick = (id: number): void => {
        dispatch({
            type: AssignAccessActions.SET_SELECTED_USER_ID,
            payload: { userId: id }
        });
        handleOpenFilter();
    };

    const handleOpenFilter = (): void => {
        dispatch({
            type: AssignAccessActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };
    const handleCloseFilters = (): void => {
        dispatch({
            type: AssignAccessActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };
    const getCurrentFilter = (): Filter => {
        if (state.selectedUserId === -1) return initFilter;
        const selectedRestrictedUser = state.restrictedUserList.find(user => user.id === state.selectedUserId);
        if (selectedRestrictedUser && selectedRestrictedUser.filterLabel !== null && state.contactFields) {
            return {
                id: -1,
                name: "",
                items: filterConnectorFromBackEnd(selectedRestrictedUser.filterLabel, state.contactFields)
            };
        } else {
            return initFilter;
        }
    };

    const updateRestrictedViewerList = (filter: Filter): void => {
        if (state.selectedUserId) {
            dispatch({
                type: AssignAccessActions.SET_COMPONENT_STATUS,
                payload: { componentStatus: ComponentStatus.loading }
            });
            adminService
                .updateRestrictedUserList(props.surveySelectedId, state.selectedUserId, filter.items)
                .then(() => {
                    fetchRestrictedUserList();
                })
                .catch(() => {
                    dispatch({
                        type: AssignAccessActions.SET_COMPONENT_STATUS,
                        payload: { componentStatus: ComponentStatus.error }
                    });
                });
        }
    };

    useEffect(() => {
        if (props.surveySelectedId) {
            getContactFieldsAndSurveyItems({
                variables: { surveyId: props.surveySelectedId, languageCode }
            });
        }
    }, []);

    const fetchRestrictedUserList = (): void => {
        adminService
            .getRestrictedUserList(props.surveySelectedId)
            .then(userList => {
                dispatch({
                    type: AssignAccessActions.SET_RESTRICTED_USER_LIST,
                    payload: {
                        restrictedUserList: restrictedUsersProcessor(userList),
                        componentStatus: ComponentStatus.success
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: AssignAccessActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
    };

    useEffect(() => {
        fetchRestrictedUserList();
    }, [state.searchString, languageCode]);

    if (state.componentStatus === ComponentStatus.idle || state.componentStatus === ComponentStatus.loading) {
        return <Loading />;
    }

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" height={82} pl={3} pr={2}>
                <Typography variant="h6">{lang.assignRestrictedReportViewers}</Typography>
                <Search onSearchChange={handleSearchChange} searchTerm={state.searchString} width={280} />
            </Box>
            <Divider />
            <Box width="100%" height="100%">
                {state.restrictedUserList.length > 0 ? (
                    <InfiniteTable
                        tableColumns={copyArrayWithObjects(tableColumns)}
                        dataSource={state.restrictedUserList}
                        onClickableTextClick={handleFilterClick}
                        contactFileds={state.contactFields}
                        rowPadding={2}
                    />
                ) : (
                    <EmptyPage image={EmptyResults} message={lang.noRestrictedUser} subMessage={lang.addusersToStart} />
                )}
                {state.isFilterDialogOpen && (
                    <Filters
                        onCloseFilter={handleCloseFilters}
                        isOpen={state.isFilterDialogOpen}
                        onFiltersApplied={updateRestrictedViewerList}
                        currentFilter={getCurrentFilter()}
                        filterMode={FilterMode.demographicOnly}
                        surveyId={props.surveySelectedId}
                        contactFields={demographics}
                    />
                )}
            </Box>
        </Box>
    );
};

export default AssignAccess;

import React from "react";

import MuiInputAdornment, { InputAdornmentProps } from "@mui/material/InputAdornment";

const InputAdornment = (props: InputAdornmentProps) => {
    const { children, ...rest } = props;
    return <MuiInputAdornment {...rest}>{children}</MuiInputAdornment>;
};

export { InputAdornment };

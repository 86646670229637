import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";
import { emptySelectedOrganization } from "core/context/inits";
import { useSelectOrgStyles } from "./styles";
import { textDarkBackgroundNotActive } from "lib/palette";
import { useUser } from "core/context/user/useUser";

type Props = {
    onClick: () => void;
    align: "right" | "left";
};

const SelectOrganization: React.FC<Props> = (props): ReactElement => {
    const classes = useSelectOrgStyles({ alignTo: props.align });
    const { lang } = useLang();
    const { user } = useUser();

    return (
        <div className={classes.organizationSelected} onClick={props.onClick} data-testid="headerSelectOrg">
            <Typography variant="body2" sx={{ color: textDarkBackgroundNotActive }}>
                {user.settings.selectedOrganization.name !== emptySelectedOrganization.name
                    ? user.settings.selectedOrganization.name
                    : lang.noOrganizationSelected}
            </Typography>
        </div>
    );
};

export default SelectOrganization;

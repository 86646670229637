import React, { ReactElement } from "react";
import { useHyperLinkStyles } from "./styles";

type Props = {
    linkTo: string;
    icon?: ReactElement;
    align: "right" | "left";
};

const HyperLink: React.FC<Props> = (props): ReactElement => {
    const classes = useHyperLinkStyles({ alignTo: props.align });
    return (
        <a className={classes.hyperlink} href={props.linkTo} target="_blank" rel="noopener noreferrer">
            {props.icon}
        </a>
    );
};

export default HyperLink;

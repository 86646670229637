import React, { ReactElement } from "react";

import { Button } from "lib/button";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "lib/dialog";

interface Props {
    closeButton: string;
    open: boolean;
    title?: string;
    message: string;
    onCloseClicked: () => void;
}

const InformationDialog = (props: Props): ReactElement => {
    const { closeButton, open, title, message, onCloseClicked } = props;

    return (
        <div>
            <Dialog fullScreen={false} open={open} aria-labelledby="information-dialog">
                <DialogTitle id="information-dialog">{title ? title : ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseClicked} autoFocus={true}>
                        {closeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InformationDialog;

export enum ReportRoutes {
    orgChart = "orgChart",
    responseRate = "responseRate",
    snapshot = "snapshot",
    heatmap = "heatmap",
    comment = "comment",
    correlation = "correlation",
    userDashboard = "userDashboard",
    bulk = "bulk",
    insight = "insight"
}

export enum AdminRoutes {
    goalsChallenges = "goalsChallenges",
    organizations = "organizations",
    organizationDetail = "organizationDetail",
    surveys = "surveys",
    users = "users",
    tags = "tags",
    benchmarks = "benchmarks",
    bulkStatus = "bulkStatus"
}

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { PanelItem } from "./interface";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { itemHeight, itemWidth } from "./DashboardItem";

type CellTypeImageStyleProps = {
    xPosition: number;
    yPosition: number;
    numberOfColumn: number;
    numberOfRow: number;
};

interface Props {
    testid: number;
    item: PanelItem;
}

const useStyles = makeStyles(() => ({
    imageCell: (styleProps: CellTypeImageStyleProps) => {
        return {
            position: "absolute",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`
        };
    },
    image: {
        display: "flex",
        justifyContent: "center"
    }
}));

const CellTypeImage = (props: Props): ReactElement => {
    const { item, testid } = props;
    const classes = useStyles({
        xPosition: item.xPosition,
        yPosition: item.yPosition,
        numberOfColumn: GRID_COL_LENGTH,
        numberOfRow: GRID_ROW_LENGTH
    });

    return (
        <div className={classes.imageCell} data-testid={`panel-${testid}`}>
            <div className={classes.image}>
                {JSON.parse(item.props).imageString ? (
                    <img
                        style={{ height: item.height * itemHeight, width: item.width * itemWidth }}
                        src={`data:image/jpeg;base64,${JSON.parse(item.props)?.imageString}`}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default CellTypeImage;

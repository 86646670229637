import { filterConnectorToBackEnd } from "components/filters/helper";
import { FilterParameter } from "components/filters/interfaces";
import saveAs from "file-saver";
import ConfigService from "./Config.service";
import { HeatmapService as IHeatmapService, HeatmapResponse, HeatmapComparison } from "./interfaces";

export default class HeatmapService extends ConfigService implements IHeatmapService {
    getHeatmap(
        surveyId: number,
        contactFieldId: number,
        filters: FilterParameter[],
        languageCode: string,
        compareFilters: FilterParameter[] | null,
        comparison: HeatmapComparison,
        round: boolean
    ): Promise<HeatmapResponse> {
        const postUrl = this.makeUrl("/heatmap");
        const params = {
            surveyId,
            contactFieldId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            compareFilters: compareFilters ? filterConnectorToBackEnd(compareFilters) : compareFilters,
            comparison,
            round
        };
        return this.post(postUrl, params);
    }

    exportToExcel(
        surveyId: number,
        contactFieldId: number,
        filters: FilterParameter[],
        languageCode: string,
        compareFilters: FilterParameter[] | null,
        comparison: HeatmapComparison,
        swapAxes: boolean,
        showFavorable: boolean
    ): Promise<void> {
        const postUrl = this.makeUrl("/fileexport/heatmap");
        const params = {
            surveyId,
            contactFieldId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            compareFilters: compareFilters ? filterConnectorToBackEnd(compareFilters) : compareFilters,
            comparison,
            swapAxes,
            showFavorable
        };
        return this.getBlobFile(postUrl, true, params)
            .then((blob: unknown) => saveAs(blob as Blob, "Heatmap.xlsx"))
            .catch(e => {
                console.log(e);
            });
    }
    getHeatmapSurveyInfo(surveyId: number, filters: FilterParameter[]): Promise<{ enablePreviousPeriod: boolean }> {
        const postUrl = this.makeUrl("/heatMap/surveyInfo");
        const params = {
            surveyId,
            filters: filterConnectorToBackEnd(filters)
        };
        return this.post(postUrl, params);
    }
}

import { UserInfo } from "components/admin/users/interface";
import { QuestionType } from "managerPortal/context/enums";
import { SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";

export const getAvailableSurveySectionsOnlyPickOne = (
    surveySections: SurveySection[],
    user: UserInfo
): SurveySection[] => {
    return surveySections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions
                    .filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                    .map((question: SurveyQuestion) => {
                        return {
                            ...question,
                            items: question.items.filter((item: SurveyItem) => {
                                const restricted = user.isRestricted && item.isHiddenForManagers;
                                return !item.isIgnored && !restricted;
                            })
                        };
                    })
                    .filter((question: SurveyQuestion) => question.items.length)
            };
        })
        .filter((section: SurveySection) => section.questions.length);
};

export const getAvailableSurveySectionsOnlyPickOneAndENPS = (
    surveySections: SurveySection[],
    user: UserInfo
): SurveySection[] => {
    return surveySections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions
                    .filter(
                        (question: SurveyQuestion) =>
                            (question.questionType === QuestionType.PickOne && question.isFun) ||
                            question.questionType === QuestionType.ENPS
                    )
                    .map((question: SurveyQuestion) => {
                        return {
                            ...question,
                            items: question.items.filter((item: SurveyItem) => {
                                const restricted = user.isRestricted && item.isHiddenForManagers;
                                return !item.isIgnored && !restricted;
                            })
                        };
                    })
                    .filter((question: SurveyQuestion) => question.items.length)
            };
        })
        .filter((section: SurveySection) => section.questions.length);
};

export const getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit = (
    surveySections: SurveySection[],
    user: UserInfo
): SurveySection[] => {
    return surveySections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions
                    .filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                    .map((question: SurveyQuestion) => {
                        return {
                            ...question,
                            items: question.items.filter((item: SurveyItem) => {
                                const restricted = user.isRestricted && item.isHiddenForManagers;
                                const answersRestricted = item.answers.length < 5 || item.answers.length > 6;
                                return !item.isIgnored && !restricted && !answersRestricted;
                            })
                        };
                    })
                    .filter((question: SurveyQuestion) => question.items.length)
            };
        })
        .filter((section: SurveySection) => section.questions.length);
};

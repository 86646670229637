import { useState } from "react";
import { useQuery } from "@apollo/client";

import { usersQuery } from "../../api/queries";
import { UserInput } from "../../components/admin/users/interface";
import { useUser } from "core/context/user/useUser";
import { SelectedOrganization } from "../context/interfaces";

export const useOrgHasAdmin = (): { hasAdmin: boolean; selectedOrg: SelectedOrganization } => {
    const [hasAdmin, setHasAdmin] = useState(false);
    const { user } = useUser();

    useQuery(usersQuery, {
        variables: { orgId: user.settings.selectedOrganization.id },
        onCompleted: (data: { users: UserInput[] }): void => {
            const admin = data.users.find((user: UserInput) => user.isSiteAdmin && user.active);
            if (admin) {
                setHasAdmin(true);
            } else {
                setHasAdmin(false);
            }
        }
    });

    return { hasAdmin, selectedOrg: user.settings.selectedOrganization };
};

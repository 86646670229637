import React from "react";
import MuiList, { ListProps } from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import { Typography } from "lib/typography";

type Item = {
    id: number | string;
    name: string;
};

type Size = "dense" | "standard" | "large";

type OnItemSelect = (id: Item["id"]) => void;

type Props = ListProps & {
    items: Item[];
    itemSelected?: Item["id"];
    onItemSelect: OnItemSelect;
    size?: Size;
};

const listItemButtonStyled = (size: Size | undefined) => {
    if (size === "large") {
        return {
            height: "56px"
        };
    }
    return null;
};

export const List = (props: Props) => {
    const { items, itemSelected, size, onItemSelect, ...rest } = props;

    return (
        <MuiList disablePadding {...rest}>
            {items.map(item => (
                <ListItem key={item.id} disablePadding>
                    <ListItemButton
                        disableRipple
                        onClick={() => onItemSelect(item.id)}
                        sx={listItemButtonStyled(size)}
                        selected={itemSelected === item.id}
                    >
                        <Typography>{item.name}</Typography>
                    </ListItemButton>
                </ListItem>
            ))}
        </MuiList>
    );
};

import { useContext } from "react";
import { CustomErrorParam } from "../languages/interfaces";
import { CompassContext } from "../context/CompassContext";
import { ErrorMessage, Translation } from "../languages/interfaces";
import { LanguageCode } from "../languages/LanguageOptions";

export function useLang(): {
    lang: Translation;
    errorMessage: (errorParam: CustomErrorParam) => ErrorMessage;
    languageCode: LanguageCode;
} {
    const { compassContextState } = useContext(CompassContext);

    return {
        lang: compassContextState.language.translation,
        errorMessage: compassContextState.language.errorMessage,
        languageCode: compassContextState.language.languageCode
    };
}

import React, { ReactElement, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { DialogLayout } from "components/shared";
import { useLang } from "core/hooks";
import { useOverallDimentsionState } from "./useOverallDimensionState";
import type { Props as PanelDialogProps } from "../PanelDialog";
import { Box as BoxType } from "../interfaces";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { Checkbox } from "lib/checkbox";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { Divider } from "lib/divider";
import { Typography } from "lib/typography";

type Props = {
    onClose: () => void;
    updatePanelItem: PanelDialogProps["updatePanelItem"];
    hasBenchmark: boolean;
    hasPreviousPeriod: boolean;
    box?: BoxType;
};
const useStyles = makeStyles(() => ({
    textField: {
        display: "flex",
        alignItems: "center",
        paddingTop: 18
    },
    languageText: {
        display: "flex",
        paddingLeft: 24,
        paddingTop: 40
    },
    surveyItemLanguageText: {
        display: "flex",
        paddingLeft: 24,
        width: 80,
        minWidth: 80
    }
}));

const availableSizes = [
    { id: "5 x 4", width: 5, height: 4, name: "5 x 4" },
    { id: "6 x 5", width: 6, height: 5, name: "6 x 5" }
];

const parseJSON = (str: string): Record<string, unknown> => {
    return JSON.parse(str);
};

const getInitSize = (box: BoxType): string => {
    const size = `${box.width} x ${box.height}` as string;
    return size;
};

const getInitHiddenField = (box: BoxType | undefined, attribute: string): boolean => {
    return box && box.props && parseJSON(box.props)[attribute] ? !!parseJSON(box.props)[attribute] : false;
};

export const OverallDimension = ({
    onClose,
    updatePanelItem,
    hasBenchmark,
    hasPreviousPeriod,
    box
}: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const {
        isBenchmarkHidden,
        isPreviousPeriodHidden,
        setBenchmarkHidden,
        setPreviousPeriodHidden,
        size,
        setSize,
        selectedOverallDimensionsSize,
        setSelectedOverallDimensionsSize,
        isDirty,
        titleTranslation,
        setTitleTranslation
    } = useOverallDimentsionState({
        initBenchmarkHidden: getInitHiddenField(box, "hideBMDelta"),
        initPreviousPeriodHidden: getInitHiddenField(box, "hidePPDelta"),
        initSize: availableSizes,
        initOverallDimentsionSize: box && getInitSize(box),
        initTitleTranslation: box && box.translations
    });

    const getOverallDimensionWidth = (): number => {
        const sizeSelected = selectedOverallDimensionsSize.split("x")[0];
        return parseInt(sizeSelected.trim());
    };
    const getOverallDimensionHeight = (): number => {
        const sizeSelected = selectedOverallDimensionsSize.split("x")[1];
        return parseInt(sizeSelected.trim());
    };

    const handleupdatePanelItem = (): void => {
        updatePanelItem(
            getOverallDimensionWidth(),
            getOverallDimensionHeight(),
            "overallDimension",
            JSON.stringify({
                hideBMDelta: isBenchmarkHidden,
                hidePPDelta: isPreviousPeriodHidden
            }),
            titleTranslation
        );
    };
    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "englishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "en" ? { languageCode: "en", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "frenchTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "fr" ? { languageCode: "fr", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "spanishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "es" ? { languageCode: "es", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
    };

    const onSelectedOverallDimensionsSize = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            setSelectedOverallDimensionsSize(value as string);
        }
    };

    useEffect(() => {
        if (box) {
            setSize(
                availableSizes.filter(
                    item =>
                        item.width <= GRID_COL_LENGTH - box.left / boxSize &&
                        item.height <= GRID_ROW_LENGTH - box.top / boxSize
                )
            );
        }
    }, [box, open]);

    return (
        <DialogLayout
            title={lang.overallDimension}
            onClose={onClose}
            open
            onClick={handleupdatePanelItem}
            buttonLabelAction={lang.ok}
            suppressContentPadding
            disableActionButton={!isDirty}
        >
            <Box p={3} pt={2} pb={2}>
                <Select
                    data-testid="select-overall-dimension-size"
                    items={size.map(s => {
                        return { value: s.id, name: s.name };
                    })}
                    onChange={onSelectedOverallDimensionsSize}
                    label={lang.size}
                    value={selectedOverallDimensionsSize}
                />
            </Box>
            <Divider />
            <Box pt={1} pl={3} pr={3}>
                {hasBenchmark && (
                    <Checkbox
                        checked={isBenchmarkHidden}
                        onChange={(e): void => setBenchmarkHidden(e.target.checked)}
                        label={lang.hideBenchmarkData}
                    />
                )}
            </Box>
            <Box pt={2} pl={3} pr={3}>
                {hasPreviousPeriod && (
                    <Checkbox
                        checked={isPreviousPeriodHidden}
                        onChange={(e): void => setPreviousPeriodHidden(e.target.checked)}
                        label={lang.hidePriveousPeriodData}
                    />
                )}
            </Box>
            <Typography variant="body1" className={classes.languageText}>
                {lang.title}
            </Typography>
            <div className={classes.textField}>
                <Typography variant="body1" className={classes.surveyItemLanguageText}>
                    {lang.english}
                </Typography>
                <Box width={300} pl={2}>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"overallDimension-englishTitle-TextField"}
                        name={"englishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "en")[0]?.name ?? ""}
                    />
                </Box>
            </div>
            <div className={classes.textField}>
                <Typography variant="body1" className={classes.surveyItemLanguageText}>
                    {lang.spanish}
                </Typography>
                <Box width={300} pl={2}>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"overallDimension-spanishTitle-TextField"}
                        name={"spanishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "es")[0]?.name ?? ""}
                    />
                </Box>
            </div>
            <div className={classes.textField}>
                <Typography variant="body1" className={classes.surveyItemLanguageText}>
                    {lang.french}
                </Typography>
                <Box width={300} pl={2}>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"overallDimension-frenchTitle-TextField"}
                        name={"frenchTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "fr")[0]?.name ?? ""}
                    />
                </Box>
            </div>
        </DialogLayout>
    );
};

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import {
    textDarkBackgroundMain,
    textDarkBackgroundDisabled,
    textDarkBackgroundNotActive,
    popoverBackground
} from "lib/palette";

type StyleProps = {
    alignTo: "right" | "left";
    disabled: boolean;
    padding: number;
};

export const useLinkButtonStyles = makeStyles(() => ({
    link: ({ alignTo, disabled, padding }: StyleProps) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: `${padding}px`,
        paddingRight: `${padding}px`,
        height: "100%",
        backgroundColor: "transparent",
        textDecoration: "none",
        float: alignTo,
        "&:hover": {
            backgroundColor: disabled ? "inherit" : theme.palette.secondary.dark,
            cursor: disabled ? "default" : "pointer"
        }
    }),
    icon: { width: 32, height: 32, marginRight: theme.spacing(1), marginLeft: theme.spacing(1) },
    notActiveTextColor: ({ disabled }: StyleProps) => ({
        color: disabled ? textDarkBackgroundDisabled : textDarkBackgroundNotActive
    }),
    activeTextColor: {
        color: textDarkBackgroundMain,
        fontWeight: theme.typography.fontWeightBold
    },
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1),
        background: popoverBackground
    },
    popoverText: {
        color: textDarkBackgroundMain,
        fontSize: 12
    }
}));

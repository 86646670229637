import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";
import { theme } from "lib/theme";

const ShowNotEnoughCorrelationResponse = (): ReactElement => {
    const { lang } = useLang();

    return (
        <>
            <Typography
                variant="body1"
                sx={{ marginTop: "24px", marginLeft: "24px", fontWeight: theme.typography.fontWeightMedium }}
            >
                {lang.warning}!
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "12px", marginLeft: "24px", marginRight: "24px" }}>
                {lang.notEnoughCorrelationResponse}
            </Typography>
        </>
    );
};

export default ShowNotEnoughCorrelationResponse;

import { FiltersState } from "../Filters";
import { CombinationOperator, ComparisonOperator, FilterType } from "../enums";
import { initFilterParameter } from "../inits";
import { FilterParameter } from "../interfaces";

export const isFilterValid = (state: FiltersState): boolean => {
    if (
        state.selectedFilter.items.some(
            (item: FilterParameter) =>
                item.field.id === initFilterParameter.field.id && item.filterType !== FilterType.date
        )
    ) {
        return false;
    }

    if (state.selectedFilter.items.some((item: FilterParameter) => item.target.length === 0)) {
        return false;
    }

    if (
        state.selectedFilter.items.some(
            (item: FilterParameter) =>
                item.comparisonOperator === ComparisonOperator.none && item.filterType !== FilterType.date
        )
    ) {
        return false;
    }

    if (state.selectedFilter.items.some((item: FilterParameter) => item.filterType === FilterType.none)) {
        return false;
    }

    if (
        state.selectedFilter.items.some(
            (item: FilterParameter) =>
                item.order > 0 &&
                item.combinationOperator === CombinationOperator.none &&
                item.filterType !== FilterType.none
        )
    ) {
        return false;
    }

    if (
        state.selectedFilter.items.some(
            (item: FilterParameter) =>
                item.filterType === FilterType.date &&
                (item.target[0] === undefined || item.target[1] === undefined || item.target.length !== 2)
        )
    ) {
        return false;
    }

    if (
        state.selectedFilter.items.some(
            (item: FilterParameter) => item.filterType === FilterType.date && item.target[0].id > item.target[1].id
        )
    ) {
        return false;
    }

    return true;
};

import React, { ReactElement, useReducer, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { mdiPencil } from "@mdi/js";
import saveAs from "file-saver";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DashboardReducer } from "./reducers";
import { DashboardActions } from "./actions";
import { DashboardState, Panel, GridItem, SelectBox, Box as BoxType, PanelInput, TitleTranslation } from "./interfaces";
import { useLang, useAdminService, useLoading, useSurveys } from "core/hooks";
import { Button } from "lib/button";
import { ComponentStatus } from "core/enums";
import { Snackbar } from "lib/snackbar";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { SurveyInfo } from "components/admin/results/interfaces/index";
import { DashboardTitlePopup } from "./DashboardTitlePopup";
import { DashboardContainer } from "./DashboardContainer";
import PanelDialog from "./PanelDialog";
import { DashboardCellType, dashboardCellOptions } from "./interfaces";
import { getKeyFromObject } from "core/helpers";
import { Box } from "lib/box";
import { IconButtonV4 } from "lib/icon-button";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import { List } from "lib/list-custom";

const useStyles = makeStyles(() => ({
    content: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    header: {
        display: "flex",
        height: 82,
        minHeight: 82,
        maxHeight: 82,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottom: theme.border.main
    },
    title: {
        display: "flex",
        height: 82,
        minHeight: 82,
        maxHeight: 82,
        alignItems: "center"
    },
    panelsContainer: {
        display: "flex",
        height: "calc(100% - 82px)",
        minHeight: "calc(100% - 82px)"
    },
    panelItemSelector: {
        width: 260,
        minWidth: 260,
        maxWidth: 260,
        display: "flex",
        flexDirection: "column",
        borderRight: theme.border.main,
        boxSizing: "border-box"
    },
    defaultTtile: {
        display: "flex",
        alignItems: "center",
        minWidth: 380,
        flexBasis: "50%",
        width: 380,
        maxWidth: 380,
        height: 56,
        minHeight: 56,
        maxHeight: 56,
        backgroundColor: theme.palette.action.hover,
        borderBottom: theme.border.main,
        marginRight: 4
    },
    panelItem: {
        paddingTop: 6,
        paddingBottom: 6,
        height: 56,
        minHeight: 56,
        paddingLeft: theme.spacing(2)
    },
    listItemTextWrap: {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    grid: {
        display: "flex",
        flexDirection: "column",
        zIndex: 0,
        height: 696,
        minHeight: 696,
        maxHeight: 696,
        width: 1392,
        minWidth: 1392,
        maxWidth: 1392
    },
    dashboardContainer: {
        display: "flex",
        position: "absolute",
        left: 484,
        zIndex: 1,
        height: 696,
        minHeight: 696,
        maxHeight: 696,
        width: 1392,
        minWidth: 1392,
        maxWidth: 1392,
        borderRight: theme.border.main,
        borderBottom: theme.border.main
    },
    gridRow: { display: "flex" },
    gridItem: {
        width: 114,
        height: 114,
        border: "1px solid #FFFFFF",
        background: theme.palette.background.default
    },
    node: {
        display: "flex"
    }
}));

const initialState = (defaultDashboardTitle: string): DashboardState => {
    return {
        selectedPanelId: "",
        selectedCellType: "singleDimensionOrItem",
        dashboardTitle: [
            { languageCode: "en", name: defaultDashboardTitle },
            { languageCode: "fr", name: defaultDashboardTitle },
            { languageCode: "es", name: defaultDashboardTitle }
        ],
        panelDialogOpen: false,
        panelItems: [],
        boxes: [],
        componentStatus: ComponentStatus.idle,
        confirmationDialog: false,
        hasPreviousPeriod: false,
        hasBenchmark: false
    };
};

type Props = {
    surveySelectedId: number;
};

export const GRID_ROW_LENGTH = 6;
export const GRID_COL_LENGTH = 12;
export const boxSize = 116;
export const defaultItemTitleTranslation = [
    { languageCode: "en", name: "" },
    { languageCode: "fr", name: "" },
    { languageCode: "es", name: "" }
];

const mapPanelToPanelInput = (panel: Panel[]): PanelInput[] => {
    return panel.map(item => {
        return {
            ...item,
            type: dashboardCellOptions[item.type]
        };
    });
};

const Dashboard = (props: Props): ReactElement => {
    const classes = useStyles();
    const { surveys } = useSurveys();
    const defaultDashboardTitle = surveys.find((survey: SurveyInfo) => survey.id === props.surveySelectedId)
        ? surveys.filter((survey: SurveyInfo) => survey.id === props.surveySelectedId)[0].translations[0].name
        : "";
    const [state, dispatch] = useReducer(DashboardReducer, initialState(defaultDashboardTitle));
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const adminService = useAdminService();
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [panelItemCount, setPanelItemCount] = useState<number>(0);

    const [grid, setGrid] = useState<GridItem[][]>([]);

    const createGrid = (): void => {
        const grid = [];
        for (let row = 0; row < GRID_ROW_LENGTH; row++) {
            const currentRow = [];
            for (let col = 0; col < GRID_COL_LENGTH; col++) {
                currentRow.push(createNode(row, col));
            }
            grid.push(currentRow);
        }
        setGrid(grid);
    };

    const createNode = (row: number, col: number): GridItem => {
        return {
            row,
            col
        };
    };

    const getDashboardTitle = (): string => {
        const dashboardTitle = state.dashboardTitle.filter(translation => translation.languageCode === languageCode)[0]
            ?.name
            ? state.dashboardTitle.filter(translation => translation.languageCode === languageCode)[0]?.name
            : defaultDashboardTitle;
        return dashboardTitle;
    };
    const handlePreviewButtonOnClick = (): void => {
        const dashboardTitle = getDashboardTitle();
        setLoading(true);
        adminService
            .exportDashboardEditor(props.surveySelectedId, languageCode, [])
            .then((blob: unknown) => {
                saveAs(blob as Blob, dashboardTitle + ".pptx");
                setLoading(false);
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                setLoading(false);
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };
    const handleClearAllButtonOnClick = (): void => {
        dispatch({
            type: DashboardActions.SET_CONFIRMATION_STATUS,
            payload: { status: true }
        });
    };
    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setAnchorEl(el);
    };
    const handleClosePopover = (): void => {
        setAnchorEl(null);
    };
    const handleApplyDashboardTitle = (englishTitle: string, frenchTitle: string, spanishTitle: string): void => {
        const newDashboardTitle = [
            { languageCode: "en", name: englishTitle },
            { languageCode: "fr", name: frenchTitle },
            { languageCode: "es", name: spanishTitle }
        ];

        adminService
            .updateDashboardPanel(newDashboardTitle, props.surveySelectedId, mapPanelToPanelInput(state.panelItems))
            .then(() => {
                fetchDashboardLayout();
                setAnchorEl(null);
            })
            .catch(() => {
                dispatch({
                    type: DashboardActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
    };
    const handleCancalDashboardTitle = (): void => {
        setAnchorEl(null);
    };

    const closePanelDialog = (): void => {
        dispatch({
            type: DashboardActions.SET_PANEL_OPEN_STATUS,
            payload: { status: false }
        });
    };

    const handleCancelClearAll = (): void => {
        dispatch({
            type: DashboardActions.SET_CONFIRMATION_STATUS,
            payload: { status: false }
        });
    };

    const handleConfirmationClicked = (): void => {
        adminService
            .updateDashboardPanel(state.dashboardTitle, props.surveySelectedId, [])
            .then(() => {
                dispatch({
                    type: DashboardActions.SET_CLEAR_ALL,
                    payload: {
                        selectedPanelId: "",
                        selectedCellType: "singleDimensionOrItem",
                        panelDialogOpen: false,
                        panelItems: [],
                        boxes: [],
                        componentStatus: ComponentStatus.idle,
                        confirmationDialog: false,
                        hasPreviousPeriod: false,
                        hasBenchmark: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: DashboardActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
    };
    const checkItemsOverlap = (selectedBox: SelectBox, boxes: SelectBox[]): boolean => {
        let overlapCheck = false;
        boxes
            .filter(box => box.id !== selectedBox.id)
            .forEach((box: SelectBox): void => {
                if (
                    selectedBox.xPosition < box.xPosition + box.width &&
                    selectedBox.xPosition + selectedBox.width > box.xPosition &&
                    selectedBox.yPosition < box.yPosition + box.height &&
                    selectedBox.yPosition + selectedBox.height > box.yPosition
                ) {
                    overlapCheck = true;
                }
            });
        return overlapCheck;
    };

    const checkBoxsOverlapped = (boxes: SelectBox[]): BoxType[] => {
        const checkedBoxes = boxes.map(box => {
            return {
                ...box,
                overlapped: checkItemsOverlap(box, boxes)
            };
        });
        const mappedBox = [...checkedBoxes].map(item => ({
            id: item.id,
            left: item.xPosition * boxSize,
            top: item.yPosition * boxSize,
            type: item.type,
            props: item.props,
            height: item.height,
            width: item.width,
            translations: item.translations,
            overlapped: item.overlapped
        }));
        dispatch({
            type: DashboardActions.SET_PANEL_BOXES,
            payload: {
                boxes: mappedBox
            }
        });
        return mappedBox.filter(box => !box.overlapped);
    };

    const mapBoxesToPanelItems = (boxes: BoxType[]): Panel[] => {
        return boxes.map(item => ({
            xPosition: item.left / boxSize,
            yPosition: item.top / boxSize,
            type: item.type,
            props: item.props,
            translations: item.translations,
            height: item.height,
            width: item.width
        }));
    };
    const mapBoxesToSelectBoxes = (boxes: BoxType[]): SelectBox[] => {
        return boxes.map(item => ({
            id: item.id,
            xPosition: item.left / boxSize,
            yPosition: item.top / boxSize,
            type: item.type,
            props: item.props,
            height: item.height,
            width: item.width,
            translations: item.translations,
            overlapped: item.overlapped
        }));
    };

    const processCollisionCheck = (updatedBoxes: BoxType[]): void => {
        const mappedBoxes = mapBoxesToSelectBoxes(updatedBoxes);
        const checkedBoxes = checkBoxsOverlapped(mappedBoxes);
        const updatedPanelItems = mapBoxesToPanelItems(checkedBoxes);
        dispatch({
            type: DashboardActions.SET_PANEL_ITEMS,
            payload: {
                panelItems: updatedPanelItems
            }
        });
        savePanelItems(updatedPanelItems);
    };

    const savePanelItems = (updatedPanelItems: Panel[]): void => {
        const panelItemsToSave = mapPanelToPanelInput(updatedPanelItems.filter(item => item.props !== ""));

        adminService.updateDashboardPanel(state.dashboardTitle, props.surveySelectedId, panelItemsToSave).catch(() => {
            dispatch({
                type: DashboardActions.SET_COMPONENT_STATUS,
                payload: { componentStatus: ComponentStatus.error }
            });
        });
    };

    const handleBoxMove = useCallback(
        (id: string, left: number, top: number) => {
            const updatedBoxIndex = state.boxes.findIndex(box => box.id === id);
            if (updatedBoxIndex !== -1) {
                const updatedBoxes = [
                    ...state.boxes.slice(0, updatedBoxIndex),
                    Object.assign({}, state.boxes[updatedBoxIndex], { left: left, top: top }),
                    ...state.boxes.slice(updatedBoxIndex + 1)
                ];
                dispatch({
                    type: DashboardActions.SET_PANEL_BOXES,
                    payload: {
                        boxes: updatedBoxes
                    }
                });
                processCollisionCheck(updatedBoxes);
            }
        },
        [state.boxes]
    );
    const handlePanelItemDelete = (id: string): void => {
        const boxFoundIndex = state.boxes.findIndex(box => box.id === id);
        if (boxFoundIndex !== -1) {
            const updatedBoxes = [...state.boxes].filter((_, i) => i !== boxFoundIndex);
            dispatch({
                type: DashboardActions.SET_PANEL_BOXES,
                payload: {
                    boxes: updatedBoxes
                }
            });
            processCollisionCheck(updatedBoxes);
        }
    };
    const handlePanelItemSelect = (id: string): void => {
        const boxFoundIndex = state.boxes.findIndex(box => box.id === id);
        if (boxFoundIndex !== -1) {
            dispatch({
                type: DashboardActions.SET_SELECTED_PANEL_ID,
                payload: { selectedPanelId: id }
            });
            dispatch({
                type: DashboardActions.SET_PANEL_OPEN_STATUS,
                payload: { status: true }
            });
        }
    };
    const handleUpdatePanelItem = (
        column: number,
        row: number,
        type: DashboardCellType,
        updateString: string,
        titleTranslations: TitleTranslation[]
    ): void => {
        const boxFoundIndex = state.boxes.findIndex(box => box.id === state.selectedPanelId);
        if (boxFoundIndex !== -1) {
            const updatedBoxes = [
                ...state.boxes.slice(0, boxFoundIndex),
                Object.assign({}, state.boxes[boxFoundIndex], {
                    height: row,
                    width: column,
                    type: type,
                    props: updateString,
                    translations: titleTranslations
                }),
                ...state.boxes.slice(boxFoundIndex + 1)
            ];
            dispatch({
                type: DashboardActions.SET_PANEL_BOXES,
                payload: {
                    boxes: updatedBoxes
                }
            });
            processCollisionCheck(updatedBoxes);
            dispatch({
                type: DashboardActions.SET_PANEL_OPEN_STATUS,
                payload: { status: false }
            });
        }
    };
    const updateNewBox = (newPanelBox: BoxType): void => {
        const updatedBoxes = [...state.boxes, newPanelBox];
        dispatch({
            type: DashboardActions.SET_PANEL_BOXES,
            payload: {
                boxes: updatedBoxes
            }
        });
        const mappedBoxes = mapBoxesToSelectBoxes(updatedBoxes);
        checkBoxsOverlapped(mappedBoxes);
        setPanelItemCount(prev => prev + 1);
    };

    const addNewBox = (cellType: DashboardCellType): void => {
        if (cellType === "image" || cellType === "singleDimensionOrItem") {
            const newPanelBox = {
                id: `${panelItemCount}`,
                left: 0,
                top: 0,
                type: cellType,
                props: "",
                height: 2,
                width: 2,
                translations: defaultItemTitleTranslation,
                overlapped: false
            };
            updateNewBox(newPanelBox);
        } else if (cellType === "keyDrivers") {
            const newPanelBox = {
                id: `${panelItemCount}`,
                left: 0,
                top: 0,
                type: cellType,
                props: "",
                height: 2,
                width: 4,
                translations: defaultItemTitleTranslation,
                overlapped: false
            };
            updateNewBox(newPanelBox);
        } else if (
            cellType === "overallDimension" ||
            cellType === "singleDimensionWithItems" ||
            cellType === "lookdown"
        ) {
            const newPanelBox = {
                id: `${panelItemCount}`,
                left: 0,
                top: 0,
                type: cellType,
                props: "",
                height: 4,
                width: 5,
                translations: defaultItemTitleTranslation,
                overlapped: false
            };
            updateNewBox(newPanelBox);
        }
    };

    const handleCellTypeSelected = (id: number | string): void => {
        dispatch({
            type: DashboardActions.SET_SELECTED_CELL_TYPE,
            payload: { cellType: id as DashboardCellType }
        });
        addNewBox(id as DashboardCellType);
    };

    const getPreviewDisableRule = (): boolean => {
        const checkBoxes = mapBoxesToSelectBoxes(state.boxes);
        const overlapped = checkBoxes.map(box => checkItemsOverlap(box, checkBoxes)).includes(true);
        const unfinished = checkBoxes.map(box => box.props === "").includes(true);
        return state.boxes.length === 0 || overlapped || unfinished;
    };

    const fetchDashboardLayout = (): void => {
        setLoading(true);
        adminService
            .getDashboardPanel(props.surveySelectedId)
            .then(response => {
                setPanelItemCount(response.items.length);
                const mappedBoxes = response.items.map((item: Panel, index: number) => ({
                    id: `${index}`,
                    left: item.xPosition * boxSize,
                    top: item.yPosition * boxSize,
                    type: getKeyFromObject(dashboardCellOptions, item.type),
                    props: item.props,
                    translations: item.translations.length > 0 ? item.translations : defaultItemTitleTranslation,
                    height: item.height,
                    width: item.width,
                    overlapped: false
                }));
                const panelItemsFromResponse = response.items.map((item: Panel) => ({
                    xPosition: item.xPosition,
                    yPosition: item.yPosition,
                    type: getKeyFromObject(dashboardCellOptions, item.type),
                    props: item.props,
                    translations: item.translations.length > 0 ? item.translations : defaultItemTitleTranslation,
                    height: item.height,
                    width: item.width
                }));
                const payload: Partial<DashboardState> = {
                    boxes: mappedBoxes,
                    dashboardTitle: response.translations.length > 0 ? response.translations : state.dashboardTitle,
                    hasBenchmark: response.hasBenchmark,
                    hasPreviousPeriod: response.hasPreviousPeriod,
                    panelItems: panelItemsFromResponse
                };
                dispatch({
                    type: DashboardActions.SET_DASHBOARD_PANEL,
                    payload
                });
                setLoading(false);
            })
            .catch(() => {
                dispatch({
                    type: DashboardActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchDashboardLayout();
        createGrid();
    }, []);

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <div className={classes.defaultTtile} data-testid={"dashboard-default-titile"}>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                paddingLeft: 16,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {getDashboardTitle()}
                        </Typography>
                    </div>
                    <IconButtonV4
                        path={mdiPencil}
                        onClick={(e): void => {
                            handleOpenPopover(e);
                        }}
                        dataTestid="dashboard-title-edit"
                    />
                </div>
                <Box display="flex" gap={2} paddingRight={2}>
                    <Button
                        variant="text"
                        onClick={handlePreviewButtonOnClick}
                        disabled={getPreviewDisableRule()}
                        data-testid="btn-dashboard-preview"
                    >
                        {lang.preview}
                    </Button>
                    <Button
                        variant="text"
                        onClick={handleClearAllButtonOnClick}
                        disabled={state.boxes.length === 0}
                        data-testid="btn-dashboard-clearall"
                    >
                        {lang.clearAll}
                    </Button>
                </Box>
            </div>
            <div className={classes.panelsContainer}>
                <Box width={260} minWidth={260}>
                    <List
                        size="large"
                        itemSelected={state.selectedCellType}
                        items={Object.keys(dashboardCellOptions).map(option => {
                            return {
                                id: option,
                                name: lang[option] as string
                            };
                        })}
                        onItemSelect={handleCellTypeSelected}
                    />
                </Box>
                <div className={classes.grid}>
                    {grid.map((row, indexRow) => {
                        return (
                            <div key={indexRow} className={classes.gridRow}>
                                {row.map((_, indexCol) => {
                                    return (
                                        <div key={indexCol} className={classes.gridItem}>
                                            <div className={classes.node}></div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className={classes.dashboardContainer}>
                    <DndProvider backend={HTML5Backend}>
                        <DashboardContainer
                            boxes={state.boxes}
                            moveBox={handleBoxMove}
                            deletePanelItem={handlePanelItemDelete}
                            panelItemSelect={handlePanelItemSelect}
                        />
                    </DndProvider>
                </div>
            </div>
            <PanelDialog
                surveySelectedId={props.surveySelectedId}
                onCancelClicked={closePanelDialog}
                open={state.panelDialogOpen}
                selectedPanelId={state.selectedPanelId}
                updatePanelItem={handleUpdatePanelItem}
                boxes={state.boxes}
                hasPreviousPeriod={state.hasPreviousPeriod}
                hasBenchmark={state.hasBenchmark}
            />
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
            <ConfirmationDialog
                open={state.confirmationDialog}
                onCancelClicked={handleCancelClearAll}
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.deleteDashboardConfiguration}
                message={lang.deleteCannotBeReversed}
                confirmButtonText={lang.delete}
                cancelButtonVariant="text"
            />
            <DashboardTitlePopup
                anchorEl={anchorEl}
                dashboardTitle={state.dashboardTitle}
                defaultDashboardTitle={defaultDashboardTitle}
                handleClosePopover={handleClosePopover}
                applyTitle={handleApplyDashboardTitle}
                cancelClicked={handleCancalDashboardTitle}
            />
        </div>
    );
};

export default Dashboard;

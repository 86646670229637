import React, { useReducer, ReactElement, useEffect, useState, MouseEvent } from "react";
import { Help } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateIcon from "@mui/icons-material/Create";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";

import { List, ListItem, ListItemText } from "lib/list";
import { IconButton, IconButtonV4 } from "lib/icon-button";
import {
    Benchmark,
    BenchmarkItemMapping,
    BenchmarkSection,
    BenchmarkItem,
    BenchmarkMappingState,
    BenchmarkQuestion
} from "../interfaces";
import { useLang, useOrgChartService, useLoading, useSurveys } from "core/hooks";
import { initBenchmark, initCoordinate, initSurveyItemIdSelected } from "../init";
import { Checkbox } from "lib/checkbox";
import { Button } from "lib/button";
import { SurveyQuestion, SurveyItem, SurveySection } from "managerPortal/context/interfaces";
import { initSurveySection } from "managerPortal/context/initialState";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { BenchmarkMappingActions } from "../actions";
import { benchmarkMappingReducer } from "../reducers";
import { useBenchmarkMappingStyles } from "./useBenchmarkMappingStyles";
import { emptySurveySettings } from "managerPortal/interfaces/InitialStates";
import { sortArray } from "core/helpers";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { BenchmarkMappingDialog } from "./BenchmarkMappingDialog";
import { Box } from "lib/box";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";

type Props = {
    surveySelectedId: number;
};

const BenchmarkMapping = (props: Props): ReactElement => {
    const orgChartService = useOrgChartService();
    const classes = useBenchmarkMappingStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const { setLoading } = useLoading();
    const { surveys } = useSurveys();
    const selectedSurvy = surveys.find(s => s.id === props.surveySelectedId);
    const isLocked = selectedSurvy && selectedSurvy.lockMappings;

    const initialState = (): BenchmarkMappingState => {
        return {
            benchmarks: [initBenchmark(lang.noBenchmark)],
            sectionSelected: [...initSurveySection],
            surveySections: [],
            benchmarkSelected: initBenchmark(lang.noBenchmark),
            benchmarkSections: [],
            benchmarkMappings: [],
            confirmAutoMapDialogOpen: false,
            surveySettings: emptySurveySettings,
            confirmationDialogContent: { title: " ", message: "", dialogOnClick: (): void => undefined },
            anchorElement: initCoordinate,
            surveyItemIdSelected: initSurveyItemIdSelected,
            disableAutoMap: false
        };
    };
    const [state, dispatch] = useReducer(benchmarkMappingReducer, null, initialState);
    const [isAllCollapsed, setCollapseAll] = useState(false);
    const [isAllExpanded, setExpandAll] = useState(false);
    const [lockConfirmationDialog, setLockConfirmationDialog] = useState(false);
    const [clearDimensionConfirmationDialog, setClearDimensionConfirmationDialog] = useState(false);
    const [selectedClearDimensionSectionId, setSelectedClearDimensionSectionId] = useState("");

    const sortBenchmarksArray = (arrayToSort: Benchmark[]): Benchmark[] => {
        if (arrayToSort[0].name.toLowerCase() === (lang.noBenchmark.toLowerCase() as string)) {
            return [
                arrayToSort[0],
                ...sortArray(
                    arrayToSort.filter(x => x.name.toLowerCase() !== (lang.noBenchmark.toLowerCase() as string)),
                    "name",
                    "asc"
                )
            ];
        } else {
            return sortArray(arrayToSort, "name", "asc");
        }
    };

    const handleSelectBenchmark = (benchmarkId?: number): void => {
        const benchmark = state.benchmarks.find((b: Benchmark) => b.id === benchmarkId);

        if (benchmark && benchmarkId !== undefined) {
            orgChartService.selectItemBenchmarkMap(props.surveySelectedId, benchmarkId).then(() => {
                orgChartService.getBenchmarkSections(benchmarkId).then((responseData: BenchmarkSection[]) => {
                    if (benchmark.id == initBenchmark(lang.noBenchmark).id && state.surveySettings.showBenchmarks) {
                        setShowBenchmarks();
                    }
                    dispatch({
                        type: BenchmarkMappingActions.SELECT_BENCHMARK,
                        payload: {
                            benchmark: benchmark,
                            benchmarkSections: responseData
                        }
                    });
                    dispatch({
                        type: BenchmarkMappingActions.DISABLE_AUTOMAP,
                        payload: {
                            disableAutoMap: false
                        }
                    });
                });
            });
        }
        dispatch({
            type: BenchmarkMappingActions.SHOW_AUTOMAP_CONFIRM_DIALOG,
            payload: { showAutomapConfirmDialog: false }
        });
    };

    const getBenchmarkItemText = (itemId: string): string | BenchmarkItem["text"] => {
        let benchmarkSectionText = "";
        const itemMapped = state.benchmarkMappings.find((item: BenchmarkItemMapping) => item.itemId === itemId);
        const benchmarkItem =
            state.benchmarkSections &&
            itemMapped &&
            state.benchmarkSections
                .map(section => section.questions)
                .reduce((a, b) => a.concat(b), [])
                .map(q => q.items)
                .reduce((a, b) => a.concat(b), [])
                .find((item: BenchmarkItem) => item.standardItemId === itemMapped.benchmarkItemId);
        state.benchmarkSections.forEach(section => {
            const items = section.questions.map(q => q.items).reduce((a, b) => a.concat(b), []);
            if (itemMapped && items.find((item: BenchmarkItem) => item.standardItemId === itemMapped.benchmarkItemId)) {
                benchmarkSectionText = section.text;
            }
        });
        return benchmarkItem ? `${benchmarkItem.text}  (${benchmarkSectionText})` : "";
    };

    const showAutomapConfirmDialog = (show: boolean): void => {
        dispatch({
            type: BenchmarkMappingActions.SHOW_AUTOMAP_CONFIRM_DIALOG,
            payload: { showAutomapConfirmDialog: show }
        });
    };

    const confirmAutoMap = (): void => {
        showAutomapConfirmDialog(false);
        setLoading(true);
        orgChartService.autoMapBenchmarkItems(props.surveySelectedId).then(responseData => {
            if (
                !state.surveySettings.showBenchmarks &&
                state.benchmarkSelected.id !== initBenchmark(lang.noBenchmark).id
            ) {
                setShowBenchmarks();
            }
            dispatch({
                type: BenchmarkMappingActions.CONFIRM_AUTOMAP,
                payload: {
                    benchmarkMappings: responseData
                }
            });
            dispatch({
                type: BenchmarkMappingActions.DISABLE_AUTOMAP,
                payload: {
                    disableAutoMap: true
                }
            });
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
    };

    const clearBenchmarkMapping = (): void => {
        showAutomapConfirmDialog(false);
        setLoading(true);
        orgChartService.clearBenchmarkItems(props.surveySelectedId).then(() => {
            dispatch({
                type: BenchmarkMappingActions.CLEAR_BENCHMARK,
                payload: {
                    benchmarkMappings: []
                }
            });
            dispatch({
                type: BenchmarkMappingActions.DISABLE_AUTOMAP,
                payload: {
                    disableAutoMap: false
                }
            });
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
    };

    const handleSelectSection = (section: SurveySection): void => {
        if (state.sectionSelected.find(s => s.sectionId === section.sectionId) === undefined) {
            dispatch({
                type: BenchmarkMappingActions.SELECT_SECTION,
                payload: { sectionSelected: section }
            });
        } else {
            dispatch({
                type: BenchmarkMappingActions.DESELECT_SECTION,
                payload: { sectionSelected: section }
            });
        }
    };

    const setShowBenchmarks = (): void => {
        const updatedSettings = { ...state.surveySettings, showBenchmarks: !state.surveySettings.showBenchmarks };

        try {
            orgChartService.setSurveySettings(props.surveySelectedId, JSON.stringify(updatedSettings));
            dispatch({
                type: BenchmarkMappingActions.SET_SURVEY_SETTINGS,
                payload: { surveySettings: updatedSettings }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const isBenchmarkItemMappingComplete = (
        item: SurveyItem,
        benchmarkItemsMappings: BenchmarkItemMapping[]
    ): boolean => {
        return (
            benchmarkItemsMappings.find(
                (itemMap: BenchmarkItemMapping) => itemMap.itemId === item.itemId && itemMap.benchmarkItemId !== -1
            ) !== undefined
        );
    };

    const getMappedBenchmarkItemsNummber = (
        sectionOrQuestion: SurveySection,
        benchmarkItemsMappings: BenchmarkItemMapping[]
    ): number => {
        const allItems: SurveyItem[] = [];

        if (sectionOrQuestion.questions) {
            sectionOrQuestion.questions.forEach((question: SurveyQuestion) => {
                question.items.forEach((item: SurveyItem) => allItems.push(item));
            });
        }

        const filtered = benchmarkItemsMappings.filter((bmItem: BenchmarkItemMapping) => {
            return (
                allItems.filter((item: SurveyItem) => {
                    return bmItem.itemId === item.itemId && bmItem.benchmarkItemId !== -1;
                }).length > 0
            );
        });

        return filtered.length;
    };

    const getSectionItemsNummber = (sectionOrQuestion: SurveySection): number => {
        const allItems: SurveyItem[] = [];
        let questionsCount = 0;

        if (sectionOrQuestion.questions) {
            sectionOrQuestion.questions.forEach((question: SurveyQuestion) => {
                if (question.isFun) questionsCount += question.items.length;
                question.items.forEach((item: SurveyItem) => allItems.push(item));
            });
        }
        return questionsCount;
    };

    const handleBenchmarkSelected = (event: SelectChangeEvent<unknown>): void => {
        const { value } = event.target;
        if (value !== undefined && value !== null) {
            handleSelectBenchmark(+value);
        }
    };

    const handleCollapseAll = (): void => {
        dispatch({
            type: BenchmarkMappingActions.DESELECT_ALL_SECTION,
            payload: { sectionSelected: initSurveySection }
        });
    };

    const handleExpandAll = (): void => {
        if (state.surveySections && state.surveySections.length > 0) {
            dispatch({
                type: BenchmarkMappingActions.SELECT_ALL_SECTION,
                payload: { sectionSelected: state.surveySections }
            });
        }
    };
    const editBenchmarkItemMapping = (event: MouseEvent<HTMLButtonElement>, itemId: string): void => {
        const mapped =
            state.benchmarkMappings &&
            state.benchmarkMappings.find(
                (itemMap: BenchmarkItemMapping) => itemMap.itemId === itemId && itemMap.benchmarkItemId !== -1
            ) !== undefined;
        dispatch({
            type: BenchmarkMappingActions.OPEN_MAPPINGDIALOG,
            payload: {
                anchorElement: {
                    top: event.clientY,
                    left: event.clientX
                },
                surveyItemIdSelected: {
                    itemId,
                    mapped
                }
            }
        });
    };

    const handleCloseConfirmation = (): void => {
        setLockConfirmationDialog(false);
    };

    useEffect(() => {
        setLoading(true);

        orgChartService.getBenchmarkMappingInit(props.surveySelectedId, user.settings.languageCode).then(response => {
            const filteredSections: SurveySection[] = [];
            if (response.surveySections && response.surveySections.length) {
                response.surveySections.forEach((section: SurveySection) => {
                    if (section.questions.filter(x => x.isFun).length > 0) filteredSections.push(section);
                });
            }

            if (
                !response.benchmarks.find(
                    (e: Benchmark) => e.name.toLowerCase() === (lang.noBenchmark.toLowerCase() as string)
                )
            ) {
                response.benchmarks.unshift(initBenchmark(lang.noBenchmark));
            }
            if (state.sectionSelected.filter(section => section.sectionId !== "").length > 0) {
                const updatedSectionSelected: SurveySection[] = [];
                filteredSections.forEach(section => {
                    if (state.sectionSelected.find(s => s.sectionId === section.sectionId) !== undefined) {
                        updatedSectionSelected.push(section);
                    }
                });
                dispatch({
                    type: BenchmarkMappingActions.UPDATE_SELECTED_SECTIONS,
                    payload: {
                        sectionSelected: updatedSectionSelected
                    }
                });
            }
            dispatch({
                type: BenchmarkMappingActions.SET_INITIAL_STATE,
                payload: {
                    surveySections: filteredSections,
                    benchmarks: response.benchmarks,
                    benchmarkMappings: response.benchmarkMappings ?? [],
                    benchmarkSections:
                        response.standardSections && response.standardSections.length > 0
                            ? response.standardSections
                            : [],
                    benchmarkSelected: response.mappedBenchmark ?? initBenchmark(lang.noBenchmark)
                }
            });
        });

        orgChartService.getSurveySettings(props.surveySelectedId).then(surveySettings => {
            if (surveySettings) {
                dispatch({
                    type: BenchmarkMappingActions.SET_SURVEY_SETTINGS,
                    payload: {
                        surveySettings: surveySettings
                    }
                });
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.surveySelectedId, user.settings.languageCode, orgChartService]);

    useEffect(() => {
        if (state.sectionSelected && state.sectionSelected.filter(section => section.sectionId !== "").length === 0) {
            setCollapseAll(true);
            setExpandAll(false);
        } else if (
            state.sectionSelected &&
            state.sectionSelected.filter(section => section.sectionId !== "").length === state.surveySections.length
        ) {
            setExpandAll(true);
            setCollapseAll(false);
        } else {
            setExpandAll(false);
            setCollapseAll(false);
        }
    }, [state.sectionSelected]);

    const tooltipHelpText = (
        <div>
            {lang.benchmarkMappingHelp.map((l, index) => (
                <div key={index} className={classes.textLine}>
                    {l}
                </div>
            ))}
        </div>
    );
    const funItemsFilter = (sections: BenchmarkSection[]): SelectOptionExpandablePanel[] => {
        const r = sections.map((s: BenchmarkSection): SelectOptionExpandablePanel => {
            const sOption: SelectOptionExpandablePanel = {
                id: `s${s.text.toString()}`,
                title: s.text,
                subNode: []
            };

            s.questions.forEach((q: BenchmarkQuestion) => {
                if (q.items.length > 0) {
                    q.items.forEach((i: BenchmarkItem) => {
                        const iOption: SelectOptionExpandablePanel = {
                            id: i.standardItemId.toString(),
                            title: i.text,
                            subNode: []
                        };
                        sOption.subNode?.push(iOption);
                    });
                }
            });

            return sOption;
        });

        return r;
    };

    const getMappedBenchmarkItems = (): string[] => {
        const disabledItems: string[] = [];
        const allItems = state.surveySections
            .map(section => section.questions)
            .reduce((a, b) => a.concat(b), [])
            .map(question => question.items)
            .reduce((a, b) => a.concat(b), []);

        state.benchmarkMappings.forEach((bmItem: BenchmarkItemMapping) => {
            const benchmarkMapping = allItems.find(item => item.itemId === bmItem.itemId);
            if (benchmarkMapping && bmItem.benchmarkItemId !== -1) {
                disabledItems.push(bmItem.benchmarkItemId.toString());
            }
        });
        return disabledItems;
    };

    const getMappedBenchmarkQuesionsOrSections = (): string[] => {
        const disabledIds: string[] = [];
        const mappedBenchmarkItems = getMappedBenchmarkItems();
        state.benchmarkSections.forEach(section => {
            const itemsPerSection = section.questions.map(q => q.items).reduce((a, b) => a.concat(b), []);
            if (itemsPerSection.every(item => mappedBenchmarkItems.includes(item.standardItemId.toString()))) {
                disabledIds.push(`s${section.text.toString()}`);
            }
        });
        const benchmarkQuestions = state.benchmarkSections
            .map(section => section.questions)
            .reduce((a, b) => a.concat(b), []);
        benchmarkQuestions.forEach(question => {
            const itemPerQuestion = question.items;
            if (itemPerQuestion.every(item => mappedBenchmarkItems.includes(item.standardItemId.toString()))) {
                disabledIds.push(`q${question.text.toString()}${question.items[0].standardItemId.toString()}`);
            }
        });
        return disabledIds;
    };

    const updateMapTarget = (id: number): void => {
        dispatch({
            type: BenchmarkMappingActions.DISABLE_AUTOMAP,
            payload: {
                disableAutoMap: false
            }
        });
        const updateItemsMappings = state.benchmarkMappings;
        const index = updateItemsMappings.findIndex(
            (item: BenchmarkItemMapping) => item.itemId === state.surveyItemIdSelected.itemId
        );
        if (index !== -1) {
            // update existing mapping
            let newItemMappingBenchmarkSectionName = "";
            state.benchmarkSections.forEach(section => {
                const items = section.questions.map(question => question.items).reduce((a, b) => a.concat(b), []);
                const item = items.find(item => item.standardItemId === id);
                newItemMappingBenchmarkSectionName = item ? section.text : "";
            });
            updateItemsMappings[index] = {
                ...updateItemsMappings[index],
                benchmarkItemId: id,
                benchmarkSectionName: newItemMappingBenchmarkSectionName
            };
            const newBenchmarkMappings = [...state.benchmarkMappings].map(bm =>
                bm.itemId === id.toString() ? updateItemsMappings[index] : bm
            );
            dispatch({
                type: BenchmarkMappingActions.UPDATE_ITEMS_MAPPED,
                payload: {
                    benchmarkMappings: newBenchmarkMappings
                }
            });
            handleClosePopover();
            orgChartService.updateItemBenchmarkMap(
                props.surveySelectedId,
                +updateItemsMappings[index].itemId.slice(1),
                id
            );
        } else {
            // add benchmark mapping
            let newItemMappingBenchmarkSectionName = "";
            state.benchmarkSections.forEach(section => {
                const items = section.questions.map(question => question.items).reduce((a, b) => a.concat(b), []);
                const item = items.find(item => item.standardItemId === id);
                newItemMappingBenchmarkSectionName = item ? section.text : "";
            });
            const newItemMapping: BenchmarkItemMapping = {
                benchmarkItemId: id,
                itemId: state.surveyItemIdSelected.itemId,
                benchmarkSectionName: newItemMappingBenchmarkSectionName
            };
            const newBenchmarkMappings = [...state.benchmarkMappings, newItemMapping];
            dispatch({
                type: BenchmarkMappingActions.UPDATE_ITEMS_MAPPED,
                payload: {
                    benchmarkMappings: newBenchmarkMappings
                }
            });
            handleClosePopover();
            orgChartService.updateItemBenchmarkMap(props.surveySelectedId, +newItemMapping.itemId.slice(1), id);
        }
    };

    const handleClosePopover = (): void => {
        dispatch({
            type: BenchmarkMappingActions.OPEN_MAPPINGDIALOG,
            payload: {
                anchorElement: initCoordinate,
                surveyItemIdSelected: initSurveyItemIdSelected
            }
        });
    };

    const handleConfirmationClicked = (): void => {
        const sectionToBeCleared = state.surveySections.find(
            section => section.sectionId == selectedClearDimensionSectionId
        );
        if (sectionToBeCleared) {
            const itemIdsToBeCleared = sectionToBeCleared.questions
                .map(q => q.items)
                .reduce((a, b) => a.concat(b), [])
                .map(item => +item.itemId.slice(1));
            if (itemIdsToBeCleared) {
                orgChartService
                    .clearDimensionBenchmarkMap(props.surveySelectedId, itemIdsToBeCleared)
                    .then(() => {
                        const updatedBenchmarkMappings = [...state.benchmarkMappings].filter(
                            m => !itemIdsToBeCleared.includes(+m.itemId.slice(1))
                        );
                        dispatch({
                            type: BenchmarkMappingActions.UPDATE_ITEMS_MAPPED,
                            payload: {
                                benchmarkMappings: updatedBenchmarkMappings
                            }
                        });
                    })
                    .finally(() => {
                        setSelectedClearDimensionSectionId("");
                        setClearDimensionConfirmationDialog(false);
                    });
            } else {
                setSelectedClearDimensionSectionId("");
                setClearDimensionConfirmationDialog(false);
            }
        } else {
            setSelectedClearDimensionSectionId("");
            setClearDimensionConfirmationDialog(false);
        }
    };

    if (state.surveySections.length === 0) return <></>;

    const listStyled = {
        width: "100%",
        padding: 0,
        borderRight: theme.border.main,
        height: "100%",
        overflow: "auto"
    };

    const contentInfoHeaderStyled = {
        width: "100%",
        height: 32,
        maxHeight: 32,
        display: "flex",
        alignItems: "center",
        background: theme.palette.grey[300]
    };

    return (
        <div className={classes.container}>
            <div className={classes.contentHead}>
                <div className={classes.contentHeadLeft}>
                    <Box width="250px">
                        <Select
                            items={sortBenchmarksArray(state.benchmarks).map(b => {
                                return {
                                    value: b.id,
                                    name: b.name
                                };
                            })}
                            disabled={!user.isTalentMapAdmin}
                            onChange={handleBenchmarkSelected}
                            label={lang.selectBenchmark}
                            value={state.benchmarkSelected.id.toString()}
                        />
                    </Box>
                    <Box display={"flex"} gap={1} mr={2} ml={2}>
                        <Button
                            variant={"text"}
                            onClick={(): void => {
                                if (!isLocked) {
                                    showAutomapConfirmDialog(true);
                                    dispatch({
                                        type: BenchmarkMappingActions.SET_DIALOG,
                                        payload: {
                                            confirmationDialogContent: {
                                                title: lang.confirmAutomapTitle,
                                                message: lang.confirmAutomapMessage,
                                                dialogOnClick: confirmAutoMap
                                            }
                                        }
                                    });
                                } else {
                                    setLockConfirmationDialog(true);
                                }
                            }}
                            style={{ color: isLocked ? "lightGrey" : "" }}
                            data-testid="btn-benchmark-automap"
                            disabled={state.disableAutoMap}
                        >
                            {lang.automap}
                        </Button>
                        <Button
                            variant={"text"}
                            onClick={(): void => {
                                if (!isLocked) {
                                    showAutomapConfirmDialog(true);
                                    dispatch({
                                        type: BenchmarkMappingActions.SET_DIALOG,
                                        payload: {
                                            confirmationDialogContent: {
                                                title: lang.clearBenchmarkMappingTitle,
                                                message: lang.clearBenchmarkMapping,
                                                dialogOnClick: clearBenchmarkMapping
                                            }
                                        }
                                    });
                                } else {
                                    setLockConfirmationDialog(true);
                                }
                            }}
                            data-testid="btn-benchmark-clearMap"
                            style={{ color: isLocked ? "lightGrey" : "" }}
                        >
                            {lang.clearMapping}
                        </Button>
                        <Tooltip title={tooltipHelpText}>
                            <IconButton aria-label={lang.help}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>
                <div className={classes.contentHeadRight}>
                    <Checkbox
                        checked={state.surveySettings.showBenchmarks}
                        onChange={setShowBenchmarks}
                        disabled={state.benchmarkSelected.id === initBenchmark(lang.noBenchmark).id}
                        label={lang.showBenchmarkInReports}
                    />
                    <IconButtonV4
                        path={mdiArrowExpandVertical}
                        onClick={handleExpandAll}
                        tooltip={lang.expandAll}
                        disabled={isAllExpanded}
                        dataTestid="benchmark-items-expandAll"
                    />
                    <IconButtonV4
                        path={mdiArrowCollapseVertical}
                        onClick={handleCollapseAll}
                        tooltip={lang.collapseAll}
                        disabled={isAllCollapsed}
                        dataTestid="benchmark-items-collapseAll"
                    />
                </div>
            </div>

            <div className={classes.content}>
                <>
                    <Box sx={contentInfoHeaderStyled}>
                        <Typography variant="body1" pl={3} width="50%">
                            {lang.survey}
                        </Typography>
                        <Typography variant="body1" pl={3}>
                            {lang.benchmark}
                        </Typography>
                    </Box>
                    <List aria-label="section" sx={listStyled}>
                        {state.surveySections.map((section: SurveySection) => {
                            const numberOfSectionItems = getSectionItemsNummber(section);
                            const numberOfMappedBenchmarkItems = getMappedBenchmarkItemsNummber(
                                section,
                                state.benchmarkMappings
                            );
                            return (
                                <React.Fragment key={section.sectionId}>
                                    <div
                                        className={classes.sectionListHeader}
                                        data-testid={`admin-benchmark-${section.title}`}
                                    >
                                        {state.sectionSelected.map(s => s.sectionId).includes(section.sectionId) ? (
                                            <div className={classes.sectionListHeaderIcon}>
                                                <ExpandMoreIcon
                                                    className={classes.expandLessIcon}
                                                    onClick={(): void => handleSelectSection(section)}
                                                />
                                            </div>
                                        ) : (
                                            <div className={classes.sectionListHeaderIcon}>
                                                <ExpandMoreIcon
                                                    className={classes.expandMoreIcon}
                                                    onClick={(): void => handleSelectSection(section)}
                                                />
                                            </div>
                                        )}
                                        <ListItem
                                            key={section.sectionId}
                                            onClick={(): void => handleSelectSection(section)}
                                            className={classes.sectionList}
                                        >
                                            <ListItemText
                                                primary={`${section.title} (${numberOfMappedBenchmarkItems}/${numberOfSectionItems} ${lang.mapped})`}
                                            />
                                            <Button
                                                variant="text"
                                                onClick={(e): void => {
                                                    e.stopPropagation();
                                                    setClearDimensionConfirmationDialog(true);
                                                    setSelectedClearDimensionSectionId(section.sectionId);
                                                }}
                                                disabled={numberOfMappedBenchmarkItems == 0}
                                                data-testid="btn-clear-dimension-mapping"
                                            >
                                                {lang.clear}
                                            </Button>
                                        </ListItem>
                                    </div>
                                    {state.sectionSelected.map(s => s.sectionId).includes(section.sectionId) && (
                                        <div>
                                            {state.sectionSelected
                                                .filter(s => s.sectionId === section.sectionId)[0]
                                                .questions.map((question: SurveyQuestion) => {
                                                    return (
                                                        question.isFun && (
                                                            <div key={question.questionId}>
                                                                {question.items.map((item: SurveyItem) => {
                                                                    const isCompleted = isBenchmarkItemMappingComplete(
                                                                        item,
                                                                        state.benchmarkMappings
                                                                    );
                                                                    return (
                                                                        <div
                                                                            key={item.itemId}
                                                                            className={`${classes.questionItem} ${
                                                                                !isCompleted
                                                                                    ? classes.notCompleted
                                                                                    : classes.questionCompleted
                                                                            } ${
                                                                                state.surveyItemIdSelected.itemId ===
                                                                                    item.itemId && classes.selected
                                                                            }`}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes.questionTitleTextLeft
                                                                                }
                                                                            >
                                                                                <TruncateDisplay
                                                                                    maxLabelLength={130}
                                                                                    title={item.title}
                                                                                >
                                                                                    <Typography>
                                                                                        {item.title}
                                                                                    </Typography>
                                                                                </TruncateDisplay>
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    classes.questionTitleTextRight
                                                                                }
                                                                            >
                                                                                <div>
                                                                                    <TruncateDisplay
                                                                                        maxLabelLength={120}
                                                                                        title={getBenchmarkItemText(
                                                                                            item.itemId
                                                                                        )}
                                                                                    >
                                                                                        <Typography>
                                                                                            {getBenchmarkItemText(
                                                                                                item.itemId
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TruncateDisplay>
                                                                                </div>
                                                                                <div>
                                                                                    <IconButton
                                                                                        data-testid={`benchmarkItemMap_${item.title}`}
                                                                                        onClick={(
                                                                                            event: MouseEvent<HTMLButtonElement>
                                                                                        ): void => {
                                                                                            if (!isLocked) {
                                                                                                editBenchmarkItemMapping(
                                                                                                    event,
                                                                                                    item.itemId
                                                                                                );
                                                                                            } else {
                                                                                                setLockConfirmationDialog(
                                                                                                    true
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        style={{
                                                                                            color: isLocked
                                                                                                ? "lightgrey"
                                                                                                : "default"
                                                                                        }}
                                                                                    >
                                                                                        <CreateIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </List>
                </>
            </div>
            {state.anchorElement.top !== initCoordinate.top && state.anchorElement.left !== initCoordinate.left && (
                <BenchmarkMappingDialog
                    title={lang.benchmarkMapping}
                    anchorPoints={state.anchorElement}
                    targetSurveyItemsOptions={funItemsFilter(state.benchmarkSections)}
                    updateSelectOption={updateMapTarget}
                    onClose={handleClosePopover}
                    disabledItemsIds={getMappedBenchmarkItems()}
                    disabledQuestionOrSectionIds={getMappedBenchmarkQuesionsOrSections()}
                    surveyItemIdSelected={state.surveyItemIdSelected}
                />
            )}
            <ConfirmationDialog
                onCancelClicked={(): void => showAutomapConfirmDialog(false)}
                open={state.confirmAutoMapDialogOpen}
                onConfirmationClicked={state.confirmationDialogContent.dialogOnClick}
                title={state.confirmationDialogContent.title}
                message={state.confirmationDialogContent.message}
                cancelButtonVariant="text"
            />
            <ConfirmationDialog
                open={lockConfirmationDialog}
                onCancelClicked={handleCloseConfirmation}
                onConfirmationClicked={(): void => void 0}
                title={lang.editMapping}
                message={""}
                messgaeWithLink={
                    <>
                        <span>{lang.lockMessagePrefix}</span>
                        <a href={"mailto:support@talentmap.com"} target="_blank" rel="noopener noreferrer">
                            support@talentmap.com
                        </a>
                        <span>{lang.lockMessageSuffix}</span>
                    </>
                }
                cancelButtonText={lang.ok}
                cancelButtonVariant="delete"
                hideCancelButton
            />
            <ConfirmationDialog
                open={clearDimensionConfirmationDialog}
                onCancelClicked={(): void => {
                    setClearDimensionConfirmationDialog(false);
                    setSelectedClearDimensionSectionId("");
                }}
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.clearDimensionMappings}
                message={lang.thisActionWillUnmapItemsMapping}
                confirmButtonText={lang.ok}
                cancelButtonVariant="text"
            />
        </div>
    );
};

export default BenchmarkMapping;

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useHeatMapStyles = makeStyles(() => ({
    emptyPage: {
        gridArea: "responseContent",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    infoHeader: {
        height: 72,
        display: "flex",
        borderBottom: theme.border.main,
        backgroundColor: theme.palette.background.default
    },
    infoHeaderItem: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 354,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.background.default
    },
    infoHeaderItem_dropDown: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 377,
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        boxSizing: "border-box"
    },
    infoHeaderItem_middle: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 340,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.background.default
    },
    infoHeaderItemMsg: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 550,
        borderLeft: theme.border.main,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        overflow: "hidden"
    },
    dataContent: {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "2px 1fr",
        gridTemplateAreas: `"sidebar heatmapGrid"`,
        boxSizing: "border-box",
        justifyContent: "center",
        height: "100%"
    },
    sidebar: {
        borderRight: theme.border.main,
        height: "100%",
        gridArea: "sidebar"
    },
    pivotGridWrapper: {
        gridArea: "heatmapGrid"
    },
    contactFieldInputRoot: {
        backgroundColor: "white !important",
        "&:hover": {
            backgroundColor: "white"
        },
        "& .MuiFilledInput-input": {
            backgroundColor: "white"
        }
    },
    contactFieldRoot: {
        "& .MuiInputLabel-root": {
            color: "black"
        },
        "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "1px solid rgb(0,0,0,0.42)"
        },
        "& .MuiFilledInput-underline:after": {
            borderWidth: 0
        }
    }
}));

import React, { ReactElement } from "react";
import { AddCircleOutline, Assignment } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import DrawerMenuItem from "./DrawerMenuItem";
import HierarchyList from "./HierarchyList";
import { useLang, useExistingHierarchies } from "../../../core/hooks";
import { Hierarchy } from "../hierarchy/interfaces";
import { sortArray } from "../../../core/helpers";
import { UserInfo } from "components/admin/users/interface";
import { theme } from "lib/theme";
import { Drawer } from "lib/drawer";
import { List } from "lib/list";
import { Divider } from "lib/divider";
import { Typography } from "lib/typography";

const useStyles = makeStyles(() => ({
    drawerStyle: {
        alignContent: "space-between",
        overflow: "hidden",
        height: "100% !important"
    },
    listStyle: {
        width: 320,
        height: "auto",
        display: "table",
        overflowY: "hidden",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    listHierarchiesStyle: {
        width: 320,
        height: "100%",
        overflowY: "auto"
    },
    subtitle: {
        height: 60,
        display: "flex !important",
        alignItems: "center",
        paddingLeft: 16,
        marginTop: theme.spacing(1),
        marginBottom: 0
    },
    menuItem: {
        height: 56
    }
}));

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCreateHierarchyClick: () => void;
    onSelectHierarchy: (id: number) => void;
    onSetDefaultHierarchy: (id: number) => void;
    onDeleteHierarchy: (id: number) => void;
    onRenameHierarchy: (id: number) => void;
    onEditLayoutClick: () => void;
    user: UserInfo;
};

const OrgChartDrawer = (props: Props): ReactElement => {
    const classes = useStyles();
    const { existingHierarchies } = useExistingHierarchies();
    const { lang } = useLang();

    const {
        isOpen,
        onClose,
        onCreateHierarchyClick,
        onEditLayoutClick,
        onSelectHierarchy,
        onSetDefaultHierarchy,
        onDeleteHierarchy,
        onRenameHierarchy
    } = props;

    const sortHierarchies = (): Hierarchy[] => {
        const sortedHierarchies = [...existingHierarchies];
        sortArray(sortedHierarchies, "Name", "asc");
        return sortedHierarchies;
    };

    const loadHierarchyHelper = (event: React.MouseEvent, id: number): void => {
        const elNode = event.target as HTMLElement;
        if (
            elNode.nodeName === "svg" ||
            elNode.nodeName === "path" ||
            elNode.nodeName === "BUTTON" ||
            elNode.nodeName === "LI" ||
            elNode.parentNode?.nodeName === "BUTTON"
        ) {
            return;
        }

        onSelectHierarchy(id);
    };

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            onClose={onClose}
            ModalProps={{ BackdropProps: { transitionDuration: 200 } }}
            SlideProps={{ timeout: 200 }}
            PaperProps={{
                id: "orgChartDrawer",
                classes: { root: classes.drawerStyle }
            }}
        >
            <List className={classes.listStyle} id="drawerList">
                {!props.user.isRestricted && (
                    <DrawerMenuItem
                        label={lang.createHierarchy}
                        icon={<AddCircleOutline />}
                        onSelect={onCreateHierarchyClick}
                        dataTestId="createHierarchy"
                    />
                )}
                <DrawerMenuItem
                    label={lang.configureCard}
                    icon={<Assignment />}
                    onSelect={onEditLayoutClick}
                    dataTestId="configureCard"
                />
            </List>
            <Divider />
            <Typography variant="body1" className={classes.subtitle} id="existingHierarchies">
                {lang.existingHierarchies}
            </Typography>
            <List className={classes.listHierarchiesStyle} id="hierarchiesList">
                {sortHierarchies().map(hierarchy => {
                    return (
                        <HierarchyList
                            key={hierarchy.HierarchyId}
                            hierarchy={hierarchy}
                            onSelectHierarchy={(event): void => loadHierarchyHelper(event, hierarchy.HierarchyId)}
                            onSetDefaultHierarchy={onSetDefaultHierarchy}
                            onDeleteHierarchy={onDeleteHierarchy}
                            onRenameHierarchy={onRenameHierarchy}
                        />
                    );
                })}
            </List>
        </Drawer>
    );
};
export default OrgChartDrawer;

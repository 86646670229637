import React, { ReactElement, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { SurveyInfo } from "components/admin/results/interfaces";
import { copyArrayWithObjects, getCommentsSettings } from "core/helpers";
import { useLang, useWindowResize } from "core/hooks";
import { SurveySection } from "managerPortal/context/interfaces";
import MPDialogLayout from "managerPortal/layout/MPDialogLayout";
import { CombinationOperator, FilterMode, FilterType } from "./enums";
import FilterItemsList from "./FilterItemsList";
import { FilterFieldsList } from "./FilterFieldsList";
import { initFilter, initFilterParameter } from "./inits";
import { Filter, FilterParameter } from "./interfaces";
import { surveyItemFieldsQuery } from "api/queries";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";
import Loading from "components/shared/Loading";
import { isFilterValid } from "./helper/filter-validation";
import { FilterTargetList } from "./FilterTargetList";

type Props = {
    isOpen: boolean;
    cardId?: number;
    filterMode: FilterMode;
    currentFilter?: Filter;
    surveyId: SurveyInfo["id"];
    contactFields: HrisFieldInfo[];
    center?: boolean;
    onFiltersApplied: (appliedFilters: Filter) => void;
    onCloseFilter: () => void;
};

export type FiltersState = {
    selectedFilterItem: FilterParameter;
    selectedFilterIndex: number;
    selectedFilter: Filter;
    anchorEl: null | HTMLElement;
    filterMode: FilterMode;
    surveySections: SurveySection[];
};

const initFiltersState = (currentFilter: Filter, mode: FilterMode): FiltersState => {
    return {
        selectedFilterItem: currentFilter.items[0],
        selectedFilter: currentFilter,
        anchorEl: null,
        filterMode: mode,
        surveySections: [],
        selectedFilterIndex: 0
    };
};

const Filters = ({
    isOpen,
    filterMode,
    currentFilter,
    surveyId,
    contactFields,
    center,
    onFiltersApplied,
    onCloseFilter
}: Props): ReactElement => {
    const { lang, languageCode } = useLang();
    const { user } = useUser();
    const size = useWindowResize();

    const getPaperStyle = (): React.CSSProperties => {
        const maxHeight = size.height < 700 + 160 ? size.height - 180 : 700;
        if (center) {
            return {
                width: 1080,
                position: "fixed",
                marginLeft: "auto",
                marginTop: "auto",
                maxHeight,
                height: maxHeight
            };
        }
        return { width: 1080, position: "fixed", top: 100, right: 24, marginRight: 0, maxHeight, height: maxHeight };
    };
    const [paperStyle, setPaperStyle] = useState<React.CSSProperties>(() => getPaperStyle());

    const initialFilter: Filter =
        currentFilter && currentFilter.items.length > 0
            ? currentFilter
            : {
                  ...initFilter,
                  items: [initFilterParameter]
              };

    const [state, setState] = useState<FiltersState>(
        (): FiltersState => initFiltersState(initialFilter, filterMode ?? FilterMode.snapshot)
    );

    const [getSurveyItems, { loading: loadingSurveyItems, data: surveyItemsData }] =
        useLazyQuery(surveyItemFieldsQuery);

    useEffect(() => {
        if (!loadingSurveyItems && surveyItemsData) {
            setState({ ...state, surveySections: surveyItemsData.surveyItemFields });
        }
    }, [surveyItemsData, loadingSurveyItems]);

    const handleUpdateFilterItems = (params: FilterParameter): void => {
        const updateList = copyArrayWithObjects(state.selectedFilter.items);
        const index = updateList.findIndex((item: FilterParameter) => item.order === params.order);
        updateList[index] = params;
        const updateSelectedFilter = { ...initFilter, items: updateList };
        setState({ ...state, selectedFilterItem: params, selectedFilter: updateSelectedFilter });
    };

    const handleSetCombinationOperator = (params: FilterParameter, opr: CombinationOperator): void => {
        const updateList = copyArrayWithObjects(state.selectedFilter.items);
        const index = updateList.findIndex((item: FilterParameter) => item.order === params.order);
        updateList[index] = { ...updateList[index], combinationOperator: opr };

        const updateSelectedFilter = { ...state.selectedFilter, items: updateList };
        setState({ ...state, selectedFilter: updateSelectedFilter, selectedFilterItem: updateList[index] });
    };

    const handleClearFilters = (): void => {
        setState({
            ...state,
            selectedFilterIndex: -1,
            selectedFilter: initFilter,
            selectedFilterItem: {
                ...initFilterParameter,
                filterType: FilterType.none
            }
        });
    };

    const handleAddFilterItem = (): void => {
        const updateItem = copyArrayWithObjects(state.selectedFilter.items);
        if (
            !updateItem.some(
                (item: FilterParameter) =>
                    item.filterType === initFilterParameter.filterType &&
                    item.field.id === initFilterParameter.field.id &&
                    item.comparisonOperator === initFilterParameter.comparisonOperator &&
                    item.target === initFilterParameter.target
            )
        ) {
            const newItem = { ...initFilterParameter, order: updateItem.length };
            updateItem.push(newItem);
            const updateSelectedFilter = { ...state.selectedFilter, items: updateItem };
            setState({
                ...state,
                selectedFilterIndex: updateItem.length - 1,
                selectedFilter: updateSelectedFilter,
                selectedFilterItem: newItem
            });
        }
    };

    const handleSelectFilterItem = (index: number): void => {
        setState({ ...state, selectedFilterIndex: index, selectedFilterItem: state.selectedFilter.items[index] });
    };

    const handleDeleteFilterItem = (index: number): void => {
        const copied = copyArrayWithObjects(state.selectedFilter.items);
        copied.splice(index, 1);
        const updatedFilters = copied.map((item: FilterParameter, i: number) => ({
            ...item,
            field: item.filterType === FilterType.date ? { id: -1 * i, fieldName: "date" } : item.field,
            order: i
        }));
        const updateSelectedFilter = { ...state.selectedFilter, items: updatedFilters };
        const newIndex =
            index === state.selectedFilterIndex // is the deleted filter the selected one?
                ? 0 // if so select the first one in the list, or the empty one if deleting the last filter
                : index < state.selectedFilterIndex // is the selected filter after the deleted one in the list?
                  ? state.selectedFilterIndex - 1 // we adjust the index by one so the same filter is still selected
                  : state.selectedFilterIndex; // then it doesn't change
        setState({
            ...state,
            selectedFilterItem: updateSelectedFilter.items.length
                ? updateSelectedFilter.items[newIndex]
                : {
                      ...initFilterParameter,
                      filterType: FilterType.none
                  },
            selectedFilter: updateSelectedFilter,
            selectedFilterIndex: newIndex
        });
    };

    const handleCloseDialog = (): void => {
        onCloseFilter();
    };

    const handleApplyClick = (): void => {
        if (isFilterValid(state)) {
            onFiltersApplied(state.selectedFilter);
        }
        onCloseFilter();
    };

    const handleKeyUp = (): void => {
        if (isFilterValid(state)) {
            handleApplyClick();
        }
    };

    useEffect(() => {
        getSurveyItems({
            variables: { surveyId: surveyId, languageCode: languageCode, includeQuestionTypes: 0 }
        });
        return () => {
            setState(initFiltersState(initialFilter, filterMode ?? FilterMode.snapshot));
        };
    }, []);

    const getDataTestIdAndLabel = (): string => {
        if (state.filterMode === FilterMode.previousPeriod) {
            return lang.clearMappings;
        }

        return lang.clearFilters;
    };

    useEffect(() => {
        if (size.height !== 0) {
            setPaperStyle(getPaperStyle());
        }
    }, [size.height]);

    if (loadingSurveyItems) {
        return <Loading />;
    }

    return (
        <MPDialogLayout
            primaryTitle={
                state.filterMode === FilterMode.snapshot
                    ? lang.filters
                    : state.filterMode === FilterMode.previousPeriod
                      ? lang.editMapping
                      : lang.filters
            }
            isDialogOpen={isOpen}
            handleCloseDialog={handleCloseDialog}
            dataTestId="dialog-filters"
            paperProps={{ style: paperStyle }}
            buttonsLabel={{ action: lang.ok }}
            isDoubleAction
            disableActionButton={!isFilterValid(state)}
            handleAction={handleApplyClick}
            noBackdrop
            loading={loadingSurveyItems || state.surveySections.length <= 0}
            actionButtons={[
                {
                    label: getDataTestIdAndLabel(),
                    onClickAction: (): void => handleClearFilters(),
                    isDisabled: loadingSurveyItems,
                    variant: "text"
                }
            ]}
            handleKeyUp={handleKeyUp}
        >
            <Box display="flex" height="100%">
                <Box width={360} overflow="auto">
                    <FilterItemsList
                        filterItems={state.selectedFilter.items}
                        selectedFilterItem={state.selectedFilterItem}
                        onAddFilterItem={handleAddFilterItem}
                        onDeleteFilterItem={handleDeleteFilterItem}
                        onSelectFilterItem={handleSelectFilterItem}
                        onSetCombinationOperator={handleSetCombinationOperator}
                    />
                </Box>
                <Divider orientation="vertical" />
                <Box width={360}>
                    <FilterFieldsList
                        selectedFilterItem={state.selectedFilterItem}
                        updateFilterItems={handleUpdateFilterItems}
                        filterItems={state.selectedFilter.items}
                        filterMode={state.filterMode}
                        surveyId={surveyId}
                        surveySections={state.surveySections}
                        fieldsList={contactFields}
                        showSentiment={
                            getCommentsSettings(surveyId, user.settings.commentsSettings).showSentimentScores
                        }
                    />
                </Box>
                <Divider orientation="vertical" />
                <Box width={360}>
                    {state.selectedFilterItem.field.id !== initFilterParameter.field.id && (
                        <FilterTargetList
                            selectedFilterItem={state.selectedFilterItem}
                            updateFilterItems={handleUpdateFilterItems}
                            surveySections={state.surveySections}
                            fieldsList={contactFields}
                        />
                    )}
                </Box>
            </Box>
        </MPDialogLayout>
    );
};

export default Filters;

import React from "react";

import MuiMenuList, { MenuListProps } from "@mui/material/MenuList";

const MenuList = (props: MenuListProps) => {
    const { children, ...rest } = props;
    return <MuiMenuList {...rest}>{children}</MuiMenuList>;
};

export { MenuList };

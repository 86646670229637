import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { useLang } from "core/hooks";
import { theme } from "lib/theme";
import { Filter } from "components/filters/interfaces";
import FilterChip from "components/shared/FilterChip";

type Props = {
    responseCount: number;
    currentFilter: Filter;
    handleDeleteFilterItem: (filterItemOrder: number) => void;
};

const ShowConfidentialtyMessageWithCountAndFilter = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { responseCount, currentFilter, handleDeleteFilterItem } = props;
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" pl={3} pr={3} sx={{ borderBottom: `${theme.border.main}`, height: 50 }}>
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="h6" whiteSpace={"nowrap"}>
                        {lang.totalResponses} - {responseCount}
                    </Typography>
                    {currentFilter.items && currentFilter.items.length > 0 && (
                        <FilterChip items={currentFilter.items} onDelete={handleDeleteFilterItem} />
                    )}
                </Box>
            </Box>
            <Typography variant="body1" sx={{ marginTop: "24px", marginLeft: "24px", fontWeight: 500 }}>
                {lang.confidentialityWarning}
            </Typography>
            {lang.disallowedFilterParagraphs.map((text: string, index: number) => (
                <Typography
                    key={index}
                    variant="body2"
                    sx={{ marginTop: "12px", marginLeft: "24px", marginRight: "24px" }}
                >
                    {text}
                </Typography>
            ))}
        </Box>
    );
};

export default ShowConfidentialtyMessageWithCountAndFilter;

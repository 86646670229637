import { CorrelationState } from "../interface";
import { CorrelationActions } from "../action";
import { Action } from "core/interfaces";

export const commentReducer = (state: CorrelationState, action: Action): CorrelationState => {
    const { type, payload } = action;

    switch (type) {
        case CorrelationActions.SELECT_SURVEY:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                currentFilter: payload.currentFilter
            };
        case CorrelationActions.LOAD_SECTION_HRIS:
            return {
                ...state,
                surveyItemsOptions: payload.surveyItemsOptions
            };
        case CorrelationActions.SET_STATUS:
            return { ...state, status: payload.status };
        case CorrelationActions.RESTORE_USER_SETTINGS:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                surveyItemsOptions: payload.surveyItemsOptions,
                currentFilter: payload.currentFilter
            };
        case CorrelationActions.SET_FILTER:
            return {
                ...state,
                currentFilter: payload.currentFilter,
                status: payload.status
            };
        case CorrelationActions.OPEN_CLOSE_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case CorrelationActions.SET_CONFIDENTIALITY_STATUS:
            return {
                ...state,
                confidentialityResult: payload.confidentialityResult
            };
        case CorrelationActions.SET_GRID_DATA:
            return {
                ...state,
                gridData: payload.gridData,
                status: payload.status,
                responseCount: payload.responseCount,
                confidentialityResult: payload.confidentialityResult
            };
        default:
            return {
                ...state
            };
    }
};

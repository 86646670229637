import React, { ReactElement, useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
    createSurveyComparisonMutation,
    editSurveyComparisonFieldMapMutation,
    editSurveyComparisonMutation,
    deleteSurveyComparisonMutation,
    editSurveyComparisonValueMapMutation,
    editSurveyComparisonItemMapMutation
} from "api/mutations";
import { loadContactFieldsAndSurveyItems, previousPeriodOnLoad } from "api/queries";
import { useLang, useLoading, useSurveys, useAdminService } from "core/hooks";
import { initSurveyComparisons, initUpdatedSurveyComparisonValue, initSnackbar } from "./init";
import { ContactField, FieldMap, SurveyComparison, UpdatedSurveyComparisonValue, ValueMap } from "./interfaces";
import PreviousPeriodMapped from "./PreviousPeriodMapped";
import PreviousPeriodNotMapped from "./PreviousPeriodNotMapped";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { Snackbar } from "lib/snackbar";
import Error500 from "components/errorPage/Error500";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";
import { QuestionType } from "managerPortal/context/enums";
import { sortArray } from "core/helpers";
import { SurveyInfo } from "../interfaces";
import ConfirmationDialog from "components/shared/ConfirmationDialog";

export interface SelectOption {
    id: string;
    title: string;
    subNode?: SelectOption[];
}

type State = {
    sourceContactFields: ContactField[];
    targetContactFields: ContactField[];
    surveyComparisons: SurveyComparison[];
    updatedSurveyComparisonValue: UpdatedSurveyComparisonValue;
    snackbar: {
        isOpen: boolean;
        message: string;
    };
    surveyItemsOptions: SelectOptionExpandablePanel[];
    targetSurveyItemsOptions: SelectOptionExpandablePanel[];
    targetSurveySelectedId: number;
    confirmationDialog: boolean;
    selectedClearDimensionSectionId: string;
};

const PreviousPeriod = (): ReactElement => {
    const adminService = useAdminService();
    const { setLoading } = useLoading();
    const routeParams = useParams<{ id: string }>();
    const { lang, languageCode } = useLang();
    const { surveys } = useSurveys();

    const [state, setState] = useState<State>({
        sourceContactFields: [],
        targetContactFields: [],
        surveyComparisons: initSurveyComparisons,
        updatedSurveyComparisonValue: initUpdatedSurveyComparisonValue,
        snackbar: initSnackbar,
        surveyItemsOptions: [],
        targetSurveyItemsOptions: [],
        targetSurveySelectedId: -1,
        confirmationDialog: false,
        selectedClearDimensionSectionId: ""
    });

    const [
        getContactFieldsAndSurveyItems,
        { loading: loadingContactFieldsAndSurveyItems, error: errorContactFieldsAndSurveyItems, variables }
    ] = useLazyQuery(loadContactFieldsAndSurveyItems, {
        onCompleted: data => {
            if (data) {
                if (variables!.surveyId !== +routeParams.id) {
                    setState({
                        ...state,
                        targetContactFields: sortArray(data.surveyContactFields, "title", "asc"),
                        targetSurveyItemsOptions: funItemsFilter(data.surveyItemFields),
                        targetSurveySelectedId: variables!.surveyId
                    });
                } else {
                    setState({
                        ...state,
                        surveyItemsOptions: funItemsFilter(data.surveyItemFields),
                        sourceContactFields: sortArray(data.surveyContactFields, "title", "asc")
                    });
                }
            }
        }
    });

    const [runPreviousPeriodOnLoad, { error: errorPreviousPeriodOnLoad, loading: loadingPreviousPeriodOnLoad }] =
        useLazyQuery(previousPeriodOnLoad, {
            variables: { surveyId: +routeParams.id, languageCode },
            onCompleted: data => {
                const surveyItemsOptions = funItemsFilter(data.surveyItemFields);
                if (data.surveyComparisons && data.surveyComparisons.length) {
                    const targetSurveyComparison = data.surveyComparisons as SurveyComparison[];
                    const targetSurveyIds = targetSurveyComparison.map(survey => survey.targetSurveyId);
                    if (state.targetSurveySelectedId !== -1) {
                        setState({
                            ...state,
                            surveyComparisons: data.surveyComparisons,
                            surveyItemsOptions: surveyItemsOptions,
                            sourceContactFields: data.surveyContactFields,
                            targetSurveySelectedId: state.targetSurveySelectedId
                        });
                        getContactFieldsAndSurveyItems({
                            variables: { surveyId: state.targetSurveySelectedId, languageCode }
                        });
                    } else {
                        setState({
                            ...state,
                            surveyComparisons: data.surveyComparisons,
                            surveyItemsOptions: surveyItemsOptions,
                            sourceContactFields: data.surveyContactFields,
                            targetSurveySelectedId: targetSurveyIds[0]
                        });
                        getContactFieldsAndSurveyItems({
                            variables: { surveyId: data.surveyComparisons[0].targetSurveyId, languageCode }
                        });
                    }
                } else if (data.surveyContactFields) {
                    setState({
                        ...state,
                        sourceContactFields: data.surveyContactFields,
                        surveyItemsOptions: surveyItemsOptions
                    });
                }
            }
        });

    const [runAutoMap, automapStatus] = useMutation(createSurveyComparisonMutation, {
        onCompleted: response => {
            if (response.createSurveyComparison) {
                const newSurveyComparisons = [];
                if (state.surveyComparisons[0].id !== -1) {
                    newSurveyComparisons.push(state.surveyComparisons[0]);
                }
                newSurveyComparisons.push(response.createSurveyComparison);
                setState({
                    ...state,
                    surveyComparisons: newSurveyComparisons,
                    targetSurveySelectedId: response.createSurveyComparison.targetSurveyId
                });

                getContactFieldsAndSurveyItems({
                    variables: { surveyId: response.createSurveyComparison.targetSurveyId, languageCode }
                });

                return;
            }
        },
        onError: ({ networkError }) => {
            if (networkError) {
                setState({ ...state, snackbar: { isOpen: true, message: networkError.message } });
            } else {
                setState({ ...state, snackbar: { isOpen: true, message: lang.unableToAutoMap } });
            }
        }
    });

    const [editSurveyItemMapping, editItemMappingStatus] = useMutation(editSurveyComparisonItemMapMutation, {
        onCompleted: data => {
            if (data) {
                const { sourceFieldId, targetFieldId } = data.editSurveyComparisonItemMap;
                const updateSurveyComparisons = state.surveyComparisons.filter(
                    comparison => comparison.targetSurveyId === state.targetSurveySelectedId
                )[0];

                const foundIndex = updateSurveyComparisons.fieldMaps.findIndex(
                    (fieldMap: FieldMap) => fieldMap.sourceFieldId === sourceFieldId
                );
                if (foundIndex !== -1) {
                    updateSurveyComparisons.fieldMaps[foundIndex].targetFieldId = targetFieldId;
                    const index = state.surveyComparisons.findIndex(
                        x => x.targetSurveyId === state.targetSurveySelectedId
                    );
                    setState({
                        ...state,
                        surveyComparisons: [
                            ...state.surveyComparisons.slice(0, index),
                            Object.assign({}, state.surveyComparisons[index], updateSurveyComparisons),
                            ...state.surveyComparisons.slice(index + 1)
                        ]
                    });
                }
            }
        },
        onError: ({ networkError }) => {
            if (networkError) {
                setState({ ...state, snackbar: { isOpen: true, message: networkError.message } });
            } else {
                setState({ ...state, snackbar: { isOpen: true, message: lang.unableToRemapGroup } });
            }
        }
    });

    const [editSurveyComparisonField, editSurveyComparisonFieldStatus] = useMutation(
        editSurveyComparisonFieldMapMutation,
        {
            onCompleted: response => {
                const updateSurveyComparisons = state.surveyComparisons.filter(
                    comparison => comparison.targetSurveyId === state.targetSurveySelectedId
                )[0];
                if (response.editSurveyComparisonFieldMap.length > 0) {
                    response.editSurveyComparisonFieldMap.forEach((fieldMap: ValueMap) => {
                        const foundIndex = updateSurveyComparisons.valueMaps.findIndex(
                            (valueMap: ValueMap) =>
                                valueMap.sourceValue === fieldMap.sourceValue &&
                                valueMap.sourceFieldId === fieldMap.sourceFieldId
                        );
                        updateSurveyComparisons.valueMaps[foundIndex].targetFilters = fieldMap.targetFilters;
                    });
                }
                const index = state.surveyComparisons.findIndex(x => x.targetSurveyId === state.targetSurveySelectedId);
                setState({
                    ...state,
                    surveyComparisons: [
                        ...state.surveyComparisons.slice(0, index),
                        Object.assign({}, state.surveyComparisons[index], updateSurveyComparisons),
                        ...state.surveyComparisons.slice(index + 1)
                    ]
                });
            },
            onError: () => {
                setState({ ...state, snackbar: { isOpen: true, message: lang.unableToRemapGroup } });
            }
        }
    );

    const [deleteSurveyComparison, deleteSurveyComparisonStatus] = useMutation(deleteSurveyComparisonMutation, {
        onCompleted: response => {
            const newSurveyComparisons = state.surveyComparisons.filter(
                comparison => comparison.id !== +response.deleteSurveyComparison
            );
            if (newSurveyComparisons.length === 0) {
                setState({
                    ...state,
                    targetContactFields: [],
                    targetSurveyItemsOptions: [],
                    surveyComparisons: initSurveyComparisons
                });
            } else {
                setState({
                    ...state,
                    targetSurveySelectedId: newSurveyComparisons[0].targetSurveyId
                });
                runPreviousPeriodOnLoad();
            }
        },
        onError: () => {
            setState({ ...state, snackbar: { isOpen: true, message: lang.unableToDeleteSurveyComparison } });
        }
    });

    const [editSurveyComparison, editSurveyComparisonStatus] = useMutation(editSurveyComparisonMutation, {
        onError: () => {
            setState({ ...state, snackbar: { isOpen: true, message: lang.unableToEditSurveyComparison } });
        }
    });

    const [editSurveyComparisonValue, editSurveyComparisonValueStatus] = useMutation(
        editSurveyComparisonValueMapMutation,
        {
            onCompleted: () => {
                const updateSurveyComparisons = state.surveyComparisons.filter(
                    comparison => comparison.targetSurveyId === state.targetSurveySelectedId
                )[0];
                const foundIndex = updateSurveyComparisons.valueMaps.findIndex(
                    (valueMap: ValueMap) =>
                        valueMap.sourceValue === state.updatedSurveyComparisonValue.sourceValue &&
                        state.updatedSurveyComparisonValue.sourceFieldId === valueMap.sourceFieldId
                );
                updateSurveyComparisons.valueMaps[foundIndex].targetFilters =
                    state.updatedSurveyComparisonValue.filterParameters.map((item: FilterParameter) => {
                        return {
                            id: item.field.id,
                            combinationOperator: item.combinationOperator,
                            filterType: item.filterType,
                            comparisonOperator: item.comparisonOperator,
                            target: item.target.map(t => t.id)
                        };
                    });
                const index = state.surveyComparisons.findIndex(x => x.targetSurveyId === state.targetSurveySelectedId);
                setState({
                    ...state,
                    surveyComparisons: [
                        ...state.surveyComparisons.slice(0, index),
                        Object.assign({}, state.surveyComparisons[index], updateSurveyComparisons),
                        ...state.surveyComparisons.slice(index + 1)
                    ],
                    updatedSurveyComparisonValue: initUpdatedSurveyComparisonValue
                });
            },
            onError: () => {
                setState({
                    ...state,
                    updatedSurveyComparisonValue: initUpdatedSurveyComparisonValue,
                    snackbar: { isOpen: true, message: lang.unableToMapValue }
                });
            }
        }
    );

    const handleEditSurveyItemMapping = (
        surveyComparisonId: number,
        sourceFieldId: number,
        targetFieldId?: number
    ): void => {
        const targetId = targetFieldId && targetFieldId > 0 ? targetFieldId : undefined;
        editSurveyItemMapping({
            variables: {
                surveyComparisonId,
                sourceFieldId,
                targetFieldId: targetId
            }
        });
    };

    const handleEditSurveyComparison = (
        surveyComparisonId: number,
        label: string,
        showInReports: boolean,
        showInFirstColumn: boolean,
        showInHeatmap: boolean
    ): void => {
        editSurveyComparison({
            variables: {
                surveyComparisonId,
                label,
                showInReports,
                showInFirstColumn,
                showInHeatmap
            }
        });
    };

    const handleDeleteSurveyComparison = (surveyComparisonId: number): void => {
        setLoading(true);
        deleteSurveyComparison({
            variables: {
                surveyComparisonId
            }
        });
    };

    const handleAutoMap = (targetSurveyId: number, label: string): void => {
        runAutoMap({
            variables: {
                sourceSurveyId: +routeParams.id,
                targetSurveyId,
                label
            }
        });
    };

    const handleEditSurveyComparisonField = (
        surveyComparisonId: number,
        sourceFieldId: number,
        targetFieldId: number
    ): void => {
        editSurveyComparisonField({
            variables: {
                surveyComparisonId,
                sourceFieldId,
                targetFieldId
            }
        });
    };

    const handleEditSurveyComparisonValue = (
        surveyComparisonId: number,
        sourceFieldId: number,
        sourceValue: string,
        filter: Filter
    ): void => {
        editSurveyComparisonValue({
            variables: {
                surveyComparisonId,
                sourceFieldId,
                sourceValue,
                filters: JSON.stringify(filterConnectorToBackEnd(filter.items))
            }
        });
        setState({
            ...state,
            updatedSurveyComparisonValue: { filterParameters: filter.items, sourceValue, sourceFieldId }
        });
    };

    const handleCloseSnackbar = (): void => {
        setState({ ...state, snackbar: { isOpen: false, message: "" } });
    };

    // fun item filters
    const funItemsFilter = (sections: SurveySection[]): SelectOptionExpandablePanel[] => {
        const r = sections
            .filter(s => {
                return s.questions.filter(q => q.isFun).length > 0;
            })
            .map((s: SurveySection): SelectOptionExpandablePanel => {
                const sOption: SelectOptionExpandablePanel = {
                    id: s.sectionId.substr(1),
                    title: s.title,
                    subNode: []
                };

                s.questions.forEach((q: SurveyQuestion) => {
                    if (q.isFun && q.questionType === QuestionType.PickOne) {
                        if (q.items.length > 0) {
                            q.items.forEach((i: SurveyItem) => {
                                const iOption: SelectOptionExpandablePanel = {
                                    id: i.fieldId.toString(),
                                    title: i.title,
                                    subNode: []
                                };
                                sOption.subNode?.push(iOption);
                            });
                        }
                    }
                });

                return sOption;
            });

        return r;
    };
    const handleTargetSurveyChange = (targetSurveyId: number): void => {
        const surveyFound = surveys.find((survey: SurveyInfo) => survey.id === targetSurveyId);
        if (surveyFound) {
            const selectedComparisonSurvey = state.surveyComparisons.filter(
                comparison => comparison.targetSurveyId === surveyFound?.id
            );
            setState({
                ...state,
                targetSurveySelectedId: selectedComparisonSurvey[0].targetSurveyId
            });
            runPreviousPeriodOnLoad();
        }
    };

    const isRenderReady = state.surveyComparisons[0].id !== initSurveyComparisons[0].id;
    const getSelectedSurveyComparisons = (): SurveyComparison => {
        return state.surveyComparisons.filter(
            comparison => comparison.targetSurveyId === state.targetSurveySelectedId
        )[0];
    };

    const handleConfirmationClicked = (): void => {
        if (state.selectedClearDimensionSectionId && getSelectedSurveyComparisons()) {
            const selectedSourceField = state.surveyItemsOptions.find(
                i => i.id == state.selectedClearDimensionSectionId
            );
            if (selectedSourceField) {
                const selectedSourceFieldIds = selectedSourceField.subNode?.map(s => +s.id);
                if (selectedSourceFieldIds) {
                    adminService
                        .clearDimensionSurveyComparison(getSelectedSurveyComparisons().id, selectedSourceFieldIds)
                        .then(data => {
                            if (data) {
                                const updateSurveyComparisons = state.surveyComparisons.filter(
                                    comparison => comparison.targetSurveyId === state.targetSurveySelectedId
                                )[0];

                                data.map(sourceFieldId => {
                                    const foundIndex = updateSurveyComparisons.fieldMaps.findIndex(
                                        (fieldMap: FieldMap) => fieldMap.sourceFieldId === sourceFieldId
                                    );
                                    if (foundIndex !== -1) {
                                        updateSurveyComparisons.fieldMaps[foundIndex].targetFieldId = null;
                                        const index = state.surveyComparisons.findIndex(
                                            x => x.targetSurveyId === state.targetSurveySelectedId
                                        );
                                        setState({
                                            ...state,
                                            surveyComparisons: [
                                                ...state.surveyComparisons.slice(0, index),
                                                Object.assign(
                                                    {},
                                                    state.surveyComparisons[index],
                                                    updateSurveyComparisons
                                                ),
                                                ...state.surveyComparisons.slice(index + 1)
                                            ]
                                        });
                                    }
                                });
                            }
                        })
                        .catch((): void => {
                            setState({ ...state, snackbar: { isOpen: true, message: lang.somethingWentWrong } });
                        })
                        .finally(() => {
                            setState({ ...state, confirmationDialog: false, selectedClearDimensionSectionId: "" });
                        });
                } else {
                    setState({ ...state, confirmationDialog: false, selectedClearDimensionSectionId: "" });
                }
            } else {
                setState({ ...state, confirmationDialog: false, selectedClearDimensionSectionId: "" });
            }
        } else {
            setState({ ...state, confirmationDialog: false, selectedClearDimensionSectionId: "" });
        }
    };

    const handleClearSectionMapping = (sectionId: string): void => {
        setState({
            ...state,
            selectedClearDimensionSectionId: sectionId,
            confirmationDialog: true
        });
    };

    useEffect(() => {
        runPreviousPeriodOnLoad();
        return (): void => {
            setState({
                ...state,
                targetContactFields: [],
                sourceContactFields: [],
                surveyItemsOptions: [],
                targetSurveyItemsOptions: [],
                surveyComparisons: initSurveyComparisons,
                updatedSurveyComparisonValue: initUpdatedSurveyComparisonValue,
                snackbar: initSnackbar,
                targetSurveySelectedId: -1
            });
        };
    }, []);

    useEffect(() => {
        const isLoading =
            loadingPreviousPeriodOnLoad ||
            automapStatus.loading ||
            editItemMappingStatus.loading ||
            editSurveyComparisonFieldStatus.loading ||
            deleteSurveyComparisonStatus.loading ||
            editSurveyComparisonStatus.loading ||
            editSurveyComparisonValueStatus.loading;
        setLoading(isLoading);
    }, [
        loadingPreviousPeriodOnLoad,
        loadingContactFieldsAndSurveyItems,
        automapStatus.loading,
        editItemMappingStatus.loading,
        editSurveyComparisonFieldStatus.loading,
        deleteSurveyComparisonStatus.loading,
        editSurveyComparisonStatus.loading,
        editSurveyComparisonValueStatus.loading
    ]);

    if (errorPreviousPeriodOnLoad || errorContactFieldsAndSurveyItems) return <Error500 />;
    if (loadingPreviousPeriodOnLoad || loadingContactFieldsAndSurveyItems) return <></>;

    if (
        state.surveyComparisons[0].id !== initSurveyComparisons[0].id &&
        state.targetContactFields.length === 0 &&
        state.sourceContactFields.length === 0 &&
        state.surveyItemsOptions.length === 0
    ) {
        return <Error500 />;
    }

    return (
        <>
            {isRenderReady ? (
                <PreviousPeriodMapped
                    sourceContactFields={state.sourceContactFields}
                    targetContactFields={state.targetContactFields}
                    surveyComparisons={getSelectedSurveyComparisons()}
                    allSurveyComparison={state.surveyComparisons}
                    selectedSurveyId={+routeParams.id}
                    editSurveyComparisonField={handleEditSurveyComparisonField}
                    editSurveyComparison={handleEditSurveyComparison}
                    editSurveyComparisonValue={handleEditSurveyComparisonValue}
                    deleteSurveyComparison={handleDeleteSurveyComparison}
                    surveyItemsOptions={state.surveyItemsOptions}
                    targetSurveyItemsOptions={state.targetSurveyItemsOptions}
                    editSurveyItemMapping={handleEditSurveyItemMapping}
                    targetSurveySelectedId={state.targetSurveySelectedId}
                    handleTargetSurveyChange={handleTargetSurveyChange}
                    onAutoMap={handleAutoMap}
                    clearSectionMapping={handleClearSectionMapping}
                />
            ) : (
                <PreviousPeriodNotMapped onAutoMap={handleAutoMap} selectedSurveyId={+routeParams.id} />
            )}
            <ConfirmationDialog
                open={state.confirmationDialog}
                onCancelClicked={(): void =>
                    setState({ ...state, confirmationDialog: false, selectedClearDimensionSectionId: "" })
                }
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.clearDimensionMappings}
                message={lang.thisActionWillUnmapItemsMapping}
                confirmButtonText={lang.ok}
                cancelButtonVariant="text"
            />
            {state.snackbar.isOpen && (
                <Snackbar
                    open={state.snackbar.isOpen}
                    message={state.snackbar.message}
                    handleClose={handleCloseSnackbar}
                />
            )}
        </>
    );
};

export default PreviousPeriod;

import React from "react";

import MuiRadio, { RadioProps } from "@mui/material/Radio";
import MuiRadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";

const Radio = (props: RadioProps) => {
    return <MuiRadio {...props} />;
};

const RadioGroup = (props: RadioGroupProps) => {
    const { children, ...rest } = props;
    return <MuiRadioGroup {...rest}>{children}</MuiRadioGroup>;
};

export { Radio, RadioGroup };

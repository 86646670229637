import { SurveySection } from "managerPortal/context/interfaces";
import { initHeatmapDisplayValue } from "../heatmap.init";
import { HeatmapDisplayValue, HeatmapValue } from "../interface";

export const heatmapValueMapper = (
    hmValue: HeatmapValue[],
    surveySections: SurveySection[],
    showDeltaValue: boolean
): HeatmapDisplayValue[] => {
    const HMDisplayValue: HeatmapDisplayValue[] = [];

    if (!hmValue || surveySections.length === 0) return [initHeatmapDisplayValue];
    hmValue.forEach(v => {
        const sectionFound = surveySections.find(s => v.targetId === +s.sectionId.slice(1));

        let itemText: string | undefined = undefined;
        let itemId: number | undefined = undefined;
        if (sectionFound && v.subTargetId) {
            sectionFound.questions.forEach(q => {
                q.items.forEach(i => {
                    if (+i.itemId.slice(1) === v.subTargetId) {
                        itemText = i.title;
                        itemId = +i.itemId.slice(1);
                    }
                });
            });

            if (itemText) {
                HMDisplayValue.push({
                    source: v.source,
                    subTarget: itemText,
                    target: sectionFound.title,
                    targetId: +sectionFound.sectionId.slice(1),
                    subTargetId: itemId,
                    showDelta: showDeltaValue,
                    delta: v.delta,
                    favorable: v.favorable
                });
            }
        }
    });

    return HMDisplayValue;
};

import React, { ReactElement, useState, useEffect } from "react";
import {
    useReactTable,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    SortingState,
    PaginationState
} from "@tanstack/react-table";
import { makeStyles } from "@mui/styles";

import {
    Field,
    ActivityLogInfo,
    ActivityLogInfoDetails,
    AdminParticiantStatusErrorType
} from "components/admin/participants/interfaces";
import AdminParticipantsActivityLog from "./AdminParticipantsActivityLog";
import { useLang, useAdminService } from "core/hooks";
import SearchBar from "managerPortal/components/shared/SearchBar";
import EditableCell from "./AdminParticipantsEditCell";
import TableCell from "./AdminParticipantsTableCell";
import { TabOptions } from "./AdminParticipantsContacts";
import { Tabs, Tab } from "lib/tabs";
import { dateFormatWithTime } from "core/constants";
import { Snackbar } from "lib/snackbar";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        height: "100%",
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden"
    },
    table: {
        borderCollapse: "collapse",
        tableLayout: "fixed"
    },
    th: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "left",
        border: theme.border.main,
        fontWeight: 500,
        width: "10%",
        minWidth: "10%",
        maxWidth: "15%"
    },
    th1: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "left",
        border: theme.border.main,
        fontWeight: 500,
        width: "0%",
        maxWidth: "0%"
    },
    th2: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "left",
        border: theme.border.main,
        fontWeight: 500,
        width: "20%",
        minWidth: "20%",
        maxWidth: "100%"
    },
    td: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        border: theme.border.main,
        textAlign: "left",
        paddingLeft: 11,
        paddingRight: 11,
        height: 38,
        minHeight: 38,
        maxHeight: 38,
        width: 300,
        minWidth: 180
    },
    td1: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        border: theme.border.main,
        textAlign: "left",
        paddingLeft: 20,
        paddingRight: 20,
        height: 38,
        minHeight: 38,
        maxHeight: 38,
        width: 60,
        maxWidth: 60
    },
    td2: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        border: theme.border.main,
        textAlign: "left",
        paddingLeft: 11,
        paddingRight: 11,
        height: 38,
        minHeight: 38,
        maxHeight: 38,
        width: 340,
        minWidth: 340
    },
    sortHeader: {
        cursor: "pointer"
    },
    pagination: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: 56,
        minHeight: 56,
        position: "fixed",
        right: 24,
        bottom: 24
    },
    paginationButton: {
        paddingLeft: 8,
        paddingRight: 8,
        border: "none",
        background: "none",
        fontWeight: 500
    },
    paginationNumber: {
        display: "flex",
        alignItems: "center",
        marginRight: 16,
        color: theme.palette.primary.main
    },
    header: {
        display: "flex",
        height: 58,
        minHeight: 58,
        alignItems: "flex-end",
        justifyContent: "space-between",
        position: "relative"
    },
    tabs: {
        display: "flex",
        position: "fixed",
        top: 126
    },
    searchBar: {
        position: "fixed",
        top: 122,
        right: 24,
        width: 400
    }
}));

type Props = {
    demographicFields: Field[];
    participants: string[][];
    tabValue: TabOptions;
    setTabValue: (e: React.ChangeEvent<unknown>, value: TabOptions) => void;
    surveyId: number;
    isPreviousPeriodSurvey: boolean;
};

const AdminParticipantsContacts = (props: Props): ReactElement => {
    const adminService = useAdminService();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnHelper = createColumnHelper<any>();
    const defaultData = props.participants.map(p => {
        const obj = {};
        props.demographicFields.forEach((field, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj[field.name] = p[index];
        });
        return obj;
    });

    const defaultColumn = {
        cell: TableCell
    };

    const regularColumns = props.demographicFields.map(field => {
        if (field.name === "RespondentId") {
            return columnHelper.accessor(field.name, {
                id: field.name,
                header: field.name,
                meta: {
                    type: "text"
                },
                enableHiding: true
            });
        } else {
            return columnHelper.accessor(field.name, {
                id: field.name,
                header: field.name,
                meta: {
                    type: "text"
                }
            });
        }
    });

    const columns = [
        columnHelper.display({
            id: "edit",
            cell: EditableCell
        }),
        ...regularColumns
    ];

    const classes = useStyles();
    const { lang } = useLang();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15
    });
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [data, setData] = useState(() => [...defaultData]);
    const [activityLog, setActivityLog] = useState<ActivityLogInfo[]>([]);
    const [activityLogFilter, setActivityLogFilter] = React.useState("");
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });

    const table = useReactTable({
        data,
        columns,
        defaultColumn,
        enableSortingRemoval: false,
        state: { sorting, pagination, globalFilter },
        initialState: {
            columnVisibility: { RespondentId: false }
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        autoResetPageIndex: false,
        meta: {
            updateData: (rowIndex: number, columnId: string, value: string) => {
                if (props.isPreviousPeriodSurvey) {
                    setSnackbar({ isOpen: true, message: lang.editParticipantsWarning });
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const requestData: any[] = [];
                    Object.entries(data[rowIndex]).forEach(([key, cellValue]) => {
                        const singleCellData = [];
                        if (key === columnId) {
                            singleCellData.push(key);
                            singleCellData.push(value);
                        } else {
                            singleCellData.push(key);
                            singleCellData.push(cellValue);
                        }
                        requestData.push(singleCellData);
                    });
                    adminService.editAdminParticipants(requestData).then(response => {
                        if (response === AdminParticiantStatusErrorType.OK) {
                            fetchAdminActivityLog();
                            setData(old =>
                                old.map((row, index) => {
                                    if (index === rowIndex) {
                                        return {
                                            ...old[rowIndex],
                                            [columnId]: value
                                        };
                                    }
                                    return row;
                                })
                            );
                        } else if (response === AdminParticiantStatusErrorType.DuplicateEmail) {
                            setSnackbar({ isOpen: true, message: lang.duplicateEmailField });
                        } else if (response === AdminParticiantStatusErrorType.InvalidEmail) {
                            setSnackbar({ isOpen: true, message: lang.invalidEmails });
                        } else if (response === AdminParticiantStatusErrorType.MissingEmailValue) {
                            setSnackbar({ isOpen: true, message: lang.missingEmailField });
                        }
                    });
                }
            },
            deleteRow: (rowIndex: number) => {
                if (props.isPreviousPeriodSurvey) {
                    setSnackbar({ isOpen: true, message: lang.editParticipantsWarning });
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const requestData = data[rowIndex] as any;
                    const formatedRequestData = parseInt(requestData["RespondentId"]);
                    adminService.deleteAdminParticipant(formatedRequestData).then(() => {
                        fetchAdminActivityLog();
                        setData(old => {
                            return old.filter((_, index) => index !== rowIndex);
                        });
                    });
                }
            }
        }
    });

    const searchActivityLogFilter = (search: string): void => {
        setActivityLogFilter(search);
    };

    const fetchAdminActivityLog = (): void => {
        adminService.getAdminParticipantActivityLog(props.surveyId).then(data => {
            const filtered = data.logs
                .filter(
                    log =>
                        log.user.trim().toLowerCase().includes(activityLogFilter.trim().toLowerCase()) ||
                        dayjs(log.date).format(dateFormatWithTime).includes(activityLogFilter.trim().toLowerCase()) ||
                        JSON.parse(log.records)
                            .map((r: ActivityLogInfoDetails) => r.Details)
                            .join("")
                            .toLowerCase()
                            .includes(activityLogFilter.trim().toLowerCase())
                )
                .sort((a, b) => (dayjs(b.date).isBefore(dayjs(a.date)) ? -1 : 1));
            setActivityLog(filtered);
        });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    useEffect((): void => {
        fetchAdminActivityLog();
    }, [activityLogFilter]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.tabs}>
                    <Tabs
                        value={props.tabValue}
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="tabs"
                        onChange={props.setTabValue}
                    >
                        <Tab label={`${lang.participants} (${data.length})`} data-testid="tab-handler-participants" />
                        <Tab label={lang.activityLog} data-testid="tab-handler-activity" />
                    </Tabs>
                </div>
                <div className={classes.searchBar} data-testid="searchBar">
                    {props.tabValue === TabOptions.participants && (
                        <SearchBar
                            onDelayChange={(e: string): void => {
                                setGlobalFilter(e);
                                table.resetPageIndex();
                            }}
                            labelText={lang.search}
                            delay={500}
                            searchTerm={globalFilter ?? ""}
                            size={"small"}
                        />
                    )}
                    {props.tabValue === TabOptions.activity && (
                        <SearchBar
                            onDelayChange={(e: string): void => {
                                searchActivityLogFilter(e);
                            }}
                            labelText={lang.search}
                            delay={500}
                            searchTerm={activityLogFilter ?? ""}
                            size={"small"}
                        />
                    )}
                </div>
            </div>
            {props.tabValue === TabOptions.participants && (
                <>
                    <table className={classes.table}>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header, i) => (
                                        <th
                                            key={header.id}
                                            className={i == 0 ? classes.th1 : i == 1 ? classes.th2 : classes.th}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    className={header.column.getCanSort() ? classes.sortHeader : ""}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {header.column.getIsSorted()
                                                        ? header.column.getIsSorted() === "asc"
                                                            ? "  ↓"
                                                            : "  ↑"
                                                        : ""}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row, index) => (
                                <tr key={row.id} data-testid={`participant-table-row-${index}`}>
                                    {row.getVisibleCells().map((cell, i) => (
                                        <td
                                            key={cell.id}
                                            className={i == 0 ? classes.td1 : i == 1 ? classes.td2 : classes.td}
                                            data-testid={`participant-table-row-${index}-column-${cell.column.columnDef.id}`}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={classes.pagination}>
                        <Typography className={classes.paginationNumber} variant="body2">
                            {`${lang.page} ${table.getState().pagination.pageIndex + 1} ${
                                lang.of
                            } ${table.getPageCount()}`}
                        </Typography>
                        <button
                            className={classes.paginationButton}
                            onClick={(): void => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                            data-testid={"first"}
                            autoFocus={false}
                        >
                            {"|<"}
                        </button>
                        <button
                            className={classes.paginationButton}
                            onClick={(): void => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            data-testid={"previous"}
                            autoFocus={false}
                        >
                            {"<"}
                        </button>
                        <button
                            className={classes.paginationButton}
                            onClick={(): void => {
                                table.nextPage();
                            }}
                            disabled={!table.getCanNextPage()}
                            data-testid={"next"}
                            autoFocus={false}
                        >
                            {">"}
                        </button>
                        <button
                            className={classes.paginationButton}
                            onClick={(): void => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                            data-testid={"last"}
                            autoFocus={false}
                        >
                            {">|"}
                        </button>
                    </div>
                </>
            )}
            {props.tabValue === TabOptions.activity && (
                <AdminParticipantsActivityLog activityLog={activityLog} searchTerm={activityLogFilter} />
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
        </div>
    );
};

export default AdminParticipantsContacts;

import React, { cloneElement, ReactElement } from "react";

import { Tooltip, TooltipProps } from "lib/tooltip";

interface ITruncateDisplayProps extends TooltipProps {
    maxLabelLength: number;
    alwaysShowTooltip?: boolean;
}

const TruncateDisplay = (props: ITruncateDisplayProps): ReactElement => {
    const { title, maxLabelLength, children, placement } = props;
    const length = typeof title === "string" ? title.length : 0;
    const updateTitle = typeof title === "string" ? title : "";

    const ellipsis = "...";

    const newDisplay = length > maxLabelLength ? updateTitle.substring(0, maxLabelLength).trim() + ellipsis : title;

    const childrenCloned = cloneElement(children, { primary: newDisplay, children: newDisplay });

    return length > maxLabelLength || props.alwaysShowTooltip ? (
        <Tooltip title={updateTitle} placement={placement}>
            {childrenCloned}
        </Tooltip>
    ) : (
        children
    );
};

export default TruncateDisplay;

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { Chip } from "lib/chip";
import { useLang } from "core/hooks";
import { FilterParameter } from "components/filters/interfaces";
import { FilterType } from "components/filters/enums";
import { theme } from "lib/theme";

type Props = {
    items: FilterParameter[];
    withoutFilterTitle?: boolean;
    onDelete?: (order: number) => void;
};

const useStyles = makeStyles(() => ({
    filterTag: {
        display: "flex",
        overflow: "hidden"
    },
    filterTagTitle: {
        paddingRight: theme.spacing(1),
        display: "flex",
        alignItems: "center"
    },
    filterTagGroup: {
        display: "flex",
        alignItems: "center"
    },
    filterTagcombinationOperator: {
        marginLeft: 2,
        marginRight: 2
    }
}));

const FilterChip = ({ items, withoutFilterTitle, onDelete }: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    const makeBaseLabel = (item: FilterParameter): string =>
        item.filterType === FilterType.surveyItemPickOne ||
        item.filterType === FilterType.surveyItemPickMany ||
        item.filterType === FilterType.contactField
            ? `${item.field.dimensionName ? `(${item.field.dimensionName})` : ""}`
            : "";

    const makeComparisonLabel = (item: FilterParameter): string =>
        item.filterType == FilterType.date
            ? lang.between
            : item.comparisonOperator === "EQUALS"
              ? "="
              : item.comparisonOperator === "NOTEQUALS"
                ? "≠"
                : "";
    const makeTargetValueList = (item: FilterParameter): string =>
        item.target.map(t => t.text).join("") // show .text if the target has it
            ? item.target.map(t => t.text).join(", ")
            : item.target.map(t => t.id).join("") // show.id if the target has it
              ? item.target.map(t => t.id).join(", ")
              : "";

    const makeChipLabel = (item: FilterParameter): string => {
        return `${makeBaseLabel(item)} ${item.field.fieldName} ${makeComparisonLabel(item)} ${makeTargetValueList(
            item
        )}`;
    };

    return (
        <div className={classes.filterTag} data-testid={"filterTag"}>
            {!withoutFilterTitle && (
                <div className={classes.filterTagTitle}>
                    <Typography variant="body1"> {`${lang.filter}:`} </Typography>
                </div>
            )}
            {items.map(item => (
                <div className={classes.filterTagGroup} key={item.order} data-testid={`${item.field.fieldName}`}>
                    {item.order > 0 && (
                        <div className={classes.filterTagcombinationOperator}>
                            <Typography variant="body2">
                                {item.combinationOperator === "AND" ? `${lang.and}` : `${lang.or}`}
                            </Typography>
                        </div>
                    )}
                    <Chip
                        style={{ maxWidth: "1000px" }}
                        label={makeChipLabel(item)}
                        onDelete={onDelete ? (): void => onDelete(item.order) : undefined}
                    />
                </div>
            ))}
        </div>
    );
};

export default FilterChip;

import React, { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDrop, DropTargetMonitor } from "react-dnd";

import { theme } from "lib/theme";
import { ColumnType, DragItemType } from "./enums";
import { Typography } from "lib/typography";
import { MappedCardContent } from "./interfaces";
import { useLang } from "../../../../core/hooks";

interface DragItem {
    index: number;
    id: string;
    type: string;
}

type StyleProps = {
    emptyList: boolean;
    isOver: boolean;
};

type Props = {
    columnType: ColumnType;
    onDrop: (item: DragItem, columnType: ColumnType) => void;
    emptyList?: boolean;
    list: MappedCardContent[];
    dataTestId?: string;
};

const useStyles = makeStyles(() => ({
    cardLayoutLists: (styleProps: StyleProps) => ({
        display: "flex",
        background: styleProps.isOver ? theme.palette.action.disabledBackground : "white",
        width: "100%",
        boxSizing: "border-box",
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16
    }),
    emptyList: {
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: 2,
        height: 56,
        border: theme.border.greyDashed,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 16
    },
    mainList: {
        width: 320,
        maxHeight: "100%",
        overflowY: "auto",
        border: theme.border.main
    },
    graphBarList: {
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column"
    },
    numberCircleList: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: 8
    }
}));

const Column = (props: PropsWithChildren<Props>): ReactElement => {
    const { lang } = useLang();
    const [{ isOver }, drop] = useDrop({
        accept: DragItemType,
        drop: (item: DragItem) => {
            item.index = 0;
            if (props.list.length === 0) {
                props.onDrop(item, props.columnType);
            }
        },
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver()
        })
    });
    const classes = useStyles({
        emptyList: props.emptyList ? props.emptyList : false,
        isOver
    });

    return (
        <div ref={drop} data-testid={props.dataTestId}>
            {props.columnType === ColumnType.graphBar ? (
                <div className={clsx(classes.cardLayoutLists, classes.graphBarList)}>
                    {props.emptyList ? (
                        <div className={classes.emptyList}>
                            <Typography variant="body2" align="center">
                                {lang.dragChartHere}
                            </Typography>
                        </div>
                    ) : (
                        props.children
                    )}
                </div>
            ) : props.columnType === ColumnType.numberCircle ? (
                <div className={clsx(classes.cardLayoutLists, classes.numberCircleList)}>
                    {props.emptyList ? (
                        <div className={classes.emptyList}>
                            <Typography variant="body2" align="center">
                                {lang.dragResponseRateHere}
                            </Typography>
                        </div>
                    ) : (
                        props.children
                    )}
                </div>
            ) : (
                <div className={classes.mainList}>{props.children}</div>
            )}
        </div>
    );
};

export default Column;

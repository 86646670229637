import React, { ReactElement } from "react";
import { LegendIcon } from "../shared/ColorLegend";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";
import { useLang } from "core/hooks";

const useStyle = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxSizing: "border-box"
    },
    legendItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "8px"
    }
}));

enum colorLegends {
    unfavorable = "unfavorable",
    neutral = "neutral",
    favorable = "favorable"
}

export const SnapshotENPSLegend = (): ReactElement => {
    const { lang } = useLang();
    const classes = useStyle();
    const legends = Object.values(colorLegends);
    const colorLabel = [lang.detractors, lang.neutral, lang.promoters];
    const mapColorLegendToLabel = (i: number): string => {
        return colorLabel[i];
    };

    return (
        <div className={classes.container}>
            {legends.map((l, index) => (
                <div className={classes.legendItem} key={l}>
                    <LegendIcon variance={l} />
                    <Typography variant="body2" style={{ fontSize: 14, marginTop: "2px" }}>
                        {mapColorLegendToLabel(index)}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

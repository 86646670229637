import { Action } from "core/interfaces";
import { DashboardState } from "../interfaces";
import { DashboardActions } from "../actions";

export const DashboardReducer = (state: DashboardState, action: Action): DashboardState => {
    const { type, payload } = action;
    switch (type) {
        case DashboardActions.SET_SELECTED_PANEL_ID:
            return {
                ...state,
                selectedPanelId: payload.selectedPanelId
            };
        case DashboardActions.SET_DASHBOARD_TITLE:
            return {
                ...state,
                dashboardTitle: payload.dashboardTitle
            };
        case DashboardActions.SET_PANEL_OPEN_STATUS:
            return {
                ...state,
                panelDialogOpen: payload.status
            };
        case DashboardActions.SET_COMPONENT_STATUS:
            return {
                ...state,
                componentStatus: payload.componentStatus
            };
        case DashboardActions.SET_PANEL_ITEMS:
            return {
                ...state,
                panelItems: payload.panelItems
            };
        case DashboardActions.SET_CONFIRMATION_STATUS:
            return {
                ...state,
                confirmationDialog: payload.status
            };
        case DashboardActions.SET_SELECTED_CELL_TYPE:
            return {
                ...state,
                selectedCellType: payload.cellType
            };
        case DashboardActions.SET_PANEL_BOXES:
            return {
                ...state,
                boxes: payload.boxes
            };
        case DashboardActions.SET_DASHBOARD_PANEL:
            return {
                ...state,
                ...payload
            };
        case DashboardActions.SET_CLEAR_ALL:
            return {
                ...state,
                selectedPanelId: payload.selectedPanelId,
                selectedCellType: payload.selectedCellType,
                panelDialogOpen: payload.panelDialogOpen,
                panelItems: payload.panelItems,
                boxes: payload.boxes,
                componentStatus: payload.componentStatus,
                confirmationDialog: payload.confirmationDialog,
                hasPreviousPeriod: payload.hasPreviousPeriod,
                hasBenchmark: payload.hasBenchmark
            };
        default:
            return {
                ...state
            };
    }
};

import { useState, useEffect } from "react";

export const useWindowResize = (): { height: number; width: number } => {
    const [size, setSize] = useState<{ height: number; width: number }>({ height: 0, width: 0 });

    const handleSizeChange = (): void => {
        setSize({ height: window.innerHeight, width: window.innerWidth });
    };
    useEffect(() => {
        handleSizeChange();
        window.addEventListener("resize", handleSizeChange);

        return (): void => {
            window.removeEventListener("resize", handleSizeChange);
        };
    }, []);

    return size;
};

import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { DatePicker as MuiDatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-ca";

const DatePicker = (props: DatePickerProps<Dayjs>) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-ca">
            <MuiDatePicker
                {...props}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                slotProps={{ textField: { "data-testid": props["data-testid"] } }}
            />
        </LocalizationProvider>
    );
};

export { DatePicker };

import React from "react";
import { makeStyles } from "@mui/styles";

import { backgroundLightGrey } from "../../core/styles/BackgroundPalette";
import { SidebarHandleStateItem } from "./interfaces";
import { useLang } from "../../core/hooks";
import { Translation } from "core/languages/interfaces";
import { theme } from "lib/theme";
import { MenuItem } from "lib/menu-item";

type Props = {
    itemList: SidebarHandleStateItem[];
    onClick: (a: string) => void;
    itemSelected?: string;
};

const useStyles = makeStyles(() => ({
    sidebar: {
        width: 224,
        minWidth: 224,
        height: "100%",
        borderRight: theme.border.main,
        background: backgroundLightGrey,
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
    }
}));

const SidebarHandleState = (props: Props): React.ReactElement => {
    const classes = useStyles(props);
    const { lang } = useLang();

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
        props.onClick(event.currentTarget.getAttribute("aria-label") ?? "");
    };

    const isSelected = (title: string): boolean => {
        if (props.itemSelected) {
            const value = lang[props.itemSelected as keyof Translation] as string;
            return title.toLowerCase() === value.toLowerCase();
        }
        return false;
    };

    return (
        <div className={classes.sidebar}>
            {props.itemList.map((item: SidebarHandleStateItem) => (
                <MenuItem
                    style={
                        isSelected(item.title)
                            ? {
                                  display: "flex",
                                  background: theme.palette.action.selected,
                                  height: "56px",
                                  alignItems: "center"
                              }
                            : { display: "flex", height: "56px", alignItems: "center" }
                    }
                    aria-label={item.id}
                    key={item.title}
                    title={item.title}
                    onClick={handleClick}
                    disabled={item.disabled ? item.disabled : false}
                    data-testid={`sidebar-${item.title}`}
                >
                    {item.title}
                </MenuItem>
            ))}
        </div>
    );
};

export default SidebarHandleState;

import React, { useState, useEffect, ReactElement } from "react";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";

import {
    useLoading,
    useLang,
    useExistingHierarchies,
    useAvailableCardContent,
    useVisibleNodes,
    useSelectedHierarchy,
    useSelectSurvey,
    useManagerPortalContext,
    useOrgChartService
} from "core/hooks";
import { emptyVisibleNodes } from "../context/initialState";
import CreateHierarchyDialog from "./hierarchy/CreateHierarchyDialog";
import InformationDialog from "./shared/InformationDialog";
import RenameHierarchy from "./hierarchy/RenameHierarchy";
import OrgChartDrawer from "./drawer/OrgChartDrawer";
import ConfigureCard from "./cards/cardLayout/ConfigureCard";
import { Hierarchy } from "../components/hierarchy/interfaces";
import { VisibleNodes, ManagerPortalState } from "../context/interfaces";
import ColorLegend from "./shared/ColorLegend";
import Header from "./header/Header";
import { backgroundLightGrey } from "core/styles/BackgroundPalette";
import Loading from "components/shared/Loading";
import OrgChart from "./OrgChart";
import { getSelectedHierarchy } from "core/helpers";
import { emptyHierarchy } from "./hierarchy/initialVariables";
import EmptyPage from "components/shared/EmptyPage";
import ReportingEmptyState from "../../assets/images/emptyPages/ReportingEmptyState.svg";
import { Comment } from "./comments/interfaces";
import { emptySurveyInfo } from "components/admin/results/init";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    managerPortalContainer: {
        width: "100%",
        height: "100% !important",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        background: backgroundLightGrey
    },
    content: {
        width: "100%",
        height: "calc(100% - 72px)"
    },
    actions: {
        top: 72,
        position: "absolute",
        width: "100%"
    }
}));

const styledFabButton = {
    color: "#FFFFFF",
    zIndex: 8,
    marginLeft: 2,
    marginTop: 2
};

type State = {
    isConfigureCardOpen: boolean;
    isDrawerOpen: boolean;
    isCreateHierarchyOpen: boolean;
    renameHierarchySelected: Hierarchy | null;
    informationDialogSetDefaultLayout: boolean;
    setDefaultLayoutError: string;
    setColorLegendOpen: { legendOpen: boolean };
    comments: Comment[];
};

const OrgChartPage = (): ReactElement => {
    const orgChartService = useOrgChartService();
    const classes = useStyles();
    const { setLoading } = useLoading();
    const { lang } = useLang();
    const { existingHierarchies } = useExistingHierarchies();
    const { availableCardContent } = useAvailableCardContent();
    const { visibleNodes } = useVisibleNodes();
    const { selectedHierarchy } = useSelectedHierarchy();
    const { managerPortalState, managerPortalUpdateState } = useManagerPortalContext();
    const { selectedSurvey } = useSelectSurvey();
    const { user } = useUser();

    const initialState: State = {
        isConfigureCardOpen: false,
        isDrawerOpen: false,
        isCreateHierarchyOpen:
            (!existingHierarchies || existingHierarchies.length === 0) &&
            user.settings.snapshot.id !== emptySurveyInfo.id,
        renameHierarchySelected: null,
        informationDialogSetDefaultLayout: false,
        setDefaultLayoutError: "",
        setColorLegendOpen: { legendOpen: false },
        comments: []
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        return (): void => {
            setState(initialState);
        };
    }, []);

    useEffect(() => {
        setState((prevState: State): State => {
            return {
                ...prevState,
                isCreateHierarchyOpen:
                    (!existingHierarchies || existingHierarchies.length === 0) &&
                    user.settings.snapshot.id !== emptySurveyInfo.id,
                setColorLegendOpen:
                    user.settings.snapshot.id !== emptySurveyInfo.id ? { legendOpen: true } : { legendOpen: false }
            };
        });

        return (): void => {
            setState((prevState: State): State => {
                return {
                    ...prevState,
                    setColorLegendOpen: { legendOpen: false }
                };
            });
        };
    }, [existingHierarchies, availableCardContent]);

    const onSelectHierarchy = (id: number): void => {
        if (id !== selectedHierarchy.HierarchyId) {
            setLoading(true);
            Promise.all([
                orgChartService.readHierarchyData(selectedSurvey.id, id, 1, user.settings.languageCode),
                orgChartService.getAvailableCardContent(selectedSurvey.id, user.settings.languageCode)
            ])
                .then(res => {
                    setLoading(false);
                    const hierarchyResponseData: Partial<ManagerPortalState> = {
                        visibleNodes: res[0],
                        availableCardContent: res[1].availableCardContent,
                        selectedHierarchy: getSelectedHierarchy(res[0].id, existingHierarchies),
                        errorLoadingHierarchy: false
                    };
                    setLoading(false);
                    managerPortalUpdateState(hierarchyResponseData);
                })
                .catch(() => {
                    setLoading(false);
                    managerPortalUpdateState({
                        errorLoadingHierarchy: true,
                        visibleNodes: emptyVisibleNodes,
                        selectedHierarchy: emptyHierarchy
                    });
                });
        }
    };

    const onSetDefaultHierarchy = (id: number): void => {
        orgChartService.setDefaultHierarchy(selectedSurvey.id, id);

        let hierarchies = Array.from(existingHierarchies);
        hierarchies = hierarchies.map(h => {
            h.IsDefault = h.HierarchyId === id;
            return h;
        });
        managerPortalUpdateState({ existingHierarchies: hierarchies, errorLoadingHierarchy: false });
        onSelectHierarchy(id);
    };

    const handleCreateHierarchy = (name: string, hierarchyIds: number[]): void => {
        setLoading(true);
        let _existingHierarchies: Hierarchy[];
        orgChartService
            .createHierarchy(name, selectedSurvey.id, hierarchyIds, user.settings.languageCode)
            .then(({ allHierarchies, hierarchyId }) => {
                _existingHierarchies = allHierarchies;
                return orgChartService.readHierarchyData(selectedSurvey.id, hierarchyId, 1, user.settings.languageCode);
            })
            .then((responseData: VisibleNodes) => {
                managerPortalUpdateState({
                    visibleNodes: responseData,
                    selectedHierarchy: getSelectedHierarchy(responseData.id, _existingHierarchies),
                    existingHierarchies: _existingHierarchies,
                    rootCardId: 1,
                    errorLoadingHierarchy: false
                });
                setState({
                    ...state,
                    isCreateHierarchyOpen: false,
                    isConfigureCardOpen: true
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                managerPortalUpdateState({
                    errorLoadingHierarchy: true,
                    visibleNodes: emptyVisibleNodes,
                    selectedHierarchy: emptyHierarchy
                });
            });
    };

    const onDeleteHierarchy = (hierarchyId: number): void => {
        orgChartService.deleteHierarchy(selectedSurvey.id, hierarchyId);

        const hierarchies = Array.from(existingHierarchies);
        hierarchies.splice(
            hierarchies.findIndex(e => e.HierarchyId === hierarchyId),
            1
        );
        setState({
            ...state,
            isDrawerOpen: false,
            isCreateHierarchyOpen: hierarchies.length === 0
        });

        const updatedState: Partial<ManagerPortalState> = {
            visibleNodes: emptyVisibleNodes,
            selectedHierarchy: emptyHierarchy,
            existingHierarchies: hierarchies
        };
        managerPortalUpdateState(updatedState);

        if (hierarchies.length > 0) {
            const defaultHierarchy = hierarchies.find(h => h.IsDefault);
            let selectHierarchy = hierarchies[0].HierarchyId;
            if (defaultHierarchy && defaultHierarchy.HierarchyId !== null) {
                selectHierarchy = defaultHierarchy.HierarchyId;
            }
            onSelectHierarchy(selectHierarchy);
        }
    };

    const onRenameHierarchy = (hierarchyId: number): void => {
        const hierarchy = existingHierarchies.find(_hierarchy => _hierarchy.HierarchyId === hierarchyId);
        setState({ ...state, renameHierarchySelected: hierarchy ? hierarchy : null });
    };

    const handleRenameHierarchy = (hierarchyUpdate: Hierarchy): void => {
        const existingHierarchiesUpdate = [...existingHierarchies];
        const index = existingHierarchiesUpdate.findIndex(h => h.HierarchyId === hierarchyUpdate.HierarchyId);

        if (existingHierarchies[index].Name !== hierarchyUpdate.Name) {
            orgChartService.renameHierarchy(selectedSurvey.id, hierarchyUpdate.HierarchyId, hierarchyUpdate.Name);

            existingHierarchiesUpdate[index] = hierarchyUpdate;
            const hierarchyResponseData: Partial<ManagerPortalState> = {
                existingHierarchies: existingHierarchiesUpdate,
                visibleNodes: {
                    ...visibleNodes,
                    tree: { ...visibleNodes.tree, title: hierarchyUpdate.Name }
                }
            };

            managerPortalUpdateState(hierarchyResponseData);
        }

        setState({ ...state, renameHierarchySelected: null });
    };

    const handleCancelRenameHierarchy = (): void => {
        setState({ ...state, renameHierarchySelected: null });
    };

    const handleOpenDrawer = (): void => {
        setState({ ...state, isDrawerOpen: true });
    };

    const handleOpenCreateHierarchyDialog = (): void => {
        managerPortalUpdateState({ errorLoadingHierarchy: false });
        setState({ ...state, isDrawerOpen: false, isCreateHierarchyOpen: true });
    };

    const handleOpenEditLayoutDialog = (): void => {
        managerPortalUpdateState({ errorLoadingHierarchy: false });
        setState({ ...state, isDrawerOpen: false, isConfigureCardOpen: true });
    };

    if (managerPortalState.isLoading) {
        return <Loading />;
    }

    return (
        <div className={classes.managerPortalContainer} id="orgChartPage">
            <Header />
            <div className={classes.content}>
                <div className={classes.actions}>
                    <Fab
                        data-testid="orgChartDrawer"
                        size="medium"
                        disableRipple={true}
                        onClick={handleOpenDrawer}
                        color="primary"
                        sx={styledFabButton}
                    >
                        <MenuIcon />
                    </Fab>
                    <ColorLegend shouldOpen={state.setColorLegendOpen} />
                </div>

                {visibleNodes.tree !== emptyVisibleNodes.tree && !managerPortalState.errorLoadingHierarchy && (
                    <OrgChart
                        id="orgChart"
                        layout={selectedHierarchy.Layout}
                        visibleNodes={visibleNodes}
                        threshold={selectedSurvey.confidentialityThreshold}
                    />
                )}

                {state.isDrawerOpen && (
                    <OrgChartDrawer
                        isOpen={state.isDrawerOpen}
                        onClose={(): void => setState({ ...state, isDrawerOpen: false })}
                        onCreateHierarchyClick={handleOpenCreateHierarchyDialog}
                        onSelectHierarchy={onSelectHierarchy}
                        onSetDefaultHierarchy={onSetDefaultHierarchy}
                        onDeleteHierarchy={onDeleteHierarchy}
                        onRenameHierarchy={onRenameHierarchy}
                        onEditLayoutClick={handleOpenEditLayoutDialog}
                        user={user}
                    />
                )}
                {!selectedSurvey ||
                    (selectedSurvey.id === -1 && (
                        <EmptyPage
                            message={lang.noSurveyResultSelected}
                            subMessage={lang.noSurveyResultSelectedMsg}
                            image={ReportingEmptyState}
                        />
                    ))}
                {managerPortalState.errorLoadingHierarchy &&
                    (user.isRestricted ? (
                        <EmptyPage
                            message={lang.noOrgChartAvailable}
                            subMessage={lang.noOrgChartAvailableMsg}
                            image={ReportingEmptyState}
                        />
                    ) : (
                        <EmptyPage message={lang.errorLoadingOrgChart} image={ReportingEmptyState} />
                    ))}
                {state.isCreateHierarchyOpen && !managerPortalState.errorLoadingHierarchy && (
                    <CreateHierarchyDialog
                        open={state.isCreateHierarchyOpen}
                        onClose={(): void => setState({ ...state, isCreateHierarchyOpen: false })}
                        handleCreateHierarchy={handleCreateHierarchy}
                    />
                )}
                {state.isConfigureCardOpen &&
                    visibleNodes.tree !== emptyVisibleNodes.tree &&
                    !managerPortalState.errorLoadingHierarchy && (
                        <ConfigureCard
                            onCloseConfigureCard={(): void => setState({ ...state, isConfigureCardOpen: false })}
                        />
                    )}
                {state.renameHierarchySelected && (
                    <RenameHierarchy
                        hierarchy={state.renameHierarchySelected}
                        onSave={handleRenameHierarchy}
                        onClose={handleCancelRenameHierarchy}
                    />
                )}
                <InformationDialog
                    closeButton={lang.close}
                    open={state.informationDialogSetDefaultLayout}
                    title={lang.warning}
                    message={state.setDefaultLayoutError}
                    onCloseClicked={(): void =>
                        setState({ ...state, informationDialogSetDefaultLayout: false, setDefaultLayoutError: "" })
                    }
                />
            </div>
        </div>
    );
};

export default OrgChartPage;

import { gql } from "@apollo/client";
export const editSurveyComparisonMutation = gql`
    mutation editSurveyComparison(
        $surveyComparisonId: Int!
        $label: String!
        $showInReports: Boolean!
        $showInFirstColumn: Boolean!
        $showInHeatmap: Boolean!
    ) {
        editSurveyComparison(
            surveyComparisonId: $surveyComparisonId
            label: $label
            showInReports: $showInReports
            showInFirstColumn: $showInFirstColumn
            showInHeatmap: $showInHeatmap
        )
    }
`;

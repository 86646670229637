import React, { ReactElement, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from "@mui/icons-material";

import { SurveySection } from "managerPortal/context/interfaces";
import { ExpandCollapseBtnContainer } from "./CorrelationAction";
import { Filter } from "components/filters/interfaces";
import { useLoading } from "core/hooks";
import { useCorrelationGridStyles } from "./correlationGrid.style";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { Correlation } from "components/reports/correlation/interface";
import { colorClasses } from "./correlation.init";
import { correlationCellClassMapper } from "./helper/correlationColorMapper";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Tooltip } from "lib/tooltip";

interface correlationColumnCell {
    title: string;
    id: number;
    isSection: boolean;
}
interface Props {
    surveyItemsOptions: SurveySection[];
    currentFilter: Filter;
    gridData: Correlation;
    shouldUpdate: boolean;
}

const CorrelationGrid = (props: Props): ReactElement => {
    const { surveyItemsOptions, gridData } = props;
    const { setLoading } = useLoading();
    const [displayHeaders, setDisplayHeaders] = useState<correlationColumnCell[]>([]);
    const [displayRows, setDisplayRows] = useState<correlationColumnCell[]>([]);
    const [expandedHeaders, setExpandedHeaders] = useState<correlationColumnCell[]>([]);
    const [expandedRows, setExpandedRows] = useState<correlationColumnCell[]>([]);
    const [firstLevelHeader, setFirstLevelHeader] = useState<correlationColumnCell[]>([]);
    const [firstLevelRow, setFirstLevelRow] = useState<correlationColumnCell[]>([]);
    const [nestedHeader, setNestedHeader] = useState<
        { firstLevel: correlationColumnCell; children: correlationColumnCell[] }[]
    >([]);
    const [nestedRow, setNestedRow] = useState<
        { firstLevel: correlationColumnCell; children: correlationColumnCell[] }[]
    >([]);
    const [tableDimension, setTableDimension] = useState<{ height: number; width: number }>({
        height: displayRows.length * 50 + 500,
        width: displayHeaders.length * 50 + 500
    });
    const [scrollingStatus, setScrollingStatus] = useState<{ left: boolean; top: boolean }>({
        left: false,
        top: false
    });

    const classes = useCorrelationGridStyles({
        width: tableDimension.width,
        height: tableDimension.height,
        floatingRowWidth: displayHeaders.length * 53 + 220,
        isScrollingDown: scrollingStatus.top,
        showFilterTags: props.currentFilter.items.length > 0
    });
    const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
        const div = e.target as HTMLDivElement;

        const isScrolling = { left: div.scrollLeft !== 0, top: div.scrollTop !== 0 };

        //flag scrolling event
        if (scrollingStatus.left !== isScrolling.left || scrollingStatus.top !== isScrolling.top) {
            setScrollingStatus(isScrolling);
        }
    };
    const tooltipTitle = (h: string, r: string): ReactElement => {
        return (
            <>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {h}
                </Typography>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {r}
                </Typography>
            </>
        );
    };
    const handleExpandAll = (): void => {
        setLoading(true);
        let expandAllHeader: correlationColumnCell[] = [];
        let expandAllRow: correlationColumnCell[] = [];
        nestedHeader.forEach(n => {
            const headerGroup = [n.firstLevel, ...n.children];
            expandAllHeader = [...expandAllHeader, ...headerGroup];
        });
        nestedRow.forEach(n => {
            const rowGroup = [n.firstLevel, ...n.children];
            expandAllRow = [...expandAllRow, ...rowGroup];
        });
        setDisplayHeaders(expandAllHeader);
        setExpandedHeaders([...firstLevelHeader]);
        setDisplayRows(expandAllRow);
        setExpandedRows([...firstLevelRow]);
        setLoading(false);
    };
    const handleCollapseAll = (): void => {
        const updatedHeaders = [...firstLevelHeader];
        setDisplayHeaders(updatedHeaders);
        setDisplayRows(updatedHeaders);
        setExpandedHeaders([]);
        setExpandedRows([]);
    };
    const handleColumnHeaderClick = (headerId: number): void => {
        const isHeaderExpanded = expandedHeaders.find(e => e.id === headerId) !== undefined;
        const isFirstLevel = firstLevelHeader.find(f => f.id === headerId) !== undefined;

        if (isFirstLevel) {
            const headerFound = nestedHeader.find(n => n.firstLevel.id === headerId);
            if (headerFound) {
                const children = headerFound.children;
                if (children && children.length > 0) {
                    if (!isHeaderExpanded) {
                        const motherIndex = displayHeaders.findIndex(h => h.id === headerId);
                        const firstHalf = [...displayHeaders].slice(0, motherIndex + 1);
                        const secondHalf = [...displayHeaders].slice(motherIndex + 1, displayHeaders.length);

                        setDisplayHeaders([...firstHalf, ...children, ...secondHalf]);
                        setExpandedHeaders([...expandedHeaders, headerFound.firstLevel]);
                    } else {
                        const headers = [...displayHeaders].filter(f => {
                            return children.findIndex(c => c.id === f.id) === -1;
                        });

                        setDisplayHeaders([...headers]);
                        const expanded = expandedHeaders.filter(ex => ex.id !== headerId);
                        setExpandedHeaders([...expanded]);
                    }
                }
            }
        }
    };
    const handleColumnRowClick = (rowId: number): void => {
        const isRowExpanded = expandedRows.find(e => e.id === rowId) !== undefined;
        const isFirstLevel = firstLevelRow.find(f => f.id === rowId) !== undefined;

        if (isFirstLevel) {
            const rowFound = nestedRow.find(n => n.firstLevel.id === rowId);
            if (rowFound) {
                const children = rowFound.children;
                if (children && children.length > 0) {
                    if (!isRowExpanded) {
                        const motherIndex = displayRows.findIndex(h => h.id === rowId);
                        const firstHalf = [...displayRows].slice(0, motherIndex + 1);
                        const secondHalf = [...displayRows].slice(motherIndex + 1, displayRows.length);

                        setDisplayRows([...firstHalf, ...children, ...secondHalf]);
                        setExpandedRows([...expandedRows, rowFound.firstLevel]);
                    } else {
                        const rows = [...displayRows].filter(f => {
                            return children.findIndex(c => c.id === f.id) === -1;
                        });

                        setDisplayRows([...rows]);
                        const expanded = expandedRows.filter(ex => ex.id !== rowId);
                        setExpandedRows([...expanded]);
                    }
                }
            }
        }
    };

    const isFirstLevelHeader = (id: number): boolean => {
        if (id === -1) return true;
        const headerFound = firstLevelHeader.findIndex(h => h.id === id);
        return headerFound !== -1;
    };
    const isFirstLevelRow = (id: number): boolean => {
        if (id === -1) return true;
        const rowFound = firstLevelRow.findIndex(h => h.id === id);
        return rowFound !== -1;
    };
    const isHeaderExpanded = (id: number): boolean => {
        return expandedHeaders.find(e => e.id === id) !== undefined;
    };
    const isHeaderExpandable = (id: number): boolean => {
        return nestedHeader.find(h => h.firstLevel.id === id && h.children.length > 0) !== undefined;
    };
    const isRowExpanded = (id: number): boolean => {
        return expandedRows.find(e => e.id === id) !== undefined;
    };
    const isRowExpandable = (id: number): boolean => {
        return nestedRow.find(h => h.firstLevel.id === id && h.children.length > 0) !== undefined;
    };
    const valueFinder = (row: correlationColumnCell, column: correlationColumnCell): string => {
        if (Object.keys(gridData.sections)[0] === "-1") {
            return "";
        }
        if (row.id === column.id && row.title === column.title) {
            return "";
        }
        const rowCorrelations = gridData[row.isSection ? "sections" : "items"][row.id];
        if (rowCorrelations) {
            const cellCorrelation = rowCorrelations[column.isSection ? "sections" : "items"][column.id];
            if (cellCorrelation) {
                return cellCorrelation !== null
                    ? cellCorrelation == 1
                        ? "1"
                        : cellCorrelation.toFixed(2).replace(/0./, ".")
                    : "-";
            }
            return "-";
        }
        return "-";
    };

    useEffect(() => {
        const headers: correlationColumnCell[] = [];
        const rowHeaders: correlationColumnCell[] = [];
        const nestedHeaders: { firstLevel: correlationColumnCell; children: correlationColumnCell[] }[] = [];
        const nestedRows: { firstLevel: correlationColumnCell; children: correlationColumnCell[] }[] = [];

        surveyItemsOptions.forEach(section => {
            //push all avaliable headers and rowHeaders
            headers.push({ title: section.title, id: +section.sectionId.slice(1), isSection: true });
            rowHeaders.push({ title: section.title, id: +section.sectionId.slice(1), isSection: true });
        });
        setDisplayHeaders(headers);
        setDisplayRows(rowHeaders);

        //first level headers are sections
        setFirstLevelHeader(headers);
        setFirstLevelRow(rowHeaders);

        surveyItemsOptions.forEach(section => {
            const parentNode: { firstLevel: correlationColumnCell; children: correlationColumnCell[] } = {
                firstLevel: { title: section.title, id: +section.sectionId.slice(1), isSection: true },
                children: []
            };
            //get all question nodes
            const children = section.questions
                .map(q => q.items.map(i => ({ title: i.title, id: +i.itemId.replace("I", ""), isSection: false })))
                .reduce((a, b) => a.concat(b)) as correlationColumnCell[];
            parentNode.children = children;
            nestedHeaders.push(parentNode);
            nestedRows.push(parentNode);
        });

        setNestedHeader(nestedHeaders);
        setNestedRow(nestedRows);

        return (): void => {
            setDisplayHeaders([]);
            setDisplayRows([]);
            setFirstLevelHeader([]);
            setFirstLevelRow([]);
            setNestedHeader([]);
            setNestedRow([]);
        };
    }, [props.shouldUpdate]);

    useEffect(() => {
        setTableDimension({
            height: displayRows.length * 50 + 200,
            width: displayHeaders.length * 50 + 400
        });
    }, [displayHeaders, displayRows]);

    return (
        <div className={classes.container}>
            <div
                className={classes.tableContainer}
                data-testid={"correlation-table-container"}
                onScroll={(e): void => {
                    scrollHandler(e);
                }}
            >
                {scrollingStatus.top && scrollingStatus.left && (
                    <>
                        <thead className={classes.flaoting_thead}>
                            <tr className={classes.tableRow}>
                                {displayHeaders.map((d: correlationColumnCell, i: number) => {
                                    return isFirstLevelHeader(d.id) ? (
                                        <th
                                            className={classes.floating_tableRow_th_vertical}
                                            key={i}
                                            onClick={(): void => {
                                                handleColumnHeaderClick(d.id);
                                            }}
                                        >
                                            <div className={classes.floating_tableRow_th_div}>
                                                <div
                                                    className={classes.floating_tableRow_th_div_span_vertical}
                                                    data-testid={`column-header-${d.title}`}
                                                >
                                                    {isHeaderExpandable(d.id) && (
                                                        <IconButton
                                                            onClick={(): void => {
                                                                handleColumnHeaderClick(d.id);
                                                            }}
                                                            data-testid={
                                                                isHeaderExpanded(d.id)
                                                                    ? `collapse-${d.title}`
                                                                    : `expand-${d.title}`
                                                            }
                                                            className={classes.iconButton}
                                                            disableFocusRipple
                                                            disableRipple
                                                            edge={false}
                                                            size="small"
                                                        >
                                                            {isHeaderExpanded(d.id) ? (
                                                                <ChevronLeft />
                                                            ) : (
                                                                <ChevronRight />
                                                            )}
                                                        </IconButton>
                                                    )}

                                                    <Tooltip title={d.title} placement={"left"}>
                                                        <Typography variant="body1">{d.title}</Typography>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </th>
                                    ) : (
                                        <th className={classes.floating_tableRow_th_vertical} key={i}>
                                            <div className={classes.floating_tableRow_th_div_children_vertical}>
                                                <div
                                                    className={classes.tableRow_th_div_span_vertical_children}
                                                    data-testid={`column-header-${d.title}`}
                                                >
                                                    <TruncateDisplay
                                                        maxLabelLength={70}
                                                        title={d.title}
                                                        alwaysShowTooltip
                                                    >
                                                        <Typography variant="body2">{d.title}</Typography>
                                                    </TruncateDisplay>
                                                </div>
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <div className={classes.floatingRowHeader}>
                            <div className={classes.placeholderShort}></div>
                            {displayRows.map((h: correlationColumnCell, i: number) => (
                                <div className={classes.floatingRowGroup} key={i}>
                                    <div className={classes.floatingRowHeaderCell}>
                                        {isFirstLevelRow(h.id) ? (
                                            <td
                                                className={classes.rowHeaderCell}
                                                key={i}
                                                onClick={(): void => {
                                                    handleColumnRowClick(h.id);
                                                }}
                                            >
                                                <div
                                                    className={classes.floating_tableRow_th_div_span_horizontal}
                                                    data-testid={`rowHeader+${h.title}`}
                                                >
                                                    <Tooltip title={h.title} placement={"bottom"}>
                                                        <Typography variant="body1">{h.title}</Typography>
                                                    </Tooltip>
                                                </div>
                                                <div className={classes.floating_iconButton_horizontal_wraper}>
                                                    {isRowExpandable(h.id) && (
                                                        <IconButton
                                                            onClick={(): void => {
                                                                handleColumnRowClick(h.id);
                                                            }}
                                                            data-testid={
                                                                isRowExpanded(h.id)
                                                                    ? `collapse-${h.title}`
                                                                    : `expand-${h.title}`
                                                            }
                                                            className={classes.iconButton_horizontal}
                                                            disableFocusRipple
                                                            disableRipple
                                                            edge={false}
                                                            size="small"
                                                        >
                                                            {isRowExpanded(h.id) ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    )}
                                                </div>
                                            </td>
                                        ) : (
                                            <td key={i}>
                                                <div className={classes.tableRow_th_div}>
                                                    <div
                                                        className={classes.tableRow_th_div_span_details_horizontal}
                                                        data-testid={`rowHeader+${h.title}`}
                                                    >
                                                        <TruncateDisplay
                                                            maxLabelLength={70}
                                                            title={h.title}
                                                            alwaysShowTooltip
                                                        >
                                                            <Typography variant="body2">{h.title}</Typography>
                                                        </TruncateDisplay>
                                                    </div>
                                                </div>
                                            </td>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {scrollingStatus.top && !scrollingStatus.left && (
                    <thead className={classes.flaoting_thead}>
                        <tr className={classes.tableRow}>
                            {displayHeaders.map((d: correlationColumnCell, i: number) => {
                                return isFirstLevelHeader(d.id) ? (
                                    <th
                                        className={classes.floating_tableRow_th_vertical}
                                        key={i}
                                        onClick={(): void => {
                                            handleColumnHeaderClick(d.id);
                                        }}
                                    >
                                        <div className={classes.floating_tableRow_th_div}>
                                            <div
                                                className={classes.floating_tableRow_th_div_span_vertical}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                {isHeaderExpandable(d.id) && (
                                                    <IconButton
                                                        onClick={(): void => {
                                                            handleColumnHeaderClick(d.id);
                                                        }}
                                                        data-testid={
                                                            isHeaderExpanded(d.id)
                                                                ? `collapse-${d.title}`
                                                                : `expand-${d.title}`
                                                        }
                                                        className={classes.iconButton}
                                                        disableFocusRipple
                                                        disableRipple
                                                        edge={false}
                                                        size="small"
                                                    >
                                                        {isHeaderExpanded(d.id) ? <ChevronLeft /> : <ChevronRight />}
                                                    </IconButton>
                                                )}

                                                <Tooltip title={d.title} placement={"left"}>
                                                    <Typography variant="body1">{d.title}</Typography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </th>
                                ) : (
                                    <th className={classes.floating_tableRow_th_vertical} key={i}>
                                        <div className={classes.tableRow_th_div_children_vertical}>
                                            <div
                                                className={classes.tableRow_th_div_span_vertical_children}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={d.title} alwaysShowTooltip>
                                                    <Typography variant="body2">{d.title}</Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                )}
                {scrollingStatus.left && !scrollingStatus.top && (
                    <div className={classes.floatingRowHeader}>
                        <div className={classes.placeholderShort}></div>
                        {displayRows.map((h: correlationColumnCell, i: number) => (
                            <div className={classes.floatingRowGroup} key={i}>
                                <div className={classes.floatingRowHeaderCell}>
                                    {isFirstLevelRow(h.id) ? (
                                        <td
                                            className={classes.rowHeaderCell}
                                            key={i}
                                            onClick={(): void => {
                                                handleColumnRowClick(h.id);
                                            }}
                                        >
                                            <div
                                                className={classes.floating_tableRow_th_div_span_horizontal}
                                                data-testid={`rowHeader+${h.title}`}
                                            >
                                                <Tooltip title={h.title} placement={"bottom"}>
                                                    <Typography variant="body1">{h.title}</Typography>
                                                </Tooltip>
                                            </div>
                                            <div className={classes.floating_iconButton_horizontal_wraper}>
                                                {isRowExpandable(h.id) && (
                                                    <IconButton
                                                        onClick={(): void => {
                                                            handleColumnRowClick(h.id);
                                                        }}
                                                        data-testid={
                                                            isRowExpanded(h.id)
                                                                ? `collapse-${h.title}`
                                                                : `expand-${h.title}`
                                                        }
                                                        className={classes.iconButton_horizontal}
                                                        disableFocusRipple
                                                        disableRipple
                                                        edge={false}
                                                        size="small"
                                                    >
                                                        {isRowExpanded(h.id) ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                )}
                                            </div>
                                        </td>
                                    ) : (
                                        <td key={i}>
                                            <div className={classes.floating_tableRow_th_div}>
                                                <div
                                                    className={classes.tableRow_th_div_span_details_horizontal}
                                                    data-testid={`rowHeader+${h.title}`}
                                                >
                                                    <TruncateDisplay
                                                        maxLabelLength={70}
                                                        title={h.title}
                                                        alwaysShowTooltip
                                                    >
                                                        <Typography variant="body2">{h.title}</Typography>
                                                    </TruncateDisplay>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className={classes.floatingAction}>
                    <ExpandCollapseBtnContainer
                        handleCollapseAll={handleCollapseAll}
                        handleExpandAll={handleExpandAll}
                        originalHeaderLength={firstLevelHeader.length}
                        currentHeaderLength={displayHeaders.length}
                        expandedAllHeaderLength={
                            nestedHeader.length + nestedHeader.map(h => h.children.length).reduce((a, b) => a + b, 0)
                        }
                        originalRowLength={firstLevelRow.length}
                        currentRowLength={displayRows.length}
                        expandedAllRowLength={
                            nestedRow.length + nestedRow.map(h => h.children.length).reduce((a, b) => a + b, 0)
                        }
                    />
                </div>
                <table className={classes.gridTable} data-testid="correlation-table">
                    <thead className={classes.thead}>
                        <tr className={classes.tableRow}>
                            <th className={classes.tableAction}></th>
                            {displayHeaders.map((d: correlationColumnCell, i: number) => {
                                return isFirstLevelHeader(d.id) ? (
                                    <th
                                        className={classes.tableRow_th_vertical}
                                        key={i}
                                        onClick={(): void => {
                                            handleColumnHeaderClick(d.id);
                                        }}
                                    >
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={classes.tableRow_th_div_span_vertical}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                {isHeaderExpandable(d.id) && (
                                                    <IconButton
                                                        onClick={(): void => {
                                                            handleColumnHeaderClick(d.id);
                                                        }}
                                                        data-testid={
                                                            isHeaderExpanded(d.id)
                                                                ? `collapse-${d.title}`
                                                                : `expand-${d.title}`
                                                        }
                                                        className={classes.iconButton}
                                                        disableFocusRipple
                                                        disableRipple
                                                        edge={false}
                                                        size="small"
                                                    >
                                                        {isHeaderExpanded(d.id) ? <ChevronLeft /> : <ChevronRight />}
                                                    </IconButton>
                                                )}

                                                <Tooltip title={d.title} placement={"left"}>
                                                    <Typography variant="body1">{d.title}</Typography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </th>
                                ) : (
                                    <th className={classes.tableRow_th_vertical} key={i}>
                                        <div className={classes.tableRow_th_div_children_vertical}>
                                            <div
                                                className={classes.tableRow_th_div_span_vertical_children}
                                                data-testid={`column-header-${d.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={d.title} alwaysShowTooltip>
                                                    <Typography variant="body2">{d.title}</Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className={classes.tbody} data-testid="correlation-data-grid">
                        {displayRows.map((h: correlationColumnCell, i: number) => (
                            <tr className={classes.tableBodyRow} key={i}>
                                {isFirstLevelRow(h.id) ? (
                                    <td
                                        className={classes.rowHeaderCell}
                                        key={i}
                                        onClick={(): void => {
                                            handleColumnRowClick(h.id);
                                        }}
                                    >
                                        <div
                                            className={classes.tableRow_th_div_span_horizontal}
                                            data-testid={`rowHeader+${h.title}`}
                                        >
                                            <Tooltip title={h.title} placement={"bottom"}>
                                                <Typography variant="body1">{h.title}</Typography>
                                            </Tooltip>
                                        </div>
                                        <div className={classes.iconButton_horizontal_wraper}>
                                            {isRowExpandable(h.id) && (
                                                <IconButton
                                                    onClick={(): void => {
                                                        handleColumnRowClick(h.id);
                                                    }}
                                                    data-testid={
                                                        isRowExpanded(h.id)
                                                            ? `collapse-${h.title}`
                                                            : `expand-${h.title}`
                                                    }
                                                    className={classes.iconButton_horizontal}
                                                    disableFocusRipple
                                                    disableRipple
                                                    edge={false}
                                                    size="small"
                                                >
                                                    {isRowExpanded(h.id) ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            )}
                                        </div>
                                    </td>
                                ) : (
                                    <td key={i}>
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={classes.tableRow_th_div_span_details_horizontal}
                                                data-testid={`rowHeader+${h.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={h.title} alwaysShowTooltip>
                                                    <Typography variant="body2">{h.title}</Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {displayHeaders.map((r: correlationColumnCell, j: number) => {
                                    const valueFound = valueFinder(h, r);
                                    const styleClass = correlationCellClassMapper(valueFound) as colorClasses;
                                    return (
                                        <td
                                            key={j}
                                            data-testid={`data-cell-${r}-${h.title}`}
                                            className={
                                                h.isSection || r.isSection
                                                    ? `${classes.dataCell} ${classes[styleClass]} ${classes.boldValue}`
                                                    : `${classes.dataCell} ${classes[styleClass]}`
                                            }
                                        >
                                            <Tooltip title={tooltipTitle(h.title, r.title)} placement={"bottom"}>
                                                <Typography variant="body1">{valueFound}</Typography>
                                            </Tooltip>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CorrelationGrid;

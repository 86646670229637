export enum SurveyEditActions {
    UPDATE_SELECTED_SIDEBAR = "UPDATE_SELECTED_SIDEBAR",
    SET_SURVEY = "SET_SURVEY",
    SET_VALIDATION_ERROR = "SET_VALIDATION_ERROR",
    SET_FOR_OPTIMIZATION = "SET_FOR_OPTIMIZATION",
    CHANGE_LAN_SIDEBAR = "CHANGE_LAN_SIDEBAR",
    ADD_TAG = "ADD_TAG",
    DELETE_TAG = "DELETE_TAG",
    ADD_ORG_TAGS = "ADD_ORG_TAGS"
}

import { QuestionType } from "managerPortal/context/enums";
import { SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";

//filter out unwanted filter values, similar filter function can be found in previous period and other places
//could use a unified solution for this
export const SurveySectionFilterDimensionName = (sections: SurveySection[], fieldOrQuestionId: number): string => {
    if (!fieldOrQuestionId) return "";
    const filteredSection = sections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions.filter(
                    (question: SurveyQuestion) =>
                        question.questionType === QuestionType.PickOne ||
                        question.questionType === QuestionType.PickMany
                )
            };
        })
        .filter((section: SurveySection) => section.questions.length);
    let dimensionName = "";
    filteredSection.forEach((section: SurveySection) =>
        section.questions.forEach((question: SurveyQuestion) => {
            if (question.questionId === fieldOrQuestionId) {
                dimensionName = section.title;
            } else {
                const surveyItem = question.items.find((item: SurveyItem) => item.fieldId === fieldOrQuestionId);
                if (surveyItem) {
                    dimensionName = section.title;
                }
            }
        })
    );
    return dimensionName;
};

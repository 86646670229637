//Background Default
export const backgroundDefault = "#EEEEEE";

// Light Grey Background
export const backgroundLightGrey = "#FAFAFA";

// hover grey background
export const backgroundHoverGrey = "rgba(0,0,0,0.08)";

// Error red background
export const backgroundErrorTomato = "rgba(220,53,69,0.24)";

// Disabled Background
export const backgroundDisabled = "#E2E2E2";

//Background Dark Mode
export const backgroundDarkActive = "#267AA4";

//Background primary
export const backgroundPrimary = "#E87424";

export const popoverBackground = "#78787A";

export const whiteBackground = "#FFFFFF";

export const lightPink = "rgba(220, 53, 69, 0.08)";

export const tableHead = "rgba(0,0,0,0.08)";

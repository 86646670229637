import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";

const ShowConfidentialityMessage = (): ReactElement => {
    const { lang } = useLang();

    return (
        <>
            <Typography variant="body1" sx={{ marginTop: "24px", marginLeft: "24px", fontWeight: 500 }}>
                {lang.confidentialityWarning}
            </Typography>
            {lang.disallowedFilterParagraphs.map((text: string, index: number) => (
                <Typography
                    key={index}
                    variant="body2"
                    sx={{ marginTop: "12px", marginLeft: "24px", marginRight: "24px" }}
                >
                    {text}
                </Typography>
            ))}
        </>
    );
};

export default ShowConfidentialityMessage;

import { createTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import * as colors from "./palette";

declare module "@mui/material/styles" {
    interface Theme {
        border: {
            main: CSSProperties["border"];
            bold: CSSProperties["border"];
            greyDashed: CSSProperties["border"];
        };
    }
    interface ThemeOptions {
        border?: {
            main?: CSSProperties["border"];
            bold: CSSProperties["border"];
            greyDashed?: CSSProperties["border"];
        };
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primaryOrange,
            light: colors.primaryOrangeLight,
            dark: colors.primaryOrangeDark
        },
        secondary: {
            main: colors.primaryBlue,
            light: colors.primaryBlueLight,
            dark: colors.primaryBlueDark
        },
        error: {
            main: colors.errorColor
        },
        background: {
            default: colors.backgroundDefault
        }
    },
    border: {
        main: "1px solid #E3E3E3",
        bold: "1px solid #161616",
        greyDashed: "1.5px dashed rgba(0,0,0,0.38)"
    },
    typography: {
        fontFamily: [
            '"Open Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        h1: {
            fontSize: "34px",
            fontWeight: 500
        },
        h2: {
            fontSize: "34px",
            fontWeight: 400,
            lineHeight: 1.235
        },
        h3: {
            fontSize: "24px",
            fontWeight: "bold"
        },
        h4: {
            fontSize: "20px"
        },
        h5: {
            fontSize: "20px",
            fontWeight: "bold"
        },
        h6: {
            fontSize: "20px",
            fontWeight: "normal"
        },
        body1: {
            fontSize: "16px"
        },
        body2: {
            fontSize: "14px"
        },
        subtitle1: {
            fontSize: "14px"
        },
        subtitle2: {
            fontSize: "12px",
            color: colors.textColorGrey
        },
        caption: {
            fontSize: "12px"
        }
    },
    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: colors.backgroundDefault,
                        "&:hover": {
                            backgroundColor: colors.backgroundHoverGrey
                        }
                    }
                }
            }
        }
    }
});

export { theme };

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { Help } from "@mui/icons-material";

import { DataKey } from "components/shared/infiniteTable/enums";
import { UserMutateDetail } from "./interface";
import { useLang } from "core/hooks";
import { UserRole } from "./enums";
import CustomTypography from "components/shared/CustomTypography";
import { Checkbox } from "lib/checkbox";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";
import { Button } from "lib/button";
import { ButtonLink } from "lib/button-link";

type Props = {
    validation: { key: string; message: string };
    editMode: boolean;
    onChangeField: (key: keyof UserMutateDetail, newValue: string | number | boolean) => void;
    onBlur?: () => void;
    user: UserMutateDetail;
    redirectURL?: string;
    handleSaveButton?: () => void;
    handleResetPassword?: () => void;
    handleDeactivateUser?: () => void;
    canEdit?: boolean;
};

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        overflowY: "auto",
        paddingBottom: theme.spacing(3)
    },
    contentInfo: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    formGroup: {
        display: "flex",
        width: "fit-content"
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    helperText: {
        marginTop: -theme.spacing(1)
    },
    formControlWithTooltip: {
        display: "flex",
        alignItems: "center"
    }
}));

const UserDetail = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { user: loggedUser } = useUser();
    const { user, validation, editMode, onChangeField, onBlur } = props;
    const classes = useStyles(loggedUser.settings.selectedOrganization.id);

    const checkboxHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === UserRole.isSiteAdmin) {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.isSiteAdmin);
        } else if (event.target.name === UserRole.isCommentViewer) {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.isCommentViewer);
        } else if (event.target.name === UserRole.canViewAllOrgReport) {
            if (!user.isSiteAdmin) onChangeField(UserRole.isRestricted as keyof UserMutateDetail, !user.isRestricted);
        } else if (event.target.name === UserRole.byPassSimilarityCheck) {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.byPassSimilarityCheck);
        } else if (event.target.name === UserRole.isInsightsEnabled) {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.isInsightsEnabled);
        } else if (event.target.name === UserRole.participantEnabled) {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.participantEnabled);
        } else {
            onChangeField(event.target.name as keyof UserMutateDetail, !user.isSurveyCreator);
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newInput = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        if (event.target.type === "text") {
            const userInput = newInput as string;
            if (event.target.name === "email") {
                onChangeField(event.target.name as keyof UserMutateDetail, userInput.trim());
            } else {
                onChangeField(event.target.name as keyof UserMutateDetail, userInput);
            }
        } else {
            checkboxHandler(event);
        }
    };

    const handleOnBlur = (): void => {
        if (onBlur) onBlur();
    };

    return (
        <div className={classes.container}>
            <div className={classes.contentInfo}>
                <Typography fontWeight="medium">{lang.userInformationLabel}</Typography>
            </div>
            <Box display="flex" flexDirection="column" width={600} pr={3} pl={3} gap={2}>
                <TextField
                    sx={{ marginBottom: 2 }}
                    onChange={handleChangeField}
                    id="userNameTextField"
                    label={lang.name}
                    name={DataKey.name}
                    value={user.name}
                    onBlur={handleOnBlur}
                    error={validation.key === DataKey.name}
                    helperText={validation.key === DataKey.name ? validation.message : "Required"}
                    disabled={!(loggedUser.isTalentMapAdmin || loggedUser.isSiteAdmin)}
                />
                {editMode ? (
                    <CustomTypography content={[user.email]} subtitle={lang.email} />
                ) : (
                    <TextField
                        onChange={handleChangeField}
                        id="userEmailTextField"
                        label={lang.email}
                        name={DataKey.email}
                        value={user.email}
                        onBlur={handleOnBlur}
                        error={validation.key === DataKey.email}
                        helperText={validation.key === DataKey.email ? validation.message : "Required"}
                    />
                )}
                {(loggedUser.isTalentMapAdmin || loggedUser.isSiteAdmin) && (
                    <Box display="flex" flexDirection="column" mt={2}>
                        <Checkbox
                            checked={user.isCommentViewer}
                            onChange={handleChangeField}
                            name={UserRole.isCommentViewer}
                            disabled={!loggedUser.isSiteAdmin}
                            label={lang.isCommentViewer}
                            data-testid={lang.isCommentViewer}
                        />
                        {loggedUser.isTalentMapAdmin && (
                            <>
                                <Checkbox
                                    checked={user.isInsightsEnabled}
                                    onChange={handleChangeField}
                                    name={UserRole.isInsightsEnabled}
                                    disabled={!loggedUser.isTalentMapAdmin}
                                    label={lang.isInsightPageEnabled}
                                    data-testid={lang.isInsightPageEnabled}
                                />
                                <Checkbox
                                    checked={user.isSurveyCreator}
                                    onChange={handleChangeField}
                                    name={UserRole.isSurveyCreator}
                                    disabled={!loggedUser.isTalentMapAdmin}
                                    label={lang.canCreateSurveys}
                                    data-testid={lang.canCreateSurveys}
                                />
                                <Checkbox
                                    checked={user.isSiteAdmin}
                                    onChange={handleChangeField}
                                    name={UserRole.isSiteAdmin}
                                    disabled={!loggedUser.isTalentMapAdmin}
                                    label={lang.isSiteAdmin}
                                    data-testid={lang.isSiteAdmin}
                                />
                                <Checkbox
                                    checked={user.participantEnabled}
                                    onChange={handleChangeField}
                                    name={UserRole.participantEnabled}
                                    disabled={!loggedUser.isTalentMapAdmin || !user.isSiteAdmin}
                                    label={lang.isParticipantEnabled}
                                    data-testid={lang.isParticipantEnabled}
                                />
                            </>
                        )}
                        <div className={classes.formControlWithTooltip}>
                            <Checkbox
                                checked={!user.isRestricted}
                                onChange={handleChangeField}
                                name={UserRole.canViewAllOrgReport}
                                disabled={!loggedUser.isSiteAdmin}
                                label={lang.canViewAllOrganizationReportData}
                                data-testid={lang.canViewAllOrganizationReportData}
                            />
                        </div>
                        <div className={classes.formControlWithTooltip}>
                            <Checkbox
                                checked={user.byPassSimilarityCheck}
                                onChange={handleChangeField}
                                name={UserRole.byPassSimilarityCheck}
                                disabled={!loggedUser.isSiteAdmin}
                                label={lang.removeFilterSimilarityWarning}
                                data-testid={lang.removeFilterSimilarityWarning}
                            />
                            <Tooltip title={lang.removeFilterSimilarityWarningTooltip} placement="right">
                                <a
                                    href={`https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/compass-reports/reporting-faq/how-does-report-filter-confidentiality-work-1`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IconButton aria-label={lang.help}>
                                        <Help />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </div>
                    </Box>
                )}
                {editMode ? (
                    <Box display="flex" gap={4} mt={2}>
                        {user.active && (
                            <Button
                                variant="outlined"
                                onClick={props.handleResetPassword}
                                disabled={!props.canEdit}
                                data-testid="btn-reset-password"
                            >
                                {user.lastActivity ? lang.resetPassWord : lang.resend}
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            onClick={props.handleDeactivateUser}
                            disabled={!props.canEdit}
                            data-testid="btn-deactivate-reactivate-user"
                        >
                            {user.active ? lang.deactivate : lang.reactivate}
                        </Button>
                    </Box>
                ) : (
                    <Box display="flex" gap={4} mt={2}>
                        <Button
                            variant="outlined"
                            onClick={props.handleSaveButton}
                            data-testid="btn-user-save"
                            disabled={user.name.trim() === "" || user.email.trim() === ""}
                        >
                            {lang.ok}
                        </Button>
                        <ButtonLink
                            variant="outlined"
                            link={props.redirectURL ?? "/admin/users"}
                            data-testid="btn-add-user-cancel"
                        >
                            {lang.cancel}
                        </ButtonLink>
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default UserDetail;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from "react";

export default class DragScroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolling: false
        };
    }

    componentDidMount = () => {
        const w = window.innerWidth;
        const center = this.props.defaultScrollX + this.props.cardWidth / 2 - w / 2;
        this.scrollingDomElement.scrollLeft = center;
    };

    render() {
        const { children } = this.props;
        const style = {
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "scroll",
            cursor: "-webkit-grab" // TODO: figure out how to use the material ui PanTool icon for this
        };

        return (
            <div
                style={style}
                ref={ref => (this.scrollingDomElement = ref)}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
            >
                {children}
            </div>
        );
    }

    onMouseDown = event => {
        // This is a hack.  We only want the drag to start when clicking on the background behind the cards, but there's several
        // layers of divs above the div containing the drag area.  So we check the mouse cursor, which is set to the "drag hand"
        // from webkit when over the backround, to see if the drag should start.
        let currentCursor = () => {
            let domElements = document.querySelectorAll(":hover");
            for (let i = domElements.length - 1; i >= 0; i--) {
                let cursor = domElements[i].style.cursor;
                if (cursor) {
                    return cursor;
                }
            }
            return null;
        };
        if (currentCursor() === "-webkit-grab") {
            // It's easy to accidentally select text when dragging, and as soon as text is select the drag effect
            // changes to try and drag the text.  So on mouse click when we start a drag we clear the text selection.
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
            if (document.selection) {
                document.selection.empty();
            }

            this.setState({
                scrolling: true,
                dragStartX: event.clientX,
                dragStartY: event.clientY,
                scrollStartX: this.scrollingDomElement.scrollLeft,
                scrollStartY: this.scrollingDomElement.scrollTop
            });
        }
    };

    onMouseMove = event => {
        if (!this.state.scrolling) {
            return;
        }

        if (event.buttons & 1) {
            this.scrollingDomElement.scrollLeft = this.state.scrollStartX - (event.clientX - this.state.dragStartX);
            this.scrollingDomElement.scrollTop = this.state.scrollStartY - (event.clientY - this.state.dragStartY);
        } else {
            this.setState({
                scrolling: false,
                dragStartX: undefined,
                dragStartY: undefined,
                scrollStartX: undefined,
                scrollStartY: undefined
            });
        }
    };
}

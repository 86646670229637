import React from "react";

import { makeStyles } from "@mui/styles";
import { HierarchyField } from "../hierarchy/interfaces";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useLang } from "../../../core/hooks";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    listStyle: {
        flexGrow: 1,
        height: "calc(100% - 30px)",
        minHeight: "calc(100% - 30px)",
        border: theme.border.main,
        overflowY: "auto",
        boxSizing: "border-box"
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItem: "center",
        padding: 8,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        boxSizing: "border-box",
        "&:hover": {
            background: theme.palette.action.hover
        }
    },
    isDraggingOver: {
        background: theme.palette.action.disabledBackground,
        boxSizing: "border-box"
    },
    isDragging: {
        border: "1.5px dashed",
        borderColor: theme.palette.action.disabledBackground,
        boxSizing: "border-box"
    }
}));

interface Props {
    fields: HierarchyField[];
    listType: string;
    droppableId: string;
}

const FieldList = (props: Props): JSX.Element => {
    const { fields, listType, droppableId } = props;
    const classes = useStyles();
    const { lang } = useLang();
    return (
        <Droppable droppableId={droppableId}>
            {(provided, snapshot): JSX.Element => (
                <div
                    id={listType}
                    ref={provided.innerRef}
                    className={
                        snapshot.isDraggingOver ? `${classes.isDraggingOver} ${classes.listStyle}` : classes.listStyle
                    }
                    {...provided.droppableProps}
                >
                    {droppableId === "hierarchyFields" && fields.length === 0 ? (
                        <Typography variant="body1" ml={2} mt={1}>
                            {lang.dragHereToAdd} {lang.fields}
                        </Typography>
                    ) : (
                        fields.map(
                            (item: HierarchyField, i: number): JSX.Element => (
                                <Draggable key={item.FieldId} draggableId={item.FieldId.toString()} index={i}>
                                    {(provided, snapshot): JSX.Element => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={
                                                snapshot.isDragging
                                                    ? `${classes.listItem} ${classes.isDragging}`
                                                    : classes.listItem
                                            }
                                        >
                                            <Typography variant="body1">{item.FieldName}</Typography>
                                        </div>
                                    )}
                                </Draggable>
                            )
                        )
                    )}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default FieldList;

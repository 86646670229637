import React from "react";

import MuiPopper, { PopperProps } from "@mui/material/Popper";

const Popper = (props: PopperProps) => {
    const { children, ...rest } = props;
    return <MuiPopper {...rest}>{children}</MuiPopper>;
};

export { Popper };

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none"
    },
    text: {
        fontSize: 12
    }
}));

type Props = {
    name: string;
    to: string;
};

const CellLink: FC<Props> = ({ to, name }) => {
    const classes = useStyles();

    return (
        <Link className={classes.link} to={to}>
            <Typography color="primary" variant="body2" className={classes.text}>
                {name}
            </Typography>
        </Link>
    );
};

export default CellLink;

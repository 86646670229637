import React, { ChangeEvent, ReactElement, useState, useRef } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Help } from "@mui/icons-material";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";
import { makeStyles } from "@mui/styles";
import { useLang, useSurveys, useOrgChartService } from "core/hooks";
import { SurveyComparison, ContactField, RemapGroup, Coordinate, FieldMap, ValueMap } from "./interfaces";
import { PreviousPeriodTable, PreviousPeriodTableHandle } from "./PreviousPeriodTable";
import { Filter } from "components/filters/interfaces";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { initSnackbar, initRemapGroup, initCoordinate } from "./init";
import { ItemMappingDialog } from "./ItemMappingDialog";
import { DemographicMappingDialog } from "./DemographicMappingDialog";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { PPSecondMappingPopup } from "./PreviousPeriodSecondMapping";
import { Checkbox } from "lib/checkbox";
import { Snackbar } from "lib/snackbar";
import { Button } from "lib/button";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { IconButton, IconButtonV4 } from "lib/icon-button";
import { theme } from "lib/theme";
import { Tab, Tabs } from "lib/tabs";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

const usePPMappedStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%"
    },
    tableHeader: {
        padding: `0 ${theme.spacing(3)}`,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        height: 32
    },
    tableHeaderPlaceHolder: {
        padding: `0 ${theme.spacing(3)}`,
        display: "flex",
        height: "1px",
        backgroundColor: "#E3E3E3"
    },
    tableContainer: { width: "100%", height: "calc(100% - 152px)" },
    tableContent: { height: "calc(100% - 16px)" },
    leftCol: { width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" },
    rightCol: { width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" },
    tabsContainerStyles: { display: "flex", justifyContent: "space-between", height: 56, alignItems: "flex-end" },
    expandIcons: {
        display: "flex",
        alignItems: "flex-end",
        paddingRight: 16
    }
}));

export enum TabOptions {
    items,
    contacts,
    properties
}

type Props = {
    sourceContactFields: ContactField[];
    targetContactFields: ContactField[];
    targetSurveyItemsOptions: SelectOptionExpandablePanel[];
    surveyComparisons: SurveyComparison;
    selectedSurveyId: number;
    surveyItemsOptions: SelectOptionExpandablePanel[];
    editSurveyComparisonField: (surveyComparisonId: number, sourceFieldId: number, targetFieldId: number) => void;
    editSurveyComparison: (
        surveyComparisonId: number,
        label: string,
        showInReports: boolean,
        showInFirstColumn: boolean,
        showInHeatmap: boolean
    ) => void;
    deleteSurveyComparison: (surveyComparisonId: number) => void;
    editSurveyComparisonValue: (
        surveyComparisonId: number,
        sourceFieldId: number,
        sourceValue: string,
        filter: Filter
    ) => void;
    editSurveyItemMapping: (surveyComparisonId: number, sourceFieldId: number, targetFieldId?: number) => void;
    allSurveyComparison: SurveyComparison[];
    targetSurveySelectedId: number;
    handleTargetSurveyChange: (targetSurveyId: number) => void;
    onAutoMap: (targetSurveyId: number, label: string) => void;
    clearSectionMapping: (sectionId: string) => void;
};

type State = {
    mappingLabel: string;
    snackbar: {
        isOpen: boolean;
        message: string;
    };
    showPreviousPeriod: boolean;
    showInFirstColumn: boolean;
    labelError: boolean;
    tabValue: TabOptions;
    confirmationDialog: boolean;
    remapGroup: RemapGroup;
    anchorElement: Coordinate;
    showInHeatmap: boolean;
};
const PreviousPeriodMapped = (props: Props): ReactElement => {
    const { surveyItemsOptions } = props;
    const maxCharacterForLabel = 6;
    const classes = usePPMappedStyles();
    const { lang, languageCode } = useLang();
    const { surveys } = useSurveys();
    const [state, setState] = useState<State>(() => {
        return {
            mappingLabel: props.surveyComparisons.label,
            snackbar: initSnackbar,
            showPreviousPeriod: props.surveyComparisons.showPreviousPeriod,
            showInFirstColumn: props.surveyComparisons.showInFirstColumn,
            labelError: false,
            tabValue: TabOptions.items,
            confirmationDialog: false,
            remapGroup: initRemapGroup,
            anchorElement: initCoordinate,
            showInHeatmap: props.surveyComparisons.showInHeatmap
        };
    });
    const [isCollapseAllDisabled, setCollapseAllDisabled] = useState(true);
    const [isExpandAllDisabled, setExpandAllDisabled] = useState(false);
    const [lockConfirmationDialog, setLockConfirmationDialog] = useState(false);
    const orgChartService = useOrgChartService();

    const selectedSurvy = surveys.find(s => s.id === props.selectedSurveyId);
    const isLocked = (selectedSurvy && selectedSurvy.lockMappings) ?? false;

    const previousPeriodTableRef = useRef<PreviousPeriodTableHandle>(null);

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleCloseSnackbar = (): void => {
        setState({ ...state, snackbar: initSnackbar });
    };

    const setSnackbar = (snackbarContent: { isOpen: boolean; message: string }): void => {
        setState({ ...state, snackbar: snackbarContent });
    };

    const setShowInReports = (): void => {
        const showPP = !state.showPreviousPeriod;
        setState({ ...state, showPreviousPeriod: showPP });
        props.editSurveyComparison(
            props.surveyComparisons.id,
            props.surveyComparisons.label,
            showPP,
            props.surveyComparisons.showInFirstColumn,
            props.surveyComparisons.showInHeatmap
        );
    };

    const setShowInFirstPlace = (): void => {
        const showFC = !state.showInFirstColumn;
        setState({ ...state, showInFirstColumn: showFC });
        props.editSurveyComparison(
            props.surveyComparisons.id,
            props.surveyComparisons.label,
            state.showPreviousPeriod,
            showFC,
            props.surveyComparisons.showInHeatmap
        );
    };

    const setShowInHeatmapComparison = (): void => {
        const showHC = !state.showInHeatmap;
        setState({ ...state, showInHeatmap: showHC });
        props.editSurveyComparison(
            props.surveyComparisons.id,
            props.surveyComparisons.label,
            state.showPreviousPeriod,
            props.surveyComparisons.showInFirstColumn,
            showHC
        );
    };

    const handleMapLabelChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > maxCharacterForLabel) {
            setState({ ...state, labelError: true });
            return;
        }

        setState({ ...state, mappingLabel: event.target.value, labelError: false });
    };

    const handleChangeLabel = (): void => {
        if (!state.labelError) {
            props.editSurveyComparison(
                props.surveyComparisons.id,
                state.mappingLabel,
                state.showPreviousPeriod,
                state.showInFirstColumn,
                state.showInHeatmap
            );
        }
    };

    const handleDeleteMapping = (): void => {
        if (!isLocked) {
            setState({ ...state, confirmationDialog: true });
        } else {
            setLockConfirmationDialog(true);
        }
    };

    const handleConfirmationClicked = (): void => {
        setState({ ...state, confirmationDialog: false });
        props.deleteSurveyComparison(props.surveyComparisons.id);
    };

    const handleTabChange = (_: ChangeEvent<unknown>, newValue: TabOptions): void => {
        setState({ ...state, tabValue: newValue });
    };

    const updateMapTarget = (id: number): void => {
        setState({ ...state, remapGroup: { ...state.remapGroup, targetFieldId: id } });
    };
    const selectMapSource = (id: number, anchorPoints: Coordinate): void => {
        if (state.tabValue === TabOptions.items) {
            const findItemMapping = props.surveyComparisons.fieldMaps.find(
                s => s.isSurveyItem && s.sourceFieldId === id && s.targetFieldId
            );
            if (findItemMapping && findItemMapping.targetFieldId) {
                setState({
                    ...state,
                    remapGroup: { sourceFieldId: id, targetFieldId: findItemMapping.targetFieldId },
                    anchorElement: anchorPoints
                });
            } else {
                setState({
                    ...state,
                    remapGroup: { sourceFieldId: id, targetFieldId: null },
                    anchorElement: anchorPoints
                });
            }
        } else {
            const findContactMapping = props.surveyComparisons.valueMaps.find(
                c => c.sourceFieldId === id && c.targetFilters !== null && c.targetFilters.length > 0
            );

            if (findContactMapping && findContactMapping.targetFilters.length > 0) {
                setState({
                    ...state,
                    remapGroup: {
                        sourceFieldId: id,
                        targetFieldId: findContactMapping.targetFilters[0].id
                            ? findContactMapping.targetFilters[0].id
                            : null
                    },
                    anchorElement: anchorPoints
                });
            } else {
                setState({
                    ...state,
                    remapGroup: { sourceFieldId: id, targetFieldId: null },
                    anchorElement: anchorPoints
                });
            }
        }
    };

    const handleClosePopover = (): void => {
        setState({ ...state, anchorElement: initCoordinate, remapGroup: { sourceFieldId: null, targetFieldId: null } });
    };

    const filterSurveyComparison = (surveyComparisons: SurveyComparison, isSurveyItem: boolean): SurveyComparison => {
        return {
            ...surveyComparisons,
            fieldMaps: surveyComparisons.fieldMaps.filter((fm: FieldMap) => fm.isSurveyItem === isSurveyItem)
        };
    };

    const getMappedSurveyTargetItems = (): string[] => {
        const disabledItems: string[] = [];

        props.surveyComparisons.fieldMaps.forEach((i: FieldMap) => {
            if (i.targetFieldId && i.isSurveyItem) {
                disabledItems.push(i.targetFieldId.toString());
            }
        });
        return disabledItems;
    };

    const confirmRemap = (): void => {
        if (state.remapGroup.sourceFieldId && state.remapGroup.targetFieldId) {
            if (state.tabValue === TabOptions.contacts) {
                props.editSurveyComparisonField(
                    props.surveyComparisons.id,
                    +state.remapGroup.sourceFieldId,
                    +state.remapGroup.targetFieldId
                );
            } else {
                props.editSurveyItemMapping(
                    props.surveyComparisons.id,
                    +state.remapGroup.sourceFieldId,
                    +state.remapGroup.targetFieldId
                );
            }

            setState({ ...state, remapGroup: initRemapGroup, anchorElement: initCoordinate });
            return;
        }
        setState({ ...state, snackbar: { isOpen: true, message: lang.unableToRemapGroup } });
    };

    const isSurveyItemsFullyMapped = (): boolean => {
        let isFullyMapped = true;

        const notMappedItems = props.surveyComparisons?.fieldMaps?.filter(
            (fieldMap: FieldMap) => fieldMap.isSurveyItem && !fieldMap.targetFieldId
        );

        surveyItemsOptions.forEach(
            s =>
                s.subNode?.forEach(i => {
                    if (notMappedItems.find(nm => nm.sourceFieldId.toString() === i.id)) isFullyMapped = false;
                })
        );

        return isFullyMapped;
    };

    const isContactFieldFullyMapped =
        props.surveyComparisons?.valueMaps?.filter(
            (valueMap: ValueMap) => !valueMap.targetFilters || valueMap.targetFilters.length === 0
        ).length === 0;

    const handleOpenSecondMappingPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setAnchorEl(el);
    };
    const handleClosePPSecondPopover = (): void => {
        setAnchorEl(null);
    };

    const handleExpandAll = (): void => {
        setCollapseAllDisabled(false);
        setExpandAllDisabled(true);
        if (previousPeriodTableRef && previousPeriodTableRef.current) {
            previousPeriodTableRef.current.expandAll();
        }
    };
    const handleCollapseAll = (): void => {
        setCollapseAllDisabled(true);
        setExpandAllDisabled(false);
        if (previousPeriodTableRef && previousPeriodTableRef.current) {
            previousPeriodTableRef.current.collapseAll();
        }
    };
    const handleCollapseAllDisable = (): void => {
        setCollapseAllDisabled(true);
        setExpandAllDisabled(false);
    };
    const handleExpandAllDisable = (): void => {
        setCollapseAllDisabled(false);
        setExpandAllDisabled(true);
    };
    const handleCollapseAndExpandEnable = (): void => {
        setCollapseAllDisabled(false);
        setExpandAllDisabled(false);
    };
    const handleCloseConfirmation = (): void => {
        setLockConfirmationDialog(false);
    };
    const onTargetSurveyChange = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            props.handleTargetSurveyChange(+value);
        }
    };

    return (
        <>
            <div className={classes.container} data-testid="ppMappedContainer">
                <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2} pl={3} pr={3} pt={2}>
                    <Box width="400px">
                        <Select
                            label={lang.mappingToSurvey}
                            value={+props.targetSurveySelectedId}
                            onChange={onTargetSurveyChange}
                            items={props.allSurveyComparison.map((survey: SurveyComparison) => {
                                const targetSurvey = surveys.find(s => s.id === survey.targetSurveyId);
                                return {
                                    value: survey.targetSurveyId,
                                    name: targetSurvey?.translations[0].name || ""
                                };
                            })}
                        />
                    </Box>
                    <Button
                        variant="text"
                        onClick={(): void => {
                            if (props.selectedSurveyId && props.targetSurveySelectedId) {
                                orgChartService
                                    .exportDemographicMappingExcelReport(
                                        props.selectedSurveyId,
                                        props.targetSurveySelectedId,
                                        languageCode
                                    )
                                    .then(() => {
                                        setState({
                                            ...state,
                                            snackbar: {
                                                isOpen: true,
                                                message: lang.previousPeriodMappingExportCompleted
                                            }
                                        });
                                    })
                                    .catch(() => {
                                        setState({
                                            ...state,
                                            snackbar: { isOpen: true, message: lang.somethingWentWrong }
                                        });
                                    });
                            }
                        }}
                        data-testid="btn-export-pp-mapping"
                    >
                        {lang.export}
                    </Button>
                    <Button
                        variant="text"
                        onClick={(e): void => {
                            if (!isLocked) {
                                handleOpenSecondMappingPopover(e!);
                            } else {
                                setLockConfirmationDialog(true);
                            }
                        }}
                        disabled={props.allSurveyComparison.length >= 2 || isLocked}
                        data-testid="btn-pp-add-second-mapping"
                        style={{ color: isLocked ? "lightGrey" : "" }}
                    >
                        {lang.addSecondMapping}
                    </Button>
                </Box>
                <div className={classes.tabsContainerStyles}>
                    <Tabs sx={{ height: "56px" }} value={state.tabValue} onChange={handleTabChange}>
                        <Tab
                            label={lang.items}
                            data-testid="tabHandlerItems"
                            iconPosition="end"
                            icon={
                                isSurveyItemsFullyMapped() ? undefined : (
                                    <FiberManualRecordIcon fontSize="small" color="error" />
                                )
                            }
                            disabled={!(surveyItemsOptions.length > 0 || props.targetSurveyItemsOptions.length > 0)}
                        />
                        <Tab
                            label={lang.demographics}
                            data-testid="tabHandlerContacts"
                            iconPosition="end"
                            icon={
                                isContactFieldFullyMapped ? undefined : (
                                    <FiberManualRecordIcon fontSize="small" color="error" />
                                )
                            }
                            disabled={!(props.sourceContactFields.length > 0 || props.targetContactFields.length > 0)}
                        />
                        <Tab label={lang.properties} data-testid="tabHandlerProperties" />
                    </Tabs>
                    {(state.tabValue === TabOptions.items || state.tabValue === TabOptions.contacts) && (
                        <div className={classes.expandIcons}>
                            <IconButtonV4
                                path={mdiArrowExpandVertical}
                                onClick={handleExpandAll}
                                tooltip={lang.expandAll}
                                disabled={isExpandAllDisabled}
                                dataTestid="pp-items-expandAll"
                            />
                            <IconButtonV4
                                path={mdiArrowCollapseVertical}
                                onClick={handleCollapseAll}
                                tooltip={lang.collapseAll}
                                disabled={isCollapseAllDisabled}
                                dataTestid="pp-items-collapseAll"
                            />
                        </div>
                    )}
                </div>
                <div className={classes.tableContainer}>
                    {(state.tabValue === TabOptions.contacts || state.tabValue === TabOptions.items) && (
                        <div className={classes.tableHeader}>
                            <div className={classes.leftCol}>
                                <Typography variant="body2">{lang.currentSurvey}</Typography>
                            </div>
                            <div className={classes.rightCol}>
                                <Typography variant="body2">
                                    {lang.previousPeriodMapping} - {props.surveyComparisons.label}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {state.tabValue === TabOptions.properties && <div className={classes.tableHeaderPlaceHolder}></div>}
                    <div className={classes.tableContent} data-testid="ppMappedTable">
                        {state.tabValue === TabOptions.contacts && (
                            <PreviousPeriodTable
                                ref={previousPeriodTableRef}
                                surveyComparisons={filterSurveyComparison(props.surveyComparisons, false)}
                                sourceContactFields={props.sourceContactFields}
                                targetContactFields={props.targetContactFields}
                                editSurveyComparisonField={props.editSurveyComparisonField}
                                editSurveyComparisonValue={props.editSurveyComparisonValue}
                                setSnackBar={setSnackbar}
                                selectMapSource={selectMapSource}
                                setCollapseAllDisable={handleCollapseAllDisable}
                                setExpandAllDisable={handleExpandAllDisable}
                                setCollapseAndExpandEnable={handleCollapseAndExpandEnable}
                                remapGroup={state.remapGroup}
                                isLocked={isLocked}
                                setLockConfirmationDialog={(): void => setLockConfirmationDialog(true)}
                                clearSectionMapping={props.clearSectionMapping}
                            />
                        )}
                        {state.tabValue === TabOptions.items && (
                            <PreviousPeriodTable
                                ref={previousPeriodTableRef}
                                surveyComparisons={filterSurveyComparison(props.surveyComparisons, true)}
                                surveyItemsOptions={surveyItemsOptions}
                                targetSurveyItemsOptions={props.targetSurveyItemsOptions}
                                editSurveyComparisonField={props.editSurveyComparisonField}
                                editSurveyComparisonValue={props.editSurveyComparisonValue}
                                setSnackBar={setSnackbar}
                                selectMapSource={selectMapSource}
                                setCollapseAllDisable={handleCollapseAllDisable}
                                setExpandAllDisable={handleExpandAllDisable}
                                setCollapseAndExpandEnable={handleCollapseAndExpandEnable}
                                remapGroup={state.remapGroup}
                                isLocked={isLocked}
                                setLockConfirmationDialog={(): void => setLockConfirmationDialog(true)}
                                clearSectionMapping={props.clearSectionMapping}
                            />
                        )}
                        {state.tabValue === TabOptions.properties && (
                            <Box display="flex" flexDirection="column" height="100%" p={3}>
                                <Box display="flex" width={220} mb={3}>
                                    <TextField
                                        label={lang.mappingLabel}
                                        name={lang.mappingLabel}
                                        value={state.mappingLabel}
                                        onChange={handleMapLabelChange}
                                        error={state.labelError}
                                        onBlur={handleChangeLabel}
                                        data-testid="text-field-mapped-label"
                                    />
                                    <Tooltip
                                        title={lang.mappingLabelDescription[0] + "\n" + lang.mappingLabelDescription[1]}
                                    >
                                        <IconButton aria-label={lang.help}>
                                            <Help />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Checkbox
                                    checked={state.showPreviousPeriod}
                                    onChange={setShowInReports}
                                    disabled={false}
                                    label={lang.showInReports}
                                />
                                <Checkbox
                                    checked={state.showInFirstColumn}
                                    onChange={setShowInFirstPlace}
                                    disabled={props.allSurveyComparison.length === 1}
                                    label={lang.showInFirstReportColumn}
                                />
                                <Checkbox
                                    checked={state.showInHeatmap}
                                    onChange={setShowInHeatmapComparison}
                                    label={lang.showInHeatmapComparison}
                                />
                                <Box mt={2}>
                                    <Button
                                        variant="text"
                                        onClick={handleDeleteMapping}
                                        data-testid="btn-pp-delete-mapping"
                                        disabled={isLocked}
                                    >
                                        {lang.deleteMapping}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                open={state.confirmationDialog}
                onCancelClicked={(): void => setState({ ...state, confirmationDialog: false })}
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.deleteMapping}
                message={lang.thisActionWillPermanentlyRemoveMapping}
                confirmButtonText={lang.delete}
                cancelButtonVariant="text"
            />
            {state.tabValue === TabOptions.items &&
                state.anchorElement.top !== initCoordinate.top &&
                state.anchorElement.left !== initCoordinate.left && (
                    <ItemMappingDialog
                        targetSurveyItemsOptions={props.targetSurveyItemsOptions}
                        anchorPoints={state.anchorElement}
                        updateSelectOption={updateMapTarget}
                        confirmRemap={confirmRemap}
                        handleClosePopover={handleClosePopover}
                        remapGroup={state.remapGroup}
                        tabValue={state.tabValue}
                        disabledSurveyItems={getMappedSurveyTargetItems()}
                    />
                )}
            {state.tabValue === TabOptions.contacts &&
                state.anchorElement.top !== initCoordinate.top &&
                state.anchorElement.left !== initCoordinate.left && (
                    <DemographicMappingDialog
                        targetContactFields={props.targetContactFields}
                        anchorPoints={state.anchorElement}
                        updateSelectOption={updateMapTarget}
                        confirmRemap={confirmRemap}
                        handleClosePopover={handleClosePopover}
                        remapGroup={state.remapGroup}
                    />
                )}
            <Snackbar
                open={state.snackbar.isOpen}
                message={state.snackbar.message}
                handleClose={handleCloseSnackbar}
                autohideOff={false}
            />
            <PPSecondMappingPopup
                anchorEl={anchorEl}
                handleClosePopover={handleClosePPSecondPopover}
                onAutoMap={props.onAutoMap}
                selectedSurveyId={props.selectedSurveyId}
                targetSurveySelectedId={props.targetSurveySelectedId}
                allSurveyComparison={props.allSurveyComparison}
            />
            <ConfirmationDialog
                open={lockConfirmationDialog}
                onCancelClicked={handleCloseConfirmation}
                onConfirmationClicked={(): void => void 0}
                title={lang.editMapping}
                message={""}
                messgaeWithLink={
                    <>
                        <span>{lang.lockMessagePrefix}</span>
                        <a href={"mailto:support@talentmap.com"} target="_blank" rel="noopener noreferrer">
                            support@talentmap.com
                        </a>
                        <span>{lang.lockMessageSuffix}</span>
                    </>
                }
                cancelButtonText={lang.ok}
                cancelButtonVariant="delete"
                hideCancelButton
            />
        </>
    );
};

export default PreviousPeriodMapped;

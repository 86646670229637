import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { useLang } from "core/hooks";
import { theme } from "lib/theme";
import { Filter } from "components/filters/interfaces";
import FilterChip from "components/shared/FilterChip";

type Props = {
    responseCount: number;
    currentFilter: Filter;
    handleDeleteFilterItem: (filterItemOrder: number) => void;
};

const ShowBelowConfidentialityMessageWithCountAndFilter = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { responseCount, currentFilter, handleDeleteFilterItem } = props;
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" pl={3} pr={3} sx={{ borderBottom: `${theme.border.main}`, height: 50 }}>
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="h6" whiteSpace={"nowrap"}>
                        {lang.totalResponses} - {responseCount}
                    </Typography>
                    {currentFilter.items && currentFilter.items.length > 0 && (
                        <FilterChip items={currentFilter.items} onDelete={handleDeleteFilterItem} />
                    )}
                </Box>
            </Box>
            <Typography variant="h6" mt={3} ml={3} fontWeight="medium">
                {lang.confidentialityWarning}
            </Typography>
            <Typography variant="body2" mt={1.5} ml={3} mr={3}>
                {lang.confidentialityWarningDescription}
            </Typography>
        </Box>
    );
};

export default ShowBelowConfidentialityMessageWithCountAndFilter;

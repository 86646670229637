import { gql } from "@apollo/client";

export const userDashboardOnLoad = gql`
    query getSurveyItems($surveyId: ID!, $languageCode: String!, $includeQuestionTypes: Int!) {
        surveyItemFields(
            surveyId: $surveyId
            languageCode: $languageCode
            includeQuestionTypes: $includeQuestionTypes
        ) {
            title
            sectionId
            questions {
                questionId
                questionTitle
                questionType
                isFun
                canCalculate
                items {
                    fieldId
                    title
                    itemId
                    isIgnored
                    isHiddenForManagers
                    answers {
                        answerId
                        description
                    }
                }
            }
        }
    }
`;

import React, { useState, ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { ReactComponent as NoAccessSVG } from "../../assets/images/emptyPages/NoDeactivatedUser.svg";
import { useLang } from "core/hooks";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Typography } from "lib/typography";

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 5
};

const ErrorAccess = (): ReactElement => {
    const [redirect, setRedirect] = useState(false);
    const { lang } = useLang();

    const redirectToHome = (): void => {
        setRedirect(!redirect);
    };

    return redirect ? (
        <Redirect to={{ pathname: "/" }} />
    ) : (
        <Box data-testid="errorAccess" sx={containerStyle}>
            <Box mb={4}>
                <Typography variant="h1">{lang.accessDenied}</Typography>
            </Box>
            <Box m={1} mb={2}>
                <Typography variant="h3" color="GrayText" align="center">
                    {lang.userHasNoAccess}
                </Typography>
            </Box>
            <Typography variant="h3" color="GrayText" align="center">
                {lang.PleaseContactUsForLicense}
            </Typography>
            <Box mt={3.5} mb={7}>
                <Button data-testid="btn-go-homepage" onClick={redirectToHome}>
                    {lang.goHomepage}
                </Button>
            </Box>
            <NoAccessSVG />
        </Box>
    );
};

export default ErrorAccess;

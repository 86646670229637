import React from "react";

import MuiCard, { CardProps } from "@mui/material/Card";
import MuiCardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import MuiCardContent, { CardContentProps } from "@mui/material/CardContent";

const Card = (props: CardProps) => {
    const { children, ...rest } = props;
    return <MuiCard {...rest}>{children}</MuiCard>;
};

const CardHeader = (props: CardHeaderProps) => {
    return <MuiCardHeader {...props} />;
};

const CardContent = (props: CardContentProps) => {
    const { children, ...rest } = props;
    return <MuiCardContent {...rest}>{children}</MuiCardContent>;
};

export { Card, CardHeader, CardContent };

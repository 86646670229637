import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    wave: {
        position: "relative",
        overflow: "hidden",
        background: "#F6F6F6",
        borderRadius: "5px",
        height: "100%",
        width: "100%",
        "&::after": {
            animation: "$wave 1.6s linear 0.5s infinite",
            background: `linear-gradient(90deg, transparent, #F0F0F0, transparent)`,
            content: '""',
            position: "absolute",
            transform: "translateX(-100%)",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1
        }
    },
    "@keyframes wave": {
        "0%": {
            transform: "translateX(-100%)"
        },
        "60%": {
            transform: "translateX(100%)"
        },
        "100%": {
            transform: "translateX(100%)"
        }
    }
});

export const LoadingPlaceholder = (): ReactElement => {
    const classes = useStyles();
    return <div className={classes.wave} />;
};

import { Hierarchy } from "../interfaces";

export const emptyHierarchy: Hierarchy = {
    Fields: [{ fieldId: -1, name: "" }],
    HierarchyId: -1,
    IsDefault: false,
    Layout: { bars: [], numerics: [] },
    Name: "",
    CreatedById: -1
};

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { textDarkBackgroundMain } from "lib/palette";

export const useHeaderStyles = makeStyles(() => ({
    headerContainer: {
        width: "100%",
        height: "100%",
        background: theme.palette.secondary.main,
        display: "block",
        paddingRight: 24,
        paddingLeft: 16,
        boxSizing: "border-box",
        zIndex: 2
    },
    headerMenu: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        flexGrow: 1,
        marginLeft: 24
    },
    iconColor: {
        color: textDarkBackgroundMain
    },
    drawer: {
        backgroundColor: theme.palette.secondary.main
    },
    drawerPaper: {
        backgroundColor: theme.palette.secondary.main
    },
    list: {
        width: 250,
        backgroundColor: theme.palette.secondary.main
    }
}));

import { gql } from "@apollo/client";

export const usersQuery = gql`
    query users($orgId: ID!) {
        users(orgId: $orgId) {
            active
            id
            email
            isSiteAdmin
            isSurveyCreator
            isTalentMapAdmin
            isRestricted
            isCommentViewer
            byPassSimilarityCheck
            isInsightsEnabled
            participantEnabled
            name
            created
            userFilters {
                surveyId
                filters
            }
            lastActivity
        }
    }
`;

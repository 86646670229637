import { gql } from "@apollo/client";

export const heatmapOnLoad = gql`
    query getSurveyItemsWithContactFieldWithValues($surveyId: ID!, $languageCode: String!) {
        surveyItemFields(surveyId: $surveyId, languageCode: $languageCode) {
            title
            sectionId
            questions {
                questionId
                questionTitle
                questionType
                isFun
                canCalculate
                items {
                    fieldId
                    title
                    itemId
                    isIgnored
                    isHiddenForManagers
                    answers {
                        answerId
                        description
                    }
                }
            }
        }

        demographicFieldsAndValues(surveyId: $surveyId, languageCode: $languageCode) {
            fieldId
            fieldName
            fieldValues
            isHidden
        }
    }
`;

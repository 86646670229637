import React, { ReactElement } from "react";
import { LegendIcon } from "../shared/ColorLegend";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";
import { useLang } from "core/hooks";

const useStyle = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxSizing: "border-box"
    },
    legendItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "8px"
    }
}));
interface Props {
    lessMargin?: boolean;
    lessFontSize?: boolean;
}
enum colorLegends {
    unfavorable = "unfavorable",
    neutral = "neutral",
    favorable = "favorable"
}

export const SnapshotLegend = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyle(props);
    const legends = Object.values(colorLegends);

    return (
        <div className={classes.container}>
            {legends.map(l => (
                <div className={classes.legendItem} key={l}>
                    <LegendIcon variance={l} />
                    <Typography variant="body2" style={{ fontSize: props.lessFontSize ? 12 : 14, marginTop: "2px" }}>
                        {lang[l]}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

export enum InsightActions {
    SELECT_SURVEY = "SELECT_SURVEY",
    SET_STATUS = "SET_STATUS",
    OPEN_CLOSE_FILTER = "OPEN_FILTER",
    SET_FILTER = "SET_FILTER",
    SET_CONFIDENTIALITY_STATUS = "SET_CONFIDENTIALITY_STATUS",
    SET_INIT_DATA = "SET_INIT_DATA",
    SELECT_OUTCOME = "SELECT_OUTCOME",
    SELECT_KEYDRIVER = "SELECT_KEYDRIVER",
    SELECT_DEMOGRAPHIC = "SELECT_DEMOGRAPHIC",
    UPDATE_KEYDRIVER_LIST = "UPDATE_KEYDRIVER_LIST",
    UPDATE_KEYDRIVER_SCORE = "UPDATE_KEYDRIVER_SCORE",
    SELECT_EXPANDED_SECTION = "SELECT_EXPANDED_SECTION",
    UPDATE_CORRELATION_DATA = "UPDATE_CORRELATION_DATA",
    SELECT_KEYDIVER_SECTION = "SELECT_KEYDIVER_SECTION",
    UPDATE_KEYDRIVER_CORRELATION = "UPDATE_KEYDRIVER_CORRELATION",
    APPLY_PRESELECTION = "APPLY_PRESELECTION",
    UPDATE_SELECTED_KEY_ITEM = "UPDATE_SELECTED_KEY_ITEM"
}

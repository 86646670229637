import { BenchmarkMappingState } from "../interfaces";
import { Action } from "core/interfaces";
import { BenchmarkMappingActions } from "../actions";

export const benchmarkMappingReducer = (state: BenchmarkMappingState, action: Action): BenchmarkMappingState => {
    const { type, payload } = action;
    switch (type) {
        case BenchmarkMappingActions.SELECT_SECTION:
            return {
                ...state,
                sectionSelected: [...state.sectionSelected, payload.sectionSelected]
            };
        case BenchmarkMappingActions.DESELECT_SECTION:
            return {
                ...state,
                sectionSelected: [...state.sectionSelected].filter(
                    section => section.sectionId !== payload.sectionSelected.sectionId
                )
            };
        case BenchmarkMappingActions.DESELECT_ALL_SECTION:
            return {
                ...state,
                sectionSelected: [...payload.sectionSelected]
            };
        case BenchmarkMappingActions.SELECT_ALL_SECTION:
            return {
                ...state,
                sectionSelected: [...payload.sectionSelected]
            };
        case BenchmarkMappingActions.OPEN_MAPPINGDIALOG:
            return {
                ...state,
                anchorElement: payload.anchorElement,
                surveyItemIdSelected: payload.surveyItemIdSelected
            };
        case BenchmarkMappingActions.DISABLE_AUTOMAP:
            return {
                ...state,
                disableAutoMap: payload.disableAutoMap
            };
        case BenchmarkMappingActions.UPDATE_SELECTED_SECTIONS:
            return {
                ...state,
                sectionSelected: payload.sectionSelected
            };
        case BenchmarkMappingActions.SELECT_BENCHMARK:
            return {
                ...state,
                benchmarkSelected: payload.benchmark,
                benchmarkSections: payload.benchmarkSections
            };
        case BenchmarkMappingActions.UPDATE_ITEMS_MAPPED:
            return {
                ...state,
                benchmarkMappings: payload.benchmarkMappings
            };
        case BenchmarkMappingActions.SHOW_AUTOMAP_CONFIRM_DIALOG:
            return {
                ...state,
                confirmAutoMapDialogOpen: payload.showAutomapConfirmDialog
            };
        case BenchmarkMappingActions.SET_INITIAL_STATE:
            return {
                ...state,
                surveySections: payload.surveySections,
                benchmarks: payload.benchmarks,
                benchmarkMappings: payload.benchmarkMappings,
                benchmarkSelected: payload.benchmarkSelected,
                benchmarkSections: payload.benchmarkSections
            };
        case BenchmarkMappingActions.CONFIRM_AUTOMAP:
            return {
                ...state,
                confirmAutoMapDialogOpen: false,
                benchmarkMappings: payload.benchmarkMappings
            };
        case BenchmarkMappingActions.SET_SURVEY_SETTINGS: {
            return {
                ...state,
                surveySettings: payload.surveySettings
            };
        }
        case BenchmarkMappingActions.SET_DIALOG: {
            return {
                ...state,
                confirmationDialogContent: { ...payload.confirmationDialogContent }
            };
        }
        case BenchmarkMappingActions.CLEAR_BENCHMARK: {
            return {
                ...state,
                benchmarkMappings: payload.benchmarkMappings
            };
        }
        default:
            return state;
    }
};

import React, { ReactElement, useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import CustomValueCell from "../shared/CustomValueCell";
import { SurveySection } from "../../context/interfaces";
import { Card } from "managerPortal/components/cards/interfaces";
import { emptyBarColor, favorableColor, neutralColor, unfavorableColor } from "../../styles/GlobalStyles";
import StackedBar from "../stackedBar/StackedBar";
import { ReportRoutes } from "routes/enums";
import SnapshotItemsDetails from "managerPortal/components/snapshotReport/SnapshotItemsDetails";
import { lightGreyColor } from "managerPortal/styles/GlobalStyles";
import { SectionData, SnapShotDefinition } from "./content/types";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";

type styleProps = {
    stackbarWidth: number;
    isExpanded: boolean;
    cellsWidth: number;
};

const useStyles = makeStyles(() => ({
    listItemWithDetails: {
        display: "flex",
        flexDirection: "column",
        borderLeft: theme.border.main
    },
    listItem: {
        display: "grid",
        gridTemplateRows: "56px",
        gridTemplateColumns: "1fr auto auto auto auto auto",
        borderBottom: theme.border.main,
        borderRight: theme.border.main
    },
    cellInfo: {
        gridRow: "1 / 2",
        gridColumn: "1 / 2",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    listItemBar: (props: styleProps) => {
        return {
            borderLeft: theme.border.main,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: props.stackbarWidth,
            width: props.stackbarWidth,
            maxWidth: props.stackbarWidth,
            boxSizing: "border-box",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            "& div": {
                height: 28
            }
        };
    },
    cellDemo: {
        gridRow: "1 / 2",
        gridColumn: "4 / 5",
        boxSizing: "border-box",
        maxWidth: 78,
        minWidth: 78,
        width: 78
    },
    cellOverall: {
        gridRow: "1 / 2",
        gridColumn: "5 / 6",
        boxSizing: "border-box",
        maxWidth: 78,
        minWidth: 78,
        width: 78
    },
    cellPreviousPeriod: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gridRow: "1 / 2",
        gridColumn: "2 / 4",
        boxSizing: "border-box",
        minWidth: 78,
        maxWidth: 157
    },
    individualCellPreviousPeriod: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: theme.border.main,
        height: 56,
        width: 78,
        minWidth: 78,
        maxWidth: 78
    },
    cellBenchmark: {
        gridRow: "1 / 2",
        gridColumn: "6 / 7",
        boxSizing: "border-box",
        width: 78,
        minWidth: 78,
        maxWidth: 78
    },
    cell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: theme.border.main
    },
    noDataTypography: {
        color: theme.palette.text.secondary,
        textAlign: "left"
    },
    expandMoreIcon: (styleProps: styleProps) => ({
        transform: styleProps.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        marginRight: theme.spacing(1),
        color: theme.palette.action.active
    }),
    cellInfoTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: theme.spacing(1),
        "& p": {
            fontWeight: 500
        }
    },
    cellInfoTitleTextDialog: (styleProps: styleProps) => ({
        display: "block",
        maxWidth: `calc(980px - ${styleProps.cellsWidth}px - ${styleProps.stackbarWidth}px)`,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }),
    cellInfoTitleText: (styleProps: styleProps) => ({
        display: "block",
        maxWidth: `calc(1500px - ${styleProps.cellsWidth}px - ${styleProps.stackbarWidth}px)`,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }),
    itemDetails: {
        display: "flex",
        flexDirection: "column",
        borderBottom: theme.border.main
    },
    itemDetailsDivider: {
        height: 16,
        background: lightGreyColor
    },
    itemDetailsWrap: {
        display: "flex",
        width: "100%"
    },
    itemDetailsVerticalDivider: {
        width: 16,
        background: lightGreyColor
    }
}));

type Props = {
    selectedCard: Card;
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    surveySections: SurveySection[];
    showOrgOverall: boolean;
    showDemographic: boolean;
    selectedSurveySections: SurveySection[];
    section: SectionData;
    snapShotDefinition: SnapShotDefinition;
    setCardElementSelected: (a: SurveySection["sectionId"]) => void;
    setCardElementUnSelected: (a: SurveySection["sectionId"]) => void;
};

const ShowSectionInfo = (props: Props): ReactElement => {
    const location = useLocation();
    const isInDialog = location.pathname.includes(ReportRoutes.orgChart);
    const {
        selectedCard,
        showBenchmarks,
        showPreviousPeriod,
        surveySections,
        showOrgOverall,
        showDemographic,
        selectedSurveySections,
        section,
        snapShotDefinition,
        setCardElementSelected,
        setCardElementUnSelected
    } = props;

    const { lang } = useLang();

    let displayValues = [0];
    let displayPercentage = true;

    let colors = [emptyBarColor];
    let total = 0;
    if (section.scores) {
        total = section.scores.reduce((a: number, b: number): number => a + b, 0);
        displayValues = total > 0 ? section.scores : [0];

        if (total <= 0) {
            displayPercentage = false;
        } else {
            colors = [unfavorableColor, neutralColor, favorableColor];
        }
    }

    const numberOfColumnsWithoutPreviousPeriod = [showDemographic, showBenchmarks, showOrgOverall].filter(
        Boolean
    ).length;
    const numberOfColumnsWithPreviousPeriod =
        [showDemographic, showBenchmarks, showOrgOverall].filter(Boolean).length +
        snapShotDefinition.previousPeriodInfo.length;
    const numberOfColumns = showPreviousPeriod
        ? numberOfColumnsWithPreviousPeriod
        : numberOfColumnsWithoutPreviousPeriod;
    const stackbarWidth = 420 - (numberOfColumns - 1) * 10;
    const cellsWidth = 78 * numberOfColumns;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const classes = useStyles({ stackbarWidth, isExpanded, cellsWidth });

    const handleExpandSection = (sectionId: SurveySection["sectionId"]): void => {
        if (!isExpanded) {
            setCardElementSelected(sectionId);
        } else {
            setCardElementUnSelected(sectionId);
        }
    };

    useEffect(() => {
        const isExpanded =
            selectedSurveySections &&
            selectedSurveySections.map(section => section.sectionId).includes(section.sectionId);
        setIsExpanded(isExpanded);
    }, [selectedSurveySections]);

    return (
        <div className={classes.listItemWithDetails}>
            <div
                className={classes.listItem}
                key={section.sectionId}
                data-testid={`row-${section.title.replace(" ", "")}`}
                onClick={(): void => handleExpandSection(section.sectionId)}
            >
                <div className={classes.cellInfo}>
                    <div className={classes.cellInfoTitle}>
                        <ExpandMoreIcon className={classes.expandMoreIcon} />
                        <Tooltip title={section.title} placement="bottom">
                            <Typography
                                variant="body1"
                                className={isInDialog ? classes.cellInfoTitleTextDialog : classes.cellInfoTitleText}
                            >
                                {section.title}
                            </Typography>
                        </Tooltip>
                    </div>
                    <div className={classes.listItemBar}>
                        {section.scores ? (
                            <StackedBar
                                values={displayValues}
                                colors={colors}
                                displayAllPercentage={displayPercentage}
                                sectionLevel
                                percentageFontSize={14}
                            />
                        ) : (
                            <Typography variant="body1" className={classes.noDataTypography}>
                                {lang.noData}
                            </Typography>
                        )}
                    </div>
                </div>

                {showDemographic && section.scores && (
                    <div className={`${classes.cellDemo} ${classes.cell}`}>
                        <CustomValueCell value={section.demographicScore} />
                    </div>
                )}
                {showDemographic && !section.scores && (
                    <div className={`${classes.cellDemo} ${classes.cell}`}>
                        <Typography>-</Typography>
                    </div>
                )}
                {showOrgOverall && section.scores && (
                    <div className={`${classes.cellOverall} ${classes.cell}`}>
                        <CustomValueCell value={section.overallScore} />
                    </div>
                )}
                {showOrgOverall && !section.scores && (
                    <div className={`${classes.cellOverall} ${classes.cell}`}>
                        <Typography>-</Typography>
                    </div>
                )}

                {showPreviousPeriod && !section.scores && (
                    <div className={`${classes.cellPreviousPeriod}`}>
                        {snapShotDefinition.previousPeriodInfo.map((_, i) => (
                            <div
                                key={i}
                                className={`${classes.individualCellPreviousPeriod}`}
                                data-testid={`row-${section.title.replace(" ", "")}-pp-${i}`}
                            >
                                <Typography>-</Typography>
                            </div>
                        ))}
                    </div>
                )}
                {showPreviousPeriod && section.scores && (
                    <div className={`${classes.cellPreviousPeriod}`}>
                        {snapShotDefinition.previousPeriodInfo.map((item, index) => (
                            <div
                                key={index}
                                className={classes.individualCellPreviousPeriod}
                                data-testid={`row-${section.title.replace(" ", "")}-pp-${index}`}
                            >
                                <CustomValueCell value={section[item.previousPeriodMap]} />
                            </div>
                        ))}
                    </div>
                )}
                {showBenchmarks && section.scores && total > 0 && (
                    <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                        <CustomValueCell value={section.benchmarkScore} />
                    </div>
                )}
                {showBenchmarks && !section.scores && (
                    <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                        <Typography>-</Typography>
                    </div>
                )}
                {showBenchmarks && total <= 0 && (
                    <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                        <Typography>-</Typography>
                    </div>
                )}
            </div>
            {isExpanded && (
                <div className={classes.itemDetails} data-testid={`row-${section.title.replace(" ", "")}-details`}>
                    <div className={classes.itemDetailsDivider}></div>
                    <div className={classes.itemDetailsWrap}>
                        <div className={classes.itemDetailsVerticalDivider}></div>
                        <SnapshotItemsDetails
                            selectedCard={selectedCard}
                            surveySections={surveySections}
                            showDemographic={showDemographic}
                            showOrgOverall={showOrgOverall}
                            showBenchmarks={showBenchmarks}
                            showPreviousPeriod={showPreviousPeriod}
                            section={section}
                            snapShotDefinition={snapShotDefinition}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowSectionInfo;

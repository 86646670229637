import React from "react";

import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";

const Drawer = (props: DrawerProps) => {
    const { children, ...rest } = props;
    return <MuiDrawer {...rest}>{children}</MuiDrawer>;
};

export { Drawer };

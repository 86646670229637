import React, { ReactElement } from "react";

import { useLang } from "core/hooks";
import { Menu } from "lib/menu";
import { MenuItem } from "lib/menu-item";
import { useUser } from "core/context/user/useUser";

interface Props {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onRevertToDefault: () => void;
    onSetAsDefault: () => void;
    onClearCard: () => void;
    disableRevertToDefault: boolean;
    disableSetDefault: boolean;
    disableClearCard: boolean;
}

const ConfigureCardMenu = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();

    const userCanSetDefault = user.isTalentMapAdmin || user.isSiteAdmin;

    return (
        <Menu
            data-testid="menu-configure-card"
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={props.onClose}
        >
            <MenuItem
                data-testid="menu-item-set-as-default"
                onClick={props.onSetAsDefault}
                disabled={props.disableSetDefault || !userCanSetDefault}
            >
                {lang.setAsDefault}
            </MenuItem>
            <MenuItem
                data-testid="menu-item-revert-to-default"
                disabled={props.disableRevertToDefault}
                onClick={props.onRevertToDefault}
            >
                {lang.revertToDefault}
            </MenuItem>
            <MenuItem data-testid="menu-item-clear-card" disabled={props.disableClearCard} onClick={props.onClearCard}>
                {lang.clearCard}
            </MenuItem>
        </Menu>
    );
};

export default ConfigureCardMenu;

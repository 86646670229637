import React, { ReactElement } from "react";
import { useLang } from "core/hooks";
import { makeStyles } from "@mui/styles";
import { PanelItem, DashboardData } from "./interface";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { itemHeight, itemWidth } from "./DashboardItem";
import { emptyBarColor, favorableColor, neutralColor, unfavorableColor } from "managerPortal/styles/GlobalStyles";
import StackedBar from "managerPortal/components/stackedBar/StackedBar";
import { HIDE_ELEMENTS } from "managerPortal/enums/Enums";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

type CellTypeStyleProps = {
    xPosition: number;
    yPosition: number;
    numberOfColumn: number;
    numberOfRow: number;
    height: number;
    width: number;
    numberOfRows: number;
};

interface Props {
    testid: number;
    item: PanelItem;
    dashboardData: DashboardData;
    responseCount: number;
}

const useStyles = makeStyles(() => ({
    cell: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            position: "absolute",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`,
            height: itemHeight * styleProps.height,
            width: itemWidth * styleProps.width
        };
    },
    cellBelowThreshold: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "absolute",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`,
            height: itemHeight * styleProps.height,
            width: itemWidth * styleProps.width
        };
    },
    chart: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "90%"
    },
    cellText: (styleProps: CellTypeStyleProps) => {
        return {
            display: "block",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            textAlign: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: styleProps.height > 4 ? 20 : 16,
            fontWeight: 500,
            paddingTop: 16
        };
    },
    cellTextBelowThreshold: (styleProps: CellTypeStyleProps) => {
        return {
            display: "block",
            width: styleProps.width * itemWidth * 0.9,
            maxWidth: styleProps.width * itemWidth * 0.9,
            textAlign: "center",
            fontSize: styleProps.height > 4 ? 20 : 16,
            fontWeight: 400,
            paddingTop: 16
        };
    },
    chartHeader: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20%",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth
        };
    },
    chartHeaderDimension: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            width: (styleProps.width * itemWidth) / 3,
            maxWidth: (styleProps.width * itemWidth) / 3,
            paddingLeft: 16
        };
    },
    chartHeaderLegend: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (7 * (styleProps.width * itemWidth)) / 12,
            maxWidth: (7 * (styleProps.width * itemWidth)) / 12,
            justifyContent: "center"
        };
    },
    chartHeaderImpact: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12,
            justifyContent: "center"
        };
    },
    keyDriver: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${70 / styleProps.numberOfRows}%`,
            maxHeight: "40%",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth
        };
    },
    keyDriverDimension: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.width * itemWidth) / 3,
            maxWidth: (styleProps.width * itemWidth) / 3,
            justifyContent: "flex-end",
            textAlign: "right",
            paddingLeft: 16
        };
    },
    keyDriverChartWrapper: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (7 * (styleProps.width * itemWidth)) / 12,
            maxWidth: (7 * (styleProps.width * itemWidth)) / 12,
            justifyContent: "center"
        };
    },
    keyDriverChart: {
        display: "flex",
        height: "80%",
        alignItems: "center",
        width: "95%",
        maxWidth: "95%",
        justifyContent: "center"
    },
    keyDriverImpact: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12,
            justifyContent: "center"
        };
    }
}));

const CellKeyDriver = (props: Props): ReactElement => {
    const { item, dashboardData, testid } = props;
    const getNumberOfRows = (): number => {
        const sectionOrItemFound = JSON.parse(item.props).isSection
            ? dashboardData.sections[JSON.parse(item.props).id]
            : dashboardData.items[JSON.parse(item.props).id];
        return sectionOrItemFound?.keyDrivers?.length ?? 1;
    };

    const classes = useStyles({
        xPosition: item.xPosition,
        yPosition: item.yPosition,
        numberOfColumn: GRID_COL_LENGTH,
        numberOfRow: GRID_ROW_LENGTH,
        height: item.height,
        width: item.width,
        numberOfRows: getNumberOfRows()
    });
    const { lang, languageCode } = useLang();

    const getDimensionName = (sectionId: number): string => {
        const sectionFound = dashboardData.sections[sectionId];
        return sectionFound ? sectionFound.text : "";
    };

    const calculateTotal = (unfavorablePercent: number, neutralPercent: number, favorablePercent: number): number => {
        let total = 0;
        total = [unfavorablePercent, neutralPercent, favorablePercent].reduce(
            (a: number, b: number): number => a + b,
            0
        );
        return total;
    };

    const getDimensionDisplayValues = (sectionId: number): number[] => {
        const sectionFound = dashboardData.sections[sectionId];
        if (!sectionFound) return [0];
        if (
            calculateTotal(
                sectionFound.unfavorablePercent,
                sectionFound.neutralPercent,
                sectionFound.favorablePercent
            ) <= 0
        )
            return [0];
        return [sectionFound.unfavorablePercent, sectionFound.neutralPercent, sectionFound.favorablePercent];
    };

    const getDimensionColors = (sectionId: number): string[] => {
        const sectionFound = dashboardData.sections[sectionId];
        if (!sectionFound) return [emptyBarColor];
        if (
            calculateTotal(
                sectionFound.unfavorablePercent,
                sectionFound.neutralPercent,
                sectionFound.favorablePercent
            ) <= 0
        )
            return [emptyBarColor];
        return [unfavorableColor, neutralColor, favorableColor];
    };
    const getDimensionDisplayPercentage = (sectionId: number): boolean => {
        const sectionFound = dashboardData.sections[sectionId];
        if (!sectionFound) return false;
        return (
            calculateTotal(
                sectionFound.unfavorablePercent,
                sectionFound.neutralPercent,
                sectionFound.favorablePercent
            ) > 0
        );
    };

    const getChart = (id: number, isSection: boolean): ReactElement => {
        const sectionOrItemFound = isSection ? dashboardData.sections[id] : dashboardData.items[id];
        if (sectionOrItemFound) {
            return (
                <div className={classes.chart}>
                    <div className={classes.chartHeader}>
                        <div className={classes.chartHeaderDimension}>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                                {lang.keyDrivers}
                            </Typography>
                        </div>
                        <div className={classes.chartHeaderLegend}></div>
                        <div className={classes.chartHeaderImpact}>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                                {lang.impact}
                            </Typography>
                        </div>
                    </div>
                    {sectionOrItemFound.keyDrivers ? (
                        sectionOrItemFound.keyDrivers.map((keyDriver, index) => (
                            <div
                                key={index}
                                className={classes.keyDriver}
                                data-testid={`keyDriver-${getDimensionName(keyDriver.sectionId)}`}
                            >
                                <div className={classes.keyDriverDimension}>
                                    <Typography variant="body2">{getDimensionName(keyDriver.sectionId)}</Typography>
                                </div>
                                <div className={classes.keyDriverChartWrapper}>
                                    <div className={classes.keyDriverChart}>
                                        {getDimensionDisplayValues(keyDriver.sectionId) ? (
                                            <StackedBar
                                                values={getDimensionDisplayValues(keyDriver.sectionId)}
                                                colors={getDimensionColors(keyDriver.sectionId)}
                                                displayAllPercentage={getDimensionDisplayPercentage(
                                                    keyDriver.sectionId
                                                )}
                                                percentageFontSize={14}
                                                sectionLevel
                                            />
                                        ) : (
                                            <Typography variant="body2">{lang.noData}</Typography>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.keyDriverImpact}>
                                    <Typography variant="body2" style={{ fontWeight: 500, fontSize: 14 }}>
                                        {keyDriver.correlations[0].toFixed(2).replace(/0./, ".")}
                                    </Typography>
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    };
    if (props.responseCount && props.responseCount < HIDE_ELEMENTS.correlationLessThan) {
        return (
            <div className={classes.cellBelowThreshold} data-testid={`panel-${testid}`}>
                <Tooltip
                    title={item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    placement={"bottom"}
                >
                    <Typography variant="body2" className={classes.cellText}>
                        {item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    </Typography>
                </Tooltip>
                <Typography variant="body2" className={classes.cellTextBelowThreshold}>
                    {lang.belowKeyDriverThreshold}
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.cell} data-testid={`panel-${testid}`}>
            <Tooltip
                title={item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                placement={"bottom"}
            >
                <Typography variant="body2" className={classes.cellText} style={{ fontWeight: 500 }}>
                    {item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                </Typography>
            </Tooltip>
            {getChart(JSON.parse(item.props).id, JSON.parse(item.props).isSection)}
        </div>
    );
};

export default CellKeyDriver;

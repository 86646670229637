import React, { ReactElement, useState, useEffect } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { HeatmapDisplayValue, ResponseCount } from "./interface";
import { heatmapCellClassMapper } from "./helper";
import { useHeatmapAxisGridStyles } from "./heatmapGridAxis.style";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { ExpandCollapseBtnContainer } from "./HeatmapAction";
import { colorClasses } from "./heatmap.init";
import { useLoading, useLang } from "core/hooks";
import { Filter } from "components/filters/interfaces";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";

interface Props {
    gridData: HeatmapDisplayValue[];
    sectionData: HeatmapDisplayValue[];
    sectionOverall: HeatmapDisplayValue[];
    itemOverall: HeatmapDisplayValue[];
    responseCount: ResponseCount[];
    selectedTarget: string;
    isFilteredOverallSelected: boolean;
    shouldUpdate: boolean;
    switchAxis: () => void;
    currentFilter: Filter;
    showDeltaValue: boolean;
    switchDelta: (a: boolean) => void;
    isPreviousPeriodSelected: boolean;
    updateStatus: () => void;
}

interface heatmapColumnCell {
    title: string;
    id: number;
}

const HeatGridAxis = (props: Props): ReactElement => {
    const { setLoading } = useLoading();
    const { lang } = useLang();
    const { gridData, sectionData, sectionOverall, itemOverall, responseCount, switchAxis } = props;
    const [displayHeader, setDisplayHeader] = useState<string[]>([]);
    const [displayRows, setDisplayRows] = useState<heatmapColumnCell[]>([{ title: lang.responses, id: -1 }]);
    const [expandedColumns, setExpandedColumns] = useState<heatmapColumnCell[]>([]);
    const [firstLevelRow, setFirstLevelRow] = useState<heatmapColumnCell[]>([]);
    const [tableDimension, setTableDimension] = useState<{ height: number; width: number }>({
        height: displayRows.length * 70 + 500,
        width: displayHeader.length * 80 + 250
    });
    const [nestedRow, setNestedRow] = useState<{ firstLevel: heatmapColumnCell; children: heatmapColumnCell[] }[]>([]);
    const responseTotalRow = props.selectedTarget ? `${props.selectedTarget} ` : lang.overallSS;
    const valueNotFound = "-";
    const [scrollingStatus, setScrollingStatus] = useState<{ left: boolean; top: boolean }>({
        left: false,
        top: false
    });

    const classes = useHeatmapAxisGridStyles({
        width: tableDimension.width,
        height: tableDimension.height,
        floatingRowWidth: displayHeader.length * 53 + 220,
        isScrollingDown: scrollingStatus.top,
        showFilterTags: props.currentFilter.items.length > 0,
        isPreviousPeriodSelected: props.isPreviousPeriodSelected
    });

    useEffect(() => {
        //the first row is response count
        const row: heatmapColumnCell[] = [{ title: lang.responses, id: -1 }];
        //the first cell is demogrphic group or overall
        // if compare to previous period, hide overall column
        const header = props.isPreviousPeriodSelected
            ? [...new Set(gridData.map(d => d.source))].sort()
            : [responseTotalRow, ...[...new Set(gridData.map(d => d.source))].sort()];
        //group section and its questions for expanding rows
        const nestedRows: { firstLevel: heatmapColumnCell; children: heatmapColumnCell[] }[] = [];
        gridData.forEach(d => {
            //push all avaliable rows
            if (row.findIndex(i => i.id === d.targetId) === -1) {
                row.push({ title: d.target, id: d.targetId });
            }
        });
        setDisplayRows(row);
        //first level rows are sections
        setFirstLevelRow(row);

        //push nestedRows
        gridData.forEach(d => {
            const rows: { firstLevel: heatmapColumnCell; children: heatmapColumnCell[] } = {
                firstLevel: { title: d.target, id: d.targetId },
                children: []
            };
            //get all question nodes
            const children = props.gridData.filter(f => f.target === d.target && f.subTarget);

            if (nestedRows.length > 0 && nestedRows.findIndex(i => i.firstLevel.id === d.targetId) === -1) {
                if (children.length > 0) {
                    children.forEach(c => {
                        if (
                            rows.children.findIndex(h => h.id === c.subTargetId) === -1 &&
                            c.subTarget &&
                            c.subTargetId
                        ) {
                            rows.children.push({ title: c.subTarget, id: c.subTargetId });
                        }
                    });
                }
                nestedRows.push(rows);
            }

            if (nestedRows.length === 0) {
                if (children.length > 0) {
                    children.forEach(c => {
                        if (
                            rows.children.findIndex(h => h.id === c.subTargetId) === -1 &&
                            c.subTarget &&
                            c.subTargetId
                        ) {
                            rows.children.push({ title: c.subTarget, id: c.subTargetId });
                        }
                    });
                }
                nestedRows.push(rows);
            }
        });

        setNestedRow(nestedRows);

        setDisplayHeader(header);

        props.updateStatus();

        return (): void => {
            setDisplayHeader([]);
            setDisplayRows([]);
            setExpandedColumns([]);
            setFirstLevelRow([]);
            setNestedRow([]);
        };
    }, [props.shouldUpdate]);

    useEffect(() => {
        setTableDimension({
            height: displayRows.length * 60 + 255,
            width: displayHeader.length * 80 + 250
        });
    }, [displayHeader, displayRows]);

    const isFirstLevelRow = (id: number): boolean => {
        if (id === -1) return true;
        const rowFound = firstLevelRow.findIndex(h => h.id === id);
        return rowFound !== -1;
    };

    const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
        const div = e.target as HTMLDivElement;

        const isScrolling = { left: div.scrollLeft !== 0, top: div.scrollTop !== 0 };

        //flag scrolling event
        if (scrollingStatus.left !== isScrolling.left || scrollingStatus.top !== isScrolling.top) {
            setScrollingStatus(isScrolling);
        }
    };

    const responseCountFinder = (header: string): number | string => {
        let lookupHeader = header;
        if (header === responseTotalRow) lookupHeader = lang.overallSS;
        const count = responseCount.find(re => re.fieldName === lookupHeader);

        return count ? count.count : valueNotFound;
    };

    const valueFinder = (column: heatmapColumnCell, header: string, onlyDelta: boolean): number | string => {
        const lookupRow = header === responseTotalRow ? lang.overallSS : header;
        let lookup = gridData.find(f => f.source === lookupRow && f.subTargetId === column.id);
        const sectionOverallValue = sectionOverall.find(f => f.targetId === column.id);

        const itemOverallValue = itemOverall.find(f => f.targetId === column.id);

        const sectionValue = sectionData.find(f => f.targetId === column.id && f.source === lookupRow);

        if (header === responseTotalRow) {
            // indicate it is overall header
            if (lookup === undefined) {
                lookup = sectionOverallValue;
            }
            if (lookup === undefined) {
                lookup = itemOverallValue;
            }
        } else {
            if (lookup === undefined) {
                lookup = sectionValue;
            }
        }

        if (lookup) {
            if (column.title === lookup.target) {
                if (lookup.delta === null && lookup.favorable === null) {
                    return valueNotFound;
                }
                if (sectionOverallValue && sectionOverallValue?.delta === 0) {
                    return valueNotFound;
                } else {
                    if (onlyDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (lookup.showDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (!lookup.showDelta && lookup.favorable !== null) {
                        return lookup.favorable;
                    } else {
                        return valueNotFound;
                    }
                }
            }
            if (column.title === lookup.subTarget) {
                if (itemOverallValue && itemOverallValue?.delta === 0) {
                    return valueNotFound;
                } else {
                    if (onlyDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (lookup.showDelta && lookup.delta !== null) {
                        return lookup.delta;
                    } else if (!lookup.showDelta && lookup.favorable !== null) {
                        return lookup.favorable;
                    } else {
                        return valueNotFound;
                    }
                }
            }
            return valueNotFound;
        } else return valueNotFound;
    };

    const handleExpandAll = (): void => {
        setLoading(true);
        let expandAllRow: heatmapColumnCell[] = [{ title: lang.responses, id: -1 }];
        nestedRow.forEach(n => {
            const rowGroup = [n.firstLevel, ...n.children];
            expandAllRow = [...expandAllRow, ...rowGroup];
        });
        setDisplayRows(expandAllRow);
        setExpandedColumns([...firstLevelRow]);
        setLoading(false);
    };

    const handleCollapseAll = (): void => {
        const updatedRows = [...firstLevelRow];
        setDisplayRows(updatedRows);
        setExpandedColumns([]);
    };

    const handleColumnRowClick = (rowId: number): void => {
        const isRowExpanded = expandedColumns.find(e => e.id === rowId) !== undefined;
        const isRowFirstLevel = firstLevelRow.find(f => f.id === rowId) !== undefined;

        if (isRowFirstLevel) {
            const rowFound = nestedRow.find(n => n.firstLevel.id === rowId);
            if (rowFound) {
                const children = rowFound.children;
                if (children && children.length > 0) {
                    if (!isRowExpanded) {
                        const motherIndex = displayRows.findIndex(h => h.id === rowId);
                        const firstHalf = [...displayRows].slice(0, motherIndex + 1);
                        const secondHalf = [...displayRows].slice(motherIndex + 1, displayRows.length);

                        setDisplayRows([...firstHalf, ...children, ...secondHalf]);

                        setExpandedColumns([...expandedColumns, rowFound.firstLevel]);
                    } else {
                        const rows = [...displayRows].filter(f => {
                            return children.findIndex(c => c.id === f.id) === -1;
                        });

                        setDisplayRows([...rows]);
                        const expanded = expandedColumns.filter(ex => ex.id !== rowId);

                        setExpandedColumns([...expanded]);
                    }
                }
            }
        }
    };
    const isRowExpanded = (id: number): boolean => {
        return expandedColumns.find(e => e.id === id) !== undefined;
    };
    const isRowExpandable = (id: number): boolean => {
        return nestedRow.find(h => h.firstLevel.id === id && h.children.length > 0) !== undefined;
    };

    const tooltipTitle = (h: string, r: string): ReactElement => {
        return (
            <>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {h}
                </Typography>
                <Typography variant="subtitle2" className={classes.dataCell_tooltip}>
                    {r}
                </Typography>
            </>
        );
    };
    if (displayRows.length === 0) {
        return <></>;
    }

    return (
        <div className={classes.container}>
            <div
                className={classes.tableContainer}
                data-testid={"tableContainer"}
                onScroll={(e): void => {
                    scrollHandler(e);
                }}
            >
                {(scrollingStatus.top || scrollingStatus.left) && (
                    <div className={classes.fixedOverallRes_horizontal}>
                        <div className={classes.floatingRowHeaderCellOverall_horizontal} data-testid={"Response"}>
                            <TruncateDisplay maxLabelLength={23} title={responseTotalRow}>
                                <Typography variant="body1" className={classes.rowHeaderText}>
                                    {displayRows[0].title}
                                </Typography>
                            </TruncateDisplay>
                        </div>
                        {!props.isPreviousPeriodSelected && (
                            <div
                                className={classes.fixedFloatingOverallCell_horizontal}
                                data-testid={`floatingResCell_${responseTotalRow}`}
                            >
                                <Typography variant="body1">{responseCountFinder(responseTotalRow)}</Typography>
                            </div>
                        )}
                    </div>
                )}
                {scrollingStatus.top && (
                    <div className={classes.floatingOverallRowContainer}>
                        {displayHeader.map((r: string, i: number) => (
                            <div
                                key={i}
                                className={classes.floatingOverallCell_horizontal}
                                data-testid={`floating_row_${r}`}
                            >
                                <Typography variant="body1">{responseCountFinder(r)}</Typography>
                            </div>
                        ))}
                    </div>
                )}
                {scrollingStatus.left && (
                    <div className={classes.floatingRowHeader}>
                        <div className={scrollingStatus.top ? classes.placeholderShort : classes.placeholder}></div>
                        {/* {props.currentFilter.items.length > 0 && <div className={classes.filterPlaceHolder}></div>} */}
                        {displayRows.slice(1).map((h: heatmapColumnCell, i: number) => (
                            <div className={classes.floatingRowGroup} key={i}>
                                <div className={classes.floatingRowHeaderCell}>
                                    {isFirstLevelRow(h.id) ? (
                                        <div
                                            className={classes.rowHeaderCell_wraper}
                                            onClick={(): void => {
                                                handleColumnRowClick(h.id);
                                            }}
                                        >
                                            <div className={classes.tableRow_th_div}>
                                                <div
                                                    className={
                                                        h.id === -1
                                                            ? classes.tableRow_th_div_span_horizontal_response
                                                            : classes.tableRow_th_div_span_horizontal
                                                    }
                                                    data-testid={h.id === -1 ? "Response" : `rowHeader+${h.title}`}
                                                >
                                                    <Tooltip title={h.title} placement={"bottom"}>
                                                        <Typography variant="body1">{h.title}</Typography>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className={classes.iconButton_horizontal_wraper}>
                                                {isRowExpandable(h.id) && (
                                                    <IconButton
                                                        onClick={(): void => {
                                                            handleColumnRowClick(h.id);
                                                        }}
                                                        data-testid={
                                                            isRowExpanded(h.id)
                                                                ? `collapse-${h.title}`
                                                                : `expand-${h.title}`
                                                        }
                                                        className={classes.iconButton_horizontal}
                                                        disableFocusRipple
                                                        disableRipple
                                                        edge={false}
                                                        size="small"
                                                        color="default"
                                                    >
                                                        {isRowExpanded(h.id) ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={classes.tableRow_th_div_span_details_horizontal_left}
                                                data-testid={`rowHeader+${h.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={h.title} alwaysShowTooltip>
                                                    <Typography variant="body2">{h.title}</Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {displayHeader.map((r: string, i: number) => {
                                    const valueFound = valueFinder(h, r, false);
                                    return r === responseTotalRow && valueFound !== valueNotFound ? (
                                        <div
                                            key={i}
                                            className={classes.floatingResCountCell}
                                            data-testid={`floatingResCell_${h.title}`}
                                        >
                                            <Typography variant="body1">{valueFound}%</Typography>
                                        </div>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}

                <div className={classes.floatingAction}>
                    <div className={classes.actionPlaceHolder}></div>
                    <ExpandCollapseBtnContainer
                        handleCollapseAll={handleCollapseAll}
                        handleExpandAll={handleExpandAll}
                        handleSwitchAxis={switchAxis}
                        originalLength={firstLevelRow.length - 1}
                        currentLength={displayRows.length - 1}
                        expandedAllLength={
                            nestedRow.length + nestedRow.map(r => r.children.length).reduce((a, b) => a + b, 0)
                        }
                        showDeltaValue={props.showDeltaValue}
                        switchDelta={props.switchDelta}
                    />
                    <div className={classes.actionPlaceHolder}></div>
                    {!props.isPreviousPeriodSelected && (
                        <div
                            className={classes.responseHeader}
                            data-testid={`responseHeader-${
                                props.isFilteredOverallSelected
                                    ? lang.overallFiltered
                                    : props.isPreviousPeriodSelected
                                      ? lang.overallSS
                                      : displayHeader[0]
                            }`}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                {props.isFilteredOverallSelected
                                    ? lang.overallFiltered
                                    : props.isPreviousPeriodSelected
                                      ? lang.overallSS
                                      : displayHeader[0]}
                            </Typography>
                        </div>
                    )}
                </div>

                <table className={classes.gridTable} data-testid="heatmap-table">
                    <thead className={classes.thead}>
                        <tr className={classes.tableRow}>
                            <th className={classes.tableAction}></th>
                            {displayHeader.map((r: string, i: number) => (
                                <th key={i} className={classes.tableRow_th_vertical}>
                                    <div className={classes.tableRow_th_div}>
                                        <div
                                            className={classes.tableRow_th_div_span_vertical}
                                            data-testid={`column-header-${r}`}
                                        >
                                            {r === responseTotalRow ? (
                                                <Tooltip title={responseTotalRow} placement={"bottom"}>
                                                    <Typography variant="body1">
                                                        {props.isFilteredOverallSelected ? lang.overallFiltered : r}
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={r} placement={"bottom"}>
                                                    <Typography variant="body1">{r}</Typography>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={classes.tbody} data-testid="heatmap-data-grid">
                        {displayRows.map((h: heatmapColumnCell, i: number) => (
                            <tr className={classes.tableRow} key={i}>
                                {isFirstLevelRow(h.id) ? (
                                    <td
                                        className={h.id === -1 ? classes.rowHeaderCell_overall : classes.rowHeaderCell}
                                        key={i}
                                        onClick={(): void => {
                                            handleColumnRowClick(h.id);
                                        }}
                                    >
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={
                                                    h.id === -1
                                                        ? classes.tableRow_th_div_span_horizontal_response
                                                        : classes.tableRow_th_div_span_horizontal
                                                }
                                                data-testid={h.id === -1 ? "Overall" : `rowHeader+${h.title}`}
                                            >
                                                <Tooltip title={h.title} placement={"bottom"}>
                                                    <Typography variant="body1">{h.title}</Typography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={classes.iconButton_horizontal_wraper}>
                                            {isRowExpandable(h.id) && (
                                                <IconButton
                                                    onClick={(): void => {
                                                        handleColumnRowClick(h.id);
                                                    }}
                                                    data-testid={
                                                        isRowExpanded(h.id)
                                                            ? `collapse-${h.title}`
                                                            : `expand-${h.title}`
                                                    }
                                                    className={classes.iconButton_horizontal}
                                                    disableFocusRipple
                                                    disableRipple
                                                    edge={false}
                                                    size="small"
                                                    color="default"
                                                >
                                                    {isRowExpanded(h.id) ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            )}
                                        </div>
                                    </td>
                                ) : (
                                    <td key={i}>
                                        <div className={classes.tableRow_th_div}>
                                            <div
                                                className={classes.tableRow_th_div_span_details_horizontal}
                                                data-testid={`rowHeader+${h.title}`}
                                            >
                                                <TruncateDisplay maxLabelLength={70} title={h.title} alwaysShowTooltip>
                                                    <Typography variant="body2">{h.title}</Typography>
                                                </TruncateDisplay>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {displayHeader.map((r: string, i: number) => {
                                    const valueFound = valueFinder(h, r, false);
                                    const deltaValueFound = valueFinder(h, r, true);
                                    const styleClass = heatmapCellClassMapper(deltaValueFound) as colorClasses;

                                    return (
                                        <td
                                            key={i}
                                            data-testid={
                                                h.title === responseTotalRow
                                                    ? "responseHeader"
                                                    : r === responseTotalRow
                                                      ? `overall-data-cell-${h.title}`
                                                      : `data-cell-${r}-${h.title}`
                                            }
                                            className={
                                                h.title === responseTotalRow
                                                    ? `${classes.dataCell} ${classes.cell_overall}`
                                                    : r === responseTotalRow
                                                      ? `${classes.dataCell} ${classes.cell_percentage}`
                                                      : h.title === lang.responses
                                                        ? `${classes.dataCell} ${classes.cell_response}`
                                                        : isFirstLevelRow(h.id)
                                                          ? `${classes.dataCellSection} ${classes[styleClass]}`
                                                          : `${classes.dataCell} ${classes[styleClass]}`
                                            }
                                        >
                                            {h.title === lang.responses ? (
                                                <Typography variant="body1">{responseCountFinder(r)}</Typography>
                                            ) : r === responseTotalRow && valueFound !== valueNotFound ? (
                                                <Typography variant="body1">{valueFound}%</Typography>
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        valueFound === valueNotFound
                                                            ? lang.noMeaningfulInfo
                                                            : tooltipTitle(h.title, r)
                                                    }
                                                    placement={"bottom"}
                                                >
                                                    <Typography variant="body1">{valueFound}</Typography>
                                                </Tooltip>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HeatGridAxis;

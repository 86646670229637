import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { Radio } from "lib/radio";

type Props = {
    name: string;
    id: number;
    selectedValue: number | undefined;
    onChange: (id: number, name: string) => void;
};

const useStyles = makeStyles(() => ({
    radioContainer: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    radioIcon: {
        marginRight: 14
    }
}));

const CellRadio: React.FC<Props> = props => {
    const classes = useStyles();
    const radioRef = useRef<HTMLInputElement>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        props.onChange(+event.target.value, event.target.title);
    };

    const handleParentClick = (): void => {
        if (radioRef.current) {
            const input = radioRef.current;

            props.onChange(+input.value, input.title);
        }
    };

    return (
        <div className={classes.radioContainer} onClick={handleParentClick}>
            <Radio
                checked={props.id === props.selectedValue}
                onChange={handleChange}
                value={props.id}
                title={props.name}
                inputProps={{ title: props.name }}
                className={classes.radioIcon}
                inputRef={radioRef}
            />
            <Typography noWrap variant="body2">
                {props.name}
            </Typography>
        </div>
    );
};

export default CellRadio;

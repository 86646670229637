/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useRef, useState, useEffect } from "react";
import StopIcon from "@mui/icons-material/Stop";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";

import { unfavorableColor, neutralColor, favorableColor } from "../../styles/GlobalStyles";
import LegendBtnIcon from "../../assets/images/icons/OrgLegend2.svg";
import { colorLegends } from "../../enums";
import { useLang } from "core/hooks";
import { theme } from "lib/theme";
import { IconButton } from "lib/icon-button";
import { Paper } from "lib/paper";
import { MenuItem } from "lib/menu-item";
import { Typography } from "lib/typography";
import { Icon } from "lib/icon";
import { Popper } from "lib/popper";
import { MenuList } from "lib/menu-List";

type Props = { variance: string };
type LegendProps = { shouldOpen: { legendOpen: boolean } };

const useStylesLegend = makeStyles(() => ({
    container: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(0.5),
        zIndex: 1000
    },
    menuBtn: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(0.5)
    },
    menu: {
        marginTop: theme.spacing(-4.5),
        marginRight: theme.spacing(4),
        display: "flex",
        flexDirection: "column"
    },
    item: {
        display: "flex",
        pointerEvents: "none"
    },
    closeBtn: {
        float: "right",
        zIndex: 1001,
        "& span": {
            "& svg": {
                fillOpacity: 0.65
            }
        }
    }
}));

export const LegendIcon = (props: Props): ReactElement => {
    return (
        <StopIcon
            sx={{
                fill:
                    props.variance === colorLegends.favorable
                        ? favorableColor
                        : props.variance === colorLegends.neutral
                          ? neutralColor
                          : unfavorableColor
            }}
        />
    );
};

const ColorLegend = (props: LegendProps): ReactElement => {
    const legends = Object.values(colorLegends);
    const classes = useStylesLegend();
    const [open, setOpen] = useState(false);
    const btnRef = useRef<HTMLButtonElement>(null);
    const { shouldOpen } = props;
    const { lang } = useLang();

    useEffect(() => {
        if (btnRef.current !== null && shouldOpen.legendOpen) {
            setOpen(shouldOpen.legendOpen);
        }

        return (): void => {
            setOpen(false);
        };
    }, [shouldOpen, btnRef.current]);

    const handleClick = (): void => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>): void => {
        if (btnRef.current && btnRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className={classes.container}>
            <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={handleClick}
                ref={btnRef}
                className={classes.menuBtn}
            >
                <Icon>
                    <img alt="color legends" src={LegendBtnIcon} />
                </Icon>
            </IconButton>
            <Popper open={open} anchorEl={btnRef.current} placement="bottom-start" sx={{ zIndex: 1002 }}>
                <Paper sx={{ transform: "translate(0%, -35%)" }}>
                    <IconButton
                        className={classes.closeBtn}
                        onClick={handleClose}
                        disableFocusRipple={true}
                        size="small"
                        disableRipple={true}
                    >
                        <ClearIcon />
                    </IconButton>
                    <MenuList>
                        {legends.map(l => (
                            <MenuItem key={l} className={classes.item}>
                                <LegendIcon variance={l} />
                                <Typography>{lang[l]}</Typography>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Paper>
            </Popper>
        </div>
    );
};

export default ColorLegend;

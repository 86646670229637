import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonProps } from "../button";

type Props = ButtonProps & {
    link: string;
};

const ButtonLink = (props: Props) => {
    const { children, link, ...rest } = props;

    return (
        <Link to={link}>
            <Button {...rest}>{children}</Button>
        </Link>
    );
};

export { ButtonLink };

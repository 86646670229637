import { useContext } from "react";

import { ManagerPortalContext } from "../../managerPortal/context/ManagerPortalContext";
import { ManagerPortalState } from "../../managerPortal/context/interfaces";

interface Return {
    managerPortalState: ManagerPortalState;
    managerPortalUpdateState: (newValue: Partial<ManagerPortalState>) => void;
}

export const useManagerPortalContext = (): Return => {
    const { handleSetState, managerPortalState } = useContext(ManagerPortalContext);

    const updateState = (newValue: Partial<ManagerPortalState>): void => {
        handleSetState(newValue);
    };

    return { managerPortalUpdateState: updateState, managerPortalState };
};

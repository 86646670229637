import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import { favorableColor, neutralColor } from "lib/palette";

type Props = {
    barWidth: number;
    rate: number;
};

const useStyles = makeStyles(() => ({
    barContainer: { display: "flex", width: "100%", height: "100%", alignItems: "center" },
    blueBar: ({ barWidth, rate }: { barWidth: number; rate: number }) => ({
        width: `${barWidth * rate >= 100 ? 100 : barWidth * rate}%`,
        background: favorableColor,
        height: 28,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: barWidth < 10 ? theme.spacing(0.4) : theme.spacing(0.7)
    }),
    cellTypography: ({ barWidth, rate }: { barWidth: number; rate: number }) => ({
        position: "relative",
        left: barWidth * rate < 10 ? barWidth * rate + 35 : 0,
        whiteSpace: "nowrap",
        fontWeight: 500
    }),
    greyBar: ({ barWidth, rate }: { barWidth: number; rate: number }) => ({
        width: `${100 - barWidth * rate <= 0 ? 0 : 100 - barWidth * rate}%`,
        background: neutralColor,
        height: 28,
        boxSizing: "border-box"
    })
}));

const RateBar = (props: Props): ReactElement => {
    const classes = useStyles({ barWidth: props.barWidth, rate: props.rate });

    return (
        <div className={classes.barContainer}>
            <div className={classes.blueBar}>
                <Typography variant="body2" className={classes.cellTypography} style={{ fontWeight: 500 }}>
                    {props.barWidth > 0 && props.barWidth < 1 ? "< 1%" : `${Math.round(props.barWidth)} %`}
                </Typography>
            </div>
            <div className={classes.greyBar}></div>
        </div>
    );
};

export default RateBar;

import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import StackedBar from "./StackedBar";
import { unfavorableColor, neutralColor, favorableColor, emptyBarColor } from "../../styles/GlobalStyles";
import TruncateDisplay from "../shared/TruncateDisplay";
import CustomValueCell from "../shared/CustomValueCell";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    noBenchmark: {
        width: "100%",
        height: "20px"
    },
    withBenchmark: {
        display: "flex",
        height: "20px"
    },
    benchmarkBar: {
        width: 50,
        marginLeft: 4,
        background: theme.palette.action.hover,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    overallContainer: {
        paddingBottom: 4
    }
}));

interface IProps {
    title: string;
    values: number[];
    valuesId: string;
    sectionOrItemId?: string;
    withBenchmark?: boolean;
}

const ScoreStackedSingleBarChart = (props: IProps) => {
    const { title, values, valuesId } = props;
    const classes = useStyles();
    const showBenchmark = false;
    const total = values ? values.reduce((a: number, b: number): number => a + b, 0) : "";
    const displayValues = +total > 0 ? values : +total < 0 ? [-1] : [100];
    let displayPercentage = true;

    let colors;
    if (total === 0) {
        colors = [emptyBarColor];
        displayPercentage = false;
    } else if (values && values.length === 2) {
        colors = [unfavorableColor, favorableColor]; // no, yes
    } else {
        colors = [unfavorableColor, neutralColor, favorableColor];
    }

    return (
        <div id={valuesId} className={classes.overallContainer}>
            {showBenchmark ? (
                <div className={classes.withBenchmark}>
                    <StackedBar
                        values={displayValues}
                        colors={colors}
                        displayPercentage={displayPercentage}
                        withBenchmark
                    />
                    {<div className={classes.benchmarkBar}>{total !== 0 && <CustomValueCell value={null} />}</div>}
                </div>
            ) : (
                <div className={classes.noBenchmark}>
                    <StackedBar values={displayValues} colors={colors} displayPercentage={displayPercentage} />
                </div>
            )}
            <TruncateDisplay title={title} maxLabelLength={30}>
                <Typography variant="caption">{title}</Typography>
            </TruncateDisplay>
        </div>
    );
};

export default ScoreStackedSingleBarChart;

import { PanelInfo, DashboardData } from "components/reports/userDashboard/interface";

export const initPanelInfo: PanelInfo = {
    translations: [],
    items: []
};

export const initDashboardData: DashboardData = {
    items: {},
    previousPeriodLabels: [],
    respondentCount: 0,
    responseCount: 0,
    sections: {},
    surveySections: [],
    lookdownInfo: {}
};

import React, { createContext, useReducer, useEffect, Dispatch, PropsWithChildren } from "react";
import { useLazyQuery } from "@apollo/client";

import { useAuth } from "core/context/auth/useAuth";
import { useUser } from "core/context/user/useUser";
import getTranslation from "../languages/getTranslation";
import { CompassContextActions } from "./actions";
import { englishUI } from "../languages/UIMessages";
import { englishError } from "../languages/errorMessages";
import { CompassContextReducer } from "./reducers";
import { CompassContextState } from "./interfaces";
import { allSurveysQuery } from "api/queries";
import OrgChartService from "api/rest/OrgChart.service";
import { SurveyInfo } from "components/admin/results/interfaces";
import { Action } from "../interfaces";
import Error500 from "components/errorPage/Error500";
import { LanguageCode } from "../languages/LanguageOptions";
import HeatmapService from "api/rest/Heatmap.service";
import AdminService from "api/rest/Admin.service";
import InsightService from "api/rest/Insight.service";
import { CommentsService } from "api/rest/comments-service";
import { useOrganization } from "./organization/useOrganization";

const languageDefault = { languageCode: LanguageCode.en, translation: englishUI, errorMessage: englishError };

type Context = {
    compassContextState: CompassContextState;
    compassContextDispatch: Dispatch<Action>;
};

export const initialState = (token: string): CompassContextState => {
    return {
        language: languageDefault,
        surveys: [],
        isLoading: true,
        orgChartService: new OrgChartService(`Bearer ${token}`),
        heatmapService: new HeatmapService(`Bearer ${token}`),
        adminService: new AdminService(`Bearer ${token}`),
        insightService: new InsightService(`Bearer ${token}`),
        commentsService: new CommentsService(`Bearer ${token}`)
    };
};

const CompassContext = createContext<Context>({
    compassContextState: initialState(""),
    compassContextDispatch: () => null
});

const CompassProvider = ({ children }: PropsWithChildren) => {
    const { token } = useAuth();
    const { user } = useUser();
    const { selectedOrganization } = useOrganization();
    const [state, dispatch] = useReducer(CompassContextReducer, token || "", initialState);

    const [getSurveys, { loading: loadingSurvey, error: getSurveysError }] = useLazyQuery(allSurveysQuery, {
        onCompleted: data => {
            dispatch({
                type: CompassContextActions.UPDATE_STATE,
                payload: {
                    selectedOrgHasSurvey: data.allSurveys.filter((s: SurveyInfo) => s.active).length > 0,
                    surveys: data.allSurveys,
                    isLoading: false
                }
            });
        },
        onError: () => {
            dispatch({
                type: CompassContextActions.UPDATE_STATE,
                payload: { selectedOrgHasSurvey: false, surveys: [], isLoading: false }
            });
        }
    });

    useEffect(() => {
        getSurveys({ variables: { orgId: selectedOrganization.id } });
    }, [selectedOrganization]);

    useEffect(() => {
        if (user.settings.languageCode !== LanguageCode.en) {
            dispatch({
                type: CompassContextActions.UPDATE_LANGUAGE,
                payload: {
                    lang: getTranslation(user.settings.languageCode).lang,
                    languageCode: user.settings.languageCode,
                    errorMessage: getTranslation(user.settings.languageCode).errorMessage
                }
            });
        }
    }, []);

    if (loadingSurvey) return <></>;
    if (state.isLoading) return <></>;

    if (getSurveysError) {
        return <Error500 />;
    }

    return (
        <CompassContext.Provider
            value={{
                compassContextState: state,
                compassContextDispatch: dispatch
            }}
        >
            {children}
        </CompassContext.Provider>
    );
};

export { CompassContext, CompassProvider };

export enum SnapshotActions {
    SET_FILTER_ALLOWED = "SET_FILTER_ALLOWED",
    SET_SNAPSHOT_DATA = "SET_SNAPSHOT_DATA",
    OPEN_FILTER = "OPEN_FILTER",
    SET_FILTER = "SET_FILTER",
    SET_STATUS = "SET_STATUS",
    SELECT_SURVEY = "SELECT_SURVEY",
    SET_CONFIDENTIALITY = "SET_CONFIDENTIALITY",
    OPEN_EXPORT = "OPEN_EXPORT",
    DATA_ONLOAD = "DATA_ONLOAD",
    SELECT_SURVEY_SECTION = "SELECT_SURVEY_SECTION",
    UNSELECT_SURVEY_SECTION = "UNSELECT_SURVEY_SECTION",
    SHOW_BENCHMARK = "SHOW_BENCHMARK",
    SHOW_ORG_OVERALL = "SHOW_ORG_OVERALL",
    SET_COMPARE_OPTIONS = "SET_COMPARE_OPTIONS",
    APPLY_PRESELECTION = "APPLY_PRESELECTION",
    SHOW_CONFIRMATION_DIALOG = "SHOW_CONFIRMATION_DIALOG",
    SET_COLUMNLABEL = "SET_COLUMNLABEL",
    SELECT_ALL_SURVEY_SECTIONS = "SELECT_ALL_SURVEY_SECTIONS",
    UNSELECT_ALL_SURVEY_SECTIONS = "UNSELECT_ALL_SURVEY_SECTIONS",
    SET_BULK_EXPORT = "SET_BULK_EXPORT",
    SNACKBAR_OPEN_CLOSE = "SNACKBAR_OPEN_CLOSE"
}

import { FilterParameter } from "../interfaces";
import { CombinationOperator, FilterType } from "../enums";
import { ReportFilterInput } from "../interfaces";
import { ContactField } from "components/admin/results/previousPeriod/interfaces";

//mapping function to map backend filter return value to fe value
export const filterConnectorFromBackEnd = (
    filterItems: ReportFilterInput[],
    contactFieldList: ContactField[]
): FilterParameter[] => {
    const getContactField = (fieldId?: number): string => {
        if (fieldId) {
            const found = contactFieldList.find((field: ContactField) => field.fieldId === fieldId);

            if (found) return found.title;
        }
        return "";
    };

    return filterItems.map((item: ReportFilterInput, index: number) => {
        return {
            combinationOperator: !item.combinationOperator ? CombinationOperator.none : item.combinationOperator,
            comparisonOperator: item.comparisonOperator,
            filterType: FilterType.contactField,
            field: {
                id: item.id,
                fieldName: getContactField(item.id)
            },
            order: index,
            target: item.target.map((t: string) => {
                return { id: t, text: t };
            })
        };
    });
};

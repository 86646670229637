import { saveAs } from "file-saver";

import {
    CustomScaleResponse,
    ScaleDefaultUpdateInput,
    ScaleItemUpdateInput
} from "components/admin/results/customScales/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { FilterParameter } from "components/filters/interfaces";
import { LanguageCode } from "core/languages/LanguageOptions";
import { Bar, CardContent, CardLayout, Numeric } from "managerPortal/components/cards/interfaces";
import { BenchmarkSection, InitialState, VisibleNodes } from "managerPortal/context/interfaces";
import { CardFile, HrisFieldInfo, SnapshotResult, SurveySettings } from "managerPortal/interfaces/Interfaces";
import {
    BenchmarkMapResponse,
    OrgChartService as IOrgChartService,
    ResponseRateResponse,
    ComparisonGroup
} from "./interfaces";
import { Hierarchy } from "managerPortal/components/hierarchy/interfaces";
import ConfigService from "./Config.service";
import { LookdownItemExport, keyDriversExport } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { SurveyReportlabel } from "components/admin/results/interfaces";
import { Correlation } from "components/reports/correlation/interface";
import { Tag } from "components/admin/tags/interface";
import {
    SurveyListResponse,
    BenchmarkAdminRespondentResponse,
    AdminBenchmarkResponse,
    AdminBenchmarkDetailsResponse
} from "components/admin/benchmarks/interfaces";
import { UserSettings } from "../../components/admin/users/interface/AdminUser.interfaces";
import { BulkReportStatus, OverallBulkReportResponse, OverallBulkReportForUser } from "./BulkReportStatus";
import { DemographicCompareFilterMapper, getCommentsSettings, OtherCompareFilterMapper } from "core/helpers";
import { HeatmapComparison } from "api/rest/interfaces/HeatmapService.interface";
import {
    defaultCompareOptionId,
    filteredOverallOptionId,
    previousPeriodOptionId
} from "components/reports/heatmap/heatmap.init";
import { initFilter } from "components/filters/inits";

export default class OrgChartService extends ConfigService implements IOrgChartService {
    initialState(surveyId: number, languageCode: string): Promise<InitialState> {
        const makeUrl = this.makeUrl(
            `/OrganizationView/InitialState?surveyId=${surveyId}&languageCode=${languageCode}`
        );
        return this.get(makeUrl);
    }

    createHierarchy(
        name: string,
        surveyId: number,
        nodeFieldIds: number[],
        languageCode: string
    ): Promise<{ allHierarchies: Hierarchy[]; hierarchyId: number }> {
        const createParams = { name, nodeFieldIds, surveyId, languageCode };
        const makeUrl = this.makeUrl("/OrganizationView/Create");
        return this.post(makeUrl, createParams);
    }

    deleteHierarchy(surveyId: number, hierarchyId: number): Promise<void> {
        const postUrl = this.makeUrl("/OrganizationView/Delete");
        return this.delete(postUrl, {
            surveyId,
            hierarchyId
        });
    }

    renameHierarchy(surveyId: number, hierarchyId: number, newName: string): Promise<void> {
        const params = {
            SurveyId: surveyId,
            HierarchyId: hierarchyId,
            Name: newName
        };
        const postUrl = this.makeUrl("/OrganizationView/RenameHierarchy");
        return this.delete(postUrl, params);
    }

    readHierarchyData(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        languageCode: string
    ): Promise<VisibleNodes> {
        return this.get(
            `/OrganizationView/HierarchyData?surveyId=${surveyId}&hierarchyId=${hierarchyId}&cardId=${cardId}&languageCode=${languageCode}`
        );
    }

    setDefaultHierarchy(surveyId: number, hierarchyId: number): Promise<void> {
        const postUrl = this.makeUrl("/OrganizationView/SetDefaultHierarchy");
        return this.post(postUrl, {
            surveyId,
            hierarchyId
        });
    }

    setHierarchyCardLayout<T>(surveyId: number, hierarchyId: number, layout: CardLayout): Promise<T> {
        const postUrl = this.makeUrl(`/OrganizationView/SetHierarchyCardLayout`);
        const params = {
            surveyId,
            hierarchyId,
            bars: layout.bars ? layout.bars : [],
            numerics: layout.numerics ? layout.numerics : []
        };
        return this.post(postUrl, params);
    }

    isCardDefaultLayoutSet<T>(hierarchyId: number): Promise<T> {
        return this.get(`/OrganizationView/IsCardDefaultLayoutSet?hierarchyId=${hierarchyId}`);
    }

    getAvailableCardContent(surveyId: number, languageCode: string): Promise<{ availableCardContent: CardContent[] }> {
        return this.get(`/OrganizationView/GetAvailableCardContent?surveyId=${surveyId}&languageCode=${languageCode}`);
    }

    renameCard(hierarchyId: number, cardId: number, newTitle: string): Promise<void> {
        const updateParams = {
            hierarchyId,
            cardId,
            newTitle
        };
        const postUrl = this.makeUrl("/OrganizationView/RenameCard");
        return this.post(postUrl, updateParams);
    }

    snapshotReport(
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        surveyId: number,
        languageCode: string,
        showOrgOverall: boolean,
        showCompareGroup: boolean,
        comparisonGroup: ComparisonGroup,
        hideBenchmark: boolean,
        hidePreviousPeriod: boolean
    ): Promise<SnapshotResult> {
        const options = {
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            surveyId,
            languageCode,
            showOrgOverall,
            showCompareGroup,
            comparisonGroup: comparisonGroup,
            hideBenchmark,
            hidePreviousPeriod
        };
        const postUrl = this.makeUrl("/OrganizationView/SnapshotReport");
        return this.post(postUrl, options);
    }

    deleteSnapshotReportFilter(surveyId: number, filterId: number): Promise<void> {
        const params = {
            surveyId,
            filterId
        };
        const postUrl = this.makeUrl("/OrganizationView/DeleteSnapshotReportFilter");
        return this.post(postUrl, params);
    }

    getDemographicFieldsAndValues(surveyId: number, hierarchyId: number, cardId: number): Promise<HrisFieldInfo[]> {
        return this.get(
            `/OrganizationView/GetDemographicFieldsAndValues?surveyId=${surveyId}&hierarchyId=${hierarchyId}&cardId=${cardId}`
        );
    }

    downloadFile(fileId: number, fileName: string): void {
        this.getBlobFile(`/OrganizationView/DownloadFile?fileId=${fileId}`)
            .then((blob: unknown) => saveAs(blob as Blob, fileName))
            .catch(e => {
                console.log(e);
            });
    }

    uploadCardFile(file: File, cardId: number, hierarchyId: number): Promise<CardFile[]> {
        const postUrl = this.makeUrl("/OrganizationView/UploadCardFile");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("hierarchyId", hierarchyId.toString());
        formData.append("cardId", cardId.toString());

        const options = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: this.bearer
            }
        };
        return fetch(postUrl, options).then(response => response.json());
    }

    deleteFile(fileId: number): Promise<boolean> {
        const params = {
            fileId
        };
        const postUrl = this.makeUrl("/OrganizationView/DeleteFile");
        return this.post(postUrl, params);
    }

    getCardFiles(hierarchyId: number, cardId: number): Promise<CardFile[]> {
        return this.get(`/OrganizationView/GetCardFiles?hierarchyId=${hierarchyId}&cardId=${cardId}`);
    }

    setDefaultLayout(hierarchyId: number, surveyId: number, bars: Bar[], numerics: Numeric[]): Promise<void> {
        const params = {
            hierarchyId,
            surveyId,
            bars,
            numerics
        };
        const postUrl = this.makeUrl("/OrganizationView/SetDefaultLayout");
        return this.post(postUrl, params);
    }

    getBenchmarkMappingInit(surveyId: number, languageCode: string): Promise<BenchmarkMapResponse> {
        return this.get(`/Benchmark/mappingInit?surveyId=${surveyId}&languageCode=${languageCode}`);
    }

    getBenchmarkSections(benchmarkId: number): Promise<BenchmarkSection[]> {
        return this.get(`/Benchmark/sections?benchmarkId=${benchmarkId}`);
    }

    updateItemBenchmarkMap(surveyId: number, itemId: number, benchmarkItemId: number): Promise<void> {
        const params = {
            surveyId,
            itemId,
            benchmarkItemId
        };
        const postUrl = this.makeUrl("/Benchmark/itemBenchmarkMap");
        return this.post(postUrl, params);
    }

    selectItemBenchmarkMap(surveyId: number, benchmarkId: number): Promise<void> {
        const params = {
            surveyId,
            benchmarkId
        };
        const postUrl = this.makeUrl("/Benchmark/selected");
        return this.post(postUrl, params);
    }

    autoMapBenchmarkItems(surveyId: number): Promise<void> {
        const params = {
            surveyId
        };
        const postUrl = this.makeUrl("/Benchmark/itemMapping");
        return this.post(postUrl, params);
    }

    clearBenchmarkItems(surveyId: number): Promise<void> {
        const params = {
            surveyId
        };
        const postUrl = this.makeUrl("/Benchmark/clearItemMapping");
        return this.post(postUrl, params);
    }

    revertToDefaultLayout(hierarchyId: number, surveyId: number): Promise<void> {
        const params = { hierarchyId, surveyId };
        const postUrl = this.makeUrl("/OrganizationView/RevertToDefaultLayout");
        return this.post(postUrl, params);
    }

    customScaleInit(surveyId: number, languageCode: string): Promise<CustomScaleResponse> {
        return this.get(`/OrganizationView/CustomScaleInit?surveyId=${surveyId}&languageCode=${languageCode}`);
    }

    updateHierarchyNodeUsers(hierarchyId: number, nodeId: number, userIds: number[], surveyId: number): Promise<void> {
        const params = {
            hierarchyId,
            nodeId,
            userIds,
            surveyId
        };
        const postUrl = this.makeUrl("/OrganizationView/UpdateHierarchyNodeUsers");
        return this.post(postUrl, params);
    }

    updateCommentWordCloudSettings(surveyId: number, wordCloudStopWords: string[]): Promise<void> {
        const params = {
            surveyId,
            wordCloudStopWords
        };
        const postUrl = this.makeUrl(`/Comments/wordcloudsettings`);
        return this.post(postUrl, params);
    }
    checkDuplicateAssignedUser(userId: number, surveyId: number, hierarchyId: number): Promise<boolean> {
        return this.get(
            `/OrganizationView/IsUserAssignedToAnotherHierarchy?currentHierarchyId=${hierarchyId}&userId=${userId}&surveyId=${surveyId}`
        );
    }
    setSurveySettings(surveyId: number, model: string): Promise<void> {
        const params = {
            surveySettings: model,
            surveyId
        };

        const postUrl = this.makeUrl(`/OrganizationView/SurveySettings`);
        return this.post(postUrl, params);
    }

    getSurveySettings(surveyId: number): Promise<SurveySettings> {
        return this.get(`/OrganizationView/GetSurveySettings?surveyId=${surveyId}`);
    }

    getCustomScales(surveyId: number, languageCode: LanguageCode) {
        return this.get(`/OrganizationView/CustomScaleInit?surveyId=${surveyId}&languageCode=${languageCode}`);
    }

    updateItemScale(updateScale: ScaleItemUpdateInput): Promise<void> {
        const postUrl = this.makeUrl(`/OrganizationView/UpdateItemScale`);
        return this.post(postUrl, updateScale);
    }

    updateDefaultScale(updateScale: ScaleDefaultUpdateInput): Promise<void> {
        const postUrl = this.makeUrl(`/OrganizationView/UpdateDefaultScale`);
        return this.post(postUrl, updateScale);
    }

    updateItemIgnoreOption(surveyId: number, itemId: number, isIgnored: boolean): Promise<void> {
        const params = {
            surveyId,
            itemId,
            isIgnored
        };

        const postUrl = this.makeUrl(`/OrganizationView/UpdateItemIgnoreOption`);
        return this.post(postUrl, params);
    }

    revertItemScale(itemId: number): Promise<void> {
        const postUrl = this.makeUrl(`/OrganizationView/RevertItemScale`);
        return this.post(postUrl, { itemId });
    }

    updateItemRemoveFromOverall(itemId: number, isRemovedOverallScore: boolean): Promise<void> {
        const params = {
            itemId,
            isRemovedOverallScore
        };

        const postUrl = this.makeUrl(`/OrganizationView/UpdateItemRemoveFromOverall`);
        return this.post(postUrl, params);
    }

    hideItemForManagers(surveyId: number, itemId: number, isHiddenForManagers: boolean): Promise<void> {
        const params = {
            surveyId,
            itemId,
            isHiddenForManagers
        };
        const postUrl = this.makeUrl("/OrganizationView/HideItemForManagers");
        return this.post(postUrl, params);
    }

    exportSnapshotReport(
        surveyId: number,
        title: string,
        subTitle: string | null,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[] | null,
        languageCode: string,
        showOrgOverall: boolean,
        showCompareGroup: boolean,
        lookdowns: LookdownItemExport[],
        heatmapFieldIds: number[],
        compareToLabel: string,
        responseRateContactFields: number[],
        keyDrivers: keyDriversExport[],
        hideBenchmark: boolean,
        hidePreviousPeriod: boolean,
        showOverallCommentsSlide: boolean,
        heatMapCompareGroupType: number[],
        comparisonGroup: ComparisonGroup,
        includeOrgOverallInLookdowns: boolean
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/fileexport/snapshot");
        const params = {
            surveyId,
            title,
            cardId,
            subTitle,
            hierarchyId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            showOrgOverall,
            showCompareGroup,
            lookdowns,
            heatmapFieldIds,
            compareToLabel,
            responseRateContactFields,
            keyDrivers,
            hideBenchmark,
            hidePreviousPeriod,
            showOverallCommentsSlide,
            heatMapCompareGroupType,
            comparisonGroup,
            includeOrgOverallInLookdowns
        };
        return this.getBlobFile(postUrl, true, params)
            .then((blob: unknown) => saveAs(blob as Blob, `${title}.pptx`))
            .catch(e => {
                throw e;
            });
    }
    exportSnapshotExcelReport(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[] | null,
        languageCode: string,
        showOrgOverall: boolean,
        showCompareGroup: boolean,
        hideBenchmark: boolean,
        hidePreviousPeriod: boolean,
        comparisonGroup: ComparisonGroup
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/fileexport/snapshotexcel");
        const params = {
            surveyId,
            cardId,
            hierarchyId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            showOrgOverall,
            showCompareGroup,
            hideBenchmark,
            hidePreviousPeriod,
            comparisonGroup
        };
        return this.getBlobFile(postUrl, true, params)
            .then((blob: unknown) => saveAs(blob as Blob, "Snapshot.xlsx"))
            .catch(e => {
                throw e;
            });
    }
    getSurveyFields(surveyId: number): Promise<SurveyReportlabel[]> {
        return this.get(`/Admin/SurveyFields?surveyId=${surveyId}`);
    }
    updateSurveyFields(id: number, currentLabel: string): Promise<void> {
        const postUrl = this.makeUrl("/Admin/SurveyFields");
        const params = {
            id,
            currentLabel
        };
        return this.post(postUrl, params);
    }
    exportResponseRate(
        title: string,
        languageCode: string,
        contactFields: number[],
        filters: FilterParameter[] | null
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/ResponseRate");
        const params = {
            title,
            languageCode,
            contactFields,
            filters: filterConnectorToBackEnd(filters)
        };
        return this.getBlobFile(postUrl, true, params);
    }
    createItemContactField(itemId: number, languageCode: string, name: string): Promise<void> {
        const postUrl = this.makeUrl("/Admin/CreateItemContactField");
        const params = {
            itemId,
            languageCode,
            name
        };
        return this.post(postUrl, params);
    }
    deleteItemContactField(itemId: number, languageCode: string): Promise<void> {
        const postUrl = this.makeUrl("/Admin/DeleteItemContactField");
        const params = {
            itemId,
            languageCode
        };
        return this.post(postUrl, params);
    }
    updateItemContactField(itemId: number, languageCode: string, name: string): Promise<void> {
        const postUrl = this.makeUrl("/Admin/UpdateItemContactField");
        const params = {
            itemId,
            languageCode,
            name
        };
        return this.post(postUrl, params);
    }
    correlationReport(
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        surveyId: number,
        languageCode: string
    ): Promise<{ correlations: Correlation; responseCount: number; confidentialityResult: number }> {
        const options = {
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            surveyId,
            languageCode
        };
        const postUrl = this.makeUrl("/Correlations");
        return this.post(postUrl, options);
    }
    exportCorrelationReport(
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        surveyId: number,
        languageCode: string
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/Correlations");
        const params = {
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            surveyId,
            languageCode
        };
        return this.getBlobFile(postUrl, true, params);
    }
    getTags(isSurvey: boolean, id: number): Promise<Tag[]> {
        return this.get(`/Tag/GetTags?isSurvey=${isSurvey}&id=${id}`);
    }
    addTags(name: string): Promise<number> {
        const postUrl = this.makeUrl(`/Tag/Create`);
        const params = {
            Id: 0,
            TagName: name
        };
        return this.post(postUrl, params);
    }
    deleteTag(id: number): Promise<boolean> {
        const postUrl = this.makeUrl("/Tag/Delete");
        const params = {
            TagId: id
        };
        return this.delete(postUrl, params);
    }
    addOrganizationTag(orgId: number, tagId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Tag/AddTagToOrganization`);
        const params = {
            organizationId: orgId,
            tagId: tagId
        };
        return this.post(postUrl, params);
    }
    deleteOrganizationTag(orgId: number, tagId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Tag/RemoveTagFromOrganization`);
        const params = {
            organizationId: orgId,
            tagId: tagId
        };
        return this.delete(postUrl, params);
    }
    addSurveyTag(surveyId: number, tagId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Tag/AddTagToSurvey`);
        const params = {
            surveyId: surveyId,
            tagId: tagId
        };
        return this.post(postUrl, params);
    }
    deleteSurveyTag(surveyId: number, tagId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Tag/RemoveTagFromSurvey`);
        const params = {
            surveyId: surveyId,
            tagId: tagId
        };
        return this.delete(postUrl, params);
    }
    getAdminBenchmark(startDate: string, endDate: string, tagIds: string): Promise<SurveyListResponse[]> {
        return this.get(`/Benchmark/surveys?startDate=${startDate}&endDate=${endDate}&tagIdsString=${tagIds}`);
    }
    getBenchmarkAdminRespondent(surveyIds: number[]): Promise<BenchmarkAdminRespondentResponse> {
        const postUrl = this.makeUrl(`/Benchmark/respondent`);
        const params = {
            surveyIds: surveyIds
        };
        return this.post(postUrl, params);
    }
    getAdminBenchmarkList(): Promise<AdminBenchmarkResponse[]> {
        return this.get(`/Benchmark/benchmarks`);
    }
    getAdminBenchmarkDetails(id: number, languageCode: string): Promise<AdminBenchmarkDetailsResponse> {
        return this.get(`/Benchmark/benchmark?id=${id}&languageCode=${languageCode}`);
    }
    createNewAdminBenchmark(
        name: string,
        frenchName: string,
        tagIds: number[],
        startDate: string,
        endDate: string,
        organizationsAndSurveys: SurveyListResponse[],
        numberOfResponses: number,
        responseRate: number
    ): Promise<unknown> {
        const postUrl = this.makeUrl(`/Benchmark/benchmark`);
        const params = {
            name: name,
            frenchName: frenchName,
            tagIds: tagIds,
            startDate: startDate,
            endDate: endDate,
            organizationsAndSurveys: organizationsAndSurveys,
            numberOfResponses: numberOfResponses,
            responseRate: responseRate
        };
        return this.getBlobFile(postUrl, true, params);
    }
    archiveBenchmark(benchmarkId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Benchmark/archive`);
        const params = {
            id: benchmarkId,
            archive: true
        };
        return this.post(postUrl, params);
    }
    activeBenchmark(benchmarkId: number): Promise<boolean> {
        const postUrl = this.makeUrl(`/Benchmark/archive`);
        const params = {
            id: benchmarkId,
            archive: false
        };
        return this.post(postUrl, params);
    }
    getResponseRate(
        surveyId: number,
        reportType: string,
        fieldIds: number[],
        filters: FilterParameter[]
    ): Promise<ResponseRateResponse> {
        const postUrl = this.makeUrl(`/ResponseRate/ResponseRate`);
        const params = {
            surveyId: surveyId,
            reportType: reportType,
            fieldIds: fieldIds,
            filters: filterConnectorToBackEnd(filters)
        };
        return this.post(postUrl, params);
    }
    startBulkExport(
        surveyId: number,
        fieldId: number,
        langCode: string,
        type: number,
        settings: UserSettings
    ): Promise<number> {
        const postUrl = this.makeUrl(`/bulkreport/start`);
        const userSettings = getCommentsSettings(settings.comment.surveySelected, settings.commentsSettings);
        type payload = {
            surveyId: number;
            demographicFieldId: number;
            languageCode: string;
            comments?: unknown;
            correlations?: unknown;
            heatmap?: unknown;
            snapshot?: unknown;
            dashboard?: unknown;
        };
        const params: payload = {
            surveyId: surveyId,
            demographicFieldId: fieldId,
            languageCode: langCode
        };
        switch (type) {
            case 0:
                params.comments = {
                    surveyId: settings.comment.surveySelected,
                    filters: filterConnectorToBackEnd(settings.filtersItems),
                    itemIds: settings.comment.itemIds,
                    minWordsPerComment: userSettings.minWordsPerComment,
                    showSentimentScores: userSettings.showSentimentScores,
                    autoTranslate: userSettings.autoTranslate,
                    commentsRandomSampleCount: userSettings.commentsRandomSampleCount,
                    typeOfSearch: settings.comment.typeOfSearch,
                    searchTerm: settings.comment.searchTerm,
                    includeWordCloud: settings.comment.exportIncludeWordCloud,
                    wordCloudTopWordsCount: userSettings.wordCloudTopWordsCount
                };
                break;
            case 1:
                params.correlations = {
                    surveyId: settings.correlation.surveySelected,
                    filters: filterConnectorToBackEnd(settings.filtersItems)
                };
                break;
            case 2:
                params.heatmap = {
                    surveyId: settings.heatmap.surveySelected,
                    filters: filterConnectorToBackEnd(settings.filtersItems),
                    contactFieldId: settings.heatmap.breakdownItems[0].id,
                    compareFilters: filterConnectorToBackEnd(
                        DemographicCompareFilterMapper(
                            settings.heatmap.selectedCompareOptionID,
                            settings.heatmap.selectedCompareTargetName,
                            {
                                id: -1,
                                name: "",
                                items: settings.filtersItems
                            }
                        ) as FilterParameter[]
                    ),
                    comparison:
                        settings.heatmap.selectedCompareOptionID === defaultCompareOptionId
                            ? HeatmapComparison.Overall
                            : settings.heatmap.selectedCompareOptionID === previousPeriodOptionId
                              ? HeatmapComparison.Previous
                              : settings.heatmap.selectedCompareOptionID === filteredOverallOptionId
                                ? HeatmapComparison.FilterRestricted
                                : HeatmapComparison.FilterUnrestricted,
                    swapAxes: settings.heatmap.swapAxes,
                    showFavorable: settings.heatmap.showFavorable,
                    compareToPrevious: settings.heatmap.selectedCompareOptionID === -3
                };
                break;
            case 3:
                params.snapshot = {
                    title: settings.snapshot.exportTitle,
                    surveyId: settings.snapshot.id,
                    filters: filterConnectorToBackEnd(settings.filtersItems),
                    showOrgOverall: settings.snapshot.showOrgOverview,
                    showCompareGroup: settings.snapshot.showOtherComparison,
                    lookdowns: settings.snapshot.lookdownItems.map(ld => ({
                        type: ld.type,
                        demographicFieldId: ld.demographicFieldId,
                        measureId: ld.measureId,
                        lookupIds: ld.lookupIds.filter(id => id !== -1)
                    })),
                    heatmapFieldIds: settings.snapshot.heatmapLookdownItems.map(hm => hm.demographicFieldId),
                    compareToLabel: settings.snapshot.columnLabel,
                    responseRateContactFields: settings.snapshot.rrLookdownItems.map(rr => rr.demographicFieldId),
                    keyDrivers: settings.snapshot.keyDriversLookdownItems.map(kd => ({
                        isSection: kd.isSection,
                        id: kd.measureId,
                        columnLabel: kd.columnLabel
                    })),
                    hideBenchmark: !settings.snapshot.customizedShowBenchmark,
                    hidePreviousPeriod: !settings.snapshot.customizedShowPP,
                    showOverallCommentsSlide: settings.snapshot.showOverallCommentsSlide,
                    heatMapCompareGroupType: settings.snapshot.heatmapLookdownItems.map(item => item.compareOption),
                    comparisonGroup: OtherCompareFilterMapper(
                        settings.snapshot.otherComparisonSurveyId,
                        settings.snapshot.columnLabel,
                        { ...initFilter, items: settings.snapshot.otherComparisonFilter }
                    ),
                    includeOrgOverallInLookdowns: settings.snapshot.includeLookupOrgOverall
                };
                break;
            case 4:
                params.dashboard = {
                    surveyId: settings.userDashboard.surveySelected,
                    filters: filterConnectorToBackEnd(settings.filtersItems)
                };
                break;
        }
        return this.post(postUrl, params);
    }
    getBulkReportStatus(surveyId: number, languageCode: string): Promise<BulkReportStatus[]> {
        const url = this.makeUrl(`/bulkreport/status?surveyId=${surveyId}&languageCode=${languageCode}`);
        return this.get(url);
    }
    downloadBulkReport(reportId: number): Promise<unknown> {
        const url = this.makeUrl(`/bulkreport/download?reportId=${reportId}`);
        return this.getBlobFile(url);
    }
    deleteBulkReport(reportId: number): Promise<unknown> {
        const postUrl = this.makeUrl(`/bulkreport/delete`);
        const params = {
            reportId: reportId
        };
        return this.post(postUrl, params);
    }
    cancelBulkReport(reportId: number): Promise<unknown> {
        const postUrl = this.makeUrl(`/bulkreport/cancel`);
        const params = {
            reportId: reportId
        };
        return this.post(postUrl, params);
    }
    deleteAllBulkExports(): Promise<unknown> {
        const postUrl = this.makeUrl(`/bulkreport/deleteAll`);
        const params = {};
        return this.post(postUrl, params);
    }
    getOverallBulkExportStatus(languageCode: string): Promise<OverallBulkReportResponse> {
        return this.get(`/bulkreport/overallStatus?languageCode=${languageCode}`);
    }
    exportInsightReport(
        surveyId: number,
        title: string,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[] | null,
        languageCode: string,
        outcomeIsItem: boolean,
        outcomeId: number,
        keyDriverId: number,
        demographicFieldId: number | null,
        hidePreviousPeriod: boolean,
        hideBenchmark: boolean,
        compareOverall: boolean
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/Insights");
        const params = {
            surveyId,
            title,
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            outcomeIsItem,
            outcomeId,
            keyDriverId,
            demographicFieldId,
            hidePreviousPeriod,
            hideBenchmark,
            compareOverall
        };
        return this.getBlobFile(postUrl, true, params);
    }
    exportParticipantsReport(surveyId: number): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/Participants");
        const params = {
            surveyId
        };
        return this.getBlobFile(postUrl, true, params);
    }
    exportDemographicMappingExcelReport(
        surveyId: number,
        targetSurveyId: number,
        languageCode: string
    ): Promise<unknown> {
        const postUrl = this.makeUrl("/fileexport/exportDemographicMappings");
        const params = {
            surveyId,
            targetSurveyId,
            languageCode
        };
        return this.getBlobFile(postUrl, true, params)
            .then((blob: unknown) => saveAs(blob as Blob, "Previous Period Export.xlsx"))
            .catch(e => {
                throw e;
            });
    }
    clearDimensionBenchmarkMap(surveyId: number, itemIds: number[]): Promise<unknown> {
        const postUrl = this.makeUrl(`/Benchmark/clearDimensionBenchmarkMap`);
        const params = {
            surveyId,
            itemIds
        };
        return this.post(postUrl, params);
    }
    getOverallBulkExportStatusForUser(languageCode: string): Promise<OverallBulkReportForUser[]> {
        return this.get(`/bulkreport/statusForUser?languageCode=${languageCode}`);
    }
}

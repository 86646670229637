import React, { ReactElement } from "react";

import { responseRateBackground } from "../../styles/GlobalStyles";
import { useLang } from "../../../core/hooks";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Avatar } from "lib/avatar";
import { Box } from "lib/box";

const avatarStyled = {
    width: 50,
    height: 50,
    color: theme.palette.text.primary,
    background: responseRateBackground
};

interface IProps {
    value: number;
    isPercent: boolean;
    tooltip: string;
}

const NumberInCircle = (props: IProps): ReactElement => {
    const { value, isPercent, tooltip } = props;
    const numberText = value ? (isPercent ? value.toFixed(0) + "%" : value.toPrecision(2)) : "N/A";
    const { lang } = useLang();

    return (
        <Box pl={1} pr={1}>
            <Tooltip title={value ? tooltip : lang.responseNATooltip}>
                <Avatar sx={avatarStyled} data-testid="avatar-number-in-circle">
                    {numberText}
                </Avatar>
            </Tooltip>
        </Box>
    );
};

export default NumberInCircle;

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { ListItemButton, ListItemIcon, ListItemText } from "lib/list";

const useStyles = makeStyles(() => ({
    itemContainer: {
        marginTop: theme.spacing(1)
    },
    secondaryIcon: {
        "& svg": {
            fontSize: 16
        }
    }
}));

interface IProps {
    label: string;
    icon: ReactElement;
    onSelect: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    secondaryIcon?: ReactElement;
    dataTestId: string;
}

const DrawerMenuItem = (props: IProps): ReactElement => {
    const { label, icon, secondaryIcon, onSelect, dataTestId } = props;
    const classes = useStyles();

    return (
        <ListItemButton onClick={onSelect} className={classes.itemContainer} data-testid={dataTestId}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
            {secondaryIcon && <ListItemIcon className={classes.secondaryIcon}>{secondaryIcon}</ListItemIcon>}
        </ListItemButton>
    );
};

export default DrawerMenuItem;

import { gql } from "@apollo/client";

export const createSurveyComparisonMutation = gql`
    mutation createSurveyComparison($sourceSurveyId: Int!, $targetSurveyId: Int!, $label: String!) {
        createSurveyComparison(sourceSurveyId: $sourceSurveyId, targetSurveyId: $targetSurveyId, label: $label) {
            id
            label
            sourceSurveyId
            targetSurveyId
            showPreviousPeriod
            showInFirstColumn
            fieldMaps {
                sourceFieldId
                targetFieldId
                isSurveyItem
            }
            showInHeatmap
            valueMaps {
                sourceFieldId
                sourceValue
                targetFilters {
                    combinationOperator
                    id
                    comparisonOperator
                    target
                    filterType
                }
            }
        }
    }
`;

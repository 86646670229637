import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { Filter } from "components/filters/interfaces";
import FilterChip from "../../shared/FilterChip";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    filterTagWrap: {
        boxSizing: "border-box",
        display: "flex",
        borderBottom: theme.border.main,
        overflow: "hidden",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        height: "100%"
    }
}));

type Props = {
    filter: Filter;
    onDeleteFilterItem: (filter: Filter) => void;
};

export const ReportsSubheader = (props: Props): ReactElement => {
    const classes = useStyles();

    const handleDeleteFilterItem = (filterItemOrder: number): void => {
        const updateCurrentFilter = { ...props.filter };
        updateCurrentFilter["items"] = updateCurrentFilter.items
            .filter(item => item.order !== filterItemOrder)
            .map((item, index) => {
                return {
                    ...item,
                    order: index
                };
            });

        props.onDeleteFilterItem(updateCurrentFilter);
    };

    return (
        <div className={classes.filterTagWrap}>
            <FilterChip items={props.filter.items} onDelete={handleDeleteFilterItem} />
        </div>
    );
};

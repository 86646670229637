import React, { ReactElement } from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { backgroundLightGrey } from "core/styles/BackgroundPalette";
import { SidebarWithRoutesItem } from "./interfaces";
import { theme } from "lib/theme";
import { Divider } from "lib/divider";

const useStyles = makeStyles(() => ({
    sidebar: {
        width: 224,
        minWidth: 224,
        height: "100%",
        borderRight: theme.border.main,
        background: backgroundLightGrey,
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
    },
    link: {
        paddingLeft: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        height: 56,
        textDecoration: "none",
        color: theme.palette.text.primary,
        "&:visited": {
            color: "inherit"
        }
    },
    selected: {
        background: theme.palette.action.selected
    }
}));

type Props = {
    itemList: SidebarWithRoutesItem[];
};

const SidebarWithRoutes = (props: Props): ReactElement => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <div className={classes.sidebar}>
            {props.itemList.map((item: SidebarWithRoutesItem) => (
                <React.Fragment key={item.path}>
                    {item.path === "/admin/organizations" && <Divider />}
                    <Link
                        className={
                            location.pathname === item.path ? clsx(classes.link, classes.selected) : classes.link
                        }
                        to={item.path}
                    >
                        {item.title}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default SidebarWithRoutes;

import React, { useState, useEffect } from "react";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import { makeStyles } from "@mui/styles";
import saveAs from "file-saver";
import Search from "components/shared/Search";
import { useLang, useOrgChartService } from "core/hooks";
import EmptyPage from "components/shared/EmptyPage";
import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import { Translation } from "core/languages/interfaces";
import { Column } from "components/shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "components/shared/infiniteTable/enums";
import InfiniteTable from "components/shared/infiniteTable/InfiniteTable";
import { isStringEmptyOrSpaces, copyArrayWithObjects } from "core/helpers";
import { TableData } from "components/shared/infiniteTable/types";
import { mapTableSource } from "components/shared/infiniteTable/hooks";
import { OverallBulkReportForUser } from "api/rest/BulkReportStatus";
import { Snackbar } from "lib/snackbar";
import dayjs from "dayjs";

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column"
};

const useStyles = makeStyles(() => ({
    bulkOverallStatusContainer: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "24px calc(100% - 24px)",
        gridTemplateAreas: `
            "appHeader"
            "appContent"
        `
    },
    adminLayoutHeader: {
        gridArea: "appHeader",
        alignSelf: "end",
        borderBottom: theme.border.main
    },
    adminLayoutContent: {
        gridArea: "appContent"
    },
    adminLayoutActions: {
        paddingLeft: 24
    }
}));

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.title,
            label: lang.title,
            minWidth: 500,
            maxWidth: 600,
            width: "100%",
            sortable: true,
            cellType: CellType.downloadTitle,
            isResizable: true
        },
        {
            dataKey: DataKey.status,
            secondaryDataKey: DataKey.progress,
            thirdyDataKey: DataKey.queuePosition,
            label: lang.status,
            minWidth: 300,
            maxWidth: 350,
            width: "100%",
            sortable: true,
            order: TableSort.desc,
            cellType: CellType.downloadStatus,
            isResizable: false
        },
        {
            dataKey: DataKey.createdDate,
            label: lang.requestedTime,
            minWidth: 250,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.completedDate,
            label: lang.completedTime,
            minWidth: 250,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        }
    ];
};

const Downloads = (): React.ReactElement => {
    const classes = useStyles();
    const { lang, languageCode } = useLang();
    const orgChartService = useOrgChartService();
    const [searchStr, setSearchStr] = useState("");
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [bulkExportList, setBulkExportList] = useState<OverallBulkReportForUser[]>([]);
    const tableColumns = tableColumnsList(lang);

    const handleSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const getBulkExportList = (): void => {
        orgChartService
            .getOverallBulkExportStatusForUser(languageCode)
            .then((bulks: OverallBulkReportForUser[]): void => {
                const sortedBulks = bulks.sort((a, b) => {
                    if (a.queuePosition === 0 && b.queuePosition !== 0) return 1;
                    else if (b.queuePosition === 0 && a.queuePosition !== 0) return -1;
                    else if (a.queuePosition === 0 && b.queuePosition === 0)
                        return dayjs(a.completedDate).isAfter(dayjs(b.completedDate)) ? -1 : 1;
                    else return a.queuePosition - b.queuePosition;
                });
                setBulkExportList(sortedBulks);
            });
    };

    const filterBySearchString = (bulkExportStautsList: OverallBulkReportForUser[]): OverallBulkReportForUser[] => {
        let filtered = bulkExportStautsList;
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (s: OverallBulkReportForUser) =>
                    s.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.createdDate.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.completedDate.toLowerCase().includes(searchStr.toLowerCase())
            );
        }

        return filtered;
    };

    const getTableData = (bulkExportStautsList: OverallBulkReportForUser[]): TableData[] => {
        const filteredData = filterBySearchString(bulkExportStautsList);
        return mapTableSource(filteredData, tableColumns);
    };

    const handleDownloadBulkExport = (id: number): void => {
        orgChartService
            .downloadBulkReport(id)
            .then((blob: unknown) => {
                setSnackbar({ isOpen: true, message: lang.startDownloading });
                saveAs(blob as Blob, "export.zip");
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    useEffect(() => {
        getBulkExportList();
    }, [searchStr]);

    return (
        <Box data-testid="downloads" sx={containerStyle}>
            <Box pt={2} pb={2} pl={"24px"} display={"flex"} alignItems="center" justifyContent={"flex-start"}>
                <Typography variant="h6" fontSize={"20px"}>
                    {lang.bulkReportDownloads}
                </Typography>
            </Box>
            <div className={classes.adminLayoutActions}>
                <Search onSearchChange={handleSearchChange} searchTerm={searchStr} width={280} />
            </div>
            <div className={classes.bulkOverallStatusContainer}>
                <div className={classes.adminLayoutContent} data-testid="bulkOverallStatusContainer">
                    {bulkExportList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={getTableData(bulkExportList)}
                            onClickableTextClick={handleDownloadBulkExport}
                            rowPadding={2}
                            isDownloadLink={true}
                            defaultOrderByColumn={DataKey.createdDate}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noCreatedBulkExports} />
                    )}
                </div>
                <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
            </div>
        </Box>
    );
};

export default Downloads;

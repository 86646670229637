import React, { ReactElement, useState, useEffect } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

import { greyColor } from "managerPortal/styles/GlobalStyles";

type Props = {
    percentage: number;
    totalCountString: string;
    stackbarWidth: number;
};

type StyleProps = {
    percentagePicked: number;
    maxBarWidth: number;
};

const totalCountWidth = 48;

const useStyles = makeStyles(() => ({
    componentContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        borderLeft: theme.border.main,
        paddingLeft: 16
    },
    barContainer: {
        height: 28,
        display: "flex",
        width: "100%",
        position: "relative"
    },
    barContentInfo: {
        height: "100%",
        width: "100%",
        display: "flex",
        position: "absolute"
    },
    percentagePickedStyle: ({ percentagePicked, maxBarWidth }: StyleProps) => {
        const currentWidth = (maxBarWidth * percentagePicked) / 100;
        return {
            height: "100%",
            minWidth: currentWidth < 30 ? 30 : 38,
            paddingLeft: 4,
            paddingRight: 4,
            boxSizing: "border-box",
            width: `${currentWidth}px`,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        };
    },
    colorBarFilling: ({ percentagePicked, maxBarWidth }: StyleProps) => {
        const currentWidth = (maxBarWidth * percentagePicked) / 100;
        return {
            height: "100%",
            boxSizing: "border-box",
            width: `${currentWidth}px`,
            display: "flex",
            justifyContent: "flex-end",
            background: greyColor
        };
    },
    typographyPercentage: {
        fontWeight: "bold"
    },
    totalPickedStyleWrap: ({ percentagePicked, maxBarWidth }: StyleProps) => {
        const currentWidth = (maxBarWidth * percentagePicked) / 100;
        return {
            height: "100%",
            width: `${totalCountWidth}px`,
            paddingLeft: 4,
            boxSizing: "border-box",
            position: "absolute",
            left: percentagePicked < 10 && currentWidth < 30 ? 30 : currentWidth < 38 ? 38 : `${currentWidth}px`,
            display: "flex",
            alignItems: "center"
        };
    }
}));

const CountChartBar = (props: Props): ReactElement => {
    const [maxBarWidth, setMaxBarWidth] = useState(0);
    const percentagePickedString = props.percentage.toFixed(0) + "%";

    useEffect(() => {
        setMaxBarWidth(props.stackbarWidth - totalCountWidth);
    }, []);
    const classes = useStyles({ percentagePicked: props.percentage, maxBarWidth });

    return (
        <div className={classes.componentContainer}>
            <div className={classes.barContainer}>
                <div className={classes.barContentInfo}>
                    <div className={classes.colorBarFilling}></div>
                    <div className={classes.totalPickedStyleWrap}>
                        <Typography>{props.totalCountString}</Typography>
                    </div>
                </div>
                <div className={classes.barContentInfo}>
                    <div className={classes.percentagePickedStyle}>
                        <Typography className={classes.typographyPercentage}>{percentagePickedString}</Typography>
                    </div>
                    <div className={classes.totalPickedStyleWrap}>
                        <Typography></Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountChartBar;

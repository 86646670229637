import React, { useEffect, useCallback, PropsWithChildren, ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";

let logoutTimeout: number;
const timeout = 60 * 60 * 1000; // 60 minutes

const UserTimeout = ({ children }: PropsWithChildren): ReactElement => {
    const { isAuthenticated, logout } = useAuth0();

    const onClearTimeout = (): void => {
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const handleLogout = useCallback(() => {
        logout({
            returnTo: window.location.origin
        });
    }, [logout]);

    const onSetTimeout = useCallback(() => {
        logoutTimeout = window.setTimeout(() => handleLogout(), timeout);
    }, [handleLogout]);

    const resetTimeout = useCallback(() => {
        onClearTimeout();
        onSetTimeout();
    }, [onSetTimeout]);

    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
    const addEventListener = useCallback(() => {
        events.forEach((event: string) => {
            window.addEventListener(event, resetTimeout);
        });
    }, [events, resetTimeout]);

    const removeEventListener = useCallback(() => {
        events.forEach((event: string) => {
            window.removeEventListener(event, resetTimeout);
        });
    }, [events, resetTimeout]);

    useEffect(() => {
        if (isAuthenticated) {
            addEventListener();
            onSetTimeout();
        }
        return (): void => {
            removeEventListener();
        };
    }, [addEventListener, isAuthenticated, onSetTimeout, removeEventListener]);

    useEffect(() => {
        return (): void => {
            onClearTimeout();
        };
    }, []);

    return <>{children}</>;
};

export default UserTimeout;

import { useContext } from "react";

import { ManagerPortalContext } from "../../managerPortal/context/ManagerPortalContext";
import { Hierarchy } from "../../managerPortal/components/hierarchy/interfaces";

export const useExistingHierarchies = (): {
    existingHierarchies: Hierarchy[];
    updateExistingHierarchies: (newValue: Hierarchy[]) => void;
} => {
    const { managerPortalState, handleSetState } = useContext(ManagerPortalContext);

    const updateExistingHierarchies = (newValue: Hierarchy[]): void => {
        handleSetState({ existingHierarchies: newValue });
    };

    return { existingHierarchies: managerPortalState.existingHierarchies, updateExistingHierarchies };
};

import React, { ReactElement, useState, useEffect, useReducer } from "react";
import { useLazyQuery } from "@apollo/client";
import saveAs from "file-saver";
import { useHistory } from "react-router-dom";
import { Typography } from "lib/typography";

import { UserDashboardState, PanelItem } from "./interface";
import { emptySurveyInfo } from "components/admin/results/init";
import { ComponentStatus } from "core/enums";
import { initFilter } from "components/filters/inits";
import { ConfidentialityResult } from "components/reports/heatmap/heatmap.init";
import { UserDashboardReducer } from "./reducer";
import { UserDashboardActions } from "./action";
import { initPanelInfo, initDashboardData } from "./inits";
import {
    useSurveys,
    useLang,
    useLoading,
    useQueryWithPromise,
    useAdminService,
    useUpdateUserSettings,
    useOrgChartService
} from "core/hooks";
import Error500 from "components/errorPage/Error500";
import { useUserDashboardStyles } from "./userDashboard.style";
import { SurveyInfo } from "components/admin/results/interfaces";
import EmptyPage from "components/shared/EmptyPage";
import NoSurveySelected from "assets/images/emptyPages/ReportingEmptyState.svg";
import { DialogExportSlide } from "managerPortal/components/shared/DialogExportSlide";
import { DialogBulkExportSlide } from "managerPortal/components/shared/DialogBulkExportSlide";
import Filters from "components/filters/Filters";
import { FilterMode, FilterType } from "components/filters/enums";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { userDashboardOnLoad, demographicFieldsAndValuesQuery } from "api/queries";
import { HrisFieldInfo } from "managerPortal/interfaces";
import ShowConfidentialityMessage from "managerPortal/components/snapshotReport/ShowConfidentialityMessage";
import ShowBelowConfidentialityMessage from "components/reports/comment/ShowBelowConfidentialityMessage";
import DashboardItem from "./DashboardItem";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { MainRoutes as MainRoutesEnum, ReportRoutes as ReportRoutesEnum } from "routes/enums";
import { ReportsLayout } from "../layout/ReportsLayout";
import FilterChip from "components/shared/FilterChip";
import ColorLegend from "managerPortal/components/shared/ColorLegend";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { getMapSurveySelected, mapSurveysToAutocompleteOption, sortArray } from "core/helpers";
import { BulkReportType } from "api/rest/BulkReportStatus";
import { Snackbar } from "lib/snackbar";
import { useUser } from "core/context/user/useUser";

const initialState = (): UserDashboardState => {
    return {
        selectedSurvey: emptySurveyInfo,
        surveyItemsOptions: [],
        status: ComponentStatus.idle,
        currentFilter: initFilter,
        isFilterDialogOpen: false,
        confidentialityResult: ConfidentialityResult.success,
        responseCount: 0,
        panelInfo: initPanelInfo,
        snapshot: initDashboardData,
        colorLegendOpen: { legendOpen: false }
    };
};

export const dashboardRowLimits = 30;

const UserDashboard = (): ReactElement => {
    const [state, dispatch] = useReducer(UserDashboardReducer, initialState());
    const classes = useUserDashboardStyles();
    const { surveys } = useSurveys();
    const orgChartService = useOrgChartService();
    const { user } = useUser();
    const history = useHistory();
    const { setInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const adminService = useAdminService();
    const [isExportDialogOpen, setExportDialogOpen] = useState(false);
    const [isBulkExportDialogOpen, setBulkExportDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [bulkExportDemographic, setBulkExportDemographic] = useState<BulkExportDemographic>({
        demographicFieldId: -1,
        demographicField: ""
    });
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const fetchHris = useQueryWithPromise(userDashboardOnLoad);
    const fetchDemographics = useQueryWithPromise(demographicFieldsAndValuesQuery);
    let hrisList: HrisFieldInfo[] = [];
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo], user.isTalentMapAdmin);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, state.selectedSurvey.id);

    const shouldShowEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.userDashboard) {
            return true;
        }

        if (user.settings.userDashboard) {
            if (user.settings.userDashboard.surveySelected === emptySurveyInfo.id) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.userDashboard.surveySelected) === undefined) {
                return true;
            }
        }

        if (state.selectedSurvey.id === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };

    //Hris and survey items
    const [loadDimensionItems, { error: errorSurveyItems }] = useLazyQuery(userDashboardOnLoad, {
        onCompleted: data => {
            if (data) {
                dispatch({
                    type: UserDashboardActions.LOAD_SECTION_HRIS,
                    payload: {
                        surveyItemsOptions: data.surveyItemFields
                    }
                });
                fetchUserDashboardValue(initFilter, state.selectedSurvey.id);
            }
        }
    });

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
        }
    });

    const fetchUserDashboardValue = (filter: Filter, surveyId: number): void => {
        if (surveyId !== emptySurveyInfo.id) {
            adminService
                .getDashboardData(surveyId, languageCode, filter.items)
                .then(response => {
                    dispatch({ type: UserDashboardActions.SET_DASHBOARDINFO, payload: { layout: response.layout } });
                    dispatch({
                        type: UserDashboardActions.SET_DASHBOARD_DATA,
                        payload: { snapshot: response.snapshot }
                    });
                    dispatch({
                        type: UserDashboardActions.SET_RESPONSE_COUNT,
                        payload: { responseCount: response.snapshot.responseCount }
                    });
                    dispatch({
                        type: UserDashboardActions.SET_CONFIDENTIALITY_STATUS,
                        payload: { confidentialityResult: response.snapshot.confidentialityResult }
                    });
                    dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                })
                .catch(() => {
                    dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                });
        } else {
            dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
        }
    };

    const restoreUserDashboard = async (surveyId: number, filterItems: FilterParameter[]): Promise<void> => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === surveyId);
        dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const loadedHris = await fetchHris({ surveyId: surveyId, languageCode, includeQuestionTypes: 0 });

        if (loadedHris) {
            const { data } = loadedHris;
            let restoredFilter = user.settings.filtersItems
                ? { ...state.currentFilter, items: filterItems }
                : initFilter;
            const loadedDemographics = await fetchDemographics({ surveyId: surveyId, languageCode });
            if (loadedDemographics) {
                const { data } = loadedDemographics;
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
            restoredFilter.items
                .filter(item => item.filterType === FilterType.contactField)
                .forEach(item => {
                    if (!hrisList.map(list => list.fieldId).includes(item.field.id as number)) {
                        restoredFilter = initFilter;
                    }
                });
            dispatch({
                type: UserDashboardActions.RESTORE_USER_SETTINGS,
                payload: {
                    selectedSurvey,
                    surveyItemsOptions: data.surveyItemFields,
                    currentFilter: restoredFilter
                }
            });
            fetchUserDashboardValue(restoredFilter, surveyId);
        } else {
            dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
        }
    };

    const handleSurveySelected = (id: number): void => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
            dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            dispatch({
                type: UserDashboardActions.SELECT_SURVEY,
                payload: {
                    selectedSurvey,
                    currentFilter: initFilter
                }
            });
            loadDimensionItems({ variables: { surveyId: id, languageCode, includeQuestionTypes: 0 } });
            loadDemographics({ variables: { surveyId: id, languageCode } });
            if (!user.settings.userDashboard || id !== user.settings.userDashboard.surveySelected) {
                setInitUserSetting(id);
            }
        } else {
            dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
        }
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        if (isBulkExport) {
            setBulkExportDialogOpen(true);
        } else {
            setExportDialogOpen(true);
        }
    };
    const handleExportDialogClose = (): void => {
        setExportDialogOpen(false);
    };
    const handleBulkExportDialogClose = (): void => {
        setBulkExportDialogOpen(false);
    };

    const handleExport = (mainTitle: string): void => {
        setLoading(true);
        adminService
            .exportDashboardEditor(state.selectedSurvey.id, languageCode, state.currentFilter.items)
            .then((blob: unknown) => {
                saveAs(blob as Blob, mainTitle + ".pptx");
                setExportDialogOpen(false);
                setLoading(false);
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                setExportDialogOpen(false);
                setLoading(false);
            });
    };
    const handleBulkExport = (): void => {
        setSnackbar({ isOpen: true, message: lang.startDashboardBulkExport });
        orgChartService
            .startBulkExport(
                state.selectedSurvey.id,
                bulkExportDemographic.demographicFieldId,
                languageCode,
                BulkReportType.Dashboard,
                user.settings
            )
            .then(() => {
                setBulkExportDialogOpen(false);
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const handleGoToSnapshotReportClicked = (): void => {
        history.push(`/${MainRoutesEnum.reports}/${ReportRoutesEnum.snapshot}`);
    };

    //Filter start
    const handleOpenFilter = (): void => {
        dispatch({
            type: UserDashboardActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };
    const handleCloseFilters = (): void => {
        dispatch({
            type: UserDashboardActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };
    const handleDeleteFilterItem = (filterItemOrder: number): void => {
        const updateCurrentFilter = { ...state.currentFilter };
        updateCurrentFilter["items"] = updateCurrentFilter.items
            .filter(item => item.order !== filterItemOrder)
            .map((item, index) => {
                return {
                    ...item,
                    order: index
                };
            });

        updateUserDashboarData(updateCurrentFilter);
    };

    const updateUserDashboarData = (filter: Filter): void => {
        dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        dispatch({
            type: UserDashboardActions.SET_FILTER,
            payload: { status: ComponentStatus.loading, currentFilter: filter }
        });
        setUserSettingAfterFilterApplied(filter);
        fetchUserDashboardValue(filter, state.selectedSurvey.id);
    };
    const defaultDashboardTitle = surveys.find((survey: SurveyInfo) => survey.id === state.selectedSurvey.id)
        ? surveys.filter((survey: SurveyInfo) => survey.id === state.selectedSurvey.id)[0].translations[0].name
        : "";
    const getDashboardTitle = (): string => {
        const dashboardTitle = state.panelInfo.translations.filter(
            translation => translation.languageCode === languageCode
        )[0]?.name
            ? state.panelInfo.translations.filter(translation => translation.languageCode === languageCode)[0]?.name
            : defaultDashboardTitle;
        return dashboardTitle;
    };

    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        setBulkExportDemographic(updatedBulkExport);
    };

    useEffect(() => {
        const restoredState = user.settings.userDashboard;

        if (
            restoredState &&
            restoredState.surveySelected &&
            restoredState.surveySelected !== emptySurveyInfo.id &&
            mapSurveys.map(s => s.id).includes(restoredState.surveySelected)
        ) {
            const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === restoredState.surveySelected);
            if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
                dispatch({ type: UserDashboardActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
                restoreUserDashboard(restoredState.surveySelected, user.settings.filtersItems);
            }
        } else if (restoredState == undefined && mapSurveys.length > 1 && user.isRestricted) {
            // For new created restricted user, we automatically pick first survey for them
            handleSurveySelected(mapSurveys[0].id);
        } else if (
            restoredState &&
            restoredState.surveySelected === emptySurveyInfo.id &&
            mapSurveys.length > 1 &&
            user.isRestricted
        ) {
            handleSurveySelected(mapSurveys[0].id);
        }
    }, []);

    useEffect(() => {
        if (state.status === ComponentStatus.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [state.status]);

    useEffect(() => {
        if (state.selectedSurvey.id !== emptySurveyInfo.id) {
            fetchUserDashboardValue(state.currentFilter, state.selectedSurvey.id);
        }
    }, [languageCode]);

    if (state.status === ComponentStatus.error || errorSurveyItems) {
        return <Error500 />;
    }

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.selectedSurvey.id === emptySurveyInfo.id ||
                state.confidentialityResult !== ConfidentialityResult.success ||
                state.panelInfo.items.length === 0
            }
            filterDisabled={state.selectedSurvey.id === emptySurveyInfo.id}
            exportDataTestId={"btn-heatmap-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={updateUserDashboarData}
            isEmptyPage={shouldShowEmptyPage()}
            contactFields={contactFields}
        >
            <div className={classes.dataContent}>
                <div className={classes.dashboardTitleWrap}>
                    <div className={classes.dashboardTitle}>
                        <div className={classes.dashboardTitleItem}>
                            <Typography variant="h6">{getDashboardTitle()}</Typography>
                        </div>
                        <div className={classes.dashboardTitleItem}>
                            <Typography variant="h6" className={classes.dashboardResponseCount}>
                                {lang.totalResponses} - {state.responseCount}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.dashboardTitleFilterChips}>
                        {state.currentFilter.items && state.currentFilter.items.length > 0 && (
                            <FilterChip items={state.currentFilter.items} onDelete={handleDeleteFilterItem} />
                        )}
                    </div>
                    <div className={classes.actions}>
                        <ColorLegend shouldOpen={state.colorLegendOpen} />
                    </div>
                </div>
                {state.panelInfo.items.length === 0 ? (
                    <div className={classes.pivotGridWrapper}>
                        <EmptyPage
                            image={NoSurveySelected}
                            message={lang.noDashboardcreated}
                            actionButtonLabel={lang.goToSnapshotReport}
                            onButtonClick={handleGoToSnapshotReportClicked}
                        />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowConfidentialityMessage />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowBelowConfidentialityMessage />
                    </div>
                ) : (
                    <div className={classes.dashboardItem}>
                        {state.panelInfo.items.map((item: PanelItem, index: number) => (
                            <DashboardItem
                                key={index}
                                testid={index}
                                item={item}
                                dashboardData={state.snapshot}
                                responseCount={state.responseCount}
                                numberOfColumn={GRID_COL_LENGTH}
                                numberOfRow={GRID_ROW_LENGTH}
                            />
                        ))}
                    </div>
                )}
            </div>
            {isExportDialogOpen && (
                <DialogExportSlide
                    dialogTitle={lang.exportPowerPoint}
                    isOpen={isExportDialogOpen}
                    onClose={handleExportDialogClose}
                    onSubmitCallback={handleExport}
                />
            )}
            {isBulkExportDialogOpen && (
                <DialogBulkExportSlide
                    title={lang.bulkExportPowerPoint}
                    isOpen={isBulkExportDialogOpen}
                    onClose={handleBulkExportDialogClose}
                    onSubmitCallback={handleBulkExport}
                    contactFields={contactFields}
                    bulkExportDemographic={bulkExportDemographic}
                    updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                />
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
            {state.isFilterDialogOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={state.isFilterDialogOpen}
                    onFiltersApplied={updateUserDashboarData}
                    currentFilter={state.currentFilter}
                    filterMode={FilterMode.snapshot}
                    surveyId={state.selectedSurvey.id}
                    contactFields={contactFields}
                />
            )}
        </ReportsLayout>
    );
};

export default UserDashboard;

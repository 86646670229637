import React from "react";

import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { theme } from "lib/theme";

const MenuItem = (props: MenuItemProps) => {
    const menuItemStyle = {
        "&.Mui-selected": {
            background: `${theme.palette.action.hover} !important`
        },
        "&.Mui-selected:hover": {
            background: `${theme.palette.action.hover} !important`
        },
        "&.Mui-focusVisible": {
            background: `${theme.palette.action.hover} !important`
        },
        "&:hover": {
            background: `${theme.palette.action.hover} !important`
        }
    };
    const { children, ...rest } = props;
    return (
        <MuiMenuItem {...rest} sx={menuItemStyle}>
            {children}
        </MuiMenuItem>
    );
};

export { MenuItem };

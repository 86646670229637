import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 12,
        width: 450,
        maxWidth: 450,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
}));

type Props = {
    jsonText: string;
};

const CellJsonInfo: FC<Props> = ({ jsonText }) => {
    const classes = useStyles();
    const text = !_.isEmpty(JSON.parse(jsonText).Failed)
        ? `${Object.keys(JSON.parse(jsonText).Failed)
              .map(key => {
                  if (JSON.parse(jsonText).Failed[key]) {
                      return `${key} (${
                          JSON.parse(jsonText).Failed[key]?.ErrorType === 0 ? "Internal Error" : "Confidentiality"
                      })`;
                  } else {
                      return key;
                  }
              })
              .join(",   ")}`
        : "";

    return (
        <Tooltip title={text} placement={"bottom"}>
            <Typography variant="body2" className={classes.text}>
                {text}
            </Typography>
        </Tooltip>
    );
};

export default CellJsonInfo;

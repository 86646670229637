import { gql } from "@apollo/client";

export const responseRateQuery = gql`
    query responseRate($report: ResponseRateReportInput!) {
        responseRate(report: $report) {
            data
            fieldIds
            reportType
            surveyId
        }
    }
`;

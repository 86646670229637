import React from "react";
import MuiAutocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { TextField, TextFieldProps } from "../text-field";
import { styled } from "@mui/material/styles";

import { backgroundLightGrey, whiteBackground } from "lib/palette";

type Value = {
    label: string;
    id: number;
};

type TextFieldCustomProps = Omit<TextFieldProps, "onChange" | "ref"> & {
    placeholder?: string;
    background?: TextFieldProps["background"];
};

type Props<Value> = Omit<AutocompleteProps<Value, boolean, boolean, boolean>, "onChange" | "renderInput"> &
    TextFieldCustomProps & {
        onChange: (newValue: Value | null) => void;
    };

const StyledTextField = styled((props: TextFieldCustomProps) => <TextField {...props} />)(props => ({
    "& .MuiInputBase-input": {
        background:
            props.background === "white"
                ? whiteBackground
                : props.variant === "filled"
                  ? backgroundLightGrey
                  : "inherit",
        fontSize: "0.875rem",
        "&:hover": {
            background: "inherit"
        }
    }
}));

const autocompleteStyle = (variant: TextFieldCustomProps["variant"], background?: TextFieldProps["background"]) => {
    if (variant === "outlined") {
        return {
            width: "100%",
            "& .MuiAutocomplete-inputRoot": {
                background: background === "white" ? whiteBackground : backgroundLightGrey
            }
        };
    }

    return { width: "100%" };
};

const Autocomplete = (props: Props<Value>) => {
    const { onChange, placeholder, label, variant } = props;
    let textFieldVariant: TextFieldCustomProps["variant"] = "outlined";
    if (variant && variant !== "outlined") {
        textFieldVariant = variant;
    }
    return (
        <MuiAutocomplete
            sx={autocompleteStyle(textFieldVariant)}
            {...props}
            onChange={(_, newValue) => onChange(newValue as Value | null)}
            renderInput={params => (
                <StyledTextField
                    {...params}
                    placeholder={placeholder}
                    variant={textFieldVariant}
                    label={label}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                />
            )}
        />
    );
};

export { Autocomplete };
export type { Value as AutocompleteValue };

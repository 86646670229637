export enum BenchmarkMappingActions {
    SELECT_BENCHMARK = "SELECT_BENCHMARK",
    SHOW_AUTOMAP_CONFIRM_DIALOG = "SHOW_AUTOMAP_CONFIRM_DIALOG",
    SET_INITIAL_STATE = "SET_INITIAL_STATE",
    SELECT_SECTION = "SELECT_SECTION",
    DESELECT_SECTION = "DESELECT_SECTION",
    UPDATE_ITEMS_MAPPED = "UPDATE_ITEMS_MAPPED",
    CONFIRM_AUTOMAP = "CONFIRM_AUTOMAP",
    SET_SURVEY_SETTINGS = "SET_SURVEY_SETTINGS",
    SET_DIALOG = "SET_DIALOG",
    DESELECT_ALL_SECTION = "DESELECT_ALL_SECTION",
    SELECT_ALL_SECTION = "SELECT_ALL_SECTION",
    OPEN_MAPPINGDIALOG = "OPEN_MAPPINGDIALOG",
    DISABLE_AUTOMAP = "DISABLE_AUTOMAP",
    UPDATE_SELECTED_SECTIONS = "UPDATE_SELECTED_SECTIONS",
    CLEAR_BENCHMARK = "CLEAR_BENCHMARK"
}

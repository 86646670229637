import { ApolloLink, NormalizedCacheObject, HttpLink, ServerError, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
let uploadClient: ApolloClient<NormalizedCacheObject> | null = null;

const CreateUploadClient = (bearer: string): ApolloClient<NormalizedCacheObject> => {
    const authLink = setContext((_, { headers }) => {
        return { headers: { ...headers, authorization: bearer } };
    });

    const uploadLink = createUploadLink({
        uri: `${process.env.REACT_APP_API_URI}/upload`
    });

    const cache = new InMemoryCache();

    const errorLink = onError(({ networkError, operation, forward }) => {
        if (networkError) {
            const ne = networkError as ServerError;
            if (ne.result) {
                try {
                    if (typeof ne.result === "string") {
                        console.log(ne.result);
                    }
                    const result = JSON.parse(ne.result["0" as keyof ServerError["result"]]);
                    console.log(result);
                } catch (e) {
                    console.log(e);
                }
            }
        }
        forward(operation);
    });

    return new ApolloClient({
        link: ApolloLink.from([authLink, errorLink, uploadLink]),
        cache: cache,
        defaultOptions: {
            mutate: {
                errorPolicy: "all"
            }
        }
    });
};

const CreateClient = (bearer: string): ApolloClient<NormalizedCacheObject> => {
    const { logout } = useAuth0();
    const cache = new InMemoryCache();
    const authLink = setContext((_, { headers }) => {
        return { headers: { ...headers, authorization: bearer } };
    });

    const defaultLink = new HttpLink({
        uri: process.env.REACT_APP_API_URI
    });

    const errorLink = onError(({ networkError, operation, forward }) => {
        if (networkError) {
            if (networkError.message.includes("Received status code 401")) {
                logout();
            }
        }
        forward(operation);
    });

    return new ApolloClient({
        link: ApolloLink.from([authLink, errorLink, defaultLink]),
        cache: cache,
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore"
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all"
            },
            mutate: {
                errorPolicy: "all"
            }
        }
    });
};

export default function initApollo(bearer: string): {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    uploadClient: ApolloClient<NormalizedCacheObject>;
} {
    if (!apolloClient) {
        apolloClient = CreateClient(bearer);
    }

    if (!uploadClient) {
        uploadClient = CreateUploadClient(bearer);
    }

    return { apolloClient, uploadClient };
}

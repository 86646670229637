import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { theme } from "lib/theme";

import { useLang } from "core/hooks";

type Props = {
    responseCount: number;
    hideTotalResponse?: boolean;
    allSubGroups?: boolean;
};

const ShowNotEnoughResponsesMessage = (props: Props): ReactElement => {
    const { lang } = useLang();
    return (
        <>
            {!props.hideTotalResponse && (
                <div>
                    <Typography
                        variant="h6"
                        pl={3}
                        pr={3}
                        sx={{
                            display: "flex",
                            borderBottom: theme.border.main,
                            alignItems: "center",
                            height: 56,
                            justifyContent: "space-between"
                        }}
                    >
                        {lang.totalResponses} - {props.responseCount}
                    </Typography>
                </div>
            )}
            <Typography variant="body1" fontWeight="medium" mt={3} ml={3}>
                {props.responseCount === 0 ? lang.noSearchResults : lang.confidentialityWarning}
            </Typography>
            {props.responseCount > 0 && (
                <Typography variant="body2" mt={1.5} ml={3} mr={3}>
                    {props.allSubGroups
                        ? lang.confidentialityWarningDescriptionAllSubGroup
                        : lang.confidentialityWarningDescription}
                </Typography>
            )}
        </>
    );
};

export default ShowNotEnoughResponsesMessage;

export const capitalize = (text: string): string => {
    const words = text.split(" ");
    let capitalizedWords = "";
    words.forEach((word: string, index: number) => {
        const capWord = word.charAt(0).toUpperCase() + word.slice(1);
        if (index === 0) {
            capitalizedWords = capWord;
        } else {
            capitalizedWords = capitalizedWords + " " + capWord;
        }
    });
    return capitalizedWords;
};

import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { mdiPlus } from "@mdi/js";

import { IconButtonV4 } from "lib/icon-button";
import { useLang } from "core/hooks";
import { CombinationOperator } from "./enums";
import FilterCard from "./FilterCard";
import { FilterParameter } from "./interfaces";
import { theme } from "lib/theme";

type Props = {
    filterItems: FilterParameter[];
    selectedFilterItem: FilterParameter;
    onAddFilterItem: () => void;
    onDeleteFilterItem: (index: number) => void;
    onSelectFilterItem: (index: number) => void;
    onSetCombinationOperator: (param: FilterParameter, opr: CombinationOperator) => void;
};

const useStyles = makeStyles(() => ({
    addBtnContainer: ({ isEmpty }: { isEmpty: boolean }) => ({
        display: "flex",
        justifyContent: isEmpty ? "flex-start" : "center",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: 14
    })
}));

const FilterItemsList = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles({ isEmpty: props.filterItems.length === 0 });
    return (
        <div>
            {props.filterItems.map((param: FilterParameter, index: number) => {
                return (
                    <FilterCard
                        key={
                            param.field.id
                                ? param.field.id.toString() + param.order.toString()
                                : param.field.questionId
                                  ? param.field.questionId.toString() + param.order.toString()
                                  : param.order.toString()
                        }
                        filterField={param}
                        isSelected={param.field.id === props.selectedFilterItem.field.id}
                        onDeleteFilterItem={props.onDeleteFilterItem}
                        onSelectFilterItem={props.onSelectFilterItem}
                        onSetCombinationOperator={props.onSetCombinationOperator}
                        index={index}
                    />
                );
            })}
            <div className={classes.addBtnContainer}>
                <IconButtonV4
                    path={mdiPlus}
                    onClick={props.onAddFilterItem}
                    tooltip={lang.clickThePlusButtonToAddANewFilter}
                    dataTestid="add-new-filter-button"
                />
                {props.filterItems.length === 0 && (
                    <Typography variant="body2">{lang.clickThePlusButtonToAddANewFilter}</Typography>
                )}
            </div>
        </div>
    );
};

export default FilterItemsList;

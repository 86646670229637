import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useManagerPortalContext, useSurveys, useLang, useUpdateUserSettings } from "core/hooks";
import { SurveyInfo } from "components/admin/results/interfaces";
import { emptySurveyInfo } from "components/admin/results/init";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import { getMapSurvey, sortArray } from "core/helpers";
import { MapSurveyToAutocompleteOption } from "components/reports/layout/ReportsLayout";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    headerContainer: {
        width: 420,
        height: 72,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        boxSizing: "border-box"
    },
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: theme.border.main
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: theme.border.main
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: orangeColor
        }
    },
    root: {
        "& .MuiInputLabel-outlined": {
            color: "black"
        }
    }
}));

const Header = (): ReactElement => {
    const classes = useStyles();
    const { managerPortalUpdateState } = useManagerPortalContext();
    const { surveys } = useSurveys();
    const { lang } = useLang();
    const { user } = useUser();
    const { setInitUserSetting } = useUpdateUserSettings();

    let selectedSurvey = emptySurveyInfo;
    if (user.settings.snapshot.id !== emptySurveyInfo.id) {
        const found = surveys.find((survey: SurveyInfo) => survey.id === user.settings.snapshot.id);
        if (found) {
            selectedSurvey = found;
        }
    }
    const sortSurveysArray = <T extends MapSurveyToAutocompleteOption>(arrayToSort: T[]): T[] =>
        sortArray(arrayToSort, "label", "asc");

    const filteredSurveys = (): MapSurveyToAutocompleteOption[] => {
        const filtered = surveys.filter((survey: SurveyInfo) => survey.active && survey.allowInReports);
        return filtered.map((survey: SurveyInfo) => {
            return getMapSurvey(survey);
        });
    };

    const handleSurveySelected = (value: AutocompleteValue | null): void => {
        if (!value) return;

        const found = surveys.find((survey: SurveyInfo) => survey.id === value.id);

        if (!found) return;

        if (selectedSurvey.id !== found.id) {
            managerPortalUpdateState({ surveySelected: found });
            setInitUserSetting(value.id);
        }
    };

    const isSurveyInTheList = (): boolean => {
        const found = filteredSurveys().find(
            (survey: MapSurveyToAutocompleteOption) => survey.id === selectedSurvey.id
        );

        if (found) return true;

        return false;
    };

    return (
        <div className={classes.headerContainer}>
            <Autocomplete
                id="orgChart-select-survey-combo-box"
                options={sortSurveysArray(filteredSurveys())}
                noOptionsText={lang.noOptionsAvailable}
                value={isSurveyInTheList() ? getMapSurvey(selectedSurvey) : getMapSurvey(emptySurveyInfo)}
                defaultValue={getMapSurvey(emptySurveyInfo)}
                onChange={handleSurveySelected}
                placeholder={lang.survey}
            />
        </div>
    );
};

export default Header;

import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { DashboardCellType } from "../interfaces";
import SingleDimensionOrItemCellType from "./SingleDimensionOrItemCellType";
import { Box as BoxType, TitleTranslation } from "../interfaces";
import { SurveySection } from "managerPortal/context/interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";
import { Button } from "lib/button";
import { theme } from "lib/theme";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";
import { SelectChangeEvent } from "@mui/material";
import { Box } from "lib/box";

export interface Size {
    id: number;
    size: number;
    title: string;
}

export interface DimensionOrItem {
    isSection: boolean;
    measureId: number;
    measureField: string;
}

type Props = {
    onClose: () => void;
    boxes: BoxType[];
    box?: BoxType;
    selectedPanelId: string;
    onCancelClicked: () => void;
    updatePanelItem: (
        column: number,
        row: number,
        type: DashboardCellType,
        updateString: string,
        titleTranslations: TitleTranslation[]
    ) => void;
    surveySections: SurveySection[];
};

const useStyles = makeStyles(() => ({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignContent: "space-between",
        width: "450px",
        height: "600px",
        minHeight: "600px",
        maxHeight: "600px"
    },
    dialogHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: "bold",
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 24,
        paddingRight: 24,
        position: "absolute",
        width: "402px",
        top: "0px",
        height: "60px",
        minHeight: "60px",
        maxHeight: "60px",
        borderBottom: theme.border.main,
        overflow: "hidden"
    },
    dialogFooterWrapper: {
        position: "absolute",
        bottom: "0px",
        width: "442px",
        justifyContent: "flex-end",
        paddingRight: 0,
        borderTop: theme.border.main,
        height: "46px",
        minHeight: "46px",
        maxHeight: "46px",
        overflow: "hidden"
    },
    dialogdropdownBody: {
        position: "absolute",
        width: "450px",
        top: "76px",
        bottom: "62px",
        overflow: "auto"
    }
}));

export const SingleDimensionOrItem = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [singleDimensionOrItem, setSingleDimensionOrItem] = useState<DimensionOrItem>({
        isSection: false,
        measureId: -1,
        measureField: ""
    });
    const singleDimensionOrItemSizeInit = [
        { id: 0, size: 2, title: "2 x 2" },
        { id: 1, size: 3, title: "3 x 3" }
    ];
    const [singleDimensionOrItemSize, setSingleDimensionOrItemSize] = useState<Size[]>(singleDimensionOrItemSizeInit);
    const [selectedSingleDimensionOrItemSize, setSelectedSingleDimensionOrItemSize] = useState<string>("2 x 2");

    const [titleTranslation, setTitleTranslation] = useState<TitleTranslation[]>(
        (props.box && props.box.translations) || defaultItemTitleTranslation
    );

    const handleUpdateTitleTranslation = (newTitleTranslations: TitleTranslation[]): void => {
        setTitleTranslation(newTitleTranslations);
    };

    const handleSingleDimensionOrItemUpdate = (item: DimensionOrItem): void => {
        setSingleDimensionOrItem(item);
    };

    const handleSingleDimensionOrItemSizeSelected = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as string;
        setSelectedSingleDimensionOrItemSize(elementValue);
    };

    const getSingleDimensionOrItemSize = (): number => {
        const sizeSelected = selectedSingleDimensionOrItemSize.split("x")[0];
        return parseInt(sizeSelected.trim());
    };

    const handleSetSingleDimensionOrItemSize = (size: string): void => {
        setSelectedSingleDimensionOrItemSize(size);
    };

    const confirmationClicked = (): void => {
        return props.updatePanelItem(
            getSingleDimensionOrItemSize(),
            getSingleDimensionOrItemSize(),
            "singleDimensionOrItem",
            JSON.stringify({
                id: singleDimensionOrItem.measureId,
                isSection: singleDimensionOrItem.isSection
            }),
            titleTranslation
        );
    };
    const cancelClicked = (): void => {
        setSingleDimensionOrItem({
            isSection: false,
            measureId: -1,
            measureField: ""
        });
        props.onCancelClicked();
    };

    const getConfirmButtonDisableRule = (): boolean => {
        return singleDimensionOrItem.measureId === -1;
    };

    useEffect(() => {
        setSingleDimensionOrItem({
            isSection: false,
            measureId: -1,
            measureField: ""
        });
    }, [open]);

    useEffect(() => {
        const selectedBox = props.boxes.find(box => box.id === props.selectedPanelId);
        if (selectedBox) {
            setSingleDimensionOrItemSize(
                singleDimensionOrItemSizeInit.filter(
                    item =>
                        item.size <=
                        Math.min(
                            GRID_COL_LENGTH - selectedBox.left / boxSize,
                            GRID_ROW_LENGTH - selectedBox.top / boxSize
                        )
                )
            );
            if (selectedBox.props) {
                const properties = selectedBox.props;
                const isSection = JSON.parse(properties).isSection;
                if (isSection) {
                    const sectionId = JSON.parse(properties).id;
                    const fieldName = props.surveySections.filter(
                        section => +section.sectionId.slice(1) === sectionId
                    )[0].title;
                    handleSingleDimensionOrItemUpdate({
                        ...singleDimensionOrItem,
                        isSection: true,
                        measureId: sectionId,
                        measureField: fieldName
                    });
                } else {
                    const itemId = JSON.parse(properties).id;
                    const fieldName = props.surveySections
                        .map(section => section.questions)
                        .reduce((a, b) => a.concat(b), [])
                        .map(question => question.items)
                        .reduce((a, b) => a.concat(b), [])
                        .filter(item => +item.itemId.slice(1) === itemId)[0].title;
                    handleSingleDimensionOrItemUpdate({
                        ...singleDimensionOrItem,
                        isSection: false,
                        measureId: itemId,
                        measureField: fieldName
                    });
                }
            }
        }
    }, [props.boxes, open]);

    return (
        <Dialog
            open
            onClose={props.onCancelClicked}
            maxWidth={"lg"}
            onKeyUp={e => {
                if (e.key === "Enter" && singleDimensionOrItem.measureId !== -1) {
                    confirmationClicked();
                }
            }}
        >
            <div data-testid="panel-dialog" className={classes.dialogContent}>
                <DialogTitle>
                    <Typography variant="h4">{lang.singleDimensionOrItem}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <SingleDimensionOrItemCellType
                        selectedPanelId={props.selectedPanelId}
                        boxes={props.boxes}
                        singleDimensionOrItemSize={singleDimensionOrItemSize}
                        selectedSingleDimensionOrItemSize={selectedSingleDimensionOrItemSize}
                        singleDimensionOrItemSizeSelected={handleSingleDimensionOrItemSizeSelected}
                        setSingleDimensionOrItemSize={handleSetSingleDimensionOrItemSize}
                        singleDimensionOrItem={singleDimensionOrItem}
                        surveySections={props.surveySections}
                        updateItem={handleSingleDimensionOrItemUpdate}
                        titleTranslation={titleTranslation}
                        updateTitleTranslation={handleUpdateTitleTranslation}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display="flex" gap={2} pr={2}>
                        <Button
                            onClick={confirmationClicked}
                            disabled={getConfirmButtonDisableRule()}
                            variant="text"
                            data-testid={"btn-ok"}
                        >
                            {lang.ok}
                        </Button>
                        <Button onClick={cancelClicked} variant="text" data-testid={"btn-cancel"}>
                            {lang.cancel}
                        </Button>
                    </Box>
                </DialogActions>
            </div>
        </Dialog>
    );
};

import React from "react";

import MuiFormControl, { FormControlProps } from "@mui/material/FormControl";

const FormControl = (props: FormControlProps) => {
    const { children, ...rest } = props;
    return <MuiFormControl {...rest}>{children}</MuiFormControl>;
};

export { FormControl };

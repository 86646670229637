import { OrganizationInfo, OrganizationLogo } from "../interfaces";

const emptyOrgLogo: OrganizationLogo = {
    logoUrl: "",
    originalFileName: ""
};

export const emptyOrganization: OrganizationInfo = {
    active: true,
    id: -1,
    name: "",
    organizationLogo: emptyOrgLogo,
    thirdPartyLogoBase64: "",
    thirdPartyLogoFileName: "",
    created: "",
    tags: [],
    isDashboardEnabled: false,
    isAIEnabled: false
};

import { colorClasses } from "components/reports/correlation/correlation.init";

export const correlationCellClassMapper = (value: string): string => {
    let className = "";

    if (value === "-" || value === "") return className;

    if (+value >= 0.6) {
        className = colorClasses.cell_above_60;
    } else if (+value >= 0.4) {
        className = colorClasses.cell_above_40;
    } else if (+value < 0.4) {
        className = colorClasses.cell_below_40;
    } else {
        className = "";
    }

    return className;
};

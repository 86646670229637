import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "lib/typography";

import { theme } from "lib/theme";
import { favorableColor, darkOrange, darkGreyColor } from "../../styles/GlobalStyles";

type Props = {
    value: number | null;
};
type DifferenceType = "na" | "overflow" | "up" | "down" | "equal";

const useStyles = makeStyles(() => ({
    benchmarkValue: {
        fontWeight: theme.typography.fontWeightMedium
    },
    arrowUp: {
        width: 0,
        height: 0,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: `5px solid ${favorableColor}`,
        marginLeft: 2
    },
    arrowDown: {
        width: 0,
        height: 0,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderTop: `5px solid ${darkOrange}`,
        marginLeft: 2
    },
    colorStyle: ({ differenceType }: { differenceType: DifferenceType }) => ({
        color: differenceType === "down" ? darkOrange : differenceType === "up" ? favorableColor : darkGreyColor
    })
}));

const getStringToRender = (value: number): string => {
    if (value > 0) {
        return "+" + value.toString();
    }
    return value.toString();
};

const getDifferenceType = (value: number): DifferenceType => {
    if (value > 100 || value < -100) {
        return "overflow";
    } else if (value > 0) {
        return "up";
    } else if (value < 0) {
        return "down";
    }
    return "equal";
};

const CustomValueCell = ({ value }: Props): ReactElement => {
    let strValue = "n/a";
    let differenceType: DifferenceType = "equal";

    if (value !== null && value !== undefined) {
        strValue = getStringToRender(value);
        differenceType = getDifferenceType(value);
    }

    const classes = useStyles({ differenceType });

    const iconClassName =
        differenceType === "up" ? classes.arrowUp : differenceType === "down" ? classes.arrowDown : undefined;
    const isShowingIcon = differenceType === "up" || differenceType === "down";

    return (
        <>
            <Typography variant="body1" className={`${classes.benchmarkValue} ${classes.colorStyle}`}>
                {strValue}
            </Typography>
            {isShowingIcon && <div className={iconClassName}></div>}
        </>
    );
};

export default CustomValueCell;

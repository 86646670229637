import { useState } from "react";
import { TitleTranslation } from "../interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";

interface SingleDimensionWithItemsSize {
    id: string;
    width: number;
    height: number;
    name: string;
}

interface DimensionWithItem {
    id: number;
    sectionField: string;
}

type SingleDimensionWithItemsState = {
    isBenchmarkHidden: boolean;
    setBenchmarkHidden: (newValue: boolean) => void;
    isPreviousPeriodHidden: boolean;
    setPreviousPeriodHidden: (newValue: boolean) => void;
    size: SingleDimensionWithItemsSize[];
    setSize: (newValue: SingleDimensionWithItemsSize[]) => void;
    selectedSingleDimensionWithItemsSize: string;
    setSelectedSingleDimensionWithItemsSize: (newValue: string) => void;
    singleDimensionWithItems: DimensionWithItem;
    handleSingleDimensionWithItemsUpdate: (item: DimensionWithItem) => void;
    titleTranslation: TitleTranslation[];
    setTitleTranslation: (newTitleTranslations: TitleTranslation[]) => void;
};

type InitState = {
    initBenchmarkHidden?: SingleDimensionWithItemsState["isBenchmarkHidden"];
    initPreviousPeriodHidden?: SingleDimensionWithItemsState["isPreviousPeriodHidden"];
    initSize?: SingleDimensionWithItemsState["size"];
    initSingleDimensionWithItemsSize?: SingleDimensionWithItemsState["selectedSingleDimensionWithItemsSize"];
    initSingleDimensionWithItems?: SingleDimensionWithItemsState["singleDimensionWithItems"];
    initTitleTranslation?: SingleDimensionWithItemsState["titleTranslation"];
};

export const useSingleDimensionWithItemsState = ({
    initBenchmarkHidden,
    initPreviousPeriodHidden,
    initSize,
    initSingleDimensionWithItemsSize,
    initSingleDimensionWithItems,
    initTitleTranslation
}: InitState): SingleDimensionWithItemsState => {
    const [isBenchmarkHidden, setBenchmarkHidden] = useState(initBenchmarkHidden || false);
    const [isPreviousPeriodHidden, setPreviousPeriodHidden] = useState(initPreviousPeriodHidden || false);
    const [size, setSize] = useState<SingleDimensionWithItemsSize[]>(initSize || []);
    const [selectedSingleDimensionWithItemsSize, setSelectedSingleDimensionWithItemsSize] = useState(
        initSingleDimensionWithItemsSize || ""
    );
    const [singleDimensionWithItem, setSingleDimensionWithItem] = useState(
        initSingleDimensionWithItems || {
            id: -1,
            sectionField: ""
        }
    );
    const [titleTranslation, setTitleTranslation] = useState<TitleTranslation[]>(
        initTitleTranslation || defaultItemTitleTranslation
    );

    const handleBenchmarkDataHidden = (newValue: boolean): void => {
        setBenchmarkHidden(newValue);
    };

    const handlePreviousPeriodHidden = (newValue: boolean): void => {
        setPreviousPeriodHidden(newValue);
    };

    const updateSize = (newValue: SingleDimensionWithItemsSize[]): void => {
        setSize(newValue);
    };

    const updateSelectedSingleDimensionOrItemSize = (newValue: string): void => {
        setSelectedSingleDimensionWithItemsSize(newValue);
    };

    const handleSingleDimensionWithItemsUpdate = (item: DimensionWithItem): void => {
        setSingleDimensionWithItem(item);
    };

    const handleUpdateTitleTranslation = (newTitleTranslations: TitleTranslation[]): void => {
        setTitleTranslation(newTitleTranslations);
    };
    return {
        isBenchmarkHidden,
        setBenchmarkHidden: handleBenchmarkDataHidden,
        isPreviousPeriodHidden,
        setPreviousPeriodHidden: handlePreviousPeriodHidden,
        size,
        setSize: updateSize,
        selectedSingleDimensionWithItemsSize,
        setSelectedSingleDimensionWithItemsSize: updateSelectedSingleDimensionOrItemSize,
        singleDimensionWithItems: singleDimensionWithItem,
        handleSingleDimensionWithItemsUpdate,
        titleTranslation,
        setTitleTranslation: handleUpdateTitleTranslation
    };
};

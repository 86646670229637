import React, { ReactElement, useState, useEffect, useImperativeHandle, forwardRef, MouseEvent } from "react";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import {
    SurveyComparison,
    ValueMap,
    ContactField,
    RemapFieldItem,
    Coordinate,
    FieldGroupMappedRawData,
    FieldValueMappedRawData,
    FieldMap,
    SectionMap,
    ItemMap,
    RemapGroup
} from "./interfaces";
import { initContactField, initRemapFieldItem } from "./init";
import { FilterMode } from "components/filters/enums";
import { useLang } from "core/hooks";
import Filters from "components/filters/Filters";
import { Filter, ReportFilter } from "components/filters/interfaces";
import { initFilter } from "components/filters/inits";
import { sortArray } from "core/helpers";
import { useAutoSizerStyles } from "core/styles";
import RenderTableRow from "./RenderTableRow";
import { filterConnectorFromBackEnd } from "components/filters/helper";
import { SelectOptionExpandablePanel, Snackbar as ISnackbar } from "components/shared/interfaces";
import { useQuery } from "@apollo/client";
import { demographicFieldsAndValuesQuery } from "api/queries";
import { HrisFieldInfo } from "managerPortal/interfaces";
import Loading from "components/shared/Loading";

type Props = {
    sourceContactFields?: ContactField[];
    targetContactFields?: ContactField[];
    targetSurveyItemsOptions?: SelectOptionExpandablePanel[];
    surveyItemsOptions?: SelectOptionExpandablePanel[];
    surveyComparisons: SurveyComparison;
    remapGroup: RemapGroup;
    editSurveyComparisonField: (surveyComparisonId: number, sourceFieldId: number, targetFieldId: number) => void;
    editSurveyComparisonValue: (
        surveyComparisonId: number,
        sourceFieldId: number,
        sourceValue: string,
        filter: Filter
    ) => void;
    setSnackBar: (snackbarContent: ISnackbar) => void;
    selectMapSource: (id: number, anchorPoints: Coordinate) => void;
    setCollapseAllDisable: () => void;
    setExpandAllDisable: () => void;
    setCollapseAndExpandEnable: () => void;
    isLocked: boolean;
    setLockConfirmationDialog: () => void;
    clearSectionMapping: (sectionId: string) => void;
};

const getContactField = (contactFieldList: ContactField[], fieldId?: number): ContactField => {
    const contactFieldFound = contactFieldList.find((contactField: ContactField) => contactField.fieldId === fieldId);

    if (contactFieldFound) return contactFieldFound;

    return initContactField;
};

const mapSurveyItemRawData = (
    surveyComparison: SurveyComparison,
    surveyItemsOptions: SelectOptionExpandablePanel[],
    targetSurveyItemsOptions: SelectOptionExpandablePanel[]
): (SectionMap | ItemMap)[] => {
    const surveyItemData: (SectionMap | ItemMap)[] = [];
    surveyItemsOptions.forEach((section: SelectOptionExpandablePanel) => {
        surveyItemData.push({
            sectionId: section.id,
            surveySection: section.title,
            totalValues: 0,
            totalMapped: 0
        });
        section.subNode!.forEach((item: SelectOptionExpandablePanel) => {
            const targetId = surveyComparison.fieldMaps.find(
                (fieldMap: FieldMap) => fieldMap.sourceFieldId === +item.id
            )?.targetFieldId;

            let targetTitle = "";
            if (targetId) {
                targetSurveyItemsOptions.forEach((targetSection: SelectOptionExpandablePanel) => {
                    targetSection.subNode?.forEach((targetItem: SelectOptionExpandablePanel) => {
                        if (+targetItem.id === targetId) {
                            targetTitle = targetItem.title;
                            return;
                        }
                    });
                });
            }

            surveyItemData.push({
                sourceSectionBuildId: section.id,
                sourceItemTitle: item.title,
                sourceItemId: item.id,
                targetItemTitle: targetTitle,
                targetItemId: targetId ? targetId.toString() : ""
            });
        });
    });

    surveyItemData.forEach((surveySection: SectionMap | ItemMap, index: number) => {
        if ("surveySection" in surveySection) {
            const countTotal = surveyItemData.filter(
                (surveyItem: SectionMap | ItemMap) =>
                    "sourceSectionBuildId" in surveyItem &&
                    surveyItem.sourceSectionBuildId.substring(0, surveySection.sectionId.length) ===
                        surveySection.sectionId
            ).length;

            const countMapped = surveyItemData.filter(
                (surveyItem: SectionMap | ItemMap) =>
                    "sourceSectionBuildId" in surveyItem &&
                    surveyItem.sourceSectionBuildId.substring(0, surveySection.sectionId.length) ===
                        surveySection.sectionId &&
                    surveyItem.targetItemId !== ""
            ).length;
            surveyItemData[index] = { ...surveyItemData[index], totalValues: countTotal, totalMapped: countMapped };
        }
    });

    return surveyItemData;
};

const mapContactFieldRawData = (
    surveyComparison: SurveyComparison,
    sourceContactFields: ContactField[],
    targetContactFields: ContactField[]
): (FieldGroupMappedRawData | FieldValueMappedRawData)[] => {
    const mapItem = (valueMap: ValueMap): FieldValueMappedRawData => {
        return {
            sourceFieldId: valueMap.sourceFieldId,
            sourceFieldName: getContactField(sourceContactFields, valueMap.sourceFieldId).title,
            sourceValueName: valueMap.sourceValue,
            targetFilters: !valueMap.targetFilters
                ? []
                : valueMap.targetFilters.map((targetFilter: ReportFilter) => {
                      return {
                          combinationOperator: targetFilter.combinationOperator,
                          comparisonOperator: targetFilter.comparisonOperator,
                          field: {
                              id: targetFilter.id,
                              fieldName: getContactField(targetContactFields, targetFilter.id).title
                          },
                          filterType: targetFilter.filterType,
                          target: targetFilter.target
                      };
                  })
        };
    };

    const getAllEntries = surveyComparison.valueMaps.reduce(
        (_surveyComparison: { [key: string]: FieldValueMappedRawData[] }, valueMap: ValueMap) => {
            const contactField = sourceContactFields.find(
                (contactField: ContactField) => contactField.fieldId === valueMap.sourceFieldId
            );

            _surveyComparison[contactField!.title] = _surveyComparison[contactField!.title]
                ? [..._surveyComparison[contactField!.title], mapItem(valueMap)]
                : [mapItem(valueMap)];

            return _surveyComparison;
        },
        {}
    );

    let compiledArray: (FieldGroupMappedRawData | FieldValueMappedRawData)[] = [];

    sortArray(sourceContactFields, "title", "asc").forEach((contactField: ContactField) => {
        const allEntries = getAllEntries[contactField.title];
        if (allEntries) {
            compiledArray.push({
                fieldGroup: contactField.title,
                fieldId: contactField.fieldId,
                totalValues: allEntries ? allEntries.length : 0,
                totalMapped: allEntries.filter(
                    (e: FieldValueMappedRawData) => e.targetFilters && e.targetFilters.length
                ).length
            });
            compiledArray = compiledArray.concat(allEntries);
        }
    });

    return compiledArray;
};

const filterItemsToRender = (
    _openedPanels: string[],
    _mainPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[]
): (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[] => {
    return _mainPanelData.filter((item: FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap) => {
        return (
            "surveySection" in item ||
            "fieldGroup" in item ||
            ("sourceFieldName" in item && _openedPanels.includes(item.sourceFieldName)) ||
            ("sourceSectionBuildId" in item && _openedPanels.includes(item.sourceSectionBuildId))
        );
    });
};

type State = {
    remapFieldItem: RemapFieldItem;
    openedPanels: string[];
    mainPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[];
    renderPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[];
};

export type PreviousPeriodTableHandle = {
    collapseAll: () => void;
    expandAll: () => void;
};

export const PreviousPeriodTable = forwardRef<PreviousPeriodTableHandle, Props>((props: Props, ref): ReactElement => {
    const {
        surveyItemsOptions,
        selectMapSource,
        setSnackBar,
        remapGroup,
        sourceContactFields,
        targetContactFields,
        surveyComparisons,
        targetSurveyItemsOptions,
        setCollapseAllDisable,
        editSurveyComparisonValue,
        setExpandAllDisable,
        setCollapseAndExpandEnable
    } = props;

    const defaultRowHeight = 48;
    const { lang, languageCode } = useLang();
    const autoSizerClass = useAutoSizerStyles();
    const [demographics, setDemographics] = useState<HrisFieldInfo[]>([]);

    const { loading } = useQuery(demographicFieldsAndValuesQuery, {
        variables: { surveyId: surveyComparisons.targetSurveyId, languageCode },
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                const hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setDemographics(sortedDemographic);
            }
        }
    });

    const [state, setState] = useState<State>(() => {
        let mainPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[] = [];
        if (sourceContactFields && targetContactFields) {
            mainPanelData = mapContactFieldRawData(surveyComparisons, sourceContactFields, targetContactFields);
        }
        if (surveyItemsOptions && targetSurveyItemsOptions) {
            mainPanelData = mapSurveyItemRawData(surveyComparisons, surveyItemsOptions, targetSurveyItemsOptions);
        }

        return {
            remapFieldItem: initRemapFieldItem,
            openedPanels: [],
            renderPanelData: filterItemsToRender([], mainPanelData),
            mainPanelData
        };
    });

    useEffect(() => {
        let updateMainPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[] = [];
        if (sourceContactFields && targetContactFields) {
            updateMainPanelData = mapContactFieldRawData(surveyComparisons, sourceContactFields, targetContactFields);
        }
        if (surveyItemsOptions && targetSurveyItemsOptions) {
            updateMainPanelData = mapSurveyItemRawData(surveyComparisons, surveyItemsOptions, targetSurveyItemsOptions);
        }

        setState({
            ...state,
            mainPanelData: updateMainPanelData,
            renderPanelData: filterItemsToRender(state.openedPanels, updateMainPanelData)
        });
    }, [surveyComparisons, sourceContactFields, targetContactFields, surveyItemsOptions, targetSurveyItemsOptions]);

    const handleExpand = (fieldName: string): void => {
        let openedPanels = [...state.openedPanels, fieldName];
        if (state.openedPanels.includes(fieldName)) {
            openedPanels = state.openedPanels.filter(
                (itemOpen: string) => fieldName !== itemOpen.substring(0, fieldName.length)
            );
        }
        setState({
            ...state,
            openedPanels,
            renderPanelData: filterItemsToRender(openedPanels, state.mainPanelData)
        });
    };

    const handleRemapFieldItem = (appliedFilter: Filter): void => {
        if (state.remapFieldItem.sourceFieldId) {
            editSurveyComparisonValue(
                surveyComparisons.id,
                state.remapFieldItem.sourceFieldId,
                state.remapFieldItem.sourceValue,
                appliedFilter
            );
            return;
        }
        setSnackBar({ isOpen: true, message: lang.unableToMapValue });
    };

    const handleCloseFilter = (): void => {
        setState({ ...state, remapFieldItem: initRemapFieldItem });
    };

    const handleSelectFieldGroup = (event: MouseEvent<HTMLButtonElement>, fieldId: ContactField["fieldId"]): void => {
        selectMapSource(fieldId, { top: event.clientY, left: event.clientX });
    };

    const handleSelectFieldItem = (
        event: MouseEvent<HTMLButtonElement>,
        fieldId: string | number,
        fieldValue: string
    ): void => {
        if (sourceContactFields && targetContactFields) {
            const valueMap = surveyComparisons.valueMaps.find(
                (valueMap: ValueMap) => valueMap.sourceValue === fieldValue && fieldId === valueMap.sourceFieldId
            );
            let setFilter = initFilter;
            if (valueMap && valueMap.targetFilters && valueMap.targetFilters.length) {
                setFilter = {
                    ...setFilter,
                    items: filterConnectorFromBackEnd(valueMap.targetFilters, targetContactFields!)
                };
            }
            setState({
                ...state,
                remapFieldItem: {
                    ...state.remapFieldItem,
                    sourceFieldId: +fieldId,
                    sourceValue: fieldValue,
                    filter: setFilter
                }
            });
        } else {
            if (typeof fieldId === "string") {
                selectMapSource(+fieldId, {
                    top: event.clientY,
                    left: event.clientX
                });
            }
        }
    };

    useImperativeHandle(ref, () => ({
        collapseAll(): void {
            const updatedRenderPanelData = [] as (
                | FieldGroupMappedRawData
                | FieldValueMappedRawData
                | SectionMap
                | ItemMap
            )[];
            state.mainPanelData.forEach(data => {
                if ("sectionId" in data || "fieldGroup" in data) {
                    updatedRenderPanelData.push(data);
                }
            });
            setState({
                ...state,
                openedPanels: [],
                renderPanelData: updatedRenderPanelData
            });
        },
        expandAll(): void {
            const updatedOpenedPanels = [] as string[];
            state.mainPanelData.forEach(data => {
                if ("sectionId" in data) {
                    updatedOpenedPanels.push(data.sectionId);
                }
                if ("fieldGroup" in data) {
                    updatedOpenedPanels.push(data.fieldGroup);
                }
            });
            setState({
                ...state,
                openedPanels: updatedOpenedPanels,
                renderPanelData: [...state.mainPanelData]
            });
        }
    }));

    useEffect(() => {
        let expandAllLength = 0;
        state.mainPanelData.forEach(data => {
            if ("sectionId" in data || "fieldGroup" in data) {
                expandAllLength++;
            }
        });
        if (state.openedPanels.length === 0 && setCollapseAllDisable) {
            setCollapseAllDisable();
        } else if (state.openedPanels.length === expandAllLength && setExpandAllDisable) {
            setExpandAllDisable();
        } else if (setCollapseAndExpandEnable) {
            setCollapseAndExpandEnable();
        }
    }, [state.openedPanels]);

    const isRowSelected = (index: number): boolean => {
        const item = state.renderPanelData[index];
        if ("sourceItemId" in item) {
            return item.sourceItemId.toString() === remapGroup.sourceFieldId?.toString();
        }
        return false;
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <AutoSizer className={autoSizerClass.autoSizer}>
                {({ height, width }): ReactElement => (
                    <List
                        height={height}
                        itemCount={state.renderPanelData.length}
                        itemSize={defaultRowHeight}
                        width={width}
                    >
                        {({ index, style }): ReactElement =>
                            RenderTableRow({
                                index,
                                style,
                                width,
                                renderPanelData: state.renderPanelData,
                                openedPanels: state.openedPanels,
                                onExpand: handleExpand,
                                onSelectFieldGroup: handleSelectFieldGroup,
                                onSelectFieldItem: handleSelectFieldItem,
                                isSurveyItem: !sourceContactFields && !targetContactFields,
                                isSelected: isRowSelected(index),
                                isLocked: props.isLocked,
                                setLockConfirmationDialog: props.setLockConfirmationDialog,
                                clearSectionMapping: props.clearSectionMapping
                            })
                        }
                    </List>
                )}
            </AutoSizer>

            {state.remapFieldItem.sourceFieldId !== null && (
                <Filters
                    onCloseFilter={handleCloseFilter}
                    isOpen={state.remapFieldItem.sourceFieldId !== null}
                    onFiltersApplied={handleRemapFieldItem}
                    currentFilter={state.remapFieldItem.filter}
                    filterMode={FilterMode.previousPeriod}
                    surveyId={surveyComparisons.targetSurveyId}
                    contactFields={demographics}
                />
            )}
        </>
    );
});
PreviousPeriodTable.displayName = "PreviousPeriodTable";

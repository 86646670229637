import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useInsightStyles = makeStyles(() => ({
    dataContent: {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1040px 1fr",
        gridTemplateAreas: `"sidebar insightGrid"`,
        boxSizing: "border-box",
        justifyContent: "center",
        height: "calc(100% - 72px)",
        overflowY: "auto"
    },
    sidebar: {
        borderRight: theme.border.main,
        gridArea: "sidebar",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    comment: {
        gridArea: "insightGrid",
        boxSizing: "border-box",
        overflowY: "auto",
        overflowX: "hidden"
    },
    infoHeader: {
        height: 72,
        display: "flex",
        borderBottom: theme.border.main,
        backgroundColor: theme.palette.background.default
    },
    infoHeaderItem_dropDown: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 480,
        paddingRight: 24,
        backgroundColor: theme.palette.background.default
    },
    surveyItemContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 480,
        paddingRight: 24,
        backgroundColor: theme.palette.background.default
    },
    surveyItemButton: {
        display: "flex",
        textTransform: "none",
        width: 458,
        height: 52,
        borderBottom: "solid thin darkgrey",
        borderRadius: 2,
        marginLeft: 20,
        backgroundColor: "white"
    },
    surveyItemButtonTextWrapper: {
        display: "flex",
        width: 450,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10
    },
    surveyItemButtonText: {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 14,
        textAlign: "left"
    },
    surveyItemButtonLabelWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "90%"
    },
    surveyItemButtonLabel: {
        fontSize: 12,
        fontWeight: 400
    },
    comparisonChartActions: {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto"
    }
}));

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";

import { SelectItem } from "core/interfaces";
import { useLang } from "core/hooks";
import { CombinationOperator, ComparisonOperatorMap, FilterType } from "./enums";
import { initFilterParameter } from "./inits";
import { FilterParameter, Target } from "./interfaces";
import { theme } from "lib/theme";
import { ButtonGroup } from "lib/button-group";
import MuiButton from "@mui/material/Button";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";

type StyleProps = {
    isSelected: boolean;
};

const useStyles = makeStyles(() => ({
    cardContainer: (styleProps: StyleProps) => ({
        display: "flex",
        minHeight: 92,
        width: "calc(100% - 40px)",
        padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)}`,
        backgroundColor: styleProps.isSelected ? theme.palette.action.selected : "inherit",
        borderBottom: theme.border.main,
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
            background: theme.palette.action.hover
        }
    }),
    outerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    coContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 12,
        paddingBottom: 12,
        width: "100%",
        borderBottom: theme.border.main
    },
    filterContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "90%"
    },
    deleteBtnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "10%"
    },
    newFilter: {
        fontStyle: "italic",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.disabled
    }
}));

type Props = {
    onDeleteFilterItem: (index: number) => void;
    filterField: FilterParameter;
    isSelected: boolean;
    onSelectFilterItem: (index: number) => void;
    onSetCombinationOperator: (param: FilterParameter, opr: CombinationOperator) => void;
    index: number;
};

const FilterCard = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { onDeleteFilterItem, filterField, isSelected, onSelectFilterItem, onSetCombinationOperator } = props;
    const classes = useStyles({
        isSelected
    });

    const comparisonOperator = ComparisonOperatorMap(lang).find(
        (item: SelectItem) => item.value === filterField.comparisonOperator
    );

    return (
        <div className={classes.outerContainer}>
            {filterField.order > 0 && (
                <div className={classes.coContainer}>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="inherit"
                        sx={{
                            "& button": {
                                height: 40,
                                width: 72,
                                borderColor: theme.palette.action.hover
                            },
                            "& button:hover": {
                                borderColor: theme.palette.action.hover
                            }
                        }}
                        data-testid="filterOperatorBtnGroup"
                    >
                        <MuiButton
                            sx={{
                                "&.MuiButton-root": {
                                    background:
                                        filterField.combinationOperator === CombinationOperator.and
                                            ? theme.palette.action.selected
                                            : "white"
                                },
                                "&:hover": {
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                            onClick={(): void => {
                                onSetCombinationOperator(filterField, CombinationOperator.and);
                            }}
                            autoFocus={false}
                            data-testid="filterOperatorBtnGroupAND"
                        >
                            {lang.and}
                        </MuiButton>
                        <MuiButton
                            sx={{
                                "&.MuiButton-root": {
                                    background:
                                        filterField.combinationOperator === CombinationOperator.or
                                            ? theme.palette.action.selected
                                            : "white"
                                },
                                "&:hover": {
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                            onClick={(): void => {
                                onSetCombinationOperator(filterField, CombinationOperator.or);
                            }}
                            autoFocus={false}
                            data-testid="filterOperatorBtnGroupOR"
                        >
                            {lang.or}
                        </MuiButton>
                    </ButtonGroup>
                </div>
            )}
            <div className={classes.cardContainer} onClick={(): void => onSelectFilterItem(props.index)}>
                <div className={classes.filterContentContainer}>
                    {filterField.filterType === FilterType.date ? (
                        <Typography variant="body1">{lang.date}</Typography>
                    ) : filterField.field.id === initFilterParameter.field.id ? (
                        <Typography variant="body1" className={classes.newFilter}>
                            {lang.newFilter}
                        </Typography>
                    ) : (
                        <Typography variant="body1">{filterField.field.fieldName}</Typography>
                    )}

                    {filterField.filterType === FilterType.date && (
                        <>
                            <Typography variant="body1">
                                {filterField.target[0] &&
                                    filterField.target[0].id.length > 0 &&
                                    `${lang.startDate}: ${filterField.target[0].id}`}
                            </Typography>
                            <Typography variant="body1">
                                {filterField.target[1] &&
                                    filterField.target[1].id.length > 0 &&
                                    `${lang.endDate}: ${filterField.target[1].id}`}
                            </Typography>
                        </>
                    )}
                    {filterField.filterType === FilterType.contactField &&
                        filterField.field.id !== initFilterParameter.field.id && (
                            <>
                                <Typography variant="subtitle2">{comparisonOperator?.text}</Typography>
                                <Typography variant="body1">
                                    {filterField.target.map((target: Target, index: number) => {
                                        return index > 0 ? `, ${target.id}` : target.id;
                                    })}
                                </Typography>
                            </>
                        )}
                    {(filterField.filterType === FilterType.surveyItemPickMany ||
                        filterField.filterType === FilterType.surveyItemPickOne) &&
                        filterField.field.id !== initFilterParameter.field.id && (
                            <>
                                <Typography variant="subtitle2">{comparisonOperator?.text}</Typography>
                                <Typography variant="body1">
                                    {filterField.target.map((target: Target, index: number) => {
                                        return index > 0 ? `, ${target.text}` : target.text;
                                    })}
                                </Typography>
                            </>
                        )}
                </div>
                <div className={classes.deleteBtnContainer}>
                    <IconButton
                        aria-label="delete"
                        data-testid="filter-delete"
                        onClick={(event): void => {
                            event.stopPropagation();
                            onDeleteFilterItem(props.index);
                        }}
                    >
                        <DeleteIcon fontSize="medium" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default FilterCard;

import { Scale } from "../interfaces";

export const initScale: Scale = {
    id: -1,
    surveyId: -1,
    name: "",
    isVisible: false,
    isFunScale: true,
    isModified: false,
    scaleValues: []
};

import { useState } from "react";

export const useReviewBenchmarkState = (
    initValue?: number
): [number | undefined, (newValue: number | undefined) => void] => {
    const [previousPeriod, setPreviousPeriod] = useState(initValue || undefined);

    const updatePreviousPeriod = (newValue: number | undefined): void => {
        setPreviousPeriod(newValue);
    };

    return [previousPeriod, updatePreviousPeriod];
};

import React, { ReactElement } from "react";
import { NavigationType } from "../header/enums";
import { NavigationOption } from "../header/interfaces";
import LinkButton from "./LinkButton";
import SelectOrganization from "./SelectOrganization";
import Dropdown from "./Dropdown";
import HyperLink from "./HyperLink";
import DrawerDropdown from "./DrawerDropdown";

type Props = {
    item: NavigationOption;
    dataTestId: string;
    handleOpenSelectOrganization: () => void;
    handleSelectChange: (selectedValue: number, selectKey: string) => void;
};

const RenderNavigationItem: React.FC<Props> = (props): ReactElement => {
    switch (props.item.navType) {
        case NavigationType.dropdownWithIcon:
            return (
                <Dropdown
                    selectOptions={props.item.selectOptions!}
                    navigationKey={props.item.navigationKey}
                    onSelect={props.handleSelectChange}
                    icon={props.item.icon}
                    align={props.item.align}
                    disabled={props.item.disabled}
                    hidden={props.item.hidden}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.dropdownWithText:
            return (
                <Dropdown
                    selectOptions={props.item.selectOptions!}
                    navigationKey={props.item.navigationKey}
                    onSelect={props.handleSelectChange}
                    label={props.item.label}
                    align={props.item.align}
                    disabled={props.item.disabled}
                    hidden={props.item.hidden}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.drawerDropdown:
            return (
                <DrawerDropdown
                    selectOptions={props.item.selectOptions!}
                    navigationKey={props.item.navigationKey}
                    onSelect={props.handleSelectChange}
                    label={props.item.label}
                    align={props.item.align}
                    disabled={props.item.disabled}
                    hidden={props.item.hidden}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.linkWithText:
            return (
                <LinkButton
                    linkTo={props.item.linkTo!}
                    label={props.item.label}
                    align={props.item.align}
                    disabled={props.item.disabled}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.linkWithIcon:
            return (
                <LinkButton
                    linkTo={props.item.linkTo!}
                    icon={props.item.icon}
                    align={props.item.align}
                    disabled={props.item.disabled}
                    padding={props.item.padding}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.openDialog:
            return (
                <SelectOrganization
                    onClick={props.handleOpenSelectOrganization}
                    align={props.item.align}
                    data-testid={props.dataTestId}
                />
            );
        case NavigationType.hyperLinkWithIcon:
            return (
                <HyperLink
                    linkTo={props.item.linkTo!}
                    icon={props.item.icon}
                    align={props.item.align}
                    data-testid={props.dataTestId}
                />
            );
        default:
            return <></>;
    }
};

export default RenderNavigationItem;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from "react";
import OrgChartCard from "./cards/OrgChartCard";
import OrgChartLayout from "../layout/OrgChartLayout";
import { DEFAULT_CARD_WIDTH } from "../enums/Enums";
import { sortArray } from "../../core/helpers";

export default class OrgChart extends Component {
    state = {
        showParents: false,
        showPeers: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.layout === nextProps.layout) {
            return null;
        }
        return {
            layout: nextProps.layout,
            cardHeight: null
        };
    }

    handleShowHideComparison = key => {
        this.setState(prevState => ({
            [key]: !prevState[key]
        }));
    };

    render() {
        const { showComments, visibleNodes, threshold } = this.props;
        const { layout, cardHeight, showParents, showPeers } = this.state;

        const sortedTree = { ...visibleNodes.tree };

        sortArray(sortedTree.kids, "title", "asc");

        const setHeight = height => {
            this.setState({ cardHeight: height });
        };

        // This function doesn't render anything, it is just a method of getting height ogf the card.
        // Probably there is a better way of doing so...
        if (!cardHeight) {
            return (
                <div
                    ref={el => {
                        if (!el) return;
                        setHeight(el.getBoundingClientRect().height);
                    }}
                >
                    <OrgChartCard
                        values={visibleNodes.tree}
                        layout={layout}
                        width={DEFAULT_CARD_WIDTH}
                        hide={true}
                        canExpand={true}
                    />
                </div>
            );
        }
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <OrgChartLayout
                    hierarchy={sortedTree}
                    parents={visibleNodes.parents}
                    peers={visibleNodes.peers}
                    layout={layout}
                    cardWidth={DEFAULT_CARD_WIDTH}
                    cardHeight={cardHeight}
                    showComments={showComments}
                    threshold={threshold}
                    showParents={showParents}
                    showPeers={showPeers}
                    handleShowHideComparison={key => this.handleShowHideComparison(key)}
                />
            </div>
        );
    }
}

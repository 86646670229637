/* eslint-disable react/display-name */
import React, { CSSProperties, ReactElement, memo } from "react";
import { areEqual, FixedSizeList as List } from "react-window";
import memoize from "memoize-one";
import TruncateDisplay from "../../managerPortal/components/shared/TruncateDisplay";
import { makeStyles } from "@mui/styles";
import { AutoSizer } from "react-virtualized";
import { Checkbox } from "lib/checkbox";
import { Typography } from "lib/typography";

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedData: any[];
    displayKey: string;
    IDKey: string;
    onSelect: (index: number) => void;
    height: number | string;
    width: number | string;
    margin: number | string;
};

const useStyles = makeStyles(() => ({
    list: {
        height: "100%",
        width: "100%"
    },
    checkContainer: {
        display: "flex !important",
        alignItems: "center",
        width: "100% ",
        cursor: "pointer "
    },

    titleContainer: {
        display: "flex !important",
        alignItems: "center",
        width: "100%",
        cursor: "default"
    },
    checkbox: {
        marginRight: 32
    }
}));

type ListProps = {
    data: {
        items: { [key: string]: string | number }[];
        onSelect: (index: number) => void;
        displayKey: string;
        selectedData: { [key: string]: string | number }[];
        IDKey: string;
    };
    index: number;
    style: CSSProperties;
};

const Row = memo((listProps: ListProps): ReactElement => {
    const classes = useStyles();
    const { data, index, style } = listProps;
    const { items, onSelect, displayKey, selectedData, IDKey } = data;
    const item = items[index];
    const checked: boolean = !IDKey
        ? false
        : selectedData.find((i: { [key: string]: string | number }) => item[IDKey] === i[IDKey])
          ? true
          : false;

    return (
        <div className={classes.checkContainer} onClick={(): void => onSelect(item[IDKey] as number)} style={style}>
            <Checkbox checked={checked} className={classes.checkbox} />
            <TruncateDisplay maxLabelLength={30} title={item[displayKey]}>
                <Typography noWrap variant="body2">
                    {item[displayKey]}
                </Typography>
            </TruncateDisplay>
        </div>
    );
}, areEqual);

const createItemData = memoize((items, onSelect, displayKey, selectedData, IDKey) => ({
    items,
    onSelect,
    displayKey,
    selectedData,
    IDKey
}));

const CheckboxList = (props: Props): ReactElement => {
    const classes = useStyles();
    const listProps = {
        itemCount: props.data.length,
        itemSize: 50
    };

    const itemData = createItemData(props.data, props.onSelect, props.displayKey, props.selectedData, props.IDKey);

    return (
        <div className={classes.list}>
            <AutoSizer>
                {({ height, width }): JSX.Element => (
                    <List {...listProps} height={height} width={width} itemData={itemData}>
                        {Row}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default CheckboxList;

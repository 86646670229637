import React, { ReactElement, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DialogLayout } from "components/shared";
import { useLang } from "core/hooks";
import { useLookdownState } from "./useLookdownState";
import type { Props as PanelDialogProps } from "../PanelDialog";
import { Box as BoxType } from "../interfaces";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { QuestionType } from "managerPortal/context/enums";
import { HrisFieldInfo } from "managerPortal/interfaces";
import SelectExpandableList from "managerPortal/components/shared/SelectExpandableList";
import {
    getAvailableSurveySectionsOnlyPickOneAndENPS,
    surveySectionsPickOneWithIsFunAndENPSItemId
} from "core/helpers";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Checkbox } from "lib/checkbox";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { SelectChangeEvent } from "lib/select-custom";
import { Select } from "lib/select-custom";
import { Typography } from "lib/typography";
import { useUser } from "core/context/user/useUser";

type Props = {
    contactFields: HrisFieldInfo[];
    surveySections: SurveySection[];
    onClose: () => void;
    updatePanelItem: PanelDialogProps["updatePanelItem"];
    hasPreviousPeriod: boolean;
    box?: BoxType;
};

const availableSizes = [
    { id: "5 x 4", width: 5, height: 4, name: "5 x 4" },
    { id: "6 x 5", width: 6, height: 5, name: "6 x 5" }
];

const parseJSON = (str: string): Record<string, unknown> => {
    return JSON.parse(str);
};

const getInitSize = (box: BoxType): string => {
    const size = `${box.width} x ${box.height}` as string;
    return size;
};

const getInitHiddenField = (box: BoxType | undefined, attribute: string): boolean => {
    return box && box.props && parseJSON(box.props)[attribute] ? !!parseJSON(box.props)[attribute] : false;
};

export const Lookdown = ({
    onClose,
    updatePanelItem,
    hasPreviousPeriod,
    box,
    surveySections,
    contactFields
}: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();
    const {
        isPreviousPeriodHidden,
        setPreviousPeriodHidden,
        size,
        setSize,
        selectedLookdownSize,
        setSelectedLookdownSize,
        lookdown,
        handleLookdownUpdate,
        titleTranslation,
        setTitleTranslation
    } = useLookdownState({
        initPreviousPeriodHidden: getInitHiddenField(box, "hidePPDelta"),
        initSize: availableSizes,
        initLookdownSize: box && getInitSize(box),
        initLookdown: {
            isSection: false,
            demographicFieldId: -1,
            measureId: -1,
            measureField: ""
        },
        initTitleTranslation: box && box.translations
    });
    const [showSurveyItemList, setShowSurveyItemList] = useState<boolean>(false);

    const surveySection = (): SurveySection[] => {
        return surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter(
                        (question: SurveyQuestion) =>
                            (question.questionType === QuestionType.PickOne && question.isFun) ||
                            question.questionType === QuestionType.ENPS
                    )
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };

    const handleSurveyItemSelect = (id: string, level: number, title: string): void => {
        setShowSurveyItemList(false);
        if (level === 0) {
            handleLookdownUpdate({
                ...lookdown,
                isSection: true,
                measureId: +id.slice(1),
                measureField: title
            });
        } else if (level === 1) {
            handleLookdownUpdate({
                ...lookdown,
                isSection: false,
                measureId: +id.slice(1),
                measureField: title
            });
        }
    };

    const getWidth = (): number => {
        const sizeSelected = selectedLookdownSize.split("x")[0];
        return parseInt(sizeSelected.trim());
    };
    const getHeight = (): number => {
        const sizeSelected = selectedLookdownSize.split("x")[1];
        return parseInt(sizeSelected.trim());
    };

    const handleupdatePanelItem = (): void => {
        updatePanelItem(
            getWidth(),
            getHeight(),
            "lookdown",
            JSON.stringify({
                isSection: lookdown.isSection,
                demographicFieldId: lookdown.demographicFieldId,
                id: lookdown.measureId,
                hidePPDelta: isPreviousPeriodHidden
            }),
            titleTranslation
        );
    };
    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "englishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "en" ? { languageCode: "en", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "frenchTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "fr" ? { languageCode: "fr", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "spanishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "es" ? { languageCode: "es", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
    };

    const handleLookdownFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            clearLookdownField();
            return;
        }
        handleLookdownUpdate({
            ...lookdown,
            demographicFieldId: value.id
        });
    };
    const handleListOnBlur = (): void => {
        if (showSurveyItemList) {
            setShowSurveyItemList(false);
        }
    };
    const handleOpenSurveyItemList = (): void => {
        setShowSurveyItemList(!showSurveyItemList);
    };
    const getConfirmButtonDisableRule = (): boolean => {
        return lookdown.measureId === -1 || lookdown.demographicFieldId === -1;
    };
    const getDisabledSectionIds = (): SelectOptionExpandablePanel["id"][] => {
        const allItemsRemovedOverallScoreSectionIds = [] as SelectOptionExpandablePanel["id"][];
        const availableSurveySections = getAvailableSurveySectionsOnlyPickOneAndENPS(surveySection(), user);
        availableSurveySections.forEach(section => {
            const items = section.questions.map(question => question.items).reduce((a, b) => a.concat(b));
            if (items.every(item => item.isRemovedOverallScore === true)) {
                allItemsRemovedOverallScoreSectionIds.push(section.sectionId.slice(1));
            }
            if (section.questions.every(question => question.questionType === QuestionType.ENPS)) {
                allItemsRemovedOverallScoreSectionIds.push(section.sectionId.slice(1));
            }
        });
        return allItemsRemovedOverallScoreSectionIds;
    };
    const clearLookdownField = (): void => {
        handleLookdownUpdate({
            ...lookdown,
            demographicFieldId: -1
        });
    };

    useEffect(() => {
        if (box) {
            setSize(
                availableSizes.filter(
                    item =>
                        item.width <= GRID_COL_LENGTH - box.left / boxSize &&
                        item.height <= GRID_ROW_LENGTH - box.top / boxSize
                )
            );
            if (box.props) {
                const properties = box.props;
                const isSection = JSON.parse(properties).isSection === true;
                if (isSection) {
                    const sectionId = JSON.parse(properties).id;
                    const fieldName = surveySections.filter(section => +section.sectionId.slice(1) === sectionId)[0]
                        .title;
                    handleLookdownUpdate({
                        ...lookdown,
                        isSection: true,
                        measureId: sectionId,
                        measureField: fieldName,
                        demographicFieldId: JSON.parse(properties).demographicFieldId
                    });
                } else {
                    const itemId = JSON.parse(properties).id;
                    const fieldName = surveySections
                        .map(section => section.questions)
                        .reduce((a, b) => a.concat(b), [])
                        .map(question => question.items)
                        .reduce((a, b) => a.concat(b), [])
                        .filter(item => +item.itemId.slice(1) === itemId)[0].title;
                    handleLookdownUpdate({
                        ...lookdown,
                        isSection: false,
                        measureId: itemId,
                        measureField: fieldName,
                        demographicFieldId: JSON.parse(properties).demographicFieldId
                    });
                }
            }
        }
    }, [box, open]);

    const selectedLookdownField = (id: number) => {
        const found = contactFields.find(demographic => demographic.fieldId === id);
        if (found) {
            return {
                id: found.fieldId,
                label: found.fieldName
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    const onSelectedLookdownSize = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            setSelectedLookdownSize(value as string);
        }
    };

    const typographySelect = {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        flexGrow: 1
    };

    const selectContent = {
        display: "flex",
        textTransform: "none",
        width: 396,
        height: 53,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderBottom: "solid thin darkgrey",
        backgroundColor: "rgba(0,0,0,0.06)"
    };

    return (
        <DialogLayout
            title={lang.lookdown}
            onClose={onClose}
            open
            onClick={handleupdatePanelItem}
            buttonLabelAction={lang.ok}
            suppressContentPadding
            disableActionButton={getConfirmButtonDisableRule()}
            hiddenOverflow
        >
            <Box display="flex" flexDirection="column" pt={2} pb={2} pl={3} pr={3} gap={2}>
                <Select
                    data-testid="select-Lookdown-size"
                    items={size.map(s => {
                        return { value: s.id, name: s.name };
                    })}
                    onChange={onSelectedLookdownSize}
                    label={lang.size}
                    value={selectedLookdownSize}
                />
                <Autocomplete
                    id="dashboard-Lookdown-combo-box"
                    options={contactFields.map((demographic: HrisFieldInfo) => {
                        return {
                            id: demographic.fieldId,
                            label: demographic.fieldName
                        };
                    })}
                    noOptionsText={lang.noOptionsAvailable}
                    value={selectedLookdownField(lookdown.demographicFieldId)}
                    defaultValue={selectedLookdownField(-1)}
                    onChange={handleLookdownFieldSelected}
                    placeholder={lang.demographics}
                />
                <Box onBlur={handleListOnBlur}>
                    <Box
                        sx={selectContent}
                        onClick={handleOpenSurveyItemList}
                        data-testid={"dashboard-kd-btn-surveyItem"}
                    >
                        <Box
                            onClick={handleOpenSurveyItemList}
                            display="flex"
                            alignItems="center"
                            width="100%"
                            pl={1.5}
                            pr={1}
                            data-testid={"dashboard-kd-btn-surveyItem"}
                        >
                            <Typography
                                data-testid={"dashboard-lookdown-surveyItem"}
                                color="GrayText"
                                sx={typographySelect}
                            >
                                {lookdown.measureField ? lookdown.measureField : `${lang.surveyDimensionOrItem}`}
                            </Typography>
                            <ArrowDropDownIcon color="action" />
                        </Box>
                    </Box>

                    {showSurveyItemList && (
                        <Box pb={1} height={318} width={396} data-testid={"dashboar-lookdown-surveyItem-list"}>
                            <SelectExpandableList
                                rawData={surveySectionsPickOneWithIsFunAndENPSItemId(
                                    getAvailableSurveySectionsOnlyPickOneAndENPS(surveySection(), user)
                                )}
                                disabledSectionIds={getDisabledSectionIds()}
                                onItemSelected={handleSurveyItemSelect}
                            />
                        </Box>
                    )}
                </Box>
                {hasPreviousPeriod && (
                    <Checkbox
                        checked={isPreviousPeriodHidden}
                        onChange={(e): void => setPreviousPeriodHidden(e.target.checked)}
                        label={lang.hidePriveousPeriodData}
                    />
                )}
                <Typography variant="body1">{lang.title}</Typography>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.english}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"lookdwon-englishTitle-TextField"}
                        name={"englishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "en")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.spanish}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"lookdwon-spanishTitle-TextField"}
                        name={"spanishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "es")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.french}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"lookdwon-frenchTitle-TextField"}
                        name={"frenchTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "fr")[0]?.name ?? ""}
                    />
                </Box>
            </Box>
        </DialogLayout>
    );
};

import React, { ReactElement, useEffect, useReducer, useState } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { sgSurveyQuery } from "api/queries";
import { sortArray } from "core/helpers";
import { useLang } from "core/hooks";
import { DialogLayout } from "components/shared";
import { SurveyAddResultActions } from "./actions";
import { surveyGzEmpty } from "./init";
import { AddResultState, SurveyGz } from "./interfaces";
import { surveyAddResultReducer } from "./reducers";
import { useUser } from "core/context/user/useUser";

const surveyDefault: SurveyGz[] = [];

const initialState = (): AddResultState => {
    return {
        surveySelected: null,
        canBeOptimized: false,
        loading: false,
        surveyList: surveyDefault
    };
};

const sgSurveyDefault = (name?: string): SurveyGz => {
    return {
        id: -1,
        translations: [{ name: name ? name : "" }]
    };
};

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCompleteOptimization: (surveySelected: SurveyGz) => void;
};
//this dialog is to add LIVE survey, it's differnt than the one in response rate as live survey is still in alchemer not synced to compass db
const AddResultDialog = (props: Props): ReactElement => {
    const { lang } = useLang();
    const [selectedSurvey, setSurvey] = useState<SurveyGz>(surveyGzEmpty);
    const [state, dispatch] = useReducer(surveyAddResultReducer, null, initialState);
    const { user } = useUser();

    useQuery(sgSurveyQuery, {
        variables: { orgId: user.settings.selectedOrganization.id },
        onCompleted: data => {
            const sgSurveys = [...data.sgsurveys];
            sgSurveys.push(sgSurveyDefault(lang.noSGSurveys));
            if (sgSurveys.length === 0) {
                dispatch({
                    type: SurveyAddResultActions.SET_SURVEY_SELECTED,
                    payload: { surveySelected: sgSurveyDefault(lang.noSGSurveys) }
                });
            }

            dispatch({
                type: SurveyAddResultActions.SET_LOAD_SURVEYLIST,
                payload: { surveyList: sgSurveys }
            });

            dispatch({
                type: SurveyAddResultActions.SET_LOADING,
                payload: { loading: false }
            });
        },
        onError: () => {
            dispatch({
                type: SurveyAddResultActions.SET_LOADING,
                payload: { loading: false }
            });
        }
    });

    const selectSurveyOnChange = (value: AutocompleteValue | null): void => {
        const selected = state.surveyList.find(s => s.id === value?.id) as SurveyGz;

        if (selected && selected.id !== sgSurveyDefault().id) {
            setSurvey(selected);
            dispatch({
                type: SurveyAddResultActions.SET_SURVEY_SELECTED,
                payload: { surveySelected: selected }
            });
        }
    };

    const handleSubmitOptimization = (): void => {
        props.onCompleteOptimization(state.surveySelected as SurveyGz);
    };

    useEffect(() => {
        dispatch({
            type: SurveyAddResultActions.SET_LOADING,
            payload: { loading: true }
        });
    }, []);

    return (
        <DialogLayout
            open={props.isOpen}
            onClose={props.onClose}
            title={lang.addSurveyResult}
            buttonLabelAction={lang.ok}
            onClick={handleSubmitOptimization}
            height={500}
            loading={state.loading}
            hiddenOverflow={true}
            disableActionButton={selectedSurvey.id === surveyGzEmpty.id}
        >
            <div>
                <Autocomplete
                    noOptionsText={lang.noOptionsAvailable}
                    value={{ id: selectedSurvey.id, label: selectedSurvey.translations[0].name }}
                    options={sortArray(
                        state.surveyList
                            .filter(s => s.id !== -1)
                            .map(s => {
                                return {
                                    id: s.id,
                                    label: s.translations[0].name
                                };
                            }),
                        "label",
                        "asc"
                    )}
                    placeholder={lang.survey}
                    onChange={selectSurveyOnChange}
                />
            </div>
        </DialogLayout>
    );
};

export default AddResultDialog;

import { gql } from "@apollo/client";

export const loadContactFieldsAndSurveyItems = gql`
    query getContactFieldsandSurveyItems($surveyId: ID!, $languageCode: String!) {
        surveyContactFields(surveyId: $surveyId) {
            fieldId
            title
        }

        surveyItemFields(surveyId: $surveyId, languageCode: $languageCode) {
            title
            sectionId
            questions {
                questionId
                questionTitle
                questionType
                isFun
                canCalculate
                items {
                    fieldId
                    title
                    itemId
                    isIgnored
                    isHiddenForManagers
                    answers {
                        answerId
                        description
                    }
                }
            }
        }
    }
`;

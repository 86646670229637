import { Filter } from "components/filters/interfaces";
import { ComparisonGroup } from "api/rest/interfaces/OrgChartService.interface";
import { filterConnectorToBackEnd } from "components/filters/helper";

export const OtherCompareFilterMapper = (surveyId: number, columnLabel: string, filters: Filter): ComparisonGroup => {
    const comparisonGroup = {
        surveyId,
        filters: filterConnectorToBackEnd(filters.items),
        compareToLabel: columnLabel
    };

    return comparisonGroup;
};

import { SurveyQuestion, SurveySectionList } from "../interface";

export const getSelectedComment = (
    surveyItemsOptions: SurveySectionList[],
    selectedCommentQuestionId: string
): SurveyQuestion | undefined => {
    return surveyItemsOptions
        .map(o => o.questions)
        .reduce((a, b) => a.concat(b))
        .find(x => x.questionId.toString() === selectedCommentQuestionId);
};

import { initField, initFilterParameter } from "components/filters/inits";
import { TargetSurvey, SurveyComparison, ContactField, UpdatedSurveyComparisonValue } from "../interfaces";

export const initTargetSurvey: TargetSurvey = {
    id: -1,
    name: ""
};

export const initContactField: ContactField = {
    fieldId: -1,
    title: ""
};

export const initSurveyComparisons: SurveyComparison[] = [
    {
        id: -1,
        label: "",
        showPreviousPeriod: false,
        showInFirstColumn: false,
        sourceSurveyId: -1,
        targetSurveyId: -2,
        fieldMaps: [],
        valueMaps: [],
        showInHeatmap: false
    }
];

export const initUpdatedSurveyComparisonValue: UpdatedSurveyComparisonValue = {
    filterParameters: [initFilterParameter],
    sourceFieldId: initField.id,
    sourceValue: ""
};

// Primary Button Contained
export const primaryBackground = "#E87424";
export const primary = "#fff";
export const primaryHover = "#AF4600";

// Secondary Button Contained
export const secondaryBackground = "rgba(0,0,0,0.54)";
export const secondary = "#fff";
export const secondaryHover = "rgba(0,0,0,0.70)";

// Delete Button Contained
export const deleteBackground = "#DC3545";
export const deleteColor = "#fff";
export const deleteHover = "#9c1926";

// Primary Button Text
export const primaryText = "#E87424";
export const primaryTextHover = "rgba(232,116,36,0.10)";

// Secondary Button Text
export const deleteText = "#DC3545";
export const deleteTextHover = "rgba(220,53,70,0.10)";

import { FilterParameter, Filter, FieldParameter } from "../interfaces";
import { FilterType, ComparisonOperator, CombinationOperator } from "../enums";
import { HrisFieldInfo } from "managerPortal/interfaces";

export const initHrisFieldInfo: HrisFieldInfo = {
    fieldId: -1,
    isHidden: false,
    fieldName: "",
    fieldValues: []
};

export const initField: FieldParameter = {
    id: -1,
    fieldName: ""
};

export const initFilterParameter: FilterParameter = {
    filterType: FilterType.contactField,
    field: initField,
    comparisonOperator: ComparisonOperator.equals,
    target: [],
    order: -1,
    combinationOperator: CombinationOperator.and
};

export const initFilter: Filter = {
    id: -1,
    name: "",
    items: []
};

import React from "react";
import { makeStyles } from "@mui/styles";

import TruncateDisplay from "../../../managerPortal/components/shared/TruncateDisplay";
import { Checkbox } from "lib/checkbox";
import { Typography } from "lib/typography";

type Props = {
    name: string;
    id: number;
    selected: boolean;
    updateSelect: (id: number, value: boolean) => void;
};

const useStyles = makeStyles(() => ({
    checkContainer: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    checkbox: {
        marginRight: 14
    }
}));

const CellCheck: React.FC<Props> = props => {
    const classes = useStyles();
    const checkBoxRef = React.useRef<HTMLInputElement>(null);

    const { id, updateSelect, selected } = props;

    const handleChange = (): void => {
        updateSelect(id, selected);
    };

    return (
        <div className={classes.checkContainer} onClick={handleChange}>
            <Checkbox checked={selected} onChange={handleChange} className={classes.checkbox} inputRef={checkBoxRef} />
            <TruncateDisplay maxLabelLength={30} title={props.name}>
                <Typography noWrap variant="body2">
                    {props.name}
                </Typography>
            </TruncateDisplay>
        </div>
    );
};

export default CellCheck;

import { useState } from "react";
import { uploadEmpty } from "components/admin/results/init";
import { SurveySection } from "managerPortal/context/interfaces";
import { PreSelection } from "./types";

import { SettingsState } from "./types";

export const useSettingsState = (): SettingsState => {
    const [preSelection, setPreSelection] = useState<PreSelection>({
        minWords: 1,
        sampleWords: 15,
        selectedSectionId: null,
        selectedDimensionScoreSort: 1
    });
    const [minWordValidationError, setMinWordValidationError] = useState({ key: "", message: "" });
    const [sampleWordValidationError, setSampleWordValidationError] = useState({ key: "", message: "" });
    const [hiddenWordValidationError, setHiddenWordValidationError] = useState({ key: "", message: "" });
    const [hiddenWordsFileLoaded, setHiddenWordsFileLoaded] = useState(uploadEmpty);
    const [hiddenWords, setHiddenWords] = useState<string[]>([]);
    const [newHiddenWord, setNewHiddenWord] = useState("");
    const [selectedHiddenWord, setSelectedHiddenWord] = useState("");
    const [sampleWordsChecked, setSampleWordsChecked] = useState(false);
    const [autoTranslateChecked, setAutoTranslateChecked] = useState(false);
    const [dimensionScoreChecked, setDimensionScoreChecked] = useState(false);
    const [sentimentScoreChecked, setSentimentScoreChecked] = useState(false);
    const [surveySections, setSurveySections] = useState<SurveySection[]>([]);

    return {
        preSelection,
        minWordValidationError,
        sampleWordValidationError,
        hiddenWordValidationError,
        hiddenWordsFileLoaded,
        hiddenWords,
        newHiddenWord,
        selectedHiddenWord,
        sampleWordsChecked,
        autoTranslateChecked,
        dimensionScoreChecked,
        sentimentScoreChecked,
        surveySections,
        setPreSelection,
        setMinWordValidationError,
        setSampleWordValidationError,
        setHiddenWordValidationError,
        setHiddenWordsFileLoaded,
        setHiddenWords,
        setNewHiddenWord,
        setSelectedHiddenWord,
        setSampleWordsChecked,
        setAutoTranslateChecked,
        setDimensionScoreChecked,
        setSentimentScoreChecked,
        setSurveySections
    };
};

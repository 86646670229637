import { UserDashboardState } from "../interface";
import { UserDashboardActions } from "../action";
import { Action } from "core/interfaces";

export const UserDashboardReducer = (state: UserDashboardState, action: Action): UserDashboardState => {
    const { type, payload } = action;

    switch (type) {
        case UserDashboardActions.SELECT_SURVEY:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                currentFilter: payload.currentFilter
            };
        case UserDashboardActions.LOAD_SECTION_HRIS:
            return {
                ...state,
                surveyItemsOptions: payload.surveyItemsOptions
            };
        case UserDashboardActions.SET_STATUS:
            return { ...state, status: payload.status };
        case UserDashboardActions.RESTORE_USER_SETTINGS:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                surveyItemsOptions: payload.surveyItemsOptions,
                currentFilter: payload.currentFilter
            };
        case UserDashboardActions.SET_FILTER:
            return {
                ...state,
                currentFilter: payload.currentFilter,
                status: payload.status
            };
        case UserDashboardActions.OPEN_CLOSE_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case UserDashboardActions.SET_CONFIDENTIALITY_STATUS:
            return {
                ...state,
                confidentialityResult: payload.confidentialityResult
            };
        case UserDashboardActions.SET_DASHBOARDINFO:
            return {
                ...state,
                panelInfo: payload.layout
            };
        case UserDashboardActions.SET_DASHBOARD_DATA:
            return {
                ...state,
                snapshot: payload.snapshot
            };
        case UserDashboardActions.SET_RESPONSE_COUNT:
            return {
                ...state,
                responseCount: payload.responseCount
            };
        default:
            return {
                ...state
            };
    }
};

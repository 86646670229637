import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { tableHeaderLight, tableHeaderWhite } from "core/styles";

export const useTableResizerStyles = makeStyles(() => ({
    resizer: {
        width: 20,
        position: "relative",
        opacity: 0.2,
        cursor: "col-resize",
        transition: "all linear 100ms",
        whiteSpace: "nowrap",
        right: 0,
        top: 0,
        userSelect: "none",
        backgroundColor: "#fff",
        "&:hover": {
            opacity: 1
        }
    }
}));

type StyleProps = {
    hideHeader: boolean;
    headerGrey: boolean;
    headerPadding?: number;
    overflow?: string;
    rowPadding?: number;
};

export const useOrganizationListStyles = makeStyles(() => ({
    container: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box"
    },
    content: (styleProps: StyleProps) => ({
        height: styleProps.hideHeader ? "100%" : "calc(100% - 48px)",
        boxSizing: "border-box"
    }),
    tableRows: (styleProps: StyleProps) => ({
        overflowY:
            styleProps.overflow?.toLowerCase() === "y" || styleProps.overflow?.toLowerCase() === "xy"
                ? "auto"
                : "hidden",
        overflowX:
            styleProps.overflow?.toLowerCase() === "x" || styleProps.overflow?.toLowerCase() === "xy"
                ? "auto"
                : "hidden",
        height: 260,
        boxSizing: "border-box"
    }),
    headerRow: (styleProps: StyleProps) => ({
        position: "sticky",
        backgroundColor: styleProps.headerGrey ? tableHeaderLight : tableHeaderWhite,
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        borderBottom: theme.border.main,
        boxSizing: "border-box",
        height: 48,
        padding: styleProps.headerPadding ? `0 ${theme.spacing(styleProps.headerPadding)}` : 0
    }),
    tableCellHead: {
        borderBottom: "none !important",
        display: "flex",
        justifyContent: "space-between",
        boxSizing: "border-box"
    },
    tableHeadText: {
        fontSize: 12,
        fontWeight: 500,
        color: "rgba(0, 0, 0, 0.54)"
    },
    tableHeadIcon: {
        fontSize: 14
    },
    tableRow: (styleProps: StyleProps) => ({
        position: "absolute",
        display: "flex",
        alignItems: "center",
        borderBottom: theme.border.main,
        boxSizing: "border-box",
        paddingLeft: styleProps.rowPadding ? `${theme.spacing(styleProps.rowPadding)}` : 0
    }),
    tableCell: {
        boxSizing: "border-box",
        paddingLeft: 8,
        paddingRight: 8
    },
    tableCellHeader: {
        boxSizing: "border-box",
        paddingLeft: 16,
        paddingRight: 8
    },
    tableCellTextHeader: {
        boxSizing: "border-box",
        paddingLeft: 20,
        paddingRight: 8,
        color: "rgba(0, 0, 0, 0.54)"
    }
}));

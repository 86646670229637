import { SurveyItem, SurveyQuestion, SurveySection } from "../interfaces";
import { QuestionType } from "../enums";
import { SectionContentType } from "../../components/snapshotReport/enums";

export const initSurveySection: SurveySection[] = [
    { sectionId: "", questions: [], title: "", benchmarkValue: 0, type: SectionContentType.Dimension, sectionOrder: -1 }
];

export const initSurveyQuestion: SurveyQuestion = {
    items: [],
    questionId: -1,
    questionTitle: "",
    questionType: QuestionType.FreeText,
    canCalculate: false,
    isFun: false
};

export const initSurveyItem: SurveyItem = {
    itemId: "",
    fieldId: -1,
    title: "",
    benchmarkValue: 0,
    type: -1,
    answers: [],
    isHiddenForManagers: false,
    isIgnored: false,
    isRemovedOverallScore: false,
    scaleDefaultId: -1,
    translations: []
};

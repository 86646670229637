import React, { FunctionComponent, ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Typography } from "lib/typography";
import { Button } from "lib/button";

const useStyles = makeStyles(() => ({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        width: 444,
        height: 240
    },
    dialogHeader: {
        fontWeight: "bold",
        borderBottom: theme.border.main
    },
    dialogFooterWrapper: {
        paddingRight: 24,
        borderTop: theme.border.main
    },
    textField: {
        marginBottom: 16
    }
}));

interface Props {
    title: string;
    isOpen: boolean;
    contactFields: HrisFieldInfo[];
    bulkExportDemographic: BulkExportDemographic;
    updateBulkExportDemographic: (bulkExportDemographic: BulkExportDemographic) => void;
    onClose: () => void;
    onSubmitCallback: () => void;
    extraAction?: ReactElement;
}

export const DialogBulkExportSlide: FunctionComponent<Props> = props => {
    const { lang } = useLang();
    const { isOpen, onClose, onSubmitCallback, extraAction } = props;

    const clearBulkExportDemographicField = (): void => {
        const updatedBulkExport = { demographicFieldId: -1, demographicField: "" };
        props.updateBulkExportDemographic(updatedBulkExport);
    };

    const handleFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            clearBulkExportDemographicField();
            return;
        }

        const updatedBulkExport = { demographicFieldId: value.id, demographicField: value?.label };
        props.updateBulkExportDemographic(updatedBulkExport);
    };

    const onExportClick = (): void => {
        onSubmitCallback();
    };

    const classes = useStyles();

    const selectedDemographicField = (id: number) => {
        const found = props.contactFields.find(demographic => demographic.fieldId === id);
        if (found) {
            return {
                id: found.fieldId,
                label: found.fieldName
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onKeyUp={e => {
                if (e.key === "Enter" && props.bulkExportDemographic.demographicField !== "") {
                    onExportClick();
                }
            }}
        >
            <div className={classes.dialogContent}>
                <DialogTitle className={classes.dialogHeader}>
                    <Typography variant="h4">{props.title}</Typography>
                </DialogTitle>
                <DialogContent style={{ overflowY: "hidden" }}>
                    <Box pt={3} pl={3} pr={3} pb={1}>
                        <Autocomplete
                            id={`bulk-export-demographic`}
                            options={props.contactFields
                                .filter(option => option.fieldId !== -1)
                                .map(s => {
                                    return { id: s.fieldId, label: s.fieldName };
                                })}
                            noOptionsText={lang.noOptionsAvailable}
                            placeholder={lang.bulkReportDemographics}
                            value={selectedDemographicField(props.bulkExportDemographic.demographicFieldId)}
                            getOptionDisabled={(option): boolean =>
                                props.bulkExportDemographic.demographicFieldId === option.id
                            }
                            onChange={handleFieldSelected}
                        />
                    </Box>
                    {extraAction && extraAction}
                </DialogContent>
                <DialogActions className={classes.dialogFooterWrapper}>
                    <Button
                        onClick={onExportClick}
                        disabled={props.bulkExportDemographic.demographicField === ""}
                        data-testid="btn-dialog-bulk-export-slide"
                        variant="text"
                    >
                        {lang.ok}
                    </Button>
                    <Button onClick={onClose} variant="text" style={{ marginRight: 12 }}>
                        {lang.cancel}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

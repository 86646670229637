import React, { CSSProperties, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import Help from "@mui/icons-material/Help";
import { useLang } from "core/hooks";
import Loading from "../Loading";
import { theme } from "lib/theme";
import { Button, ButtonVariant } from "lib/button";
import { Box } from "lib/box";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import { Divider } from "lib/divider";

type Props = {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
    title: string;
    buttonLabelCancel?: string;
    buttonLabelAction: string;
    disableActionButton?: boolean;
    cancelButtonType?: ButtonVariant;
    width?: CSSProperties["width"];
    height?: CSSProperties["height"];
    minHeight?: CSSProperties["minHeight"];
    helpText?: string;
    loading?: boolean;
    suppressContentPadding?: boolean;
    hiddenOverflow?: boolean;
    extraAction?: string;
    extraActionOnClick?: () => void;
    headerPaddingRight?: number;
    hideCancel?: boolean;
};

const useStyles = makeStyles(() => ({
    head: (props: Props) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        "& h3": {
            fontWeight: theme.typography.fontWeightMedium
        },
        paddingRight: props?.headerPaddingRight ? props.headerPaddingRight : 24
    }),
    icons: {
        display: "flex"
    },
    dialogActions: {
        height: 52,
        padding: 0
    },
    actionBtnGroup: (props: Props) => ({
        minWidth: 128,
        height: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        marginRight: props.hideCancel ? 0 : theme.spacing(1)
    })
}));

type ContentStyled = {
    suppressContentPadding: boolean | undefined;
    hiddenOverflow: boolean | undefined;
};
const contentStyled = ({ suppressContentPadding, hiddenOverflow }: ContentStyled) => {
    return {
        padding: suppressContentPadding ? 0 : "8px 24px 8px 24px",
        overflow: hiddenOverflow ? "hidden" : "auto",
        height: "100%"
    };
};

type ContainerStyled = {
    width: CSSProperties["width"];
    height: CSSProperties["height"];
    minHeight: CSSProperties["minHeight"];
    hiddenOverflow?: boolean;
};
const containerStyled = ({ width, height, minHeight, hiddenOverflow }: ContainerStyled) => {
    return {
        maxWidth: "none",
        maxHeight: 850,
        width: width ? width : 444,
        height: height ? height : 650,
        minHeight: minHeight ? minHeight : 500,
        overflow: hiddenOverflow ? "hidden" : "auto"
    };
};

export const DialogLayout = (props: PropsWithChildren<Props>): ReactElement => {
    const [dialogSize, setDialogSize] = useState<{ height: number; width: number }>({
        width: 0,
        height: 0
    });

    useEffect(() => {
        const handleResize = (): void => {
            setDialogSize({
                width: window.innerWidth * 0.25 < 444 ? 444 : window.innerWidth * 0.25,
                height: window.innerHeight * 0.8 < 500 ? 500 : window.innerHeight * 0.8
            });
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return (): void => window.removeEventListener("resize", handleResize);
    }, []);

    const styleProps = {
        ...props,
        width: props.width && +props.width > dialogSize.width ? props.width : dialogSize.width,
        height: props.height === "RRdialog" ? dialogSize.height * 0.6 : props.height ? props.height : dialogSize.height
    };

    const classes = useStyles(styleProps);
    const { lang } = useLang();

    const handleCloseDialog = (): void => {
        props.onClose();
    };

    const handleOnClick = (): void => {
        props.onClick();
    };

    const handleExtraActionOnClick = (): void => {
        props.extraActionOnClick && props.extraActionOnClick();
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            PaperProps={{
                sx: containerStyled({
                    width: props.width,
                    height: props.height,
                    minHeight: props.minHeight,
                    hiddenOverflow: props.hiddenOverflow
                })
            }}
            onKeyUp={e => {
                if (e.key === "Enter" && !props.disableActionButton) {
                    handleOnClick();
                }
            }}
        >
            {props.loading && <Loading styleProps={{ position: "absolute" }} />}
            <DialogTitle className={classes.head}>
                <Typography variant="h6">{props.title}</Typography>
                <div className={classes.icons}>
                    {props.helpText && (
                        <Tooltip title={props.helpText} placement="bottom-end">
                            <IconButton aria-label={lang.help}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    )}
                    {props.extraAction && (
                        <Box m={2}>
                            <Button variant="text" onClick={handleExtraActionOnClick} data-testid={`btn-extra-action`}>
                                {props.extraAction}
                            </Button>
                        </Box>
                    )}
                </div>
            </DialogTitle>
            <Divider />
            <DialogContent
                sx={contentStyled({
                    suppressContentPadding: props.suppressContentPadding,
                    hiddenOverflow: props.hiddenOverflow
                })}
            >
                {props.children}
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                <Box display="flex" gap={2} pr={3}>
                    <Button
                        variant="text"
                        onClick={handleOnClick}
                        disabled={props.disableActionButton}
                        data-testid={`btn-${props.buttonLabelAction}`}
                    >
                        {props.buttonLabelAction}
                    </Button>
                    {!props.hideCancel && (
                        <Button
                            variant={
                                props.cancelButtonType && props.cancelButtonType === "deleteText"
                                    ? "deleteText"
                                    : "text"
                            }
                            onClick={handleCloseDialog}
                            data-testid={props.buttonLabelCancel ? `btn-${props.buttonLabelCancel}` : "btn-cancel"}
                        >
                            {props.buttonLabelCancel ? props.buttonLabelCancel : lang.cancel}
                        </Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};

import React, { ReactElement, useState, useEffect } from "react";
import { SelectOptionNavigation, NavItemDisabled } from "../header/interfaces";
import { NavigationKey } from "../header/enums";
import { useDrawerDropdownStyles } from "./styles";
import { useLocation } from "react-router-dom";

import LinkButton from "./LinkButton";
import { ReportRoutes } from "routes/enums";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

type Props = {
    selectOptions: SelectOptionNavigation[];
    onSelect: (selectedValue: number, selectKey: string) => void;
    navigationKey: NavigationKey;
    icon?: ReactElement;
    label?: string;
    align: "right" | "left";
    disabled?: NavItemDisabled;
    hidden?: boolean;
};

const DrawerDropdown = (props: Props): ReactElement => {
    const [selected, setSelected] = useState<boolean>(false);
    const location = useLocation();
    const styleProps = {
        alignTo: props.align,
        disabled: props.disabled?.isDisabled ? true : false,
        selected,
        hidden: props.hidden ? props.hidden : false
    };

    const classes = useDrawerDropdownStyles(styleProps);

    useEffect(() => {
        if (
            location.pathname.includes(ReportRoutes.responseRate) ||
            location.pathname.includes(ReportRoutes.snapshot) ||
            location.pathname.includes(ReportRoutes.orgChart)
        ) {
            setSelected(true);
        } else if (
            !location.pathname.includes(ReportRoutes.responseRate) ||
            !location.pathname.includes(ReportRoutes.snapshot) ||
            !location.pathname.includes(ReportRoutes.orgChart)
        ) {
            setSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div className={classes.container}>
            <Typography className={classes.textColor} variant="body2">
                {props.label}
            </Typography>
            <div className={classes.listContainer}>
                {props.selectOptions.map((option: SelectOptionNavigation, index: number) =>
                    option.disabled && option.disabledMsg ? (
                        <Tooltip title={option.disabledMsg} key={index}>
                            <div style={{ display: option.hidden ? "none" : "inline", minWidth: 200 }}>
                                <Typography variant="body1">{option.label}</Typography>
                            </div>
                        </Tooltip>
                    ) : (
                        <LinkButton
                            key={index}
                            linkTo={option.linkTo!}
                            align={"left"}
                            disabled={{ isDisabled: option.disabled!, message: option.disabledMsg! }}
                            data-testid={`drawer-dropdown-item-${option.label}`}
                            label={option.label}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default DrawerDropdown;

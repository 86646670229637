import React, { ReactElement } from "react";
import StopIcon from "@mui/icons-material/Stop";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { theme } from "lib/theme";

const useStyle = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",

        boxSizing: "border-box"
    },
    legendItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: theme.spacing(2)
    }
}));

type Props = { variance: string };

enum colorLegends {
    negative = "negative",
    neutral = "neutral",
    positive = "positive"
}

export const LegendIcon = (props: Props): ReactElement => {
    return (
        <StopIcon
            color={
                props.variance === colorLegends.negative
                    ? "error"
                    : props.variance === colorLegends.neutral
                      ? "disabled"
                      : "success"
            }
        />
    );
};

export const CommentDetailsLegend = (): ReactElement => {
    const { lang } = useLang();
    const classes = useStyle();
    const legends = Object.values(colorLegends);

    return (
        <div className={classes.container}>
            {legends.map(l => (
                <div className={classes.legendItem} key={l}>
                    <LegendIcon variance={l} />
                    <Typography variant="body2">{lang[l] + " %"}</Typography>
                </div>
            ))}
        </div>
    );
};

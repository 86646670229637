import { useState } from "react";
import { TitleTranslation } from "../interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";

interface DimensionOrItem {
    isSection: boolean;
    measureId: number;
    measureField: string;
}
interface KeyDriverSize {
    id: string;
    width: number;
    height: number;
    name: string;
}

type KeyDriverState = {
    keyDriverSize: KeyDriverSize[];
    setKeyDriverSize: (keyDriverSize: KeyDriverSize[]) => void;
    selectedKeyDriverSize: string;
    setKeyDriverSelectedSize: (newValue: string) => void;
    keyDriverSingleDimensionOrItem: DimensionOrItem;
    handleSingleDimensionOrItemUpdate: (item: DimensionOrItem) => void;
    excludedDimensions: string[];
    handleExcludedDimensionsUpdate: (dimensions: string[]) => void;
    titleTranslation: TitleTranslation[];
    setTitleTranslation: (newTitleTranslations: TitleTranslation[]) => void;
};

type InitState = {
    initKeyDriverSize?: KeyDriverState["keyDriverSize"];
    initSingleDimensionOrItem?: KeyDriverState["keyDriverSingleDimensionOrItem"];
    initSelectedKeyDriverSize?: KeyDriverState["selectedKeyDriverSize"];
    initTitleTranslation?: KeyDriverState["titleTranslation"];
};

export const useKeyDriverState = ({
    initKeyDriverSize,
    initSingleDimensionOrItem,
    initSelectedKeyDriverSize,
    initTitleTranslation
}: InitState): KeyDriverState => {
    const [keyDriverDimension, setKeyDriverDimension] = useState(initKeyDriverSize || []);
    const [singleDimensionOrItem, setSingleDimensionOrItem] = useState(
        initSingleDimensionOrItem || {
            isSection: false,
            measureId: -1,
            measureField: ""
        }
    );
    const [selectedSingleDimensionOrItemSize, setSelectedSingleDimensionOrItemSize] = useState(
        initSelectedKeyDriverSize || ""
    );

    const [excludedDimensions, setExcluededDimensions] = useState<string[]>([]);

    const [titleTranslation, setTitleTranslation] = useState<TitleTranslation[]>(
        initTitleTranslation || defaultItemTitleTranslation
    );

    const setKeyDriverSize = (newValue: KeyDriverSize[]): void => {
        setKeyDriverDimension(newValue);
    };

    const setKeyDriverSelectedSize = (newValue: string): void => {
        setSelectedSingleDimensionOrItemSize(newValue);
    };

    const handleSingleDimensionOrItemUpdate = (item: DimensionOrItem): void => {
        setSingleDimensionOrItem(item);
    };

    const handleExcludedDimensionsUpdate = (dimensions: string[]): void => {
        setExcluededDimensions(dimensions);
    };

    const handleUpdateTitleTranslation = (newTitleTranslations: TitleTranslation[]): void => {
        setTitleTranslation(newTitleTranslations);
    };
    return {
        keyDriverSize: keyDriverDimension,
        setKeyDriverSize,
        selectedKeyDriverSize: selectedSingleDimensionOrItemSize,
        setKeyDriverSelectedSize,
        keyDriverSingleDimensionOrItem: singleDimensionOrItem,
        handleSingleDimensionOrItemUpdate,
        excludedDimensions,
        handleExcludedDimensionsUpdate,
        titleTranslation,
        setTitleTranslation: handleUpdateTitleTranslation
    };
};

export enum NavigationKey {
    home = "home",
    admin = "admin",
    reports = "reports",
    downloads = "downloads",
    selectOrganization = "selectOrganization",
    help = "help",
    language = "language",
    profile = "profile",
    surveys = "surveys"
}

export enum NavigationType {
    linkWithIcon,
    linkWithText,
    dropdownWithIcon,
    dropdownWithText,
    hyperLinkWithIcon,
    openDialog,
    drawerDropdown
}

export enum ProfileMenuAction {
    logout
}

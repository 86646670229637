export enum DashboardActions {
    SET_SELECTED_PANEL_ID = "SET_SELECTED_PANEL_ID",
    SET_DASHBOARD_TITLE = "SET_DASHBOARD_TITLE",
    SET_PANEL_OPEN_STATUS = "SET_PANEL_OPEN_STATUS",
    SET_COMPONENT_STATUS = "SET_COMPONENT_STATUS",
    SET_PANEL_ITEMS = "SET_PANEL_ITEMS",
    SET_SELECTED_PANEL_POSITION = "SET_SELECTED_PANEL_POSITION",
    SET_CONFIRMATION_STATUS = "SET_CONFIRMATION_STATUS",
    SET_SELECTED_CELL_TYPE = "SET_SELECTED_CELL_TYPE",
    SET_PANEL_BOXES = "SET_PANEL_BOXES",
    SET_CLEAR_ALL = "SET_CLEAR_ALL",
    SET_DASHBOARD_PANEL = "SET_DASHBOARD_PANEL"
}

import { initCommentsSettings } from "components/admin/users/initialVariables";
import { UserCommentSettings } from "components/admin/users/interface";
import { UserInfo } from "components/admin/users/interface";

export const getUserSettings = (user: UserInfo): UserCommentSettings => {
    const userCommentsSettings = user.settings.commentsSettings;
    const findIndex = userCommentsSettings.findIndex(s => s.surveyId === user.settings.comment.surveySelected);
    if (findIndex > -1) {
        return userCommentsSettings[findIndex];
    } else {
        return { ...initCommentsSettings, surveyId: user.settings.comment.surveySelected };
    }
};

import { IRouteProps } from "../interfaces";
import { OrganizationsRoutes as OrganizationsRoutesEnum } from "../enums";
import { UserInfo } from "../../components/admin/users/interface";

import AddOrg from "../../components/admin/organizations/AddOrg";
import EditOrg from "../../components/admin/organizations/EditOrg";

export const OrganizationsRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        /**
         * Creating and Editing Organization need it own route to render its own sidebar
         * It can't be part of admin's route
         */
        {
            path: `/${OrganizationsRoutesEnum.organizations}/${OrganizationsRoutesEnum.addOrg}`,
            exact: true,
            label: OrganizationsRoutesEnum.addOrg,
            routeKey: OrganizationsRoutesEnum.addOrg,
            component: AddOrg,
            isAccessGranted: user.isTalentMapAdmin
        },
        {
            path: `/${OrganizationsRoutesEnum.organizations}/${OrganizationsRoutesEnum.editOrg}/:id`,
            exact: true,
            label: OrganizationsRoutesEnum.editOrg,
            routeKey: OrganizationsRoutesEnum.editOrg,
            component: EditOrg,
            isAccessGranted: user.isTalentMapAdmin
        }
    ];
};

import { OrganizationInfo } from "../interfaces";
import { isStringEmptyOrSpaces } from "core/helpers";
import { OrgDataKey } from "../enums";
import { Translation } from "core/languages/interfaces";

interface ValidationInfo {
    isValid: boolean;
    errorMessage: string;
    validationKey: OrgDataKey;
}

export const formValidation = (
    organization: OrganizationInfo,
    organizations: OrganizationInfo[],
    lang: Translation
): ValidationInfo => {
    if (isStringEmptyOrSpaces(organization.name)) {
        return {
            isValid: false,
            errorMessage: lang.orgNameCannotBeBlank,
            validationKey: OrgDataKey.name
        };
    }

    const findOrg = organizations.find(
        (org: OrganizationInfo) =>
            org.name.toLowerCase().replace(/\s/g, "") === organization.name.toLowerCase().replace(/\s/g, "")
    );
    if (findOrg && findOrg.id !== organization.id) {
        return {
            isValid: false,
            errorMessage: lang.organizationNameMustBeUnique,
            validationKey: OrgDataKey.name
        };
    }

    return {
        isValid: true,
        errorMessage: "",
        validationKey: OrgDataKey.null
    };
};

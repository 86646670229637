import React, { lazy, Suspense, ReactElement, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";

import Loading from "../shared/Loading";
import UserTimeout from "../shared/UserTimeout";
import { SgUserQuery } from "../../api/queries";
import ErrorAccess from "../errorPage/ErrorAccess";
const SurveyGizmo = lazy(() => import("./SurveyGizmo"));
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    surveysContainer: {
        height: "100%",
        width: "100%",
        background: theme.palette.secondary.main
    },
    surveysContainerError: {
        height: "100%",
        width: "100%",
        background: theme.palette.background.paper
    }
}));

const Surveys = (): ReactElement => {
    const classes = useStyles();
    const { user } = useUser();
    const [sgLicensedId, setSgLicensedId] = useState<number>(-1);

    const { loading } = useQuery(SgUserQuery, {
        variables: {
            id: user.id
        },
        onCompleted: data => {
            setSgLicensedId(data.getSGUser);
        }
    });

    if (loading) return <Loading />;
    //alchemer/sg endpoint return -1 when the user has no assigned license but has the permission to access that page
    const userHasNoLicense = -1;
    const renderLoader = (): ReactElement => <Loading />;

    return (
        <div
            className={
                sgLicensedId === userHasNoLicense || process.env.NODE_ENV === "development"
                    ? classes.surveysContainerError
                    : classes.surveysContainer
            }
        >
            <Suspense fallback={renderLoader()}>
                {process.env.NODE_ENV === "development" ? (
                    <UserTimeout>
                        <ErrorAccess />
                    </UserTimeout>
                ) : sgLicensedId !== userHasNoLicense ? (
                    <UserTimeout>
                        <SurveyGizmo />
                    </UserTimeout>
                ) : (
                    <ErrorAccess />
                )}
            </Suspense>
        </div>
    );
};

export default Surveys;

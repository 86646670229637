import React, { ReactElement, useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { useHover } from "../../core/hooks";
import { HomeIcon, MenuOption } from "./interfaces";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";

type Props = {
    icon: HomeIcon;
};

const useStyles = makeStyles(() => ({
    boxContainer: ({ isDisabled }: { isDisabled: boolean }) => ({
        margin: "12px 36px",
        height: 232,
        width: 226,
        boxSizing: "border-box",
        background: theme.palette.background.paper,
        borderRadius: 10,
        border: "2px solid",
        borderColor: theme.palette.background.paper,
        "&:hover": {
            borderColor: isDisabled ? "transparent" : theme.palette.primary.main
        }
    }),
    menuClosed: ({ isDisabled }: { isDisabled: boolean }) => ({
        height: "100%",
        width: "100%",
        display: "block",
        position: "relative",
        "&:hover": {
            cursor: isDisabled ? "default" : "pointer"
        }
    }),
    menuOpened: {
        height: "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    titleClosedMenu: ({ isDisabled }: { isDisabled: boolean }) => ({
        width: "100%",
        position: "absolute",
        opacity: isDisabled ? 0.38 : 1,
        color: "black"
    }),
    fixedBottom: {
        bottom: 8
    },
    image: ({ isDisabled }: { isDisabled: boolean }) => ({
        height: "80%",
        width: "100%",
        position: "absolute",
        opacity: isDisabled ? 0.38 : 1,
        top: 8
    }),
    itemDisabled: {
        cursor: "error",
        opacity: 0.38
    }
}));

const IconMenu: React.FC<Props> = (props): ReactElement => {
    const isDisabled = props.icon.disabled?.isDisabled ? true : false;
    const classes = useStyles({ isDisabled });
    const [isMenuOpen, setOpenMenu] = useState(false);
    const [isHovered, bind] = useHover();

    const handleOpenMenu = (): void => {
        setOpenMenu(true);
    };

    const handleCloseMenu = (): void => {
        setOpenMenu(false);
    };

    useEffect(() => {
        if (!isHovered) {
            handleCloseMenu();
        }
    }, [isHovered]);

    return (
        <div className={classes.boxContainer} data-testid="iconMenu">
            {isMenuOpen ? (
                <div className={classes.menuOpened} {...bind} data-testid="openedMenu">
                    {props.icon.menuOptions?.map((item: MenuOption) => {
                        return item.disabled && item.disableMessage ? (
                            <Link
                                key={item.label}
                                to={"#"}
                                style={{
                                    display: item.hidden ? "none" : "inline",
                                    textDecoration: "none",
                                    color: "black",
                                    paddingTop: "2px",
                                    paddingBottom: "2px"
                                }}
                            >
                                <Tooltip title={item.disableMessage[0]}>
                                    <Typography align="center" className={classes.itemDisabled} variant="body1">
                                        {item.label}
                                    </Typography>
                                </Tooltip>
                            </Link>
                        ) : (
                            <Link
                                key={item.label}
                                to={item.linkTo}
                                style={{
                                    display: item.hidden ? "none" : "inline",
                                    textDecoration: "none",
                                    color: "black",
                                    paddingTop: "2px",
                                    paddingBottom: "2px"
                                }}
                            >
                                <Typography align="center" variant="body1">
                                    {item.label}
                                </Typography>
                            </Link>
                        );
                    })}
                </div>
            ) : !isMenuOpen && props.icon.disabled?.isDisabled ? (
                <div className={classes.menuClosed} data-testid="closedMenuDisabled">
                    <img alt={props.icon.label} src={props.icon.imageUrl} className={classes.image} />
                    <Typography
                        variant="h6"
                        align="center"
                        className={clsx(classes.titleClosedMenu, classes.fixedBottom)}
                    >
                        {props.icon.label}
                    </Typography>
                </div>
            ) : (
                <div className={classes.menuClosed} onClick={handleOpenMenu} data-testid="closedMenuNotDisabled">
                    <img alt={props.icon.label} src={props.icon.imageUrl} className={classes.image} />
                    <Typography
                        align="center"
                        variant="h6"
                        className={clsx(classes.titleClosedMenu, classes.fixedBottom)}
                    >
                        {props.icon.label}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default IconMenu;

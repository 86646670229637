import React, { ReactElement } from "react";

import { responseRateBackground } from "../../../styles/GlobalStyles";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Avatar } from "lib/avatar";
import { Box } from "lib/box";

const avatarStyled = {
    width: 50,
    height: 50,
    color: theme.palette.text.primary,
    background: responseRateBackground
};
interface Props {
    number: number;
    title: string;
}

const NumberCircle = (props: Props): ReactElement => {
    const numberText = props.number.toFixed(0) + "%";

    return (
        <Box pl={1} pr={1}>
            <Tooltip title={props.title}>
                <Avatar sx={avatarStyled}>{numberText}</Avatar>
            </Tooltip>
        </Box>
    );
};

export default NumberCircle;

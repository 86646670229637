// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import url from "url";

export default class ConfigService {
    fetchMode: "same-origin" | "cors" | "navigate" | "no-cors" | undefined;
    bearer: string;

    fetchGetOptions: {
        method: "GET";
        headers: {
            "content-type": string;
            Authorization: string;
        };
    };

    fetchPostOptions: {
        method: "POST";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body: any;
        headers: {
            "content-type": string;
            Authorization: string;
        };
    };

    constructor(bearer: string) {
        this.fetchMode = "cors";
        this.bearer = bearer;
        this.fetchGetOptions = {
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: bearer
            }
        };
        this.fetchPostOptions = {
            method: "POST",
            body: null,
            headers: {
                "content-type": "application/json",
                Authorization: bearer
            }
        };
    }

    get<T>(subUrl: string): Promise<T> {
        const makeUrl = this.makeUrl(subUrl);

        return fetch(makeUrl, this.fetchGetOptions)
            .then(response => {
                try {
                    return response.json();
                } catch (e) {
                    return new Promise(resolve => {
                        resolve({ error: "Return Value Parsing Error" });
                    });
                }
            })
            .catch(() => {
                return new Promise(resolve => {
                    resolve({ error: "Return Value Invalid Error" });
                });
            });
    }

    getBlobFile(
        subUrl: string,
        isPost?: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?: any
    ): Promise<unknown> {
        const makeUrl = this.makeUrl(subUrl);
        if (isPost) {
            this.fetchPostOptions.body = JSON.stringify(data);
        }
        const option = isPost ? this.fetchPostOptions : this.fetchGetOptions;
        return fetch(makeUrl, option)
            .then(response => {
                try {
                    const blob = response.blob();
                    return blob.then(blobResp => {
                        if (blobResp.type === "application/json") {
                            throw new Error("Failed to fetch");
                        } else {
                            return blob;
                        }
                    });
                } catch (e) {
                    return new Promise(() => {
                        throw new Error("Failed to fetch");
                    });
                }
            })
            .catch(() => {
                throw new Error("Failed to fetch");
            });
    }

    post<T, K>(subUrl: string, body: K): Promise<T> {
        return this.postOrDelete(subUrl, body, "POST");
    }

    delete<T, K>(subUrl: string, body: K): Promise<T> {
        // Delete method is causing issues in the CORS communication with the backend when in DEV environment.
        // this might change in the future, but for now, lets keep delete as a POST method
        return this.postOrDelete(subUrl, body, "POST");
    }

    postOrDelete<K, T>(subUrl: string, body: K, method: string): Promise<T> {
        return new Promise(resolve => {
            return fetch(this.makeUrl(subUrl), {
                method,
                mode: this.fetchMode,
                headers: {
                    "content-type": "application/json",
                    Authorization: this.bearer
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(
                            `Failed to ${method} to ${response.url}, server returned status ${response.status}.`
                        );
                    }
                    return response;
                })
                .then(response => {
                    const contentType = response.headers.get("Content-Type");
                    const isJson = contentType && contentType.includes("application/json");
                    if (isJson) {
                        resolve(response.json());
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        resolve(undefined);
                    }
                })
                .catch(e => {
                    throw e;
                });
        });
    }
    upload<T>(subUrl: string, formData: BodyInit | null | undefined): Promise<T> {
        return new Promise(resolve => {
            return fetch(this.makeUrl(subUrl), {
                mode: this.fetchMode,
                method: "POST",
                headers: {
                    Authorization: this.bearer
                },
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(
                            `Failed to post to ${response.url}, server returned status ${response.status}.`
                        );
                    }
                    return response;
                })
                .then(response => {
                    const contentType = response.headers.get("Content-Type");
                    const isJson = contentType && contentType.includes("application/json");
                    if (isJson) {
                        resolve(response.json());
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        resolve(undefined);
                    }
                })
                .catch(e => {
                    throw e;
                });
        });
    }

    makeUrl(subUrl: string): string {
        return url.resolve(process.env.REACT_APP_MP_BASE_URI!, subUrl);
    }

    getBearer(): string {
        return this.bearer;
    }
}

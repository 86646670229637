import { useState } from "react";
import type { SortBy, OrderBy } from "./types";

type Return = {
    orderBy: OrderBy;
    sortBy: SortBy;
    setSortBy: (value: SortBy) => void;
};

export const useSnapshoTableState = (): Return => {
    const [sortBy, setSortBy] = useState<SortBy>("default");
    const [orderBy, setOrderBy] = useState<OrderBy>("desc");

    const onSetSortBy = (value: SortBy): void => {
        if (sortBy === value && value !== "default") {
            if (orderBy === "desc") {
                return setOrderBy("asc");
            }
            return setOrderBy("desc");
        }
        setOrderBy("desc");
        setSortBy(value);
    };

    return {
        sortBy,
        setSortBy: onSetSortBy,
        orderBy
    };
};

import React, { ReactElement, ChangeEvent } from "react";
import { makeStyles } from "@mui/styles";

import SelectScale from "./SelectScale";
import ReportingOptions from "./ReportingOptions";
import { useLang } from "core/hooks";
import { Scale, ScaleValue } from "./interfaces";
import { Answer, SurveyItem } from "managerPortal/context/interfaces";
import { ReportingOptions as ReportingOptionsEnum } from "./enums";
import { sortArray } from "core/helpers";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { FormControlLabel } from "lib/form-control-label";
import { Checkbox } from "lib/checkbox";
import { List, ListItem, ListItemText } from "lib/list";
import { Box } from "lib/box";
import { Divider } from "lib/divider";

const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 180,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderBottom: theme.border.main,
        height: 68,
        paddingRight: theme.spacing(3),
        paddingLeft: 42
    },
    actionsContainerFlexEnd: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderBottom: theme.border.main,
        height: 68,
        paddingRight: theme.spacing(3),
        paddingLeft: 42
    },
    scalesActionsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderBottom: theme.border.main,
        height: 68,
        paddingRight: theme.spacing(3),
        paddingLeft: 24
    }
}));

const styledListItem = (disabled: boolean) => {
    return {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottom: theme.border.main,
        color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        height: 60
    };
};

type Props = {
    isOverridden?: boolean;
    onReportingOptionsChange: (a: ReportingOptionsEnum, b: boolean) => void;
    surveyItemSelected?: SurveyItem;
    updateSurveyItem?: (surveyItem: SurveyItem) => void;
    defaultScale: Scale;
    updateDefaultScale?: (scale: Scale) => void;
};

const CustomizationArea = (props: Props): ReactElement => {
    const {
        isOverridden,
        onReportingOptionsChange,
        surveyItemSelected,
        updateSurveyItem,
        defaultScale,
        updateDefaultScale
    } = props;
    const classes = useStyles();
    const { lang } = useLang();

    const handleDefaultScaleSelectChange = (value: number, index: number): void => {
        const updateScaleValues = [...defaultScale!.scaleValues];
        updateScaleValues[index] = { ...updateScaleValues[index], outputValue: value };

        const updateScale: Scale = {
            ...defaultScale!,
            scaleValues: [...updateScaleValues]
        };

        updateDefaultScale!(updateScale);
    };

    const handleSurveyItemSelectChange = (answer: Answer, value: number, index: number): void => {
        const updateAnswers = [...surveyItemSelected!.answers];
        updateAnswers[index] = { ...answer, outputValue: value };

        const surveyItem: SurveyItem = {
            ...surveyItemSelected!,
            answers: [...updateAnswers]
        };
        updateSurveyItem!(surveyItem);
    };
    const handleCheckboxChange = (key: keyof typeof ReportingOptionsEnum, isChecked: boolean): void => {
        onReportingOptionsChange(ReportingOptionsEnum[key], isChecked);
    };

    const GetScaleDefaultReference = (): ReactElement => {
        const headText = isOverridden
            ? `${lang.defaultScaleCustomized} ${defaultScale.name}`
            : `${lang.defaultScaleUsed} ${defaultScale.name}`;

        // Should be 16px
        return (
            <Box width={300}>
                <Typography variant="body1">{headText}</Typography>
            </Box>
        );
    };

    const sortAnswerArray = <T extends Answer | ScaleValue>(arrayToSort: T[]): T[] =>
        sortArray(arrayToSort, "answerValue", "asc");

    return (
        <div>
            {surveyItemSelected && (
                <>
                    <div className={classes.header}>
                        <ReportingOptions
                            isHiddenForManagers={surveyItemSelected?.isHiddenForManagers ?? false}
                            isIgnored={surveyItemSelected?.isIgnored ?? false}
                            isRemovedOverallScore={surveyItemSelected?.isRemovedOverallScore ?? false}
                            onOptionChange={handleCheckboxChange}
                            isNominalScale={!defaultScale.isFunScale}
                        />
                    </div>
                    <div
                        className={defaultScale.isFunScale ? classes.actionsContainer : classes.actionsContainerFlexEnd}
                    >
                        {defaultScale.isFunScale && (
                            <>
                                <GetScaleDefaultReference />
                                <div data-testid="reporting-options-is-overridden" style={{ marginLeft: "30px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={ReportingOptionsEnum.isOverridden}
                                                checked={isOverridden}
                                                disabled={surveyItemSelected.isIgnored}
                                                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                                    handleCheckboxChange(
                                                        event.target.value as keyof typeof ReportingOptionsEnum,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        disabled={surveyItemSelected.isIgnored}
                                        label={lang.overrideDefault}
                                    />
                                </div>
                            </>
                        )}
                        {!defaultScale.isFunScale && <GetScaleDefaultReference />}
                    </div>
                    <List aria-label="scaleSetting" disablePadding>
                        {sortAnswerArray(surveyItemSelected.answers).map((answer: Answer, index: number) => (
                            <>
                                <ListItem
                                    key={answer.answerId}
                                    sx={styledListItem(surveyItemSelected?.isIgnored || !isOverridden)}
                                >
                                    <ListItemText primary={answer.description} />
                                    {defaultScale.isFunScale && (
                                        <SelectScale
                                            disabled={surveyItemSelected?.isIgnored || !isOverridden}
                                            scaleValue={answer.outputValue}
                                            onChangeSelect={(value: number) =>
                                                handleSurveyItemSelectChange(answer, value, index)
                                            }
                                        />
                                    )}
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                </>
            )}
            {defaultScale && !surveyItemSelected && (
                <>
                    <div className={classes.scalesActionsContainer} data-testid="reporting-options-use-nominal-scale">
                        <Checkbox
                            label={lang.useNominalScale}
                            value={ReportingOptionsEnum.useNominalScale}
                            checked={!defaultScale.isFunScale}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                handleCheckboxChange(
                                    event.target.value as keyof typeof ReportingOptionsEnum,
                                    event.target.checked
                                )
                            }
                        />
                    </div>
                    <List aria-label="scaleSetting" disablePadding>
                        {sortAnswerArray(defaultScale.scaleValues).map((scaleValue: ScaleValue, index: number) => (
                            <>
                                <ListItem key={scaleValue.id} sx={styledListItem(!defaultScale?.isFunScale)}>
                                    <ListItemText
                                        primary={scaleValue.answerDescription}
                                        primaryTypographyProps={{
                                            color: defaultScale.isFunScale
                                                ? theme.palette.text.primary
                                                : theme.palette.text.disabled
                                        }}
                                    />
                                    {defaultScale.isFunScale && (
                                        <SelectScale
                                            scaleValue={scaleValue.outputValue}
                                            onChangeSelect={(value: number) =>
                                                handleDefaultScaleSelectChange(value, index)
                                            }
                                        />
                                    )}
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                </>
            )}
        </div>
    );
};

export default CustomizationArea;

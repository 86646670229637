import React from "react";
import { Typography } from "lib/typography";
import { useLang } from "core/hooks";
import { DialogLayout } from "components/shared";
import DropArea from "../../shared/DropArea";
import { useAdminParticipantsContactStyles } from "./styles";
import { uploadEmpty } from "./AdminParticipantsContacts";
import { LoadFile } from "components/admin/types/types";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    fileDrop: (fileDrop: LoadFile) => void;
    uploadFile: LoadFile;
    handleRejectedFiles: () => void;
    handleUploadClick: () => void;
};

const AdminParticipantsImportDialog: React.FC<Props> = props => {
    const { lang } = useLang();
    const classes = useAdminParticipantsContactStyles();

    const handleFileDrop = (files: File[]): void => {
        const loadFile = { ...uploadEmpty, file: files[0], isLoaded: true };
        props.fileDrop(loadFile);
    };

    const validation = (): boolean => {
        let isNotValid = false;

        isNotValid = !props.uploadFile.isLoaded || !!props.uploadFile.errorMessage;
        return isNotValid;
    };

    return (
        <>
            <DialogLayout
                open={props.isOpen}
                onClose={props.onClose}
                title={lang.uploadCSVFile}
                buttonLabelAction={lang.ok}
                disableActionButton={validation()}
                onClick={props.handleUploadClick}
                height={300}
                minHeight={300}
            >
                <DropArea
                    fileDrop={handleFileDrop}
                    dataDropKey={"AdminParticipantsContacts"}
                    label={lang.dragDropFilesHereOrClick}
                    csvOnly
                    onRejectFiles={(): void => props.handleRejectedFiles()}
                />
                <Typography
                    className={classes.loadedFile}
                    variant={!props.uploadFile.isLoaded ? "body2" : "body1"}
                    color={props.uploadFile.errorMessage ? "error" : "initial"}
                >
                    {props.uploadFile.errorMessage
                        ? props.uploadFile.errorMessage
                        : !props.uploadFile.isLoaded
                          ? lang.noFileAttached
                          : props.uploadFile.file.name}
                </Typography>
            </DialogLayout>
        </>
    );
};

export default AdminParticipantsImportDialog;

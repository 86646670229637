import React, { createContext, PropsWithChildren, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";

import Loading from "components/shared/Loading";
import ErrorDisabledUser from "components/errorPage/ErrorDisabledUser";
import Error404 from "components/errorPage/Error404";

type Context = {
    user: User | undefined;
    token: string;
};

const AuthContext = createContext<Context>({
    user: undefined,
    token: ""
});

const AuthProvider = ({ children }: PropsWithChildren) => {
    const { isLoading, isAuthenticated, error, user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState("");

    if (window.Cypress && token === "") {
        const auth0Cypress = window.localStorage.getItem("auth0Cypress");
        if (auth0Cypress) {
            setToken(JSON.parse(auth0Cypress).access_token);
        }
    }

    if (isLoading) return <Loading />;
    if (error && error.message === "user is blocked") return <ErrorDisabledUser />;
    if (error) return <Error404 />;

    if (!isAuthenticated && token === "") {
        loginWithRedirect();
    }

    if (token === "") {
        (async (): Promise<void> => {
            const tokenFound = await getAccessTokenSilently();
            setToken(tokenFound);
        })();
    }

    if (token === "") return <Loading />;

    return (
        <AuthContext.Provider
            value={{
                user,
                token
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };

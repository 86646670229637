import { DocumentNode, OperationVariables, useQuery } from "@apollo/client";
//custom hooks for using graphql with promise, this is a workaround, future version might have this feature
export const useQueryWithPromise = (query: DocumentNode) => {
    const { refetch } = useQuery(query, { skip: true });

    const imperativelyCallQuery = (variables?: Partial<OperationVariables> | undefined) => {
        return refetch(variables);
    };

    return imperativelyCallQuery;
};

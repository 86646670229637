import React, { ReactElement, useState, useRef } from "react";
import { useLang } from "core/hooks";
import CustomSelectExpandablePanel from "components/shared/SelectExpandablePanel";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { Coordinate, SurveyItemMap } from "components/admin/results/interfaces";
import { Card } from "lib/card-custom";
import { Modal } from "lib/modal";
import { CardHandle } from "lib/card-custom/card";

type Props = {
    targetSurveyItemsOptions: SelectOptionExpandablePanel[];
    disabledItemsIds: string[];
    disabledQuestionOrSectionIds: string[];
    surveyItemIdSelected: SurveyItemMap;
    anchorPoints: Coordinate;
    title: string;
    updateSelectOption: (id: number) => void;
    onClose: () => void;
};

export const BenchmarkMappingDialog = (props: Props): ReactElement => {
    const {
        targetSurveyItemsOptions,
        disabledItemsIds,
        disabledQuestionOrSectionIds,
        anchorPoints,
        title,
        updateSelectOption,
        onClose
    } = props;

    const { lang } = useLang();
    const [selectedId, setSelectedId] = useState<string>("");
    const [isDirty, setIsDirty] = useState(false);
    const cardRef = useRef<CardHandle>(null);

    const handleSelectItem = (id: string): void => {
        setSelectedId(id);
    };

    const handleConfirmButtonClicked = (): void => {
        if (!selectedId || selectedId === "") {
            onClose();
        } else {
            updateSelectOption(+selectedId);
        }
    };

    return (
        <Modal anchorPosition={anchorPoints} onClose={onClose}>
            <Card
                ref={cardRef}
                title={title}
                onCancelAction={onClose}
                onConfirmAction={handleConfirmButtonClicked}
                headerAction={{
                    label: lang.clearMapping,
                    action: (): void => {
                        handleSelectItem("-1");
                        setIsDirty(true);
                        if (cardRef && cardRef.current) {
                            cardRef.current.makeFocus();
                        }
                    },
                    isDisabled: selectedId === "-1"
                }}
                width={700}
                height={580}
                isConfirmDisabled={!isDirty && selectedId === ""}
            >
                <CustomSelectExpandablePanel
                    rawData={targetSurveyItemsOptions}
                    onItemSelected={handleSelectItem}
                    selectedId={selectedId}
                    disabledItemsIds={disabledItemsIds}
                    disabledQuestionOrSectionIds={disabledQuestionOrSectionIds}
                />
            </Card>
        </Modal>
    );
};

import React, { PropsWithChildren, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import Help from "@mui/icons-material/Help";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { useLang } from "core/hooks";
import Loading from "../../components/shared/Loading";
import { Filter } from "components/filters/interfaces";
import FilterChip from "components/shared/FilterChip";
import { Button, ButtonVariant } from "lib/button";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Dialog, DialogActions, DialogContent } from "lib/dialog";
import { Tooltip } from "lib/tooltip";

const useStyles = makeStyles(() => ({
    root: ({ noBackdrop }: { noBackdrop: boolean }) => ({
        "& .MuiBackdrop-root": {
            backgroundColor: noBackdrop ? "transparent" : ""
        }
    }),
    paper: {
        width: 1080,
        height: 750,
        overflow: "hidden"
    },
    dialogHeadStyles: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: theme.border.main,
        paddingTop: 8
    },
    dialogTitle: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(3)
    },
    dialogSubHeadContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: theme.border.main
    },
    searchBarStyles: {
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        width: "30%",
        minWidth: "30%"
    },
    dialogActionsStyles: {
        borderTop: theme.border.main,
        margin: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    headerActions: {
        display: "flex",
        paddingRight: 16
    },
    filterTags: {
        paddingLeft: theme.spacing(3),
        width: "66%",
        maxWidth: "66%"
    }
}));

interface ButtonAction {
    label: string;
    onClickAction: () => void;
    isDisabled?: boolean;
    badgeValue?: number;
    variant?: ButtonVariant;
}

interface Props {
    primaryTitle: string;
    secondaryTitle?: string;
    handleCloseDialog: () => void;
    handleAction?: () => void;
    isDialogOpen: boolean;
    disableEnforceFocus?: boolean;
    helpContent?: string | JSX.Element;
    dataTestId: string;
    paperProps?: {
        style?: React.CSSProperties;
    };
    searchBar?: ReactElement;
    isDoubleAction?: boolean;
    buttonsLabel?: { action?: string; close?: string };
    disableActionButton?: boolean;
    disableCloseButton?: boolean;
    onSubMenuClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    actionButtons?: ButtonAction[];
    loading?: boolean;
    handleStartExport?: () => void;
    exportBtnDisabled?: boolean;
    currentFilter?: Filter;
    noBackdrop?: boolean;
    handleKeyUp?: () => void;
}

const styledDialogContent = {
    padding: 0,
    overflow: "hidden",
    boxSizing: "border-box"
};

const MPDialogLayout = (props: PropsWithChildren<Props>): ReactElement => {
    const { lang } = useLang();
    const {
        children,
        primaryTitle,
        secondaryTitle,
        handleCloseDialog,
        handleAction,
        isDialogOpen,
        disableEnforceFocus,
        helpContent,
        dataTestId,
        paperProps,
        searchBar,
        isDoubleAction,
        buttonsLabel,
        disableActionButton,
        disableCloseButton,
        onSubMenuClick,
        actionButtons,
        loading,
        handleStartExport,
        exportBtnDisabled,
        currentFilter,
        noBackdrop,
        handleKeyUp
    } = props;
    const classes = useStyles({ noBackdrop: noBackdrop ?? false });

    return (
        <Dialog
            maxWidth={false}
            fullScreen={false}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            classes={{ paper: classes.paper, root: classes.root }}
            disableEnforceFocus={disableEnforceFocus ? disableEnforceFocus : false}
            PaperProps={paperProps ? paperProps : {}}
            data-testid={dataTestId}
            onKeyUp={e => {
                if (e.key === "Enter" && handleKeyUp) {
                    handleKeyUp();
                }
            }}
        >
            {loading && <Loading styleProps={{ position: "absolute" }} />}
            <div className={classes.dialogHeadStyles}>
                <div className={classes.dialogTitle}>
                    <TruncateDisplay
                        title={secondaryTitle ? primaryTitle + " - " + secondaryTitle : primaryTitle}
                        maxLabelLength={110}
                    >
                        <Typography variant="h4">
                            {primaryTitle}
                            {secondaryTitle && ` - ${secondaryTitle}`}
                        </Typography>
                    </TruncateDisplay>
                </div>
                <div className={classes.headerActions}>
                    {actionButtons &&
                        actionButtons.length > 0 &&
                        actionButtons.map((button: ButtonAction) => (
                            <Button
                                key={button.label}
                                onClick={button.onClickAction}
                                sx={{ marginTop: 6 }}
                                disabled={button.isDisabled}
                                badgeValue={button.badgeValue}
                                data-testid={`btn-${button.label}`}
                                variant={button.variant}
                            >
                                {button.label}
                            </Button>
                        ))}
                    {handleStartExport && (
                        <Button
                            variant="text"
                            onClick={handleStartExport}
                            disabled={exportBtnDisabled}
                            data-testid="btn-mpDialog-layout-export"
                        >
                            {lang.export}
                        </Button>
                    )}
                    {helpContent && (
                        <Tooltip title={helpContent}>
                            <IconButton aria-label={lang.help}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    )}
                    {onSubMenuClick && (
                        <Tooltip title={lang.openMenu}>
                            <IconButton onClick={onSubMenuClick} data-testid="btn-dialog-sub-menu-open">
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            {searchBar && (
                <div className={classes.dialogSubHeadContainer}>
                    <div className={classes.filterTags}>
                        {currentFilter && currentFilter.items && currentFilter.items.length > 0 && (
                            <FilterChip items={currentFilter.items} />
                        )}
                    </div>
                    <div className={classes.searchBarStyles} id="searchBar">
                        {searchBar}
                    </div>
                </div>
            )}
            <DialogContent sx={styledDialogContent}>{children}</DialogContent>
            {!isDoubleAction ? (
                <DialogActions className={classes.dialogActionsStyles}>
                    <Box pr={3} display="flex" gap={2}>
                        <Button
                            data-testid="btn-mp-dialog-layout-close"
                            variant="text"
                            onClick={handleCloseDialog}
                            disabled={disableCloseButton}
                        >
                            {buttonsLabel?.close ? buttonsLabel?.close : lang.close}
                        </Button>
                    </Box>
                </DialogActions>
            ) : (
                <DialogActions className={classes.dialogActionsStyles}>
                    <Box pr={3} display="flex" gap={2}>
                        <Button
                            data-testid="btn-dialog-create"
                            variant="text"
                            onClick={handleAction!}
                            disabled={disableActionButton}
                        >
                            {buttonsLabel?.action ? buttonsLabel?.action : lang.create}
                        </Button>
                        <Button data-testid="btn-dialog-cancel" onClick={handleCloseDialog} variant="text">
                            {buttonsLabel?.close ? buttonsLabel?.close : lang.cancel}
                        </Button>
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    );
};
export default MPDialogLayout;

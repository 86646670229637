import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import ImageHolder from "./ImageHolder";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { theme } from "lib/theme";

type Props = {
    message: string;
    subMessage?: string[] | string;
    image: string;
    onButtonClick?: () => void;
    actionButtonLabel?: string;
};
const useStyles = makeStyles(() => ({
    emptyPageContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        paddingTop: "7%"
    },
    messageArea: {
        marginTop: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    message: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2)
    }
}));

const EmptyPage = (props: Props): ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.emptyPageContainer} data-testid="empty-state-page">
            <ImageHolder maxHeight={200} maxWidth={150} src={props.image} />
            <div className={classes.messageArea}>
                <Typography className={classes.message} variant="body1">
                    {props.message}
                </Typography>
                {props.subMessage && typeof props.subMessage === "string" && (
                    <Typography variant="subtitle1">{props.subMessage}</Typography>
                )}
                {props.subMessage &&
                    typeof props.subMessage === "object" &&
                    props.subMessage.map(str => (
                        <Typography variant="subtitle1" key={str}>
                            {str}
                        </Typography>
                    ))}

                {props.actionButtonLabel && props.onButtonClick && (
                    <Box p={2}>
                        <Button
                            variant="text"
                            onClick={props.onButtonClick}
                            data-testid={`btn-${props.actionButtonLabel}`}
                        >
                            {props.actionButtonLabel}
                        </Button>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default EmptyPage;

import React, { ReactElement } from "react";
import MuiChip, { ChipProps } from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

import { theme } from "../theme";

const deleteIconSyle = {
    width: 12,
    height: 12,
    marginBottom: "10px !important",
    color: `${theme.palette.primary.main} !important`
};

export const Chip = (props: ChipProps): ReactElement => {
    return <MuiChip {...props} deleteIcon={<ClearIcon sx={deleteIconSyle} />} />;
};

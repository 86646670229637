import React, { FC, ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { useLang } from "core/hooks";
import { LinearProgress, LinearProgressProps } from "lib/linear-progress";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 12
    },
    progressBox: {
        display: "flex",
        alignItems: "center"
    }
}));

type Props = {
    isCompleted: boolean;
    queuePosition: number;
    progress: number;
};

const CellDownloadStatus: FC<Props> = ({ isCompleted, queuePosition, progress }) => {
    const classes = useStyles();
    const { lang } = useLang();

    const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }): ReactElement => {
        return (
            <div className={classes.progressBox}>
                <Box width={250}>
                    <LinearProgress {...props} />
                </Box>
                <Box width={35} ml={2}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </div>
        );
    };
    if (isCompleted) {
        return (
            <Typography variant="body2" className={classes.text}>
                {lang.complete}
            </Typography>
        );
    }

    return queuePosition === 1 ? (
        <LinearProgressWithLabel value={progress} />
    ) : (
        <Typography variant="body2" className={classes.text}>
            {`${lang.reportQueued} (${lang.queuePositions} - ${queuePosition})`}
        </Typography>
    );
};

export default CellDownloadStatus;

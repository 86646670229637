import React, { Component } from "react";
import { blueGrey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { withStyles } from "@mui/styles";

import OrgChartCard from "../components/cards/OrgChartCard";
import DragScroll from "../components/shared/DragScroll";

const treeBaseX = 10;
const treeBaseY = 30;
const parentPeerBaseY = 10;
const styles = {
    button: {
        position: "absolute"
    },
    lines: {
        zIndex: 1,
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%"
    }
};

const CustomSvgButton = withStyles(styles)(({ classes, onClick, left, top, path, ariaDescribedby }) => (
    <IconButton
        onClick={onClick}
        className={classes.button}
        style={{ left: left, top: top }}
        aria-describedby={ariaDescribedby}
    >
        <SvgIcon viewBox="0 0 45 45">
            <circle style={{ fill: "#78787a" }} cx="22.85" cy="22.5" r="22.5" />
            <path style={{ fill: "#fff" }} d={path} />
        </SvgIcon>
    </IconButton>
));
const LeftArrowButton = props => (
    <CustomSvgButton {...props} path="M26.41,15.61,24.3,13.5l-9,9,9,9,2.11-2.11L19.54,22.5Z" />
);
const RightArrowButton = props => (
    <CustomSvgButton {...props} path="M19.3,29.39l2.11,2.11,9-9-9-9L19.3,15.61l6.86,6.89Z" />
);
const ArrowButton = props => (props.isLeft ? <LeftArrowButton {...props} /> : <RightArrowButton {...props} />);

class OrgChartLayout extends Component {
    constructor(props) {
        super(props);
        const isExpanded = {};
        this.depthFirstTraverse(this.props.hierarchy, node => {
            isExpanded[node.id] = true;
        });
        this.state = {
            isExpanded: isExpanded,
            cardMenuAnchorElement: null,
            showParents: this.props.showParents,
            showPeers: this.props.showPeers,
            peers: [...this.props.peers].sort(this.peerSort)
        };
    }

    componentDidUpdate = prevProps => {
        if (this.props.peers !== prevProps.peers) {
            this.setState({
                peers: [...this.props.peers].sort(this.peerSort)
            });
        }
        if (this.props.showPeers !== prevProps.showPeers) {
            this.setState({
                showPeers: this.props.showPeers
            });
        }
        if (this.props.showParents !== prevProps.showParents) {
            this.setState({
                showParents: this.props.showParents
            });
        }
    };

    peerSort = (a, b) => {
        const aHidden = a.response_count < this.props.threshold ? 1 : 0;
        const bHidden = b.response_count < this.props.threshold ? 1 : 0;
        if (aHidden !== bHidden) {
            return aHidden - bHidden;
        }
        const aTitle = a.title.toUpperCase();
        const bTitle = b.title.toUpperCase();
        return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
    };

    handleShowHideComparison = key => {
        this.props.handleShowHideComparison(key);
    };

    render() {
        const { classes, hierarchy, parents, cardWidth } = this.props;
        const { peers, showParents, showPeers } = this.state;

        this.cardLeftOffsetX = Math.round(cardWidth * 0.6);
        this.cardCenterOffsetX = Math.round(cardWidth * 0.1);
        this.cardSpacingX = Math.round(cardWidth * 0.1);
        this.cardSpacingY = Math.round(cardWidth * 0.2);

        // eslint-disable-next-line prefer-const
        let [positions, xmax, ymax] = this.makeHierarchyLayout(hierarchy);

        const rootCardX = positions[hierarchy.id].x;
        const calcCardOffsetX = i => 80 + (i + 1) * (this.cardSpacingX + cardWidth);
        const comparisonCardPositions = [
            ...(showParents
                ? parents.map((p, i) => ({
                      node: { ...p, readOnly: true },
                      x: rootCardX - calcCardOffsetX(i),
                      y: parentPeerBaseY
                  }))
                : []),
            ...(showPeers
                ? peers.map((p, i) => ({
                      node: { ...p, readOnly: true },
                      x: rootCardX + calcCardOffsetX(i),
                      y: parentPeerBaseY
                  }))
                : [])
        ];

        const buttonSpacingX = 90;
        const xmin = Math.min(...comparisonCardPositions.map(p => p.x));
        const offsetX = Math.min(xmin, 0, xmin >= 0 ? 0 : xmin - buttonSpacingX);
        if (offsetX < 0) {
            const updateCardsLeft = cards => {
                for (const id in cards) cards[id].x -= offsetX;
            };
            updateCardsLeft(positions);
            updateCardsLeft(comparisonCardPositions);
        }

        xmax = Math.max(
            xmax - offsetX,
            cardWidth +
                (comparisonCardPositions.length <= 0
                    ? 0
                    : comparisonCardPositions[comparisonCardPositions.length - 1].x)
        );

        // The nodes are given absolute positions which are relative to the "nearest positioned ancestor".
        // By default, DOM elements get position:static which doesn't count as "positioned", so we have to give the
        // container position:relative (with x/y of 0 so that it's still in the normal position) in order for the
        // nodes to be positioned relative to their container.
        // The size is set so that any elements that follow the container on the page are positioned correctly.
        let chartOffsetX = 0;
        if (window.innerWidth > xmax && !showParents) {
            chartOffsetX = window.innerWidth / 2 - cardWidth / 2 - rootCardX;
        }
        const containerStyle = {
            position: "relative",
            width: xmax + 20,
            height: ymax,
            left: chartOffsetX
        };

        const toggleButtonY = positions[hierarchy.id].y + this.props.cardHeight / 2 - 24;
        return (
            <DragScroll defaultScrollX={rootCardX} cardWidth={cardWidth} offsetX={offsetX}>
                <div style={containerStyle} data-testid="org-chart-layout">
                    <div style={{ zIndex: 2, position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }}>
                        {this.makeCardsFromPositions(positions)}
                        {this.makeCardsFromPositions(comparisonCardPositions)}
                        {parents.length !== 0 && (
                            <ArrowButton
                                ariaDescribedby="leftArrowButton"
                                isLeft={!showParents}
                                onClick={() => this.handleShowHideComparison("showParents")}
                                left={positions[hierarchy.id].x - 58}
                                top={toggleButtonY}
                            />
                        )}
                        {peers.length !== 0 && (
                            <ArrowButton
                                ariaDescribedby="rightArrowButton"
                                isLeft={showPeers}
                                onClick={() => this.handleShowHideComparison("showPeers")}
                                left={positions[hierarchy.id].x + cardWidth + 10}
                                top={toggleButtonY}
                            />
                        )}
                    </div>
                    <svg className={classes.lines}>{this.makeConnectingLines(positions, -offsetX)}</svg>
                </div>
            </DragScroll>
        );
    }

    depthFirstTraverse(node, func) {
        func(node);
        if (node.kids) node.kids.forEach(kid => this.depthFirstTraverse(kid, func));
    }

    hasKids(node) {
        return Array.isArray(node.kids) && node.kids.length > 0;
    }

    makeCardsFromPositions(positions) {
        const {
            layout,
            cardHeight,
            cardWidth,
            showComments,
            nodeGeneralUserRemoved,
            nodeWithUserUpdated,
            nodeCreationRightsUserRemoved
        } = this.props;
        const { isExpanded } = this.state;
        const cards = [];
        for (const id in positions) {
            const p = positions[id];
            cards.push(
                <OrgChartCard
                    key={p.node.id}
                    values={p.node}
                    nodeGeneralUserRemoved={nodeGeneralUserRemoved}
                    nodeCreationRightsUserRemoved={nodeCreationRightsUserRemoved}
                    nodeWithUserUpdated={nodeWithUserUpdated}
                    layout={layout}
                    width={cardWidth}
                    height={cardHeight}
                    left={p.x}
                    top={p.y}
                    onExpandClicked={id => this.onExpandClicked(id)}
                    isExpanded={isExpanded[p.node.id]}
                    canExpand={p.node.kids && p.node.kids.length > 0}
                    showComments={showComments}
                />
            );
        }
        return cards;
    }

    onExpandClicked(id) {
        const newExpandedState = Object.assign({}, this.state.isExpanded);
        newExpandedState[id] = !newExpandedState[id];
        this.setState({ isExpanded: newExpandedState });
    }

    makeHierarchyLayout(hierarchy) {
        const positions = {};
        let x = treeBaseX;
        const y = treeBaseY + this.props.cardHeight + this.cardSpacingY;

        let [xmax, ymax] = [this.props.cardWidth, this.props.cardHeight];

        if (this.state.isExpanded[hierarchy.id]) {
            if (hierarchy.kids)
                hierarchy.kids.forEach(kid => {
                    const [extentX, extentY] = this.makeHierarchyColumnLayout(positions, kid, 1, x, y);
                    x = extentX + this.cardSpacingX;
                    xmax = Math.max(extentX, xmax);
                    ymax = Math.max(extentY, ymax);
                });
        }

        positions[hierarchy.id] = { x: xmax / 2 - this.props.cardWidth / 2, y: treeBaseY, node: hierarchy, depth: 0 };
        return [positions, xmax, ymax];
    }

    makeHierarchyColumnLayout(positions, node, depth, columnX, ymin) {
        const x = columnX + (depth - 1) * this.cardLeftOffsetX;
        let y = ymin;
        positions[node.id] = { x: x, y: y, node: node, depth: depth };

        let xmax = x + this.props.cardWidth;
        let ymax = y + this.props.cardHeight;

        if (this.state.isExpanded[node.id]) {
            if (node.kids)
                node.kids.forEach(kid => {
                    y = ymax + this.cardSpacingY;
                    const [extentX, extentY] = this.makeHierarchyColumnLayout(positions, kid, depth + 1, columnX, y);
                    xmax = Math.max(extentX, xmax);
                    ymax = Math.max(extentY, ymax);
                });
        }
        return [xmax, ymax];
    }

    makeConnectingLines(positions, offsetX) {
        const lineStyle = {
            stroke: blueGrey[500],
            strokeWidth: 2
        };
        const lines = [];
        for (const id in positions) {
            const p = positions[id];
            if (p.depth >= 1 && this.hasKids(p.node)) {
                const lastKid = p.node.kids[p.node.kids.length - 1];
                const lastKidPos = positions[lastKid.id];
                if (lastKidPos) {
                    lines.push(
                        <line
                            key={`${p.node.id}3`}
                            x1={p.x + this.props.cardWidth / 2}
                            y1={p.y + this.props.cardHeight}
                            x2={p.x + this.props.cardWidth / 2}
                            y2={lastKidPos.y + this.props.cardHeight / 2}
                            style={lineStyle}
                        />
                    );
                }
            }
            if (p.depth > 1) {
                const ycenter = p.y + this.props.cardHeight / 2;
                lines.push(
                    <line
                        key={`${p.node.id}1`}
                        x1={p.x}
                        y1={ycenter}
                        x2={p.x - this.cardCenterOffsetX}
                        y2={ycenter}
                        style={lineStyle}
                    />
                );
            } else if (p.depth === 1) {
                const xcenter = p.x + this.props.cardWidth / 2;
                lines.push(
                    <line
                        key={`${p.node.id}1`}
                        x1={xcenter}
                        y1={p.y}
                        x2={xcenter}
                        y2={p.y - this.cardSpacingY / 2}
                        style={lineStyle}
                    />
                );
                lines.push(
                    <line
                        key={`${p.node.id}2`}
                        x1={treeBaseX + offsetX + this.props.cardWidth / 2}
                        y1={p.y - this.cardSpacingY / 2}
                        x2={xcenter}
                        y2={p.y - this.cardSpacingY / 2}
                        style={lineStyle}
                    />
                );
            } else {
                const xcenter = p.x + this.props.cardWidth / 2;
                lines.push(
                    <line
                        key={`${p.node.id}1`}
                        x1={xcenter}
                        y1={p.y + this.props.cardHeight}
                        x2={xcenter}
                        y2={p.y + this.props.cardHeight + this.cardSpacingY / 2}
                        style={lineStyle}
                    />
                );
            }
        }
        return lines;
    }
}

export default withStyles(styles)(OrgChartLayout);

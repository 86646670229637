import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    input: {
        height: 28,
        outline: "none",
        border: "1px solid white",
        fontSize: 15,
        width: "97%",
        "&:focus, &:focus-visible, &$active": {
            outline: "none",
            border: theme.border.main,
            borderColor: theme.palette.primary.main
        }
    }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableCell = ({ getValue, row, column, table }: any) => {
    const classes = useStyles();
    const initialValue = getValue();
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        tableMeta?.updateData(row.index, column.id, value);
    };

    return (
        <input
            className={classes.input}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
            onBlur={onBlur}
            type={columnMeta?.type || "text"}
        />
    );
};

export default TableCell;

import React, { ReactElement } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { IRouteProps } from "routes/interfaces/Routes.interface";
import RouteRender from "routes/RouteRender";
import { AdminRoutes } from "routes/routes";
import SidebarWithRoutes from "../../shared/SidebarWithRoutes";
import { useLang } from "core/hooks";
import { MainRoutes, AdminRoutes as AdminRoutesEnum } from "routes/enums";
import { Translation } from "core/languages/interfaces";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    adminPageContainer: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "224px 1fr",
        gridTemplateRows: "100%",
        gridTemplateAreas: `"adminPageSidebar adminPageContent"`
    },
    adminSideBar: {
        gridArea: "adminPageSidebar",
        height: "100%",
        maxHeight: "100%"
    },
    adminPageContent: {
        gridArea: "adminPageContent",
        height: "100%",
        maxHeight: "100%"
    }
}));

const Admin = (): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const location = useLocation();

    const sidebarItems = AdminRoutes(user)
        .filter((route: IRouteProps) => !route.isHiddenLink && route.label && route.isAccessGranted)
        .map((route: IRouteProps) => {
            return { path: route.path, title: lang[route.label! as keyof Translation] as string };
        });

    if (location.pathname === `/${MainRoutes.admin}`) {
        return <Redirect to={`/${MainRoutes.admin}/${AdminRoutesEnum.surveys}`} />;
    }

    return (
        <div data-testid="adminContainer" className={classes.adminPageContainer}>
            <div className={classes.adminSideBar}>
                <SidebarWithRoutes itemList={sidebarItems} />
            </div>
            <div className={classes.adminPageContent}>
                <RouteRender routes={AdminRoutes(user)} />
            </div>
        </div>
    );
};

export default Admin;

import React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import MuiSelect, { SelectProps, SelectChangeEvent } from "@mui/material/Select";
import { theme } from "lib/theme";
import { backgroundLightGrey } from "lib/palette";

type Item = {
    value: string | number;
    name: string;
};

type Props = SelectProps & {
    items: Item[];
    background?: string;
};

const FormControlS = styled((props: FormControlProps) => <FormControl {...props} />)(() => ({
    "& .MuiInputBase-root": {
        fontSize: "0.875rem"
    },
    "& .MuiInputBase-input": {
        background: backgroundLightGrey,
        "&:hover": {
            background: backgroundLightGrey
        }
    },
    "& .MuiFormLabel-root": {
        fontSize: "0.875rem"
    }
}));

const menuSelectStyle = {
    background: theme.palette.background.paper,
    "&.Mui-selected": {
        background: theme.palette.background.paper
    },
    "&.Mui-selected:hover": {
        background: theme.palette.background.paper
    },
    "&.Mui-focusVisible": {
        background: theme.palette.background.paper
    },
    "&:hover": {
        background: theme.palette.background.paper
    }
};
const menuItemStyle = {
    "&.Mui-selected": {
        background: `${theme.palette.action.hover} !important`
    },
    "&.Mui-selected:hover": {
        background: theme.palette.action.hover
    },
    "&.Mui-focusVisible": {
        background: theme.palette.action.hover
    },
    "&:hover": {
        background: theme.palette.action.hover
    }
};
const Select = (props: Props) => {
    const { items, label, ...rest } = props;

    return (
        <FormControlS fullWidth>
            <InputLabel>{label}</InputLabel>
            <MuiSelect label={label} {...rest} fullWidth sx={menuSelectStyle}>
                {items.map(i => (
                    <MenuItem key={i.value} value={i.value} sx={menuItemStyle}>
                        {i.name}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControlS>
    );
};

export { Select };

export type { SelectChangeEvent };

import { SurveyInfo } from "components/admin/results/interfaces";
import { useSurveys } from "core/hooks";
import { SelectedSurveyStoredData } from "components/admin/users/interface";
import { useUser } from "core/context/user/useUser";
import { emptySurveyInfo } from "components/admin/results/init";

type ReturnType = {
    selectedSurvey: SurveyInfo;
};

export const useSelectSurvey = (): ReturnType => {
    const { surveys } = useSurveys();
    const { user } = useUser();
    let surveySelected = emptySurveyInfo;

    const savedSelectedSurvey = user.settings.selectedSurvey.find(
        (s: SelectedSurveyStoredData) => s.orgId === user.settings.selectedOrganization.id
    );
    if (savedSelectedSurvey) {
        const foundSurveySelected = surveys.find((s: SurveyInfo) => s.id === savedSelectedSurvey.id && s.active);
        if (foundSurveySelected) {
            surveySelected = foundSurveySelected;
        }
    }

    return { selectedSurvey: surveySelected };
};

import { gql } from "@apollo/client";

export const previousPeriodOnLoad = gql`
    query previousPeriodOnLoad($surveyId: ID!, $languageCode: String!) {
        surveyComparisons(surveyId: $surveyId) {
            id
            label
            sourceSurveyId
            targetSurveyId
            showPreviousPeriod
            showInFirstColumn
            fieldMaps {
                sourceFieldId
                targetFieldId
                isSurveyItem
            }
            valueMaps {
                sourceFieldId
                sourceValue
                targetFilters {
                    combinationOperator
                    id
                    comparisonOperator
                    target
                    filterType
                }
            }
            showInHeatmap
        }
        surveyItemFields(surveyId: $surveyId, languageCode: $languageCode) {
            title
            sectionId
            questions {
                questionId
                questionTitle
                questionType
                isFun
                canCalculate
                items {
                    fieldId
                    title
                    itemId
                    isIgnored
                    isHiddenForManagers
                    answers {
                        answerId
                        description
                    }
                }
            }
        }
        surveyContactFields(surveyId: $surveyId) {
            fieldId
            title
        }
    }
`;

import React, { ReactElement, useReducer, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useLang, useAdminService, useLoading, useOrgChartService } from "core/hooks";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";
import { Button } from "lib/button";
import {
    CustomizeSnapshotExportState,
    uploadEmpty,
    fileEmpty,
    SSRLayoutSlideType,
    SSRLayoutSectionPayload
} from "./interfaces";
import { ComponentStatus } from "core/enums";
import { CustomizeSnapshotExportReducer } from "./reducers";
import { CustomizeSnapshotExportActions } from "./actions";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import DropArea from "components/shared/DropArea";
import { DropEvent, FileRejection } from "react-dropzone";
import { Delete } from "@mui/icons-material";
import Error500 from "components/errorPage/Error500";
import { PreviewDialogExportSlideWithLookdown } from "managerPortal/components/shared/PreviewDialogExportSlideWithLookdown";
import { demographicFieldsAndValuesQuery } from "api/queries";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { sortArray, OtherCompareFilterMapper } from "core/helpers";
import { defaultDemographicOption } from "components/reports/snapshot/Snapshot.init";
import {
    HeatmapCompareOption,
    LookdownItemExport,
    keyDriversExport
} from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { initFilter } from "components/filters/inits";
import { primaryBlue } from "core/styles/ColorsDefinition";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    header: {
        display: "flex",
        height: 82,
        minHeight: 82,
        maxHeight: 82,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottom: theme.border.main
    },
    title: {
        display: "flex",
        height: 82,
        minHeight: 82,
        maxHeight: 82,
        alignItems: "center"
    },
    panelsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "calc(100% - 82px)",
        minHeight: "calc(100% - 82px)",
        overflowY: "scroll"
    }
}));

export enum FileInputKey {
    coverFileLoaded = "coverFileLoaded",
    introFileLoaded = "introFileLoaded",
    understandResultFileLoaded = "understandResultFileLoaded",
    surveyResultTransFileLoaded = "surveyResultTransFileLoaded",
    understandHeatmapFileLoaded = "understandHeatmapFileLoaded",
    understandKeyDriverFileLoaded = "understandKeyDriverFileLoaded",
    outroFileLoaded = "outroFileLoaded"
}

const CustomizeSnapshotExport = (): ReactElement => {
    const classes = useStyles();
    const { lang, languageCode } = useLang();
    const adminService = useAdminService();
    const orgChartService = useOrgChartService();
    const { setLoading } = useLoading();
    const routeParams = useParams<{ id: string }>();

    const initialState = (): CustomizeSnapshotExportState => {
        return {
            isDirty: false,
            componentStatus: ComponentStatus.idle,
            confirmationDialog: false,
            isPreviewExportDialogOpen: false,
            coverFileLoaded: uploadEmpty,
            introFileLoaded: uploadEmpty,
            understandResultFileLoaded: uploadEmpty,
            surveyResultTransFileLoaded: uploadEmpty,
            understandHeatmapFileLoaded: uploadEmpty,
            understandKeyDriverFileLoaded: uploadEmpty,
            outroFileLoaded: uploadEmpty,
            compareOptions: [],
            surveySections: []
        };
    };

    const [state, dispatch] = useReducer(CustomizeSnapshotExportReducer, null, initialState);

    let hrisList: HrisFieldInfo[] = [];
    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                const compareOptions =
                    sortedDemographic.length === 1
                        ? [defaultDemographicOption]
                        : [
                              defaultDemographicOption,

                              ...sortedDemographic.map((contactField: HrisFieldInfo) => {
                                  return {
                                      name: contactField.fieldName,
                                      id: contactField.fieldId,
                                      values: contactField.fieldValues
                                  };
                              })
                          ];

                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COMPARE_OPTIONS,
                    payload: { compareOptions }
                });
            }
        }
    });

    const handlePreviewButtonOnClick = (): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.OPEN_PREVIEW,
            payload: { isPreviewExportDialogOpen: true }
        });
    };

    const handlePreviewExportDialogClose = (): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.OPEN_PREVIEW,
            payload: { isPreviewExportDialogOpen: false }
        });
    };

    const handleExport = (
        mainTitle: string,
        secondaryTitle: string,
        lookdownItems: LookdownItemExport[],
        heatmapFieldIds: number[],
        rrFieldIds: number[],
        keyDrivers: keyDriversExport[]
    ): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
            payload: { componentStatus: ComponentStatus.loading }
        });
        // For Preview, hard code some params to simplfy
        orgChartService
            .exportSnapshotReport(
                +routeParams.id,
                mainTitle,
                secondaryTitle,
                0,
                0,
                initFilter.items,
                languageCode,
                false,
                false,
                lookdownItems,
                heatmapFieldIds,
                "",
                rrFieldIds,
                keyDrivers,
                false,
                false,
                false,
                [HeatmapCompareOption.overall],
                OtherCompareFilterMapper(-1, "", initFilter),
                false
            )
            .then(() => {
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.idle }
                });
            })
            .catch(() => {
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
        dispatch({
            type: CustomizeSnapshotExportActions.OPEN_PREVIEW,
            payload: { isPreviewExportDialogOpen: false }
        });
    };

    const handleUploadButtonOnClick = (): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
            payload: { componentStatus: ComponentStatus.loading }
        });
        const ssrlayout: SSRLayoutSectionPayload[] = [];
        if (state.coverFileLoaded && state.coverFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.coverFileLoaded.fileName,
                file: state.coverFileLoaded.file,
                slideType: SSRLayoutSlideType.CoverFile
            });
        }
        if (state.introFileLoaded && state.introFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.introFileLoaded.fileName,
                file: state.introFileLoaded.file,
                slideType: SSRLayoutSlideType.IntroFile
            });
        }
        if (state.understandResultFileLoaded && state.understandResultFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.understandResultFileLoaded.fileName,
                file: state.understandResultFileLoaded.file,
                slideType: SSRLayoutSlideType.UnderstandResultFile
            });
        }
        if (state.surveyResultTransFileLoaded && state.surveyResultTransFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.surveyResultTransFileLoaded.fileName,
                file: state.surveyResultTransFileLoaded.file,
                slideType: SSRLayoutSlideType.SurveyResultTransFile
            });
        }
        if (state.understandHeatmapFileLoaded && state.understandHeatmapFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.understandHeatmapFileLoaded.fileName,
                file: state.understandHeatmapFileLoaded.file,
                slideType: SSRLayoutSlideType.UnderstandHeatmapFile
            });
        }
        if (state.understandKeyDriverFileLoaded && state.understandKeyDriverFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.understandKeyDriverFileLoaded.fileName,
                file: state.understandKeyDriverFileLoaded.file,
                slideType: SSRLayoutSlideType.UnderstandKeyDriverFile
            });
        }
        if (state.outroFileLoaded && state.outroFileLoaded.fileName !== undefined) {
            ssrlayout.push({
                fileName: state.outroFileLoaded.fileName,
                file: state.outroFileLoaded.file,
                slideType: SSRLayoutSlideType.OutroFileLoaded
            });
        }
        adminService
            .postSSRLayout(+routeParams.id, ssrlayout)
            .then(() => {
                fetchSSRLayout()
                    .then(() => {
                        if (state.coverFileLoaded && state.coverFileLoaded.errorMessage !== undefined) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                                payload: { coverFileLoaded: uploadEmpty }
                            });
                        }
                        if (state.introFileLoaded && state.introFileLoaded.errorMessage !== undefined) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                                payload: { introFileLoaded: uploadEmpty }
                            });
                        }
                        if (
                            state.understandResultFileLoaded &&
                            state.understandResultFileLoaded.errorMessage !== undefined
                        ) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                                payload: {
                                    understandResultFileLoaded: uploadEmpty
                                }
                            });
                        }
                        if (
                            state.surveyResultTransFileLoaded &&
                            state.surveyResultTransFileLoaded.errorMessage !== undefined
                        ) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                                payload: {
                                    surveyResultTransFileLoaded: uploadEmpty
                                }
                            });
                        }
                        if (
                            state.understandHeatmapFileLoaded &&
                            state.understandHeatmapFileLoaded.errorMessage !== undefined
                        ) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                                payload: {
                                    understandHeatmapFileLoaded: uploadEmpty
                                }
                            });
                        }
                        if (
                            state.understandKeyDriverFileLoaded &&
                            state.understandKeyDriverFileLoaded.errorMessage !== undefined
                        ) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                                payload: {
                                    understandKeyDriverFileLoaded: uploadEmpty
                                }
                            });
                        }
                        if (state.outroFileLoaded && state.outroFileLoaded.errorMessage !== undefined) {
                            dispatch({
                                type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                                payload: { outroFileLoaded: uploadEmpty }
                            });
                        }
                        dispatch({
                            type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                            payload: { componentStatus: ComponentStatus.idle }
                        });
                    })
                    .catch(() => {
                        dispatch({
                            type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                            payload: { componentStatus: ComponentStatus.error }
                        });
                    });
            })
            .catch(() => {
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
    };

    const handleClearAllButtonOnClick = (): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_CONFIRMATION_STATUS,
            payload: { status: true }
        });
    };

    const handleCancelClearAll = (): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_CONFIRMATION_STATUS,
            payload: { status: false }
        });
    };

    const handleConfirmationClicked = (): void => {
        adminService
            .deleteSSRLayout(+routeParams.id)
            .then(() => {
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_CLEAR_ALL,
                    payload: {
                        componentStatus: ComponentStatus.idle,
                        confirmationDialog: false,
                        coverFileLoaded: uploadEmpty,
                        introFileLoaded: uploadEmpty,
                        understandResultFileLoaded: uploadEmpty,
                        surveyResultTransFileLoaded: uploadEmpty,
                        understandHeatmapFileLoaded: uploadEmpty,
                        understandKeyDriverFileLoaded: uploadEmpty,
                        outroFileLoaded: uploadEmpty,
                        isDirty: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COMPONENT_STATUS,
                    payload: { componentStatus: ComponentStatus.error }
                });
            });
    };

    const handleDeleteFile = (key: string): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_IS_DIRTY,
            payload: {
                isDirty: true
            }
        });
        switch (key) {
            case FileInputKey.coverFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                    payload: { coverFileLoaded: uploadEmpty }
                });
                return;
            case FileInputKey.introFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                    payload: { introFileLoaded: uploadEmpty }
                });
                return;
            case FileInputKey.understandResultFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                    payload: {
                        understandResultFileLoaded: uploadEmpty
                    }
                });
                return;
            case FileInputKey.surveyResultTransFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                    payload: {
                        surveyResultTransFileLoaded: uploadEmpty
                    }
                });
                return;
            case FileInputKey.understandHeatmapFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                    payload: {
                        understandHeatmapFileLoaded: uploadEmpty
                    }
                });
                return;
            case FileInputKey.understandKeyDriverFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                    payload: {
                        understandKeyDriverFileLoaded: uploadEmpty
                    }
                });
                return;
            case FileInputKey.outroFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                    payload: { outroFileLoaded: uploadEmpty }
                });
                return;
            default:
                break;
        }
    };

    const handleFileDrop = (files: File[], reject: FileRejection[], event: DropEvent): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_IS_DIRTY,
            payload: {
                isDirty: true
            }
        });
        let title = "";
        if (event && event.target) {
            if ("getAttribute" in event.target && event.target.getAttribute("data-dropkey")) {
                title = event.target.getAttribute("data-dropkey") || "";
            } else if ("parentElement" in event.target) {
                title = event.target.parentElement?.title || "";
            }
        }
        const loadFile = { ...uploadEmpty, id: 0, file: files[0], fileName: files[0].name, isLoaded: true };
        // check if file is larger than 5 MB
        const sizeOverflow = loadFile.file.size / 1024 / 1024 > 5;
        const sizeOverflowMessgae = lang.sizeLimit5MB;

        switch (title) {
            case FileInputKey.coverFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                        payload: {
                            coverFileLoaded: {
                                ...state.coverFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                        payload: { coverFileLoaded: loadFile }
                    });
                }
                return;
            case FileInputKey.introFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                        payload: {
                            introFileLoaded: {
                                ...state.introFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                        payload: { introFileLoaded: loadFile }
                    });
                }
                return;
            case FileInputKey.understandResultFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                        payload: {
                            understandResultFileLoaded: {
                                ...state.understandResultFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                        payload: { understandResultFileLoaded: loadFile }
                    });
                }

                return;
            case FileInputKey.surveyResultTransFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                        payload: {
                            surveyResultTransFileLoaded: {
                                ...state.surveyResultTransFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                        payload: { surveyResultTransFileLoaded: loadFile }
                    });
                }
                return;
            case FileInputKey.understandHeatmapFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                        payload: {
                            understandHeatmapFileLoaded: {
                                ...state.understandHeatmapFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                        payload: { understandHeatmapFileLoaded: loadFile }
                    });
                }
                return;
            case FileInputKey.understandKeyDriverFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                        payload: {
                            understandKeyDriverFileLoaded: {
                                ...state.understandKeyDriverFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                        payload: { understandKeyDriverFileLoaded: loadFile }
                    });
                }

                return;
            case FileInputKey.outroFileLoaded:
                if (sizeOverflow) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                        payload: {
                            outroFileLoaded: {
                                ...state.outroFileLoaded,
                                id: 0,
                                errorMessage: sizeOverflowMessgae,
                                file: fileEmpty,
                                fileName: undefined,
                                isLoaded: false
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                        payload: { outroFileLoaded: loadFile }
                    });
                }
                return;
            default:
                break;
        }
    };

    const handleRejectedFiles = (key: string, message: string): void => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_IS_DIRTY,
            payload: {
                isDirty: true
            }
        });
        switch (key) {
            case FileInputKey.coverFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                    payload: {
                        coverFileLoaded: {
                            ...state.coverFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });
                return;
            case FileInputKey.introFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                    payload: {
                        introFileLoaded: {
                            ...state.introFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });
                return;
            case FileInputKey.understandResultFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                    payload: {
                        understandResultFileLoaded: {
                            ...state.understandResultFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });

                return;
            case FileInputKey.surveyResultTransFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                    payload: {
                        surveyResultTransFileLoaded: {
                            ...state.surveyResultTransFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });
                return;
            case FileInputKey.understandHeatmapFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                    payload: {
                        understandHeatmapFileLoaded: {
                            ...state.understandHeatmapFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });
                return;
            case FileInputKey.understandKeyDriverFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                    payload: {
                        understandKeyDriverFileLoaded: {
                            ...state.understandKeyDriverFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });

                return;
            case FileInputKey.outroFileLoaded:
                dispatch({
                    type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                    payload: {
                        outroFileLoaded: {
                            ...state.outroFileLoaded,
                            id: 0,
                            errorMessage: message,
                            file: fileEmpty,
                            fileName: undefined,
                            isLoaded: false
                        }
                    }
                });

                return;
            default:
                break;
        }
    };

    const fetchSSRLayout = async (): Promise<void> => {
        dispatch({
            type: CustomizeSnapshotExportActions.SET_IS_DIRTY,
            payload: {
                isDirty: false
            }
        });
        const layout = await adminService.getSSRLayout(+routeParams.id);
        if (layout && layout.sections) {
            layout.sections.forEach(section => {
                if (section.slideType === SSRLayoutSlideType.CoverFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_COVER_FILE_LOADED,
                        payload: {
                            coverFileLoaded: {
                                ...state.coverFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.IntroFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_INTRO_FILE_LOADED,
                        payload: {
                            introFileLoaded: {
                                ...state.introFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.UnderstandResultFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_RESULT_FILE_LOADED,
                        payload: {
                            understandResultFileLoaded: {
                                ...state.understandResultFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.SurveyResultTransFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_SURVEY_RESULT_TRANS_FILE_LOADED,
                        payload: {
                            surveyResultTransFileLoaded: {
                                ...state.surveyResultTransFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.UnderstandHeatmapFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_HEATMAP_FILE_LOADED,
                        payload: {
                            understandHeatmapFileLoaded: {
                                ...state.understandHeatmapFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.UnderstandKeyDriverFile) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_UNDERSTAND_KEYDRIVER_FILE_LOADED,
                        payload: {
                            understandKeyDriverFileLoaded: {
                                ...state.understandKeyDriverFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
                if (section.slideType === SSRLayoutSlideType.OutroFileLoaded) {
                    dispatch({
                        type: CustomizeSnapshotExportActions.SET_OUTRO_FILE_LOADED,
                        payload: {
                            outroFileLoaded: {
                                ...state.outroFileLoaded,
                                id: section.id,
                                errorMessage: undefined,
                                file: fileEmpty,
                                fileName: section.fileName,
                                isLoaded: true
                            }
                        }
                    });
                }
            });
        }
    };

    useEffect(() => {
        if (state.componentStatus === ComponentStatus.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [state.componentStatus]);

    useEffect(() => {
        fetchSSRLayout();
    }, []);

    useEffect(() => {
        loadDemographics({ variables: { surveyId: +routeParams.id, languageCode } });
    }, []);

    useEffect(() => {
        orgChartService.customScaleInit(+routeParams.id, languageCode).then(({ surveySections }) => {
            dispatch({
                type: CustomizeSnapshotExportActions.SET_SURVEY_SECTIONS,
                payload: {
                    surveySections: surveySections
                }
            });
        });
    }, []);

    const setSessionStorage = (): void => {
        const participantReloading = window.sessionStorage.getItem("participantReloading");
        if (participantReloading) {
            window.sessionStorage.removeItem("participantReloading");
        }
        window.sessionStorage.setItem("snapshotExportReloading", "true");
    };

    useEffect(() => {
        window.addEventListener("beforeunload", setSessionStorage);
        return () => {
            window.removeEventListener("beforeunload", setSessionStorage);
        };
    }, []);

    if (state.componentStatus === ComponentStatus.error) {
        return <Error500 />;
    }

    return (
        <div data-testid="customize-snapshot-export-container" className={classes.container}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <Typography
                        variant="h6"
                        style={{
                            fontSize: 20,
                            fontWeight: 400
                        }}
                    >
                        {lang.snapshotExportLayoutCustomization}
                    </Typography>
                </div>
                <Box display="flex" gap={2} paddingRight={2}>
                    <Box mr={"4px"}>
                        <Button
                            variant="outlined"
                            onClick={handleUploadButtonOnClick}
                            data-testid="btn-snapshot-customize-export-upload"
                            disabled={!state.isDirty}
                        >
                            {lang.save}
                        </Button>
                    </Box>

                    <Button
                        variant="text"
                        onClick={handlePreviewButtonOnClick}
                        data-testid="btn-snapshot-customize-export-preview"
                        disabled={state.isDirty}
                    >
                        {lang.preview}
                    </Button>
                    <Button
                        variant="text"
                        onClick={handleClearAllButtonOnClick}
                        data-testid="btn-snapshot-customize-export-clearall"
                        disabled={
                            state.coverFileLoaded.fileName === undefined &&
                            state.introFileLoaded.fileName === undefined &&
                            state.understandResultFileLoaded.fileName === undefined &&
                            state.surveyResultTransFileLoaded.fileName === undefined &&
                            state.understandHeatmapFileLoaded.fileName === undefined &&
                            state.understandKeyDriverFileLoaded.fileName === undefined &&
                            state.outroFileLoaded.fileName === undefined
                        }
                    >
                        {lang.clearAll}
                    </Button>
                </Box>
            </div>
            <div className={classes.panelsContainer}>
                <Box width={"60%"} minWidth={"60%"}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        height={"130px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        sx={{ backgroundColor: theme.palette.background.default }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: 14,
                                fontWeight: 500
                            }}
                            pl={"24px"}
                            pr={"24px"}
                        >
                            {lang.snapshotExportDescription[0] + "  " + lang.snapshotExportDescription[1]}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: 14,
                                fontWeight: 500
                            }}
                            pl={"24px"}
                            pr={"24px"}
                        >
                            {lang.snapshotExportDescription[2] + "  " + lang.snapshotExportDescription[3]}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: 14,
                                fontWeight: 500
                            }}
                            pl={"24px"}
                            pr={"24px"}
                        >
                            {lang.snapshotExportDescription[4]}
                            <a
                                href={`https://view.officeapps.live.com/op/view.aspx?src=https:%2f%2ftmmarketingstorage.blob.core.windows.net%2fsitefiles%2fSnapshot-Empty%2520Slide%2520Template.pptx&wdOrigin=BROWSELINK`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: primaryBlue }}
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500
                                    }}
                                    pl={"4px"}
                                    display={"inline"}
                                >
                                    {lang.snapshotExportDescription[5]}
                                </Typography>
                            </a>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500
                                }}
                                display={"inline"}
                            >
                                {lang.snapshotExportDescription[6]}
                                <a
                                    href={`https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/compass-admin/results/snapshot-customization`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: primaryBlue }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 500
                                        }}
                                        pl={"4px"}
                                        display={"inline"}
                                    >
                                        {lang.snapshotExportDescription[7]}
                                    </Typography>
                                </a>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500
                                    }}
                                    display={"inline"}
                                >
                                    .
                                </Typography>
                            </Typography>
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="cover-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.replaceCoverSlide}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.coverFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(FileInputKey.coverFileLoaded, lang.errorMustUploadPPTX)
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.coverFileLoaded.errorMessage
                                            ? ""
                                            : !state.coverFileLoaded.isLoaded
                                              ? ""
                                              : state.coverFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.coverFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.coverFileLoaded.errorMessage
                                            ? state.coverFileLoaded.errorMessage
                                            : !state.coverFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.coverFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                {
                                    <IconButton
                                        size="small"
                                        aria-label={lang.delete}
                                        onClick={(): void => handleDeleteFile(FileInputKey.coverFileLoaded)}
                                        color="default"
                                    >
                                        <Delete />
                                    </IconButton>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="intro-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.addAdditionalIntroSlide}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.introFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(FileInputKey.introFileLoaded, lang.errorMustUploadPPTX)
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.introFileLoaded.errorMessage
                                            ? ""
                                            : !state.introFileLoaded.isLoaded
                                              ? ""
                                              : state.introFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.introFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.introFileLoaded.errorMessage
                                            ? state.introFileLoaded.errorMessage
                                            : !state.introFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.introFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.introFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.reportDetailsSlide}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="understand-result-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.replaceUnderstandingResults}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.understandResultFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(
                                            FileInputKey.understandResultFileLoaded,
                                            lang.errorMustUploadPPTX
                                        )
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.understandResultFileLoaded.errorMessage
                                            ? ""
                                            : !state.understandResultFileLoaded.isLoaded
                                              ? ""
                                              : state.understandResultFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.understandResultFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.understandResultFileLoaded.errorMessage
                                            ? state.understandResultFileLoaded.errorMessage
                                            : !state.understandResultFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.understandResultFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.understandResultFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="survey-result-trans-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.replaceSurveyResultsTransition}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.surveyResultTransFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(
                                            FileInputKey.surveyResultTransFileLoaded,
                                            lang.errorMustUploadPPTX
                                        )
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.surveyResultTransFileLoaded.errorMessage
                                            ? ""
                                            : !state.surveyResultTransFileLoaded.isLoaded
                                              ? ""
                                              : state.surveyResultTransFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.surveyResultTransFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.surveyResultTransFileLoaded.errorMessage
                                            ? state.surveyResultTransFileLoaded.errorMessage
                                            : !state.surveyResultTransFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.surveyResultTransFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.surveyResultTransFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.responseRateSlide}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.lookupLookdownSlide}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="understand-heatmap-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.replaceUnderstandingHeatmap}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.understandHeatmapFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(
                                            FileInputKey.understandHeatmapFileLoaded,
                                            lang.errorMustUploadPPTX
                                        )
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.understandHeatmapFileLoaded.errorMessage
                                            ? ""
                                            : !state.understandHeatmapFileLoaded.isLoaded
                                              ? ""
                                              : state.understandHeatmapFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.understandHeatmapFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.understandHeatmapFileLoaded.errorMessage
                                            ? state.understandHeatmapFileLoaded.errorMessage
                                            : !state.understandHeatmapFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.understandHeatmapFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.understandHeatmapFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.heatmapSlide}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="understand-keyDriver-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.replaceUnderstandingKeyDrivers}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.understandKeyDriverFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(
                                            FileInputKey.understandKeyDriverFileLoaded,
                                            lang.errorMustUploadPPTX
                                        )
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.understandKeyDriverFileLoaded.errorMessage
                                            ? ""
                                            : !state.understandKeyDriverFileLoaded.isLoaded
                                              ? ""
                                              : state.understandKeyDriverFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.understandKeyDriverFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.understandKeyDriverFileLoaded.errorMessage
                                            ? state.understandKeyDriverFileLoaded.errorMessage
                                            : !state.understandKeyDriverFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.understandKeyDriverFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.understandKeyDriverFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.keyDriverSlides}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        height={"56px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                fontWeight: 400
                            }}
                            pl={"24px"}
                        >
                            {lang.surveyDataSlides}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        height={"110px"}
                        borderLeft={theme.border.main}
                        borderRight={theme.border.main}
                        borderBottom={theme.border.main}
                        id="outro-file"
                    >
                        <Box width={"55%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Typography pl={"24px"}>{lang.addOutroSlides}</Typography>
                        </Box>
                        <Box width={"45%"} height={"110px"} display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"} width={"320px"} mt={3}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={FileInputKey.outroFileLoaded}
                                    pptxOnly
                                    onRejectFiles={(): void =>
                                        handleRejectedFiles(FileInputKey.outroFileLoaded, lang.errorMustUploadPPTX)
                                    }
                                />
                                <Tooltip
                                    title={
                                        state.outroFileLoaded.errorMessage
                                            ? ""
                                            : !state.outroFileLoaded.isLoaded
                                              ? ""
                                              : state.outroFileLoaded.fileName
                                    }
                                    placement="bottom"
                                >
                                    <Typography
                                        mt={"4px"}
                                        variant={"body2"}
                                        color={state.outroFileLoaded.errorMessage ? "error" : "initial"}
                                        style={{
                                            maxWidth: 280,
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {state.outroFileLoaded.errorMessage
                                            ? state.outroFileLoaded.errorMessage
                                            : !state.outroFileLoaded.isLoaded
                                              ? lang.noFileAttached
                                              : state.outroFileLoaded.fileName}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box width={"20%"} display={"flex"} alignItems={"center"}>
                                <IconButton
                                    size="small"
                                    aria-label={lang.delete}
                                    onClick={(): void => handleDeleteFile(FileInputKey.outroFileLoaded)}
                                    color="default"
                                >
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
            <ConfirmationDialog
                condense={true}
                open={state.confirmationDialog}
                onCancelClicked={handleCancelClearAll}
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.deleteSnapshotExportLayout}
                message={lang.deleteCannotBeReversedForBulk}
                confirmButtonText={lang.ok}
                cancelButtonVariant="text"
            />
            {state.isPreviewExportDialogOpen && (
                <PreviewDialogExportSlideWithLookdown
                    compareOptions={state.compareOptions}
                    surveySections={state.surveySections}
                    isOpen={state.isPreviewExportDialogOpen}
                    onClose={handlePreviewExportDialogClose}
                    onSubmitCallback={handleExport}
                />
            )}
        </div>
    );
};

export default CustomizeSnapshotExport;

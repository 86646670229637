import React from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import Badge from "@mui/material/Badge";

import * as colors from "./buttons-palette";

export type ButtonVariant = "primary" | "secondary" | "delete" | "text" | "outlined" | "deleteText";

type Props = Omit<ButtonProps, "variant"> & {
    variant?: ButtonVariant;
    badgeValue?: number;
};

const Button = (props: Props) => {
    const { children, variant, badgeValue, ...rest } = props;
    let btnVariant: ButtonProps["variant"] = "contained";
    let style = null;

    if (variant === "secondary") {
        style = {
            color: colors.secondary,
            background: colors.secondaryBackground,
            "&:hover": {
                background: colors.secondaryHover
            }
        };
    }

    if (variant === "text") {
        btnVariant = "text";
        style = {
            "&:hover": {
                background: colors.primaryTextHover
            }
        };
    }
    if (variant === "outlined") {
        btnVariant = "outlined";
        style = {
            "&:hover": {
                background: colors.primaryTextHover
            }
        };
    }
    if (variant === "delete") {
        style = {
            color: colors.deleteColor,
            background: colors.deleteBackground,
            "&:hover": {
                background: colors.deleteHover
            }
        };
    }
    if (variant === "deleteText") {
        btnVariant = "text";
        style = {
            color: colors.deleteText,
            "&:hover": {
                background: colors.deleteTextHover
            }
        };
    }

    return (
        <Badge color="secondary" badgeContent={badgeValue} showZero={!!badgeValue} overlap="rectangular">
            <MuiButton {...rest} autoFocus={false} variant={btnVariant} sx={style}>
                {children}
            </MuiButton>
        </Badge>
    );
};

export { Button };
export type { Props as ButtonProps };

import React, { ReactNode, useState, ReactElement, useRef } from "react";
import clsx from "clsx";
import { useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";

import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import BarChart from "./BarChart";
import NumberCircle from "./NumberCircle";
import TruncateDisplay from "../../shared/TruncateDisplay";
import KeyDriverList from "../../shared/KeyDriverList";
import { DragItemType, ColumnType, CardContentType } from "./enums";
import { MappedCardContent, DragItem } from "./interfaces";
import { useVisibleNodes } from "../../../../core/hooks";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    mainListItem: ({ isDragging }: { isDragging: boolean }) => ({
        height: 48,
        display: "flex",
        alignItems: "center",
        opacity: isDragging ? 0.1 : 1,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.action.hover
        }
    }),
    listItemNonExpandable: {
        paddingLeft: theme.spacing(5)
    },
    listItemExpandable: {
        justifyContent: "space-between",
        padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(3)}`
    },
    typography: {
        width: "100%"
    },
    subPanel: {
        width: "100%"
    },
    chartItem: ({ isDragging }: { isDragging: boolean }) => ({
        width: "100%",
        opacity: isDragging ? 0.1 : 1,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.action.hover
        }
    }),
    numberCircleItem: ({ isDragging }: { isDragging: boolean }) => ({
        opacity: isDragging ? 0.1 : 1,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.action.hover
        }
    })
}));
interface Props {
    columnType: ColumnType;
    item: MappedCardContent;
    index: number;
    onBegin: (columnType: ColumnType) => void;
    moveItem: (
        droppedId: number,
        originalIndex: number,
        itemId: string,
        isDragging: boolean,
        columnType: ColumnType
    ) => void;
    children?: ReactNode;
    isExpandable?: boolean;
    isSubPanel?: boolean;
    truncateDisplay?: number;
    list: MappedCardContent[];
    onHover: (item: DragItem, columnType: ColumnType) => void;
    dataTestId?: string;
}
const CardLayoutItem = (props: Props): ReactElement => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const { visibleNodes } = useVisibleNodes();

    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: DragItemType,
        hover(item, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragItem = item as DragItem;
            const dragIndex = dragItem.index;
            const hoverIndex = props.index;

            const addItem = props.list.find((listItem: MappedCardContent) => listItem.id === dragItem.id);
            if (!addItem) {
                dragItem.index = hoverIndex;
                if (props.columnType === ColumnType.numberCircle && props.list.length < 4) {
                    props.onHover(dragItem, props.columnType);
                    return;
                }
                if (props.columnType === ColumnType.graphBar && props.list.length < 7) {
                    props.onHover(dragItem, props.columnType);
                    return;
                }
                return;
            }
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoveredRect = ref.current?.getBoundingClientRect();
            let hoverMiddle = (hoveredRect.bottom - hoveredRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            let hoverClient = (clientOffset as XYCoord).y - hoveredRect.top;
            if (props.columnType === ColumnType.numberCircle) {
                hoverMiddle = (hoveredRect.right - hoveredRect.left) / 2;
                hoverClient = (clientOffset as XYCoord).x - hoveredRect.left;
            }

            if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
                return;
            }

            props.moveItem(dragIndex, hoverIndex, props.item.id, true, props.columnType);
            dragItem.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: "cardContentType",
        item: { type: DragItemType, ...props.item, index: props.index },
        end: (_, monitor) => {
            const { id: droppedId, index: originalIndex } = monitor.getItem();
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                props.moveItem(+droppedId, originalIndex, props.item.id, false, props.columnType);
            }
        },
        collect: (monitor: DragSourceMonitor) => {
            return {
                isDragging: monitor.isDragging()
            };
        }
    });

    drag(drop(ref));

    const classes = useStyles({ isDragging });
    return props.columnType === ColumnType.graphBar ? (
        <div ref={ref} className={classes.chartItem} data-testid={props.item.title.toLowerCase()}>
            {props.item.itemType === CardContentType.keyDriver ? (
                <KeyDriverList
                    keyDrivers={visibleNodes.tree.key_drivers}
                    responseCount={visibleNodes.tree.response_count}
                />
            ) : (
                <>
                    <Box height="20px" width="100%">
                        <BarChart chartValues={props.item.chartValues} />
                    </Box>
                    {props.truncateDisplay ? (
                        <TruncateDisplay title={props.item.title} maxLabelLength={props.truncateDisplay}>
                            <Typography variant="subtitle2" align="left" mb={1}>
                                {props.item.title}
                            </Typography>
                        </TruncateDisplay>
                    ) : (
                        <Typography variant="subtitle2" align="left" mb={1}>
                            {props.item.title}
                        </Typography>
                    )}
                </>
            )}
        </div>
    ) : props.columnType === ColumnType.numberCircle ? (
        <div ref={ref} className={classes.numberCircleItem} data-testid={props.item.title.toLowerCase()}>
            <NumberCircle number={props.item.chartValues.favorable} title={props.item.title} />
        </div>
    ) : (
        <>
            <div
                ref={ref}
                key={props.item.id}
                className={clsx(
                    classes.mainListItem,
                    !props.isExpandable && props.isSubPanel ? classes.listItemNonExpandable : classes.listItemExpandable
                )}
                data-testid={props.item.title.toLowerCase()}
            >
                {props.truncateDisplay ? (
                    <TruncateDisplay title={props.item.title} maxLabelLength={props.truncateDisplay}>
                        <Typography className={classes.typography} variant="body2">
                            {props.item.title}
                        </Typography>
                    </TruncateDisplay>
                ) : (
                    <Typography className={classes.typography} variant="body2">
                        {props.item.title}
                    </Typography>
                )}
                {props.isExpandable && (
                    <IconButton onClick={(): void => setPanelOpen(!isPanelOpen)}>
                        {!isPanelOpen ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                )}
            </div>
            {props.children && isPanelOpen && <div className={classes.subPanel}>{props.children}</div>}
        </>
    );
};

export default CardLayoutItem;

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useOrganizationInfoStyles = makeStyles(() => ({
    container: {
        width: "100%",
        overflowY: "auto",
        paddingBottom: theme.spacing(3)
    },
    contentInfo: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    formControl: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: 500
    },
    dropAreaContainer: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        flex: 1
    },
    permission: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderBottom: theme.border.main,
        flex: 1
    },
    logoContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        display: "flex",
        width: 500,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    deleteLogoButton: {
        marginTop: theme.spacing(2),
        alignSelf: "center"
    },
    dropContainer: {
        display: "flex",
        paddingTop: "12px"
    }
}));

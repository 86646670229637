import React, { ReactElement } from "react";
import { Lens } from "@mui/icons-material";

import { funScaleItem } from "./constants";
import { useLang } from "core/hooks";
import { FunScaleItem } from "./interfaces";
import { FunScale } from "./enums";
import { neutralColor, unfavorableColor, favorableColor, ignoredColor } from "core/styles/ColorsDefinition";
import { Select, SelectChangeEvent } from "lib/select";
import { MenuItem } from "lib/menu-item";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { FormControl } from "lib/form-control";

type Props = {
    scaleValue: number;
    onChangeSelect: (value: number) => void;
    disabled?: boolean;
};

const SelectScale = (props: Props): ReactElement => {
    const { scaleValue, onChangeSelect, disabled } = props;
    const { lang } = useLang();

    const getColor = (funScale: FunScale): string => {
        switch (funScale) {
            case FunScale.neutral:
                return neutralColor;
            case FunScale.favorable:
                return favorableColor;
            case FunScale.unfavorable:
                return unfavorableColor;
            default:
                return ignoredColor;
        }
    };

    const handleChangeSelect = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as string;

        onChangeSelect(+elementValue);
    };

    return (
        <FormControl
            variant="standard"
            sx={{
                m: 1,
                minWidth: 200
            }}
        >
            <Select
                disabled={disabled}
                value={scaleValue}
                onChange={handleChangeSelect}
                SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
            >
                {funScaleItem(lang).map((s: FunScaleItem) => (
                    <MenuItem key={s.value} value={s.value} data-testid={`fan-scale-${s.name}`}>
                        <Box display="flex" gap={1} pl={1}>
                            <Lens style={{ fill: getColor(s.propertyName) }} />
                            <Typography>{s.name}</Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectScale;

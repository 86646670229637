import { makeStyles } from "@mui/styles";

import { lightPink } from "core/styles/BackgroundPalette";
import { theme } from "lib/theme";

export const useBenchmarkMappingStyles = makeStyles(() => ({
    container: {
        width: "100%"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 82px)",
        boxSizing: "border-box"
    },
    contentInfoHeader: {
        width: "100%",
        height: 32,
        maxHeight: 32,
        display: "flex",
        alignItems: "center",
        background: theme.palette.grey[300]
    },
    contentHead: {
        width: "100%",
        height: 82,
        maxHeight: 82,
        display: "flex",
        justifyContent: "space-between",
        borderBottom: theme.border.main,
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        boxSizing: "border-box"
    },
    contentHeadLeft: {
        display: "flex",
        alignItems: "center"
    },
    contentHeadRight: {
        display: "flex",
        alignItems: "center"
    },
    selectBenchmark: {
        width: 322,
        "& div": {
            "&::after": {
                borderBottom: `2px solid ${theme.palette.primary.main}`
            }
        },

        "& label.Mui-focused": {
            color: `${theme.palette.primary.main}`
        }
    },
    benchmarkSectionList: {
        width: "100%",
        padding: 0,
        borderRight: theme.border.main,
        height: "100%",
        overflowY: "auto",
        paddingTop: 0
    },
    questionItem: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: theme.border.main,
        height: 56,
        maxHeight: 56
    },
    notCompleted: {
        background: lightPink
    },
    sectionList: {
        background: theme.palette.grey[200],
        borderBottom: theme.border.main,
        "&:hover": {
            background: `${theme.palette.grey[200]} !important`,
            cursor: "pointer"
        },
        justifyContent: "space-between"
    },
    selectInputProps: {
        paddingTop: 25,
        paddingBottom: 5
    },
    questionCompleted: {
        background: `${theme.palette.action.hover} !important `
    },
    textLine: {
        paddingBottom: 8
    },
    sectionListHeader: {
        display: "flex",
        height: 49,
        maxHeight: 49
    },
    sectionListHeaderIcon: {
        background: theme.palette.grey[200],
        borderBottom: theme.border.main,
        width: 58,
        maxWidth: 58
    },
    expandMoreIcon: {
        transform: "rotate(-90deg)",
        marginTop: 28,
        paddingLeft: 30,
        color: theme.palette.action.active
    },
    expandLessIcon: {
        transform: "rotate(0deg)",
        marginTop: 12,
        paddingLeft: 18,
        color: theme.palette.action.active
    },
    questionTitleTextLeft: {
        display: "flex",
        paddingLeft: 58,
        alignItems: "center",
        width: "50%",
        borderRight: theme.border.main
    },
    questionTitleTextRight: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 24,
        alignItems: "center",
        width: "50%"
    },
    selected: {
        border: `2px solid ${theme.palette.action.active}`
    }
}));

import React from "react";

import MuiGrid, { GridProps } from "@mui/material/Grid";

const Grid = (props: GridProps) => {
    const { children, ...rest } = props;
    return <MuiGrid {...rest}>{children}</MuiGrid>;
};

export { Grid };

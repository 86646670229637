import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import ManagerPortal from "managerPortal/ManagerPortal";
import { useSurveys, useLang } from "core/hooks";
import EmptyPage from "../../shared/EmptyPage";
import { Typography } from "lib/typography";
import ReportingEmptyState from "../../../assets/images/emptyPages/ReportingEmptyState.svg";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    emptyRestricted: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
        padding: "5% 0 0 5%"
    }
}));

const OrgChart = (): ReactElement => {
    const classes = useStyles();
    const { surveys } = useSurveys();
    const { user } = useUser();
    const { lang } = useLang();

    if (user.isRestricted && surveys.length <= 0) {
        return (
            <div className={classes.emptyRestricted}>
                <Typography variant="h6">{lang.noOrgChartAvailable}</Typography>
                <Typography variant="body2">{lang.noOrgChartAvailableMsg}</Typography>
                <Typography variant="body2">{lang.noOrgChartAvailableContact}</Typography>
            </div>
        );
    }

    if (!user.isRestricted && surveys.length <= 0) {
        return (
            <EmptyPage
                message={lang.noResultsCreated}
                subMessage={lang.noResultsCreatedMessage}
                image={ReportingEmptyState}
            />
        );
    }

    if (surveys.length <= 0) return <></>;

    return <ManagerPortal />;
};

export default OrgChart;

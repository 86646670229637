import React, { ReactElement, useState } from "react";
import { makeStyles } from "@mui/styles";

import Highlighter from "react-highlight-words";
import { mdiArrowExpand, mdiArrowCollapse } from "@mdi/js";
import { ActivityLogInfo, ActivityLogInfoDetails, ActivityLogAction } from "components/admin/participants/interfaces";
import { useLang } from "core/hooks";
import { dateFormatWithTime } from "core/constants";
import { Button } from "lib/button";
import { IconButtonV4 } from "lib/icon-button";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderBottom: theme.border.main
    },
    expandIcon: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: 8
    },
    iconButton: {
        display: "flex",
        alignItems: "center",
        paddingRight: 24
    },
    info: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 8
    },
    details: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 8
    },
    highlightWord: {
        backgroundColor: "#7BEDA7"
    }
}));

type Props = {
    activityLog: ActivityLogInfo;
    index: number;
    searchTerm: string;
};

const AdminParticipantsActivityLogDetails = (props: Props): ReactElement => {
    const classes = useStyles();
    const [expandMore, setExpandMore] = useState<boolean>(false);
    const { lang } = useLang();

    const getHighlitedLog = (log: string): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={props.searchTerm ? [props.searchTerm] : []}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={log}
                />
            </>
        );
    };

    const toggleExpand = (): void => {
        setExpandMore(!expandMore);
    };

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(
            `${lang.date}:  ${dayjs(props.activityLog.date).format(dateFormatWithTime)} UTC ${lang.user}:  ${
                props.activityLog.user
            } ${JSON.parse(props.activityLog.records)
                .map(
                    (r: ActivityLogInfoDetails) =>
                        `${mapActions(r.Action)} ${r.Details.length}, ${r.Details.join(", ")}`
                )
                .join(", ")}`
        );
    };

    const getRecordDetailsText = (details: string[]): ReactElement[] => {
        if (details.length <= 30) {
            return details.map((d, index) => <React.Fragment key={index}>{getHighlitedLog(d)}</React.Fragment>);
        } else {
            return details
                .slice(0, 30)
                .concat(`${lang.andMore}...`)
                .map((d, index) => <React.Fragment key={index}>{getHighlitedLog(d)}</React.Fragment>);
        }
    };

    const mapActions = (action: ActivityLogAction): string => {
        switch (action) {
            case ActivityLogAction.FiedlAdded:
                return lang.fieldAdded;
            case ActivityLogAction.FieldRemoved:
                return lang.fieldRemoved;
            case ActivityLogAction.ParticipantCreated:
                return lang.participantCreated;
            case ActivityLogAction.ParticipantUpdated:
                return lang.participantUpdated;
            case ActivityLogAction.ParticipantDeleted:
                return lang.participantDeleted;
            case ActivityLogAction.ImportInitial:
                return lang.initialImport;
            default:
                return "";
        }
    };

    const getCount = (record: ActivityLogInfoDetails): number =>
        record.Action != ActivityLogAction.ParticipantUpdated
            ? record.Details.length
            : new Set(record.Details.map(x => x.split(" ")[0])).size;

    return (
        <div className={classes.container} data-testid={`activity-log-${props.index.toString()}`}>
            <div className={classes.expandIcon}>
                <Button variant="text" data-testid={`btn-copy-to-clipBoard`} onClick={copyToClipboard}>
                    {lang.copyToClipborad}
                </Button>
                <div className={classes.iconButton}>
                    <IconButtonV4
                        path={expandMore ? mdiArrowCollapse : mdiArrowExpand}
                        onClick={toggleExpand}
                        dataTestid={`expand-icon-${props.index.toString()}`}
                    />
                </div>
            </div>
            <div className={classes.info} data-testid={`activity-log-info-${props.index.toString()}`}>
                {getHighlitedLog(`${lang.date}:  ${dayjs(props.activityLog.date).format(dateFormatWithTime)} UTC`)}
                {getHighlitedLog(`${lang.user}:  ${props.activityLog.user}`)}
            </div>
            {expandMore &&
                JSON.parse(props.activityLog.records).map((record: ActivityLogInfoDetails, index: number) => (
                    <div
                        className={classes.details}
                        key={index}
                        data-testid={`activity-log-info-${props.index.toString()}-details-${index}`}
                    >
                        <Typography>{`${mapActions(record.Action)}: ${getCount(record)}`}</Typography>
                        {getRecordDetailsText(record.Details)}
                    </div>
                ))}
        </div>
    );
};

export default AdminParticipantsActivityLogDetails;

import { FilterParameter, ReportFilterInput } from "../interfaces";
import { CombinationOperator } from "../enums";

//mapping function to parse fe filter value for be to deserialize
export const filterConnectorToBackEnd = (filterItems: FilterParameter[] | null): ReportFilterInput[] | null =>
    filterItems == null
        ? null
        : filterItems.map((item: FilterParameter) => ({
              id: item.field.id,
              combinationOperator:
                  item.combinationOperator === CombinationOperator.none ? null : item.combinationOperator,
              filterType: item.filterType,
              comparisonOperator: item.comparisonOperator,
              target: item.target.map(t => t.id)
          }));

import React, { ChangeEvent, ReactElement, useState } from "react";

import { TextField } from "lib/text-field";
import { Button } from "lib/button";
import { useLang, useLoading, useSurveys } from "core/hooks";
import { SurveyInfo } from "../interfaces";
import { isStringEmptyOrSpaces, mapToTargetSurvey } from "core/helpers";
import { TargetSurvey } from "./interfaces";
import { initTargetSurvey } from "./init";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";

type Props = {
    onAutoMap: (targetSurveyId: number, label: string) => void;
    selectedSurveyId: number;
};

const PreviousPeriodNotMapped = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { surveys } = useSurveys();
    const { isLoading } = useLoading();
    const [targetSurvey, setTargetSurvey] = useState<TargetSurvey>(initTargetSurvey);
    const [mappingLabel, setMappingLabel] = useState("");
    const [labelError, setLabelError] = useState<string | null>(null);
    const [lockConfirmationDialog, setLockConfirmationDialog] = useState(false);

    const selectedSurvy = surveys.find(s => s.id === props.selectedSurveyId);
    const isLocked = (selectedSurvy && selectedSurvy.lockMappings) ?? false;

    const handleTargetSurveyChange = (value: AutocompleteValue | null): void => {
        const surveyFound = surveys.find((survey: SurveyInfo) => survey.id === value?.id);
        if (surveyFound) {
            setTargetSurvey(
                surveyFound
                    ? {
                          id: surveyFound.id,
                          name: surveyFound.translations[0].name
                      }
                    : initTargetSurvey
            );
        }
    };

    const handleMapLabelChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > 7) {
            setLabelError(lang.mappingLabelDescription[0]);
            return;
        }
        setMappingLabel(event.target.value);
        setLabelError(null);
    };

    const targetSurveys = mapToTargetSurvey(surveys, props.selectedSurveyId, true);

    const autoMapButtonDisabled = (): boolean => {
        if (!!labelError || isStringEmptyOrSpaces(mappingLabel) || targetSurvey.id === initTargetSurvey.id) return true;

        return isLoading;
    };

    const handleAutoMap = (): void => {
        if (!isLocked) {
            props.onAutoMap(targetSurvey.id, mappingLabel);
        } else {
            setLockConfirmationDialog(true);
        }
    };

    const handleCloseConfirmation = (): void => {
        setLockConfirmationDialog(false);
    };

    return (
        <Box width="100%" height="100%" p={3}>
            <Box mb={3}>
                <Typography fontWeight="bold" variant="body1">
                    {lang.noPreviousPeriodMappingCreated}
                </Typography>
                <Typography variant="body2">{lang.toContinueEnterInformationBelowAndAutomap}</Typography>
            </Box>
            <Box width={400} mb={3}>
                <Autocomplete
                    id="previous-period-survey-select"
                    noOptionsText={lang.noOptionsAvailable}
                    options={targetSurveys.map(s => {
                        return {
                            id: s.id,
                            label: s.name
                        };
                    })}
                    onChange={handleTargetSurveyChange}
                    placeholder={lang.previousSurvey}
                />
                <Typography variant="subtitle2">{lang.previousSurveyDescription}</Typography>
            </Box>
            <Box width={140}>
                <TextField
                    data-testid="text-field-pp-not-mapped-label"
                    label={lang.mappingLabel}
                    name={lang.mappingLabel}
                    value={mappingLabel}
                    onChange={handleMapLabelChange}
                    error={!!labelError}
                    helperText={labelError ?? ""}
                    FormHelperTextProps={{
                        sx: { marginLeft: "0px !important", marginRight: "0px !important" }
                    }}
                />
            </Box>
            {!labelError && (
                <>
                    <Typography variant="subtitle2">{lang.mappingLabelDescription[0]}</Typography>
                    <Typography variant="subtitle2">{lang.mappingLabelDescription[1]}</Typography>
                </>
            )}
            <Box mt={labelError ? 4 : 2}>
                <Button
                    onClick={handleAutoMap}
                    disabled={autoMapButtonDisabled() || isLocked}
                    data-testid="btn-pp-automap"
                    variant={isLocked ? "text" : "primary"}
                >
                    {lang.automap}
                </Button>
            </Box>
            <ConfirmationDialog
                open={lockConfirmationDialog}
                onCancelClicked={handleCloseConfirmation}
                onConfirmationClicked={(): void => void 0}
                title={lang.editMapping}
                message={""}
                messgaeWithLink={
                    <>
                        <span>{lang.lockMessagePrefix}</span>
                        <a href={"mailto:support@talentmap.com"} target="_blank" rel="noopener noreferrer">
                            support@talentmap.com
                        </a>
                        <span>{lang.lockMessageSuffix}</span>
                    </>
                }
                cancelButtonText={lang.ok}
                cancelButtonVariant="delete"
                hideCancelButton
            />
        </Box>
    );
};

export default PreviousPeriodNotMapped;

import React from "react";

import MuiList, { ListProps } from "@mui/material/List";
import MuiListItem, { ListItemProps } from "@mui/material/ListItem";
import MuiListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import MuiListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import MuiListItemIcon, { ListItemIconProps } from "@mui/material/ListItemIcon";
import { theme } from "lib/theme";

const listItemButtonStyle = {
    "&.Mui-selected": {
        background: theme.palette.action.hover
    },
    "&.Mui-selected:hover": {
        background: theme.palette.action.hover
    },
    "&.Mui-focusVisible": {
        background: theme.palette.action.hover
    },
    "&:hover": {
        background: theme.palette.action.hover
    }
};

const List = (props: ListProps) => {
    const { children, ...rest } = props;
    return <MuiList {...rest}>{children}</MuiList>;
};

const ListItem = (props: ListItemProps) => {
    const { children, ...rest } = props;
    return (
        <MuiListItem {...rest} sx={listItemButtonStyle}>
            {children}{" "}
        </MuiListItem>
    );
};

const ListItemText = (props: ListItemTextProps) => {
    const { children, ...rest } = props;
    return <MuiListItemText {...rest}>{children}</MuiListItemText>;
};

const ListItemButton = (props: ListItemButtonProps) => {
    const { children, ...rest } = props;
    return (
        <MuiListItemButton {...rest} sx={listItemButtonStyle}>
            {children}
        </MuiListItemButton>
    );
};

const ListItemIcon = (props: ListItemIconProps) => {
    const { children, ...rest } = props;
    return <MuiListItemIcon {...rest}>{children}</MuiListItemIcon>;
};

export { List, ListItem, ListItemText, ListItemButton, ListItemIcon };

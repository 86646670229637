import React from "react";

import { ReactComponent as ErrorSVG } from "../../assets/images/error/404Error.svg";
import { useLang } from "core/hooks";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Typography } from "lib/typography";

const containerStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 5
};

const ErrorDisabledUser = (): React.ReactElement => {
    const { lang } = useLang();

    const closeTab = (): void => {
        window.open("mailto:support@talentmap.com?subject=User disabled");
    };

    return (
        <Box data-testid="disabledUser" sx={containerStyle}>
            <Box mb={4}>
                <Typography variant="h1">{lang.loginError}</Typography>
            </Box>
            <Box m={1} mb={2}>
                <Typography variant="h6" color="GrayText" align="center">
                    {lang.loginErrorMsg}
                </Typography>
            </Box>

            <Box mt={3.5} mb={7}>
                <Button data-testid="btn-contact-us" onClick={closeTab}>
                    {lang.contactUs}
                </Button>
            </Box>
            <ErrorSVG />
        </Box>
    );
};

export default ErrorDisabledUser;

import { InsightState } from "../interface";
import { InsightActions } from "../action";
import { Action } from "core/interfaces";

export const insightReducer = (state: InsightState, action: Action): InsightState => {
    const { type, payload } = action;

    switch (type) {
        case InsightActions.SELECT_SURVEY:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                currentFilter: payload.currentFilter
            };
        case InsightActions.SET_INIT_DATA:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                status: payload.status,
                correlation: payload.correlation,
                keyDriverList: payload.keyDriverList,
                currentFilter: payload.currentFilter,
                isFilterDialogOpen: payload.isFilterDialogOpen,
                confidentialityResult: payload.confidentialityResult,
                responseCount: payload.responseCount,
                demographicField: payload.demographicField,
                selectedOutcome: payload.selectedOutcome,
                selectedKeyDriver: payload.selectedKeyDriver,
                selectedDemographicField: payload.selectedDemographicField,
                previousPeriodLabels: payload.previousPeriodLabels,
                expandedKeyDriverSections: payload.expandedKeyDriverSections,
                selectedKeyDriverSection: payload.selectedKeyDriverSection,
                showOrgOverall: payload.showOrgOverall,
                customizedShowPP: payload.customizedShowPP,
                showPreviousPeriod: payload.showPreviousPeriod,
                selectedKeyItemId: payload.selectedKeyItemId
            };
        case InsightActions.SET_STATUS:
            return { ...state, status: payload.status };
        case InsightActions.SET_FILTER:
            return {
                ...state,
                currentFilter: payload.currentFilter,
                responseCount: payload.responseCount
            };
        case InsightActions.OPEN_CLOSE_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case InsightActions.SET_CONFIDENTIALITY_STATUS:
            return {
                ...state,
                confidentialityResult: payload.confidentialityResult
            };
        case InsightActions.SELECT_OUTCOME:
            return {
                ...state,
                selectedOutcome: payload.selectedOutcome
            };
        case InsightActions.SELECT_KEYDRIVER:
            return {
                ...state,
                selectedKeyDriver: payload.selectedKeyDriver
            };
        case InsightActions.SELECT_DEMOGRAPHIC:
            return {
                ...state,
                selectedDemographicField: payload.selectedDemographicField
            };
        case InsightActions.UPDATE_KEYDRIVER_LIST:
            return {
                ...state,
                keyDriverList: payload.keyDriverList
            };
        case InsightActions.UPDATE_KEYDRIVER_SCORE:
            return {
                ...state,
                keyDriverScore: payload.keyDriverScore
            };
        case InsightActions.SELECT_EXPANDED_SECTION:
            return {
                ...state,
                expandedKeyDriverSections: payload.sectionTitle
            };
        case InsightActions.UPDATE_CORRELATION_DATA:
            return {
                ...state,
                correlation: payload.correlation,
                currentFilter: payload.currentFilter,
                keyDriverList: payload.keyDriverList,
                selectedKeyDriver: payload.selectedKeyDriver,
                isFilterDialogOpen: payload.isFilterDialogOpen,
                confidentialityResult: payload.confidentialityResult,
                responseCount: payload.responseCount,
                expandedKeyDriverSections: payload.expandedKeyDriverSections,
                demographicField: payload.demographicFields,
                selectedKeyDriverSection: payload.selectedKeyDriverSection,
                showPreviousPeriod: payload.showPreviousPeriod,
                previousPeriodLabels: payload.previousPeriodLabels,
                selectedKeyItemId: payload.selectedKeyItemId
            };
        case InsightActions.SELECT_KEYDIVER_SECTION:
            return {
                ...state,
                selectedKeyDriverSection: payload.selectedKeyDriverSection
            };
        case InsightActions.UPDATE_KEYDRIVER_CORRELATION:
            return {
                ...state,
                correlation: payload.correlation,
                keyDriverList: payload.keyDriverList,
                confidentialityResult: payload.confidentialityResult
            };
        case InsightActions.APPLY_PRESELECTION:
            return {
                ...state,
                showOrgOverall: payload.showOrgOverall,
                customizedShowPP: payload.customizedShowPP
            };
        case InsightActions.UPDATE_SELECTED_KEY_ITEM:
            return {
                ...state,
                selectedKeyItemId: payload.selectedKeyItemId
            };
        default:
            return {
                ...state
            };
    }
};

export const nameToIdentifier = (str: string): string => {
    return str
        .replace(" ", "")
        .replace("/", "")
        .replace("&", "")
        .replace("-", "")
        .replace("_", "")
        .replace("=", "")
        .replace("+", "")
        .replace("'", "")
        .replace("`", "")
        .replace(",", "")
        .replace(".", "")
        .toLowerCase();
};

import { saveAs } from "file-saver";
import { filterConnectorToBackEnd } from "components/filters/helper";
import {
    CommentsPayload,
    ExportCommentsPayload,
    UpdateCommentSettingsPayload,
    CommentsSummaryPayload,
    CommentSummaryResponse,
    getCommentPromptSettingsResponse,
    CommentSummaryPromptPayload,
    CommentSummaryExportPayload,
    CommentsConversationPayload,
    CommentConversationResponse,
    PostCommentConversationPayload,
    RegenerateCommentSummaryPayload
} from "./interfaces";
import { ReadCommentsResponse } from "managerPortal/components/comments/interfaces";
import ConfigService from "./Config.service";
import { CommentsService as CommentsServiceType } from "./interfaces";

export class CommentsService extends ConfigService implements CommentsServiceType {
    readComments(payload: CommentsPayload): Promise<ReadCommentsResponse> {
        const postUrl = this.makeUrl(`/Comments`);
        return this.post(postUrl, payload);
    }
    updateCommentSettings(payload: UpdateCommentSettingsPayload): Promise<void> {
        const postUrl = this.makeUrl(`/Comments/surveysettings`);
        return this.post(postUrl, payload);
    }
    exportSurveyComments(payload: ExportCommentsPayload): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/Comments");
        const params = {
            ...payload,
            filters: filterConnectorToBackEnd(payload.filters)
        };
        return this.getBlobFile(postUrl, true, params);
    }
    exportCommentExcelReport(payload: ExportCommentsPayload): Promise<void> {
        const postUrl = this.makeUrl("/fileexport/commentsexcel");
        const params = {
            ...payload,
            filters: filterConnectorToBackEnd(payload.filters)
        };
        return this.getBlobFile(postUrl, true, params)
            .then((blob: unknown) => saveAs(blob as Blob, "Comment.xlsx"))
            .catch(e => {
                throw e;
            });
    }
    getCommentSummary(payload: CommentsSummaryPayload): Promise<CommentSummaryResponse> {
        const postUrl = this.makeUrl(`/Comments/Summary`);
        return this.post(postUrl, payload);
    }
    getCommentPromptSettings(surveyId: number): Promise<getCommentPromptSettingsResponse> {
        return this.get(`/Comments/promptsettings?surveyId=${surveyId}`);
    }
    updateCommentPromptSettings(payload: CommentSummaryPromptPayload): Promise<void> {
        const postUrl = this.makeUrl(`/Comments/promptsettings`);
        return this.post(postUrl, payload);
    }
    exportCommentSummary(payload: CommentSummaryExportPayload): Promise<unknown> {
        const postUrl = this.makeUrl("/fileexport/commentsummary");
        return this.getBlobFile(postUrl, true, payload)
            .then((blob: unknown) => saveAs(blob as Blob, `${payload.reportTitle}.pptx`))
            .catch(e => {
                throw e;
            });
    }
    getCommentConversation(payload: CommentsConversationPayload): Promise<CommentConversationResponse> {
        const postUrl = this.makeUrl(`/Comments/Conversations`);
        return this.post(postUrl, payload);
    }
    postCommentConversation(payload: PostCommentConversationPayload): Promise<void> {
        const postUrl = this.makeUrl(`/Comments/postresponse`);
        return this.post(postUrl, payload);
    }
    resetCommentConversation(conversationId: number): Promise<void> {
        const postUrl = this.makeUrl("/Comments/deleteconversation");
        const params = {
            conversationId
        };
        return this.post(postUrl, params);
    }
    regenerateCommentSummary(payload: RegenerateCommentSummaryPayload): Promise<void> {
        const postUrl = this.makeUrl(`/Comments/RegenerateSummarySuggestion`);
        return this.post(postUrl, payload);
    }
}

import React, { ReactElement, useRef, useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import SelectExpandableList from "managerPortal/components/shared/SelectExpandableList";
import {
    getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit,
    surveySectionsPickOneWithIsFunAndItemId
} from "core/helpers";
import { useLang } from "core/hooks";
import { useKeyDriverState } from "./useKeyDriverState";
import { DialogLayout } from "components/shared";
import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { QuestionType } from "managerPortal/context/enums";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { Box as IBox, DashboardCellType, TitleTranslation } from "../interfaces";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { TextField } from "lib/text-field";
import { Select as CustomSelect, SelectChangeEvent as SelectChangeEventCustom } from "lib/select-custom";
import { Divider } from "lib/divider";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { FormControl } from "lib/form-control";
import { InputLabel } from "lib/input-label";
import { Select, SelectChangeEvent } from "lib/select";
import { MenuItem } from "lib/menu-item";
import { Checkbox } from "lib/checkbox";
import { Tooltip } from "lib/tooltip";
import { ListItemText } from "lib/list";
import { useUser } from "core/context/user/useUser";

const availableSizes = [
    { id: "4 x 2", width: 4, height: 2, name: "4 x 2" },
    { id: "5 x 2", width: 5, height: 2, name: "5 x 2" },
    { id: "6 x 3", width: 6, height: 3, name: "6 x 3" }
];

type Props = {
    boxes: IBox[];
    box?: IBox;
    selectedPanelId: string;
    surveySections: SurveySection[];
    onClose: () => void;
    updatePanelItem: (
        column: number,
        row: number,
        type: DashboardCellType,
        updateString: string,
        titleTranslations: TitleTranslation[]
    ) => void;
};

export const KeyDriver = ({
    onClose,
    surveySections,
    updatePanelItem,
    boxes,
    selectedPanelId,
    box
}: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();
    const singleDimensionOrItemListRef = useRef<HTMLDivElement>(null);
    const [showSurveyItemList, setShowSurveyItemList] = useState<boolean>(false);
    const {
        keyDriverSize,
        setKeyDriverSize,
        selectedKeyDriverSize,
        setKeyDriverSelectedSize,
        keyDriverSingleDimensionOrItem,
        handleSingleDimensionOrItemUpdate,
        excludedDimensions,
        handleExcludedDimensionsUpdate,
        titleTranslation,
        setTitleTranslation
    } = useKeyDriverState({
        initKeyDriverSize: availableSizes,
        initSingleDimensionOrItem: {
            isSection: false,
            measureId: -1,
            measureField: ""
        },
        initSelectedKeyDriverSize: "4 x 2",
        initTitleTranslation: box && box.translations
    });

    const onKeyDriverSelectedSize = (event: SelectChangeEventCustom<unknown>) => {
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            setKeyDriverSelectedSize(value as string);
        }
    };

    const surveySection = (): SurveySection[] => {
        return surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };

    const handleSurveyItemSelect = (id: string, level: number, title: string): void => {
        setShowSurveyItemList(false);
        if (level === 0) {
            handleSingleDimensionOrItemUpdate({
                ...keyDriverSingleDimensionOrItem,
                isSection: true,
                measureId: +id.slice(1),
                measureField: title
            });
        } else if (level === 1) {
            handleSingleDimensionOrItemUpdate({
                ...keyDriverSingleDimensionOrItem,
                isSection: false,
                measureId: +id.slice(1),
                measureField: title
            });
        }
    };

    const handleChangeSelect = (event: SelectChangeEvent<unknown>): void => {
        handleExcludedDimensionsUpdate(event.target.value as string[]);
    };

    const handleOpenSurveyItemList = (): void => {
        setShowSurveyItemList(!showSurveyItemList);
        setTimeout(() => {
            if (singleDimensionOrItemListRef && singleDimensionOrItemListRef.current) {
                singleDimensionOrItemListRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            }
        }, 100);
    };

    const handleListOnBlur = (): void => {
        if (showSurveyItemList) {
            setShowSurveyItemList(false);
        }
    };

    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "englishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "en" ? { languageCode: "en", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "frenchTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "fr" ? { languageCode: "fr", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "spanishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "es" ? { languageCode: "es", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
    };

    const getConfirmButtonDisableRule = (): boolean => {
        return keyDriverSingleDimensionOrItem.measureId === -1;
    };
    const getSingleDimensionOrItemWidth = (): number => {
        const sizeSelected = selectedKeyDriverSize.split("x")[0];
        return parseInt(sizeSelected.trim());
    };
    const getSingleDimensionOrItemHeight = (): number => {
        const sizeSelected = selectedKeyDriverSize.split("x")[1];
        return parseInt(sizeSelected.trim());
    };

    const getExcludedSectionIds = (): number[] => {
        const excluededSectionIds = [] as number[];
        surveySections.forEach(section => {
            if (excludedDimensions.includes(section.title)) {
                excluededSectionIds.push(+section.sectionId.slice(1));
            }
        });
        return excluededSectionIds;
    };

    const getExcludedDimensionText = (excludedSectionIds: number[]): string[] => {
        const excluededSectionTitle = [] as string[];
        excludedSectionIds.forEach(sectionId => {
            if (surveySections.findIndex(section => +section.sectionId.slice(1) === sectionId) > -1) {
                const sectionFound = surveySections.find(section => +section.sectionId.slice(1) === sectionId);
                if (sectionFound) {
                    excluededSectionTitle.push(sectionFound.title);
                }
            }
        });
        return excluededSectionTitle;
    };

    const confirmationClicked = (): void => {
        return updatePanelItem(
            getSingleDimensionOrItemWidth(),
            getSingleDimensionOrItemHeight(),
            "keyDrivers",
            JSON.stringify({
                id: keyDriverSingleDimensionOrItem.measureId,
                isSection: keyDriverSingleDimensionOrItem.isSection,
                excludedSectionIds: getExcludedSectionIds()
            }),
            titleTranslation
        );
    };
    const getDisabledSectionIds = (): SelectOptionExpandablePanel["id"][] => {
        const allItemsRemovedOverallScoreSectionIds = [] as SelectOptionExpandablePanel["id"][];
        const availableSurveySections = getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit(
            surveySection(),
            user
        );
        availableSurveySections.forEach(section => {
            const items = section.questions
                .filter(q => q.questionType === 0)
                .map(question => question.items)
                .reduce((a, b) => a.concat(b), []);
            if (items.every(item => item.isRemovedOverallScore === true)) {
                allItemsRemovedOverallScoreSectionIds.push(section.sectionId.slice(1));
            }
        });
        return allItemsRemovedOverallScoreSectionIds;
    };

    useEffect(() => {
        const selectedBox = boxes.find(box => box.id === selectedPanelId);
        if (selectedBox) {
            setKeyDriverSelectedSize(`${selectedBox.width} x ${selectedBox.height}`);
            setKeyDriverSize(
                availableSizes.filter(
                    item =>
                        item.width <= GRID_COL_LENGTH - selectedBox.left / boxSize &&
                        item.height <= GRID_ROW_LENGTH - selectedBox.top / boxSize
                )
            );
            if (selectedBox.props) {
                const properties = selectedBox.props;
                const excludedSectionIds = JSON.parse(properties).excludedSectionIds;
                if (excludedSectionIds && excludedSectionIds.length > 0) {
                    handleExcludedDimensionsUpdate(getExcludedDimensionText(excludedSectionIds));
                }
                const isSection = JSON.parse(properties).isSection;
                if (isSection) {
                    const sectionId = JSON.parse(properties).id;
                    const fieldName = surveySections.filter(section => +section.sectionId.slice(1) === sectionId)[0]
                        .title;
                    handleSingleDimensionOrItemUpdate({
                        ...keyDriverSingleDimensionOrItem,
                        isSection: true,
                        measureId: sectionId,
                        measureField: fieldName
                    });
                } else {
                    const itemId = JSON.parse(properties).id;
                    const fieldName = surveySections
                        .map(section => section.questions)
                        .reduce((a, b) => a.concat(b), [])
                        .map(question => question.items)
                        .reduce((a, b) => a.concat(b), [])
                        .filter(item => +item.itemId.slice(1) === itemId)[0].title;
                    handleSingleDimensionOrItemUpdate({
                        ...keyDriverSingleDimensionOrItem,
                        isSection: false,
                        measureId: itemId,
                        measureField: fieldName
                    });
                }
            }
        }
    }, [boxes, open]);

    const selectContainer = {
        display: "flex",
        flexDirection: "column",
        paddingTop: 2,
        marginLeft: 3
    };

    const selectContent = {
        display: "flex",
        textTransform: "none",
        width: 396,
        height: 53,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderBottom: "solid thin darkgrey",
        backgroundColor: "rgba(0,0,0,0.06)"
    };

    const typographySelect = {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        flexGrow: 1
    };

    const surveyDimensionText = {
        flexBasis: "90%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    };

    return (
        <DialogLayout
            title={lang.keyDrivers}
            onClose={onClose}
            open
            onClick={confirmationClicked}
            buttonLabelAction={lang.ok}
            suppressContentPadding
            disableActionButton={getConfirmButtonDisableRule()}
            hiddenOverflow
        >
            <Box p={2} pl={3} pr={3}>
                <CustomSelect
                    data-testid="select-keydriver-size"
                    items={keyDriverSize.map(k => {
                        return {
                            value: k.id,
                            name: k.name
                        };
                    })}
                    onChange={onKeyDriverSelectedSize}
                    label={lang.size}
                    value={selectedKeyDriverSize}
                />
            </Box>
            <Divider />
            <Box sx={selectContainer} tabIndex={0} onBlur={handleListOnBlur} ref={singleDimensionOrItemListRef}>
                <Box sx={selectContent} onClick={handleOpenSurveyItemList} data-testid={"dashboard-kd-btn-surveyItem"}>
                    <Box display="flex" alignItems="center" width="100%" pl={1.5} pr={1}>
                        <Typography data-testid={"dashboard-kd-surveyItem"} color="GrayText" sx={typographySelect}>
                            {keyDriverSingleDimensionOrItem.measureField
                                ? keyDriverSingleDimensionOrItem.measureField
                                : `${lang.surveyDimensionOrItem}`}
                        </Typography>
                        <ArrowDropDownIcon color="action" />
                    </Box>
                </Box>
                {showSurveyItemList && (
                    <Box pb={1} height={370} width={396} data-testid={"dashboar-kd-surveyItem-list"}>
                        <SelectExpandableList
                            rawData={surveySectionsPickOneWithIsFunAndItemId(
                                getAvailableSurveySectionsOnlyPickOneWithAnswerLengthLimit(surveySection(), user)
                            )}
                            disabledSectionIds={getDisabledSectionIds()}
                            onItemSelected={handleSurveyItemSelect}
                        />
                    </Box>
                )}
            </Box>
            <Box pt={2} pl={3} pr={3}>
                <FormControl variant="filled" sx={{ width: 396 }}>
                    <InputLabel id="mutiple-dimension-name">{lang.excludedDimensions}</InputLabel>
                    <Select
                        value={excludedDimensions}
                        labelId="mutiple-dimension-name"
                        onChange={handleChangeSelect}
                        renderValue={(selected): string => (selected as string[]).join(", ")}
                        multiple
                        MenuProps={{
                            sx: { maxHeight: 400, maxWidth: 396 },
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            }
                        }}
                    >
                        {surveySections.map((s: SurveySection) => (
                            <MenuItem key={s.sectionId} value={s.title}>
                                <Checkbox checked={excludedDimensions.indexOf(s.title) > -1} />
                                <Tooltip title={s.title} placement={"bottom"}>
                                    <ListItemText
                                        primary={s.title}
                                        primaryTypographyProps={{ sx: { surveyDimensionText } }}
                                    />
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box pl={3} pr={3} display="flex" flexDirection="column" mt={3} gap={2}>
                <Typography variant="body1">{lang.title}</Typography>
                <Box display="flex" alignItems="flex-end">
                    <Typography width={100} variant="body1">
                        {lang.english}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"keyDriver-englishTitle-TextField"}
                        name={"englishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "en")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="flex-end">
                    <Typography width={100} variant="body1">
                        {lang.spanish}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"keyDriver-spanishTitle-TextField"}
                        name={"spanishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "es")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="flex-end">
                    <Typography width={100} variant="body1">
                        {lang.french}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"keyDriver-frenchTitle-TextField"}
                        name={"frenchTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "fr")[0]?.name ?? ""}
                    />
                </Box>
            </Box>
        </DialogLayout>
    );
};

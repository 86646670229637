import React, { ReactElement, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { NavItemDisabled } from "../header/interfaces";
import { useLinkButtonStyles } from "./styles";
import { Typography } from "lib/typography";
import { textDarkBackgroundDisabled, textDarkBackgroundMain, textDarkBackgroundNotActive } from "lib/palette";

type Props = {
    linkTo: string;
    icon?: ReactElement;
    label?: string;
    align: "right" | "left";
    disabled?: NavItemDisabled;
    padding?: number;
};

const LinkButton: React.FC<Props> = props => {
    const disabled = props.disabled?.isDisabled || false;
    const classes = useLinkButtonStyles({
        alignTo: props.align,
        disabled,
        padding: props.padding !== undefined ? 0 : 5
    });

    const match = useRouteMatch({
        path: props.linkTo
    });

    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div
            className={classes.link}
            ref={buttonRef}
            aria-owns={open ? "popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-testid={
                props.disabled?.isDisabled
                    ? "disabled-header-" + props.label?.toLowerCase()
                    : "enabled-header-" + props.label?.toLowerCase()
            }
        >
            <Link to={props.disabled?.isDisabled ? "#" : props.linkTo} className={classes.link}>
                {props.icon && !props.label ? (
                    <div className={classes.icon}>{props.icon}</div>
                ) : (
                    <Typography
                        sx={
                            match
                                ? { color: textDarkBackgroundMain, fontWeight: "bold" }
                                : {
                                      color: disabled ? textDarkBackgroundDisabled : textDarkBackgroundNotActive
                                  }
                        }
                        variant="body2"
                    >
                        {props.label}
                    </Typography>
                )}
            </Link>
            {props.disabled?.isDisabled && props.disabled.message ? (
                <Popover
                    id="popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography className={classes.popoverText}>{props.disabled?.message}</Typography>
                </Popover>
            ) : null}
        </div>
    );
};

export default LinkButton;

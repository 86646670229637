import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../components/errorPage/Error404";
import { IRouteProps } from "./interfaces/Routes.interface";
import { useGoogleAnalytics } from "core/hooks";
import initHelpHero from "helphero";
import { useUser } from "core/context/user/useUser";

type Props = {
    routes: IRouteProps[];
};

const RouteRender: React.FC<Props> = ({ routes }) => {
    const { user } = useUser();
    useGoogleAnalytics();

    const onboardingEnabled = user.settings && user.settings.startTour;

    const hlp = initHelpHero(`${process.env.REACT_APP_HELPHERO_APP_ID}`);
    if (onboardingEnabled && user.email) {
        hlp.identify(user.email, {
            is_Restricted: user.isRestricted
        });
    } else {
        hlp.cancelTour();
    }

    return (
        <Switch>
            {routes.map((route: IRouteProps) => {
                return (
                    route.isAccessGranted && (
                        <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
                    )
                );
            })}
            <Route exact path="*">
                <Error404 />
            </Route>
        </Switch>
    );
};

export default RouteRender;

import { CommentState } from "../interface";
import { getSelectedComment } from "./get-selected-comment";

export const getItemsId = (
    selectedCommentId: CommentState["selectedCommentId"],
    surveyItemsOptions: CommentState["surveyItemsOptions"],
    selectedCommentQuestionId: CommentState["selectedCommentQuestionId"]
): string[] => {
    let itemIds: string[] = [];
    if (selectedCommentId && !selectedCommentQuestionId) {
        if (selectedCommentId === "AllComments") {
            itemIds = surveyItemsOptions
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .map(q => q.items.map(i => i.itemId.replace("I", "")))
                .reduce((a, b) => a.concat(b));
        } else {
            const selectedComment = surveyItemsOptions.find(x => x.sectionId === selectedCommentId);
            if (selectedComment) {
                itemIds = selectedComment.questions
                    .map(q => q.items.map(i => i.itemId.replace("I", "")))
                    .reduce((a, b) => a.concat(b));
            }
        }
    } else if (selectedCommentQuestionId && !selectedCommentId) {
        const selectedComment = getSelectedComment(surveyItemsOptions, selectedCommentQuestionId);
        if (selectedComment) {
            itemIds = selectedComment.items.map(i => i.itemId.replace("I", ""));
        }
    }
    return itemIds;
};

export const getItemsIdInNumber = (
    selectedCommentId: CommentState["selectedCommentId"],
    surveyItemsOptions: CommentState["surveyItemsOptions"],
    selectedCommentQuestionId: CommentState["selectedCommentQuestionId"]
): number[] => {
    let itemIds: number[] = [];
    if (selectedCommentId && !selectedCommentQuestionId) {
        if (selectedCommentId === "AllComments") {
            itemIds = surveyItemsOptions
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .map(q => q.items.map(i => parseInt(i.itemId.replace("I", ""))))
                .reduce((a, b) => a.concat(b));
        } else {
            const selectedComment = surveyItemsOptions.find(x => x.sectionId === selectedCommentId);
            if (selectedComment) {
                itemIds = selectedComment.questions
                    .map(q => q.items.map(i => parseInt(i.itemId.replace("I", ""))))
                    .reduce((a, b) => a.concat(b));
            }
        }
    } else if (selectedCommentQuestionId && !selectedCommentId) {
        const selectedComment = getSelectedComment(surveyItemsOptions, selectedCommentQuestionId);
        if (selectedComment) {
            itemIds = selectedComment.items.map(i => parseInt(i.itemId.replace("I", "")));
        }
    }
    return itemIds;
};

import React, { ReactElement, useEffect } from "react";

import { useLang } from "core/hooks";
import DropArea from "components/shared/DropArea";
import { Box as Boxtype } from "components/admin/results/dashboard/interfaces";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { Typography } from "lib/typography";
import { Box } from "lib/box";

type Props = {
    selectedPanelId: string;
    boxes: Boxtype[];
    imageColumn: number[];
    imageRow: number[];
    selectedImageColumn: number;
    selectedImageRow: number;
    fileTypeMessage: string;
    dropFile: (files: File[]) => Promise<void>;
    rejectFile: () => void;
    imageColumnSelected: (event: SelectChangeEvent<unknown>) => void;
    imageRowSelected: (event: SelectChangeEvent<unknown>) => void;
    setSelectedImageSize: (column: number, row: number) => void;
};

const ImageCellType = (props: Props): ReactElement => {
    const { lang } = useLang();

    useEffect(() => {
        const boxFound = props.boxes.find(box => box.id === props.selectedPanelId);
        if (boxFound) {
            props.setSelectedImageSize(boxFound.width, boxFound.height);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={3} p={3}>
            <Box display="flex" gap={2}>
                <Select
                    label={lang.column}
                    value={props.selectedImageColumn}
                    onChange={props.imageColumnSelected}
                    data-testid="image-column"
                    items={props.imageColumn.map((column: number) => {
                        return {
                            value: column,
                            name: column.toString()
                        };
                    })}
                />
                <Select
                    label={lang.row}
                    value={props.selectedImageRow}
                    onChange={props.imageRowSelected}
                    data-testid="image-row"
                    items={props.imageRow.map((row: number) => {
                        return {
                            value: row,
                            name: row.toString()
                        };
                    })}
                />
            </Box>
            <Box>
                <Typography>{lang.addPNGImage}</Typography>
                <DropArea
                    fileDrop={props.dropFile}
                    dataDropKey={"dashboard-editor-image"}
                    acceptFiles={"image/png"}
                    helperText={props.fileTypeMessage}
                    width={300}
                    onRejectFiles={props.rejectFile}
                />
            </Box>
        </Box>
    );
};

export default ImageCellType;

import React, { ReactElement } from "react";
import { useLang } from "core/hooks";
import { SurveySection } from "managerPortal/context/interfaces";
import type { DimensionScoreSort, SettingsState } from "./types";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Checkbox } from "lib/checkbox";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";
import { FormControl } from "lib/form-control";
import { InputLabel } from "lib/input-label";
import { Select, SelectChangeEvent } from "lib/select";
import { FilledInput } from "lib/filled-input";
import { MenuItem } from "lib/menu-item";

type Props = {
    minWordValidationError: SettingsState["minWordValidationError"];
    setMinWordValidationError: SettingsState["setMinWordValidationError"];
    preSelection: SettingsState["preSelection"];
    setPreSelection: SettingsState["setPreSelection"];
    sampleWordValidationError: SettingsState["sampleWordValidationError"];
    setSampleWordValidationError: SettingsState["setSampleWordValidationError"];
    sampleWordsChecked: SettingsState["sampleWordsChecked"];
    setSampleWordsChecked: SettingsState["setSampleWordsChecked"];
    autoTranslateChecked: SettingsState["autoTranslateChecked"];
    setAutoTranslateChecked: SettingsState["setAutoTranslateChecked"];
    dimensionScoreChecked: SettingsState["dimensionScoreChecked"];
    setDimensionScoreChecked: SettingsState["setDimensionScoreChecked"];
    sentimentScoreChecked: SettingsState["sentimentScoreChecked"];
    setSentimentScoreChecked: SettingsState["setSentimentScoreChecked"];
    surveySections: SettingsState["surveySections"];
};

export const Preferences = (props: Props): ReactElement => {
    const { lang } = useLang();
    const {
        minWordValidationError,
        setMinWordValidationError,
        preSelection,
        setPreSelection,
        sampleWordValidationError,
        setSampleWordValidationError,
        sampleWordsChecked,
        setSampleWordsChecked,
        autoTranslateChecked,
        setAutoTranslateChecked,
        dimensionScoreChecked,
        setDimensionScoreChecked,
        sentimentScoreChecked,
        setSentimentScoreChecked,
        surveySections
    } = props;

    const dimensionScoreSort: DimensionScoreSort[] = [
        { order: 0, title: lang.noneCapitalize },
        { order: 1, title: lang.dimensionScoreHighToLow },
        { order: 2, title: lang.dimensionScoreLowToHigh },
        { order: 3, title: lang.sentimentScoreHighToLow },
        { order: 4, title: lang.sentimentScoreLowToHigh }
    ];

    const validationMinWords = (fieldValue: number, targetName: string): boolean => {
        if (fieldValue <= 0 && Number.isInteger(fieldValue) && targetName === "minWords") {
            setMinWordValidationError({
                key: "minWords",
                message: lang.minmumTopWordNumber
            });
            return false;
        } else {
            setMinWordValidationError({ key: "", message: "" });
            return true;
        }
    };
    const validationSampleWords = (fieldValue: number, targetName: string): boolean => {
        if (fieldValue <= 0 && Number.isInteger(fieldValue) && targetName === "sampleWords") {
            setSampleWordValidationError({
                key: "sampleWords",
                message: lang.minmumTopWordNumber
            });
            return false;
        } else {
            setSampleWordValidationError({ key: "", message: "" });
            return true;
        }
    };
    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newNumber = +event.target.value;
        if (isNaN(newNumber)) return;
        if (event.target.name === "minWords") {
            setPreSelection({ ...preSelection, minWords: newNumber });
            validationMinWords(newNumber, event.target.name);
        }
        if (event.target.name === "sampleWords") {
            setPreSelection({ ...preSelection, sampleWords: newNumber });
            validationSampleWords(newNumber, event.target.name);
        }
    };

    const handleSampleWordsCheckboxChange = (checked: boolean): void => {
        setSampleWordsChecked(checked);
    };

    const handleDimensionScoreCheckboxChange = (checked: boolean): void => {
        setDimensionScoreChecked(checked);
        if (
            !checked &&
            (preSelection.selectedDimensionScoreSort === 1 || preSelection.selectedDimensionScoreSort === 2)
        ) {
            setPreSelection({
                ...preSelection,
                selectedDimensionScoreSort: 0
            });
        }
    };
    const handleSentimentScoreCheckboxChange = (checked: boolean): void => {
        setSentimentScoreChecked(checked);
        if (
            !checked &&
            (preSelection.selectedDimensionScoreSort === 3 || preSelection.selectedDimensionScoreSort === 4)
        ) {
            setPreSelection({
                ...preSelection,
                selectedDimensionScoreSort: 0
            });
        }
    };
    const handleSurveySectionSelected = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as string;
        setPreSelection({
            ...preSelection,
            selectedSectionId: +elementValue
        });
    };
    const handleDimensionScoreSortSelected = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as number;
        setPreSelection({
            ...preSelection,
            selectedDimensionScoreSort: +elementValue
        });
    };

    const getDisableRule = (sort: DimensionScoreSort): boolean => {
        if ((sort.order === 1 || sort.order === 2) && !dimensionScoreChecked) return true;
        if ((sort.order === 3 || sort.order === 4) && !sentimentScoreChecked) return true;
        return false;
    };

    return (
        <Box width={370} minWidth={370}>
            <Box height={50} p={2}>
                <Typography variant="body2" fontWeight="500">
                    {lang.preferences}
                </Typography>
            </Box>
            <Divider />
            <Box p={2} display="flex" flexDirection="column" gap={"16px"}>
                <Box component="form" display="flex" alignItems="center">
                    <Typography alignSelf="flex-end">{lang.showCommentMinWordsPrefix}</Typography>
                    <Box width={100} ml={1} mr={1}>
                        <TextField
                            onChange={handleChangeField}
                            data-testid="text-field-comment-min-words"
                            label={lang.number}
                            name={"minWords"}
                            value={preSelection.minWords}
                            error={minWordValidationError.key === "minWords"}
                            helperText={minWordValidationError.key === "minWords" ? minWordValidationError.message : ""}
                        />
                    </Box>
                    <Typography alignSelf="flex-end">{lang.showCommentMinWordsSuffix}</Typography>
                </Box>
                <Box>
                    <Checkbox
                        data-testid="comment-settings-dimension-score-checkbox"
                        value={"dimensionScore"}
                        checked={dimensionScoreChecked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleDimensionScoreCheckboxChange(event.target.checked)
                        }
                        label={lang.showDimensionScore}
                    />
                    <FormControl variant="filled" sx={{ marginLeft: 3, width: 300, minWidth: 300 }}>
                        <InputLabel shrink={true} id="dimensionScore">
                            {lang.dimension}
                        </InputLabel>
                        <Select
                            value={preSelection.selectedSectionId}
                            onChange={handleSurveySectionSelected}
                            sx={{ height: 52 }}
                            label={lang.dimension}
                            labelId="dimensionScore"
                            input={<FilledInput />}
                            color="primary"
                            data-testid={"select-section"}
                        >
                            {surveySections.map((section: SurveySection) => (
                                <MenuItem key={section.sectionId} value={+section.sectionId.slice(1)}>
                                    {section.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <Checkbox
                        data-testid="comment-settings-sentiment-score-checkbox"
                        value={"sentimentScore"}
                        checked={sentimentScoreChecked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleSentimentScoreCheckboxChange(event.target.checked)
                        }
                        label={lang.showSentimentScore}
                    />
                    <FormControl variant="filled" sx={{ marginLeft: 3, width: 300, minWidth: 300 }}>
                        <InputLabel shrink={true} id="sortOrder">
                            {lang.sort}
                        </InputLabel>
                        <Select
                            value={preSelection.selectedDimensionScoreSort}
                            onChange={handleDimensionScoreSortSelected}
                            sx={{ height: 52 }}
                            labelId="sortOrder"
                            label={lang.sort}
                            input={<FilledInput />}
                            color="primary"
                            data-testid={"select-sort-order"}
                        >
                            {dimensionScoreSort.map((sort: DimensionScoreSort) => (
                                <MenuItem key={sort.order} value={sort.order} disabled={getDisableRule(sort)}>
                                    {sort.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box display="flex">
                    <Checkbox
                        data-testid="comment-settings-random-sample-checkbox"
                        value={"randomSampleWords"}
                        checked={sampleWordsChecked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleSampleWordsCheckboxChange(event.target.checked)
                        }
                        label={`${lang.showCommentRandomSamplePrefix} ${lang.showCommentRandomSampleSuffix}`}
                    />
                    <TextField
                        onChange={handleChangeField}
                        data-testid="text-field-comment-sample-words"
                        label={lang.number}
                        name={"sampleWords"}
                        value={preSelection.sampleWords}
                        error={sampleWordValidationError.key === "sampleWords"}
                        sx={{ width: "140px", marginLeft: -1.4 }}
                        helperText={
                            sampleWordValidationError.key === "sampleWords" ? sampleWordValidationError.message : ""
                        }
                    />
                </Box>
                <Checkbox
                    data-testid="comment-settings-auto-translate"
                    value={"autoTranslate"}
                    checked={autoTranslateChecked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        setAutoTranslateChecked(event.target.checked)
                    }
                    label={lang.autoTranslate}
                />
            </Box>
        </Box>
    );
};

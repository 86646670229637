import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { blueGrey } from "@mui/material/colors";
import { Help } from "@mui/icons-material";

import { unfavorableColor, neutralColor, favorableColor, emptyBarColor } from "../../styles/GlobalStyles";
import TruncateDisplay from "../shared/TruncateDisplay";
import { HIDE_ELEMENTS } from "../../enums/Enums";
import { CardKeyDriver } from "../../interfaces/Interfaces";
import { useLang } from "../../../core/hooks";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    keyDriverList: {
        marginBottom: 5
    },
    driverDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    driverTitle: {
        marginLeft: 30,
        flex: "1 100%",
        color: blueGrey[800]
    },
    driverValue: {
        color: blueGrey[800]
    },
    circle: {
        minWidth: 14,
        height: 14,
        borderRadius: 7,
        marginLeft: 4
    },
    tooltipTextContainer: {
        marginBottom: 0,
        marginTop: 0,
        paddingBottom: 0
    },
    keyDriverListTitle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        maxHeight: 24
    },
    iconButtonPaddingOverride: {
        padding: 4
    },
    iconStyle: {
        fontSize: 20
    },
    noDisplayKeyDriver: {
        marginBottom: theme.spacing(2),
        height: 51
    }
}));

interface IPropsDriverText {
    driver: CardKeyDriver;
    responseCount: number;
}

interface IPropsDriverList {
    keyDrivers: CardKeyDriver[];
    responseCount: number;
}

const KeyDriverText = (props: IPropsDriverText): ReactElement => {
    const classes = useStyles();
    const { driver, responseCount } = props;
    const circleColorStyle = {
        background:
            responseCount < HIDE_ELEMENTS.correlationLessThan
                ? emptyBarColor
                : driver.correlation > 0.75
                  ? favorableColor
                  : driver.correlation > 0.5
                    ? neutralColor
                    : unfavorableColor
    };

    return (
        <Box display="flex" justifyContent="space-between">
            <TruncateDisplay title={driver.title} maxLabelLength={20} placement="bottom-end">
                <Typography variant="caption" key={driver.sectionId} className={classes.driverTitle}>
                    {driver.title}
                </Typography>
            </TruncateDisplay>
            <Box display="flex" alignItems="center">
                {responseCount >= HIDE_ELEMENTS.correlationLessThan && (
                    <Typography variant="caption" className={classes.driverValue}>
                        {driver.correlation.toPrecision(2)}
                    </Typography>
                )}
                <div style={circleColorStyle} className={classes.circle} />
            </Box>
        </Box>
    );
};

const KeyDriverList = (props: IPropsDriverList): ReactElement => {
    const { keyDrivers, responseCount } = props;
    const classes = useStyles();
    const { lang } = useLang();

    return (
        <div className={classes.keyDriverList} data-testid="card-key-driver-list">
            <div className={classes.keyDriverListTitle}>
                <Typography variant="body1">{lang.keyDrivers}</Typography>
                <Tooltip title={lang.keyDriverHelp} placement="bottom">
                    <IconButton aria-label={lang.help} size="small">
                        <Help />
                    </IconButton>
                </Tooltip>
            </div>
            {responseCount < HIDE_ELEMENTS.correlationLessThan ? (
                <Typography className={classes.noDisplayKeyDriver}>{lang.keyDriversNoDisplay}</Typography>
            ) : (
                keyDrivers.map((driver: CardKeyDriver) => (
                    <KeyDriverText key={driver.sectionId} driver={driver} responseCount={responseCount} />
                ))
            )}
        </div>
    );
};

export default KeyDriverList;

import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";

const ShowTooSimilarConfidentialityMessage = (): ReactElement => {
    const { lang } = useLang();

    return (
        <>
            <Typography variant="h6" fontWeight="medium" mt={3} ml={4}>
                {lang.confidentialityWarning}
            </Typography>
            {lang.disallowedFilterParagraphs.map((text: string, index: number) => (
                <Typography key={index} variant="body2" mt={1.5} ml={3} mr={3}>
                    {text}
                </Typography>
            ))}
        </>
    );
};

export default ShowTooSimilarConfidentialityMessage;

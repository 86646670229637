import React, { createContext, useState, useEffect, PropsWithChildren, ReactElement } from "react";
import { SurveyInfo } from "../../components/admin/results/interfaces";
import { useMutation } from "@apollo/client";

import { useOrgChartService, useSelectSurvey, useOrgUsers, useLang, useSurveys } from "core/hooks";
import { getSelectedHierarchy } from "core/helpers";
import {
    ManagerPortalState,
    InitialState,
    AssignableUser,
    SurveySection,
    SurveyQuestion,
    SurveyItem,
    VisibleNodes
} from "./interfaces";
import { emptyVisibleNodes } from "./initialState";
import { emptyHierarchy } from "../components/hierarchy/initialVariables";
import { emptySurveyInfo } from "components/admin/results/init";
import { userSettingMutation } from "api/mutations";
import { SelectedSurveyStoredData, UserInfo } from "components/admin/users/interface";
import { UserInput } from "components/admin/users/interface";
import { emptyBenchmark, emptySurveySettings } from "../interfaces/InitialStates";
import { Hierarchy } from "managerPortal/components/hierarchy/interfaces";
import { Bar, Numeric } from "managerPortal/interfaces";
import { CardLayout } from "managerPortal/components/cards/interfaces";
import { useUser } from "core/context/user/useUser";

type Context = {
    managerPortalState: ManagerPortalState;
    handleSetState: (newValue: Partial<ManagerPortalState>) => void;
};

const initialState = (initParams?: { surveySelected: SurveyInfo }): ManagerPortalState => {
    return {
        availableCardContent: [],
        surveySections: [],
        existingHierarchies: [],
        hrisFields: [],
        commentFields: [],
        benchmarks: [],
        mappedBenchmark: emptyBenchmark,
        benchmarkMappings: [],
        benchmarkSections: [],
        rootCardId: 1,
        surveySelected: initParams?.surveySelected ? initParams.surveySelected : emptySurveyInfo,
        visibleNodes: emptyVisibleNodes,
        selectedHierarchy: emptyHierarchy,
        errorLoadingHierarchy: false,
        highlightUnassignedCard: false,
        assignableUsers: [],
        isLoading: false,
        surveySettings: emptySurveySettings
    };
};

const ManagerPortalContext = createContext<Context>({
    managerPortalState: initialState(),
    handleSetState: () => null
});

const mapInitialData = (data: InitialState): Partial<ManagerPortalState> => {
    const mappedData = {
        availableCardContent: data.availableCardContent,
        surveySections: data.surveySections,
        existingHierarchies: data.existingHierarchies,
        hrisFields: data.hrisFields,
        rootCardId: data.rootCardId,
        visibleNodes: data.visibleNodes.id ? data.visibleNodes : emptyVisibleNodes,
        commentFields: data.commentFields,
        benchmarks: data.benchmarks,
        mappedBenchmark: data.mappedBenchmark ? data.mappedBenchmark : emptyBenchmark,
        benchmarkMappings: data.benchmarkMappings,
        benchmarkSections: data.benchmarkSections,
        surveySettings: data.initialSurveySettings
    };

    return mappedData;
};

const filterLayoutItem = (
    _selectedHierarchy: Hierarchy,
    surveySections: SurveySection[],
    visibleTree: VisibleNodes["tree"],
    _user: UserInfo,
    key: keyof CardLayout
): Bar[] | Numeric[] => {
    return _selectedHierarchy.Layout[key].filter((layoutItem: Bar | Numeric) => {
        if (layoutItem.id === "XKeyDrivers") {
            return !!visibleTree["key_drivers"];
        } else if (layoutItem.id === "XResponseRate") {
            return !!visibleTree["XNResponseRate"];
        } else if (layoutItem.id.includes("S")) {
            return surveySections
                .find((section: SurveySection) => section.sectionId === layoutItem.id)
                ?.questions.some(
                    (question: SurveyQuestion) =>
                        question.isFun &&
                        question.items.some((item: SurveyItem) => {
                            const restricted = _user.isRestricted && item.isHiddenForManagers;
                            return !item.isIgnored && !restricted;
                        })
                );
        } else {
            let removeItem = false;
            surveySections.forEach((section: SurveySection) => {
                section.questions.forEach((question: SurveyQuestion) => {
                    question.items.forEach((item: SurveyItem) => {
                        const restricted = _user.isRestricted && item.isHiddenForManagers;
                        if (item.itemId === layoutItem.id) {
                            removeItem = !item.isIgnored && !restricted;
                        }
                    });
                });
            });
            return removeItem;
        }
    });
};

const carLayoutValidation = (
    _selectedHierarchy: Hierarchy,
    surveySections: SurveySection[],
    visibleTree: VisibleNodes["tree"],
    _user: UserInfo
): Hierarchy => {
    _selectedHierarchy = {
        ..._selectedHierarchy,
        Layout: {
            bars: filterLayoutItem(_selectedHierarchy, surveySections, visibleTree, _user, "bars"),
            numerics: filterLayoutItem(_selectedHierarchy, surveySections, visibleTree, _user, "numerics")
        }
    };
    return _selectedHierarchy;
};

const ManagerPortalProvider = ({ children }: PropsWithChildren): ReactElement => {
    const orgChartService = useOrgChartService();
    const { user, setUser } = useUser();
    const { languageCode } = useLang();
    const { surveys } = useSurveys();
    const { selectedSurvey } = useSelectSurvey();
    const { orgUsers } = useOrgUsers();
    const [isLanguageChanged, setIsLanguageChanged] = useState<boolean>(false);
    const [updateUserSetting] = useMutation(userSettingMutation);

    const [state, setState] = useState(initialState({ surveySelected: emptySurveyInfo }));
    const handleSetState = (newValue: Partial<ManagerPortalState>): void => {
        setState((prevState: ManagerPortalState) => {
            return { ...prevState, ...newValue };
        });
    };

    useEffect(() => {
        const assignableUsers: AssignableUser[] = [];
        orgUsers.forEach((user: UserInput) => {
            if (user.isRestricted) {
                const assignUser = {
                    displayName: user.name,
                    userId: user.id,
                    userName: user.name
                };
                assignableUsers.push(assignUser);
            }
        });

        setState((prevState: ManagerPortalState) => {
            return { ...prevState, assignableUsers };
        });
    }, [orgUsers]);

    useEffect(() => {
        if (
            selectedSurvey &&
            state.surveySelected &&
            state.surveySelected.id !== emptySurveyInfo.id &&
            user.settings.snapshot.id === state.surveySelected.id
        ) {
            setState((prevState: ManagerPortalState): ManagerPortalState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });
            orgChartService
                .initialState(state.surveySelected.id, user.settings.languageCode)
                .then((_initialState: InitialState) => {
                    const mappedData = mapInitialData(_initialState);

                    const selectedHierarchy = getSelectedHierarchy(
                        _initialState.selectedHierarchyId,
                        _initialState.existingHierarchies
                    );

                    setState((prevState: ManagerPortalState): ManagerPortalState => {
                        return {
                            ...prevState,
                            ...mappedData,
                            errorLoadingHierarchy: false,
                            isLoading: false,
                            selectedHierarchy: carLayoutValidation(
                                selectedHierarchy,
                                _initialState.surveySections,
                                _initialState.visibleNodes.tree,
                                user
                            )
                        };
                    });
                })
                .catch(() => {
                    setIsLanguageChanged(false);
                    setState((): ManagerPortalState => {
                        return {
                            ...initialState(),
                            errorLoadingHierarchy: true
                        };
                    });
                });
        }
    }, [languageCode]);

    useEffect(() => {
        if (user.settings.snapshot.id !== emptySurveyInfo.id && user.settings.snapshot.id !== state.surveySelected.id) {
            // user select survey from other report page
            setState((prevState: ManagerPortalState): ManagerPortalState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });
            orgChartService
                .initialState(user.settings.snapshot.id, user.settings.languageCode)
                .then((_initialState: InitialState) => {
                    const mappedData = mapInitialData(_initialState);

                    const selectedHierarchy = getSelectedHierarchy(
                        _initialState.selectedHierarchyId,
                        _initialState.existingHierarchies
                    );
                    const surveyFound = surveys.find((survey: SurveyInfo) => survey.id === user.settings.snapshot.id);
                    setState((prevState: ManagerPortalState): ManagerPortalState => {
                        return {
                            ...prevState,
                            ...mappedData,
                            surveySelected: surveyFound ? surveyFound : emptySurveyInfo,
                            errorLoadingHierarchy: false,
                            isLoading: false,
                            selectedHierarchy: carLayoutValidation(
                                selectedHierarchy,
                                _initialState.surveySections,
                                _initialState.visibleNodes.tree,
                                user
                            )
                        };
                    });

                    const updateSelectedSurvey = user.settings.selectedSurvey.filter(
                        (_survey: SelectedSurveyStoredData) => _survey.orgId !== user.settings.selectedOrganization.id
                    );
                    updateSelectedSurvey.push({
                        id: user.settings.snapshot.id,
                        orgId: user.settings.selectedOrganization.id
                    });
                    const settings = {
                        ...user.settings,
                        selectedSurvey: updateSelectedSurvey
                    };
                    const mutationOptions = {
                        variables: { settings: JSON.stringify(settings) }
                    };
                    updateUserSetting(mutationOptions);
                    setUser({ settings });
                    setIsLanguageChanged(false);
                })
                .catch(() => {
                    setIsLanguageChanged(false);
                    setState((): ManagerPortalState => {
                        return {
                            ...initialState(),
                            errorLoadingHierarchy: true
                        };
                    });
                });
        } else if (
            user.settings.snapshot.id !== emptySurveyInfo.id &&
            state.surveySelected.id !== emptySurveyInfo.id &&
            (state.surveySelected.id !== selectedSurvey.id || state.errorLoadingHierarchy || isLanguageChanged)
        ) {
            // user select survey from org Chart page
            setState((prevState: ManagerPortalState): ManagerPortalState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });
            orgChartService
                .initialState(state.surveySelected.id, user.settings.languageCode)
                .then((_initialState: InitialState) => {
                    const mappedData = mapInitialData(_initialState);

                    const selectedHierarchy = getSelectedHierarchy(
                        _initialState.selectedHierarchyId,
                        _initialState.existingHierarchies
                    );

                    setState((prevState: ManagerPortalState): ManagerPortalState => {
                        return {
                            ...prevState,
                            ...mappedData,
                            errorLoadingHierarchy: false,
                            isLoading: false,
                            selectedHierarchy: carLayoutValidation(
                                selectedHierarchy,
                                _initialState.surveySections,
                                _initialState.visibleNodes.tree,
                                user
                            )
                        };
                    });

                    const updateSelectedSurvey = user.settings.selectedSurvey.filter(
                        (_survey: SelectedSurveyStoredData) => _survey.orgId !== user.settings.selectedOrganization.id
                    );
                    updateSelectedSurvey.push({
                        id: state.surveySelected.id,
                        orgId: user.settings.selectedOrganization.id
                    });
                    const settings = {
                        ...user.settings,
                        selectedSurvey: updateSelectedSurvey
                    };
                    const mutationOptions = {
                        variables: { settings: JSON.stringify(settings) }
                    };
                    updateUserSetting(mutationOptions);
                    setUser({ settings });
                    setIsLanguageChanged(false);
                })
                .catch(() => {
                    setIsLanguageChanged(false);
                    setState((): ManagerPortalState => {
                        return {
                            ...initialState(),
                            errorLoadingHierarchy: true
                        };
                    });
                });
        } else if (user.settings.snapshot.id === emptySurveyInfo.id && state.surveySelected.id === emptySurveyInfo.id) {
            setState({ ...initialState() });
            return;
        }
    }, [state.surveySelected.id, isLanguageChanged]);

    React.useLayoutEffect(() => {
        if (user.settings.snapshot.id === emptySurveyInfo.id && state.surveySelected.id === emptySurveyInfo.id) return;
        if (user.settings.snapshot.id !== state.surveySelected.id) return;
        setState((prevState: ManagerPortalState): ManagerPortalState => {
            return {
                ...prevState,
                isLoading: true
            };
        });
        orgChartService
            .initialState(state.surveySelected.id, user.settings.languageCode)
            .then((_initialState: InitialState) => {
                if (_initialState) {
                    const mappedData = mapInitialData(_initialState);
                    const selectedHierarchy = getSelectedHierarchy(
                        _initialState.selectedHierarchyId,
                        _initialState.existingHierarchies
                    );
                    setState((prevState: ManagerPortalState): ManagerPortalState => {
                        return {
                            ...prevState,
                            ...mappedData,
                            selectedHierarchy: carLayoutValidation(
                                selectedHierarchy,
                                _initialState.surveySections,
                                _initialState.visibleNodes.tree,
                                user
                            ),
                            errorLoadingHierarchy: false,
                            isLoading: false
                        };
                    });
                }
            })
            .catch(() => {
                setState((): ManagerPortalState => {
                    return {
                        ...initialState(),
                        errorLoadingHierarchy: true
                    };
                });
            });
        return (): void => {
            setIsLanguageChanged(false);
            setState({ ...initialState() });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ManagerPortalContext.Provider
            value={{
                managerPortalState: state,
                handleSetState: handleSetState
            }}
        >
            {children}
        </ManagerPortalContext.Provider>
    );
};

export { ManagerPortalContext, ManagerPortalProvider };

import { HeatmapValue } from "../interface";

export const heatmapColorRangeMapper = (values: HeatmapValue[]): { max: number; min: number } => {
    let max = 0;
    let min = 0;
    if (!values) {
        return { max: 0, min: 0 };
    }

    values.forEach(v => {
        if (v.delta > max) max = v.delta;
        else if (v.delta < min) min = v.delta;
    });

    return { max, min };
};

export const heatmapCellClassMapper = (value: number | string): string => {
    let className = "cell_neu";

    if (value === "-" || typeof value === "string") return className;

    //get how many color range hm should have
    if (value > -1 && value < 1) {
        className = "cell_neu";
    } else if (value >= 1 && value <= 7) {
        className = "cell_fav_25";
    } else if (value >= 8 && value <= 14) {
        className = "cell_fav_50";
    } else if (value >= 15 && value <= 21) {
        className = "cell_fav_75";
    } else if (value > 21) {
        className = "cell_fav";
    } else if (value <= -1 && value >= -7) {
        className = "cell_unfav_25";
    } else if (value <= -8 && value >= -14) {
        className = "cell_unfav_50";
    } else if (value <= -15 && value >= -21) {
        className = "cell_unfav_75";
    } else {
        className = "cell_unfav";
    }
    return className;
};

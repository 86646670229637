import type { CSSProperties } from "react";
import React, { ReactElement } from "react";
import { useDrop } from "react-dnd";
import { DraggableBox } from "./DraggableBox";
import type { DragItem, Box } from "./interfaces";
import { ItemTypes } from "./ItemTypes";
import { SnapToGrid as doSnapToGrid } from "./SnapToGrid";
import { boxSize } from "./Dashboard";

const styles: CSSProperties = {
    width: "100%",
    height: "100%",
    position: "relative"
};

type DashboardContainerProps = {
    boxes: Box[];
    moveBox: (id: string, left: number, top: number) => void;
    deletePanelItem: (id: string) => void;
    panelItemSelect: (id: string) => void;
};

export const DashboardContainer = (props: DashboardContainerProps): ReactElement => {
    const { boxes, moveBox } = props;
    const [, drop] = useDrop({
        accept: ItemTypes.BOX,
        drop(item: DragItem, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset() as {
                x: number;
                y: number;
            };

            let left = Math.round(item.left + delta.x);
            let top = Math.round(item.top + delta.y);
            [left, top] = doSnapToGrid(left, top);
            const itemFound = props.boxes.find(box => box.id == item.id.toString());
            if (itemFound) {
                const widthOffset = 1392 - boxSize * itemFound.width;
                const heightOffset = 696 - boxSize * itemFound.height;
                if (top < 0 || left < 0) return undefined;
                if (top > heightOffset || left > widthOffset) return undefined;
                moveBox(item.id, left, top);
                return undefined;
            } else {
                return undefined;
            }
        }
    });

    return (
        <div ref={drop} style={styles} data-testid="dashboard-editor">
            {boxes.map(box => (
                <DraggableBox
                    key={box.id}
                    id={box.id}
                    properties={box.props}
                    type={box.type}
                    left={box.left}
                    top={box.top}
                    height={box.height}
                    width={box.width}
                    translations={box.translations}
                    overlapped={box.overlapped}
                    deletePanelItem={props.deletePanelItem}
                    panelItemSelect={props.panelItemSelect}
                />
            ))}
        </div>
    );
};

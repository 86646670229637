import React, { ReactElement, useState, useEffect } from "react";
import { useLang } from "core/hooks";
import { useLazyQuery } from "@apollo/client";
import { surveyItemFieldsQuery, demographicFieldsAndValuesQuery } from "api/queries";
import { SurveySection } from "managerPortal/context/interfaces";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { Box, DashboardCellType, TitleTranslation } from "./interfaces";
import { OverallDimension } from "./overallDimension/OverallDimension";
import { KeyDriver } from "./keyDriver/KeyDriver";
import { Image } from "./image/Image";
import { SingleDimensionOrItem } from "./singleDimensionOrItem/SingleDimensionOrItem";
import { SingleDimensionWithItems } from "./singleDimensionWithItems/SingleDimensionWithItems";
import { Lookdown } from "./lookdown/Lookdown";
import { sortArray } from "core/helpers";

export type Props = {
    surveySelectedId: number;
    onCancelClicked: () => void;
    open: boolean;
    selectedPanelId: string;
    boxes: Box[];
    updatePanelItem: (
        column: number,
        row: number,
        type: DashboardCellType,
        updateString: string,
        titleTranslations: TitleTranslation[]
    ) => void;
    hasPreviousPeriod: boolean;
    hasBenchmark: boolean;
};

const PanelDialog = (props: Props): ReactElement => {
    const { open } = props;
    const { languageCode } = useLang();
    const [surveySections, setSurveySections] = useState<SurveySection[]>([]);
    const [surveySectionsWithENPS, setSurveySectionsWithENPS] = useState<SurveySection[]>([]);
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);

    const cellType = (): DashboardCellType => {
        const selectedBox = props.boxes.find(box => box.id === props.selectedPanelId);
        if (!selectedBox) {
            return "image";
        }
        return selectedBox.type;
    };

    const [getSurveyItems] = useLazyQuery(surveyItemFieldsQuery, {
        onCompleted: res => {
            if (res) {
                setSurveySections(res.surveyItemFields);
            }
        }
    });

    const [getSurveyItemsWithENPS] = useLazyQuery(surveyItemFieldsQuery, {
        onCompleted: res => {
            if (res) {
                setSurveySectionsWithENPS(res.surveyItemFields);
            }
        }
    });

    const [loadDemographicFieldsAndValues] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: (data: { demographicFieldsAndValues: HrisFieldInfo[] }) => {
            const fieldsList = data.demographicFieldsAndValues.filter((field: HrisFieldInfo) => !field.isHidden);
            const fieldsListSorted = sortArray(fieldsList, "fieldName", "asc");
            setContactFields(fieldsListSorted);
        }
    });

    useEffect(() => {
        getSurveyItems({
            variables: { surveyId: props.surveySelectedId, languageCode: languageCode, includeQuestionTypes: 2 }
        });
    }, []);

    useEffect(() => {
        getSurveyItemsWithENPS({
            variables: { surveyId: props.surveySelectedId, languageCode: languageCode, includeQuestionTypes: 4 }
        });
    }, []);

    useEffect(() => {
        loadDemographicFieldsAndValues({
            variables: { surveyId: props.surveySelectedId, languageCode: languageCode }
        });
    }, []);

    const getSelectedBox = (): Box | undefined => {
        return props.boxes.find(box => box.id === props.selectedPanelId) || undefined;
    };

    if (cellType() === "overallDimension" && open) {
        return (
            <OverallDimension
                onClose={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
                hasPreviousPeriod={props.hasPreviousPeriod}
                hasBenchmark={props.hasBenchmark}
                box={getSelectedBox()}
            />
        );
    }
    if (cellType() === "keyDrivers" && open) {
        return (
            <KeyDriver
                selectedPanelId={props.selectedPanelId}
                boxes={props.boxes}
                box={getSelectedBox()}
                surveySections={surveySections}
                onClose={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
            />
        );
    }
    if (cellType() === "image" && open) {
        return (
            <Image
                onClose={props.onCancelClicked}
                selectedPanelId={props.selectedPanelId}
                boxes={props.boxes}
                onCancelClicked={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
            />
        );
    }
    if (cellType() === "singleDimensionOrItem" && open) {
        return (
            <SingleDimensionOrItem
                onClose={props.onCancelClicked}
                selectedPanelId={props.selectedPanelId}
                boxes={props.boxes}
                box={getSelectedBox()}
                onCancelClicked={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
                surveySections={surveySectionsWithENPS}
            />
        );
    }
    if (cellType() === "singleDimensionWithItems" && open) {
        return (
            <SingleDimensionWithItems
                surveySections={surveySections}
                onClose={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
                hasPreviousPeriod={props.hasPreviousPeriod}
                hasBenchmark={props.hasBenchmark}
                box={getSelectedBox()}
            />
        );
    }
    if (cellType() === "lookdown" && open) {
        return (
            <Lookdown
                contactFields={contactFields}
                surveySections={surveySectionsWithENPS}
                onClose={props.onCancelClicked}
                updatePanelItem={props.updatePanelItem}
                hasPreviousPeriod={props.hasPreviousPeriod}
                box={getSelectedBox()}
            />
        );
    }
    return <></>;
};

export default PanelDialog;

import type { CSSProperties, FC } from "react";
import React, { memo } from "react";
import type { DragSourceMonitor } from "react-dnd";
import { useDrag } from "react-dnd";
import { DashboardBox } from "./DashboardBox";
import { ItemTypes } from "./ItemTypes";
import { TitleTranslation } from "./interfaces";

function getStyles(left: number, top: number, isDragging: boolean): CSSProperties {
    const transform = `translate3d(${left}px, ${top}px, 0)`;
    return {
        position: "absolute",
        transform,
        WebkitTransform: transform,
        opacity: isDragging ? 0 : 1
    };
}

export interface DraggableBoxProps {
    id: string;
    properties: string;
    type: string;
    left: number;
    top: number;
    height: number;
    width: number;
    translations: TitleTranslation[];
    overlapped: boolean;
    deletePanelItem: (id: string) => void;
    panelItemSelect: (id: string) => void;
}

export const DraggableBox: FC<DraggableBoxProps> = memo(function DraggableBox(props) {
    const { id, properties, left, top, type, height, width, overlapped, translations } = props;

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.BOX,
        item: { id, left, top, type: ItemTypes.BOX },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <div ref={drag} style={getStyles(left, top, isDragging)} role="DraggableBox">
            <DashboardBox
                id={id}
                properties={properties}
                type={type}
                height={height}
                width={width}
                overlapped={overlapped}
                translations={translations}
                deletePanelItem={props.deletePanelItem}
                panelItemSelect={props.panelItemSelect}
            />
        </div>
    );
});

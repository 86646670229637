export enum CorrelationActions {
    SELECT_SURVEY = "SELECT_SURVEY",
    LOAD_SECTION_HRIS = "LOAD_SECTION_HRIS",
    SET_STATUS = "SET_STATUS",
    RESTORE_USER_SETTINGS = "RESTORE_USER_SETTINGS",
    OPEN_CLOSE_FILTER = "OPEN_FILTER",
    SET_FILTER = "SET_FILTER",
    SET_CONFIDENTIALITY_STATUS = "SET_CONFIDENTIALITY_STATUS",
    SET_GRID_DATA = "SET_GRID_DATA"
}

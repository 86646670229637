import { SurveyItem, SurveySection } from "managerPortal/context/interfaces";
import { HeatmapDisplayValue, OverallValue, SectionOverallValue } from "../interface";

export const heatmapSectionMapper = (
    hmValue: SectionOverallValue[],
    surveySections: SurveySection[],
    showDeltaValue: boolean
): HeatmapDisplayValue[] => {
    const SectionValues: HeatmapDisplayValue[] = [];
    if (!hmValue) return SectionValues;
    hmValue.forEach(v => {
        const sectionFound = surveySections.find(s => v.sectionId === +s.sectionId.slice(1));

        if (sectionFound) {
            SectionValues.push({
                source: v.source,
                target: sectionFound?.title,
                showDelta: showDeltaValue,
                delta: v.delta,
                favorable: v.favorable,
                targetId: +sectionFound.sectionId.slice(1)
            });
        }
    });
    return SectionValues;
};

export const heatmapSectionOverallMapper = (
    overall: OverallValue[],
    surveySections: SurveySection[]
): HeatmapDisplayValue[] => {
    const SectionOverall: HeatmapDisplayValue[] = [];
    if (!overall) return SectionOverall;

    overall.forEach(o => {
        const sectionFound = surveySections.find(s => o.id === +s.sectionId.slice(1));
        if (sectionFound) {
            SectionOverall.push({
                source: "Overall",
                target: sectionFound?.title,
                showDelta: true,
                delta: o.delta,
                favorable: o.delta,
                targetId: +sectionFound.sectionId.slice(1)
            });
        }
    });
    return SectionOverall;
};

export const heatmapItemOverallMapper = (
    overall: OverallValue[],
    surveySections: SurveySection[]
): HeatmapDisplayValue[] => {
    const ItemOverall: HeatmapDisplayValue[] = [];
    if (!overall) return ItemOverall;
    const surveyItems: SurveyItem[] = [];

    surveySections.forEach(section =>
        section.questions.forEach(question => question.items.forEach(item => surveyItems.push({ ...item })))
    );

    overall.forEach(v => {
        const itemFound = surveyItems.find(item => +item.itemId.slice(1) === v.id);
        if (itemFound) {
            ItemOverall.push({
                source: "Overall",
                target: itemFound.title,
                showDelta: true,
                delta: v.delta,
                favorable: v.delta,
                targetId: +itemFound.itemId.slice(1)
            });
        }
    });
    return ItemOverall;
};

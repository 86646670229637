import React, { FunctionComponent, ChangeEvent, useState } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { isStringEmptyOrSpaces } from "core/helpers";
import { Tag } from "components/admin/tags/interface";
import { Button } from "lib/button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
import { Divider } from "lib/divider";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    dialogContent: {
        width: "400px"
    }
}));

interface Props {
    tags: Tag[];
    dialogTitle: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmitCallback: (tagName: string) => void;
}

export const CreateTagDialog: FunctionComponent<Props> = props => {
    const { lang } = useLang();
    const { dialogTitle, isOpen, onClose, onSubmitCallback, tags } = props;
    const [tagName, setTagName] = useState("");
    const [error, setError] = useState<boolean>(false);
    const [tagNameHelperText, setTagNameHelperText] = useState<string>("");

    const validation = (): boolean => {
        if (isStringEmptyOrSpaces(tagName)) {
            setError(true);
            setTagNameHelperText(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }

        return true;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        if (name === "tagName") {
            if (tags.map(t => t.name.toLowerCase()).includes(value.trim().toLowerCase())) {
                const helperText = lang.duplicateTag;
                setTagNameHelperText(helperText);
                setError(true);
                setTagName(value);
                return;
            }
            const helperText = !value ? lang.dialogExportSlide.controls.errorMainTitle : "";
            setTagNameHelperText(helperText);
            setError(!value);
            setTagName(value);
            return;
        }
    };

    const onCreateClick = (): void => {
        validation() && onSubmitCallback(tagName);
    };

    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onKeyUp={e => {
                if (e.key === "Enter" && !error) {
                    onCreateClick();
                }
            }}
        >
            <div className={classes.dialogContent}>
                <DialogTitle>
                    <Typography variant="h4">{dialogTitle}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        autoFocus
                        data-testid="text-field-tag-title"
                        name="tagName"
                        error={error}
                        label={lang.dialogExportSlide.controls.labelMainTitle}
                        onChange={handleChange}
                        value={tagName}
                        helperText={tagNameHelperText}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display="flex" gap={1} pr={2}>
                        <Button
                            onClick={onCreateClick}
                            disabled={error}
                            data-testid="btn-dialog-create-tag"
                            variant="text"
                        >
                            {lang.ok}
                        </Button>
                        <Button onClick={onClose} autoFocus={false} variant="text">
                            {lang.cancel}
                        </Button>
                    </Box>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export enum FunScale {
    neutral = "neutral",
    favorable = "favorable",
    unfavorable = "unfavorable",
    ignored = "ignored"
}

export enum FunScaleValue {
    Unfavorable = 1,
    Neutral = 2,
    Favorable = 3,
    Ignore = 4
}

export enum ReportingOptions {
    includeInReports = "includeInReports",
    removeFromReports = "removeFromReports",
    excludeFromOverallScore = "excludeFromOverallScore",
    isHiddenForManagers = "isHiddenForManagers",
    isOverridden = "isOverridden",
    useNominalScale = "useNominalScale"
}

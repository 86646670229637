import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { InView } from "react-intersection-observer";

import { useLang } from "core/hooks";
import NumberInCircle from "../shared/NumberInCircle";
import KeyDriverList from "../shared/KeyDriverList";
import ScoreStackedSingleBarChart from "../stackedBar/ScoreStackedSingleBarChart";
import { Classes } from "../../interfaces/Interfaces";
import { Card as CardType } from "./interfaces";
import { Bar, CardLayout, Numeric } from "../cards/interfaces";
import { disabledUnderlineColor } from "../../styles/GlobalStyles";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Divider } from "lib/divider";
import { Grid } from "lib/grid";
import { Card, CardContent, CardHeader } from "lib/card";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    content: {
        maxWidth: "88%",
        minWidth: "88%"
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardFooterContainer: {
        textAlign: "center"
    },
    cardFooterTooltip: {
        fontSize: 11
    },
    cardFooterIconButton: {
        width: 36,
        height: 24,
        padding: 0
    },
    cardContent: {
        paddingBottom: theme.spacing(1)
    },
    subheader: {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    subheaderName: {
        color: disabledUnderlineColor,
        fontWeight: theme.typography.fontWeightMedium
    }
}));

interface IFooterProps {
    values: CardType;
    onExpandClicked: (a: number) => void;
    canExpand: boolean;
    isExpanded: boolean;
    classes: Classes;
}

const CardFooter = (props: IFooterProps): ReactElement => {
    const { values, onExpandClicked, canExpand, isExpanded } = props;
    const { lang } = useLang();
    const classes = useStyles();

    return (
        <Box display="flex" width="100%" justifyContent="center">
            <Box width={20} display="flex" alignItems={"center"} justifyContent="flex-end">
                <Tooltip title={lang.responseCount} placement="bottom" classes={{ tooltip: classes.cardFooterTooltip }}>
                    <Typography variant="caption" align="center" sx={{ float: "right" }}>
                        {values.response_count}
                    </Typography>
                </Tooltip>
            </Box>
            {canExpand && (
                <>
                    <IconButton
                        className={classes.cardFooterIconButton}
                        onClick={(): void => onExpandClicked(values.id)}
                    >
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Box width={20}>
                        <Tooltip
                            title={lang.childCount}
                            placement="bottom"
                            classes={{ tooltip: classes.cardFooterTooltip }}
                        >
                            <Typography variant="caption" align="left" minWidth={20}>
                                {values && values.kids ? values.kids.length : 0}
                            </Typography>
                        </Tooltip>
                    </Box>
                </>
            )}
        </Box>
    );
};

interface ICarBarListProps {
    bar: Bar;
    node: CardType;
}
const CardBarList = ({ node, bar }: ICarBarListProps): ReactElement => {
    const valuesId = bar.id.slice(0, 1) + "SB" + bar.id.slice(1);
    let barValues = node[valuesId];

    if (!Array.isArray(barValues)) {
        barValues = [barValues];
    }

    if (bar.id === "XKeyDrivers") {
        return <KeyDriverList keyDrivers={node.key_drivers} responseCount={node.response_count} />;
    } else {
        return (
            <ScoreStackedSingleBarChart
                key={bar.id}
                values={barValues}
                sectionOrItemId={bar.id}
                valuesId={valuesId}
                title={bar.title}
                withBenchmark
            />
        );
    }
};

interface IProps {
    hide: boolean;
    values: CardType;
    layout: CardLayout;
    left: number;
    top: number;
    height: number;
    width: number;
    onExpandClicked: () => void;
    canExpand: boolean;
    isExpanded: boolean;
    classes: Classes;
    showMenu: (a: CardType, b: HTMLElement) => void;
}

const OrgChartCard = (props: IProps): ReactElement => {
    const { hide, layout, left, top, height, width, onExpandClicked, canExpand, isExpanded, values } = props;
    const classes = useStyles();

    const cardStyle: Classes = {
        visibility: hide ? "hidden" : "visible",
        width: width,
        height: height && height,
        position: !left && !top ? "auto" : "absolute",
        left: left ?? "auto",
        top: top ?? "auto",
        cursor: "default"
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InView>
            {({ inView, ref }): ReactElement => {
                inView = inView || !!hide;
                return (
                    <div style={cardStyle} ref={ref}>
                        {inView && (
                            <Card elevation={4} data-testid={values.title}>
                                <CardHeader
                                    title={values.title}
                                    classes={{
                                        title: classes.title,
                                        content: classes.content
                                    }}
                                    titleTypographyProps={{ variant: "body1" }}
                                />
                                <Divider />
                                <CardContent className={classes.cardContent}>
                                    {layout.bars &&
                                        layout.bars.length > 0 &&
                                        layout.bars.map((bar: Bar) => (
                                            <CardBarList key={bar.id} node={values} bar={bar} />
                                        ))}
                                    <Grid container justifyContent="center">
                                        {layout.numerics &&
                                            layout.numerics.length > 0 &&
                                            layout.numerics.map((e: Numeric, i: number) => (
                                                <Grid key={i} item>
                                                    {
                                                        <NumberInCircle
                                                            isPercent={true}
                                                            value={values[e.id.slice(0, 1) + "N" + e.id.slice(1)]}
                                                            tooltip={e.title}
                                                        />
                                                    }
                                                </Grid>
                                            ))}
                                    </Grid>
                                </CardContent>
                                <CardFooter
                                    onExpandClicked={onExpandClicked}
                                    canExpand={canExpand}
                                    isExpanded={isExpanded}
                                    classes={classes}
                                    values={values}
                                />
                            </Card>
                        )}
                    </div>
                );
            }}
        </InView>
    );
};

export default OrgChartCard;

import { gql } from "@apollo/client";

export const editSurveyComparisonFieldMapMutation = gql`
    mutation editSurveyComparisonFieldMap($surveyComparisonId: Int!, $sourceFieldId: Int!, $targetFieldId: Int!) {
        editSurveyComparisonFieldMap(
            surveyComparisonId: $surveyComparisonId
            sourceFieldId: $sourceFieldId
            targetFieldId: $targetFieldId
        ) {
            sourceValue
            sourceFieldId
            targetFilters {
                comparisonOperator
                combinationOperator
                id
                filterType
                target
            }
        }
    }
`;

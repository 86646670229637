import React, { ReactElement, useEffect, useReducer } from "react";
import { Typography } from "lib/typography";
import Papa from "papaparse";
import { DropEvent, FileRejection } from "react-dropzone";
import dayjs, { Dayjs } from "dayjs";

import { TextField } from "lib/text-field";
import { useLang, useSurveys } from "core/hooks";
import { DialogLayout } from "components/shared";
import SurveyPreviewDialog from "./SurveyPreviewDialog";
import DropArea from "../../shared/DropArea";
import { isStringEmptyOrSpaces } from "../../../core/helpers";
import { surveyImportReducer } from "./reducers";
import { SurveyImportActions } from "./actions";
import { SurveyImportState, CreateSurveyInput, SurveyInfo } from "./interfaces";
import { useSurveyImportStyles } from "./styles";
import { SurveyInputKey } from "./enums";
import { uploadEmpty, surveyInfoEmpty, fileEmpty } from "./init";
import { dateFormat } from "core/constants";
import { DatePicker } from "lib/date-picker";
import { useUser } from "core/context/user/useUser";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCompleteUpload: (surveyVariables: CreateSurveyInput) => void;
};

const init = (): SurveyImportState => {
    return {
        surveyFileLoaded: uploadEmpty,
        responsesFileLoaded: uploadEmpty,
        scaleFileLoaded: uploadEmpty,
        loading: false,
        isPreviewDialogOpen: false,
        surveyInfo: surveyInfoEmpty,
        isFormValid: { message: "", key: SurveyInputKey.none }
    };
};

const SurveyImportDialog = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();
    const { surveys } = useSurveys();
    const classes = useSurveyImportStyles();
    const [state, dispatch] = useReducer(surveyImportReducer, null, init);

    const handleCloseDateChange = (date: Dayjs | null): void => {
        const updateSurvey = { ...state.surveyInfo, closeDate: date };

        dispatch({
            type: SurveyImportActions.SET_SURVEY_INFO,
            payload: { surveyInfo: updateSurvey }
        });
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let updateSurvey = {
            ...state.surveyInfo
        };
        if (event.target.id === SurveyInputKey.name) {
            const updateName = [...updateSurvey.translations];
            updateName[0] = { name: event.target.value };
            updateSurvey = {
                ...updateSurvey,
                translations: updateName
            };
            dispatch({
                type: SurveyImportActions.SET_FORM_VALIDATION,
                payload: { isFormValid: { message: "", key: SurveyInputKey.none } }
            });
        } else {
            updateSurvey = {
                ...updateSurvey,
                [event.target.title]: event.target.value
            };
        }

        dispatch({
            type: SurveyImportActions.SET_SURVEY_INFO,
            payload: { surveyInfo: updateSurvey }
        });
    };

    const handleFileDrop = (files: File[], reject: FileRejection[], event: DropEvent): void => {
        let title = "";
        if (event && event.target) {
            if ("getAttribute" in event.target && event.target.getAttribute("data-dropkey")) {
                title = event.target.getAttribute("data-dropkey") || "";
            } else if ("parentElement" in event.target) {
                title = event.target.parentElement?.title || "";
            }
        }
        const loadFile = { ...uploadEmpty, file: files[0], isLoaded: true };

        switch (title) {
            case SurveyInputKey.surveyLoad:
                dispatch({
                    type: SurveyImportActions.SET_SURVEY_FILE_LOADED,
                    payload: { surveyFileLoaded: loadFile }
                });
                return;
            case SurveyInputKey.responseLoad:
                dispatch({
                    type: SurveyImportActions.SET_RESPONSES_FILE_LOADED,
                    payload: { responsesFileLoaded: loadFile }
                });
                return;
            case SurveyInputKey.scaleLoad:
                dispatch({
                    type: SurveyImportActions.SET_SCALE_FILE_LOADED,
                    payload: { scaleFileLoaded: loadFile }
                });

                return;
            default:
                break;
        }
    };

    useEffect(() => {
        if (
            state.surveyFileLoaded?.data.length > 0 &&
            state.responsesFileLoaded?.data.length > 0 &&
            state.scaleFileLoaded?.data.length > 0
        ) {
            if (
                !state.surveyFileLoaded.errorMessage &&
                !state.responsesFileLoaded.errorMessage &&
                !state.scaleFileLoaded.errorMessage
            ) {
                dispatch({
                    type: SurveyImportActions.SET_OPEN_PREVIEW_DIALOG,
                    payload: { isPreviewDialogOpen: true }
                });
            }
            dispatch({
                type: SurveyImportActions.SET_LOADING,
                payload: { loading: false }
            });
        }
    }, [state.surveyFileLoaded, state.responsesFileLoaded, state.scaleFileLoaded]);

    const handleUploadClick = (): void => {
        dispatch({
            type: SurveyImportActions.SET_LOADING,
            payload: { loading: true }
        });
        if (state.surveyFileLoaded) {
            Papa.parse(state.surveyFileLoaded.file, {
                skipEmptyLines: true,
                complete: results => {
                    dispatch({
                        type: SurveyImportActions.SET_SURVEY_FILE_LOADED,
                        payload: { surveyFileLoaded: { ...state.surveyFileLoaded, data: results.data } }
                    });
                },
                error: () => {
                    dispatch({
                        type: SurveyImportActions.SET_SURVEY_FILE_LOADED,
                        payload: {
                            surveyFileLoaded: {
                                ...state.surveyFileLoaded,
                                errorMessage: lang.errorLoadingCSV
                            }
                        }
                    });
                }
            });
        }
        if (state.responsesFileLoaded) {
            Papa.parse(state.responsesFileLoaded.file, {
                skipEmptyLines: true,
                complete: results => {
                    dispatch({
                        type: SurveyImportActions.SET_RESPONSES_FILE_LOADED,
                        payload: { responsesFileLoaded: { ...state.responsesFileLoaded, data: results.data } }
                    });
                },
                error: () => {
                    dispatch({
                        type: SurveyImportActions.SET_RESPONSES_FILE_LOADED,
                        payload: {
                            responsesFileLoaded: {
                                ...state.responsesFileLoaded,
                                errorMessage: lang.errorLoadingCSV
                            }
                        }
                    });
                }
            });
        }
        if (state.scaleFileLoaded) {
            Papa.parse(state.scaleFileLoaded.file, {
                skipEmptyLines: true,
                complete: results => {
                    dispatch({
                        type: SurveyImportActions.SET_SCALE_FILE_LOADED,
                        payload: { scaleFileLoaded: { ...state.scaleFileLoaded, data: results.data } }
                    });
                },
                error: () => {
                    dispatch({
                        type: SurveyImportActions.SET_SCALE_FILE_LOADED,
                        payload: {
                            scaleFileLoaded: { ...state.scaleFileLoaded, errorMessage: lang.errorLoadingCSV }
                        }
                    });
                }
            });
        }
    };

    const handleClosePreviewDialog = (): void => {
        dispatch({
            type: SurveyImportActions.SET_OPEN_PREVIEW_DIALOG,
            payload: { isPreviewDialogOpen: false }
        });
    };

    const handleCompleteUploadClick = (): void => {
        const surveyVariables = {
            survey: {
                organizationId: user.settings.selectedOrganization.id,
                name: state.surveyInfo.translations[0].name,
                closeDate: state.surveyInfo.closeDate ? state.surveyInfo.closeDate.format(dateFormat) : ""
            },
            itemsFile: state.surveyFileLoaded.file,
            valuesFile: state.scaleFileLoaded.file,
            responsesFile: state.responsesFileLoaded.file
        };
        dispatch({
            type: SurveyImportActions.SET_OPEN_PREVIEW_DIALOG,
            payload: { isPreviewDialogOpen: false }
        });

        props.onCompleteUpload(surveyVariables);
    };

    const validation = (): boolean => {
        let isNotValid = false;

        isNotValid =
            !state.surveyInfo.closeDate ||
            !state.scaleFileLoaded.isLoaded ||
            !state.surveyFileLoaded.isLoaded ||
            !state.responsesFileLoaded.isLoaded ||
            !!state.scaleFileLoaded.errorMessage ||
            !!state.surveyFileLoaded.errorMessage ||
            !!state.responsesFileLoaded.errorMessage;

        return isNotValid;
    };

    const isNameValid = (): void => {
        if (isStringEmptyOrSpaces(state.surveyInfo.translations[0].name)) {
            dispatch({
                type: SurveyImportActions.SET_FORM_VALIDATION,
                payload: { isFormValid: { message: lang.surveyNameCannotBeBlank, key: SurveyInputKey.name } }
            });
            return;
        }

        const found = surveys.find((s: SurveyInfo) => {
            return s.translations[0].name.toLowerCase() === state.surveyInfo.translations[0].name.toLowerCase();
        });
        if (found) {
            dispatch({
                type: SurveyImportActions.SET_FORM_VALIDATION,
                payload: { isFormValid: { message: lang.surveyNameMustBeUnique, key: SurveyInputKey.name } }
            });
            return;
        }

        dispatch({
            type: SurveyImportActions.SET_FORM_VALIDATION,
            payload: { isFormValid: { message: "", key: SurveyInputKey.none } }
        });
    };

    const handleRejectedFiles = (key: string, message: string): void => {
        switch (key) {
            case SurveyInputKey.surveyLoad:
                dispatch({
                    type: SurveyImportActions.SET_SURVEY_FILE_LOADED,
                    payload: { surveyFileLoaded: { ...state.surveyFileLoaded, errorMessage: message, file: fileEmpty } }
                });
                return;
            case SurveyInputKey.responseLoad:
                dispatch({
                    type: SurveyImportActions.SET_RESPONSES_FILE_LOADED,
                    payload: {
                        responsesFileLoaded: { ...state.surveyFileLoaded, errorMessage: message, file: fileEmpty }
                    }
                });
                return;
            case SurveyInputKey.scaleLoad:
                dispatch({
                    type: SurveyImportActions.SET_SCALE_FILE_LOADED,
                    payload: { scaleFileLoaded: { ...state.surveyFileLoaded, errorMessage: message, file: fileEmpty } }
                });
                return;
            default:
                break;
        }
    };

    return (
        <>
            <DialogLayout
                open={props.isOpen}
                onClose={props.onClose}
                title={lang.uploadSurvey}
                buttonLabelAction={lang.ok}
                disableActionButton={state.isFormValid.key !== SurveyInputKey.none || validation()}
                onClick={handleUploadClick}
                loading={state.loading}
                height={650}
            >
                <div className={classes.formControl}>
                    <div className={classes.optionsSplit}>
                        <TextField
                            label={lang.name}
                            name={SurveyInputKey.name}
                            id={SurveyInputKey.name}
                            value={state.surveyInfo.translations[0].name}
                            onBlur={isNameValid}
                            onChange={handleChangeField}
                            error={state.isFormValid.key === SurveyInputKey.name}
                            helperText={state.isFormValid.key === SurveyInputKey.name ? state.isFormValid.message : ""}
                            data-testid="text-field-survey-import-name"
                        />
                    </div>
                    <DatePicker
                        label={lang.closeDate}
                        value={state.surveyInfo.closeDate}
                        onChange={handleCloseDateChange}
                        minDate={dayjs("1900/01/01")}
                        maxDate={dayjs()}
                    />
                </div>
                <DropArea
                    fileDrop={handleFileDrop}
                    dataDropKey={SurveyInputKey.surveyLoad}
                    label={lang.surveyFileCSV}
                    csvOnly
                    onRejectFiles={(): void => handleRejectedFiles(SurveyInputKey.surveyLoad, lang.errorMustUploadCSV)}
                />
                <Typography
                    className={classes.loadedFile}
                    variant={!state.surveyFileLoaded.isLoaded ? "body2" : "body1"}
                    color={state.surveyFileLoaded.errorMessage ? "error" : "initial"}
                >
                    {state.surveyFileLoaded.errorMessage
                        ? state.surveyFileLoaded.errorMessage
                        : !state.surveyFileLoaded.isLoaded
                          ? lang.noFileAttached
                          : state.surveyFileLoaded.file.name}
                </Typography>
                <DropArea
                    fileDrop={handleFileDrop}
                    dataDropKey={SurveyInputKey.responseLoad}
                    label={lang.responseFileCSV}
                    csvOnly
                    onRejectFiles={(): void =>
                        handleRejectedFiles(SurveyInputKey.responseLoad, lang.errorMustUploadCSV)
                    }
                />
                <Typography
                    className={classes.loadedFile}
                    variant={!state.responsesFileLoaded.isLoaded ? "body2" : "body1"}
                    color={state.responsesFileLoaded.errorMessage ? "error" : "initial"}
                >
                    {state.responsesFileLoaded.errorMessage
                        ? state.responsesFileLoaded.errorMessage
                        : !state.responsesFileLoaded.isLoaded
                          ? lang.noFileAttached
                          : state.responsesFileLoaded.file.name}
                </Typography>
                <DropArea
                    fileDrop={handleFileDrop}
                    dataDropKey={SurveyInputKey.scaleLoad}
                    label={lang.scaleFileCSV}
                    csvOnly
                    onRejectFiles={(): void => handleRejectedFiles(SurveyInputKey.scaleLoad, lang.errorMustUploadCSV)}
                />
                <Typography
                    className={classes.loadedFile}
                    variant={!state.scaleFileLoaded.isLoaded ? "body2" : "body1"}
                    color={state.scaleFileLoaded.errorMessage ? "error" : "initial"}
                >
                    {state.scaleFileLoaded.errorMessage
                        ? state.scaleFileLoaded.errorMessage
                        : !state.scaleFileLoaded.isLoaded
                          ? lang.noFileAttached
                          : state.scaleFileLoaded.file.name}
                </Typography>
            </DialogLayout>
            {state.isPreviewDialogOpen && (
                <SurveyPreviewDialog
                    isOpen={state.isPreviewDialogOpen}
                    onClose={handleClosePreviewDialog}
                    onCompleteClick={handleCompleteUploadClick}
                    responses={state.responsesFileLoaded.data}
                />
            )}
        </>
    );
};

export default SurveyImportDialog;

import React, { useState } from "react";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { OrganizationInfo } from "../admin/organizations/interfaces";
import { useLang } from "core/hooks";
import { useOrganization } from "core/context/organization/useOrganization";
import { Button } from "lib/button";
import { Dialog } from "lib/dialog";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { sortArray } from "core/helpers";
import { Typography } from "lib/typography";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onSelect: (orgSelected: OrganizationInfo) => void;
    organizationSelected: { id: number; name: string };
};

const OrganizationListDialog: React.FC<Props> = ({ isOpen, onCancel, onSelect, organizationSelected }) => {
    const { lang } = useLang();
    const { organizations } = useOrganization();
    const activeOrgs = organizations.filter((org: OrganizationInfo) => org.active);
    const [selectedOrg, setSelectedOrg] = useState(organizationSelected);

    const sortOrgsArray = <T extends OrganizationInfo>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");

    const handleSelectOrganization = (value: AutocompleteValue | null): void => {
        if (value !== null && value !== undefined) {
            const pickedOrg = { name: value.label, id: value.id };
            setSelectedOrg(pickedOrg);
        }
    };

    const onOKClick = () => {
        const foundOrg = activeOrgs.find(org => org.id === selectedOrg.id);
        if (foundOrg) {
            onSelect(foundOrg);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            id="organization-dialog"
            onKeyUp={e => {
                if (e.key === "Enter") {
                    onOKClick();
                }
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "548px",
                    height: "320px"
                }}
            >
                <Box display="flex" alignItems="center" pl={3} pr={3} height={80}>
                    <Typography variant="h6">{lang.switchOrganization}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent={"space-between"} height={180}>
                    <Divider />
                    <Box width={500} ml={"24px"}>
                        <Autocomplete
                            id="switch-org-combo-box"
                            noOptionsText={lang.noOptionsAvailable}
                            value={{ id: selectedOrg.id, label: selectedOrg.name }}
                            options={sortOrgsArray(activeOrgs).map(s => {
                                return {
                                    id: s.id,
                                    label: s.name
                                };
                            })}
                            onChange={handleSelectOrganization}
                            getOptionDisabled={(option): boolean => {
                                return selectedOrg.id === option.id;
                            }}
                            placeholder={lang.selectOrg}
                        />
                    </Box>
                    <Box></Box>
                    <Divider />
                </Box>
                <Box height={60} display="flex" alignItems="center" justifyContent="flex-end" gap={2} pr={3}>
                    <Button onClick={onOKClick} variant="text" data-testid="btn-switch-org-ok">
                        {lang.ok}
                    </Button>
                    <Button variant="text" data-testid="btn-switch-org-cancel" onClick={onCancel}>
                        {lang.cancel}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default OrganizationListDialog;

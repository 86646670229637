import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import saveAs from "file-saver";
import { NotInterested, Delete } from "@mui/icons-material";

import { useLang, useOrgChartService } from "core/hooks";
import { theme } from "lib/theme";
import { BulkReportStatus, OverallBulkStatus } from "api/rest/BulkReportStatus";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import { dateFormatWithTime } from "core/constants";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Snackbar } from "lib/snackbar";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { LinearProgress, LinearProgressProps } from "lib/linear-progress";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Popover } from "lib/popover";
import dayjs from "dayjs";
import { useUser } from "core/context/user/useUser";

type Props = {
    closePopover: () => void;
};

const useStyles = makeStyles(() => ({
    bulkExport: {
        display: "flex"
    },
    popoverContainer: {
        width: 500,
        minHeight: 287,
        height: 287,
        maxHeight: 287,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        boxSizing: "border-box"
    },
    popover_row_ttile: {
        display: "flex",
        width: 468,
        justifyContent: "space-between",
        alignItems: "center",
        height: 55,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        borderBottom: theme.border.main
    },
    popover_status: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: 231,
        overflowX: "hidden",
        overflowY: "auto"
    },
    statusBar: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        minHeight: 76,
        height: 76,
        maxHeight: 76,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottom: theme.border.main
    },
    report: {
        display: "flex",
        width: 450,
        justifyContent: "space-between",
        alignItems: "center"
    },
    reportInfo: {
        display: "flex",
        flexDirection: "column"
    },
    clickableText: {
        textDecoration: "underline",
        color: theme.palette.secondary.light,
        cursor: "pointer"
    },
    progressBox: {
        display: "flex",
        alignItems: "center"
    },
    deleteBtn: {
        display: "flex"
    },
    clearIconButton: {
        color: orangeColor,
        "& svg": {
            fontSize: 20
        }
    }
}));

const BulkStatusDialog = (props: Props): ReactElement => {
    const classes = useStyles();
    const { user } = useUser();
    const orgChartService = useOrgChartService();
    const { lang, languageCode } = useLang();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [reportStatuses, setReportStatuses] = useState<BulkReportStatus[]>([]);
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
    const [deleteAllConfirmationDialog, setDeleteAllConfirmationDialog] = useState<boolean>(false);
    const [jobId, setJobId] = useState<number>(-1);

    const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }): ReactElement => {
        return (
            <div className={classes.progressBox}>
                <Box width={360}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box width={35} ml={3}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </div>
        );
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setAnchorEl(el);
    };

    const handleClosePopover = (): void => {
        setAnchorEl(null);
        props.closePopover();
    };

    const handleReportDownload = (status: BulkReportStatus): void => {
        orgChartService.downloadBulkReport(status.id).then((blob: unknown) => {
            setSnackbar({ isOpen: true, message: lang.startDownloading });
            saveAs(blob as Blob, "export.zip");
        });
    };

    const deleteFromCompletedReportsList = (jobId: number): void => {
        orgChartService.deleteBulkReport(jobId).then((): void => {
            setUpdateStatus(!updateStatus);
        });
    };

    const handleDeleteBulkExport = (jobId: number): void => {
        setJobId(jobId);
        setConfirmationDialog(true);
    };

    const handleOpenDeleteAllDialog = (): void => {
        setDeleteAllConfirmationDialog(true);
    };

    const handleCancelDeleteBulkExport = (): void => {
        setJobId(-1);
        setConfirmationDialog(false);
    };

    const handleCancelDeleteAllBulkExport = (): void => {
        setDeleteAllConfirmationDialog(false);
    };

    const handleConfirmationClicked = (): void => {
        if (jobId !== -1) {
            orgChartService.deleteBulkReport(jobId).then((): void => {
                setUpdateStatus(!updateStatus);
                setJobId(-1);
                setConfirmationDialog(false);
            });
        }
    };

    const handleDeleteAllConfirmationClicked = (): void => {
        orgChartService.deleteAllBulkExports().then((): void => {
            setUpdateStatus(!updateStatus);
            setDeleteAllConfirmationDialog(false);
        });
    };

    const cancelGeneratingReport = (jobId: number): void => {
        orgChartService.cancelBulkReport(jobId).then((): void => {
            setUpdateStatus(!updateStatus);
        });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const RenderQueuedReport = (status: BulkReportStatus, index: number): ReactElement => {
        return (
            <div className={classes.report}>
                <div className={classes.reportInfo} data-testid={`reportinfo-${index}`}>
                    <Typography variant="body2">{status.title}</Typography>
                    <Typography variant="body2">{`${lang.reportQueued} (${lang.queuePositions} - ${status.queuePosition})`}</Typography>
                </div>
                <div className={classes.deleteBtn} data-testid={`delete-btn-${index}`}>
                    <IconButton
                        size="small"
                        className={classes.clearIconButton}
                        aria-label={lang.delete}
                        onClick={(): void => deleteFromCompletedReportsList(status.id)}
                        color="primary"
                    >
                        <NotInterested />
                    </IconButton>
                </div>
            </div>
        );
    };
    const RenderGeneratingReport = (status: BulkReportStatus, index: number): ReactElement => {
        return (
            <div className={classes.report}>
                <div className={classes.reportInfo} data-testid={`reportinfo-${index}`}>
                    <Typography variant="body2">{status.title}</Typography>
                    <LinearProgressWithLabel value={status.progress} />
                    <Typography variant="body2">{lang.reportGenerating}</Typography>
                </div>
                <div className={classes.deleteBtn} data-testid={`delete-btn-${index}`}>
                    <IconButton
                        size="small"
                        className={classes.clearIconButton}
                        aria-label={lang.delete}
                        onClick={(): void => cancelGeneratingReport(status.id)}
                        color="primary"
                    >
                        <NotInterested />
                    </IconButton>
                </div>
            </div>
        );
    };
    const RenderErrorReport = (status: BulkReportStatus, index: number): ReactElement => {
        return (
            <div className={classes.report}>
                <div className={classes.reportInfo} data-testid={`reportinfo-${index}`}>
                    <Typography variant="body2">{status.title}</Typography>
                    <Typography variant="body2" color="red">
                        {`${lang.reportError}.`}
                        <Typography variant="body2" display={"inline"} paddingLeft={"4px"} color="rgb(0,0,0,0.87)">
                            {`${dayjs(status.lastModified).format(dateFormatWithTime)} UTC`}
                        </Typography>
                    </Typography>
                    <Typography variant="body2">
                        {lang.pleaseContact}
                        {"  "}
                        <a href={"mailto:support@talentmap.com"} target="_blank" rel="noopener noreferrer">
                            support@talentmap.com
                        </a>
                    </Typography>
                </div>
                <div className={classes.deleteBtn} data-testid={`delete-btn-${index}`}>
                    <IconButton
                        size="small"
                        className={classes.clearIconButton}
                        aria-label={lang.delete}
                        onClick={(): void => handleDeleteBulkExport(status.id)}
                        color="primary"
                    >
                        <Delete />
                    </IconButton>
                </div>
            </div>
        );
    };

    const RenderDownloadReport = (status: BulkReportStatus, index: number): ReactElement => {
        return (
            <div className={classes.report}>
                <div className={classes.reportInfo} data-testid={`reportinfo-${index}`}>
                    <Typography
                        variant="body2"
                        className={classes.clickableText}
                        onClick={(): void => {
                            handleReportDownload(status);
                        }}
                    >
                        {status.title}
                    </Typography>
                    <Typography variant="body2">{`${lang.reportCompleted} - ${dayjs(status.lastModified).format(
                        dateFormatWithTime
                    )} UTC`}</Typography>
                </div>
                <div className={classes.deleteBtn} data-testid={`delete-btn-${index}`}>
                    <IconButton
                        size="small"
                        className={classes.clearIconButton}
                        aria-label={lang.delete}
                        onClick={(): void => handleDeleteBulkExport(status.id)}
                        color="primary"
                    >
                        <Delete />
                    </IconButton>
                </div>
            </div>
        );
    };

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (anchorEl) {
            const getStatus = (): void => {
                orgChartService.getBulkReportStatus(user.settings.snapshot.id, languageCode).then(statuses => {
                    if (statuses && statuses.some(s => s.progress != 100)) {
                        setReportStatuses(statuses);
                        timerId = setTimeout(() => getStatus(), 2000);
                    } else {
                        setReportStatuses(statuses);
                        if (timerId) {
                            clearTimeout(timerId);
                        }
                    }
                });
            };
            getStatus();
        }
        return (): void => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [anchorEl, updateStatus]);

    return (
        <div className={classes.bulkExport}>
            <Button onClick={handleOpenPopover} variant="text" data-testid={"bulk-export-status"}>
                {lang.bulkstatus}
            </Button>
            <Popover
                open={anchorEl !== null}
                onClose={handleClosePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 50,
                    horizontal: -16
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
            >
                <div className={classes.popoverContainer}>
                    <div className={classes.popover_row_ttile}>
                        {lang.bulkProgress}
                        <Button onClick={handleOpenDeleteAllDialog} variant="text" data-testid="bulk-export-delete-all">
                            {lang.deleteCompleted}
                        </Button>
                    </div>
                    <div className={classes.popover_status}>
                        {reportStatuses &&
                            reportStatuses
                                .sort((a, b) => {
                                    if (a.queuePosition === 0 && b.queuePosition !== 0) return 1;
                                    else if (b.queuePosition === 0 && a.queuePosition !== 0) return -1;
                                    else if (a.queuePosition === 0 && b.queuePosition === 0)
                                        return dayjs(a.lastModified).isAfter(dayjs(b.lastModified)) ? -1 : 1;
                                    else return a.queuePosition - b.queuePosition;
                                })
                                .map((status, index) => (
                                    <div key={status.id} className={classes.statusBar} data-testid={`status-${index}`}>
                                        {status.status === OverallBulkStatus.Errored &&
                                            RenderErrorReport(status, index)}
                                        {status.progress === 0 &&
                                            status.queuePosition > 1 &&
                                            RenderQueuedReport(status, index)}
                                        {status.queuePosition === 1 &&
                                            status.progress !== 100 &&
                                            RenderGeneratingReport(status, index)}
                                        {status.progress === 100 &&
                                            status.status === OverallBulkStatus.Complete &&
                                            RenderDownloadReport(status, index)}
                                    </div>
                                ))}
                    </div>
                </div>
            </Popover>
            <ConfirmationDialog
                open={confirmationDialog}
                onCancelClicked={handleCancelDeleteBulkExport}
                onConfirmationClicked={handleConfirmationClicked}
                title={lang.deleteBulkExport}
                message={lang.deleteCannotBeReversedForBulk}
                confirmButtonText={lang.delete}
                cancelButtonVariant="text"
            />
            <ConfirmationDialog
                open={deleteAllConfirmationDialog}
                onCancelClicked={handleCancelDeleteAllBulkExport}
                onConfirmationClicked={handleDeleteAllConfirmationClicked}
                title={lang.deleteAllBulkExport}
                message={lang.deleteCannotBeReversedForBulk}
                confirmButtonText={lang.delete}
                cancelButtonVariant="text"
            />
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
        </div>
    );
};

export default BulkStatusDialog;

import React from "react";

import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import MuiFormGroup from "@mui/material/FormGroup";
import MuiFormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";

type Props = SwitchProps & {
    label: string;
    lablePlacement: FormControlLabelProps["labelPlacement"];
};

const Switch = (props: Props) => {
    const { label, lablePlacement, ...rest } = props;
    return (
        <MuiFormGroup>
            <MuiFormControlLabel
                control={<MuiSwitch defaultChecked {...rest} />}
                label={label}
                labelPlacement={lablePlacement}
                sx={{ marginLeft: 0 }}
                color="primary"
            />
        </MuiFormGroup>
    );
};

export { Switch };

import React, { ReactElement } from "react";
import { ThemeProvider } from "@mui/material";

import { theme as themeV5 } from "lib/theme";
import { ApolloConfig } from "core/context/apollo/apollo-config";
import { AuthProvider } from "core/context/auth/auth";
import { UserProvider } from "core/context/user/user";
import { CompassProvider } from "core/context/CompassContext";
import { LoadingProvider } from "core/context/LoadingContext";
import { SnackbarContextProvider } from "core/context/SnackbarContext";
import Layout from "components/hoc/layout/Layout";
import MainRouter from "routes/MainRouter";
import { OrganizationProvider } from "core/context/organization/organization";

const App = (): ReactElement => {
    return (
        <AuthProvider>
            <ApolloConfig>
                <UserProvider>
                    <OrganizationProvider>
                        <CompassProvider>
                            <ThemeProvider theme={themeV5}>
                                <SnackbarContextProvider>
                                    <LoadingProvider>
                                        <Layout>
                                            <MainRouter />
                                        </Layout>
                                    </LoadingProvider>
                                </SnackbarContextProvider>
                            </ThemeProvider>
                        </CompassProvider>
                    </OrganizationProvider>
                </UserProvider>
            </ApolloConfig>
        </AuthProvider>
    );
};

export default App;

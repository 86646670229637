import React, { PropsWithChildren } from "react";

import Popover, { PopoverOrigin, PopoverPosition } from "@mui/material/Popover";

type Props = {
    onClose: () => void;
    anchorPosition: PopoverPosition;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};

const paperStyles = {
    boxSizing: "border-box"
};

const Modal = (props: PropsWithChildren<Props>) => {
    const { onClose, anchorPosition, children, anchorOrigin, transformOrigin } = props;

    return (
        <Popover
            open={true}
            anchorPosition={anchorPosition}
            anchorReference="anchorPosition"
            anchorOrigin={{
                vertical: anchorOrigin?.vertical ?? "top",
                horizontal: anchorOrigin?.horizontal ?? "right"
            }}
            transformOrigin={{
                vertical: transformOrigin?.vertical ?? "top",
                horizontal: transformOrigin?.horizontal ?? "right"
            }}
            onClose={onClose}
            PaperProps={{
                sx: paperStyles
            }}
        >
            {children}
        </Popover>
    );
};

export { Modal };

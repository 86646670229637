import React, { ReactElement, useState, useEffect } from "react";

import { useLang } from "core/hooks";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";
import { IconButtonV4 } from "lib/icon-button";

import { makeStyles } from "@mui/styles";
import { theme } from "lib/theme";

interface ExpandCollapseProps {
    handleExpandAll: () => void;
    handleCollapseAll: () => void;
    originalHeaderLength: number;
    currentHeaderLength: number;
    expandedAllHeaderLength: number;
    originalRowLength: number;
    currentRowLength: number;
    expandedAllRowLength: number;
}

const useStyles = makeStyles(() => ({
    container: {
        height: 270,
        width: 322,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        boxSizing: "border-box"
    }
}));

export const ExpandCollapseBtnContainer = (props: ExpandCollapseProps): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [isAllCollapsed, setCollapseAll] = useState(true);
    const [isAllExpanded, setExpandAll] = useState(false);
    const {
        originalHeaderLength,
        currentHeaderLength,
        expandedAllHeaderLength,
        originalRowLength,
        currentRowLength,
        expandedAllRowLength
    } = props;

    useEffect(() => {
        if (
            originalHeaderLength > 0 &&
            currentHeaderLength === originalHeaderLength &&
            currentRowLength === originalRowLength
        ) {
            setCollapseAll(true);
            setExpandAll(false);
        } else if (
            originalHeaderLength > 0 &&
            currentHeaderLength === expandedAllHeaderLength &&
            currentRowLength === expandedAllRowLength
        ) {
            setExpandAll(true);
            setCollapseAll(false);
        } else {
            setExpandAll(false);
            setCollapseAll(false);
        }
    }, [props.currentHeaderLength, props.currentRowLength]);
    return (
        <div className={classes.container}>
            <IconButtonV4
                path={mdiArrowExpandVertical}
                onClick={props.handleExpandAll}
                tooltip={lang.expandAll}
                color={theme.palette.primary.main}
                dataTestid="correlation-expandAll"
                disabled={isAllExpanded}
            />
            <IconButtonV4
                path={mdiArrowCollapseVertical}
                onClick={props.handleCollapseAll}
                color={theme.palette.primary.main}
                tooltip={lang.collapseAll}
                dataTestid="correlation-collapseAll"
                disabled={isAllCollapsed}
            />
        </div>
    );
};

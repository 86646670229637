import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { sortArray } from "core/helpers";
import { useLang } from "core/hooks";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { SurveyInfo } from "components/admin/results/interfaces";
import { BreakdownItem, BreakdownItems } from "./interfaces";
import CustomSelectExpandablePanel from "components/reports/responseRate/CustomSelectExpandablePanel";
import { theme } from "lib/theme";

type Props = {
    surveySelected: SurveyInfo;
    breakdownItems: BreakdownItems;
    selectOptions: SelectOptionExpandablePanel[];
    setCurrentSelect: (id: number) => void;
    onDeleteBreakdown: (id: number) => void;
    onSelectBreakdown: (id: number, name: string) => void;
    onAddBreakdown: () => void;
    lastTimeUpdated?: string;
    //TODO: temporary
    hideIcons?: boolean;
};

const emptyOptions = (name: string): SelectOptionExpandablePanel[] => {
    return [{ id: "-1", title: name }];
};

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: 353,
        paddingLeft: theme.spacing(3),
        backgroundColor: theme.palette.background.default
    },
    breakdownItems: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    itemInfo: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        alignItems: "center",
        marginBottom: theme.spacing(1),
        "& button": {
            padding: `${theme.spacing(1)}px !important`,
            marginLeft: theme.spacing(1)
        }
    },
    lastUpdated: {
        height: 48,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: theme.border.main
    },
    textLine: {
        paddingBottom: 8
    }
}));

const MultiSelectionHeader = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    const sortedOptions = sortArray(props.selectOptions, "title", "asc");

    const handleSelectBreakdown = (id: string, name: string): void => {
        props.onSelectBreakdown(+id, name);
    };

    return (
        <div className={classes.container}>
            <div className={classes.breakdownItems}>
                <>
                    {props.breakdownItems.items.map((item: BreakdownItem) => (
                        <div
                            className={classes.itemInfo}
                            key={item.id}
                            onClick={(): void => props.setCurrentSelect(item.id)}
                        >
                            <CustomSelectExpandablePanel
                                inputLabel={lang.demographics}
                                selectOptions={
                                    props.selectOptions.length ? sortedOptions : emptyOptions(lang.noOptionsAvailable)
                                }
                                disabledItemsIds={
                                    props.selectOptions.length
                                        ? props.breakdownItems.items.map((item): string => item.id.toString())
                                        : [emptyOptions(lang.noOptionsAvailable)[0].id]
                                }
                                selectedItem={{ id: item.id.toString(), name: item.name }}
                                onSelectItem={handleSelectBreakdown}
                            />
                        </div>
                    ))}
                </>
            </div>
        </div>
    );
};

export default MultiSelectionHeader;

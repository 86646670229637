import { SurveyGz, SurveyInfo, SurveyInfoLoad } from "../interfaces";
import { LoadFile } from "components/admin/types/types";
import dayjs from "dayjs";

export const emptySurveyInfo: SurveyInfo = {
    active: true,
    created: "",
    id: -1,
    translations: [{ name: "" }],
    closeDate: dayjs(),
    confidentialityThreshold: 5,
    sgSurveyName: "",
    createdByName: "",
    sentimentCalculationStatus: "",
    allowInReports: false,
    lockMappings: false
};

export const fileEmpty = new File([""], "");

export const uploadEmpty: LoadFile = {
    file: fileEmpty,
    data: [],
    errorMessage: undefined,
    isLoaded: false
};

export const surveyGzEmpty: SurveyGz = {
    id: -1,
    translations: [{ name: "" }]
};

export const surveyInfoEmpty: SurveyInfoLoad = {
    closeDate: dayjs(),
    translations: [
        {
            name: ""
        }
    ]
};

export const snackbarEmpty = {
    isOpen: false,
    message: ""
};

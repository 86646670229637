import React, { ReactElement, CSSProperties, MouseEvent, useRef, useImperativeHandle, forwardRef } from "react";
import MuiCard, { CardProps } from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import { Button } from "../button";
import { Divider } from "../divider";
import { useLang } from "core/hooks";

type HeaderAction = {
    label: string;
    action: (e: MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
};

type Props = CardProps & {
    title: string;
    isConfirmDisabled?: boolean;
    width?: CSSProperties["width"];
    height?: CSSProperties["height"];
    onConfirmAction: () => void;
    onCancelAction: () => void;
    headerAction?: HeaderAction;
};

const cardContentStyles = {
    flex: 1,
    padding: 0
};

const cardActionsStyles = { justifyContent: "flex-end", gap: 1, paddingRight: 2, paddingLeft: 2 };

const cardHeaderStyles = {
    "& .MuiCardHeader-action": {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 1
    }
};

const cardStyles = ({ width, height }: CSSProperties): CSSProperties => {
    return { display: "flex", flexDirection: "column", width: width ?? "100%", height: height ?? "100%" };
};

export type CardHandle = {
    makeFocus: () => void;
};

export const Card = forwardRef<CardHandle, Props>((props: Props, ref): ReactElement => {
    const { title, isConfirmDisabled, onConfirmAction, onCancelAction, children, width, height, headerAction } = props;
    const { lang } = useLang();
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const HeaderAction = ({ label, action, isDisabled }: HeaderAction): ReactElement => (
        <Button onClick={action} disabled={isDisabled} data-testid={`btn-card-${label}`} variant="text">
            {label}
        </Button>
    );

    useImperativeHandle(ref, () => ({
        makeFocus(): void {
            if (buttonRef && buttonRef.current) {
                buttonRef.current.focus();
            }
        }
    }));

    return (
        <MuiCard
            sx={cardStyles({ width, height })}
            onKeyUp={e => {
                if (e.key === "Enter" && !isConfirmDisabled) {
                    onConfirmAction();
                }
            }}
        >
            <CardHeader
                title={title}
                titleTypographyProps={{ variant: "h6" }}
                sx={cardHeaderStyles}
                action={
                    headerAction ? (
                        <HeaderAction
                            label={headerAction.label}
                            action={headerAction.action}
                            isDisabled={headerAction.isDisabled}
                        />
                    ) : undefined
                }
            />
            <Divider />
            <CardContent sx={cardContentStyles}>{children}</CardContent>
            <Divider />
            <CardActions sx={cardActionsStyles}>
                <Button
                    ref={el => {
                        buttonRef.current = el;
                    }}
                    onClick={onConfirmAction}
                    disabled={isConfirmDisabled}
                    data-testid={`btn-ok-card-${title}`}
                    variant="text"
                >
                    {lang.ok}
                </Button>
                <Button onClick={onCancelAction} data-testid={`btn-cancel-card-${title}`} variant="text">
                    {lang.cancel}
                </Button>
            </CardActions>
        </MuiCard>
    );
});

Card.displayName = "Card";

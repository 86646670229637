import getTranslation from "core/languages/getTranslation";
import { CustomErrorParam } from "core/languages/interfaces";
import { LanguageCode } from "core/languages/LanguageOptions";

export enum SurveyImportResponseCode {
    DifferentItemTypes,
    ColumnNameNotInResponseFile,
    ValueMappingNameNotInScaleFile,
    DuplicateMappingValuesInScaleFile,
    MissingOrBadformattiingOfCompletedColumn,
    ValueInResponseFileNotInScaleFile,
    DuplicateColumnNameInSurveyFile,
    DuplicateColumnNameInValueFile,
    MultipleItemsInQuestionHaveDifferentScales,
    InValidLanguageCodeInSurveyFile,
    InValidLanguageCodeInValuesFile,
    InconsistentLangCodeSectionQuestionItemSurveyFile,
    ErrorReadingResponseFileAtRowN,
    ScaleNotFound
}

export const errorMessageParser = (
    message: string,
    lang: LanguageCode,
    extensions: { data?: { ResponseCode: string | number; Parameters: CustomErrorParam } }
): string => {
    try {
        const messageObj = JSON.parse(message);

        if ("NoSurveyResponses" in messageObj) {
            const message = getTranslation(lang).errorMessage(messageObj).error;

            return message;
        }

        return getTranslation(lang).lang.somethingWentWrong;
    } catch (_) {
        //for survey upload
        if (message.includes("Error trying to resolve field 'createSurvey'")) {
            const responseCode = extensions?.data?.ResponseCode;
            const parameters = extensions?.data?.Parameters;
            if (!parameters) {
                return getTranslation(lang).lang.internalServerError;
            }
            switch (responseCode) {
                case SurveyImportResponseCode.DifferentItemTypes:
                    return getTranslation(lang).errorMessage(parameters)[SurveyImportResponseCode.DifferentItemTypes];
                case SurveyImportResponseCode.ColumnNameNotInResponseFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.ColumnNameNotInResponseFile
                    ];
                case SurveyImportResponseCode.ValueMappingNameNotInScaleFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.ValueMappingNameNotInScaleFile
                    ];
                case SurveyImportResponseCode.DuplicateMappingValuesInScaleFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.DuplicateMappingValuesInScaleFile
                    ];
                case SurveyImportResponseCode.MissingOrBadformattiingOfCompletedColumn:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.MissingOrBadformattiingOfCompletedColumn
                    ];
                case SurveyImportResponseCode.ValueInResponseFileNotInScaleFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.ValueInResponseFileNotInScaleFile
                    ];
                case SurveyImportResponseCode.DuplicateColumnNameInSurveyFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.DuplicateColumnNameInSurveyFile
                    ];
                case SurveyImportResponseCode.DuplicateColumnNameInValueFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.DuplicateColumnNameInValueFile
                    ];
                case SurveyImportResponseCode.MultipleItemsInQuestionHaveDifferentScales:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.MultipleItemsInQuestionHaveDifferentScales
                    ];
                case SurveyImportResponseCode.InValidLanguageCodeInSurveyFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.InValidLanguageCodeInSurveyFile
                    ];
                case SurveyImportResponseCode.InValidLanguageCodeInValuesFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.InValidLanguageCodeInValuesFile
                    ];
                case SurveyImportResponseCode.InconsistentLangCodeSectionQuestionItemSurveyFile:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.InconsistentLangCodeSectionQuestionItemSurveyFile
                    ];
                case SurveyImportResponseCode.ErrorReadingResponseFileAtRowN:
                    return getTranslation(lang).errorMessage(parameters)[
                        SurveyImportResponseCode.ErrorReadingResponseFileAtRowN
                    ];
                case SurveyImportResponseCode.ScaleNotFound:
                    return getTranslation(lang).errorMessage(parameters)[SurveyImportResponseCode.ScaleNotFound];
                default:
                    return getTranslation(lang).lang.internalServerError;
            }
        }
        return getTranslation(lang).lang.internalServerError;
    }
};

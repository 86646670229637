import React, { ReactElement, useState } from "react";

import { Coordinate, RemapGroup } from "./interfaces";
import { useLang } from "core/hooks";
import { TabOptions } from "./PreviousPeriodMapped";
import CustomSelectExpandablePanel from "components/shared/SelectExpandablePanel";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { Modal } from "lib/modal";
import { Card } from "lib/card-custom";

type Props = {
    targetSurveyItemsOptions: SelectOptionExpandablePanel[];
    tabValue: TabOptions;
    disabledSurveyItems: string[];
    updateSelectOption: (id: number) => void;
    confirmRemap: () => void;
    handleClosePopover: () => void;
    anchorPoints: Coordinate;
    remapGroup: RemapGroup;
};

export const ItemMappingDialog = (props: Props): ReactElement => {
    const {
        remapGroup,
        anchorPoints,
        targetSurveyItemsOptions,
        tabValue,
        disabledSurveyItems,
        handleClosePopover,
        updateSelectOption,
        confirmRemap
    } = props;
    const { lang } = useLang();
    const [selectedId, setSelectedId] = useState<string>("");
    const [isDirty, setIsDirty] = useState(false);

    const handleSelectItem = (id: string): void => {
        setSelectedId(id);
        updateSelectOption(+id);
    };

    const handleConfirmButtonClicked = (): void => {
        if (remapGroup.targetFieldId) {
            confirmRemap();
        }
    };

    return (
        <Modal anchorPosition={anchorPoints} onClose={handleClosePopover}>
            <Card
                title={lang.editItemMapping}
                onCancelAction={handleClosePopover}
                onConfirmAction={handleConfirmButtonClicked}
                headerAction={{
                    label: lang.clearMapping,
                    action: (): void => {
                        handleSelectItem("-1");
                        setIsDirty(true);
                    },
                    isDisabled: !remapGroup.targetFieldId || selectedId === "-1"
                }}
                width={700}
                height={580}
                isConfirmDisabled={!isDirty && selectedId === ""}
            >
                <CustomSelectExpandablePanel
                    rawData={targetSurveyItemsOptions}
                    onItemSelected={handleSelectItem}
                    disabledItemsIds={disabledSurveyItems}
                    selectedId={selectedId}
                    isFocused={tabValue === TabOptions.items && remapGroup.targetFieldId ? true : false}
                    focusedItemId={remapGroup.targetFieldId ? remapGroup.targetFieldId.toString() : undefined}
                />
            </Card>
        </Modal>
    );
};

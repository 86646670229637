import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";
import { useLang } from "core/hooks";

type Props = {
    multiline?: boolean;
};

const useStyles = makeStyles(() => ({
    multiline: {
        display: "flex",
        flexDirection: "column"
    }
}));

const ShowBelowConfidentialityMessage = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" mt={3} ml={3} fontWeight="medium">
                {lang.confidentialityWarning}
            </Typography>
            {props.multiline ? (
                <div className={classes.multiline}>
                    <Typography variant="body2" mt={1.5} ml={3} mr={3}>
                        {lang.confidentialityWarningDescriptionFirst}
                    </Typography>
                    <Typography variant="body2" ml={3} mr={3}>
                        {lang.confidentialityWarningDescriptionSecond}
                    </Typography>
                </div>
            ) : (
                <Typography variant="body2" mt={1.5} ml={3} mr={3}>
                    {lang.confidentialityWarningDescription}
                </Typography>
            )}
        </>
    );
};

export default ShowBelowConfidentialityMessage;

import { CustomErrorParam } from "../interfaces/CustomErrorParam.interface";
import { ErrorMessage } from "../interfaces";
import { composeMessageHelper } from "./helper/composeMessageHelper";
import { SurveyImportResponseCode } from "./helper/errorMessageParser";

export const frenchError = (errorParam: CustomErrorParam): ErrorMessage => {
    const translation: CustomErrorParam = {
        NoSurveyResponses: `Il n'y a pas de réponses sous les campagnes sélectionnées dans l'enquête:, campagnes sélectionnées: `
    };

    return {
        error: composeMessageHelper(errorParam, translation),
        [SurveyImportResponseCode.DifferentItemTypes]: `Mélange de différents types d'éléments dans la même question. la section ${errorParam[0]}, la question ${errorParam[1]}`,
        [SurveyImportResponseCode.ColumnNameNotInResponseFile]: `Le nom de colonne ${errorParam[0]} dans le fichier Survey n'existe pas dans le fichier Response.`,
        [SurveyImportResponseCode.ValueMappingNameNotInScaleFile]: `Le nom de mappage de valeur ${errorParam[0]} dans le fichier Survey n'existe pas dans le fichier Scale.`,
        [SurveyImportResponseCode.DuplicateMappingValuesInScaleFile]: `Valeurs en double dans le même mappage ${errorParam[0]} dans le fichier Scale.`,
        [SurveyImportResponseCode.MissingOrBadformattiingOfCompletedColumn]:
            "Colonne Terminé manquante ou mauvais formatage de la colonne Terminé dans le fichier de réponse.",
        [SurveyImportResponseCode.ValueInResponseFileNotInScaleFile]: `La valeur ${errorParam[0]} dans la ligne ${errorParam[1]} du fichier de réponse n'existe pas dans le mappage de valeur ${errorParam[2]} dans le fichier Scale.`,
        [SurveyImportResponseCode.DuplicateColumnNameInSurveyFile]: `Nom de colonne en double ${errorParam[0]} dans le fichier Survey.`,
        [SurveyImportResponseCode.DuplicateColumnNameInValueFile]: `Nom de colonne en double ${errorParam[0]} dans le fichier de valeurs.`,
        [SurveyImportResponseCode.MultipleItemsInQuestionHaveDifferentScales]:
            "Plusieurs éléments d'une même question ont des échelles différentes.",
        [SurveyImportResponseCode.InValidLanguageCodeInSurveyFile]:
            "Code de langue non valide trouvé dans le fichier Survey.",
        [SurveyImportResponseCode.InValidLanguageCodeInValuesFile]:
            "Code de langue non valide trouvé dans le fichier de valeurs.",
        [SurveyImportResponseCode.InconsistentLangCodeSectionQuestionItemSurveyFile]:
            "Codes de langue incohérents dans la section/la question/le texte du fichier d'enquête.",
        [SurveyImportResponseCode.ErrorReadingResponseFileAtRowN]: `Erreur lors de la lecture du fichier de réponse à la ligne ${errorParam[0]} ${errorParam[1]}`,
        [SurveyImportResponseCode.ScaleNotFound]: `Impossible de trouver une échelle nommée ${errorParam[0]} dans le fichier Scale.`
    };
};

/**
|--------------------------------------------------
| Colors as defined in the "Style Guide.pdf"
|--------------------------------------------------
*/

//Blue
export const primaryBlue = "#006394";
export const primaryBlueLight = "#4D90C4";
export const primaryBlueDark = "#003965";

//Orange
export const primaryOrange = "#E87424";
export const primaryOrangeLight = "#FFA454";
export const primaryOrangeDark = "#AF4600";

//Semantic
export const infoColor = "#0094C8";
export const successColor = "#46B978";
export const warningColor = "#FFC107";
export const errorColor = "#DC3545";

//table
export const tableHeaderLight = "rgba(0, 0, 0, 0.04)";
export const tableHeaderWhite = "#ffffff";

// favorable - unfavorable
export const unfavorableColor = "#F48C3C";
export const neutralColor = "#D6D6D6";
export const favorableColor = "#2EACE2";
export const ignoredColor = "#F0F0F0";
export const neutralPercentageColor = "#9e9e9e";

//Text Dark Background
export const textDarkBackgroundMain = "rgba(255, 255, 255, 1)";
export const textDarkBackgroundSecondary = "rgba(255, 255, 255, 0.7)";
export const textDarkBackgroundDisabled = "rgba(255, 255, 255, 0.38)";
export const textDarkBackgroundError = "rgba(220, 53, 69, 1)";
export const textDarkBackgroundNotActive = "rgba(194, 218, 230, 1)";

export const colorRange065To100 = "#007DAB";
export const colorRange000To049 = "#D5EEF9";
export const colorTopCorrelation = "#003965";
export const colorRange050To064 = "#99CBDD";

export const textColorGrey = "rgba(0, 0, 0, .54)";

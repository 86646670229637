import { FilterParameter } from "components/filters/interfaces";
import { HeatmapValue, ResponseCount, SectionOverallValue } from "components/reports/heatmap/interface";

export type HeatmapResponse = {
    confidentialityResult: number;
    fieldName: string;
    fieldResponseCount: ResponseCount[];
    heatMaps: HeatmapValue[];
    overallItems: { itemId: number; value: number }[];
    overallResponseCount: number;
    overallSections: { sectionId: number; value: number }[];
    sectionHeatMaps: SectionOverallValue[];
    showLegend: boolean;
};

export interface HeatmapService {
    getHeatmap(
        surveyId: number,
        contactFieldId: number,
        filters: FilterParameter[],
        languageCode: string,
        compareFilters: FilterParameter[] | null,
        comparison: HeatmapComparison,
        round: boolean
    ): Promise<HeatmapResponse>;
    exportToExcel(
        surveyId: number,
        contactFieldId: number,
        filters: FilterParameter[],
        languageCode: string,
        compareFilters: FilterParameter[] | null,
        comparison: HeatmapComparison,
        swapAxes: boolean,
        showFavorable: boolean
    ): Promise<unknown>;
    getHeatmapSurveyInfo(surveyId: number, filters: FilterParameter[]): Promise<{ enablePreviousPeriod: boolean }>;
}

export enum HeatmapComparison {
    Overall,
    FilterRestricted,
    FilterUnrestricted,
    Previous
}

import { ComponentStatus } from "core/enums";

export interface AdminParticipantState {
    tabSelected: number;
    searchString: string;
    snackBar: { isOpen: boolean; message: string };
    activatedSurveyList: AdminParticipantSurveyInfo[];
    deactivatedSurveyList: AdminParticipantSurveyInfo[];
    componentStatus: ComponentStatus;
}

export interface AdminParticipantSurveyResponse {
    surveyId: number;
    name: string;
    active: boolean;
    participantCount: number;
    responseCount: number;
    createdTime: string;
    lastResponseSubmittedTime: string;
    createdByName: string;
    source: string;
}

export interface AdminParticipantSurveyInfo {
    id: number;
    name: string;
    active: boolean;
    participantCount: number;
    responseCount: number;
    createdTime: string;
    lastResponseSubmittedTime: string;
    source: string;
}

export interface AdminParticiantStatusResponse {
    errors: AdminParticiantStatusError[] | null;
    numParticipantsUpdated: number;
}

export interface AdminParticiantStatusError {
    message: string;
    type: AdminParticiantStatusErrorType;
}

export enum AdminParticiantStatusErrorType {
    OK,
    InvalidEmail,
    MissingHeader,
    MissingKeyField,
    MissingEmailField,
    MissingFile,
    MissingEmailValue,
    DuplicateEmail,
    UpdateEmailField,
    DuplicateHeader,
    InconsistentCase
}

export interface AdminParticipantsResponse {
    fields: Field[];
    participants: string[][];
    isPreviousPeriodSurvey: boolean;
}

export interface Field {
    id: number;
    name: string;
}

export interface AdminParticipantUploadStatus {
    queuePosition: number;
    status: UploadStatus;
    progress: number;
}

export enum UploadStatus {
    New,
    InProgress,
    Complete,
    Error
}

export interface ActivityLogInfoResponse {
    logs: ActivityLogInfo[];
}

export interface ActivityLogInfo {
    user: string;
    date: string;
    records: string;
}

export interface ActivityLogInfoDetails {
    Action: ActivityLogAction;
    Details: string[];
}

export enum ActivityLogAction {
    FiedlAdded,
    FieldRemoved,
    ParticipantUpdated,
    ParticipantCreated,
    ParticipantDeleted,
    ImportInitial
}

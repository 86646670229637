import { Action } from "../../../../core/interfaces";
import { SurveyEditActions } from "../actions";
import { SurveyEditState } from "../interfaces";

export const surveyDetailsReducer = (state: SurveyEditState, action: Action): SurveyEditState => {
    const { type, payload } = action;
    switch (type) {
        case SurveyEditActions.UPDATE_SELECTED_SIDEBAR:
            return {
                ...state,
                selectedSidebarItem: payload.selectedSidebarItem
            };
        case SurveyEditActions.SET_SURVEY:
            return {
                ...state,
                surveySelected: payload.surveySelected
            };
        case SurveyEditActions.SET_VALIDATION_ERROR:
            return {
                ...state,
                validationError: payload.validationError
            };
        case SurveyEditActions.SET_FOR_OPTIMIZATION:
            return {
                ...state,
                forOptimization: payload.forOptimization
            };
        case SurveyEditActions.CHANGE_LAN_SIDEBAR:
            return {
                ...state,
                selectedSidebarItem: payload.selectedSidebarItem
            };
        case SurveyEditActions.ADD_TAG:
            return {
                ...state,
                createdTags: [...state.createdTags, payload.tag]
            };
        case SurveyEditActions.DELETE_TAG:
            return {
                ...state,
                createdTags: [...state.createdTags].filter(t => t.id !== payload.tag.id)
            };
        case SurveyEditActions.ADD_ORG_TAGS:
            return {
                ...state,
                createdTags: [...state.createdTags].concat(payload.tags)
            };
        default:
            return {
                ...state
            };
    }
};

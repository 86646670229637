import React, { ReactElement } from "react";

import { ManagerPortalProvider } from "./context/ManagerPortalContext";

import OrgChartPage from "./components/OrgChartPage";

const ManagerPortal = (): ReactElement => {
    return (
        <ManagerPortalProvider>
            <OrgChartPage />
        </ManagerPortalProvider>
    );
};
export default ManagerPortal;

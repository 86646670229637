import React, { ReactElement } from "react";

import { Coordinate, ContactField, RemapGroup } from "./interfaces";
import { useLang } from "core/hooks";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { Modal } from "lib/modal";
import { Card } from "lib/card-custom";
import { Box } from "lib/box";

type Props = {
    targetContactFields: ContactField[];
    updateSelectOption: (id: number) => void;
    confirmRemap: () => void;
    handleClosePopover: () => void;
    anchorPoints: Coordinate;
    remapGroup: RemapGroup;
};

export const DemographicMappingDialog = (props: Props): ReactElement => {
    const { remapGroup, anchorPoints, targetContactFields, updateSelectOption, handleClosePopover, confirmRemap } =
        props;
    const { lang } = useLang();
    const mapToSelectOption = (arrayToMap: ContactField[]) => {
        return arrayToMap.map((item: ContactField) => {
            return { value: item.fieldId, name: item.title };
        });
    };

    const clearSurveyItemMapping = (): void => {
        updateSelectOption(-1);
    };

    const handleConfirmButtonClicked = (): void => {
        if (remapGroup.targetFieldId) {
            confirmRemap();
        }
    };

    const onSelectChange = (event: SelectChangeEvent<unknown>) => {
        event.stopPropagation();
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            updateSelectOption(+value);
        }
    };

    return (
        <Modal anchorPosition={anchorPoints} onClose={handleClosePopover}>
            <Card
                title={lang.editItemMapping}
                onCancelAction={handleClosePopover}
                onConfirmAction={handleConfirmButtonClicked}
                headerAction={{
                    label: lang.clearMapping,
                    action: clearSurveyItemMapping
                }}
                height={250}
                width={500}
            >
                <Box pt={1.5} pl={1.5} pr={1.5}>
                    <Select
                        data-TestId="select-mapping-contact-field"
                        items={mapToSelectOption(targetContactFields)}
                        onChange={onSelectChange}
                        label={lang.mapTo}
                        name={"targetContactFieldsSelect"}
                        value={remapGroup.targetFieldId ?? ""}
                    />
                </Box>
            </Card>
        </Modal>
    );
};

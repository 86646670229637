import React, { useState, ReactElement, useMemo } from "react";
import { makeStyles } from "@mui/styles";

import EmptyPage from "../../shared/EmptyPage";
import AdminLayout from "../shared/AdminLayout";
import InfiniteTable from "../../shared/infiniteTable/InfiniteTable";
import { useLang } from "core/hooks";
import { OrganizationsRoutes } from "routes/enums";
import { isStringEmptyOrSpaces, copyArrayWithObjects, sortArray } from "core/helpers";
import { Translation } from "core/languages/interfaces";
import { Column } from "../../shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "../../shared/infiniteTable/enums";
import { TableData } from "../../shared/infiniteTable/types";
import { mapTableSource } from "../../shared/infiniteTable/hooks";
import { OrganizationInfo } from "./interfaces";
import emptyOrgImg from "assets/images/emptyPages/OrganizationsEmptyState.svg";
import { Tab, Tabs } from "lib/tabs";
import { theme } from "lib/theme";
import { useOrganization } from "core/context/organization/useOrganization";

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.name,
            minWidth: 170,
            maxWidth: 470,
            width: "100%",
            sortable: true,
            order: TableSort.asc,
            cellType: CellType.link,
            linkTo: `/${OrganizationsRoutes.organizations}/${OrganizationsRoutes.editOrg}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.created,
            label: lang.dateCreated,
            width: 120,
            minWidth: 170,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.tags,
            label: lang.tags,
            minWidth: 170,
            width: "100%",
            cellType: CellType.chip
        }
    ];
};

const useStyles = makeStyles({
    listContainer: {
        height: "calc(100% - 48px)",
        borderTop: theme.border.main
    },
    tabs: {
        display: "flex",
        alignItems: "flex-end",
        height: 48,
        minHeight: 48,
        maxHeight: 48
    }
});

const orgWithSortedTags = (organizations: OrganizationInfo[]) => {
    return organizations.map((org: OrganizationInfo) => {
        return { ...org, tags: sortArray(org?.tags, "name", "asc") };
    });
};

const AdminOrganizations = (): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [tabSelected, setTabNumber] = useState(0);
    const buttonLink = `/${OrganizationsRoutes.organizations}/${OrganizationsRoutes.addOrg}`;
    const { organizations } = useOrganization();
    const [searchStr, setSearchStr] = useState("");
    const tableColumns = tableColumnsList(lang);

    const activatedOrgList = useMemo(() => {
        return orgWithSortedTags(organizations).filter((s: OrganizationInfo) => s.active);
    }, [organizations]);

    const deactivatedOrgList = useMemo(() => {
        return orgWithSortedTags(organizations).filter((s: OrganizationInfo) => !s.active);
    }, [organizations]);

    const handleSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const filterBySearchString = (orgList: OrganizationInfo[]): OrganizationInfo[] => {
        let filtered = orgList;
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (s: OrganizationInfo) =>
                    s.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.tags.some(tag => tag.name.toLowerCase().includes(searchStr.toLowerCase()))
            );
        }

        return filtered;
    };

    const getTableData = (orgList: OrganizationInfo[]): TableData[] => {
        const filteredData = filterBySearchString(orgList);

        return mapTableSource(filteredData, tableColumns);
    };

    const onTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        setTabNumber(newValue);
    };

    return (
        <AdminLayout
            searchTerm={searchStr}
            onSearchChange={handleSearchChange}
            buttonLink={buttonLink}
            buttonLabel={lang.addOrganization}
            showButton={true}
        >
            <div className={classes.tabs}>
                <Tabs
                    value={tabSelected}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={onTabChange}
                    aria-label="tabs"
                >
                    <Tab
                        label={`${lang.active} (${getTableData(activatedOrgList).length.toString()})`}
                        data-testid="tab-admin-activated-list"
                    />
                    <Tab
                        label={`${lang.deactivated} (${getTableData(deactivatedOrgList).length.toString()})`}
                        data-testid="tab-admin-deactivated-list"
                    />
                </Tabs>
            </div>
            <div className={classes.listContainer} data-testid="adminOrganizationListContainer">
                {tabSelected === 0 &&
                    (activatedOrgList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={getTableData(activatedOrgList)}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage
                            image={emptyOrgImg}
                            message={lang.noActiveOrganizations}
                            subMessage={lang.noActiveOrganizationsMessage}
                        />
                    ))}
                {tabSelected === 1 &&
                    (deactivatedOrgList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={getTableData(deactivatedOrgList)}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage
                            image={emptyOrgImg}
                            message={lang.noDeactivatedOrganizations}
                            subMessage={lang.noDeactivatedOrganizationsMessage}
                        />
                    ))}
            </div>
        </AdminLayout>
    );
};

export default AdminOrganizations;

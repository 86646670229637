import React, { ReactElement, useState, useEffect } from "react";
import MuiSnackbar, { SnackbarProps } from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";

import { IconButton } from "../icon-button";

type Props = SnackbarProps & {
    handleClose?: () => void;
    autohideOff?: boolean;
};

export const Snackbar = (props: Props): ReactElement => {
    const { handleClose, autohideOff, ...rest } = props;
    const [open, setOpen] = useState(props.open);

    const onIconClick = (_: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        if (handleClose) {
            handleClose();
        }
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <MuiSnackbar
            {...rest}
            open={open}
            autoHideDuration={autohideOff ? null : 5000}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={onIconClick}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            onClose={onIconClick}
        />
    );
};

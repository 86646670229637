import React from "react";

import MuiAvatar, { AvatarProps } from "@mui/material/Avatar";

const Avatar = (props: AvatarProps) => {
    const { children, ...rest } = props;
    return <MuiAvatar {...rest}>{children}</MuiAvatar>;
};

export { Avatar };

import { CommentCount } from "managerPortal/components/comments/interfaces";
import { SurveySectionList } from "../interface/Comment.interface";

export const getCommentItemCount = (
    commentCountMapping: CommentCount[],
    surveyItemsOptions: SurveySectionList[],
    selectedCommentQuestionId: string,
    questionId: number
): number => {
    let sectionId = "";
    surveyItemsOptions.forEach(section => {
        if (section.questions.find(q => q.questionId.toString() === selectedCommentQuestionId)) {
            sectionId = section.sectionId;
        }
    });

    if (!commentCountMapping) return 0;
    const commentSectionPercentageMapping = commentCountMapping.find(
        section => section.sectionId.toString() === sectionId.replace("S", "")
    );
    if (commentSectionPercentageMapping !== undefined) {
        const questionFound = commentSectionPercentageMapping.questions.find(q => q.questionId === questionId);
        if (questionFound !== undefined) {
            return questionFound.count;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
};

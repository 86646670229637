import { englishUI, frenchUI, spanishUI } from "./UIMessages";
import { englishError, frenchError, spanishError } from "./errorMessages";
import { CustomErrorParam } from "./interfaces/CustomErrorParam.interface";

import { Translation, ErrorMessage } from "./interfaces";

const getTranslation = (
    languageCode: string
): { lang: Translation; errorMessage: (errorParam: CustomErrorParam) => ErrorMessage } => {
    switch (languageCode) {
        case "en":
            return { lang: englishUI, errorMessage: englishError };
        case "es":
            return { lang: spanishUI, errorMessage: spanishError };
        case "fr":
            return { lang: frenchUI, errorMessage: frenchError };
        default:
            return { lang: englishUI, errorMessage: englishError };
    }
};

export default getTranslation;

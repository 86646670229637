import React, { ReactElement, FC } from "react";
import { useLang } from "core/hooks";
import { makeStyles } from "@mui/styles";

import { SnapshotLegend } from "managerPortal/components/snapshotReport/SnapshotLegend";
import { InsightLookdownByDemographic } from "api/rest/interfaces/InsightService.interface";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import StackedBar from "managerPortal/components/stackedBar/StackedBar";
import { emptyBarColor, favorableColor, neutralColor, unfavorableColor } from "managerPortal/styles/GlobalStyles";
import { darkGreyColor } from "managerPortal/styles/GlobalStyles";
import { CorrelationInfo } from "components/reports/insight/interface";
import InsightKeyDriverScoreItems from "./InsightKeyDriverScoreItems";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Tooltip } from "lib/tooltip";

type Props = {
    correlation: CorrelationInfo[];
    responseCount: number;
    keyDriverScore: InsightLookdownByDemographic[];
    selectedKeyDriverSection: string;
    handleKeyDriverSectionScoreSelected: (sectionTitle: string) => void;
    handleKeyDriverSectionScoreClick: (sectionTitle: string) => void;
    expandedKeyDriverSections: string[];
    showOrgOverall: boolean;
    customizedShowPP: boolean;
    previousPeriodLabels: string[];
    keyDriverScoreThreshold: boolean;
    selectedKeyDriver: number;
    selectedOutcome: number;
    selectedKeyItemId: number;
    updateSelectedKeyItem: (id: number) => void;
};

type InsightStyleProps = {
    numberOfSideBar: number;
};

export const useStyles = makeStyles(() => ({
    sideBarHeader: {
        display: "flex",
        borderBottom: theme.border.main,
        height: 56,
        minHeight: 56,
        width: 1040,
        minWidth: 1040,
        maxWidth: 1040
    },
    totalCount: {
        display: "flex",
        alignItems: "center",
        width: 380,
        minWidth: 380,
        maxWidth: 380,
        borderRight: theme.border.main
    },
    colorLegend: (styleProps: InsightStyleProps) => {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 641 - styleProps.numberOfSideBar * 80,
            minWidth: 641 - styleProps.numberOfSideBar * 80,
            maxWidth: 641 - styleProps.numberOfSideBar * 80,
            borderRight: theme.border.main
        };
    },
    displayHeaderBar: (styleProps: InsightStyleProps) => {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: styleProps.numberOfSideBar * 80 + 17,
            minWidth: styleProps.numberOfSideBar * 80 + 17,
            maxWidth: styleProps.numberOfSideBar * 80 + 17
        };
    },
    singleTitleDisplayBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 56,
        minHeight: 56,
        width: 79,
        maxWidth: 79,
        minWidth: 79,
        borderRight: theme.border.main,
        background: theme.palette.background.default
    },
    cellIcon: {
        width: 17,
        maxWidth: 17,
        minWidth: 17,
        height: 56,
        background: theme.palette.background.default
    },
    scrollableScores: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    KeyDriverSectionAndItem: {
        display: "flex",
        flexDirection: "column"
    },
    keyDriverSectionScore: {
        display: "flex",
        alignItems: "center",
        height: 56,
        minHeight: 56,
        maxHeight: 56
    },
    selectedKeyItemSectionTitle: {
        display: "flex",
        alignItems: "center",
        width: 380,
        minWidth: 380,
        maxWidth: 380,
        height: 56,
        minHeight: 56,
        borderRight: theme.border.main,
        borderBottom: theme.border.main,
        background: theme.palette.action.selected
    },
    keyItemSectionTitle: {
        display: "flex",
        alignItems: "center",
        width: 380,
        minWidth: 380,
        maxWidth: 380,
        height: 56,
        minHeight: 56,
        borderRight: theme.border.main,
        borderBottom: theme.border.main
    },
    keyItemSectionExpandIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 56,
        minHeight: 56,
        maxHeight: 56,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        paddingLeft: 10,
        paddingRight: 4
    },
    iconButton: {
        position: "relative",
        float: "right"
    },
    expandMore: {
        transform: "rotate(-90deg)"
    },
    expandLess: {
        transform: "rotate(180deg)"
    },
    keyDriverScoreTitleTooltip: {
        width: 330,
        maxWidth: 330,
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 500,
        fontSize: 18,
        textAlign: "left"
    },
    keyDriverScoreLegend: (styleProps: InsightStyleProps) => {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 56,
            minHeight: 56,
            maxHeight: 56,
            width: 641 - styleProps.numberOfSideBar * 80,
            minWidth: 641 - styleProps.numberOfSideBar * 80,
            maxWidth: 641 - styleProps.numberOfSideBar * 80,
            borderRight: theme.border.main,
            borderBottom: theme.border.main
        };
    },
    keyItemStackBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50%",
        width: "90%"
    },
    ppValueAndCount: (styleProps: InsightStyleProps) => {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: styleProps.numberOfSideBar * 80
        };
    },
    singleDisplayBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 56,
        minHeight: 56,
        width: 79,
        maxWidth: 79,
        minWidth: 79,
        borderRight: theme.border.main,
        borderBottom: theme.border.main
    },
    valueNA: {
        color: darkGreyColor,
        fontWeight: 500,
        fontSize: 16
    },
    valueZero: {
        color: darkGreyColor,
        fontWeight: 500,
        fontSize: 16
    },
    valuePositive: {
        color: favorableColor,
        fontWeight: 500,
        fontSize: 16
    },
    valueNegative: {
        color: unfavorableColor,
        fontWeight: 500,
        fontSize: 16
    },
    keyDriverItemScore: {
        display: "flex",
        alignItems: "center",
        minHeight: 56,
        maxHeight: 56
    },
    keyDriverScoreItemTitleTooltip: {
        width: 356,
        maxWidth: 356,
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 16,
        textAlign: "left",
        paddingLeft: 16,
        display: "-webkit-box",
        lineHeight: "1.3em",
        height: "2.6em",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        whiteSpace: "initial"
    },
    keyItemsHeader: {
        display: "flex",
        justifyContent: "space-between",
        borderTop: theme.border.main,
        borderBottom: theme.border.main,
        height: 56,
        minHeight: 56
    },
    keyItemText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    chartActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    impactActionRowText: {
        width: 161,
        height: 56,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const InsightKeyDriverScore: FC<Props> = (props): ReactElement => {
    const {
        correlation,
        responseCount,
        keyDriverScore,
        selectedKeyDriverSection,
        expandedKeyDriverSections,
        showOrgOverall,
        customizedShowPP,
        previousPeriodLabels,
        keyDriverScoreThreshold,
        selectedKeyDriver,
        selectedOutcome,
        selectedKeyItemId,
        updateSelectedKeyItem
    } = props;
    const { lang } = useLang();
    const getNumberOfSideBar = (): number => {
        let count = 1;
        if (showOrgOverall) {
            count += 1;
        }
        if (customizedShowPP && previousPeriodLabels.length > 0) {
            count += previousPeriodLabels.length;
        }
        return count;
    };
    const classes = useStyles({ numberOfSideBar: getNumberOfSideBar() });
    const getSideBarHeader = (): ReactElement => {
        const displayTitle = [] as string[];
        if (customizedShowPP && previousPeriodLabels.length > 0) {
            previousPeriodLabels.forEach(label => displayTitle.push(label));
        }
        if (showOrgOverall) {
            displayTitle.push(lang.overallSS);
        }
        displayTitle.push(lang.count);
        return (
            <div className={classes.displayHeaderBar}>
                {displayTitle.map((title, index) => (
                    <div key={index} className={classes.singleTitleDisplayBar}>
                        <Typography variant="subtitle2">{title}</Typography>
                    </div>
                ))}
                <div className={classes.cellIcon}></div>
            </div>
        );
    };

    const isKeyDriverSectionExpanded = (sectionTitle: string): boolean => {
        return expandedKeyDriverSections.find(s => s == sectionTitle) !== undefined;
    };

    const calculateTotal = (unfavorablePercent: number, neutralPercent: number, favorablePercent: number): number => {
        let total = 0;
        total = [unfavorablePercent, neutralPercent, favorablePercent].reduce(
            (a: number, b: number): number => a + b,
            0
        );
        return total;
    };

    const getKeyDriverScoreDisplayValues = (
        unfavorablePercent: number,
        neutralPercent: number,
        favorablePercent: number
    ): number[] => {
        if (calculateTotal(unfavorablePercent, neutralPercent, favorablePercent) <= 0) return [0];
        return [unfavorablePercent, neutralPercent, favorablePercent];
    };

    const getKeyDriverScoreColors = (
        unfavorablePercent: number,
        neutralPercent: number,
        favorablePercent: number
    ): string[] => {
        if (calculateTotal(unfavorablePercent, neutralPercent, favorablePercent) <= 0) return [emptyBarColor];
        return [unfavorableColor, neutralColor, favorableColor];
    };

    const getKeyDriverScorePercentage = (
        unfavorablePercent: number,
        neutralPercent: number,
        favorablePercent: number
    ): boolean => {
        return calculateTotal(unfavorablePercent, neutralPercent, favorablePercent) > 0;
    };

    const getKeyDriverScorePPAndCount = (
        ppValue: (number | null)[],
        count: number | null,
        text: string,
        overallDelta: number | null
    ): ReactElement => {
        const displayBar = [] as (number | null)[];
        if (customizedShowPP && previousPeriodLabels.length > 0) {
            ppValue.forEach(v => {
                if (v == 0) {
                    displayBar.push(0);
                } else if (v) {
                    displayBar.push(Math.round(v));
                } else {
                    displayBar.push(null);
                }
            });
        }
        if (showOrgOverall && overallDelta) {
            displayBar.push(Math.round(overallDelta));
        } else if (showOrgOverall && overallDelta == 0) {
            displayBar.push(0);
        } else if (showOrgOverall && overallDelta == null) {
            displayBar.push(null);
        }
        return (
            <>
                {displayBar.map((delta, index) => (
                    <div
                        key={index}
                        className={classes.singleDisplayBar}
                        data-testid={`insight-keydriver-score-pp-${text}-${index}`}
                    >
                        <Typography
                            variant="body2"
                            style={{ fontSize: 16, fontWeight: 500 }}
                            className={
                                delta && delta > 0
                                    ? classes.valuePositive
                                    : delta && delta < 0
                                      ? classes.valueNegative
                                      : delta == 0
                                        ? classes.valueZero
                                        : classes.valueNA
                            }
                        >
                            {getKeyDriverScorePPValue(delta)}
                        </Typography>
                    </div>
                ))}
                <div className={classes.singleDisplayBar} data-testid={`insight-keydriver-score-count-${text}`}>
                    <Typography variant={"body2"} style={{ fontWeight: 400, fontSize: 16 }}>
                        {count}
                    </Typography>
                </div>
            </>
        );
    };

    const getKeyDriverScorePPValue = (delta: number | null): string => {
        if (delta === undefined) return "n/a";
        if (delta === null) return "n/a";
        if (delta === 0) return "0";
        if (delta > 0) {
            return `+${delta}`;
        } else return delta.toString();
    };

    return (
        <>
            <div className={classes.sideBarHeader}>
                <div className={classes.totalCount}>
                    <Typography variant="h6" style={{ paddingLeft: 20 }}>
                        {lang.totalComments} - {responseCount}
                    </Typography>
                </div>
                <div className={classes.colorLegend}>
                    <SnapshotLegend lessFontSize />
                </div>
                {getSideBarHeader()}
            </div>
            <div className={classes.scrollableScores}>
                {keyDriverScore.map((s, index) => (
                    <div key={index} className={classes.KeyDriverSectionAndItem}>
                        <div className={classes.keyDriverSectionScore}>
                            <div
                                className={
                                    s.text === selectedKeyDriverSection
                                        ? classes.selectedKeyItemSectionTitle
                                        : classes.keyItemSectionTitle
                                }
                                data-testid={`insight-keydriver-score-${s.text}`}
                                onClick={(): void => {
                                    props.handleKeyDriverSectionScoreSelected(s.text);
                                }}
                            >
                                <div className={classes.keyItemSectionExpandIcon}>
                                    <IconButton
                                        onClick={(e): void => {
                                            e.stopPropagation();
                                            props.handleKeyDriverSectionScoreClick(s.text);
                                        }}
                                        data-testid={`insight-keydriver-score-button-${s.text}`}
                                        className={classes.iconButton}
                                        disableFocusRipple
                                        disableRipple
                                        edge={false}
                                        size="small"
                                    >
                                        {isKeyDriverSectionExpanded(s.text) ? (
                                            <ExpandLess className={classes.expandLess} />
                                        ) : (
                                            <ExpandMore className={classes.expandMore} />
                                        )}
                                    </IconButton>
                                </div>
                                <Tooltip title={s.text} placement={"bottom"}>
                                    <Typography
                                        variant="body1"
                                        style={{ fontWeight: 500, fontSize: 16 }}
                                        className={classes.keyDriverScoreTitleTooltip}
                                    >{`${s.text}`}</Typography>
                                </Tooltip>
                            </div>
                            <div
                                className={classes.keyDriverScoreLegend}
                                data-testid={`insight-keydriver-color-score-${s.text}`}
                            >
                                <div className={classes.keyItemStackBar}>
                                    <StackedBar
                                        values={getKeyDriverScoreDisplayValues(
                                            s.unfavorablePercent,
                                            s.neutralPercent,
                                            s.favorablePercent
                                        )}
                                        colors={getKeyDriverScoreColors(
                                            s.unfavorablePercent,
                                            s.neutralPercent,
                                            s.favorablePercent
                                        )}
                                        displayAllPercentage={getKeyDriverScorePercentage(
                                            s.unfavorablePercent,
                                            s.neutralPercent,
                                            s.favorablePercent
                                        )}
                                        percentageFontSize={16}
                                        sectionLevel
                                    />
                                </div>
                            </div>
                            <div className={classes.ppValueAndCount}>
                                {getKeyDriverScorePPAndCount(s.previousFavorable, s.count, s.text, s.surveyDelta)}
                            </div>
                        </div>
                        {expandedKeyDriverSections.includes(s.text) && (
                            <>
                                {s.itemScores
                                    .sort((s1, s2) => s2.favorablePercent - s1.favorablePercent)
                                    .map(item => (
                                        <div key={item.itemId} className={classes.keyDriverItemScore}>
                                            <div
                                                className={
                                                    item.itemId === selectedKeyItemId
                                                        ? classes.selectedKeyItemSectionTitle
                                                        : classes.keyItemSectionTitle
                                                }
                                                data-testid={`insight-keydriver-itemScore-${s.text}-${item.text}`}
                                                onClick={(): void => updateSelectedKeyItem(item.itemId)}
                                            >
                                                <Tooltip title={item.text} placement={"bottom"}>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.keyDriverScoreItemTitleTooltip}
                                                    >{`${item.text}`}</Typography>
                                                </Tooltip>
                                            </div>
                                            <div
                                                className={classes.keyDriverScoreLegend}
                                                data-testid={`insight-keydriver-item-colorLegend-${s.text}-${item.text}`}
                                            >
                                                <div className={classes.keyItemStackBar}>
                                                    <StackedBar
                                                        values={getKeyDriverScoreDisplayValues(
                                                            Math.round(item.unfavorablePercent),
                                                            Math.round(item.neutralPercent),
                                                            Math.round(item.favorablePercent)
                                                        )}
                                                        colors={getKeyDriverScoreColors(
                                                            Math.round(item.unfavorablePercent),
                                                            Math.round(item.neutralPercent),
                                                            Math.round(item.favorablePercent)
                                                        )}
                                                        displayAllPercentage={getKeyDriverScorePercentage(
                                                            Math.round(item.unfavorablePercent),
                                                            Math.round(item.neutralPercent),
                                                            Math.round(item.favorablePercent)
                                                        )}
                                                        percentageFontSize={16}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.ppValueAndCount}>
                                                {getKeyDriverScorePPAndCount(
                                                    item.previousFavorable,
                                                    null,
                                                    `${s.text}-${item.text}`,
                                                    item.surveyDelta
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </>
                        )}
                    </div>
                ))}
            </div>
            {keyDriverScore && keyDriverScore.length > 0 && (
                <div className={classes.keyItemsHeader}>
                    <div className={classes.keyItemText}>
                        <Typography variant="h6" style={{ fontSize: 16, fontWeight: 500, paddingLeft: 24 }}>
                            {lang.keyItems}
                        </Typography>
                    </div>
                    <div className={classes.chartActions}>
                        <div className={classes.impactActionRowText}>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                                {lang.profile}
                            </Typography>
                        </div>
                        <div className={classes.impactActionRowText}>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                                {lang.impact}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            {keyDriverScore && keyDriverScore.length ? (
                <InsightKeyDriverScoreItems
                    correlation={correlation}
                    keyDriverScoreThreshold={keyDriverScoreThreshold}
                    selectedKeyDriver={selectedKeyDriver}
                    selectedOutcome={selectedOutcome}
                    selectedKeyItemId={selectedKeyItemId}
                    updateSelectedKeyItem={updateSelectedKeyItem}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default InsightKeyDriverScore;

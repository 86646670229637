import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { SurveyReportlabel } from "./interfaces";
import { textColorGrey } from "core/styles/TextPalette";
import { TextField } from "lib/text-field";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";

type Props = {
    surveyReportlabel: SurveyReportlabel;
    handleChangeField: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    onBlur: (id: number) => void;
    validationReportLabelErrorMessage: { key: number; message: string };
    id: number;
    index: number;
    inputRef: (el: HTMLDivElement) => HTMLDivElement;
    handleKeyUp: (e: React.KeyboardEvent<HTMLDivElement>, index: number) => void;
    handleOnFocus: (e: React.FocusEvent, index: number) => void;
};

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: "center"
    },
    originalLabel: {
        paddingLeft: 24,
        width: 270,
        maxWidth: 270,
        minWidth: 270,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "& >p": { display: "inline", width: "95%" }
    },
    exampleLabel: {
        paddingLeft: 24,
        width: 270,
        maxWidth: 270,
        minWidth: 270,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "& >p": { display: "inline", width: "95%", color: textColorGrey }
    },
    currentLabel: {
        paddingLeft: 24,
        width: 320,
        maxWidth: 320,
        minWidth: 320
    }
}));

const SurveyReportLabelList = (props: Props): ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.originalLabel} data-testid={`originalLabel-${props.index}`}>
                <Tooltip title={props.surveyReportlabel.originalLabel} placement={"bottom"}>
                    <Typography variant="body1">{props.surveyReportlabel.originalLabel}</Typography>
                </Tooltip>
            </div>
            <div className={classes.exampleLabel} data-testid={`dataExample-${props.index}`}>
                <Tooltip title={props.surveyReportlabel.example} placement={"bottom"}>
                    <Typography variant="body1">{props.surveyReportlabel.example}</Typography>
                </Tooltip>
            </div>
            <div className={classes.currentLabel}>
                <TextField
                    inputRef={props.inputRef}
                    onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>): void => props.handleKeyUp(e, props.index)}
                    name={"currentLabel"}
                    value={props.surveyReportlabel.currentLabel}
                    onBlur={(): void => {
                        props.onBlur(props.id);
                    }}
                    inputProps={{ style: { fontSize: 16 } }}
                    hiddenLabel={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        props.handleChangeField(e, props.surveyReportlabel.id);
                    }}
                    onFocus={(e: React.FocusEvent): void => props.handleOnFocus(e, props.index)}
                    error={props.validationReportLabelErrorMessage.key === props.id}
                    helperText={
                        props.validationReportLabelErrorMessage.key === props.id
                            ? props.validationReportLabelErrorMessage.message
                            : ""
                    }
                    data-testid={`currentLabel-${props.index}`}
                />
            </div>
        </div>
    );
};

export default SurveyReportLabelList;

import React, { useState, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useLang } from "core/hooks";
import { IconButton } from "lib/icon-button";

const useStyles = makeStyles(() => ({
    editCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingLeft: 0,
        paddingRight: 0,
        height: 38,
        minHeight: 38,
        maxHeight: 38
    },
    editIcons: {
        display: "flex",
        alignItems: "center",
        gap: 4
    }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditCell = ({ row, table }: any): ReactElement => {
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const classes = useStyles();
    const { lang } = useLang();
    const meta = table.options.meta;

    const deleteSelectedRow = () => {
        meta?.deleteRow(row.index);
        setConfirmDeleteDialogOpen(false);
    };

    return (
        <>
            <div className={classes.editCell} data-testid={`participant-table-row-${row.index}-edit-cell`}>
                <div className={classes.editIcons}>
                    <IconButton size="small" onClick={(): void => setConfirmDeleteDialogOpen(true)} name="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            {confirmDeleteDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => setConfirmDeleteDialogOpen(false)}
                    open={confirmDeleteDialogOpen}
                    onConfirmationClicked={deleteSelectedRow}
                    title={lang.confirmDelete}
                    message={lang.deletingParticipant}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};

export default EditCell;

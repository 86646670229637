import React, { PropsWithChildren, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import Header from "../../../components/header/Header";

const useStyles = makeStyles(() => ({
    appContainer: {
        height: "100vh",
        display: "grid",
        gridTemplateRows: "60px calc(100% - 60px)",
        gridTemplateAreas: `
            "header"
            "content"
        `,
        overflow: "hidden"
    },
    appHeader: {
        gridArea: "header",
        minHeight: 60
    },
    appContent: {
        gridArea: "content",
        height: "100% !important",
        maxHeight: "100% !important",
        width: "100vw"
    }
}));

const Layout = (props: PropsWithChildren): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.appContainer}>
            <div id="masterLayoutHeader" className={classes.appHeader}>
                <Header />
            </div>
            <div id="masterLayoutContent" className={classes.appContent}>
                {props.children}
            </div>
        </div>
    );
};

export default Layout;

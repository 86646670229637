import React, { useMemo, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import { WordCloud as Word } from "../interface";
import ReactWordcloud, { CallbacksProp, WordEventCallback, WordToStringCallback, OptionsProp } from "react-wordcloud";
import "d3-transition";
import { BaseType, select } from "d3-selection";

type Props = {
    rawData: Word[];
    selectWordFromCloud: (word: string) => void;
    hoverOverWordCloud: (text: string, value: number) => void;
    mouseOutWordCloud: () => void;
    extraActions?: boolean;
};
export interface WordDisplay {
    text: string;
    value: number;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex"
    }
}));

export type WordCloudHandle = {
    onSearchWord: (searchString: string) => void;
};

const WordCloud = forwardRef<WordCloudHandle, Props>((props: Props, ref): JSX.Element => {
    const { rawData } = props;
    const classes = useStyles();
    const words = rawData.map(data => ({ text: data.word, value: data.count }));
    const options: OptionsProp = {
        colors: [
            "rgb(44,67,116)",
            "rgb(150,127,71)",
            "rgb(150,54,47)",
            "rgb(255,144,90)",
            "rgb(4,181,172)",
            "rgb(4,130,123)",
            "rgb(62,125,204)",
            "rgb(77,82,90)",
            "rgb(196,78,65)",
            "rgb(245,197,36)"
        ],
        deterministic: true,
        fontSizes: [24, 74],
        fontFamily: "Arial",
        padding: 1,
        rotations: 2,
        rotationAngles: [0, 0],
        transitionDuration: 0,
        enableTooltip: false
    };
    const size: [number, number] = [600, 300];

    useImperativeHandle(ref, () => ({
        onSearchWord(searchTerm: string): void {
            if (searchTerm !== "") {
                const text = select("#wordcloud")
                    .selectAll("svg text")
                    .filter(function () {
                        return select(this).text() === searchTerm.toLocaleLowerCase();
                    });
                if (text.size() !== 0) {
                    select("#wordcloud").selectAll("svg text").transition().attr("opacity", "0.3");
                    text.transition().attr("opacity", "1");
                } else {
                    select("#wordcloud").selectAll("svg text").transition().attr("opacity", "0.3");
                }
            } else {
                select("#wordcloud").selectAll("svg text").transition().attr("opacity", "1");
            }
        }
    }));

    const getCallback = (callbacks: string): WordEventCallback | WordToStringCallback | undefined => {
        if (callbacks === "onWordClick") {
            return (word: WordDisplay, event?: MouseEvent): void => {
                if (props.extraActions) {
                    select("#wordcloud").selectAll("svg text").transition().attr("opacity", "0.3");
                    if (event) {
                        const element = event.target;
                        if (element) {
                            const text = select(element as BaseType);
                            text.transition().attr("opacity", "1");
                        }
                    }
                }
                props.selectWordFromCloud(word.text);
            };
        } else if (callbacks === "onWordMouseOver") {
            return (word: WordDisplay): void => {
                props.hoverOverWordCloud(word.text, word.value);
            };
        } else if (callbacks === "onWordMouseOut") {
            return (): void => {
                props.mouseOutWordCloud();
            };
        }
        return undefined;
    };
    const callbacks: CallbacksProp = {
        onWordClick: getCallback("onWordClick"),
        onWordMouseOver: getCallback("onWordMouseOver"),
        onWordMouseOut: getCallback("onWordMouseOut"),
        getWordTooltip: (word: WordDisplay): string => `${word.text} (${word.value})`
    };

    const memoizedResult = useMemo(() => {
        return <ReactWordcloud options={options} size={size} words={words} callbacks={callbacks} />;
    }, [rawData]);

    return (
        <div className={classes.container} id="wordcloud">
            {memoizedResult}
        </div>
    );
});

WordCloud.displayName = "WordCloud";

export default WordCloud;

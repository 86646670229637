import { HeatmapDisplayValue, HeatmapValue, HeatmapRestoreValue, DemographicOptions } from "./interface";
import { emptySurveyInfo } from "components/admin/results/init";
import { initBreakdownItems } from "../responseRate/init";
import { Translation } from "core/languages/interfaces";

export const InitHeatmapValue: HeatmapValue = {
    source: "",
    targetId: -1,
    delta: -101,
    favorable: -1
};

export const initHeatmapDisplayValue: HeatmapDisplayValue = {
    source: "",
    target: "",
    showDelta: true,
    delta: -10001,
    favorable: -1,
    targetId: -1
};

export enum cellType {
    favorable = "favorable",
    unfavorable = "unfavorable",
    neutral = "neutral"
}

export const defaultCompareOption = (lang: Translation): DemographicOptions => {
    return { name: lang.overallSS, id: 0 };
};
export const defaultCompareOptionId = 0;

export const filteredOverallOption = (lang: Translation): DemographicOptions => {
    return { name: lang.overallFiltered, id: -2, shouldBeSeparted: true };
};
export const filteredOverallOptionId = -2;

export const previousPeriodOption = (lang: Translation): DemographicOptions => {
    return { name: lang.previousPeriod, id: -3, shouldBeSeparted: true };
};
export const previousPeriodOptionId = -3;

export const initCompareTarget: DemographicOptions = {
    name: "",
    id: -1
};

export enum headerAction {
    insert = "insert",
    delete = "delete"
}

export enum colorClasses {
    cell_unfav_25 = "cell_unfav_25",

    cell_unfav_50 = "cell_unfav_50",

    cell_unfav_75 = "cell_unfav_75",

    cell_unfav = "cell_unfav",
    cell_neu = "cell_neu",

    cell_fav_25 = "cell_fav_25",

    cell_fav_50 = "cell_fav_50",

    cell_fav_75 = "cell_fav_75",

    cell_fav = "cell_fav"
}

export const initHeatmapRestoreValue: HeatmapRestoreValue = {
    surveySelected: emptySurveyInfo.id,
    breakdownItems: initBreakdownItems.items,
    selectedCompareOptionID: 0,
    selectedCompareTargetID: -1,
    selectedCompareTargetName: "",
    swapAxes: true,
    compareToPrevious: false,
    showFavorable: false
};

export enum ConfidentialityResult {
    success = 0,
    belowThreshold = 1,
    tooSimilar = 2,
    compareGroupBelowThreshold = 3,
    allSubGroupsBelowThreshold = 4,
    tooFewResponsesToCorrelate = 5
}

import { CompassContextActions } from "../actions";
import { CompassContextState } from "../interfaces";
import { Action } from "../../interfaces";

export const CompassContextReducer = (state: CompassContextState, action: Action): CompassContextState => {
    const { type, payload } = action;
    switch (type) {
        case CompassContextActions.UPDATE_STATE:
            return {
                ...state,
                ...payload
            };
        case CompassContextActions.UPDATE_LANGUAGE:
            return {
                ...state,
                language: {
                    languageCode: payload.languageCode,
                    translation: payload.lang,
                    errorMessage: payload.errorMessage
                }
            };
        case CompassContextActions.SET_SURVEY_LIST:
            return {
                ...state,
                surveys: payload.surveys
            };
        case CompassContextActions.SET_LOADING:
            return {
                ...state,
                isLoading: payload.isLoading
            };
        default:
            return state;
    }
};

import React, { useState, ReactElement, useEffect } from "react";

import { makeStyles } from "@mui/styles";

import { useLang, useOrgChartService } from "core/hooks";
import { CreateTagDialog } from "./CreateTagDialog";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Tag } from "components/admin/tags/interface";
import { sortArray } from "core/helpers";
import { ComponentStatus } from "core/enums";
import Error500 from "components/errorPage/Error500";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { List, ListItem, ListItemText } from "lib/list";

const useStyles = makeStyles(() => ({
    adminTagsList: {
        marginLeft: theme.spacing(3),
        border: theme.border.main,
        maxWidth: 360,
        height: 700,
        minHeight: 700,
        maxHeight: 700,
        padding: 0,
        overflowY: "auto"
    },
    adminTagsListItem: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: 50,
        "&:hover": {
            cursor: "pointer",
            background: theme.palette.action.hover
        }
    },
    selectedAdminTagsListItem: {
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: 50,
        "&:hover": {
            cursor: "pointer",
            background: theme.palette.action.hover
        }
    },
    adminTagsListItemText: {
        maxWidth: 300
    },
    adminTagInfoTitle: {
        fontWeight: theme.typography.fontWeightBold
    }
}));

const Tags = (): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const orgChartService = useOrgChartService();
    const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [status, setStatus] = useState<ComponentStatus>(ComponentStatus.idle);
    const [tags, setTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [selectedTag, setSelectedTag] = useState<Tag>({ name: "", id: -1 });

    const sortTagsArray = <T extends Tag>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");

    const addTagOnClick = (): void => {
        setCreateDialogOpen(true);
    };
    const deleteTagOnClick = (): void => {
        setDeleteDialogOpen(true);
    };
    const handleCreatetDialogClose = (): void => {
        setCreateDialogOpen(false);
    };
    const handleCreateTag = (tagName: string): void => {
        if (tagName) {
            orgChartService
                .addTags(tagName.trim())
                .then(res => {
                    if (res) {
                        getAllTags();
                        setCreateDialogOpen(false);
                    }
                })
                .catch(() => {
                    setStatus(ComponentStatus.error);
                });
        }
    };
    const handleDeleteDialogClose = (): void => {
        setDeleteDialogOpen(false);
    };
    const handleDeleteTag = (): void => {
        if (selectedTag && selectedTag.id !== -1) {
            orgChartService
                .deleteTag(selectedTag.id)
                .then(res => {
                    if (res) {
                        getAllTags();
                        setDeleteDialogOpen(false);
                    }
                })
                .catch(() => {
                    setStatus(ComponentStatus.error);
                });
        }
    };
    const handleTagSelected = (id: number, name: string): void => {
        const selectedTag = { name: name, id: id };
        setSelectedTag(selectedTag);
    };
    const getAllTags = async (): Promise<void> => {
        const tags = await orgChartService.getTags();
        setTags(tags);
        setSelectedTag({ name: "", id: -1 });
    };

    useEffect(() => {
        getAllTags();
    }, []);

    if (status === ComponentStatus.error) {
        return (
            <Box height="100%">
                <Error500 />
            </Box>
        );
    }
    return (
        <Box height="100%">
            <Box display="flex" justifyContent="space-between" padding={3}>
                <Typography variant="h6">{lang.benchmarkTags}</Typography>
                <Box display="flex" gap={2}>
                    <Button onClick={addTagOnClick} data-testid="btn-add-tag">
                        {lang.addTag}
                    </Button>
                    <Button onClick={deleteTagOnClick} data-testid="btn-delete-tag" disabled={selectedTag.id === -1}>
                        {lang.deleteTag}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box padding={3}>
                <Typography className={classes.adminTagInfoTitle}>{lang.createdTags}</Typography>
            </Box>
            <List aria-label="tagList" className={classes.adminTagsList} id="tag-list">
                {sortTagsArray(tags).map((tag: Tag) => (
                    <ListItem
                        key={tag.id}
                        className={
                            selectedTag.id === tag.id ? classes.selectedAdminTagsListItem : classes.adminTagsListItem
                        }
                        onClick={(): void => handleTagSelected(tag.id, tag.name)}
                        id={`tag-item-${tag.name}`}
                    >
                        <ListItemText primary={tag.name} className={classes.adminTagsListItemText} />
                    </ListItem>
                ))}
            </List>
            {isCreateDialogOpen && (
                <CreateTagDialog
                    tags={tags}
                    dialogTitle={lang.createNewTag}
                    isOpen={isCreateDialogOpen}
                    onClose={handleCreatetDialogClose}
                    onSubmitCallback={handleCreateTag}
                />
            )}
            {isDeleteDialogOpen && (
                <ConfirmationDialog
                    open={isDeleteDialogOpen}
                    onCancelClicked={handleDeleteDialogClose}
                    onConfirmationClicked={handleDeleteTag}
                    title={`${lang.deleteTag} "${selectedTag.name}"`}
                    message={lang.thisActionWillPermanentlyRemoveTag}
                    confirmButtonText={lang.delete}
                    cancelButtonVariant="text"
                />
            )}
        </Box>
    );
};

export default Tags;

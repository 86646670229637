import React, { ReactElement, useState, useEffect, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { AutoSizer } from "react-virtualized";

import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { useAutoSizerStyles } from "core/styles";
import { nameToIdentifier } from "core/helpers";
import FlagIcon from "@mui/icons-material/Flag";
import { theme } from "lib/theme";

type Props = {
    rawData: SelectOptionExpandablePanel[];
    disabledItemsIds?: SelectOptionExpandablePanel["id"][];
    disabledQuestionOrSectionIds?: SelectOptionExpandablePanel["id"][];
    onItemSelected: (id: SelectOptionExpandablePanel["id"], name: string) => void;
    selectedId?: string | number;
    isCondensed?: boolean;
    isFocused?: boolean;
    focusedItemId?: SelectOptionExpandablePanel["id"];
    isShowingFlag?: boolean;
};

type PanelData = {
    builtId: string;
    parentId: string;
    level: number;
    title: string;
    id: SelectOptionExpandablePanel["id"];
    isDefault?: boolean;
};

type rowStyleProps = {
    isExpanded: boolean;
    level: number;
    canExpand: boolean;
    isDisabled: boolean;
    isFocused: boolean;
};

const useRowStyles = makeStyles(() => ({
    expandMoreIcon: (styleProps: rowStyleProps) => ({
        transform: styleProps.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        marginRight: theme.spacing(),
        color: theme.palette.action.active
    }),
    rowStyle: (styleProps: rowStyleProps) => ({
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: styleProps.canExpand
            ? theme.spacing((styleProps.level + 1) * 2)
            : theme.spacing((styleProps.level + 1) * 4),
        paddingRight: 16,
        display: "flex",
        "&:hover": {
            background: styleProps.isFocused
                ? "inherit"
                : styleProps.isDisabled
                  ? "inherit"
                  : theme.palette.action.hover,
            cursor: styleProps.isDisabled ? "inherit" : "pointer"
        },
        borderBottom: styleProps.isFocused ? theme.border.bold : theme.border.main,
        borderLeft: styleProps.isFocused ? theme.border.bold : "none",
        borderRight: styleProps.isFocused ? theme.border.bold : "none",
        borderTop: styleProps.isFocused ? theme.border.bold : "none"
    }),
    cellName: {
        display: "flex",
        alignItems: "center"
    },
    typographyName: (styleProps: rowStyleProps) => ({
        color: styleProps.isDisabled ? theme.palette.action.disabled : "inherit",
        fontSize: 16
    }),
    disabledTypographyName: {
        color: theme.palette.action.disabled,
        fontSize: 16
    },
    selected: {
        background: theme.palette.action.selected
    },
    sectionFlag: {
        color: theme.palette.primary.main,
        paddingLeft: 6
    },
    itemFlag: {
        color: theme.palette.primary.main,
        paddingLeft: 6,
        paddingRight: 6
    }
}));

const mapRawData = (
    obj: SelectOptionExpandablePanel[],
    level = 0,
    parentId = "",
    data: PanelData[] = []
): PanelData[] => {
    obj.forEach((item: SelectOptionExpandablePanel, index: number) => {
        const builtId: string = item.title.toLowerCase().replace(/\s/g, "") + parentId + level + index;
        const builtItem: PanelData = {
            builtId,
            parentId,
            level,
            title: item.title,
            id: item.id,
            isDefault: item.isDefault
        };

        data.push(builtItem);

        if (item.subNode && item.subNode.length > 0) {
            mapRawData(item.subNode, level + 1, builtId, data);
        }
    });

    return data;
};
const filterItemsToRender = (_openedPanels: string[], _mainPanelData: PanelData[]): PanelData[] => {
    return _mainPanelData.filter((item: PanelData) => {
        return _openedPanels.includes(item.builtId) || item.level === 0 || _openedPanels.includes(item.parentId);
    });
};

const getOpenedPanels = (_array: PanelData[], parentId: string, openedItems: string[] = []): string[] => {
    const itemFound = _array.find((item: PanelData) => item.builtId === parentId);
    if (itemFound) {
        openedItems.push(itemFound.builtId);
        getOpenedPanels(_array, itemFound.parentId, openedItems);
    }

    return openedItems;
};

const SelectExpandablePanel = (props: Props): ReactElement => {
    const [mainPanelData, setMainPanelData] = useState<PanelData[]>(mapRawData(props.rawData));
    const [openedPanels, setOpenedPanels] = useState<string[]>([]);
    const autoSizerClass = useAutoSizerStyles();
    const [renderPanelData, setRenderPanelData] = useState(filterItemsToRender(openedPanels, mainPanelData));
    const [showingFlag] = useState<boolean>(props.isShowingFlag ? true : false);
    const listRef = useRef(null) as React.RefObject<List<unknown>>;

    const defaultRowHeight = props.isCondensed ? 48 : 56;

    useEffect(() => {
        if (props.isFocused && props.focusedItemId) {
            handleAutoFocus(props.focusedItemId);
        }
    }, []);

    useEffect(() => {
        const updateRenderPanelData = filterItemsToRender(openedPanels, mainPanelData);
        setRenderPanelData(updateRenderPanelData);
    }, [openedPanels, mainPanelData]);

    useEffect(() => {
        const mappedRawData = mapRawData(props.rawData);
        setMainPanelData(mappedRawData);

        return (): void => {
            setMainPanelData([]);
        };
    }, [props.rawData]);

    useEffect(() => {
        const mappedRawData = mapRawData(props.rawData);

        const itemFound = mappedRawData.find((panel: PanelData) => panel.id === props.selectedId?.toString());
        if (itemFound) {
            const openedPanels = getOpenedPanels(mappedRawData, itemFound.parentId);
            openedPanels.length && setOpenedPanels(openedPanels);
        }
    }, [props.selectedId, props.rawData]);

    const handleExpand = (builtId: string, e: React.MouseEvent): void => {
        e.stopPropagation();
        const foundId = openedPanels.find((itemOpen: string) => builtId === itemOpen);
        const foundItem = renderPanelData.find(r => r.builtId === foundId);
        if (foundId) {
            if (foundItem?.level === 0) {
                const relatedItem = mainPanelData.filter(m => m.parentId === foundId);
                setOpenedPanels(
                    openedPanels.filter((itemOpen: string) => {
                        return relatedItem.findIndex(r => r.builtId === itemOpen) < 0 && builtId !== itemOpen;
                    })
                );
            } else {
                setOpenedPanels(openedPanels.filter((itemOpen: string) => builtId !== itemOpen));
            }
        } else {
            const previousCount = listRef.current?.props?.itemCount as number;
            setOpenedPanels([...openedPanels, builtId]);
            const index = renderPanelData.findIndex(data => data.builtId === builtId);
            setTimeout(() => {
                const recentCount = listRef.current?.props?.itemCount as number;
                const listScrollOffset = recentCount - previousCount;
                if (listRef && listRef.current && listScrollOffset > 0) {
                    listRef.current.scrollToItem(index + listScrollOffset, "smart");
                }
            }, 200);
        }
    };

    const handleAutoFocus = (id: SelectOptionExpandablePanel["id"]): void => {
        //find parent and siblings
        //open parent
        let conutOfSection = 0;
        let totalCount = 0;
        let sectionId = "";

        const data = [...props.rawData];

        const renderedItem = mainPanelData.find(d => d.id === id);
        if (renderedItem) {
            loop1: for (let s = 0; s < data.length; s++) {
                conutOfSection++;
                let itemCount = 0;
                if ("subNode" in data[s]) {
                    const questions = data[s].subNode!;
                    for (let q = 0; q < questions.length; q++) {
                        itemCount++;
                        if (questions[q].id === id) {
                            sectionId = data[s].id;
                            totalCount = conutOfSection + itemCount - 1;
                            //found the section that the item belongs to, breaking the outside loop.
                            break loop1;
                        }
                    }
                }
            }

            mainPanelData.forEach(d => {
                if (d.builtId === renderedItem.parentId || d.id === sectionId) {
                    if (openedPanels.length !== 2 && !openedPanels.find(o => o === d.builtId)) {
                        setOpenedPanels([...openedPanels, d.builtId]);
                    }
                }
            });
            if (listRef.current) {
                listRef.current.scrollTo(totalCount * defaultRowHeight);
            }
        }
    };

    const handleItemSelected = (builtId: string, e: React.MouseEvent): void => {
        e.stopPropagation();
        const foundItem = mainPanelData.find((item: PanelData) => builtId === item.builtId);
        props.onItemSelected(foundItem!.id, foundItem!.title);
    };

    const Row = ({
        index,
        style,
        width
    }: {
        index: number;
        style: React.CSSProperties;
        width: number;
    }): ReactElement => {
        const item = renderPanelData[index];
        const isExpanded = openedPanels.some((builtId: string) => builtId === item.builtId);
        const canExpand = mainPanelData.some((panel: PanelData) => panel.parentId === item.builtId);
        const isDisabled = props.disabledItemsIds?.some((id: string) => id === item.id.toString());
        const questionOrSectionDisabled = props.disabledQuestionOrSectionIds?.some(
            (id: string) => id === item.id.toString()
        );

        let totalWidth = width - 32 - (item.level + 1) * 2 * 8;
        if (!canExpand) {
            totalWidth = width - 8 - (item.level + 1) * 4 * 8;
        }
        //10px => is the width of one letter
        const maxLetters = totalWidth / 10;

        const rowClasses = useRowStyles({
            isExpanded,
            level: item.level,
            canExpand,
            isDisabled: isDisabled ?? false,
            isFocused: props.focusedItemId !== undefined && item.id === props.focusedItemId
        });

        return isDisabled ? (
            <div className={rowClasses.rowStyle} style={style}>
                <div className={rowClasses.cellName}>
                    <Typography variant="body2" style={{ fontSize: "16px" }} className={rowClasses.typographyName}>
                        {item.title}
                    </Typography>
                </div>
            </div>
        ) : (
            <div
                className={
                    item.id === props.selectedId?.toString()
                        ? `${rowClasses.selected} ${rowClasses.rowStyle}`
                        : rowClasses.rowStyle
                }
                style={style}
                onClick={
                    canExpand
                        ? (e: React.MouseEvent): void => handleExpand(item.builtId, e)
                        : (e: React.MouseEvent): void => handleItemSelected(item.builtId, e)
                }
                data-parentid={nameToIdentifier(item.parentId.replace((item.level - 1).toString(), ""))}
                data-testid={item.title}
            >
                <div className={rowClasses.cellName}>
                    {canExpand && <ExpandMoreIcon className={rowClasses.expandMoreIcon} />}
                    <TruncateDisplay
                        maxLabelLength={item.level === 0 ? maxLetters * 2 - 10 : maxLetters * 2}
                        title={item.title}
                    >
                        <Typography
                            variant="body2"
                            style={{ fontSize: "16px" }}
                            className={
                                questionOrSectionDisabled
                                    ? rowClasses.disabledTypographyName
                                    : rowClasses.typographyName
                            }
                        >
                            {item.title}
                        </Typography>
                    </TruncateDisplay>
                    {showingFlag && !item.isDefault && (
                        <FlagIcon
                            fontSize="small"
                            className={item.level === 0 ? rowClasses.sectionFlag : rowClasses.itemFlag}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <AutoSizer className={autoSizerClass.autoSizer}>
            {({ height, width }): JSX.Element => (
                <List
                    height={height}
                    itemCount={renderPanelData.length}
                    itemSize={defaultRowHeight}
                    width={width}
                    ref={listRef}
                >
                    {({ index, style }): ReactElement => Row({ index, style, width })}
                </List>
            )}
        </AutoSizer>
    );
};

export default SelectExpandablePanel;

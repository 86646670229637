/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// This must be matched to the CardElementType enumeration in the TalentGate site.

export const DEFAULT_CARD_WIDTH = 280;

export const HIDE_ELEMENTS = {
    scoresLessThan: 5,
    correlationLessThan: 30
};

export const USER_ROLE = {
    ADMIN: { value: 1, propertyName: "admin", propertyDisplayName: "" },
    GENERAL_USER: {
        value: 2,
        propertyName: "associated_general_users",
        propertyDisplayName: "associated_general_users_names"
    },
    CREATION_RIGHT: {
        value: 3,
        propertyName: "associated_creation_rights_users",
        propertyDisplayName: "associated_creation_rights_users_name"
    },
    SUPER_USER: {
        value: 4,
        propertyName: "associated_super_user",
        propertyDisplayName: "associated_super_user_name"
    },
    TALENTMAP_ADMIN: { value: 5, propertyName: "talentmap_admin", propertyDisplayName: "" }
};

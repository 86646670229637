import React, { useState, ReactElement } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";

import ConfirmationDialog from "components/shared/ConfirmationDialog";
import DrawerHierarchyMenu from "./DrawerHierarchyMenu";
import TruncateDisplay from "../shared/TruncateDisplay";
import { useLang, useSelectedHierarchy, useVisibleNodes } from "core/hooks";
import { Hierarchy } from "../hierarchy/interfaces";

import { Chip } from "lib/chip";
import { ListItemButton, ListItemIcon, ListItemText } from "lib/list";
import { IconButton } from "lib/icon-button";
import { Typography } from "lib/typography";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    listItem: {
        height: 46
    },
    listItemHead: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    },
    listItemHierarchy: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingRight: "0px !important"
    },
    fieldsContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 16,
        boxSizing: "border-box"
    },
    chipIsDefault: {
        height: 20,
        alignSelf: "flex-end"
    },
    selectStyle: {
        "& svg": {
            display: "none"
        }
    }
}));

type HierarchyMenuProps = {
    hierarchy: Hierarchy;
    onSetDefaultHierarchy: (id: number) => void;
    onDeleteHierarchy: (id: number) => void;
    onRenameHierarchy: (id: number) => void;
};

const HierarchyMenu = (props: HierarchyMenuProps): ReactElement => {
    const { hierarchy, onSetDefaultHierarchy, onDeleteHierarchy, onRenameHierarchy } = props;
    const { lang } = useLang();
    const [hierarchyMenuAnchorElement, setHierarchyMenuAnchorElement] = useState<HTMLElement | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const { user } = useUser();

    const userCanEdit = user.isSiteAdmin || user.isTalentMapAdmin || !hierarchy.IsDefault;

    const handleDeleteDialogClose = (): void => {
        setConfirmDeleteOpen(false);
        setHierarchyMenuAnchorElement(null);
    };

    const handleDeleteHierarchyConfirmed = (hierarchyId: number): void => {
        onDeleteHierarchy(hierarchyId);
        setConfirmDeleteOpen(false);
    };

    if (!userCanEdit) return <></>;

    return (
        <>
            <ListItemIcon
                onClick={(event: React.MouseEvent): void => {
                    setHierarchyMenuAnchorElement(event.target as HTMLElement);
                }}
                aria-haspopup="true"
            >
                <IconButton>
                    <MoreVertIcon />
                </IconButton>
            </ListItemIcon>
            {hierarchyMenuAnchorElement && (
                <DrawerHierarchyMenu
                    handleDeleteClicked={(): void => setConfirmDeleteOpen(true)}
                    handleMakeDefaultClicked={(): void => onSetDefaultHierarchy(hierarchy.HierarchyId)}
                    hierarchyMenuAnchorElement={hierarchyMenuAnchorElement}
                    handleCloseHierarchyMenu={(): void => setHierarchyMenuAnchorElement(null)}
                    handleRenameHierarchy={(): void => onRenameHierarchy(hierarchy.HierarchyId)}
                />
            )}
            <ConfirmationDialog
                onCancelClicked={handleDeleteDialogClose}
                open={confirmDeleteOpen}
                onConfirmationClicked={(): void => handleDeleteHierarchyConfirmed(hierarchy.HierarchyId)}
                title={lang.deleteThisItem}
                message={lang.deleteCannotBeReversed}
                confirmButtonText={lang.delete}
                cancelButtonVariant="text"
            />
        </>
    );
};

type HierarchyListProps = {
    hierarchy: Hierarchy;
    onSetDefaultHierarchy: (id: number) => void;
    onDeleteHierarchy: (id: number) => void;
    onRenameHierarchy: (id: number) => void;
    onSelectHierarchy: (event: React.MouseEvent) => void;
};

const HierarchyList = (props: HierarchyListProps): ReactElement => {
    const { hierarchy, onSetDefaultHierarchy, onDeleteHierarchy, onSelectHierarchy, onRenameHierarchy } = props;
    const { selectedHierarchy } = useSelectedHierarchy();
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const { visibleNodes } = useVisibleNodes();

    return (
        <ListItemButton
            className={classes.listItemHierarchy}
            selected={selectedHierarchy.HierarchyId === hierarchy.HierarchyId}
            onClick={(event: React.MouseEvent): void => {
                onSelectHierarchy(event);
            }}
        >
            <div className={classes.listItemHead}>
                <TruncateDisplay title={hierarchy.Name} maxLabelLength={25} placement="bottom-end">
                    <ListItemText primary={hierarchy.Name} />
                </TruncateDisplay>
                <HierarchyMenu
                    hierarchy={hierarchy}
                    onSetDefaultHierarchy={onSetDefaultHierarchy}
                    onDeleteHierarchy={onDeleteHierarchy}
                    onRenameHierarchy={onRenameHierarchy}
                />
            </div>
            <div className={classes.fieldsContainer} onClick={onSelectHierarchy}>
                <div>
                    {user.isRestricted && (
                        <TruncateDisplay title={visibleNodes.tree.title} maxLabelLength={25} placement="bottom-end">
                            <Typography variant="body2">{visibleNodes.tree.title}</Typography>
                        </TruncateDisplay>
                    )}
                    {hierarchy.Fields.map((field: { fieldId: number; name: string }, index: number) => (
                        <Typography key={index} variant="body2" color="textSecondary">
                            {field.name}
                        </Typography>
                    ))}
                </div>
                {!user.isRestricted && hierarchy.IsDefault && (
                    <Chip className={classes.chipIsDefault} label={lang.default} color="primary" variant="outlined" />
                )}
            </div>
        </ListItemButton>
    );
};

export default HierarchyList;

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "../../core/hooks";
import { TextField } from "lib/text-field";

type Props = {
    onSearchChange: (str: string) => void;
    searchTerm: string;
    width?: number;
    margin?: boolean;
};

const useStyles = makeStyles(() => ({
    searchContainer: (props: Props) => ({
        width: props.width ? props.width : props.margin ? "calc(100% - 32px)" : "100%",
        margin: props.margin ? "8px 16px 8px 16px" : "0px 0px 0px 0px"
    })
}));

const Search = (props: Props): ReactElement => {
    const classes = useStyles(props);
    const { lang } = useLang();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.onSearchChange(event.target.value.toString());
    };

    return (
        <div className={classes.searchContainer}>
            <TextField
                onChange={handleOnChange}
                value={props.searchTerm}
                data-testid="text-field-search"
                label={lang.search}
                fullWidth
            />
        </div>
    );
};

export default Search;

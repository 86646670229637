import { makeStyles } from "@mui/styles";
import { theme } from "lib/theme";
import { whiteBackground } from "lib/palette";
import { orangeColor } from "managerPortal/styles/GlobalStyles";

export const useCommentAskStyles = makeStyles(() => ({
    ask: {
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        overflowY: "hidden"
    },
    askHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: theme.border.main,
        height: 48,
        minHeight: 48,
        maxHeight: 48
    },
    askConversations: {
        display: "flex",
        flexDirection: "column",
        overflowY: "auto"
    },
    askInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto",
        borderTop: theme.border.main,
        borderLeft: theme.border.main,
        background: whiteBackground
    },
    askInputWrapper: {
        width: "96%"
    },
    pivotGridWrapper: {
        "& >p": {
            fontWeight: 400,
            marginLeft: 30,
            marginTop: 30
        }
    },
    adornedEndStyle: {
        paddingRight: "0px !important",
        backgroundColor: "white"
    },
    root: {
        height: "46px",
        backgroundColor: `${whiteBackground} !important`,
        marginTop: "16px",
        marginBottom: "16px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${orangeColor} !important`
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${orangeColor} !important`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${orangeColor} !important`
        }
    },
    highlightWord: {
        backgroundColor: "#7BEDA7"
    }
}));

import React from "react";

import MuiIcon, { IconProps } from "@mui/material/Icon";

const Icon = (props: IconProps) => {
    const { children, ...rest } = props;
    return <MuiIcon {...rest}>{children}</MuiIcon>;
};

export { Icon };

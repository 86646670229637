import { makeStyles } from "@mui/styles";

import type { HeatmapStyleProps } from "./types";
import { theme } from "lib/theme";

export const useHeatmapAxisGridStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        position: "relative"
    },
    tableContainer: {
        width: "100%",
        overflow: "auto",
        height: "calc(100% - 73px)",
        zIndex: 11,
        position: "absolute",
        top: 0,
        left: 0
    },
    gridTable: (styleProps: HeatmapStyleProps) => {
        return {
            height: styleProps.height + 300,
            width: styleProps.width,
            borderCollapse: "collapse",
            display: "block",
            whiteSpace: "nowrap",
            position: "absolute",
            top: 0,
            left: 0
        };
    },
    rowHeaderCell: {
        padding: theme.spacing(1),
        width: 322,
        maxWidth: 322,
        minWidth: 322,
        whiteSpace: "normal",
        boxSizing: "border-box"
    },
    rowHeaderCell_overall: {
        padding: theme.spacing(1),
        width: 322,
        minWidth: 272,
        backgroundColor: theme.palette.background.default,
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 500
        }
    },
    thead: {
        position: "relative",
        overflowX: "auto",
        width: "100%",
        height: 220,
        overflowY: "scroll",
        whiteSpace: "normal"
    },
    tbody: {
        position: "relative",
        width: "100%",
        overflowY: "auto",
        overflowX: "auto",
        whiteSpace: "normal"
    },
    tableRow: {
        boxSizing: "border-box",
        height: 50,
        border: theme.border.main,
        borderLeft: "none",
        borderRight: "none !important"
    },
    tableRow_th: {
        height: 220,
        verticalAlign: "bottom",
        textAlign: "left",
        lineHeight: 1,
        zIndex: 15,
        position: "sticky",
        top: 0
    },
    tableRow_th_vertical: (props: HeatmapStyleProps) => {
        return {
            height: props.isScrollingDown ? 271 : 270,
            boxSizing: "border-box",
            verticalAlign: "bottom",
            borderRight: theme.border.main,
            backgroundColor: "white !important",
            textAlign: "left",
            lineHeight: 1,
            zIndex: 15,
            position: "sticky",
            top: -1
        };
    },
    tableRow_th_div: {
        float: "left",
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        width: 50
    },
    tableRow_th_div_vertical: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        backgroundColor: "white",
        width: 50
    },
    tableRow_th_vertical_res: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        backgroundColor: "white",
        width: 50,
        borderRight: "none"
    },
    tableAction: {
        borderRight: theme.border.main,
        position: "sticky"
    },
    tableRow_th_div_span: {
        borderBottom: theme.border.main,
        width: 280,
        transformOrigin: "bottom left",
        transform: "translateX(70px) rotate(-45deg)",
        "& > p": { display: "inline" },
        "& > button": { display: "inline" },

        backgroundColor: "white"
    },
    tableRow_th_div_span_vertical: {
        display: "block",
        paddingLeft: 8,
        width: 250,
        transformOrigin: "bottom left",
        transform: "translateX(38px) rotate(270deg)",
        "& >p": { display: "inline", fontWeight: 400, width: "100%", height: 30 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    tableRow_th_div_span_horizontal: {
        display: "block",
        width: 270,
        paddingLeft: "2px",
        paddingTop: "6px",
        "& >p": { display: "inline", width: "100%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    iconButton_horizontal_wraper: {
        display: "block"
    },
    rowHeaderCell_wraper: {
        width: 306
    },
    tableRow_th_div_span_horizontal_response: {
        display: "flex",
        width: 300,
        "& >p": { width: "95%", fontWeight: 400 },
        backgroundColor: theme.palette.background.default
    },
    tableRow_th_div_span_details_horizontal: {
        paddingLeft: 8,
        display: "flex",
        width: 290,
        "& >p": { width: "95%", color: "grey" }
    },
    tableRow_th_div_span_details_horizontal_left: (styleProps: HeatmapStyleProps) => {
        return {
            display: "flex",
            width: styleProps.isPreviousPeriodSelected ? 300 : 320,
            "& >p": { width: "95%", color: "grey" }
        };
    },
    iconButton: {
        position: "relative",
        transform: "rotate(90deg)"
    },
    iconButton_horizontal: {
        position: "relative",
        float: "right"
    },
    dataCell: {
        boxSizing: "border-box",
        height: 51,
        width: 50,
        textAlign: "center",
        border: theme.border.main
    },
    dataCellSection: {
        boxSizing: "border-box",
        height: 50,
        width: 50,
        textAlign: "center",
        border: theme.border.main,
        "& >p": {
            fontWeight: 500
        }
    },
    cell_overall: {
        backgroundColor: theme.palette.background.default,
        "& >p": {
            fontWeight: 500
        }
    },
    cell_percentage: {
        backgroundColor: theme.palette.background.default,
        "& >p": { fontWeight: 500 }
    },
    dataCell_tooltip: { color: "white" },
    cell_response: {
        backgroundColor: theme.palette.background.default,
        "& >p": { fontWeight: 400 }
    },
    cell_unfav_25: {
        backgroundColor: "rgba(244,140,60,0.25)"
    },
    cell_unfav_50: {
        backgroundColor: "rgba(244,140,60,0.5)"
    },
    cell_unfav_75: {
        backgroundColor: "rgba(244,140,60,0.75)"
    },
    cell_unfav: {
        backgroundColor: "rgb(244,140,60)"
    },
    cell_neu: {
        backgroundColor: "#FFFFFF"
    },
    cell_fav_25: {
        backgroundColor: "rgba(46,172,225,0.25)"
    },
    cell_fav_50: {
        backgroundColor: "rgba(46,172,225,0.5)"
    },
    cell_fav_75: {
        backgroundColor: "rgba(46,172,225,0.75)"
    },
    cell_fav: {
        backgroundColor: "rgb(46,172,225)"
    },
    floatingRowHeader: {
        width: 322,
        position: "sticky",
        left: 0,
        backgroundColor: "white",
        borderRight: theme.border.main,
        borderBottom: theme.border.main,
        zIndex: 30
    },
    floatingOverallRowContainer: (styleProps: HeatmapStyleProps) => {
        return {
            position: "sticky",
            marginLeft: 322,
            top: 270,
            zIndex: 20,
            width: styleProps.floatingRowWidth,
            display: "flex",
            height: 50
        };
    },
    fixedOverallRes_horizontal: (styleProps: HeatmapStyleProps) => {
        return {
            position: "fixed",
            left: 2,
            top: styleProps.showFilterTags ? 526 : 476,
            zIndex: 40,
            width: 375,
            display: "flex",
            boxSizing: "border-box"
        };
    },
    fixedFloatingOverallCell_horizontal: {
        height: 50,
        width: 53,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        borderLeft: theme.border.main,
        borderTop: theme.border.main,
        borderRight: theme.border.main,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 500
        }
    },
    floatingOverallCell_horizontal: {
        height: 50,
        width: 53,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        borderLeft: theme.border.main,
        borderTop: theme.border.main,
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        "& >p": {
            fontWeight: 400
        }
    },
    floatingRowHeaderCell: {
        width: 322,
        minHeight: 51,
        boxSizing: "border-box",
        borderTop: theme.border.main,
        paddingLeft: 8,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    rowHeaderText: { lineHeight: "1.15 !important" },

    floatingRowHeaderCellOverall_horizontal: {
        width: 322,
        minHeight: 50,
        boxSizing: "border-box",
        borderTop: theme.border.main,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    floatingRowGroup: (styleProps: HeatmapStyleProps) => {
        return {
            display: "flex",
            justifyContent: "center",
            width: styleProps.isPreviousPeriodSelected ? 320 : 375
        };
    },
    floatingResCountCell: {
        width: 52.5,
        minHeight: 50,
        backgroundColor: theme.palette.background.default,
        borderTop: theme.border.main,
        borderLeft: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        "& >p": {
            fontWeight: 500
        }
    },
    floatingResCountCellOverall: {
        width: 53,
        minHeight: 50,
        backgroundColor: theme.palette.background.default,
        borderTop: theme.border.main,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        fontWeight: 500
    },
    placeholder: {
        height: 320,
        width: 322,
        backgroundColor: "white"
    },
    placeholderShort: {
        height: 270,
        width: 322,
        borderBottom: theme.border.main,
        backgroundColor: "white"
    },
    filterPlaceHolder: {
        height: 50,
        width: 372,
        borderBottom: theme.border.main,
        backgroundColor: "white"
    },
    floatingAction: (styleProps: HeatmapStyleProps) => {
        return {
            position: "fixed",
            width: styleProps.isPreviousPeriodSelected ? 322 : 375,
            height: 270,
            backgroundColor: "white",
            borderRight: theme.border.main,
            borderRadius: 5,
            left: 2,
            top: styleProps.showFilterTags ? 256 : 206,
            zIndex: 40,
            display: "flex",
            justifyContent: "center"
        };
    },
    responseHeader: {
        width: 53,
        height: 271,
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.default,
        borderLeft: theme.border.main,
        borderBottom: theme.border.main,
        textAlign: "start",
        "& >p": {
            transformOrigin: "bottom",
            transform: "translate(-88px,-90px) rotate(270deg)",
            position: "absolute",
            width: 250,
            bottom: 43
        }
    },
    actionPlaceHolder: {
        width: 1,
        height: 275
    }
}));

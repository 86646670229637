import React, { FC } from "react";
import { Button, ButtonVariant } from "lib/button";

type Props = {
    name: string;
    id: number;
    variant: ButtonVariant;
    onChange: (id: number, name: string) => void;
    dataKey: string;
};

const CellClickableButton: FC<Props> = ({ name, id, variant, onChange, dataKey }) => {
    const handleClick = (): void => {
        onChange(id, dataKey);
    };
    if (name === "") {
        return <></>;
    }

    return (
        <Button variant={variant} onClick={handleClick} data-testid={`btn-${name}`}>
            {name}
        </Button>
    );
};

export default CellClickableButton;

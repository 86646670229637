import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { textDarkBackgroundMain, textDarkBackgroundNotActive, textDarkBackgroundDisabled } from "lib/palette";

type StyleProps = {
    alignTo: "right" | "left";
    disabled: boolean;
    selected: boolean;
    hidden: boolean;
};

export const useDrawerDropdownStyles = makeStyles(() => ({
    container: ({ disabled, selected }: StyleProps) => ({
        color: disabled ? textDarkBackgroundDisabled : selected ? textDarkBackgroundMain : textDarkBackgroundNotActive,
        fontWeight: selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
    }),
    textColor: ({ disabled, selected }: StyleProps) => ({
        paddingLeft: theme.spacing(2.5),
        color: disabled ? textDarkBackgroundDisabled : selected ? textDarkBackgroundMain : textDarkBackgroundNotActive,
        fontWeight: selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
    }),
    listContainer: {
        paddingLeft: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    link: {
        textDecoration: "none",
        width: "100%",
        height: "100%",
        "&:visited": {
            color: "inherit"
        }
    }
}));

export const useDropdownStyles = makeStyles(() => ({
    menuButton: ({ alignTo, disabled, hidden }: StyleProps) => ({
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 10,
        paddingRight: 10,
        height: "100%",
        backgroundColor: "transparent",
        textDecoration: "none",
        float: alignTo,
        display: hidden ? "none" : "flex",

        "&:hover": {
            backgroundColor: disabled ? "inherit" : theme.palette.secondary.dark,
            cursor: disabled ? "default" : "pointer"
        },
        "& svg": {
            color: disabled ? textDarkBackgroundDisabled : textDarkBackgroundMain
        }
    }),
    textColor: ({ disabled, selected }: StyleProps) => ({
        color: disabled ? textDarkBackgroundDisabled : selected ? textDarkBackgroundMain : textDarkBackgroundNotActive,
        fontWeight: selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
    }),
    selected: {
        minWidth: 200,
        background: `${theme.palette.action.hover} !important`
    },
    link: {
        textDecoration: "none",
        width: "100%",
        height: "100%",
        "&:visited": {
            color: "inherit"
        }
    }
}));

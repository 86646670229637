import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

type CommentStyleProps = {
    showSentimentScore: boolean;
    showDimensionScore: boolean;
};

export const useStyles = makeStyles(() => ({
    pivotGridWrapper: {
        borderLeft: theme.border.main,
        gridArea: "commentGrid",
        "& >p": {
            fontWeight: 400
        }
    },
    commentDetails: {
        boxSizing: "border-box",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    },
    commentDetailsHeader: {
        display: "flex",
        borderBottom: theme.border.main,
        alignItems: "center",
        height: 48,
        minHeight: 48,
        maxHeight: 48,
        paddingLeft: 24
    },
    commentDetailsStackedBar: {
        display: "flex",
        height: 80,
        minHeight: 80,
        maxHeight: 80,
        borderBottom: theme.border.main,
        alignItems: "center"
    },
    comments: (styleProps: CommentStyleProps) => {
        return {
            height: styleProps.showSentimentScore ? "calc(100% - 80px)" : "100%",
            boxSizing: "border-box",
            overflowY: "auto"
        };
    },
    stackedBarWrapper: {
        display: "flex",
        flexDirection: "column",
        width: 600,
        height: 70,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "& >p": {
            fontWeight: 400
        }
    },
    stackedBar: {
        width: 600,
        height: 28
    },
    stackBarHeader: (styleProps: CommentStyleProps) => {
        return {
            paddingLeft: styleProps.showDimensionScore ? 48 : 28,
            paddingTop: 4,
            fontSize: 16,
            fontWeight: 500
        };
    },
    minimumWordChip: {
        marginLeft: 8
    }
}));

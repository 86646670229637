import React, { ReactElement, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { withRouter, useParams } from "react-router-dom";
import Papa from "papaparse";
import saveAs from "file-saver";

import {
    AdminParticipantSurveyInfo,
    AdminParticipantSurveyResponse,
    AdminParticiantStatusResponse,
    Field,
    AdminParticipantUploadStatus,
    UploadStatus
} from "components/admin/participants/interfaces";
import { useLang, useAdminService, useLoading, useOrgChartService, useSnackbar } from "core/hooks";
import { Button } from "lib/button";
import AdminParticipantsImportDialog from "./AdminParticipantsImportDialog";
import AdminParticipantsStatusDialog from "./AdminParticipantsStatusDialog";
import AdminParticipantsProgressStatusDialog from "./AdminParticipantsProgressStatusDialog";
import { Snackbar } from "lib/snackbar";
import AdminParticipantsContactTable from "./AdminParticipantsContactTable";
import EmptyPage from "components/shared/EmptyPage";
import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { LoadFile } from "components/admin/types/types";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "calc(100% - 226px)",
        height: "100%"
    },
    resultBtnGroup: {
        position: "fixed",
        top: 72,
        right: 24
    },
    emptyPage: {
        marginTop: 200
    }
}));

export enum TabOptions {
    participants,
    activity
}

const fileEmpty = new File([""], "");

export const uploadEmpty: LoadFile = {
    file: fileEmpty,
    data: [],
    errorMessage: undefined,
    isLoaded: false
};

const AdminParticipantsContacts = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const adminService = useAdminService();
    const orgChartService = useOrgChartService();
    const { setMessage } = useSnackbar();
    const { user } = useUser();
    const { setLoading } = useLoading();
    const routeParams = useParams<{ id: string }>();
    const classes = useStyles();
    const [surveyDetails, setSurveyDetails] = useState<AdminParticipantSurveyInfo>();
    const [isImportOpen, setIsImportOpen] = useState<boolean>(false);
    const [isStatusReportOpen, setIsStatusReportOpen] = useState<boolean>(false);
    const [statusReport, setStatusReport] = useState<AdminParticiantStatusResponse>();
    const [snackBar, setSnackBar] = useState<{ isOpen: boolean; message: string }>({ isOpen: false, message: "" });
    const [demographicFields, setDemographicFields] = useState<Field[]>([]);
    const [participants, setParticipnts] = useState<string[][]>([]);
    const [isProgressStatusDialogOpen, setIsProgressStatusDialogOpen] = useState<boolean>(false);
    const [uploadStatus, setUpoadStatus] = useState<AdminParticipantUploadStatus>();
    const [importDisabled, setImportDisabled] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<TabOptions>(TabOptions.participants);
    const [confirmImportDialogOpen, setConfirmImportDialogOpen] = useState(false);
    const [errorImportDialogOpen, setErrorImportDialogOpen] = useState(false);
    const [uploadFile, setUploadFile] = useState<LoadFile>(uploadEmpty);
    const [isPreviousPeriodSurvey, setIsPreviousPeriodSurvey] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);

    const importContactsOnClick = (): void => {
        if (isPreviousPeriodSurvey) {
            setSnackBar({ isOpen: true, message: lang.editParticipantsWarning });
        } else {
            setIsImportOpen(true);
        }
    };

    const handleCloseImportDialog = (): void => {
        setUploadFile(uploadEmpty);
        setIsImportOpen(false);
    };

    const handleCloseStatusDialog = (): void => {
        setIsStatusReportOpen(false);
    };

    const exportContactsOnClick = (): void => {
        orgChartService
            .exportParticipantsReport(+routeParams.id)
            .then((blob: unknown) => {
                saveAs(blob as Blob, "Participants.xlsx");
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
            });
    };

    const handleCompleteUpload = (contactFile: File): void => {
        if (surveyDetails && surveyDetails.id !== -1) {
            setLoading(true);
            adminService
                .updateAdminParticipants(surveyDetails.id, contactFile, "")
                .then(result => {
                    if (result) {
                        if (result.errors == null || result.errors.length == 0) {
                            setLoading(true);
                            adminService
                                .getAdminParticipants(+routeParams.id)
                                .then(data => {
                                    setDemographicFields(data.fields);
                                    setParticipnts(data.participants);
                                    setIsPreviousPeriodSurvey(data.isPreviousPeriodSurvey);
                                })
                                .finally(() => {
                                    setValue(v => v + 1);
                                    setLoading(false);
                                });
                        } else {
                            setStatusReport(result);
                            setUploadFile(uploadEmpty);
                            setIsStatusReportOpen(true);
                        }
                    }
                })
                .catch(() => setSnackBar({ isOpen: true, message: lang.somethingWentWrong }))
                .finally(() => setLoading(false));
        }
        setIsImportOpen(false);
    };

    const handleCloseSnackbar = (): void => {
        setSnackBar({ isOpen: false, message: "" });
    };

    const handleSetTabValue = (_: React.ChangeEvent<unknown>, newValue: TabOptions): void => {
        setTabValue(newValue);
    };

    const handleFileDrop = (fileDrop: LoadFile): void => {
        setUploadFile(fileDrop);
    };

    const handleRejectedFiles = (): void => {
        const updatedUploadFile = {
            ...uploadFile,
            errorMessage: lang.errorMustUploadCSV,
            file: fileEmpty
        };
        setUploadFile(updatedUploadFile);
    };

    const handleUploadClick = (): void => {
        if (participants && participants.length > 0) {
            setConfirmImportDialogOpen(true);
        } else {
            Papa.parse(uploadFile.file, {
                skipEmptyLines: true,
                complete: results => {
                    const updatedUploadFile = { ...uploadFile, data: results.data };
                    setUploadFile(updatedUploadFile);
                    handleCompleteUpload(uploadFile.file);
                },
                error: () => {
                    const updatedUploadFile = { ...uploadFile, errorMessage: lang.errorLoadingCSV };
                    setUploadFile(updatedUploadFile);
                }
            });
        }
    };

    const handleCloseConfirmation = (): void => {
        setErrorImportDialogOpen(false);
    };

    useEffect(() => {
        if (user.settings.selectedOrganization.id) {
            adminService.getAdminSurveys(user.settings.selectedOrganization.id, languageCode).then(surveys => {
                const mappedSurvey = surveys
                    .map(
                        (survey: AdminParticipantSurveyResponse): AdminParticipantSurveyInfo => ({
                            ...survey,
                            id: survey.surveyId,
                            name: survey.name,
                            active: survey.active,
                            participantCount: survey.participantCount,
                            responseCount: survey.responseCount,
                            createdTime: survey.createdTime,
                            lastResponseSubmittedTime: survey.lastResponseSubmittedTime,
                            source: survey.source
                        })
                    )
                    .filter(survey => survey.id === +routeParams.id)[0];
                setSurveyDetails(mappedSurvey);
            });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        adminService
            .getAdminParticipants(+routeParams.id)
            .then(data => {
                setDemographicFields(data.fields);
                setParticipnts(data.participants);
                setIsPreviousPeriodSurvey(data.isPreviousPeriodSurvey);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        const getStatus = (): void => {
            adminService.getAdminParticipantUploadStatus(+routeParams.id).then(status => {
                if (status) {
                    if (status.status == UploadStatus.Error) {
                        setImportDisabled(false);
                        setIsProgressStatusDialogOpen(false);
                        setErrorImportDialogOpen(true);
                        if (timerId) {
                            clearTimeout(timerId);
                        }
                    } else if (
                        (status.status == UploadStatus.InProgress && status.progress !== 100) ||
                        status.status == UploadStatus.New
                    ) {
                        setUpoadStatus(status);
                        setImportDisabled(true);
                        if (isProgressStatusDialogOpen == false) {
                            setIsProgressStatusDialogOpen(true);
                        }
                        timerId = setTimeout(() => getStatus(), 2000);
                    } else {
                        setIsProgressStatusDialogOpen(false);
                        setImportDisabled(false);
                        if (timerId) {
                            clearTimeout(timerId);
                        }
                    }
                } else {
                    setImportDisabled(false);
                    if (timerId) {
                        clearTimeout(timerId);
                    }
                }
            });
        };
        getStatus();
        return (): void => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [value]);

    const setSessionStorage = (): void => {
        const snapshotExportReloading = window.sessionStorage.getItem("snapshotExportReloading");
        if (snapshotExportReloading) {
            window.sessionStorage.removeItem("snapshotExportReloading");
        }
        window.sessionStorage.setItem("participantReloading", "true");
    };

    useEffect(() => {
        window.addEventListener("beforeunload", setSessionStorage);
        return () => {
            window.removeEventListener("beforeunload", setSessionStorage);
        };
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.resultBtnGroup}>
                <Button
                    variant="text"
                    onClick={importContactsOnClick}
                    disabled={importDisabled}
                    data-testid="btn-import-participants-contact"
                    style={{ marginRight: 18 }}
                >
                    {lang.import}
                </Button>
                <Button
                    variant="text"
                    onClick={exportContactsOnClick}
                    data-testid={"btn-export-participants-contact"}
                    disabled={participants.length === 0}
                    style={{ marginLeft: 18 }}
                >
                    {lang.export}
                </Button>
            </div>
            {participants && participants.length > 0 ? (
                <AdminParticipantsContactTable
                    key={value}
                    demographicFields={demographicFields}
                    participants={participants}
                    tabValue={tabValue}
                    setTabValue={handleSetTabValue}
                    surveyId={+routeParams.id}
                    isPreviousPeriodSurvey={isPreviousPeriodSurvey}
                />
            ) : (
                <div className={classes.emptyPage}>
                    <EmptyPage image={EmptyResults} message={lang.clickImportToAddContacts} />
                </div>
            )}
            {isImportOpen && (
                <AdminParticipantsImportDialog
                    isOpen={isImportOpen}
                    onClose={handleCloseImportDialog}
                    fileDrop={handleFileDrop}
                    uploadFile={uploadFile}
                    handleRejectedFiles={handleRejectedFiles}
                    handleUploadClick={handleUploadClick}
                />
            )}
            {isStatusReportOpen && (
                <AdminParticipantsStatusDialog
                    isOpen={isStatusReportOpen}
                    onClose={handleCloseStatusDialog}
                    status={statusReport}
                />
            )}
            {isProgressStatusDialogOpen && (
                <AdminParticipantsProgressStatusDialog
                    isOpen={isProgressStatusDialogOpen}
                    uploadStatus={uploadStatus}
                />
            )}
            {confirmImportDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => {
                        setConfirmImportDialogOpen(false);
                        setIsImportOpen(false);
                        setUploadFile(uploadEmpty);
                    }}
                    open={confirmImportDialogOpen}
                    onConfirmationClicked={(): void => {
                        setConfirmImportDialogOpen(false);
                        setIsImportOpen(false);
                        handleCompleteUpload(uploadFile.file);
                    }}
                    title={lang.confirmImport}
                    message={lang.confirmImportParticipants[0]}
                    submessage={lang.confirmImportParticipants[1]}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
            <ConfirmationDialog
                open={errorImportDialogOpen}
                onCancelClicked={handleCloseConfirmation}
                onConfirmationClicked={(): void => void 0}
                title={lang.errorInfo}
                message={""}
                messgaeWithLink={
                    <>
                        <span>{lang.uploadContactsError}</span>
                        <a href={"mailto:support@talentmap.com"} target="_blank" rel="noopener noreferrer">
                            support@talentmap.com
                        </a>
                    </>
                }
                cancelButtonText={lang.ok}
                cancelButtonVariant="delete"
                hideCancelButton
            />
            <Snackbar open={snackBar.isOpen} handleClose={handleCloseSnackbar} message={snackBar.message} />
        </div>
    );
};

export default withRouter(AdminParticipantsContacts);

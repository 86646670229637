/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column } from "../interfaces";
import { TableData } from "../types";
import { DataKey } from "../enums";

export const mapTableSource = <T>(rawDataList: T[], tableColumns: Column[]): TableData[] => {
    const dataTable: TableData[] = [];

    rawDataList.forEach((item: any) => {
        let obj: TableData;
        if (item.selected !== undefined) {
            obj = { id: item.id, selected: item.selected };
        } else {
            obj = { id: item.id };
        }

        tableColumns.forEach((col: Column) => {
            if (col.dataKey === DataKey.name && "translations" in item) {
                obj[col.dataKey] = item.translations[0][col.dataKey];
            } else if (col.dataKey === "survey") {
                obj[col.dataKey] = item.sgSurveyName;
            } else if (col.dataKey === "createdBy") {
                obj[col.dataKey] = item.createdByName;
            } else {
                obj[col.dataKey] = item[col.dataKey];
            }

            if (col.secondaryDataKey && col.secondaryDataKey === "progress") {
                obj[col.secondaryDataKey] = item.progress;
            }
            if (col.thirdyDataKey && col.thirdyDataKey === "queuePosition") {
                obj[col.thirdyDataKey] = item.queuePosition;
            }
        });

        dataTable.push(obj);
    });

    return dataTable;
};

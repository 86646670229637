import React, { ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./core/styles/index.css";

const WrappedApp = (): ReactElement => {
    return (
        <Auth0Provider
            domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
            clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
            audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
            redirectUri={window.location.origin}
            responseType="token"
            scope=""
        >
            <Router>
                <App />
            </Router>
        </Auth0Provider>
    );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<WrappedApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogActions, { DialogActionsProps } from "@mui/material/DialogActions";
import MuiDialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import MuiDialogContentText, { DialogContentTextProps } from "@mui/material/DialogContentText";

const Dialog = (props: DialogProps) => {
    const { children, ...rest } = props;
    return <MuiDialog {...rest}>{children}</MuiDialog>;
};

const DialogTitle = (props: DialogTitleProps) => {
    const { children, ...rest } = props;
    return <MuiDialogTitle {...rest}>{children}</MuiDialogTitle>;
};

const DialogActions = (props: DialogActionsProps) => {
    const { children, ...rest } = props;
    return <MuiDialogActions {...rest}>{children}</MuiDialogActions>;
};

const DialogContent = (props: DialogContentProps) => {
    const { children, ...rest } = props;
    return <MuiDialogContent {...rest}>{children}</MuiDialogContent>;
};

const DialogContentText = (props: DialogContentTextProps) => {
    const { children, ...rest } = props;
    return <MuiDialogContentText {...rest}>{children}</MuiDialogContentText>;
};

export { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText };

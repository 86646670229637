import React, { ReactElement, useState, useEffect, useRef } from "react";
import FileCopy from "@mui/icons-material/FileCopy";
import { useLang, useCommentsService } from "core/hooks";
import { useCommentAskStyles } from "./comments-ask.style";
import { CommentsConversationPayload, ConversationResponse, PostCommentConversationPayload } from "api/rest/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { Filter } from "components/filters/interfaces";
import Loading from "components/shared/Loading";
import { CommentState } from "../interface";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import ShowTooSimilarConfidentialityMessage from "../ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "../ShowBelowConfidentialityMessage";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Box } from "lib/box";
import { Button } from "lib/button";
import { theme } from "lib/theme";
import { TextField } from "lib/text-field";
import { InputAdornment } from "lib/input-adornment";
import { Send } from "@mui/icons-material";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import Highlighter from "react-highlight-words";

type Props = {
    surveyId: number;
    sectionOrItemTitle: string;
    currentFilter: Filter;
    itemIds: number[];
    confidentialityResult: CommentState["confidentialityResult"];
    searchTerm: string;
};

export enum CommentAskStatus {
    idle,
    loading,
    error
}

export enum CommentQA {
    question,
    answer
}

export const CommentsAsk = ({
    surveyId,
    sectionOrItemTitle,
    currentFilter,
    itemIds,
    confidentialityResult,
    searchTerm
}: Props): ReactElement => {
    const classes = useCommentAskStyles();
    const { lang, languageCode } = useLang();
    const commentsService = useCommentsService();
    const [conversationId, setConversationId] = useState<number>(-1);
    const [conversation, setConversation] = useState<ConversationResponse[]>([]);
    const [status, setStatus] = useState<CommentAskStatus>(CommentAskStatus.idle);
    const [input, setInput] = useState<string>("");
    const [confirmCommentQADialogOpen, setConfirmCommentQADialogOpen] = useState<boolean>(false);
    const conversationBoxRef = useRef<HTMLDivElement>(null);

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(
            `${lang.commentAskAnswer} ${sectionOrItemTitle}: 
${conversation
    .map(c => (c.agent === CommentQA.question ? `Q: ${c.response}` + "\r\n" : `A: ${c.response}` + "\r\n \r\n"))
    .join("")}`
        );
    };

    const getHighlitedQA = (qa: string): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={searchTerm ? [searchTerm] : []}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={qa}
                />
            </>
        );
    };

    const getStatus = (): void => {
        const commentsConversationPayload: CommentsConversationPayload = {
            surveyId: surveyId,
            hierarchyId: 0,
            cardId: 0,
            filters: filterConnectorToBackEnd(currentFilter.items),
            languageCode,
            itemIds
        };
        setStatus(CommentAskStatus.loading);
        commentsService
            .getCommentConversation(commentsConversationPayload)
            .then(res => {
                if (res) {
                    setConversation(res.responses);
                    setConversationId(res.conversationId);
                }
                setStatus(CommentAskStatus.idle);
            })
            .catch(() => {
                setStatus(CommentAskStatus.error);
            });
    };

    const resetConversation = (): void => {
        setConfirmCommentQADialogOpen(true);
    };

    const confirmationCommentQADialogOnClick = (): void => {
        setConfirmCommentQADialogOpen(false);
        if (conversationId) {
            commentsService.resetCommentConversation(conversationId).then(() => {
                const commentsConversationPayload: CommentsConversationPayload = {
                    surveyId: surveyId,
                    hierarchyId: 0,
                    cardId: 0,
                    filters: filterConnectorToBackEnd(currentFilter.items),
                    languageCode,
                    itemIds
                };
                commentsService
                    .getCommentConversation(commentsConversationPayload)
                    .then(res => {
                        if (res) {
                            setConversation(res.responses);
                            setConversationId(res.conversationId);
                            setInput("");
                        }
                        setStatus(CommentAskStatus.idle);
                    })
                    .catch(() => {
                        setStatus(CommentAskStatus.error);
                    });
            });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setInput(event.target.value);
    };

    const postResponse = (): void => {
        setStatus(CommentAskStatus.loading);
        const postCommentConversationPayload: PostCommentConversationPayload = {
            conversationId,
            response: input
        };
        commentsService
            .postCommentConversation(postCommentConversationPayload)
            .then(() => {
                const commentsConversationPayload: CommentsConversationPayload = {
                    surveyId: surveyId,
                    hierarchyId: 0,
                    cardId: 0,
                    filters: filterConnectorToBackEnd(currentFilter.items),
                    languageCode,
                    itemIds
                };
                commentsService
                    .getCommentConversation(commentsConversationPayload)
                    .then(res => {
                        if (res) {
                            setConversation(res.responses);
                            setConversationId(res.conversationId);
                            setInput("");
                            setTimeout(() => {
                                if (conversationBoxRef && conversationBoxRef.current) {
                                    conversationBoxRef.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    });
                                }
                            }, 100);
                        }
                        setStatus(CommentAskStatus.idle);
                    })
                    .catch(() => {
                        setStatus(CommentAskStatus.error);
                    });
            })
            .catch(() => {
                setStatus(CommentAskStatus.error);
            });
    };

    useEffect(() => {
        getStatus();
    }, [currentFilter.items, itemIds]);

    if (status === CommentAskStatus.loading) {
        return (
            <Loading styleProps={{ background: "white" }} zIndex={1000} borderLeft>
                <Typography>{lang.loadingCommentsAsk}</Typography>
            </Loading>
        );
    }

    if (status === CommentAskStatus.error) {
        return <Typography>{lang.somethingWentWrong}</Typography>;
    }

    return (
        <>
            {confidentialityResult === ConfidentialityResult.tooSimilar ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowTooSimilarConfidentialityMessage />
                </div>
            ) : confidentialityResult === ConfidentialityResult.belowThreshold ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowBelowConfidentialityMessage />
                </div>
            ) : (
                <div className={classes.ask}>
                    <div className={classes.askHeader}>
                        <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={"medium"}
                            pl={3}
                        >{`${lang.commentAskAnswer} ${sectionOrItemTitle}`}</Typography>
                        <Box pr={3}>
                            <IconButton
                                sx={{ marginRight: "8px" }}
                                onClick={copyToClipboard}
                                id="btn-ask-copy-to-clipBoard"
                                color="primary"
                            >
                                <FileCopy />
                            </IconButton>
                            <Button
                                variant="text"
                                onClick={resetConversation}
                                data-testId="btn-comment-reset-conversation"
                            >
                                {lang.clear}
                            </Button>
                        </Box>
                    </div>
                    <div className={classes.askConversations}>
                        {conversation &&
                            conversation.length > 0 &&
                            conversation
                                .sort((a, b) => a.order - b.order)
                                .map((c, index) => (
                                    <React.Fragment key={index}>
                                        {c.agent === CommentQA.question ? (
                                            <Box pl={1} pr={2} pt={"8px"} pb={"4px"} width={"100%"}>
                                                <Typography fontWeight={"medium"} pl={"16px"}>
                                                    {`Q: `}
                                                    <Typography display={"inline"} fontWeight={"400"}>
                                                        {getHighlitedQA(c.response)}
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                pl={1}
                                                pr={2}
                                                pt={"4px"}
                                                pb={"8px"}
                                                borderBottom={theme.border.main}
                                                width={"100%"}
                                            >
                                                <Typography fontWeight={"medium"} pl={"16px"}>
                                                    {`A: `}
                                                    <Typography
                                                        display={"inline"}
                                                        whiteSpace={"pre-wrap"}
                                                        fontWeight={"400"}
                                                    >
                                                        {getHighlitedQA(c.response)}
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        )}
                                    </React.Fragment>
                                ))}
                        <div ref={conversationBoxRef}></div>
                    </div>
                    <div className={classes.askInput}>
                        <div className={classes.askInputWrapper}>
                            <TextField
                                id="standard-input"
                                label={""}
                                autoComplete="off"
                                placeholder={lang.typeYourQuestion}
                                variant="outlined"
                                onChange={handleInputChange}
                                onKeyDown={(e): void => {
                                    if (e.key === "Enter") {
                                        postResponse();
                                    }
                                }}
                                color="primary"
                                fullWidth
                                size={"medium"}
                                inputProps={{ value: input }}
                                InputProps={{
                                    classes: {
                                        root: classes.root,
                                        adornedEnd: classes.adornedEndStyle
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ marginLeft: "2px", marginRight: "4px", display: "flex" }}
                                        >
                                            <IconButton size="small" onClick={postResponse}>
                                                <Send />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {confirmCommentQADialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => setConfirmCommentQADialogOpen(false)}
                    open={confirmCommentQADialogOpen}
                    onConfirmationClicked={confirmationCommentQADialogOnClick}
                    title={lang.clearConversation}
                    message={lang.confirmNewCommentConversation}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};

import React from "react";

import MuiSelect, { SelectProps } from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { backgroundLightGrey } from "../palette";

const Select = (props: SelectProps) => {
    const { children, ...rest } = props;
    return (
        <MuiSelect
            {...rest}
            sx={{
                "&.Mui-disabled": {
                    background: backgroundLightGrey
                }
            }}
        >
            {children}
        </MuiSelect>
    );
};

export { Select };
export type { SelectChangeEvent };

import React, { CSSProperties, ReactElement, useState, useEffect } from "react";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList as VirtualizedList } from "react-window";

import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { SurveySection } from "managerPortal/context/interfaces";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { FilterType } from "../enums";
import { initFilterParameter, initHrisFieldInfo } from "../inits";
import { FilterParameter, Target } from "../interfaces";
import { useAutoSizerStyles } from "core/styles";
import { surveyQuestionFilter } from "../helper";
import { theme } from "lib/theme";
import { Checkbox } from "lib/checkbox";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import Search from "components/shared/Search";

const rowHeight = 50;

type CheckboxTarget = {
    selectedFilterItem: FilterParameter;
    surveySections: SurveySection[];
    fieldsList: HrisFieldInfo[];
    updateFilterItems: (param: FilterParameter) => void;
};

export const CheckboxTarget = ({
    selectedFilterItem,
    surveySections,
    fieldsList,
    updateFilterItems
}: CheckboxTarget) => {
    const autoSizerClass = useAutoSizerStyles();
    const [searchStr, setSearchStr] = useState("");
    const rowStyle = {
        height: rowHeight,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        overflowY: "auto",
        "&:hover": {
            cursor: "pointer",
            background: theme.palette.action.hover
        }
    };

    useEffect(() => {
        setSearchStr("");
    }, [selectedFilterItem]);

    const handleSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const handleTargetChange = (itemId: string, itemText?: string): void => {
        let updateTargetList = [...selectedFilterItem.target];
        if (updateTargetList.some((target: Target) => target.id === itemId)) {
            updateTargetList = updateTargetList.filter((target: Target) => target.id !== itemId);
        } else {
            updateTargetList.push({ id: itemId, text: itemText });
        }
        const updateSelectedFilterItem = {
            ...selectedFilterItem,
            target: updateTargetList
        };
        updateFilterItems(updateSelectedFilterItem);
    };

    const findSelectedField = (): HrisFieldInfo => {
        const _selectedField = fieldsList.find((field: HrisFieldInfo) => field.fieldId === selectedFilterItem.field.id);

        if (!_selectedField) return initHrisFieldInfo;

        if (selectedFilterItem.filterType === FilterType.contactField && searchStr.trim() !== "") {
            return {
                ..._selectedField,
                fieldValues: [
                    ..._selectedField.fieldValues.filter(v => v.toLowerCase().includes(searchStr.toLowerCase()))
                ].sort()
            };
        } else {
            return { ..._selectedField, fieldValues: [..._selectedField.fieldValues].sort() };
        }
    };

    const getItemCount = (): number => {
        if (selectedFilterItem.filterType === FilterType.contactField) {
            if (searchStr.trim() !== "") {
                return findSelectedField().fieldValues.filter(v => v.toLowerCase().includes(searchStr.toLowerCase()))
                    .length;
            } else {
                return findSelectedField().fieldValues.length;
            }
        }

        const lookupId = selectedFilterItem.field.id
            ? selectedFilterItem.field.id
            : selectedFilterItem.field.questionId
              ? selectedFilterItem.field.questionId
              : initFilterParameter.field.id;
        const selectedSurveyItem = surveyQuestionFilter(surveySections, lookupId!);

        if (selectedSurveyItem) {
            if ("answers" in selectedSurveyItem) {
                return selectedSurveyItem.answers.length;
            }

            return selectedSurveyItem.items.length;
        }
        return 0;
    };

    const TargetRow = ({ index, style }: { index: number; style: CSSProperties }): ReactElement => {
        let itemId = findSelectedField().fieldValues[index];
        let itemText = findSelectedField().fieldValues[index];
        let isSelected = selectedFilterItem.target.some((target: Target) => target.id === itemId);

        const lookupId = selectedFilterItem.field.id
            ? selectedFilterItem.field.id
            : selectedFilterItem.field.questionId
              ? selectedFilterItem.field.questionId
              : initFilterParameter.field.id;
        const selectedSurveyItem = surveyQuestionFilter(surveySections, lookupId!);

        if (selectedFilterItem.filterType !== FilterType.contactField) {
            if ("itemId" in selectedSurveyItem) {
                itemId = selectedSurveyItem.answers[index].answerValue.toString();
                itemText = selectedSurveyItem.answers[index].description;
            } else {
                itemId = selectedSurveyItem.items[index].fieldId.toString();
                itemText = selectedSurveyItem.items[index].title;
            }
            isSelected = selectedFilterItem.target.some((target: Target) => target.id === itemId);
        }

        return (
            <Box sx={[rowStyle, style]} onClick={(): void => handleTargetChange(itemId, itemText)}>
                <Checkbox checked={isSelected} />
                <TruncateDisplay maxLabelLength={30} title={itemText}>
                    <Typography noWrap variant="body2">
                        {itemText}
                    </Typography>
                </TruncateDisplay>
            </Box>
        );
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" data-testid="targetList">
            {selectedFilterItem.filterType === FilterType.contactField && (
                <Box paddingY={1} paddingX={2} data-testid="targetList-search">
                    <Search onSearchChange={handleSearchChange} searchTerm={searchStr} />
                </Box>
            )}
            <Box flexGrow={1}>
                <AutoSizer className={autoSizerClass.autoSizer}>
                    {({ height, width }): JSX.Element => (
                        <VirtualizedList height={height} width={width} itemCount={getItemCount()} itemSize={rowHeight}>
                            {TargetRow}
                        </VirtualizedList>
                    )}
                </AutoSizer>
            </Box>
        </Box>
    );
};

export const DragItemType = "cardContentType";

export enum CardContentType {
    question = "question",
    item = "item",
    keyDriver = "keydriver",
    responseRate = "responserate"
}

export enum ColumnType {
    cardContentList = "cardContentList",
    graphBar = "graphBar",
    numberCircle = "numberCircle"
}

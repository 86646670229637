import { makeStyles } from "@mui/styles";
import { theme } from "lib/theme";

export const useAdminSurveyStyles = makeStyles(() => ({
    listContainer: {
        height: "calc(100% - 48px)",
        borderTop: theme.border.main
    },
    noOrgSelected: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
    tabs: {
        display: "flex",
        alignItems: "flex-end",
        height: 48,
        minHeight: 48,
        maxHeight: 48
    }
}));

import React, { ReactElement, useEffect, forwardRef, useImperativeHandle } from "react";
import { Popover } from "@mui/material";
import { useLazyQuery } from "@apollo/client";

import { useLang } from "core/hooks";
import { uploadEmpty, fileEmpty } from "components/admin/results/init";
import { SurveySection } from "managerPortal/context/interfaces";
import { SurveyInfo } from "components/admin/results/interfaces";
import { commentOnLoad } from "api/queries";
import { useSettingsState } from "./useSettingsState";
import type { APIPreSelection, CommentSettingsHandle } from "./types";
import { Preferences } from "./Preferences";
import { HiddenWordsPhrases } from "./HiddenWordsPhrases";
import { UserCommentSettings } from "components/admin/users/interface";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { Typography } from "lib/typography";
import { useUser } from "core/context/user/useUser";

type Props = {
    settingAnchorEl: Element | null;
    handleClosePopover: () => void;
    applySetting: (
        preselection: APIPreSelection,
        hiddenWords: string[],
        dimensionScoreChecked: boolean,
        sentimentScoreChecked: boolean,
        autoTranslateChecked: boolean
    ) => void;
    openConfirmationDialog: () => void;
    commentSettings: UserCommentSettings;
    surveyId: SurveyInfo["id"];
    hiddenWords: string[];
};

export const CommentSettingPopup = forwardRef<CommentSettingsHandle, Props>((props: Props, ref): ReactElement => {
    const { lang, languageCode } = useLang();
    const { user } = useUser();
    const { openConfirmationDialog } = props;
    const {
        preSelection,
        minWordValidationError,
        sampleWordValidationError,
        hiddenWordValidationError,
        hiddenWordsFileLoaded,
        hiddenWords,
        newHiddenWord,
        selectedHiddenWord,
        sampleWordsChecked,
        autoTranslateChecked,
        dimensionScoreChecked,
        sentimentScoreChecked,
        surveySections,
        setPreSelection,
        setMinWordValidationError,
        setSampleWordValidationError,
        setHiddenWordValidationError,
        setHiddenWordsFileLoaded,
        setHiddenWords,
        setNewHiddenWord,
        setSelectedHiddenWord,
        setSampleWordsChecked,
        setAutoTranslateChecked,
        setDimensionScoreChecked,
        setSentimentScoreChecked,
        setSurveySections
    } = useSettingsState();

    const canHideWords = user.isTalentMapAdmin || user.isSiteAdmin;

    useImperativeHandle(ref, () => ({
        resetImportedFile(): void {
            setHiddenWordsFileLoaded(uploadEmpty);
            setHiddenWords([]);
        }
    }));

    const handleCancelOnClick = (): void => {
        if (props.commentSettings) {
            if (props.commentSettings.commentsRandomSampleCount) {
                setSampleWordsChecked(true);
            } else {
                setSampleWordsChecked(false);
            }
            if (props.commentSettings.showSectionScore) {
                setDimensionScoreChecked(true);
            } else {
                setDimensionScoreChecked(false);
            }
            setAutoTranslateChecked(props.commentSettings?.autoTranslate);
            setSentimentScoreChecked(props.commentSettings?.showSentimentScores);
            const updatedSampleWords = props.commentSettings.commentsRandomSampleCount
                ? props.commentSettings.commentsRandomSampleCount
                : 15;
            setPreSelection({
                ...preSelection,
                minWords: props.commentSettings.minWordsPerComment,
                sampleWords: updatedSampleWords,
                selectedSectionId: props.commentSettings.commentsSectionId,
                selectedDimensionScoreSort: props.commentSettings.commentsSectionScoreSortOrder
            });
            setHiddenWords(props.hiddenWords);
        }
        setMinWordValidationError({ key: "", message: "" });
        setSampleWordValidationError({ key: "", message: "" });
        setHiddenWordValidationError({ key: "", message: "" });
        setNewHiddenWord("");
        setSelectedHiddenWord("");
        setHiddenWordsFileLoaded(uploadEmpty);
        props.handleClosePopover();
    };

    const [loadDimensionItems] = useLazyQuery(commentOnLoad, {
        onCompleted: data => {
            if (data) {
                const sections = [...data.surveyItemFields] as SurveySection[];
                const filteredSection = sections.filter(s => {
                    return s.questions.filter(q => q.isFun).length > 0;
                });
                setSurveySections(filteredSection);
            }
        }
    });

    useEffect(() => {
        if (props.commentSettings) {
            setSampleWordsChecked(!!props.commentSettings.commentsRandomSampleCount);
            setDimensionScoreChecked(props.commentSettings.showSectionScore);
            setSentimentScoreChecked(props.commentSettings.showSentimentScores);
            setAutoTranslateChecked(props.commentSettings.autoTranslate);

            const updatedSampleWords = props.commentSettings.commentsRandomSampleCount
                ? props.commentSettings.commentsRandomSampleCount
                : 15;
            setPreSelection({
                ...preSelection,
                minWords: props.commentSettings.minWordsPerComment,
                sampleWords: updatedSampleWords,
                selectedSectionId: props.commentSettings.commentsSectionId,
                selectedDimensionScoreSort: props.commentSettings.commentsSectionScoreSortOrder
            });
            setHiddenWords(props.hiddenWords);
        }
    }, [props.commentSettings]);

    useEffect(() => {
        if (hiddenWordsFileLoaded.errorMessage) {
            setHiddenWordsFileLoaded({
                ...hiddenWordsFileLoaded,
                errorMessage: lang.errorMustUploadCSV,
                file: fileEmpty
            });
        }
    }, [languageCode]);

    useEffect(() => {
        if (props.surveyId) {
            loadDimensionItems({ variables: { surveyId: props.surveyId, languageCode, includeQuestionTypes: 2 } });
        }
    }, [props.surveyId]);

    return (
        <Popover
            sx={{
                marginTop: 30,
                overflowY: "hidden",
                boxSizing: "border-box"
            }}
            open={props.settingAnchorEl !== null}
            onClose={props.handleClosePopover}
            anchorEl={props.settingAnchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right"
            }}
            onKeyUp={e => {
                if (
                    e.key === "Enter" &&
                    minWordValidationError.key === "" &&
                    hiddenWordsFileLoaded.errorMessage == undefined &&
                    sampleWordValidationError.key == "" &&
                    ((dimensionScoreChecked && preSelection.selectedSectionId !== null) || !dimensionScoreChecked)
                ) {
                    if (sampleWordsChecked) {
                        props.applySetting(
                            preSelection,
                            hiddenWords,
                            dimensionScoreChecked,
                            sentimentScoreChecked,
                            autoTranslateChecked
                        );
                    } else {
                        const updatedPreselection = {
                            minWords: preSelection.minWords,
                            sampleWords: 0,
                            selectedSectionId: preSelection.selectedSectionId,
                            selectedDimensionScoreSort: preSelection.selectedDimensionScoreSort
                        };
                        props.applySetting(
                            updatedPreselection,
                            hiddenWords,
                            dimensionScoreChecked,
                            sentimentScoreChecked,
                            autoTranslateChecked
                        );
                    }
                }
            }}
        >
            <Box
                sx={{
                    width: canHideWords ? 740 : 370,
                    height: 620,
                    overflowY: "hidden"
                }}
            >
                <Box height={50} pl={2} display="flex" alignItems="center">
                    <Typography variant="body1" fontWeight="500">
                        {lang.commentSettings}
                    </Typography>
                </Box>
                <Divider />
                <Box display="flex" height="calc(100% - 100px)">
                    <Preferences
                        minWordValidationError={minWordValidationError}
                        setMinWordValidationError={setMinWordValidationError}
                        preSelection={preSelection}
                        setPreSelection={setPreSelection}
                        sampleWordValidationError={sampleWordValidationError}
                        setSampleWordValidationError={setSampleWordValidationError}
                        sampleWordsChecked={sampleWordsChecked}
                        setSampleWordsChecked={setSampleWordsChecked}
                        autoTranslateChecked={autoTranslateChecked}
                        setAutoTranslateChecked={setAutoTranslateChecked}
                        dimensionScoreChecked={dimensionScoreChecked}
                        setDimensionScoreChecked={setDimensionScoreChecked}
                        sentimentScoreChecked={sentimentScoreChecked}
                        setSentimentScoreChecked={setSentimentScoreChecked}
                        surveySections={surveySections}
                    />
                    <Divider orientation="vertical" />
                    {canHideWords && (
                        <>
                            <HiddenWordsPhrases
                                openConfirmationDialog={openConfirmationDialog}
                                hiddenWordValidationError={hiddenWordValidationError}
                                setHiddenWordValidationError={setHiddenWordValidationError}
                                hiddenWordsFileLoaded={hiddenWordsFileLoaded}
                                setHiddenWordsFileLoaded={setHiddenWordsFileLoaded}
                                hiddenWords={hiddenWords}
                                setHiddenWords={setHiddenWords}
                                newHiddenWord={newHiddenWord}
                                setNewHiddenWord={setNewHiddenWord}
                                selectedHiddenWord={selectedHiddenWord}
                                setSelectedHiddenWord={setSelectedHiddenWord}
                            />
                        </>
                    )}
                </Box>
                <Divider />
                <Box display="flex" gap={2} pr={2} pt={1} pb={1} height={50} justifyContent="flex-end">
                    <Button
                        variant="text"
                        onClick={(): void => {
                            if (sampleWordsChecked) {
                                props.applySetting(
                                    preSelection,
                                    hiddenWords,
                                    dimensionScoreChecked,
                                    sentimentScoreChecked,
                                    autoTranslateChecked
                                );
                            } else {
                                const updatedPreselection = {
                                    minWords: preSelection.minWords,
                                    sampleWords: 0,
                                    selectedSectionId: preSelection.selectedSectionId,
                                    selectedDimensionScoreSort: preSelection.selectedDimensionScoreSort
                                };
                                props.applySetting(
                                    updatedPreselection,
                                    hiddenWords,
                                    dimensionScoreChecked,
                                    sentimentScoreChecked,
                                    autoTranslateChecked
                                );
                            }
                        }}
                        data-testid="btn-comment-setting-apply"
                        disabled={
                            minWordValidationError.key !== "" ||
                            hiddenWordsFileLoaded.errorMessage !== undefined ||
                            sampleWordValidationError.key !== "" ||
                            (dimensionScoreChecked && preSelection.selectedSectionId == null)
                        }
                    >
                        {lang.ok}
                    </Button>
                    <Button variant="text" onClick={handleCancelOnClick} data-testid="btn-comment-setting-cancel">
                        {lang.cancel}
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
});

CommentSettingPopup.displayName = "CommentSettingPopup";

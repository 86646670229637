import { SurveySettings } from "./Interfaces";
import { Benchmark } from "components/admin/results/interfaces";

export const emptySurveySettings: SurveySettings = {
    showRoot: false,
    showParent: false,
    showPeers: false,
    activateNotifications: false,
    activateReportFilters: false,
    showKeyDriversManager: false,
    showBenchmarks: false
};

export const emptyBenchmark: Benchmark = {
    id: -1,
    name: ""
};

import ReactGA from "react-ga4";

const init = (): void => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);
};

const sendPageview = (path: string): void => {
    ReactGA.set({ page: path });
};

export default {
    init,
    sendPageview
};

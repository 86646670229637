import React, { ReactElement, useState, useReducer, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import saveAs from "file-saver";

import { demographicFieldsAndValuesQuery, queryOnSelectSurvey } from "api/queries";
import { userSettingMutation } from "api/mutations";
import { useLang, useLoading, useSurveys, useOrgChartService, useUpdateUserSettings } from "core/hooks";
import {
    copyArrayWithObjects,
    getAvailableSurveySections,
    getMapSurveySelected,
    mapSurveysToAutocompleteOption,
    sortArray,
    surveySectionsPickOne
} from "core/helpers";
import { SurveyInfo } from "components/admin/results/interfaces";
import { emptySurveyInfo } from "components/admin/results/init";
import { ResponseRateState } from "./interface";
import { responseRateReducer } from "./reducer";
import { ResponseRateActions } from "./action";
import { ReportBreakdownType } from "./enums";
import { ComponentStatus } from "core/enums";
import { initBreakdownItem, initBreakdownItems, initResponseRateStoredData, initSurveyOption } from "./init";
import MultiSelectionSidebar from "components/shared/MultiSelectionSidebar";
import ResponseRateChart from "./ResponseRateChart";
import { ResponseRateStoredData, UserInfo } from "components/admin/users/interface";
import Error500 from "../../errorPage/Error500";
import { BreakdownItem, BreakdownItems } from "components/shared";
import { DialogExportSlide } from "managerPortal/components/shared/DialogExportSlide";
import { initFilter } from "components/filters/inits";
import Filters from "components/filters/Filters";
import { FilterMode } from "components/filters/enums";
import { Filter } from "components/filters/interfaces";
import { ReportsLayout } from "../layout/ReportsLayout";
import { ReviewBenchmark } from "./reviewBenchmark/ReviewBenchmark";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { Snackbar } from "lib/snackbar";
import { useUser } from "core/context/user/useUser";

const useStyles = makeStyles(() => ({
    dataContent: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "320px 1fr",
        gridTemplateAreas: `"Sidebar responseRateChart"`,
        boxSizing: "border-box",
        justifyContent: "center"
    }
}));

const initialState = (restoredState: Partial<ResponseRateState>): ResponseRateState => {
    return {
        surveySelected: restoredState.surveySelected!,
        breakdownItems: restoredState.breakdownItems!,
        responseRate: [],
        contactFieldsOptions: [],
        surveyItemsOptions: [],
        currentSelectId: null,
        overallResponseRate: { responseRate: 0, surveysSent: 0, surveysCompleted: 0 },
        status: ComponentStatus.idle,
        lastTimeUpdated: Date.now().toString(),
        isExportDialogOpen: false,
        isFilterDialogOpen: false,
        currentFilter: initFilter,
        isFromAlchemer: false
    };
};

const restoredState = (_surveys: SurveyInfo[], storedState: ResponseRateStoredData): Partial<ResponseRateState> => {
    const surveyFound = _surveys.find(
        (s: SurveyInfo) => s.active && storedState.surveySelected && s.id === storedState.surveySelected
    );

    return {
        surveySelected: surveyFound ?? emptySurveyInfo,
        breakdownItems: initBreakdownItems
    };
};

const ResponseRate = (): ReactElement => {
    const { user, setUser } = useUser();
    const { lang, languageCode } = useLang();
    const { surveys } = useSurveys();
    const { setLoading } = useLoading();
    const orgChartService = useOrgChartService();
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const { setInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();

    const [state, dispatch] = useReducer(
        responseRateReducer,
        restoredState(surveys, user.settings.responseRate),
        initialState
    );
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    //default selection must be present but can be hidden from users
    const initMapSurvey = { id: initSurveyOption.id, name: lang.noOptionsAvailable };
    const styleProps = {
        isFilterApplied: state.currentFilter.items && state.currentFilter.items.length > 0
    };
    const classes = useStyles(styleProps);
    const showReviewButton = user.isTalentMapAdmin;

    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo], user.isTalentMapAdmin, true);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, user.settings.responseRate.surveySelected);

    const [updateUserSetting] = useMutation(userSettingMutation);

    const shouldShowEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.responseRate) {
            return true;
        }

        if (user.settings.responseRate) {
            if (
                user.settings.responseRate.surveySelected === emptySurveyInfo.id &&
                mapSurveys[0] &&
                mapSurveys[0].id === initMapSurvey.id
            ) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.responseRate.surveySelected) === undefined) {
                return true;
            }
        }

        if (user.settings.responseRate.surveySelected === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };

    const saveResponseRateSettings = (
        newSettings: Partial<UserInfo["settings"]["responseRate"]>
    ): UserInfo["settings"] => {
        const settings: UserInfo["settings"] = {
            ...user.settings,
            responseRate: {
                ...user.settings.responseRate,
                ...newSettings
            }
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };

        updateUserSetting(mutationOptions);
        return settings;
    };

    const updateResponseRate = (surveyId: number, reportType: string, fieldIds: number[], filters: Filter): void => {
        orgChartService
            .getResponseRate(surveyId, reportType, fieldIds, filters.items)
            .then(response => {
                dispatch({
                    type: ResponseRateActions.SET_RESPONSE_RATE,
                    payload: {
                        responseRate: JSON.parse(response.data),
                        overallResponseRate: response.overall,
                        lastTimeUpdated: response.overall.lastSyncTime ? response.overall.lastSyncTime : "-",
                        isFromAlchemer: response.overall.isFromAlchemer
                    }
                });
                if (state.surveySelected.id === -1) {
                    dispatch({
                        type: ResponseRateActions.SELECT_SURVEY,
                        payload: {
                            surveySelected: emptySurveyInfo
                        }
                    });

                    saveResponseRateSettings(initResponseRateStoredData);
                }
                dispatch({
                    type: ResponseRateActions.SET_STATUS,
                    payload: { status: ComponentStatus.success }
                });
            })
            .catch(() => {
                saveResponseRateSettings(initResponseRateStoredData);

                dispatch({
                    type: ResponseRateActions.SET_STATUS,
                    payload: { status: ComponentStatus.error }
                });
            });
    };

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                const hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
            updateResponseRate(
                state.surveySelected.id,
                state.breakdownItems.type,
                state.breakdownItems.items.map(item => item.id).filter(id => id !== -1),
                state.currentFilter
            );
        }
    });

    const [onSelectSurvey] = useLazyQuery(queryOnSelectSurvey, {
        onCompleted: responseData => {
            const userSavedResponseRateSettings = user.settings.responseRate;
            const fieldIds = userSavedResponseRateSettings.breakdownItems.items.map(i => i.id);
            const reportType = userSavedResponseRateSettings.breakdownItems.type;

            const setReportTypeInfo =
                responseData.surveyContactFields.length > 0
                    ? ReportBreakdownType.contactField
                    : ReportBreakdownType.surveyItem;

            let restoredItems: BreakdownItem[] = [];
            let breakdownItemsInfo = initBreakdownItems;

            if (fieldIds.length > 0 && reportType) {
                restoredItems = [...state.breakdownItems.items];

                breakdownItemsInfo = {
                    type: setReportTypeInfo,
                    items: restoredItems
                };
            }

            if (breakdownItemsInfo.items.length === 0) {
                breakdownItemsInfo.items.push(initBreakdownItem);
            }

            dispatch({
                type: ResponseRateActions.ON_SELECT_SURVEY,
                payload: {
                    surveyItemsOptions: surveySectionsPickOne(
                        getAvailableSurveySections(responseData.surveyItemFields, user)
                    ),
                    contactFieldsOptions: responseData.surveyContactFields.map(
                        (contactField: { fieldId: number; title: string }) => {
                            return {
                                ...contactField,
                                id: contactField.fieldId
                            };
                        }
                    ),
                    breakdownItems: breakdownItemsInfo
                }
            });

            loadDemographics({ variables: { surveyId: state.surveySelected.id, languageCode } });
        },
        onError: () => {
            dispatch({
                type: ResponseRateActions.SET_STATUS,
                payload: { status: ComponentStatus.error }
            });

            saveResponseRateSettings(initResponseRateStoredData);
        }
    });

    const updateBreakdownItems = (breakdownItems: BreakdownItems): void => {
        const prevItemsIds = state.breakdownItems.items.map(item => item.id);
        const updatedItemsIds = breakdownItems.items.map(item => item.id);

        if (
            updatedItemsIds[0] !== initBreakdownItem.id &&
            updatedItemsIds[updatedItemsIds.length - 1] &&
            updatedItemsIds[updatedItemsIds.length - 1] === initBreakdownItem.id
        ) {
            updatedItemsIds.pop();
        }

        if (updatedItemsIds.length === 0 || updatedItemsIds[0] === initBreakdownItem.id) {
            dispatch({
                type: ResponseRateActions.SET_BREAKDOWN_ITEMS,
                payload: { breakdownItems, responseRate: [] }
            });
            return;
        }

        if (
            updatedItemsIds.length > 0 &&
            !updatedItemsIds.includes(initBreakdownItem.id) &&
            prevItemsIds !== updatedItemsIds
        ) {
            dispatch({
                type: ResponseRateActions.SET_STATUS,
                payload: { status: ComponentStatus.loading }
            });

            if (updatedItemsIds.length > 0 && !updatedItemsIds.includes(initBreakdownItem.id)) {
                updateResponseRate(
                    state.surveySelected.id,
                    state.breakdownItems.type,
                    updatedItemsIds,
                    state.currentFilter
                );
            }
        }

        dispatch({
            type: ResponseRateActions.SET_BREAKDOWN_ITEMS,
            payload: { breakdownItems }
        });

        const settings = saveResponseRateSettings({
            breakdownItems: breakdownItems
        });
        setUser({ settings });
    };

    const handleSelectBreakdown = (id: number, name: string): void => {
        const foundIndex = state.breakdownItems.items.findIndex(
            (item: BreakdownItem) => item.id === state.currentSelectId?.id
        );

        const _updateBreakdownItems = copyArrayWithObjects(state.breakdownItems.items);

        if (foundIndex > -1) {
            _updateBreakdownItems[foundIndex] = { id, name };
        } else {
            _updateBreakdownItems.push({ id, name });
        }

        updateBreakdownItems({
            items: copyArrayWithObjects(_updateBreakdownItems),
            type: state.breakdownItems.type
        });
    };

    const setCurrentSelect = (id: number): void => {
        dispatch({
            type: ResponseRateActions.SET_CURRENT_SELECT_ID,
            payload: { currentSelectId: { id } }
        });
    };

    const handleAddBreakdown = (): void => {
        const _updateBreakdownItems = copyArrayWithObjects(state.breakdownItems.items);
        if (!_updateBreakdownItems.some((breakdown: BreakdownItem) => breakdown.id === initBreakdownItem.id)) {
            _updateBreakdownItems.push({ id: -1, name: "Select..." });
            updateBreakdownItems({
                items: copyArrayWithObjects(_updateBreakdownItems),
                type: state.breakdownItems.type
            });
        }
    };

    const handleDeleteBreakdown = (id: number): void => {
        const _updateBreakdownItems = copyArrayWithObjects(state.breakdownItems.items).filter(
            (item: BreakdownItem) => item.id !== id
        );

        if (_updateBreakdownItems.length === 0) {
            _updateBreakdownItems.push(initBreakdownItem);
        }

        const settings = saveResponseRateSettings({
            surveySelected: state.surveySelected.id,
            breakdownItems: {
                items: copyArrayWithObjects(_updateBreakdownItems),
                type: state.breakdownItems.type
            }
        });
        setUser({ settings });

        updateBreakdownItems({
            items: copyArrayWithObjects(_updateBreakdownItems),
            type: state.breakdownItems.type
        });
    };

    const handleSurveySelected = (id: number): void => {
        const surveySelected = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (surveySelected && surveySelected.id !== state.surveySelected.id) {
            setInitUserSetting(id);

            dispatch({
                type: ResponseRateActions.SELECT_SURVEY,
                payload: { surveySelected }
            });
            dispatch({
                type: ResponseRateActions.SET_FILTER,
                payload: { currentFilter: initFilter }
            });
            updateBreakdownItems(initBreakdownItems);
        }
    };

    const handleOpenFilter = (): void => {
        dispatch({
            type: ResponseRateActions.OPEN_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };

    const handleCloseFilters = (): void => {
        dispatch({
            type: ResponseRateActions.OPEN_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };

    const handleStartExport = (): void => {
        dispatch({
            type: ResponseRateActions.OPEN_EXPORT,
            payload: { isExportDialogOpen: true }
        });
    };

    const handleExportDialogClose = (): void => {
        dispatch({
            type: ResponseRateActions.OPEN_EXPORT,
            payload: { isExportDialogOpen: false }
        });
    };

    const handleExport = (mainTitle: string): void => {
        const contactFieldIds = state.breakdownItems.items.filter(item => item.id !== -1).map(i => i.id);
        setLoading(true);
        dispatch({
            type: ResponseRateActions.OPEN_EXPORT,
            payload: { isExportDialogOpen: false }
        });
        orgChartService
            .exportResponseRate(mainTitle, languageCode, contactFieldIds, state.currentFilter.items)
            .then((blob: unknown) => {
                saveAs(blob as Blob, mainTitle + ".xlsx");
                setLoading(false);
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                setLoading(false);
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const updateResponseRateData = (filter: Filter): void => {
        if (filter.items.length === 0) {
            resetFilters();
        } else {
            dispatch({
                type: ResponseRateActions.SET_STATUS,
                payload: { status: ComponentStatus.loading }
            });
            dispatch({
                type: ResponseRateActions.SET_FILTER,
                payload: { currentFilter: filter }
            });

            setUserSettingAfterFilterApplied(filter);

            updateResponseRate(
                state.surveySelected.id,
                state.breakdownItems.type,
                state.breakdownItems.items.map(item => item.id).filter(id => id !== -1),
                filter
            );
        }
    };

    const resetFilters = (): void => {
        dispatch({
            type: ResponseRateActions.SET_STATUS,
            payload: { status: ComponentStatus.loading }
        });
        dispatch({
            type: ResponseRateActions.SET_FILTER,
            payload: { currentFilter: initFilter }
        });

        setUserSettingAfterFilterApplied(initFilter);

        updateResponseRate(
            state.surveySelected.id,
            state.breakdownItems.type,
            state.breakdownItems.items.map(item => item.id).filter(id => id !== -1),
            initFilter
        );
    };

    useEffect(() => {
        if (
            state.surveySelected.id !== emptySurveyInfo.id &&
            mapSurveys.map(s => s.id).includes(user.settings.responseRate.surveySelected)
        ) {
            dispatch({
                type: ResponseRateActions.SET_STATUS,
                payload: { status: ComponentStatus.loading }
            });
            onSelectSurvey({
                variables: { surveyId: state.surveySelected.id, languageCode }
            });
        }
    }, [state.surveySelected, onSelectSurvey, languageCode]);

    useEffect(() => {
        if (
            state.surveySelected.id !== emptySurveyInfo.id &&
            mapSurveys.map(s => s.id).includes(user.settings.responseRate.surveySelected) &&
            user.settings.responseRate.breakdownItems !== initBreakdownItems
        ) {
            let filterItems = user.settings.filtersItems;
            if (filterItems == undefined) filterItems = [];
            const filters = { ...initFilter, items: filterItems };
            dispatch({
                type: ResponseRateActions.SET_FILTER,
                payload: { currentFilter: filters }
            });
            dispatch({
                type: ResponseRateActions.SET_BREAKDOWN_ITEMS,
                payload: { breakdownItems: user.settings.responseRate.breakdownItems }
            });
        }
    }, [surveys]);

    useEffect(() => {
        setLoading(state.status === ComponentStatus.loading);
    }, [state.status, setLoading]);

    if (state.status === ComponentStatus.error) {
        return <Error500 />;
    }

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.surveySelected.id === emptySurveyInfo.id ||
                (state.breakdownItems.items.length === 1 && state.breakdownItems.items[0].id === -1) ||
                state.responseRate.length === 0
            }
            filterDisabled={state.surveySelected.id === emptySurveyInfo.id}
            exportDataTestId={"btn-heatmap-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={updateResponseRateData}
            isEmptyPage={shouldShowEmptyPage()}
            extraAction={showReviewButton ? <ReviewBenchmark surveyId={state.surveySelected.id} /> : undefined}
            contactFields={contactFields}
        >
            {state.surveySelected.id !== emptySurveyInfo.id && !!state.overallResponseRate && (
                <div className={classes.dataContent} id="dataContent">
                    <MultiSelectionSidebar
                        surveySelected={state.surveySelected}
                        breakdownItems={state.breakdownItems}
                        selectOptions={
                            state.breakdownItems.type === ReportBreakdownType.contactField
                                ? state.contactFieldsOptions
                                : state.surveyItemsOptions
                        }
                        setCurrentSelect={setCurrentSelect}
                        onDeleteBreakdown={handleDeleteBreakdown}
                        onSelectBreakdown={handleSelectBreakdown}
                        onAddBreakdown={handleAddBreakdown}
                        lastTimeUpdated={state.lastTimeUpdated}
                        isFromAlchemer={state.isFromAlchemer}
                    />
                    <ResponseRateChart
                        overallResponseRateResponseRate={state.overallResponseRate.responseRate}
                        overallResponseRateSurveysSent={state.overallResponseRate.surveysSent}
                        overallResponseRateSurveysCompleted={state.overallResponseRate.surveysCompleted}
                        responseRate={state.responseRate}
                        breakdownType={state.breakdownItems.type}
                        currentFilter={state.currentFilter}
                    />
                </div>
            )}
            {state.isFilterDialogOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={state.isFilterDialogOpen}
                    onFiltersApplied={updateResponseRateData}
                    currentFilter={state.currentFilter}
                    filterMode={FilterMode.responseRate}
                    surveyId={state.surveySelected.id}
                    contactFields={contactFields}
                />
            )}
            {state.isExportDialogOpen && (
                <DialogExportSlide
                    dialogTitle={lang.exportToExcel}
                    isOpen={state.isExportDialogOpen}
                    onClose={handleExportDialogClose}
                    onSubmitCallback={handleExport}
                />
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
        </ReportsLayout>
    );
};

export default ResponseRate;

import { Benchmark, BenchmarkSection, Coordinate, SurveyItemMap } from "../interfaces";

export const initBenchmark = (translation: string): Benchmark => {
    return { id: 0, name: translation };
};

export const initBenchmarkSection: BenchmarkSection = {
    text: "",
    questions: []
};

export const initCoordinate: Coordinate = { top: 0, left: 0 };

export const initSurveyItemIdSelected: SurveyItemMap = {
    itemId: "-1",
    mapped: false
};

import React, { ReactElement, useState, useEffect } from "react";
import saveAs from "file-saver";
import { makeStyles } from "@mui/styles";

import { Button } from "lib/button";
import { Tabs, Tab } from "lib/tabs";
import { Chip } from "lib/chip";
import { useLang, useOrgChartService, useSnackbar, useLoading } from "core/hooks";
import { CreateAdminBenchmarkDialog } from "./CreateAdminBenchmarkDialog";
import { AdminBenchmarkResponse, AdminBenchmarkDetailsResponse } from "./interfaces";
import { dateFormat } from "core/constants";
import { SurveyList } from "./interfaces";
import { Tag } from "components/admin/tags/interface";
import { sortArray } from "core/helpers";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { List } from "lib/list-custom";
import dayjs, { Dayjs } from "dayjs";
import { theme } from "lib/theme";
import { Divider } from "lib/divider";

export enum TabOptions {
    active,
    archived
}

type StyleProps = {
    isSurveyOrOrgList: boolean;
};

const useAdminBenchmarkStyles = makeStyles(() => ({
    adminBenchmarksContainer: {
        display: "flex",
        boxSizing: "border-box",
        maxHeight: "100%",
        height: "100%"
    },
    adminBenchmarksList: {
        display: "flex",
        flexDirection: "column",
        width: 400,
        minWidth: 400,
        boxSizing: "border-box"
    },
    adminBenchmarksInfo: {
        width: "calc(100% - 400px)",
        boxSizing: "border-box"
    },
    adminBenchmarksHeader: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 85,
        minHeight: 85,
        borderBottom: theme.border.main,
        boxSizing: "border-box",
        paddingRight: theme.spacing(3)
    },
    adminBenchmarksActions: {
        display: "flex",
        paddingRight: theme.spacing(2),
        boxSizing: "border-box"
    },
    tabs: {
        marginTop: 36
    },
    adminBenchmarksListActive: {
        height: "calc(100% - 86px)",
        minHeight: "calc(100% - 86px)",
        overflowY: "auto",
        borderRight: theme.border.main,
        borderTop: theme.border.main,
        boxSizing: "border-box"
    },
    adminBenchmarksListArchived: {
        height: "calc(100% - 86px)",
        minHeight: "calc(100% - 86px)",
        overflowY: "auto",
        borderRight: theme.border.main,
        boxSizing: "border-box"
    },
    adminBenchmarksInfoDetails: {
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 86px)",
        minHeight: "calc(100% - 86px)"
    },
    adminBenchmarksInfoDetailsHeader: {
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        flexGrow: 0,
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 24,
        paddingRight: 24
    },
    adminBenchmarksInfoDetailsTitle: {
        display: "flex",
        alignItems: "center"
    },
    adminBenchmarksInfoDetailsTitleName: {
        display: "flex",
        minWidth: 360
    },
    adminBenchmarksInfoDetailsTags: {
        display: "flex",
        marginTop: 16,
        marginBottom: 16,
        alignItems: "center"
    },
    tagTitle: {
        display: "flex"
    },
    adminBenchmarksInfoDetailsDate: {
        display: "flex",
        marginTop: 16,
        marginBottom: 16
    },
    adminBenchmarksInfoDetailsNumber: {
        display: "flex",
        marginTop: 16,
        marginBottom: 16
    },
    surveyInfoDetails: {
        display: "flex",
        width: 230,
        minWidth: 230
    },
    surveyInfoOrgEngagementSoreDetails: {
        display: "flex",
        width: 260,
        minWidth: 260
    },
    surveyInfoNumberOfSurveyDetails: {
        display: "flex",
        width: 200,
        minWidth: 200
    },
    adminBenchmarksListHeaderBodyWarpper: ({ isSurveyOrOrgList }: StyleProps) => ({
        display: "flex",
        background: "#FAFAFA",
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 24,
        paddingRight: 24,
        borderTop: theme.border.main,
        borderBottom: isSurveyOrOrgList ? undefined : theme.border.main,
        flexShrink: 0,
        flexGrow: 0
    }),
    adminBenchmarksListBodyWarpper: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 0,
        paddingTop: 0,
        overflowY: "auto",
        height: "100%",
        scrollbarGutter: "stable"
    },
    adminBenchmarkSurveyListBodyPlaceHolder: {
        width: "50%",
        borderRight: theme.border.main,
        height: "100%",
        boxSizing: "border-box"
    }
}));

const BenchmarksAdmin = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
    const [tabValue, setTabValue] = useState<TabOptions>(TabOptions.active);
    const [benchmarks, setBenchmarks] = useState<AdminBenchmarkResponse[]>([]);
    const [selectedBenchmark, setSelectedBenchmark] = useState<AdminBenchmarkResponse>();
    const [selectedBenchmarkDetails, setSelectedBenchmarkDetails] = useState<AdminBenchmarkDetailsResponse>();
    const orgChartService = useOrgChartService();
    const { setMessage } = useSnackbar();
    const { setLoading } = useLoading();

    const styleProps = {
        isSurveyOrOrgList: selectedBenchmarkDetails
            ? selectedBenchmarkDetails.organizationsAndSurveys.length > 0
            : false
    };
    const classes = useAdminBenchmarkStyles(styleProps);

    const numberOfOrg = (selectedBenchmarkDetails && selectedBenchmarkDetails.organizationsAndSurveys.length) || 0;
    const numberOfSurveys =
        (selectedBenchmarkDetails &&
            selectedBenchmarkDetails.organizationsAndSurveys.map(org => org.surveys).reduce((a, b) => a.concat(b), [])
                .length) ||
        0;
    const sortBenchmarksArray = <T extends Tag>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");
    const addBenchmarkOnClick = (): void => {
        setCreateDialogOpen(true);
    };
    const handleCreatetDialogClose = (): void => {
        setCreateDialogOpen(false);
    };
    const handleCreateBenchmark = (
        englishTitle: string,
        frenchTitle: string,
        tags: Tag[],
        startDate: Dayjs,
        endDate: Dayjs,
        surveyList: SurveyList[],
        numberOfResponses: number,
        responseRate: number
    ): void => {
        if (!startDate || !endDate) return;
        setLoading(true);
        const checkedOrgList = surveyList
            .filter(org => org.checked === true)
            .map(org => ({
                id: org.id,
                name: org.name,
                checked: org.checked,
                surveys: org.surveys.filter(survey => survey.checked === true)
            }));
        const checkedSurveyList = checkedOrgList.map(org => ({
            id: org.id,
            name: org.name,
            surveys: org.surveys.map(survey => ({
                id: survey.id,
                name: survey.name
            }))
        }));
        orgChartService
            .createNewAdminBenchmark(
                englishTitle,
                frenchTitle,
                tags.filter(t => t.id !== -1).map(t => t.id),
                startDate.format(dateFormat),
                endDate.format(dateFormat),
                checkedSurveyList,
                numberOfResponses,
                responseRate
            )
            .then((blob: unknown) => {
                saveAs(blob as Blob, englishTitle + ".xlsx");
                setCreateDialogOpen(false);
                getActiveBenchmarkList();
                setLoading(false);
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
                setLoading(false);
            });
    };
    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: TabOptions): void => {
        setTabValue(newValue);
        if (
            newValue === TabOptions.active &&
            benchmarks &&
            benchmarks.filter(benchmark => benchmark.isActive).length > 0
        ) {
            const selectedBenchmark = benchmarks.filter(benchmark => benchmark.isActive)[0];
            setSelectedBenchmark(selectedBenchmark);
            getAdminBenchmarkDetails(selectedBenchmark.id, languageCode);
        } else if (
            newValue === TabOptions.archived &&
            benchmarks &&
            benchmarks.filter(benchmark => !benchmark.isActive).length > 0
        ) {
            const selectedBenchmark = benchmarks.filter(benchmark => !benchmark.isActive)[0];
            setSelectedBenchmark(selectedBenchmark);
            getAdminBenchmarkDetails(selectedBenchmark.id, languageCode);
        } else if (
            newValue === TabOptions.active &&
            benchmarks &&
            benchmarks.filter(benchmark => benchmark.isActive).length === 0
        ) {
            setSelectedBenchmark(undefined);
            setSelectedBenchmarkDetails(undefined);
        } else if (
            newValue === TabOptions.archived &&
            benchmarks &&
            benchmarks.filter(benchmark => !benchmark.isActive).length === 0
        ) {
            setSelectedBenchmark(undefined);
            setSelectedBenchmarkDetails(undefined);
        }
    };
    const getActiveBenchmarkList = async (): Promise<void> => {
        setTabValue(TabOptions.active);
        const benchmarksResponse = await orgChartService.getAdminBenchmarkList();
        setBenchmarks(benchmarksResponse);
        if (benchmarksResponse && benchmarksResponse.filter(benchmark => benchmark.isActive).length > 0) {
            const selectedBenchmark = benchmarksResponse.filter(benchmark => benchmark.isActive)[0];
            setSelectedBenchmark(selectedBenchmark);
            getAdminBenchmarkDetails(selectedBenchmark.id, languageCode);
        } else {
            setSelectedBenchmark(undefined);
            setSelectedBenchmarkDetails(undefined);
        }
    };
    const getArchivedBenchmarkList = async (): Promise<void> => {
        setTabValue(TabOptions.archived);
        const benchmarksResponse = await orgChartService.getAdminBenchmarkList();
        setBenchmarks(benchmarksResponse);
        if (benchmarksResponse && benchmarksResponse.filter(benchmark => !benchmark.isActive).length > 0) {
            const selectedBenchmark = benchmarksResponse.filter(benchmark => !benchmark.isActive)[0];
            setSelectedBenchmark(selectedBenchmark);
            getAdminBenchmarkDetails(selectedBenchmark.id, languageCode);
        } else {
            setSelectedBenchmark(undefined);
            setSelectedBenchmarkDetails(undefined);
        }
    };
    const handleBenchmarkSelected = (id: number | string): void => {
        const selected = benchmarks.find(benchmark => benchmark.id === +id);
        if (selected) {
            setSelectedBenchmark(selected);
            getAdminBenchmarkDetails(selected.id, languageCode);
        }
    };

    const getAdminBenchmarkDetails = async (id: number, languageCode: string): Promise<void> => {
        const benchmarkDetails = await orgChartService.getAdminBenchmarkDetails(id, languageCode);
        if (benchmarkDetails) {
            setSelectedBenchmarkDetails(benchmarkDetails);
        }
    };
    const archiveBenchmark = (): void => {
        if (selectedBenchmark && selectedBenchmark.id) {
            orgChartService
                .archiveBenchmark(selectedBenchmark.id)
                .then(() => {
                    getActiveBenchmarkList();
                })
                .catch(() => {
                    setMessage(lang.somethingWentWrong);
                });
        }
    };
    const activateBenchmark = (): void => {
        if (selectedBenchmark && selectedBenchmark.id) {
            orgChartService
                .activeBenchmark(selectedBenchmark.id)
                .then(() => {
                    getArchivedBenchmarkList();
                })
                .catch(() => {
                    setMessage(lang.somethingWentWrong);
                });
        }
    };

    useEffect(() => {
        getActiveBenchmarkList();
    }, []);

    return (
        <div className={classes.adminBenchmarksContainer}>
            <div className={classes.adminBenchmarksList}>
                <div className={classes.tabs}>
                    <Tabs value={tabValue} variant="fullWidth" aria-label="simpleTabs" onChange={handleTabChange}>
                        <Tab label={lang.active} data-testid="tab-handler-active" />
                        <Tab label={lang.archived} data-testid="tab-handler-archived" />
                    </Tabs>
                </div>
                {tabValue === TabOptions.active && (
                    <div className={classes.adminBenchmarksListActive} data-testid={`active-benchmark-list`}>
                        <List
                            onItemSelect={handleBenchmarkSelected}
                            itemSelected={selectedBenchmark?.id}
                            items={sortBenchmarksArray(benchmarks)
                                .filter(b => b.isActive)
                                .map((b: AdminBenchmarkResponse) => {
                                    return {
                                        id: b.id,
                                        name: b.name
                                    };
                                })}
                        />
                    </div>
                )}
                {tabValue === TabOptions.archived && (
                    <div className={classes.adminBenchmarksListArchived} data-testid={`archived-benchmark-list`}>
                        <List
                            onItemSelect={handleBenchmarkSelected}
                            itemSelected={selectedBenchmark?.id}
                            items={sortBenchmarksArray(benchmarks)
                                .filter(b => !b.isActive)
                                .map((b: AdminBenchmarkResponse) => {
                                    return {
                                        id: b.id,
                                        name: b.name
                                    };
                                })}
                        />
                    </div>
                )}
            </div>
            <div className={classes.adminBenchmarksInfo}>
                <div className={classes.adminBenchmarksHeader}>
                    <Button onClick={addBenchmarkOnClick} data-testid="btn-add-benchmark">
                        {lang.create}
                    </Button>
                </div>
                <div className={classes.adminBenchmarksInfoDetails}>
                    <div className={classes.adminBenchmarksInfoDetailsHeader} data-testid={`benchmark-info-details`}>
                        <div className={classes.adminBenchmarksInfoDetailsTitle}>
                            <div className={classes.adminBenchmarksInfoDetailsTitleName}>
                                <Typography fontWeight="medium">{`${lang.name}:`}</Typography>
                                <Typography ml={0.5}>{selectedBenchmarkDetails?.name}</Typography>
                            </div>
                            {selectedBenchmark && selectedBenchmark.isActive && (
                                <Button variant="delete" onClick={archiveBenchmark} data-testid="btn-archive-benchmark">
                                    {lang.archive}
                                </Button>
                            )}
                            {selectedBenchmark && !selectedBenchmark.isActive && (
                                <Button variant="delete" onClick={activateBenchmark} data-testid="btn-active-benchmark">
                                    {lang.activate}
                                </Button>
                            )}
                        </div>
                        <div className={classes.adminBenchmarksInfoDetailsTags}>
                            <div className={classes.tagTitle}>
                                <Typography variant="body1" fontWeight="medium">
                                    {`${lang.tags}:`}
                                </Typography>
                            </div>
                            {selectedBenchmarkDetails?.tags.map(tag => (
                                <Box ml={1} key={tag.id}>
                                    <Chip data-testid={`${tag.name}`} label={`${tag.name}`} />
                                </Box>
                            ))}
                        </div>
                        <div className={classes.adminBenchmarksInfoDetailsDate}>
                            <Typography variant="body1" fontWeight="medium">
                                {`${lang.startDate}:`}
                            </Typography>
                            {selectedBenchmarkDetails ? (
                                <Typography variant="body1" id="start-date">
                                    {dayjs(selectedBenchmarkDetails.startDate).format(dateFormat)}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            <Typography variant="body1" fontWeight="medium" marginLeft={1}>
                                {`${lang.endDate}:`}
                            </Typography>
                            {selectedBenchmarkDetails ? (
                                <Typography variant="body1" id="end-date">
                                    {dayjs(selectedBenchmarkDetails.endDate).format(dateFormat)}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={classes.adminBenchmarksInfoDetailsNumber}>
                            <div className={classes.surveyInfoDetails}>
                                <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                    mr={0.5}
                                >{`${lang.numberOfOrganizations}:`}</Typography>
                                <Typography variant="body1" id="number-of-org">{`${numberOfOrg}`}</Typography>
                            </div>
                            <div className={classes.surveyInfoNumberOfSurveyDetails}>
                                <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                    mr={0.5}
                                >{`${lang.numberOfSurveys}:`}</Typography>
                                <Typography variant="body1" id="number-of-survey">{`${numberOfSurveys}`}</Typography>
                            </div>
                            <div className={classes.surveyInfoDetails}>
                                <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                    mr={0.5}
                                >{`${lang.numberOfResponses}:`}</Typography>
                                {selectedBenchmarkDetails ? (
                                    <Typography
                                        variant="body1"
                                        id="number-of-response"
                                    >{`${selectedBenchmarkDetails.numberOfResponses}`}</Typography>
                                ) : (
                                    <Typography variant="body1">{0}</Typography>
                                )}
                            </div>
                            <div className={classes.surveyInfoDetails}>
                                <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                    mr={0.5}
                                >{`${lang.responseRate}:`}</Typography>
                                {selectedBenchmarkDetails ? (
                                    <Typography
                                        variant="body1"
                                        id="number-of-response-rate"
                                    >{`${selectedBenchmarkDetails.responseRate}%`}</Typography>
                                ) : (
                                    <Typography variant="body1">{0}</Typography>
                                )}
                            </div>
                            <div className={classes.surveyInfoOrgEngagementSoreDetails}>
                                <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                    mr={0.5}
                                >{`${lang.orgEngagementScore}:`}</Typography>
                                {selectedBenchmarkDetails ? (
                                    <Typography
                                        variant="body1"
                                        id="number-of-response-rate"
                                    >{`${selectedBenchmarkDetails.orgEngagementScore.toFixed(2)}%`}</Typography>
                                ) : (
                                    <Typography variant="body1">{0}</Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.adminBenchmarksListHeaderBodyWarpper}>
                        <Typography variant="body2" style={{ width: "50%", float: "left", fontWeight: 500 }}>
                            {lang.organizations}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ width: "50%", float: "left", paddingLeft: 32, fontWeight: 500 }}
                        >
                            {lang.surveys}
                        </Typography>
                    </div>
                    {numberOfOrg > 0 && <Divider />}
                    <div className={classes.adminBenchmarksListBodyWarpper}>
                        {selectedBenchmarkDetails?.organizationsAndSurveys.map(organization => (
                            <>
                                <Box key={organization.id} display="flex">
                                    <Box width="50%" pt={1} pl={"24px"} sx={{ borderBottom: `${theme.border.main}` }}>
                                        <Typography
                                            variant="body1"
                                            data-testid={`organization-list-${organization.name}`}
                                        >
                                            {organization.name}
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box width="50%" sx={{ borderBottom: `${theme.border.main}` }}>
                                        {organization.surveys.map(survey => (
                                            <Box
                                                pl={"24px"}
                                                height={38}
                                                display="flex"
                                                alignItems="center"
                                                key={`${organization.id}-${survey.id}`}
                                                data-testid={`survey-list-${survey.name}`}
                                            >
                                                <Typography
                                                    key={`${organization.id}-${survey.id}`}
                                                    data-testid={`survey-list-${survey.name}`}
                                                >
                                                    {survey.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </>
                        ))}
                        <div className={classes.adminBenchmarkSurveyListBodyPlaceHolder}></div>
                    </div>
                </div>
                {isCreateDialogOpen && (
                    <CreateAdminBenchmarkDialog
                        dialogTitle={lang.createNewBenchmark}
                        isOpen={isCreateDialogOpen}
                        onClose={handleCreatetDialogClose}
                        onSubmitCallback={handleCreateBenchmark}
                        benchmarks={benchmarks}
                    />
                )}
            </div>
        </div>
    );
};

export default BenchmarksAdmin;

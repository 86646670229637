import { ComponentStatus } from "core/enums";
import { SentimentCalculationStatus } from "../constants/enums";
import { CommentState } from "../interface";
import { initFilter } from "components/filters/inits";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import { initCommentSettings, initWordCloudSettings } from "./WordCloudSettings.init";

export const initialState = (): CommentState => {
    return {
        hasMore: false,
        isExportDialogOpen: false,
        isSummaryExportDialogOpen: false,
        selectedCommentId: "",
        selectedCommentQuestionId: "",
        searchTerm: "",
        totalComments: 0,
        commentsNumbserPerSection: 0,
        commentLength: 200,
        snackbar: { isOpen: false, message: "" },
        sentimentScoreNeutralThreshold: 0,
        wordCloudRawData: [],
        overallSentimentScore: [0, 0, 0],
        sentimentScoreCalculationStatus: SentimentCalculationStatus.notCalculated,
        isBulkExportDialogOpen: false,
        contactFields: [],
        settingAnchorEl: null,
        summarySettingAnchorEl: null,
        bulkExportDemographic: {
            demographicFieldId: -1,
            demographicField: ""
        },
        confirmCommentSettingsDialogOpen: false,
        surveyItemsOptions: [],
        status: ComponentStatus.idle,
        comments: [],
        currentFilter: initFilter,
        isFilterDialogOpen: false,
        confidentialityResult: ConfidentialityResult.success,
        commentCountMapping: [],
        wordCloudSettings: initWordCloudSettings,
        commentSettings: initCommentSettings,
        isSummarySettingLoading: false,
        exportIncludeWordCloud: false
    };
};

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { textDarkBackgroundMain } from "lib/palette";

export const useHyperLinkStyles = makeStyles(() => ({
    hyperlink: ({ alignTo }: { alignTo: "right" | "left" }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 10,
        paddingRight: 10,
        height: "100%",
        backgroundColor: "transparent",
        textDecoration: "none",
        float: alignTo,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
            cursor: "pointer"
        },
        "& svg": {
            color: textDarkBackgroundMain
        }
    })
}));

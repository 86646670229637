import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { colorRange065To100, colorRange050To064, colorRange000To049 } from "lib/palette";

type correlationStyleProps = {
    height: number;
    width: number;
    floatingRowWidth: number;
    isScrollingDown: boolean;
    showFilterTags: boolean;
};

export const useCorrelationGridStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "calc(100% - 56px)",
        maxWidth: "100%",
        overflow: "hidden",
        position: "relative",
        boxSizing: "border-box"
    },
    tableContainer: {
        width: "100%",
        overflow: "auto",
        height: "100%",
        zIndex: 11,
        position: "absolute",
        top: 0,
        left: 0,
        boxSizing: "border-box"
    },
    floatingAction: (styleProps: correlationStyleProps) => {
        return {
            position: "fixed",
            width: 322,
            backgroundColor: "white",
            borderBottom: theme.border.main,
            left: 0,
            top: styleProps.showFilterTags ? 239 : 189,
            zIndex: 40,
            display: "flex",
            justifyContent: "center",
            boxSizing: "border-box"
        };
    },
    gridTable: (styleProps: correlationStyleProps) => {
        return {
            height: styleProps.height + 400,
            width: styleProps.width + 400,
            borderCollapse: "collapse",
            display: "block",
            whiteSpace: "nowrap",
            position: "absolute",
            top: 0,
            left: 0
        };
    },
    thead: {
        position: "relative",
        overflowX: "auto",
        width: "100%",
        height: 270,
        overflowY: "scroll",
        whiteSpace: "normal"
    },
    tbody: {
        position: "relative",
        width: "100%",
        overflowY: "auto",
        overflowX: "auto",
        whiteSpace: "normal"
    },
    tableRow: {
        boxSizing: "border-box",
        height: 50,
        borderLeft: "none",
        borderRight: "none !important"
    },
    tableBodyRow: {
        boxSizing: "border-box",
        height: 50,
        border: theme.border.main,
        borderLeft: "none",
        borderRight: "none !important"
    },
    tableAction: {
        borderRight: theme.border.main,
        position: "sticky"
    },
    tableRow_th_vertical: {
        height: 270,
        boxSizing: "border-box",
        verticalAlign: "bottom",
        borderRight: theme.border.main,
        backgroundColor: "white !important",
        textAlign: "left",
        lineHeight: 1,
        zIndex: 15,
        position: "sticky",
        top: 0
    },
    floating_tableRow_th_vertical: {
        height: 270,
        width: 53,
        maxWidth: 53,
        boxSizing: "border-box",
        verticalAlign: "bottom",
        borderLeft: theme.border.main,
        borderBottom: theme.border.main,
        backgroundColor: "white !important",
        textAlign: "left",
        lineHeight: 1,
        zIndex: 15,
        position: "sticky",
        top: 0
    },
    tableRow_th_div: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        width: 50
    },
    floating_tableRow_th_div: {
        boxSizing: "border-box",
        position: "sticky",
        zIndex: 15,
        cursor: "pointer",
        width: 50,
        marginLeft: 1,
        marginRight: 1
    },
    tableRow_th_div_span_vertical: {
        display: "flex",
        alignItems: "center",
        width: 260,
        transformOrigin: "bottom left",
        transform: "translateX(40px) rotate(270deg)",
        "& >p": { display: "inline", width: "95%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    floating_tableRow_th_div_span_vertical: {
        display: "flex",
        alignItems: "center",
        width: 260,
        transformOrigin: "bottom left",
        transform: "translateX(40px) rotate(270deg)",
        "& >p": { display: "inline", width: "95%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    iconButton: {
        position: "relative",
        transform: "rotate(90deg)"
    },
    tableRow_th_div_children_vertical: {
        boxSizing: "border-box",
        position: "sticky",
        width: 50,
        zIndex: 15,
        backgroundColor: "white",
        "& > p": { color: "#333333 !important", lineHeight: 1.2 }
    },
    floating_tableRow_th_div_children_vertical: {
        boxSizing: "border-box",
        position: "sticky",
        width: 50,
        zIndex: 15,
        backgroundColor: "white",
        "& > p": { color: "#333333 !important", lineHeight: 1.2 },
        marginLeft: 1,
        marginRight: 1
    },
    tableRow_th_div_span_vertical_children: {
        display: "flex",
        width: 270,
        transformOrigin: "bottom left",
        transform: "translateX(45px) rotate(270deg)",
        "& >p": { width: "95%", paddingLeft: 8, color: "grey", maxHeight: 40, minHeight: 30 },
        backgroundColor: "white",
        overflow: "hidden"
    },
    rowHeaderCell: {
        display: "flex",
        padding: theme.spacing(1),
        width: 322,
        maxWidth: 322,
        minWidth: 322,
        whiteSpace: "normal",
        boxSizing: "border-box"
    },
    tableRow_th_div_span_horizontal_response: {
        display: "flex",
        width: 320,
        "& >p": { width: "95%", fontWeight: 400 },
        backgroundColor: theme.palette.background.default
    },
    tableRow_th_div_span_horizontal: {
        display: "block",
        width: 270,
        "& >p": { display: "inline", width: "100%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingTop: 6,
        paddingLeft: 8
    },
    floating_tableRow_th_div_span_horizontal: {
        display: "block",
        width: 270,
        "& >p": { display: "inline", width: "100%", fontWeight: 500 },
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingTop: 4,
        paddingLeft: 8
    },
    iconButton_horizontal_wraper: {
        display: "block",
        paddingTop: 2
    },
    floating_iconButton_horizontal_wraper: {
        display: "block"
    },
    iconButton_horizontal: {
        position: "relative",
        float: "right"
    },
    tableRow_th_div_span_details_horizontal: {
        paddingLeft: 16,
        display: "flex",
        width: 300,
        "& >p": { width: "95%", color: "grey" }
    },
    dataCell: {
        boxSizing: "border-box",
        height: 50,
        width: 50,
        textAlign: "center",
        border: theme.border.main
    },
    floatingRowHeader: {
        width: 322,
        position: "sticky",
        left: 0,
        backgroundColor: "white",
        borderRight: theme.border.main,
        zIndex: 30
    },
    placeholderShort: (styleProps: correlationStyleProps) => {
        return {
            height: styleProps.isScrollingDown ? 0 : 270,
            width: 322,
            borderBottom: theme.border.main,
            backgroundColor: "white"
        };
    },
    floatingRowGroup: { display: "flex", justifyContent: "center", width: 322 },
    floatingRowHeaderCell: {
        width: 322,
        minHeight: 50,
        boxSizing: "border-box",
        borderBottom: theme.border.main,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    rowHeaderCell_wraper: {
        width: 276,
        display: "flex"
    },
    tableRow_th_div_span_details_horizontal_left: {
        display: "flex",
        width: 320,
        "& >p": { width: "95%", color: "grey" }
    },
    flaoting_thead: (styleProps: correlationStyleProps) => {
        return {
            position: "sticky",
            marginLeft: 322,
            top: 0,
            zIndex: 20,
            width: styleProps.floatingRowWidth,
            display: "flex",
            height: 270
        };
    },
    dataCell_tooltip: { color: "white" },
    cell_above_60: {
        backgroundColor: colorRange065To100
    },
    cell_above_40: {
        backgroundColor: colorRange050To064
    },
    cell_below_40: {
        backgroundColor: colorRange000To049
    },
    boldValue: {
        "& >p": { fontWeight: 500 }
    }
}));

import { SurveyInfo } from "components/admin/results/interfaces";
import { initCommentsSettings } from "components/admin/users/initialVariables";
import { UserCommentSettings } from "components/admin/users/interface";

export const getCommentsSettings = (
    surveyId: SurveyInfo["id"],
    commentsSettings: UserCommentSettings[]
): UserCommentSettings => {
    const commentSettings = commentsSettings.find(surveySettings => surveySettings.surveyId === surveyId);
    return commentSettings || initCommentsSettings;
};

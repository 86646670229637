import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { PickManyMappedItem } from "../../interfaces/Interfaces";
import TruncateDisplay from "../shared/TruncateDisplay";
import CountChartBar from "components/shared/CountChartBar";
import { lightGreyColor } from "managerPortal/styles/GlobalStyles";
import { SnapShotDefinition } from "./content/types";
import { theme } from "lib/theme";

type styleProps = {
    stackbarWidth: number;
    placeHolderWidth: number;
};

const useStyles = makeStyles(() => ({
    listItem: {
        display: "grid",
        gridTemplateRows: "56px",
        gridTemplateColumns: "1fr auto auto"
    },
    cellInfo: {
        gridRow: "1 / 2",
        gridColumn: "1 / 2",
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        borderBottom: theme.border.main
    },
    listItemBar: (props: styleProps) => {
        return {
            display: "flex",
            gridRow: "1 / 2",
            gridColumn: "2 / 3",
            alignItems: "center",
            boxSizing: "border-box",
            width: props.stackbarWidth,
            minWidth: props.stackbarWidth,
            borderBottom: theme.border.main
        };
    },
    cellPlaceHolder: (props: styleProps) => {
        return {
            gridRow: "1 / 2",
            gridColumn: "3 / 4",
            width: props.placeHolderWidth,
            minWidth: props.placeHolderWidth,
            background: lightGreyColor
        };
    }
}));

type Props = {
    mappedItem: PickManyMappedItem;
    labelLength: number;
    snapShotDefinition: SnapShotDefinition;
    placeHolderWidth: number;
    stackbarWidth: number;
};

const ShowPickManyItem = (props: Props): ReactElement => {
    const { mappedItem, labelLength, snapShotDefinition, placeHolderWidth, stackbarWidth } = props;

    const percentagePicked =
        mappedItem.percentage > 0
            ? mappedItem.percentage
            : (mappedItem.numberOfPicks * 100) / snapShotDefinition.responseCount;
    const totalPicked = isNaN(mappedItem.numberOfPicks) ? "0" : mappedItem.numberOfPicks.toFixed(0);

    const classes = useStyles({ placeHolderWidth, stackbarWidth });

    return (
        <div className={classes.listItem} key={mappedItem.itemId} data-testid="pick-many-row">
            <div className={classes.cellInfo} data-testid="pick-many-item-text">
                <TruncateDisplay title={mappedItem.title} maxLabelLength={labelLength}>
                    <Typography variant="body1">{mappedItem.title} </Typography>
                </TruncateDisplay>
            </div>
            <div className={classes.listItemBar} data-testid="pick-many-chart-bar">
                <CountChartBar
                    percentage={percentagePicked}
                    totalCountString={totalPicked}
                    stackbarWidth={stackbarWidth}
                />
            </div>
            <div className={classes.cellPlaceHolder}></div>
        </div>
    );
};

export default ShowPickManyItem;

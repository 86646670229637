import React, { ReactElement, FC } from "react";
import { makeStyles } from "@mui/styles";

import { CorrelationInfo, ProfileType } from "components/reports/insight/interface";
import { useLang } from "core/hooks";
import { favorableColor, unfavorableColor } from "managerPortal/styles/GlobalStyles";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

export interface ItemImpactRow {
    id: number;
    label: string;
    value: string;
    type: number;
    translation: string;
}

type Props = {
    correlation: CorrelationInfo[];
    keyDriverScoreThreshold: boolean;
    selectedKeyDriver: number;
    selectedOutcome: number;
    selectedKeyItemId: number;
    updateSelectedKeyItem: (id: number) => void;
};

export const useStyles = makeStyles(() => ({
    keyItem: {
        height: 226,
        minHeight: 226,
        maxHeight: 226,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"
    },
    keyItemsImpactRow: {
        display: "flex",
        height: 40,
        minHeight: 40,
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: theme.border.main
    },
    selectedKeyItemsImpactRow: {
        display: "flex",
        height: 40,
        minHeight: 40,
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: theme.border.main,
        background: theme.palette.action.selected
    },
    ActionsRowSectionText: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: 320
    },
    impactRowText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 160
    },
    impactRowTextWithRightBorder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 158,
        borderRight: theme.border.main,
        height: 40
    },
    impactRowStrengthTextWithRightBorder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 158,
        borderRight: theme.border.main,
        height: 40,
        color: favorableColor
    },
    impactRowOpportunityTextWithRightBorder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 158,
        borderRight: theme.border.main,
        height: 40,
        color: unfavorableColor
    },
    impactRowSectionText: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 24,
        paddingRight: 24,
        width: 668,
        minWidth: 668,
        height: 40,
        minHeight: 40,
        borderRight: theme.border.main
    },
    impactRowItemTextTooltip: {
        width: 680,
        maxWidth: 680,
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 16,
        textAlign: "left"
    },
    colorBlue: {
        color: favorableColor
    },
    colorOrange: {
        color: unfavorableColor
    }
}));

const InsightKeyDriverScoreItems: FC<Props> = (props): ReactElement => {
    const { correlation, keyDriverScoreThreshold, selectedKeyDriver, selectedOutcome, selectedKeyItemId } = props;
    const classes = useStyles();
    const { lang } = useLang();

    const castProfileTypeToString = (type: ProfileType): string => {
        switch (type) {
            case ProfileType.None:
                return "";
            case ProfileType.Opportunity:
                return lang.opportunity;
            case ProfileType.Strength:
                return lang.strength;
            default:
                return "";
        }
    };

    const getImpactRowSectionName = (): string => {
        const selectedKeyDriverFound = correlation.find(c => c.id === selectedKeyDriver);
        if (!selectedKeyDriverFound) return "";
        return selectedKeyDriverFound.label;
    };

    const getImpactRowSectionValue = (): string => {
        const selectedOutcomeFound = correlation.find(c => c.id === selectedOutcome);
        if (!selectedOutcomeFound) {
            const itemsCorrelations = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const itemKeyDriverList = itemsCorrelations.find(i => i.id === selectedOutcome);
            if (itemKeyDriverList) {
                const selectedItemKeyDriver = itemKeyDriverList.correlations.find(c => c.id === selectedKeyDriver);
                if (!selectedItemKeyDriver) return "";
                return selectedItemKeyDriver.value.toFixed(2).replace(/0./, ".");
            } else {
                return "";
            }
        } else {
            const selectedKeyDriverFound = selectedOutcomeFound.correlations.find(
                c => c.isSection === true && c.id === selectedKeyDriver
            );
            if (!selectedKeyDriverFound) return "";
            return selectedKeyDriverFound.value.toFixed(2).replace(/0./, ".");
        }
    };

    const getProfileRowSectionValue = (): string => {
        const selectedOutcomeFound = correlation.find(c => c.id === selectedOutcome);
        if (!selectedOutcomeFound) {
            const itemsCorrelations = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const itemKeyDriverList = itemsCorrelations.find(i => i.id === selectedOutcome);
            if (itemKeyDriverList) {
                const selectedItemKeyDriver = itemKeyDriverList.correlations.find(c => c.id === selectedKeyDriver);
                if (!selectedItemKeyDriver) return "";
                return castProfileTypeToString(selectedItemKeyDriver.type);
            } else {
                return "";
            }
        } else {
            const selectedKeyDriverFound = selectedOutcomeFound.correlations.find(
                c => c.isSection === true && c.id === selectedKeyDriver
            );
            if (!selectedKeyDriverFound) return "";
            return castProfileTypeToString(selectedKeyDriverFound.type);
        }
    };

    const isProfileRowStrength = (): boolean => {
        const selectedOutcomeFound = correlation.find(c => c.id === selectedOutcome);
        if (!selectedOutcomeFound) {
            const itemsCorrelations = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const itemKeyDriverList = itemsCorrelations.find(i => i.id === selectedOutcome);
            if (itemKeyDriverList) {
                const selectedItemKeyDriver = itemKeyDriverList.correlations.find(c => c.id === selectedKeyDriver);
                if (!selectedItemKeyDriver) return false;
                return selectedItemKeyDriver.type === ProfileType.Strength;
            } else {
                return false;
            }
        } else {
            const selectedKeyDriverFound = selectedOutcomeFound.correlations.find(
                c => c.isSection === true && c.id === selectedKeyDriver
            );
            if (!selectedKeyDriverFound) return false;
            return selectedKeyDriverFound.type === ProfileType.Strength;
        }
    };

    const impactItemRows = (): ItemImpactRow[] => {
        const selectedKeyDriverFound = correlation.find(c => c.id === selectedKeyDriver);
        if (!selectedKeyDriverFound) return [];
        const selectedKeyDriverItemIds = selectedKeyDriverFound.items.map(i => i.id);
        if (selectedKeyDriverItemIds.length === 0) return [];
        const selectedOutcomeFound = correlation.find(c => c.id === selectedOutcome);
        if (!selectedOutcomeFound) {
            const itemsCorrelations = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const itemKeyDriverList = itemsCorrelations.find(i => i.id === selectedOutcome);
            if (itemKeyDriverList) {
                return itemKeyDriverList.correlations
                    .filter(c => selectedKeyDriverItemIds.includes(c.id))
                    .sort((a, b) => b.value - a.value)
                    .map(c => ({
                        id: c.id,
                        label: c.text,
                        value: c.value.toFixed(2).replace(/0./, "."),
                        type: c.type,
                        translation: castProfileTypeToString(c.type)
                    }));
            } else {
                return [];
            }
        } else {
            return selectedOutcomeFound.correlations
                .filter(c => selectedKeyDriverItemIds.includes(c.id))
                .sort((a, b) => b.value - a.value)
                .map(c => ({
                    id: c.id,
                    label: c.text,
                    value: c.value.toFixed(2).replace(/0./, "."),
                    type: c.type,
                    translation: castProfileTypeToString(c.type)
                }));
        }
    };

    const handleSelectKeyItem = (id: number): void => {
        props.updateSelectedKeyItem(id);
    };

    if (keyDriverScoreThreshold) {
        return (
            <div className={classes.keyItem}>
                <div
                    className={classes.keyItemsImpactRow}
                    data-testid={`insight-keyItem-section-${getImpactRowSectionName()}`}
                >
                    <div className={classes.impactRowSectionText}>
                        <Typography variant="body2" style={{ fontSize: 16 }}>
                            {getImpactRowSectionName()}
                        </Typography>
                    </div>
                    <div className={classes.ActionsRowSectionText}>
                        <div className={classes.impactRowTextWithRightBorder}>
                            <Typography className={isProfileRowStrength() ? classes.colorBlue : classes.colorOrange}>
                                {getProfileRowSectionValue()}
                            </Typography>
                        </div>
                        <div className={classes.impactRowText}>{getImpactRowSectionValue()}</div>
                    </div>
                </div>
                {impactItemRows().map(item => (
                    <div
                        key={item.id}
                        className={
                            selectedKeyItemId === item.id
                                ? classes.selectedKeyItemsImpactRow
                                : classes.keyItemsImpactRow
                        }
                        onClick={(): void => handleSelectKeyItem(item.id)}
                        data-testid={`insight-keyItem-${item.label}`}
                    >
                        <div className={classes.impactRowSectionText}>
                            <Tooltip title={item.label} placement={"bottom"}>
                                <Typography
                                    variant="body2"
                                    className={classes.impactRowItemTextTooltip}
                                    style={{ fontSize: 16 }}
                                >{`${item.label}`}</Typography>
                            </Tooltip>
                        </div>
                        <div className={classes.ActionsRowSectionText}>
                            <div
                                className={
                                    item.type === ProfileType.Strength
                                        ? classes.impactRowStrengthTextWithRightBorder
                                        : classes.impactRowOpportunityTextWithRightBorder
                                }
                            >
                                {item.translation}
                            </div>
                            <div className={classes.impactRowText}>{item.value}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default InsightKeyDriverScoreItems;

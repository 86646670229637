import React, { FC } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    textContainer: {
        textDecoration: "none",
        cursor: "pointer"
    },
    text: {
        fontSize: 12
    }
}));

type Props = {
    name: string;
    id: number;
    onChange: (id: number) => void;
    isDownloadLink?: boolean;
};

const CellClickableText: FC<Props> = ({ name, id, onChange, isDownloadLink }) => {
    const classes = useStyles();

    const handleClick = (): void => {
        onChange(id);
    };

    return (
        <div className={classes.textContainer} onClick={handleClick}>
            <Typography color={isDownloadLink ? "secondary" : "primary"} variant="body2" className={classes.text}>
                {name}
            </Typography>
        </div>
    );
};

export default CellClickableText;

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useAdminParticipantsContactStyles = makeStyles(() => ({
    loadedFile: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

import { Action } from "../../../../core/interfaces";
import { OrganizationDetailState } from "../interfaces";
import { OrganizationDetailActions } from "../actions";

export const OrganizationDetailReducer = (state: OrganizationDetailState, action: Action): OrganizationDetailState => {
    const { type, payload } = action;
    switch (type) {
        case OrganizationDetailActions.UPDATE_ORG:
            return {
                ...state,
                organization: payload.organization
            };
        case OrganizationDetailActions.UPDATE_SELECTED_SIDEBAR:
            return {
                ...state,
                selectedSidebarItem: payload.selectedSidebarItem
            };
        case OrganizationDetailActions.SET_VALIDATION_ERROR:
            return {
                ...state,
                validation: payload.validation
            };
        case OrganizationDetailActions.ADD_TAG:
            return {
                ...state,
                createdTags: [...state.createdTags, payload.tag]
            };
        case OrganizationDetailActions.DELETE_TAG:
            return {
                ...state,
                createdTags: [...state.createdTags].filter(t => t.id !== payload.tag.id)
            };
        default:
            return {
                ...state
            };
    }
};

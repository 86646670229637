import { Translation } from "core/languages/interfaces";
import { FunScaleItem } from "../interfaces";
import { FunScale } from "../enums";

export const funScaleItem = (lang: Translation): FunScaleItem[] => {
    return [
        { value: 1, propertyName: FunScale.unfavorable, name: lang.unfavorable },
        { value: 2, propertyName: FunScale.neutral, name: lang.neutral },
        { value: 3, propertyName: FunScale.favorable, name: lang.favorable },
        { value: 4, propertyName: FunScale.ignored, name: lang.ignored }
    ];
};

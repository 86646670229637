export enum SurveyEditSidebar {
    general = "general",
    participants = "participants",
    customScale = "customScale",
    benchmarks = "benchmarks",
    previousPeriod = "previousPeriod",
    assignAccess = "assignAccess",
    dashboard = "dashboard",
    snapshot = "snapshot"
}

import { useState, useMemo } from "react";

interface Bind {
    onMouseOver: () => void;
    onMouseLeave: () => void;
}

export const useHover = (): [boolean, Bind] => {
    const [isHovered, setIsHovered] = useState(false);

    const bind = useMemo(() => {
        return {
            onMouseOver: (): void => setIsHovered(true),
            onMouseLeave: (): void => setIsHovered(false)
        };
    }, []);

    return [isHovered, bind];
};

import React, { useCallback, CSSProperties } from "react";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { useLang } from "../../core/hooks";
import {
    backgroundLightGrey,
    backgroundHoverGrey,
    backgroundErrorTomato,
    errorColor,
    textColorGrey
} from "../../core/styles";
import { theme } from "lib/theme";

type FileDrop = (files: File[], reject: FileRejection[], event: DropEvent) => void;

type Props = {
    fileDrop: FileDrop;
    acceptFiles?: string;
    label?: string;
    helperText?: string;
    width?: CSSProperties["width"];
    dataDropKey?: string;
    title?: string;
    csvOnly?: boolean;
    pptxOnly?: boolean;
    onRejectFiles: () => void;
};

type StyleProps = {
    isDragActive: boolean;
    isDragAccept: boolean;
    isDragReject: boolean;
    fileRejections: FileRejection[];
    width?: CSSProperties["width"];
};

const useStyles = makeStyles(() => ({
    dropAreaContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        marginTop: 0
    },
    dropAreaContent: ({ width }: StyleProps) => ({
        width: width ? width : "100%"
    }),
    dropAreaLabel: {
        marginRight: theme.spacing(2),
        alignSelf: "flex-start",
        height: 40,
        display: "flex",
        alignItems: "center"
    },
    dropSubtitle: ({ fileRejections }: StyleProps) => ({
        color: fileRejections.length > 0 ? errorColor : textColorGrey
    }),
    dropArea: ({ isDragActive, isDragAccept, width }: StyleProps) => ({
        height: 40,
        boxSizing: "border-box",
        minWidth: 283,
        width: width ? width : "100%",
        border: theme.border.greyDashed,
        background: isDragActive ? (isDragAccept ? backgroundHoverGrey : backgroundErrorTomato) : backgroundLightGrey,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        "&:hover": {
            cursor: "pointer",
            pointerEvents: "initial"
        }
    })
}));

const DropArea: React.FC<Props> = props => {
    const { lang } = useLang();

    const onDrop = useCallback(
        <T extends File>(acceptedFiles: T[], rejectFiles: FileRejection[], event: DropEvent) => {
            if (rejectFiles.length > 0) {
                props.onRejectFiles();
                return;
            }
            if (props.csvOnly && !acceptedFiles[0].name.toLowerCase().includes(".csv")) {
                props.onRejectFiles();
                return;
            }
            if (props.pptxOnly && !acceptedFiles[0].name.toLowerCase().includes(".pptx")) {
                props.onRejectFiles();
                return;
            }
            props.fileDrop(acceptedFiles, rejectFiles, event);
        },
        [props]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
        accept: props.acceptFiles ? props.acceptFiles : "",
        multiple: false,
        onDrop
    });

    const classes = useStyles({
        isDragActive,
        isDragAccept,
        isDragReject,
        fileRejections,
        width: props.width
    });

    return (
        <div className={classes.dropAreaContainer}>
            {props.label && (
                <div className={classes.dropAreaLabel}>
                    <Typography variant="subtitle2">{props.label}</Typography>
                </div>
            )}
            <div className={classes.dropAreaContent}>
                <div {...getRootProps()} className={classes.dropArea} data-dropkey={props.dataDropKey}>
                    <input {...getInputProps()} data-dropkey={props.dataDropKey} />
                    {
                        <Typography variant="subtitle2">
                            {isDragActive
                                ? isDragAccept
                                    ? lang.dropHere
                                    : lang.cannotBeDroppedHere
                                : lang.dragDropFilesHereOrClick}
                        </Typography>
                    }
                </div>
                {props.helperText && (
                    <Typography
                        className={classes.dropSubtitle}
                        variant="subtitle2"
                        style={{ fontSize: 14 }}
                        id={"upload-helper-text"}
                    >
                        {props.helperText}
                    </Typography>
                )}
            </div>
        </div>
    );
};
export default DropArea;

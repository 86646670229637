import { CustomErrorParam } from "../interfaces/CustomErrorParam.interface";
import { ErrorMessage } from "../interfaces";
import { composeMessageHelper } from "./helper/composeMessageHelper";
import { SurveyImportResponseCode } from "./helper/errorMessageParser";

export const englishError = (errorParam: CustomErrorParam): ErrorMessage => {
    const translation: CustomErrorParam = {
        NoSurveyResponses: `There are no responses under the selected campaigns in the survey: , campaigns selected: `
    };

    return {
        error: composeMessageHelper(errorParam, translation),
        [SurveyImportResponseCode.DifferentItemTypes]: `Mix of different item types within the same question. Section ${errorParam[0]}, Question ${errorParam[1]}`,
        [SurveyImportResponseCode.ColumnNameNotInResponseFile]: `Column name ${errorParam[0]} in the Survey file doesn't exist in the Response file.`,
        [SurveyImportResponseCode.ValueMappingNameNotInScaleFile]: `Value mapping name ${errorParam[0]} in the Survey file doesn't exist in the Scale file.`,
        [SurveyImportResponseCode.DuplicateMappingValuesInScaleFile]: `Duplicate values in same mapping ${errorParam[0]} in the Scale file.`,
        [SurveyImportResponseCode.MissingOrBadformattiingOfCompletedColumn]:
            "Missing Completed column or bad formatting of the Completed column in the Response file.",
        [SurveyImportResponseCode.ValueInResponseFileNotInScaleFile]: `Value ${errorParam[0]} in row ${errorParam[1]} of the Response file doesn't exist in the value mapping ${errorParam[2]} in the Scale file.`,
        [SurveyImportResponseCode.DuplicateColumnNameInSurveyFile]: `Duplicate column name ${errorParam[0]} in the Survey file.`,
        [SurveyImportResponseCode.DuplicateColumnNameInValueFile]: `Duplicate column name ${errorParam[0]} in the Value file.`,
        [SurveyImportResponseCode.MultipleItemsInQuestionHaveDifferentScales]:
            "Multiple items in the same question have different scales.",
        [SurveyImportResponseCode.InValidLanguageCodeInSurveyFile]: "Found invalid language code in Survey file.",
        [SurveyImportResponseCode.InValidLanguageCodeInValuesFile]: "Found invalid language code in Values file.",
        [SurveyImportResponseCode.InconsistentLangCodeSectionQuestionItemSurveyFile]:
            "Inconsistent language codes in Section/Question/Text in Survey file.",
        [SurveyImportResponseCode.ErrorReadingResponseFileAtRowN]: `Error reading from Response file at row ${errorParam[0]} ${errorParam[1]}`,
        [SurveyImportResponseCode.ScaleNotFound]: `Unable to find a scale named ${errorParam[0]} in the Scale file.`
    };
};

import { CustomErrorParam } from "../interfaces/CustomErrorParam.interface";
import { ErrorMessage } from "../interfaces";
import { composeMessageHelper } from "./helper/composeMessageHelper";
import { SurveyImportResponseCode } from "./helper/errorMessageParser";

export const spanishError = (errorParam: CustomErrorParam): ErrorMessage => {
    const translation: CustomErrorParam = {
        NoSurveyResponses: `No hay respuestas en las campañas seleccionadas en la encuesta:, campañas seleccionadas: `
    };

    return {
        error: composeMessageHelper(errorParam, translation),
        [SurveyImportResponseCode.DifferentItemTypes]: `Mezcla de diferentes tipos de elementos dentro de la misma pregunta. Sección ${errorParam[0]}, Pregunta ${errorParam[1]}`,
        [SurveyImportResponseCode.ColumnNameNotInResponseFile]: `El nombre de columna ${errorParam[0]} en el archivo de encuesta no existe en el archivo de respuesta.`,
        [SurveyImportResponseCode.ValueMappingNameNotInScaleFile]: `El nombre de asignación de valor ${errorParam[0]} en el archivo de encuesta no existe en el archivo de escala.`,
        [SurveyImportResponseCode.DuplicateMappingValuesInScaleFile]: `Valores duplicados en la misma asignación ${errorParam[0]} en el archivo Scale.`,
        [SurveyImportResponseCode.MissingOrBadformattiingOfCompletedColumn]:
            "Falta la columna Completada o el formato incorrecto de la columna Completada en el archivo de respuesta.",
        [SurveyImportResponseCode.ValueInResponseFileNotInScaleFile]: `El valor ${errorParam[0]} en la fila ${errorParam[1]} del archivo de respuesta no existe en la asignación de valor ${errorParam[2]} en el archivo de escala.`,
        [SurveyImportResponseCode.DuplicateColumnNameInSurveyFile]: `Duplicar el nombre de la columna ${errorParam[0]} en el archivo de encuesta.`,
        [SurveyImportResponseCode.DuplicateColumnNameInValueFile]: `Nombre de columna duplicado ${errorParam[0]} en el archivo de valores.`,
        [SurveyImportResponseCode.MultipleItemsInQuestionHaveDifferentScales]:
            "Varios elementos en la misma pregunta tienen diferentes escalas.",
        [SurveyImportResponseCode.InValidLanguageCodeInSurveyFile]:
            "Se encontró un código de idioma no válido en el archivo de encuesta.",
        [SurveyImportResponseCode.InValidLanguageCodeInValuesFile]:
            "Se encontró un código de idioma no válido en el archivo de valores.",
        [SurveyImportResponseCode.InconsistentLangCodeSectionQuestionItemSurveyFile]:
            "Códigos de idioma inconsistentes en la sección/pregunta/texto en el archivo de la encuesta.",
        [SurveyImportResponseCode.ErrorReadingResponseFileAtRowN]: `Error al leer del archivo de respuesta en la fila ${errorParam[0]} ${errorParam[1]}`,
        [SurveyImportResponseCode.ScaleNotFound]: `No se puede encontrar una escala denominada ${errorParam[0]} en el archivo de escala.`
    };
};

export enum ResponseRateActions {
    SET_BREAKDOWN_ITEMS = "SET_BREAKDOWN_ITEMS",
    SET_RESPONSE_RATE = "SET_RESPONSE_RATE",
    SET_CURRENT_SELECT_ID = "SET_CURRENT_SELECT_ID",
    ON_SELECT_SURVEY = "ON_SELECT_SURVEY",
    SET_STATUS = "SET_STATUS",
    SELECT_SURVEY = "SELECT_SURVEY",
    OPEN_EXPORT = "OPEN_EXPORT",
    OPEN_FILTER = "OPEN_FILTER",
    SET_FILTER = "SET_FILTER"
}

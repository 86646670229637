export enum HeatmapActions {
    INITIAL_STATE = "INITIAL_STATE",
    OPEN_CLOSE_FILTER = "OPEN_FILTER",
    SELECT_SURVEY = "SELECT_SURVEY",
    LOAD_SECTION_HRIS = " LOAD_SECTION_HRIS",
    SET_GRID_DATA = "SET_GRID_DATA",
    SET_BREAKDOWN_ITEMS = "SET_BREAKDOWN_ITEMS",
    SET_CURRENT_BREAKDOWN = "SET_CURRENT_BREAKDOWN",
    SET_STATUS = "SET_STATUS",
    UPDATE_GRID_DATA = "UPDATE_GRID_DATA",
    SET_FILTER = "SET_FILTER",
    RESTORE_USER_SETTINGS = "RESTORE_USER_SETTINGS",
    SET_COMPARE_OPTIONS = "SET_COMPARE_OPTIONS",
    SELECT_COMPARE_TARGET = "SELECT_COMPARE_TARGET",
    LOAD_COMPARE_OPTIONS = "LOAD_COMPARE_OPTIONS",
    SNACKBAR_OPEN_CLOSE = "SNACKBAR_OPEN_CLOSE"
}

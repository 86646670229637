import React, { ReactElement, useEffect } from "react";
import { Typography } from "lib/typography";
import { getRoute } from "../../core/helpers";
import { UserInfo } from "../admin/users/interface";
import { MainRoutes, ReportRoutes } from "../../routes/routes";
import { MainRoutes as MainRoutesEnum, ReportRoutes as ReportRoutesEnum } from "routes/enums";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { theme } from "lib/theme";

import { useLang, useSurveys } from "core/hooks";
import { IconType } from "./enums";
import { HomeIcon } from "./interfaces";
import IconLink from "./IconLink";
import IconMenu from "./IconMenu";
import { Translation } from "core/languages/interfaces";
import { useUser } from "core/context/user/useUser";
import { useOrganization } from "core/context/organization/useOrganization";

const useStyles = makeStyles(() => {
    return {
        homeContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.background.default,
            height: "100%"
        },
        messages: {
            height: 68,
            background: theme.palette.background.paper,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: `1px solid ${theme.palette.background.default}`
        },
        icons: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 48
        }
    };
});

// square options on the home page
const getHomeIconList = (
    user: UserInfo,
    lang: Translation,
    hasSurvey: boolean,
    orgId: number,
    isDashboardEnabled: boolean
): HomeIcon[] => {
    return [
        {
            label: lang.surveys,
            imageUrl: "/images/surveys.svg",
            iconType: IconType.link,
            isAccessGranted: getRoute(MainRoutesEnum.createSurveys, MainRoutes(user)).isAccessGranted,
            linkTo: `/${MainRoutesEnum.createSurveys}`
        },
        {
            label: lang.reports,
            imageUrl: "/images/reports.svg",
            iconType: IconType.menu,
            isAccessGranted: getRoute(ReportRoutesEnum.snapshot, ReportRoutes(user)).isAccessGranted,
            menuOptions: [
                {
                    label: lang.dashboard,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.userDashboard}`,
                    hidden: !isDashboardEnabled && !user.isTalentMapAdmin,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.responseRate,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.responseRate}`,
                    hidden: user.isRestricted
                },
                {
                    label: lang.snapshot,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.snapshot}`,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.heatmap,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.heatmap}`,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.comments,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.comment}`,
                    hidden: !user.isCommentViewer,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.insights,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.insight}`,
                    hidden: !user.isInsightsEnabled && !user.isTalentMapAdmin,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.correlations,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.correlation}`,
                    hidden: user.isRestricted,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                },
                {
                    label: lang.orgChart,
                    linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.orgChart}`,
                    hidden: user.isRestricted,
                    disabled: !hasSurvey,
                    disableMessage: [
                        orgId === -1
                            ? lang.noOrganizationSelected
                            : user.isSiteAdmin
                              ? lang.reportWillBeUnlockSurveyCreated
                              : lang.reportWillBeUnlockReportViewer,
                        orgId === -1 ? "" : user.isSiteAdmin ? lang.startSurveyClickSurveyOrUploadSurvey : ""
                    ]
                }
            ]
        },
        {
            label: lang.admin,
            imageUrl: "/images/admin.svg",
            iconType: IconType.link,
            isAccessGranted: getRoute(MainRoutesEnum.admin, MainRoutes(user)).isAccessGranted,
            linkTo: `/${MainRoutesEnum.admin}`
        }
    ];
};

const Home = (): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const { organizations } = useOrganization();
    const { surveys } = useSurveys();
    const selectedOrgId = user.settings.selectedOrganization.id;
    let isDashboardEnabled = false;
    const selectedOrganizations = organizations.filter(organization => organization.id === selectedOrgId);
    if (selectedOrganizations.length > 0) {
        const selectedOrganization = selectedOrganizations[0];
        isDashboardEnabled = selectedOrganization.isDashboardEnabled;
    }
    const homeIconList = getHomeIconList(
        user,
        lang,
        surveys.length > 0,
        user.settings.selectedOrganization.id,
        isDashboardEnabled
    );
    const history = useHistory();

    const RenderItem = ({ item }: { item: HomeIcon }): ReactElement => {
        switch (item.iconType) {
            case IconType.link:
                return <IconLink icon={item} />;
            case IconType.menu:
                return <IconMenu icon={item} />;
            default:
                return <></>;
        }
    };

    const hasHomeAccess = user.isSiteAdmin || user.isSurveyCreator || user.isTalentMapAdmin || !user.isRestricted;

    useEffect(() => {
        const hasAdminPermission = user.isSiteAdmin || user.isSurveyCreator || user.isTalentMapAdmin;

        if (!hasAdminPermission) {
            if (user.isRestricted) {
                history.push(`/${MainRoutesEnum.reports}/${ReportRoutesEnum.snapshot}`);
            }
        }
    }, [user, history]);

    return (
        <div className={classes.homeContainer} data-testid="home-page-container">
            {homeIconList.map(
                (item: HomeIcon) =>
                    item.disabled?.isDisabled &&
                    item.isAccessGranted && (
                        <div className={classes.messages} key={item.label}>
                            {item.disabled?.message.map((str: string, index: number) => (
                                <Typography key={index}>{str}</Typography>
                            ))}
                        </div>
                    )
            )}
            {hasHomeAccess && (
                <div className={classes.icons}>
                    {homeIconList.map(
                        (item: HomeIcon) => item.isAccessGranted && <RenderItem key={item.label} item={item} />
                    )}
                </div>
            )}
        </div>
    );
};

export default Home;

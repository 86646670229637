import React, { PropsWithChildren } from "react";
import { ApolloProvider } from "@apollo/client";
import initApollo from "api/ApolloClient";

import { useAuth } from "../auth/useAuth";

export const ApolloConfig = ({ children }: PropsWithChildren) => {
    const { token } = useAuth();
    const graphClient = initApollo(`Bearer ${token}`);

    return <ApolloProvider client={graphClient.apolloClient}>{children}</ApolloProvider>;
};

import { useContext } from "react";

import { SurveyInfo } from "../../components/admin/results/interfaces";
import { CompassContext } from "../context/CompassContext";
import { CompassContextActions } from "../context/actions";

type ReturnType = {
    surveys: SurveyInfo[];
    setSurveyList: (surveys: SurveyInfo[]) => void;
    updateSingleSurvey: (surveyId: SurveyInfo["id"], value: Partial<SurveyInfo>) => void;
};

export const useSurveys = (): ReturnType => {
    const { compassContextState, compassContextDispatch } = useContext(CompassContext);

    const setSurveyList = (surveys: SurveyInfo[]): void => {
        compassContextDispatch({
            type: CompassContextActions.SET_SURVEY_LIST,
            payload: { surveys }
        });
    };

    const updateSingleSurvey = (surveyId: SurveyInfo["id"], value: Partial<SurveyInfo>): void => {
        const updateSurveys = [...compassContextState.surveys];
        const index = updateSurveys.findIndex((s: SurveyInfo) => s.id === surveyId);
        const update = {
            ...updateSurveys[index],
            ...value
        };
        updateSurveys[index] = update;
        setSurveyList(updateSurveys);
    };

    return { surveys: compassContextState.surveys, setSurveyList, updateSingleSurvey };
};

import React from "react";
import MuiTab, { TabProps } from "@mui/material/Tab";

const tabStyle = {
    minWidth: 150
};

const Tab = (props: TabProps) => {
    return <MuiTab {...props} sx={tabStyle} />;
};

export { Tab };

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { useLang } from "core/hooks";
import { ReportingOptions as ReportingOptionsEnum } from "./enums";
import { Typography } from "lib/typography";
import { Radio, RadioGroup } from "lib/radio";
import { FormControlLabel } from "lib/form-control-label";
import { Checkbox } from "lib/checkbox";
import { Box } from "lib/box";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        marginTop: 26
    },
    radioGroupContainer: {
        display: "flex",
        flexDirection: "column",
        width: 300,
        minWidth: 300
    },
    checkboxContainer: {
        display: "flex",
        alignItems: "flex-start",
        marginLeft: 30,
        marginTop: 32
    },
    radioGroup: {
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

type Props = {
    onOptionChange: (a: ReportingOptionsEnum, b: boolean) => void;
    isHiddenForManagers: boolean;
    isIgnored: boolean;
    isRemovedOverallScore: boolean;
    isNominalScale: boolean;
};

const ReportingOptions = (props: Props): ReactElement => {
    const classes = useStyles();
    const { isHiddenForManagers, isIgnored, isRemovedOverallScore, onOptionChange, isNominalScale } = props;

    const { lang } = useLang();

    const radioValue = (): ReportingOptionsEnum => {
        if (isIgnored) return ReportingOptionsEnum.removeFromReports;

        if (isRemovedOverallScore) return ReportingOptionsEnum.excludeFromOverallScore;

        return ReportingOptionsEnum.includeInReports;
    };

    return (
        <div className={classes.container}>
            <div className={classes.radioGroupContainer} data-testid="reporting-options-radio-group">
                <Typography variant="subtitle1" color="grey">
                    {lang.reportingOptions}
                </Typography>
                <RadioGroup
                    className={classes.radioGroup}
                    value={radioValue()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        onOptionChange(event.target.value as ReportingOptionsEnum, true)
                    }
                >
                    <FormControlLabel
                        value={ReportingOptionsEnum.includeInReports}
                        control={<Radio />}
                        label={lang.includeInReports}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={ReportingOptionsEnum.removeFromReports}
                        control={<Radio />}
                        label={lang.removeFromReports}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={ReportingOptionsEnum.excludeFromOverallScore}
                        control={<Radio />}
                        label={lang.noOverallScore}
                        labelPlacement="end"
                        disabled={isNominalScale}
                    />
                </RadioGroup>
            </div>
            <div className={classes.checkboxContainer} data-testid="reporting-options-hidden-for-managers">
                <Box display="flex" alignItems="center">
                    <Checkbox
                        value={ReportingOptionsEnum.isHiddenForManagers}
                        checked={isHiddenForManagers}
                        disabled={isIgnored}
                        onChange={(event): void =>
                            onOptionChange(
                                ReportingOptionsEnum[event.target.value as keyof typeof ReportingOptionsEnum],
                                event.target.checked
                            )
                        }
                    />
                    <Typography
                        style={isIgnored ? { color: theme.palette.text.disabled, fontSize: 16 } : { fontSize: 16 }}
                        variant="body2"
                    >
                        {lang.hideFromRestrictedReportViewer}
                    </Typography>
                </Box>
            </div>
        </div>
    );
};

export default ReportingOptions;

export enum ErrorTypeEnum {
    fileErrorNoMultiFiles = "fileErrorNoMultiFiles",
    fileErrorFormatPNGorSVG = "fileErrorFormatPNGorSVG"
}

export enum colorLegends {
    favorable = "favorable",
    neutral = "neutral",
    unfavorable = "unfavorable"
}

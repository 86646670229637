import { OrganizationInput } from "../interfaces";
export const emptyOrgInput: OrganizationInput = {
    id: -1,
    name: "",
    active: true,
    encodedLogo: "",
    encodedLogoFilename: "",
    thirdPartyLogoBase64: "",
    thirdPartyLogoFileName: "",
    isDashboardEnabled: false,
    isAIEnabled: false
};

import { gql } from "@apollo/client";

export const userQuery = gql`
    query user($id: ID!) {
        user(id: $id) {
            active
            id
            email
            isSiteAdmin
            isSurveyCreator
            isCommentViewer
            isTalentMapAdmin
            byPassSimilarityCheck
            name
            created
            organizationId
            isRestricted
            isInsightsEnabled
            participantEnabled
            userFilters {
                surveyId
                filters
            }
            lastActivity
        }
    }
`;

import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { popoverBackground, textDarkBackgroundMain } from "../../core/styles";
import { theme } from "lib/theme";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";

const useStyles = makeStyles(() => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1),
        background: `${popoverBackground} !important`
    },
    popoverText: {
        color: `${textDarkBackgroundMain} !important`,
        fontSize: 12
    }
}));

type Props = {
    message: string;
    isOpen: boolean;
    anchorEl: HTMLElement | null;
    onPopoverClose: () => void;
};

const NavPopover = (props: Props): ReactElement => {
    const classes = useStyles();
    return (
        <Popover
            className={classes.popover}
            classes={{
                paper: classes.paper
            }}
            open={props.isOpen}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            onClose={props.onPopoverClose}
            disableRestoreFocus
        >
            <Typography className={classes.popoverText}>{props.message}</Typography>
        </Popover>
    );
};

export default NavPopover;

import { useState } from "react";
import { TitleTranslation } from "../interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";

interface OverallDimensionsSize {
    id: string;
    width: number;
    height: number;
    name: string;
}

type OverallDimentsionState = {
    isBenchmarkHidden: boolean;
    setBenchmarkHidden: (newValue: boolean) => void;
    isPreviousPeriodHidden: boolean;
    setPreviousPeriodHidden: (newValue: boolean) => void;
    size: OverallDimensionsSize[];
    setSize: (newValue: OverallDimensionsSize[]) => void;
    selectedOverallDimensionsSize: string;
    setSelectedOverallDimensionsSize: (newValue: string) => void;
    isDirty: boolean;
    titleTranslation: TitleTranslation[];
    setTitleTranslation: (newTitleTranslations: TitleTranslation[]) => void;
};

type InitState = {
    initBenchmarkHidden?: OverallDimentsionState["isBenchmarkHidden"];
    initPreviousPeriodHidden?: OverallDimentsionState["isPreviousPeriodHidden"];
    initSize?: OverallDimentsionState["size"];
    initOverallDimentsionSize?: OverallDimentsionState["selectedOverallDimensionsSize"];
    initTitleTranslation?: OverallDimentsionState["titleTranslation"];
};

export const useOverallDimentsionState = ({
    initBenchmarkHidden,
    initPreviousPeriodHidden,
    initSize,
    initOverallDimentsionSize,
    initTitleTranslation
}: InitState): OverallDimentsionState => {
    const [isBenchmarkHidden, setBenchmarkHidden] = useState(initBenchmarkHidden || false);
    const [isPreviousPeriodHidden, setPreviousPeriodHidden] = useState(initPreviousPeriodHidden || false);
    const [size, setSize] = useState<OverallDimensionsSize[]>(initSize || []);
    const [selectedOverallDimensionsSize, setSelectedOverallDimensionsSize] = useState(initOverallDimentsionSize || "");
    const [titleTranslation, setTitleTranslation] = useState<TitleTranslation[]>(
        initTitleTranslation || defaultItemTitleTranslation
    );
    const [isDirty, setIsDirty] = useState(false);

    const handleBenchmarkDataHidden = (newValue: boolean): void => {
        setBenchmarkHidden(newValue);
        setIsDirty(true);
    };

    const handlePreviousPeriodHidden = (newValue: boolean): void => {
        setPreviousPeriodHidden(newValue);
        setIsDirty(true);
    };

    const updateSize = (newValue: OverallDimensionsSize[]): void => {
        setSize(newValue);
        setIsDirty(true);
    };

    const updateSelectedSingleDimensionOrItemSize = (newValue: string): void => {
        setSelectedOverallDimensionsSize(newValue);
    };

    const handleUpdateTitleTranslation = (newTitleTranslations: TitleTranslation[]): void => {
        setTitleTranslation(newTitleTranslations);
    };
    return {
        isBenchmarkHidden,
        setBenchmarkHidden: handleBenchmarkDataHidden,
        isPreviousPeriodHidden,
        setPreviousPeriodHidden: handlePreviousPeriodHidden,
        size,
        setSize: updateSize,
        selectedOverallDimensionsSize,
        setSelectedOverallDimensionsSize: updateSelectedSingleDimensionOrItemSize,
        isDirty,
        titleTranslation,
        setTitleTranslation: handleUpdateTitleTranslation
    };
};

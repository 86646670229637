import React, { createContext, useEffect, useState, PropsWithChildren, ReactElement } from "react";
import Loading from "../../components/shared/Loading";

type Context = {
    isLoading: boolean;
    handleSetLoading: (a: boolean) => void;
};

const LoadingContext = createContext<Context>({
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetLoading: () => {}
});

const LoadingProvider = ({ children }: PropsWithChildren): ReactElement => {
    const [isLoading, setIsLoaging] = useState(false);

    const handleSetLoading = (update: boolean): void => {
        setIsLoaging(update);
    };
    useEffect(() => {
        return (): void => {
            setIsLoaging(false);
        };
    }, []);

    return (
        <LoadingContext.Provider
            value={{
                isLoading: isLoading,
                handleSetLoading: handleSetLoading
            }}
        >
            {isLoading && <Loading styleProps={{ position: "absolute" }} />}
            {children}
        </LoadingContext.Provider>
    );
};

export { LoadingContext, LoadingProvider };

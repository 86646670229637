import React, { ReactElement, useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import * as _ from "lodash";

import { IconButtonV4 } from "lib/icon-button";
import { useLang } from "core/hooks";
import { useStyles } from "./comments-dimensions.style";
import { CommentState, SurveySectionList } from "../interface";
import { FilterParameter } from "components/filters/interfaces";

import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import RateBar from "components/shared/RateBar";
import { Typography } from "lib/typography";
import { List, ListItemButton } from "lib/list";
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";

type DimensionSort = "null" | "dimension" | "count";
type OrderBy = "asc" | "desc";

type Props = {
    surveyItemsOptions: CommentState["surveyItemsOptions"];
    commentsState: CommentState;
    updateCommentState: (value: Partial<CommentState>) => void;
    onCommentSelect: (surveyId: number, ids: string[], searchTerm: string, filter: FilterParameter[]) => void;
    summaryLoadingStatus: boolean;
};

export const CommentsDimensions = ({
    surveyItemsOptions,
    commentsState,
    onCommentSelect,
    updateCommentState,
    summaryLoadingStatus
}: Props): ReactElement => {
    const [selectedSurveySections, setSelectedSurveySections] = useState<string[]>([]);
    const [sort, setSort] = useState<{ sortBy: DimensionSort; orderBy: OrderBy }>({ sortBy: "null", orderBy: "desc" });
    const { lang } = useLang();
    const { user } = useUser();
    const scrollTarget = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const showCommentDialog =
        surveyItemsOptions &&
        surveyItemsOptions.length > 0 &&
        surveyItemsOptions.filter(option => option.questions && option.questions.some(q => q.questionType == 2))
            .length > 0;

    const onSectionSelected = (id: string): void => {
        resetInfiniteScrollbar();
        if (id) {
            updateCommentState({ selectedCommentId: id, selectedCommentQuestionId: "" });
        }
        const selectedComment = commentsState.surveyItemsOptions.find(x => x.sectionId === id);
        if (selectedComment) {
            const itemIds = selectedComment.questions
                .map(q => q.items.map(i => i.itemId.replace("I", "")))
                .reduce((a, b) => a.concat(b));
            onCommentSelect(
                user.settings.comment.surveySelected,
                itemIds,
                commentsState.searchTerm,
                commentsState.currentFilter.items
            );
        }
    };

    const onSelectAllComments = (): void => {
        resetInfiniteScrollbar();
        if (showCommentDialog) {
            const itemIds = commentsState.surveyItemsOptions
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .map(q => q.items.map(i => i.itemId.replace("I", "")))
                .reduce((a, b) => a.concat(b));
            onCommentSelect(
                user.settings.comment.surveySelected,
                itemIds,
                commentsState.searchTerm,
                commentsState.currentFilter.items
            );
            updateCommentState({ selectedCommentId: "AllComments", selectedCommentQuestionId: "" });
        }
    };

    const onQuestionSelected = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string): void => {
        resetInfiniteScrollbar();
        e.stopPropagation();
        if (id) {
            updateCommentState({ selectedCommentId: "", selectedCommentQuestionId: id });
        }
        const selectedComment = commentsState.surveyItemsOptions
            .map(o => o.questions)
            .reduce((a, b) => a.concat(b))
            .find(x => x.questionId.toString() === id);
        if (selectedComment) {
            const selectedItems = selectedComment.items.map(i => i.itemId.replace("I", ""));
            onCommentSelect(
                user.settings.comment.surveySelected,
                selectedItems,
                commentsState.searchTerm,
                commentsState.currentFilter.items
            );
        }
    };

    const resetInfiniteScrollbar = (): void => {
        if (scrollTarget && scrollTarget.current) {
            scrollTarget.current.scrollTo(0, 0);
        }
    };

    const handleCollapseAll = (): void => {
        setSelectedSurveySections([]);
    };

    const handleExpandAll = (): void => {
        if (commentsState.surveyItemsOptions && commentsState.surveyItemsOptions.length > 0) {
            const surveySectionIds = commentsState.surveyItemsOptions.map(section => section.sectionId);
            setSelectedSurveySections(surveySectionIds);
        }
    };

    const handleExpandSection = (id: string): void => {
        if (selectedSurveySections.find(s => s === id)) {
            setSelectedSurveySections(selectedSurveySections.filter(i => i !== id));
        } else {
            setSelectedSurveySections([...selectedSurveySections, id]);
        }
    };

    const getCommentSectionPercentage = (id: string): number => {
        if (!commentsState.commentCountMapping) return 0;
        const commentSectionPercentageMapping = commentsState.commentCountMapping.find(
            section => section.sectionId.toString() === id.replace("S", "")
        );
        return commentSectionPercentageMapping !== undefined
            ? Math.round(commentSectionPercentageMapping.percent * 100) / 100
            : 0;
    };
    const getMaxSectionPercentage = (): number => {
        if (!commentsState.commentCountMapping) return 100;
        const percentage = commentsState.commentCountMapping.map(section => section.percent) as number[];
        return Math.max(...percentage) === 0 ? 100 : Math.max(...percentage);
    };

    const getCommentSectionCount = (id: string): number => {
        if (!commentsState.commentCountMapping) return 0;
        const commentSectionCountMapping = commentsState.commentCountMapping.find(
            section => section.sectionId.toString() === id.replace("S", "")
        );
        return commentSectionCountMapping !== undefined ? commentSectionCountMapping.count : 0;
    };
    const getCommentItemPercentage = (sectionId: string, questionId: number): number => {
        if (!commentsState.commentCountMapping) return 0;
        const commentSectionPercentageMapping = commentsState.commentCountMapping.find(
            section => section.sectionId.toString() === sectionId.replace("S", "")
        );
        if (commentSectionPercentageMapping !== undefined) {
            const questionFound = commentSectionPercentageMapping.questions.find(q => q.questionId === questionId);
            if (questionFound !== undefined) {
                return Math.round(questionFound.percent * 100) / 100;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };
    const getCommentItemCount = (sectionId: string, questionId: number): number => {
        if (!commentsState.commentCountMapping) return 0;
        const commentSectionPercentageMapping = commentsState.commentCountMapping.find(
            section => section.sectionId.toString() === sectionId.replace("S", "")
        );
        if (commentSectionPercentageMapping !== undefined) {
            const questionFound = commentSectionPercentageMapping.questions.find(q => q.questionId === questionId);
            if (questionFound !== undefined) {
                return questionFound.count;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const getTotalCommentsCount = (): number => {
        if (!commentsState.commentCountMapping) return 0;
        return commentsState.commentCountMapping.map(s => s.count).reduce((a, b) => a + b, 0);
    };

    const handleSort = (sortBy: DimensionSort): void => {
        const orderBy = sort.orderBy === "desc" ? "asc" : "desc";
        setSort({ sortBy, orderBy });
    };

    const sortDimensions = (): SurveySectionList[] => {
        const copySurveyItemOptions = _.cloneDeep(commentsState.surveyItemsOptions);
        if (sort.sortBy === "dimension") {
            if (sort.orderBy === "desc") {
                return copySurveyItemOptions.sort((a, b) => b.sectionOrder - a.sectionOrder);
            }
            return copySurveyItemOptions.sort((a, b) => a.sectionOrder - b.sectionOrder);
        }

        if (sort.sortBy === "count") {
            if (sort.orderBy === "desc") {
                return copySurveyItemOptions.sort(
                    (a, b) => getCommentSectionCount(b.sectionId) - getCommentSectionCount(a.sectionId)
                );
            }
            return copySurveyItemOptions.sort(
                (a, b) => getCommentSectionCount(a.sectionId) - getCommentSectionCount(b.sectionId)
            );
        }
        return copySurveyItemOptions;
    };

    useEffect((): void => {
        if (commentsState.selectedCommentId === "AllComments") {
            setSelectedSurveySections([]);
        } else if (commentsState.selectedCommentId !== "") {
            setSelectedSurveySections([]);
        } else if (commentsState.selectedCommentId == "" && commentsState.selectedCommentQuestionId) {
            const mappedSurveySections = commentsState.surveyItemsOptions.map(option => ({
                sectionId: option.sectionId,
                questions: option.questions.map(q => q.questionId.toString())
            }));
            const selectedSurveySection = mappedSurveySections.find(s =>
                s.questions.includes(commentsState.selectedCommentQuestionId)
            );
            if (selectedSurveySection) {
                setSelectedSurveySections([selectedSurveySection.sectionId]);
            }
        }
    }, []);

    return (
        <>
            <div className={classes.dimensionHeader}>
                <div className={classes.dimensionsAndItems}>
                    <Typography variant="h6" style={{ fontSize: 16, fontWeight: 500, paddingLeft: 36 }}>
                        {lang.dimensionsAndItems}
                    </Typography>
                    <div className={classes.commentCountTextIcon} onClick={(): void => handleSort("dimension")}>
                        {sort.orderBy === "desc" && sort.sortBy === "dimension" ? (
                            <ArrowUpwardIcon
                                fontSize={"small"}
                                color={sort.sortBy === "dimension" ? "action" : "disabled"}
                            />
                        ) : (
                            <ArrowDownwardIcon
                                fontSize={"small"}
                                color={sort.sortBy === "dimension" ? "action" : "disabled"}
                            />
                        )}
                    </div>
                </div>
                <div className={classes.commentCount}>
                    <div className={classes.commentCountText}>
                        <Typography variant="h6" style={{ fontSize: 16, fontWeight: 500, paddingLeft: 16 }}>
                            {lang.commentCount}
                        </Typography>
                        <div className={classes.commentCountTextIcon} onClick={(): void => handleSort("count")}>
                            {sort.orderBy === "desc" && sort.sortBy === "count" ? (
                                <ArrowUpwardIcon
                                    fontSize={"small"}
                                    color={sort.sortBy === "count" ? "action" : "disabled"}
                                />
                            ) : (
                                <ArrowDownwardIcon
                                    fontSize={"small"}
                                    color={sort.sortBy === "count" ? "action" : "disabled"}
                                />
                            )}
                        </div>
                    </div>
                    <div className={classes.chartActions}>
                        <IconButtonV4
                            path={mdiArrowExpandVertical}
                            onClick={handleExpandAll}
                            disabled={
                                selectedSurveySections.length > 0 &&
                                selectedSurveySections.length === commentsState.surveyItemsOptions.length
                            }
                            tooltip={lang.expandAll}
                            dataTestid="comment-expandAll"
                        />
                        <IconButtonV4
                            path={mdiArrowCollapseVertical}
                            onClick={handleCollapseAll}
                            disabled={selectedSurveySections.length === 0}
                            tooltip={lang.collapseAll}
                            dataTestid="comment-collapseAll"
                        />
                    </div>
                </div>
            </div>
            <div className={classes.scrollableBar}>
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <div className={classes.listItemWrap}>
                        <ListItemButton
                            style={{
                                width: "400px",
                                maxWidth: "400px",
                                borderRight: theme.border.main,
                                display: "block"
                            }}
                            onClick={onSelectAllComments}
                            selected={commentsState.selectedCommentId === "AllComments"}
                            data-testid={`selectField-AllComments`}
                            disabled={summaryLoadingStatus === true}
                        >
                            <div className={classes.listItemTextWrap}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        paddingLeft: 18
                                    }}
                                >
                                    {lang.allComments}
                                </Typography>
                            </div>
                        </ListItemButton>
                        <div className={classes.rateBarWrap}>
                            <Typography style={{ paddingLeft: 2 }}>{getTotalCommentsCount()}</Typography>
                        </div>
                    </div>
                    {sortDimensions()
                        .filter(option => option.questions && option.questions.some(q => q.questionType == 2))
                        .map(e => (
                            <div key={e.sectionId}>
                                <div className={classes.listItemWrap}>
                                    <ListItemButton
                                        selected={e.sectionId === commentsState.selectedCommentId}
                                        style={{
                                            width: "400px",
                                            maxWidth: "400px",
                                            borderRight: theme.border.main,
                                            display: "block",
                                            paddingLeft: "8px"
                                        }}
                                        data-testid={`selectField-${e.title}`}
                                        disabled={summaryLoadingStatus === true}
                                    >
                                        <ExpandMoreIcon
                                            className={
                                                selectedSurveySections.includes(e.sectionId)
                                                    ? classes.expandMoreIcon
                                                    : classes.expandLessIcon
                                            }
                                            onClick={(event): void => {
                                                event.stopPropagation();
                                                handleExpandSection(e.sectionId);
                                            }}
                                        />
                                        <div
                                            className={classes.listItemTextWrap}
                                            onClick={(): void => {
                                                onSectionSelected(e.sectionId);
                                            }}
                                        >
                                            <TruncateDisplay maxLabelLength={35} title={e.title}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    {e.title}
                                                </Typography>
                                            </TruncateDisplay>
                                        </div>
                                    </ListItemButton>
                                    <div className={classes.rateBarWrap}>
                                        <div className={classes.rateBar}>
                                            <RateBar
                                                barWidth={getCommentSectionPercentage(e.sectionId)}
                                                rate={100 / getMaxSectionPercentage()}
                                            />
                                        </div>
                                        <Typography sx={{ alignSelf: "center" }} style={{ paddingLeft: 6 }}>
                                            {getCommentSectionCount(e.sectionId)}
                                        </Typography>
                                    </div>
                                </div>
                                {selectedSurveySections.includes(e.sectionId) &&
                                    e.questions.map(q => (
                                        <div className={classes.listItemWrap} key={q.questionId}>
                                            <ListItemButton
                                                onClick={(e): void => onQuestionSelected(e, q.questionId.toString())}
                                                selected={
                                                    q.questionId.toString() === commentsState.selectedCommentQuestionId
                                                }
                                                style={{
                                                    width: "400px",
                                                    maxWidth: "400px",
                                                    borderRight: theme.border.main,
                                                    display: "block"
                                                }}
                                                data-testid={`selectQuestion-${q.questionTitle}`}
                                                disabled={summaryLoadingStatus === true}
                                            >
                                                <div className={classes.listItemQuestionTextWrap}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: 300
                                                        }}
                                                    >
                                                        {q.questionTitle}
                                                    </Typography>
                                                </div>
                                            </ListItemButton>
                                            <div className={classes.rateBarWrap}>
                                                <div className={classes.rateBar}>
                                                    <RateBar
                                                        barWidth={getCommentItemPercentage(e.sectionId, q.questionId)}
                                                        rate={100 / getMaxSectionPercentage()}
                                                    />
                                                </div>
                                                <Typography style={{ paddingLeft: 6 }}>
                                                    {getCommentItemCount(e.sectionId, q.questionId)}
                                                </Typography>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                </List>
            </div>
        </>
    );
};

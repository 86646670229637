import { gql } from "@apollo/client";

export const sgSurveyQuery = gql`
    query sgsurveys($orgId: ID!) {
        sgsurveys(orgId: $orgId) {
            id
            translations {
                name
            }
        }
    }
`;

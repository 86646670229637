import { SnapshotActions } from "../action/Snapshot.action";
import { State } from "../Snapshot";
import { Action } from "core/interfaces";

export const snapshotReducer = (state: State, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        case SnapshotActions.SELECT_SURVEY:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                snapshotData: payload.snapshotData,
                isFilterAllowed: payload.isFilterAllowed,
                confidentiality: payload.confidentiality,
                currentFilter: payload.currentFilter,
                surveySections: payload.surveySections,
                lastUpdate: payload.lastUpdate,
                showOrgOverall: payload.showOrgOverall,
                selectedSurveySections: payload.selectedSurveySections,
                showOtherComparison: payload.showOtherComparison,
                otherComparisonSurveyId: payload.otherComparisonSurveyId,
                otherComparisonFilter: payload.otherComparisonFilter,
                columnLabel: payload.columnLabel,
                customizedShowBenchmark: payload.customizedShowBenchmark,
                customizedShowPP: payload.customizedShowPP
            };
        case SnapshotActions.OPEN_EXPORT:
            return { ...state, isExportDialogOpen: payload.isExportDialogOpen };
        case SnapshotActions.OPEN_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case SnapshotActions.SET_CONFIDENTIALITY:
            return { ...state, confidentiality: payload.confidentiality };
        case SnapshotActions.SET_FILTER:
            return { ...state, currentFilter: payload.currentFilter };
        case SnapshotActions.SET_FILTER_ALLOWED:
            return { ...state, isFilterAllowed: payload.isFilterAllowed };
        case SnapshotActions.SET_SNAPSHOT_DATA:
            return { ...state, snapshotData: payload.snapshotData };
        case SnapshotActions.SET_STATUS:
            return { ...state, status: payload.status };
        case SnapshotActions.DATA_ONLOAD:
            return {
                ...state,
                snapshotData: payload.snapshotData,
                isFilterAllowed: payload.isFilterAllowed,
                confidentiality: payload.confidentiality,
                currentFilter: payload.currentFilter,
                surveySections: payload.surveySections,
                lastUpdate: payload.lastUpdate,
                showOrgOverall: payload.showOrgOverall,
                status: payload.status,
                customizedShowBenchmark: payload.customizedShowBenchmark,
                customizedShowPP: payload.customizedShowPP,
                compareGroupIds: payload.compareGroupIds
            };
        case SnapshotActions.SELECT_SURVEY_SECTION:
            return {
                ...state,
                selectedSurveySections: [...state.selectedSurveySections, payload.selectedSurveySection]
            };
        case SnapshotActions.UNSELECT_SURVEY_SECTION:
            return {
                ...state,
                selectedSurveySections: [...state.selectedSurveySections].filter(
                    surveySection => surveySection.sectionId !== payload.unSelectedSurveySection.sectionId
                )
            };
        case SnapshotActions.SHOW_ORG_OVERALL:
            return {
                ...state,
                showOrgOverall: payload.showOrgOverall
            };
        case SnapshotActions.APPLY_PRESELECTION:
            return {
                ...state,
                showOrgOverall: payload.showOrgOverall,
                showOtherComparison: payload.showOtherComparison,
                columnLabel: payload.columnLabel,
                customizedShowBenchmark: payload.customizedShowBenchmark,
                customizedShowPP: payload.customizedShowPP,
                otherComparisonSurveyId: payload.otherComparisonSurveyId,
                otherComparisonFilter: payload.otherComparisonFilter
            };
        case SnapshotActions.SET_COMPARE_OPTIONS:
            return {
                ...state,
                compareOptions: payload.compareOptions
            };
        case SnapshotActions.SHOW_CONFIRMATION_DIALOG:
            return {
                ...state,
                showConfirmationDialog: payload.showConfirmationDialog
            };
        case SnapshotActions.SELECT_ALL_SURVEY_SECTIONS:
            return {
                ...state,
                selectedSurveySections: payload.selectedSurveySections
            };
        case SnapshotActions.UNSELECT_ALL_SURVEY_SECTIONS:
            return {
                ...state,
                selectedSurveySections: payload.selectedSurveySections
            };
        case SnapshotActions.SET_BULK_EXPORT:
            return {
                ...state,
                isBulkExport: payload.isBulkExport
            };
        case SnapshotActions.SNACKBAR_OPEN_CLOSE:
            return {
                ...state,
                snackBar: { ...payload.snackBar }
            };
        default:
            return {
                ...state
            };
    }
};

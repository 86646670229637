import { filterConnectorToBackEnd } from "components/filters/helper";
import { FilterParameter } from "components/filters/interfaces";
import ConfigService from "./Config.service";
import {
    InsightService as IInsightService,
    InsightResponse,
    InsightLookdownByDemographic,
    InsightCommentResponse,
    InsightWordCloudResponse
} from "./interfaces";

export default class InsightService extends ConfigService implements IInsightService {
    getInsight(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        languageCode: string
    ): Promise<InsightResponse> {
        const postUrl = this.makeUrl("/insights/overall");
        const params = {
            surveyId,
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            languageCode
        };
        return this.post(postUrl, params);
    }
    getInsightLookdown(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        languageCode: string,
        demographicFieldId: number | null,
        sectionId: number,
        compareOverall: boolean,
        compareFilters: FilterParameter[] | null
    ): Promise<InsightLookdownByDemographic[]> {
        const postUrl = this.makeUrl("/insights/lookdown");
        const params = {
            surveyId,
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            demographicFieldId,
            sectionId,
            compareOverall,
            compareFilters
        };
        return this.post(postUrl, params);
    }

    getInsightComment(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        languageCode: string,
        similarText: string,
        topCommentCount: number,
        itemId: number
    ): Promise<InsightCommentResponse> {
        const postUrl = this.makeUrl("/insights/comments");
        const params = {
            surveyId,
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            similarText,
            topCommentCount,
            itemId
        };
        return this.post(postUrl, params);
    }

    getInsightWordCloud(
        surveyId: number,
        hierarchyId: number,
        cardId: number,
        filters: FilterParameter[],
        languageCode: string,
        similarText: string,
        wordCount: number,
        commentCount: number,
        itemId: number
    ): Promise<InsightWordCloudResponse> {
        const postUrl = this.makeUrl("/insights/wordcloud");
        const params = {
            surveyId,
            hierarchyId,
            cardId,
            filters: filterConnectorToBackEnd(filters),
            languageCode,
            similarText,
            wordCount,
            commentCount,
            itemId
        };
        return this.post(postUrl, params);
    }

    hideInsightComment(itemId: number, respondentId: number, surveyFieldId: number): Promise<void> {
        const postUrl = this.makeUrl("/insights/comments/hide");
        const params = {
            itemId,
            respondentId,
            surveyFieldId
        };
        return this.post(postUrl, params);
    }

    resetInsightComment(itemId: number): Promise<void> {
        const postUrl = this.makeUrl("/insights/comments/reset");
        const params = {
            itemId
        };
        return this.post(postUrl, params);
    }
}

import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import { Typography } from "lib/typography";
import { dateFormatWithTime } from "core/constants";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 12
    }
}));

type Props = {
    time: string | null;
};

const CellTime: FC<Props> = ({ time }) => {
    const classes = useStyles();

    if (time === null) {
        return <></>;
    }
    return (
        <Typography variant="body2" className={classes.text}>
            {`${dayjs(time).format(dateFormatWithTime)} UTC`}
        </Typography>
    );
};

export default CellTime;

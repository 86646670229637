import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import {
    mdiArrowCollapseVertical,
    mdiArrowExpandVertical,
    mdiRotate3dVariant,
    mdiPlusMinusVariant,
    mdiPercentOutline
} from "@mdi/js";
import { IconButtonV4 } from "lib/icon-button";
import { theme } from "lib/theme";

interface ExpandCollapseProps {
    handleExpandAll: () => void;
    handleCollapseAll: () => void;
    handleSwitchAxis: () => void;
    originalLength: number;
    currentLength: number;
    expandedAllLength: number;
    showDeltaValue: boolean;
    switchDelta: (a: boolean) => void;
}

const useStyles = makeStyles(() => ({
    container: {
        height: 270,
        width: 322,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white"
    }
}));

export const ExpandCollapseBtnContainer = (props: ExpandCollapseProps): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [isAllCollapsed, setCollapseAll] = useState(true);
    const [isAllExpanded, setExpandAll] = useState(false);
    const { originalLength, currentLength, expandedAllLength } = props;

    useEffect(() => {
        setCollapseAll(originalLength > 0 && currentLength === originalLength);
        setExpandAll(originalLength > 0 && currentLength === expandedAllLength);
    }, [props.currentLength]);
    return (
        <div className={classes.container}>
            <IconButtonV4
                path={mdiPlusMinusVariant}
                onClick={(): void => props.switchDelta(true)}
                tooltip={lang.value}
                color={theme.palette.primary.main}
                dataTestid="heatmap-swithch-delta"
                disabled={props.showDeltaValue}
            />
            <IconButtonV4
                path={mdiPercentOutline}
                onClick={(): void => props.switchDelta(false)}
                color={theme.palette.primary.main}
                tooltip={lang.percentage}
                dataTestid="heatmap-swithch-percentage"
                disabled={!props.showDeltaValue}
            />
            <IconButtonV4
                path={mdiRotate3dVariant}
                onClick={props.handleSwitchAxis}
                tooltip={lang.switchAxis}
                color={theme.palette.primary.main}
                dataTestid="heatmap-switchAxis"
            />
            <IconButtonV4
                path={mdiArrowExpandVertical}
                onClick={props.handleExpandAll}
                tooltip={lang.expandAll}
                color={theme.palette.primary.main}
                dataTestid="heatmap-expandAll"
                disabled={isAllExpanded}
            />
            <IconButtonV4
                path={mdiArrowCollapseVertical}
                onClick={props.handleCollapseAll}
                color={theme.palette.primary.main}
                tooltip={lang.collapseAll}
                dataTestid="heatmap-collapseAll"
                disabled={isAllCollapsed}
            />
        </div>
    );
};

import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import SettingsIcon from "@mui/icons-material/Settings";
import saveAs from "file-saver";
import * as _ from "lodash";

import {
    useLang,
    useSurveys,
    useLoading,
    useQueryWithPromise,
    useOrgChartService,
    useUpdateUserSettings,
    useCommentsService
} from "core/hooks";
import { useCommentStyles } from "./comment.style";
import { emptySurveyInfo } from "components/admin/results/init";
import { SurveyInfo } from "components/admin/results/interfaces";
import { CommentState, OverallSentimentScores, SurveySectionList } from "./interface";
import { userSettingMutation } from "api/mutations";
import { commentOnLoad, demographicFieldsAndValuesQuery } from "api/queries";
import Error500 from "components/errorPage/Error500";
import { ComponentStatus } from "core/enums";
import SearchBar, { SearchBarHandle } from "managerPortal/components/shared/SearchBar";
import SemanticSearchBar, { SemanticSearchBarHandle } from "managerPortal/components/shared/SemanticSearch";
import { DialogExportSlide } from "managerPortal/components/shared/DialogExportSlide";
import Filters from "components/filters/Filters";
import { initFilter } from "components/filters/inits";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { FilterMode, FilterType } from "components/filters/enums";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import FilterChip from "components/shared/FilterChip";
import { HrisFieldInfo } from "managerPortal/interfaces";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { CommentSettingPopup } from "./settings/CommentSettingPopup";
import { CommentSummarySettingPopup } from "./settings/CommentSummarySettingPopup";
import type { CommentSettingsHandle, APIPreSelection } from "./settings/types";
import { ReportsLayout } from "../layout/ReportsLayout";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { getCommentsSettings, getMapSurveySelected, mapSurveysToAutocompleteOption, sortArray } from "core/helpers";
import { BulkReportType } from "api/rest/BulkReportStatus";
import { UserCommentSettings, UserSettings } from "components/admin/users/interface";
import { DialogBulkExportSlide } from "managerPortal/components/shared/DialogBulkExportSlide";
import { initCommentsSettings } from "components/admin/users/initialVariables";
import { CommentCount } from "managerPortal/components/comments/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { initialState } from "./init/comments.inits";
import { Snackbar as SnackbarType } from "./interface";
import { CommentsList } from "./comments-list/comments-list";
import { CommentsSummary, CommentSummaryHandle } from "./comments-summary/comments-summary";
import { CommentsAsk } from "./comments-ask/comments-ask";
import { CommentsWordCloud } from "./word-cloud/comments-word-cloud";
import { CommentsDimensions } from "./comments-dimentsions/comments-dimensions";
import { getCommentItemCount } from "./helpers/get-comment-item-count";
import { getCommentSectionCount } from "./helpers/get-comment-section-count";
import { getTotalCommentsCount } from "./helpers/get-total-comment-count";
import { AutoTranslationCalculationStatus, SentimentCalculationStatus } from "./constants/enums";
import { getUserSettings } from "./helpers/get-user-settings";
import { getSelectedComment } from "./helpers/get-selected-comment";
import { getItemsId, getItemsIdInNumber } from "./helpers/get-items-id";
import {
    CommentsPayload,
    ExportCommentsPayload,
    UpdateCommentSettingsPayload,
    CommentSummaryExportPayload
} from "api/rest/interfaces";
import Loading from "components/shared/Loading";
import { Snackbar } from "lib/snackbar";
import { Tabs, Tab } from "lib/tabs";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Badge } from "lib/badge";
import { useUser } from "core/context/user/useUser";
import { useOrganization } from "core/context/organization/useOrganization";
import { Checkbox } from "lib/checkbox";
import { Box } from "lib/box";
import { Button } from "lib/button";

export enum TabOptions {
    comments,
    summary,
    ask
}

export enum SearchType {
    anyWord = "anyWord",
    exactMatch = "exactMatch",
    semanticSearch = "semanticSearch"
}

const Comment = (): ReactElement => {
    const [commentState, setCommentState] = useState(initialState);
    const [tabValue, setTabValue] = useState<TabOptions>(TabOptions.comments);
    const [searchType, setSearchType] = useState<SearchType>(SearchType.exactMatch);
    const { lang, languageCode } = useLang();
    const { surveys, updateSingleSurvey } = useSurveys();
    const { user, setUser } = useUser();
    const { organizations } = useOrganization();
    const { setLoading } = useLoading();
    const { setInitUserSetting } = useUpdateUserSettings();
    const fetchHris = useQueryWithPromise(commentOnLoad);
    const fetchDemographics = useQueryWithPromise(demographicFieldsAndValuesQuery);
    const orgChartService = useOrgChartService();
    const commentsService = useCommentsService();
    const scrollTarget = useRef<HTMLDivElement>(null);
    const settingTargetRef = useRef<HTMLDivElement>(null);
    const searchBarRef = useRef<SearchBarHandle>(null);
    const semanticSearchBarRef = useRef<SemanticSearchBarHandle>(null);
    const commentSettingPopupRef = useRef<CommentSettingsHandle>(null);
    const commentSummaryRef = useRef<CommentSummaryHandle>(null);
    const numberPerLoad = 200;
    let hrisList: HrisFieldInfo[] = [];
    const userSettings = getUserSettings(user);
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo], user.isTalentMapAdmin);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, user.settings.comment.surveySelected);
    const selectedOrgId = user.settings.selectedOrganization.id;
    let isAIEnabled = false;
    const selectedOrganizations = organizations.filter(organization => organization.id === selectedOrgId);
    if (selectedOrganizations.length > 0) {
        const selectedOrganization = selectedOrganizations[0];
        isAIEnabled = selectedOrganization.isAIEnabled;
    }
    const updateState = (newValues: Partial<CommentState>): void => {
        setCommentState(prevState => {
            return {
                ...prevState,
                ...newValues
            };
        });
    };

    const classes = useCommentStyles();

    const shouldShowEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.comment) {
            return true;
        }

        if (user.settings.comment) {
            if (user.settings.comment.surveySelected === emptySurveyInfo.id) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.comment.surveySelected) === undefined) {
                return true;
            }
        }

        if (user.settings.comment.surveySelected === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };

    const showCommentDialog =
        commentState.surveyItemsOptions &&
        commentState.surveyItemsOptions.length > 0 &&
        commentState.surveyItemsOptions.filter(
            option => option.questions && option.questions.some(q => q.questionType == 2)
        ).length > 0;

    const [updateUserSetting] = useMutation(userSettingMutation);
    const updateUserSettings = (settings: UserSettings): void => {
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const [loadDimensionItems, { error: errorDimensionItem, loading: loadingDimensionItems }] = useLazyQuery(
        commentOnLoad,
        {
            onCompleted: data => {
                if (data) {
                    updateState({ surveyItemsOptions: data.surveyItemFields });
                    const sectionList: SurveySectionList[] = [...data.surveyItemFields];
                    initCommentValue(sectionList, user.settings.comment.surveySelected, initFilter);
                }
            }
        }
    );

    const [loadDemographics, { loading: loadingDemographics }] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                updateState({ contactFields: sortedDemographic });
            }
        }
    });

    const handleSurveySelected = (id: number): void => {
        resetInfiniteScrollbar();
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== user.settings.comment.surveySelected) {
            setTabValue(TabOptions.comments);
            setSearchType(SearchType.exactMatch);
            updateState({ currentFilter: initFilter, status: ComponentStatus.loading, searchTerm: "" });
            loadDimensionItems({ variables: { surveyId: id, languageCode, includeQuestionTypes: 1 } });
            loadDemographics({ variables: { surveyId: id, languageCode } });
        }
        if (!user.settings.comment || id !== user.settings.comment.surveySelected) {
            setInitUserSetting(id);
        }
    };

    const restoreComment = async (surveyId: number, filterItems: FilterParameter[]): Promise<void> => {
        const loadedHris = await fetchHris({ surveyId: surveyId, languageCode, includeQuestionTypes: 1 });

        if (loadedHris) {
            const { data } = loadedHris;
            let restoredFilter = user.settings.filtersItems
                ? { ...commentState.currentFilter, items: filterItems }
                : initFilter;
            const sectionList: SurveySectionList[] = [...data.surveyItemFields];
            const loadedDemographics = await fetchDemographics({ surveyId: surveyId, languageCode });
            if (loadedDemographics) {
                const { data } = loadedDemographics;
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                restoredFilter.items
                    .filter(item => item.filterType === FilterType.contactField)
                    .forEach(item => {
                        if (!hrisList.map(list => list.fieldId).includes(item.field.id as number)) {
                            restoredFilter = initFilter;
                        }
                    });
                updateState({
                    currentFilter: restoredFilter,
                    surveyItemsOptions: sectionList,
                    contactFields: sortedDemographic,
                    searchTerm: "",
                    exportIncludeWordCloud: user.settings.comment.exportIncludeWordCloud
                });
                setSearchType(SearchType.exactMatch);
                initCommentValue(sectionList, surveyId, restoredFilter);
            }
        } else {
            updateState({ status: ComponentStatus.success });
        }
    };

    const getSurveyItemOptions = (sectionList: SurveySectionList[], sections: CommentCount[]): SurveySectionList[] => {
        const copySurveyItemOptions = _.cloneDeep(sectionList);
        const getSectionCount = (id: string): number => {
            const commentSectionCountMapping = sections.find(
                section => section.sectionId.toString() === id.replace("S", "")
            );
            return commentSectionCountMapping !== undefined ? commentSectionCountMapping.count : 0;
        };
        copySurveyItemOptions.sort((a, b) => getSectionCount(b.sectionId) - getSectionCount(a.sectionId));
        return copySurveyItemOptions;
    };

    const getScores = (overallSentimentScores: OverallSentimentScores): number[] => {
        return [
            overallSentimentScores.negativeScorePercentage,
            overallSentimentScores.neutralScorePercentage,
            overallSentimentScores.positiveScorePercentage
        ];
    };

    const setSnackbar = (
        sentimentCalculationStatus: SentimentCalculationStatus,
        translationStatus: AutoTranslationCalculationStatus,
        readCommentsPayload: CommentsPayload
    ): SnackbarType => {
        if (
            sentimentCalculationStatus === SentimentCalculationStatus.calculating &&
            readCommentsPayload.showSentimentScores
        ) {
            return { isOpen: true, message: lang.sentimentScoreCalculationInProgress };
        } else if (
            translationStatus === AutoTranslationCalculationStatus.calculating &&
            readCommentsPayload.autoTranslate
        ) {
            return { isOpen: true, message: lang.autoTranslationCalculationInProgress };
        }
        return { isOpen: false, message: "" };
    };

    const messageComposer = (msg: string, fileName: string): string => {
        const index = msg.indexOf(".");
        return msg.slice(0, index) + fileName + msg.slice(index);
    };

    const handleReadComments = (
        readCommentsPayload: CommentsPayload,
        surveyItemsOptions: SurveySectionList[]
    ): void => {
        commentsService
            .readComments(readCommentsPayload)
            .then(
                ({
                    comments,
                    totalRespondents,
                    confidentiality,
                    sections,
                    words,
                    wordCloudSettings,
                    surveyCommentSettings,
                    sentimentCalculationStatus,
                    sentimentScoreNeutralThreshold,
                    overallSentimentScores,
                    translationStatus
                }) => {
                    let _wordCloudRawData = commentState.wordCloudRawData;
                    let _hasMore = commentState.hasMore;
                    let _commentLength = commentState.commentLength;
                    let _comments = commentState.comments;
                    let _commentCountMapping = commentState.commentCountMapping;
                    let _surveyItemsOptions = surveyItemsOptions;
                    let _commentsNumbserPerSection = commentState.commentsNumbserPerSection;
                    let _scores = [...commentState.overallSentimentScore];

                    if (confidentiality !== ConfidentialityResult.success) {
                        _wordCloudRawData = [];
                    } else if (comments.length >= 0) {
                        _hasMore = false;
                        _commentLength = numberPerLoad;
                        _comments = comments;
                        _commentCountMapping = sections;

                        if (words) {
                            _wordCloudRawData = words;
                        }

                        if (comments && sections) {
                            _surveyItemsOptions = getSurveyItemOptions(_surveyItemsOptions, sections);
                            _commentsNumbserPerSection = comments.length;
                        }

                        if (comments.length > numberPerLoad) {
                            _hasMore = true;
                        }
                    }

                    if (overallSentimentScores) {
                        _scores = getScores(overallSentimentScores);
                    }

                    const updateSurveyValue = {
                        sentimentCalculationStatus:
                            sentimentCalculationStatus === 0
                                ? "NOT_CALCULATED"
                                : sentimentCalculationStatus === 0
                                  ? "CALCULATING"
                                  : "COMPLETED"
                    };
                    updateSingleSurvey(readCommentsPayload.surveyId, updateSurveyValue);

                    updateState({
                        totalComments: totalRespondents,
                        overallSentimentScore: _scores,
                        wordCloudSettings: wordCloudSettings ?? commentState.wordCloudSettings,
                        commentSettings: surveyCommentSettings ?? commentState.commentSettings,
                        sentimentScoreNeutralThreshold:
                            sentimentScoreNeutralThreshold ?? commentState.sentimentScoreNeutralThreshold,
                        snackbar: setSnackbar(sentimentCalculationStatus, translationStatus, readCommentsPayload),
                        status: ComponentStatus.success,
                        wordCloudRawData: _wordCloudRawData,
                        hasMore: _hasMore,
                        commentLength: _commentLength,
                        sentimentScoreCalculationStatus: sentimentCalculationStatus,
                        comments: _comments,
                        commentCountMapping: _commentCountMapping,
                        confidentialityResult: confidentiality,
                        surveyItemsOptions: _surveyItemsOptions,
                        commentsNumbserPerSection: _commentsNumbserPerSection
                    });
                }
            )
            .catch(() => {
                updateState({ status: ComponentStatus.error });
            });
    };

    const onCommentSelect = (
        surveyId: number,
        ids: string[],
        searchTerm: string,
        filter: FilterParameter[],
        type?: number
    ): void => {
        updateState({ status: ComponentStatus.loading });
        const updatedSettings = {
            ...user.settings,
            filtersItems: filter,
            comment: {
                ...user.settings.comment,
                itemIds: ids,
                typeOfSearch: type !== undefined && type !== null ? type : mapSearchType(searchType),
                searchTerm: searchTerm ? searchTerm : ""
            }
        };
        updateUserSettings(updatedSettings);

        const userSettings = getCommentsSettings(surveyId, user.settings.commentsSettings);
        const readCommentsPayload: CommentsPayload = {
            surveyId,
            hierarchyId: 0,
            cardId: 0,
            itemIds: ids,
            searchTerm: searchTerm ? searchTerm : "",
            typeOfSearch: type !== undefined && type !== null ? type : mapSearchType(searchType),
            filters: filterConnectorToBackEnd(filter),
            languageCode,
            wordCloudTopWordsCount: userSettings.wordCloudTopWordsCount,
            commentsRandomSampleCount: userSettings.commentsRandomSampleCount || 0,
            minWordsPerComment: userSettings.minWordsPerComment,
            showSectionScore: userSettings.showSectionScore,
            commentsSectionId: userSettings.commentsSectionId || 0,
            commentsSectionScoreSortOrder: userSettings.commentsSectionScoreSortOrder,
            showSentimentScores: userSettings.showSentimentScores,
            autoTranslate: userSettings.autoTranslate
        };
        handleReadComments(readCommentsPayload, commentState.surveyItemsOptions);
    };

    const onSearchTermChange = (input: string): void => {
        resetInfiniteScrollbar();
        updateState({ searchTerm: input });
        onCommentSelect(
            user.settings.comment.surveySelected,
            getItemsId(
                commentState.selectedCommentId,
                commentState.surveyItemsOptions,
                commentState.selectedCommentQuestionId
            ),
            input,
            commentState.currentFilter.items
        );
    };

    const handleBulkExport = (): void => {
        updateState({ snackbar: { isOpen: true, message: lang.startCommentBulkExport } });
        orgChartService
            .startBulkExport(
                user.settings.comment.surveySelected,
                commentState.bulkExportDemographic.demographicFieldId,
                languageCode,
                BulkReportType.Comment,
                user.settings
            )
            .then(() => {
                updateState({ isBulkExportDialogOpen: false });
            });
    };
    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        updateState({ bulkExportDemographic: updatedBulkExport });
    };
    const handleBulkExportDialogClose = (): void => {
        updateState({ isBulkExportDialogOpen: false });
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        if (tabValue === TabOptions.comments) {
            if (isBulkExport) {
                updateState({ isBulkExportDialogOpen: true });
            } else {
                updateState({ isExportDialogOpen: true });
            }
        } else if (tabValue === TabOptions.summary) {
            updateState({ isSummaryExportDialogOpen: true });
        } else {
            return;
        }
    };
    const handleExportDialogClose = (): void => {
        updateState({ isExportDialogOpen: false });
    };

    const handleSummaryExportDialogClose = (): void => {
        updateState({ isSummaryExportDialogOpen: false });
    };

    const handleExport = (mainTitle: string): void => {
        setLoading(true);
        if (tabValue === TabOptions.comments) {
            updateState({ isExportDialogOpen: false });
            const userSettings = getCommentsSettings(
                user.settings.comment.surveySelected,
                user.settings.commentsSettings
            );
            const payload: ExportCommentsPayload = {
                surveyId: user.settings.comment.surveySelected,
                title: mainTitle,
                hierarchyId: 0,
                cardId: 0,
                filters: commentState.currentFilter.items,
                languageCode,
                searchTerm: commentState.searchTerm,
                typeOfSearch: mapSearchType(searchType),
                itemIds: getItemsId(
                    commentState.selectedCommentId,
                    commentState.surveyItemsOptions,
                    commentState.selectedCommentQuestionId
                ),
                minWordsPerComment: userSettings.minWordsPerComment,
                showSentimentScores: userSettings.showSentimentScores,
                autoTranslate: userSettings.autoTranslate,
                commentsRandomSampleCount: userSettings.commentsRandomSampleCount,
                includeWordCloud: commentState.exportIncludeWordCloud,
                wordCloudTopWordsCount: userSettings.wordCloudTopWordsCount
            };

            commentsService
                .exportSurveyComments(payload)
                .then((blob: unknown) => {
                    saveAs(blob as Blob, mainTitle);
                    updateState({
                        snackbar: { isOpen: true, message: messageComposer(lang.snapshotExportMsg[1], mainTitle) }
                    });
                    setLoading(false);
                })
                .catch(() => {
                    updateState({ snackbar: { isOpen: true, message: lang.unableToFetchPDF } });
                    setLoading(false);
                });
        } else if (tabValue === TabOptions.summary) {
            updateState({ isSummaryExportDialogOpen: false });
            const payload: CommentSummaryExportPayload = {
                surveyId: user.settings.comment.surveySelected,
                hierarchyId: 0,
                cardId: 0,
                filters: filterConnectorToBackEnd(commentState.currentFilter.items),
                languageCode,
                itemIds: getItemsIdInNumber(
                    commentState.selectedCommentId,
                    commentState.surveyItemsOptions,
                    commentState.selectedCommentQuestionId
                ),
                title: getCommentSectionOrItemTitle(),
                reportTitle: mainTitle
            };
            commentsService
                .exportCommentSummary(payload)
                .then((): void => {
                    updateState({
                        snackbar: { isOpen: true, message: messageComposer(lang.snapshotExportMsg[1], mainTitle) }
                    });
                })
                .catch(() => {
                    updateState({ snackbar: { isOpen: true, message: lang.unableToFetchPPTX } });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (): void => {
        updateState({ snackbar: { isOpen: false, message: "" } });
    };

    const initCommentValue = (sectionList: SurveySectionList[], surveyId: number, filter: Filter): void => {
        if (sectionList && sectionList.length > 0) {
            const itemIds = sectionList
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .map(q => q.items.map(i => i.itemId.replace("I", "")))
                .reduce((a, b) => a.concat(b));
            const updatedSettings = {
                ...user.settings,
                comment: {
                    ...user.settings.comment,
                    itemIds: itemIds,
                    typeOfSearch: 1,
                    searchTerm: ""
                }
            };
            updateUserSettings(updatedSettings);

            updateState({
                selectedCommentId: "AllComments",
                selectedCommentQuestionId: "",
                searchTerm: "",
                status: ComponentStatus.loading
            });

            const userSettings = getCommentsSettings(surveyId, user.settings.commentsSettings);
            const readCommentsPayload: CommentsPayload = {
                surveyId,
                hierarchyId: 0,
                cardId: 0,
                itemIds,
                searchTerm: commentState.searchTerm,
                typeOfSearch: mapSearchType(SearchType.exactMatch),
                filters: filterConnectorToBackEnd(filter.items),
                languageCode,
                wordCloudTopWordsCount: userSettings.wordCloudTopWordsCount,
                commentsRandomSampleCount: userSettings.commentsRandomSampleCount || 0,
                minWordsPerComment: userSettings.minWordsPerComment,
                showSectionScore: userSettings.showSectionScore,
                commentsSectionId: userSettings.commentsSectionId || 0,
                commentsSectionScoreSortOrder: userSettings.commentsSectionScoreSortOrder,
                showSentimentScores: userSettings.showSentimentScores,
                autoTranslate: userSettings.autoTranslate
            };

            handleReadComments(readCommentsPayload, sectionList);
        } else {
            updateState({
                selectedCommentId: "AllComments",
                selectedCommentQuestionId: "",
                searchTerm: "",
                totalComments: 0,
                commentsNumbserPerSection: 0,
                status: ComponentStatus.success
            });
        }
    };

    const resetInfiniteScrollbar = (): void => {
        if (scrollTarget && scrollTarget.current) {
            scrollTarget.current.scrollTo(0, 0);
        }
    };
    const handleOpenFilter = (): void => {
        updateState({ isFilterDialogOpen: true });
    };
    const handleCloseFilters = (): void => {
        updateState({ isFilterDialogOpen: false });
    };

    const updateCommentData = (filter: Filter): void => {
        resetInfiniteScrollbar();
        updateState({ status: ComponentStatus.loading, currentFilter: filter });
        fetchCommentValue(filter, user.settings.comment.surveySelected);
    };

    const fetchCommentValue = (filter: Filter, surveyId: number): void => {
        if (surveyId !== emptySurveyInfo.id) {
            onCommentSelect(
                surveyId,
                getItemsId(
                    commentState.selectedCommentId,
                    commentState.surveyItemsOptions,
                    commentState.selectedCommentQuestionId
                ),
                commentState.searchTerm,
                filter.items
            );
        } else {
            updateState({ status: ComponentStatus.error });
        }
    };

    const handleCloseSettingPopover = (): void => {
        updateState({ settingAnchorEl: null });
    };

    const handleCloseSummarySettingPopover = (): void => {
        updateState({ summarySettingAnchorEl: null });
    };

    const handleRegenerateSummary = (): void => {
        if (commentSummaryRef && commentSummaryRef.current) {
            commentSummaryRef.current.regenerateSummaryBasedonPromptChanges();
        }
    };

    const refetchComments = (): void => {
        if (commentState.selectedCommentId && !commentState.selectedCommentQuestionId) {
            if (commentState.selectedCommentId === "AllComments") {
                const itemIds = commentState.surveyItemsOptions
                    .map(o => o.questions)
                    .reduce((a, b) => a.concat(b))
                    .map(q => q.items.map(i => i.itemId.replace("I", "")))
                    .reduce((a, b) => a.concat(b));
                onCommentSelect(
                    user.settings.comment.surveySelected,
                    itemIds,
                    commentState.searchTerm,
                    commentState.currentFilter.items
                );
            } else {
                const selectedComment = commentState.surveyItemsOptions.find(
                    x => x.sectionId === commentState.selectedCommentId
                );
                if (selectedComment) {
                    const itemIds = selectedComment.questions
                        .map(q => q.items.map(i => i.itemId.replace("I", "")))
                        .reduce((a, b) => a.concat(b));
                    onCommentSelect(
                        user.settings.comment.surveySelected,
                        itemIds,
                        commentState.searchTerm,
                        commentState.currentFilter.items
                    );
                }
            }
        } else if (commentState.selectedCommentQuestionId && !commentState.selectedCommentId) {
            const selectedComment = commentState.surveyItemsOptions
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .find(x => x.questionId.toString() === commentState.selectedCommentQuestionId);
            if (selectedComment) {
                const selectedItems = selectedComment.items.map(i => i.itemId.replace("I", ""));
                onCommentSelect(
                    user.settings.comment.surveySelected,
                    selectedItems,
                    commentState.searchTerm,
                    commentState.currentFilter.items
                );
            }
        }
    };

    const refetchCommentSettings = (): void => {
        refetchComments();
        if (commentSettingPopupRef && commentSettingPopupRef.current) {
            commentSettingPopupRef.current.resetImportedFile();
        }
    };

    const handleApplyCommentSetting = (
        preSelection: APIPreSelection,
        hiddenWords: string[],
        dimensionScoreChecked: boolean,
        sentimentScoreChecked: boolean,
        autoTranslateChecked: boolean
    ): void => {
        const userSettings: UserCommentSettings = {
            surveyId: user.settings.comment.surveySelected,
            autoTranslate: autoTranslateChecked,
            commentsRandomSampleCount: preSelection.sampleWords,
            commentsSectionId: preSelection.selectedSectionId,
            commentsSectionScoreSortOrder: preSelection.selectedDimensionScoreSort,
            minWordsPerComment: preSelection.minWords,
            showSectionScore: dimensionScoreChecked,
            showSentimentScores: sentimentScoreChecked,
            wordCloudTopWordsCount: initCommentsSettings.wordCloudTopWordsCount
        };

        const userCommentsSettins = user.settings.commentsSettings;
        const findIndex = userCommentsSettins.findIndex(s => s.surveyId === user.settings.comment.surveySelected);
        if (findIndex > -1) {
            userSettings.wordCloudTopWordsCount = userCommentsSettins[findIndex].wordCloudTopWordsCount;
            userCommentsSettins[findIndex] = userSettings;
        } else {
            userCommentsSettins.push(userSettings);
        }
        updateUserSettings({ ...user.settings, commentsSettings: userCommentsSettins });

        const payload: UpdateCommentSettingsPayload = {
            surveyId: user.settings.comment.surveySelected,
            hiddenWords,
            autoTranslate: autoTranslateChecked,
            showSentimentScores: sentimentScoreChecked
        };

        commentsService
            .updateCommentSettings(payload)
            .then(() => {
                resetInfiniteScrollbar();
                refetchCommentSettings();
                updateState({ settingAnchorEl: null, status: ComponentStatus.success });
            })
            .catch(() => {
                updateState({ status: ComponentStatus.error });
            });
    };

    const handleOpenCommentSetting = (): void => {
        if (settingTargetRef && settingTargetRef.current) {
            const el = settingTargetRef.current as Element;
            updateState({ settingAnchorEl: el });
        }
    };

    const handleOpenCommentSummarySetting = (): void => {
        if (settingTargetRef && settingTargetRef.current) {
            const el = settingTargetRef.current as Element;
            updateState({ summarySettingAnchorEl: el });
        }
    };

    const showCommentConfirmDialog = (status: boolean): void => {
        updateState({ confirmCommentSettingsDialogOpen: status });
    };

    const confirmationCommentDialogOnClick = (): void => {
        updateState({ confirmCommentSettingsDialogOpen: false });
        if (commentSettingPopupRef && commentSettingPopupRef.current) {
            commentSettingPopupRef.current.resetImportedFile();
        }
    };

    const getCommentsNumberPerSection = (): string => {
        if (commentState.selectedCommentId && !commentState.selectedCommentQuestionId) {
            if (commentState.selectedCommentId === "AllComments") {
                const totalCommentCount = getTotalCommentsCount(commentState.commentCountMapping);
                if (totalCommentCount === commentState.commentsNumbserPerSection) {
                    return ``;
                } else {
                    return `${lang.commentSamplePrefix} ${commentState.commentsNumbserPerSection} ${lang.of} ${totalCommentCount} ${lang.commentSampleSuffix}`;
                }
            } else {
                const selectedComment = commentState.surveyItemsOptions.find(
                    x => x.sectionId === commentState.selectedCommentId
                );
                if (selectedComment) {
                    const commentSectionCount = getCommentSectionCount(
                        commentState.commentCountMapping,
                        selectedComment.sectionId
                    );
                    if (commentSectionCount === commentState.commentsNumbserPerSection) {
                        return ``;
                    } else {
                        return `${lang.commentSamplePrefix} ${commentState.commentsNumbserPerSection} ${lang.of} ${commentSectionCount} ${lang.commentSampleSuffix}`;
                    }
                } else {
                    return ``;
                }
            }
        } else if (commentState.selectedCommentQuestionId && !commentState.selectedCommentId) {
            const selectedComment = getSelectedComment(
                commentState.surveyItemsOptions,
                commentState.selectedCommentQuestionId
            );
            if (selectedComment) {
                const commentItemCount = getCommentItemCount(
                    commentState.commentCountMapping,
                    commentState.surveyItemsOptions,
                    commentState.selectedCommentQuestionId,
                    selectedComment.questionId
                );
                if (commentItemCount === commentState.commentsNumbserPerSection) {
                    return ``;
                } else {
                    return `${lang.commentSamplePrefix} ${commentState.commentsNumbserPerSection} ${lang.of} ${commentItemCount} ${lang.commentSampleSuffix}`;
                }
            } else {
                return ``;
            }
        } else {
            return ``;
        }
    };

    const handleDeleteFilterItem = (filterItemOrder: number): void => {
        const updateCurrentFilter = { ...commentState.currentFilter };
        updateCurrentFilter["items"] = updateCurrentFilter.items
            .filter(item => item.order !== filterItemOrder)
            .map((item, index) => {
                return {
                    ...item,
                    order: index
                };
            });

        updateCommentData(updateCurrentFilter);
    };

    const handleExcelExport = (): void => {
        setLoading(true);
        const userSettings = getCommentsSettings(user.settings.comment.surveySelected, user.settings.commentsSettings);

        const exportCommnetPayload: ExportCommentsPayload = {
            surveyId: user.settings.comment.surveySelected,
            hierarchyId: 0,
            cardId: 0,
            filters: commentState.currentFilter.items,
            languageCode: languageCode,
            title: "",
            searchTerm: commentState.searchTerm,
            typeOfSearch: mapSearchType(searchType),
            itemIds: getItemsId(
                commentState.selectedCommentId,
                commentState.surveyItemsOptions,
                commentState.selectedCommentQuestionId
            ),
            minWordsPerComment: userSettings.minWordsPerComment,
            showSentimentScores: userSettings.showSentimentScores,
            autoTranslate: userSettings.autoTranslate,
            commentsRandomSampleCount: userSettings.commentsRandomSampleCount,
            includeWordCloud: commentState.exportIncludeWordCloud,
            wordCloudTopWordsCount: userSettings.wordCloudTopWordsCount
        };

        commentsService
            .exportCommentExcelReport(exportCommnetPayload)
            .catch(() => {
                updateState({ snackbar: { isOpen: true, message: lang.somethingWentWrong } });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchMoreData = (): void => {
        let commentLength = commentState.commentLength + numberPerLoad;
        let hasMore = commentState.hasMore;
        if (commentState.commentLength + numberPerLoad > commentState.comments.length) {
            commentLength = commentState.comments.length;
            hasMore = false;
        }
        updateState({ commentLength, hasMore });
    };

    const handleSetTabValue = (_: React.ChangeEvent<unknown>, newValue: TabOptions): void => {
        setTabValue(newValue);
    };

    const getCommentsTabLabel = (): string => {
        if (commentState.confidentialityResult === ConfidentialityResult.success) {
            return `${lang.comments} (${commentState.commentsNumbserPerSection})`;
        } else {
            return lang.comments;
        }
    };

    const getCommentSectionOrItemTitle = (): string => {
        if (commentState.selectedCommentId && !commentState.selectedCommentQuestionId) {
            if (commentState.selectedCommentId === "AllComments") {
                return lang.allComments;
            } else {
                const selectedComment = commentState.surveyItemsOptions.find(
                    x => x.sectionId === commentState.selectedCommentId
                );
                return selectedComment ? selectedComment.title : lang.allComments;
            }
        } else if (commentState.selectedCommentQuestionId && !commentState.selectedCommentId) {
            const selectedItemComment = getSelectedComment(
                commentState.surveyItemsOptions,
                commentState.selectedCommentQuestionId
            );
            return selectedItemComment ? selectedItemComment.questionTitle : lang.allComments;
        } else {
            return lang.allComments;
        }
    };

    const getCommentItems = (): number[] => {
        if (commentState.selectedCommentId && !commentState.selectedCommentQuestionId) {
            if (commentState.selectedCommentId === "AllComments") {
                return commentState.surveyItemsOptions
                    .map(o => o.questions)
                    .reduce((a, b) => a.concat(b))
                    .map(q => q.items.map(i => parseInt(i.itemId.replace("I", ""))))
                    .reduce((a, b) => a.concat(b));
            } else {
                const selectedComment = commentState.surveyItemsOptions.find(
                    x => x.sectionId === commentState.selectedCommentId
                );
                if (selectedComment) {
                    return selectedComment.questions
                        .map(q => q.items.map(i => parseInt(i.itemId.replace("I", ""))))
                        .reduce((a, b) => a.concat(b));
                } else {
                    return [];
                }
            }
        } else if (commentState.selectedCommentQuestionId && !commentState.selectedCommentId) {
            const selectedComment = commentState.surveyItemsOptions
                .map(o => o.questions)
                .reduce((a, b) => a.concat(b))
                .find(x => x.questionId.toString() === commentState.selectedCommentQuestionId);
            if (selectedComment) {
                return selectedComment.items.map(i => parseInt(i.itemId.replace("I", "")));
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const handleUpdateSummaryLoadingStatus = (status: boolean): void => {
        updateState({ isSummarySettingLoading: status });
    };

    const getFilterDisableRule = (): boolean => {
        if (tabValue === TabOptions.comments || tabValue === TabOptions.ask) {
            return user.settings.comment.surveySelected === emptySurveyInfo.id;
        } else {
            return (
                user.settings.comment.surveySelected === emptySurveyInfo.id ||
                commentState.isSummarySettingLoading == true
            );
        }
    };

    const getExportDisableRule = (): boolean => {
        if (tabValue === TabOptions.comments) {
            return (
                user.settings.comment.surveySelected === emptySurveyInfo.id ||
                commentState.status !== ComponentStatus.success ||
                commentState.confidentialityResult !== ConfidentialityResult.success ||
                commentState.comments.length == 0
            );
        } else if (tabValue === TabOptions.summary) {
            return (
                user.settings.comment.surveySelected === emptySurveyInfo.id ||
                commentState.isSummarySettingLoading == true ||
                commentState.status !== ComponentStatus.success ||
                commentState.confidentialityResult !== ConfidentialityResult.success ||
                commentState.comments.length == 0
            );
        } else {
            return true;
        }
    };

    const handleSetSearchType = (type: SearchType): void => {
        setSearchType(type);
        onCommentSelect(
            user.settings.comment.surveySelected,
            getItemsId(
                commentState.selectedCommentId,
                commentState.surveyItemsOptions,
                commentState.selectedCommentQuestionId
            ),
            commentState.searchTerm,
            commentState.currentFilter.items,
            mapSearchType(type)
        );
    };

    const mapSearchType = (type: SearchType): number => {
        switch (type) {
            case SearchType.exactMatch:
                return 1;
            case SearchType.anyWord:
                return 0;
            case SearchType.semanticSearch:
                return 2;
            default:
                return 1;
        }
    };

    const handleExportIncludeCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateState({ exportIncludeWordCloud: event.target.checked });
        const updatedSettings = {
            ...user.settings,
            comment: {
                ...user.settings.comment,
                exportIncludeWordCloud: event.target.checked
            }
        };
        updateUserSettings(updatedSettings);
    };

    useEffect(() => {
        const restoredState = user.settings.comment;
        if (
            restoredState &&
            restoredState.surveySelected !== emptySurveyInfo.id &&
            mapSurveys.find(s => s.id === restoredState.surveySelected) !== undefined
        ) {
            updateState({ status: ComponentStatus.loading });
            restoreComment(restoredState.surveySelected, user.settings.filtersItems);
        } else {
            updateState({ status: ComponentStatus.success });
        }
    }, [languageCode]);

    useEffect(() => {
        setLoading(commentState.status === ComponentStatus.loading);
    }, [commentState.status]);

    if (commentState.status === ComponentStatus.error || errorDimensionItem) {
        return <Error500 />;
    }

    if (loadingDemographics || loadingDimensionItems || commentState.status === ComponentStatus.loading) {
        return <Loading />;
    }

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={getExportDisableRule()}
            filterDisabled={getFilterDisableRule()}
            selectDiabled={commentState.isSummarySettingLoading}
            exportDataTestId={"btn-heatmap-export"}
            filter={commentState.currentFilter}
            onDeleteFilterItem={updateCommentData}
            isEmptyPage={shouldShowEmptyPage()}
            startCommentExcelExport={handleExcelExport}
            commentTabValue={tabValue}
            contactFields={commentState.contactFields}
        >
            <>
                <div className={classes.searchBarHeader}>
                    {commentState.currentFilter.items && commentState.currentFilter.items.length > 0 && (
                        <FilterChip items={commentState.currentFilter.items} onDelete={handleDeleteFilterItem} />
                    )}
                    {commentState.currentFilter.items && commentState.currentFilter.items.length === 0 && (
                        <div className={classes.filterTagsPlaceHolder}></div>
                    )}
                    {tabValue === TabOptions.comments && (
                        <div className={classes.searchBar} data-testid="searchBar-semantic">
                            <SemanticSearchBar
                                searchType={searchType}
                                setSearchType={handleSetSearchType}
                                ref={semanticSearchBarRef}
                                onDelayChange={(e: string): void => onSearchTermChange(e)}
                                delay={1000}
                                searchTerm={commentState.searchTerm}
                                isAIEnabled={isAIEnabled}
                            />
                        </div>
                    )}
                    {(tabValue === TabOptions.summary || tabValue == TabOptions.ask) && (
                        <div className={classes.searchBar} data-testid="searchBar-summary">
                            <SearchBar
                                ref={searchBarRef}
                                onDelayChange={(e: string): void => onSearchTermChange(e)}
                                labelText={lang.search}
                                delay={1000}
                                searchTerm={commentState.searchTerm}
                                size={"small"}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.responseHeader}>
                    <div
                        data-testid="totalResponseCount"
                        className={classes.totalResponseHeader}
                        ref={settingTargetRef}
                    >
                        <Typography variant="h6">
                            {lang.totalComments} - {commentState.totalComments}
                        </Typography>
                    </div>
                    <div data-testid="responsePerSectionCount" className={classes.responsePerSectionHeader}>
                        {commentState.status === ComponentStatus.success && (
                            <div className={classes.responsePerSectionHeaderLeft}>
                                <div className={classes.tabs}>
                                    <Tabs
                                        value={tabValue}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        aria-label="tabs"
                                        onChange={handleSetTabValue}
                                    >
                                        <Tab
                                            label={getCommentsTabLabel()}
                                            data-testid="tab-handler-comments"
                                            style={{ width: 190 }}
                                        />
                                        {(isAIEnabled || user.isTalentMapAdmin) && (
                                            <Tab
                                                label={
                                                    <Badge
                                                        badgeContent={`BETA`}
                                                        classes={{ badge: classes.customBadge }}
                                                    >
                                                        {lang.summary}
                                                    </Badge>
                                                }
                                                style={{ width: 190 }}
                                                data-testid="tab-handler-summary"
                                            />
                                        )}
                                        {(isAIEnabled || user.isTalentMapAdmin) && (
                                            <Tab
                                                label={
                                                    <Badge
                                                        badgeContent={`BETA`}
                                                        classes={{ badge: classes.customBadge }}
                                                    >
                                                        {lang.ask}
                                                    </Badge>
                                                }
                                                style={{ width: 190 }}
                                                disabled={commentState.isSummarySettingLoading}
                                                data-testid="tab-handler-ask"
                                            />
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                        )}
                        {commentState.status === ComponentStatus.success && tabValue === TabOptions.comments && (
                            <Button
                                style={{ height: "48px", marginBottom: "4px" }}
                                variant="text"
                                id="comment-setting"
                                classes={{ endIcon: classes.endIcon }}
                                endIcon={
                                    <IconButton size="small" color="primary">
                                        <SettingsIcon style={{ fontSize: "1.8rem", paddingBottom: "4px" }} />
                                    </IconButton>
                                }
                                onClick={handleOpenCommentSetting}
                                disabled={commentState.confidentialityResult !== ConfidentialityResult.success}
                            >
                                {lang.settings}
                            </Button>
                        )}
                        {commentState.status === ComponentStatus.success &&
                            tabValue === TabOptions.summary &&
                            user.isTalentMapAdmin && (
                                <Button
                                    style={{ height: "48px", marginBottom: "4px" }}
                                    variant="text"
                                    id="comment-summary-setting"
                                    classes={{ endIcon: classes.endIcon }}
                                    endIcon={
                                        <IconButton size="small" color="primary">
                                            <SettingsIcon style={{ fontSize: "1.8rem", paddingBottom: "4px" }} />
                                        </IconButton>
                                    }
                                    onClick={handleOpenCommentSummarySetting}
                                    disabled={
                                        commentState.confidentialityResult !== ConfidentialityResult.success ||
                                        commentState.isSummarySettingLoading === true
                                    }
                                >
                                    {lang.settings}
                                </Button>
                            )}
                    </div>
                </div>
                <div className={classes.dataContent}>
                    {user.settings.comment.surveySelected !== emptySurveyInfo.id && showCommentDialog ? (
                        <>
                            <div className={classes.sidebar} data-testid="sideBar">
                                {commentState.confidentialityResult === ConfidentialityResult.success && (
                                    <CommentsDimensions
                                        surveyItemsOptions={commentState.surveyItemsOptions}
                                        commentsState={commentState}
                                        onCommentSelect={onCommentSelect}
                                        updateCommentState={updateState}
                                        summaryLoadingStatus={commentState.isSummarySettingLoading}
                                    />
                                )}
                                {commentState.confidentialityResult === ConfidentialityResult.success && (
                                    <CommentsWordCloud
                                        wordCloudRawData={commentState.wordCloudRawData}
                                        wordCloudSettings={commentState.wordCloudSettings}
                                        onSearchTermChange={onSearchTermChange}
                                        resetInfiniteScrollbar={resetInfiniteScrollbar}
                                        refetchComments={refetchComments}
                                    />
                                )}
                            </div>
                            {tabValue === TabOptions.comments && (
                                <CommentsList
                                    sentimentScoreCalculationStatus={commentState.sentimentScoreCalculationStatus}
                                    overallSentimentScore={commentState.overallSentimentScore}
                                    confidentialityResult={commentState.confidentialityResult}
                                    sentimentScoreNeutralThreshold={commentState.sentimentScoreNeutralThreshold}
                                    comments={commentState.comments}
                                    searchTerm={commentState.searchTerm}
                                    showCommentDialog={showCommentDialog}
                                    commentLength={commentState.commentLength}
                                    hasMore={commentState.hasMore}
                                    fetchMoreData={fetchMoreData}
                                    commentsNumberPerSection={getCommentsNumberPerSection()}
                                    searchType={searchType}
                                />
                            )}
                            {tabValue === TabOptions.summary && (isAIEnabled || user.isTalentMapAdmin) && (
                                <CommentsSummary
                                    ref={commentSummaryRef}
                                    surveyId={user.settings.comment.surveySelected}
                                    sectionOrItemTitle={getCommentSectionOrItemTitle()}
                                    currentFilter={commentState.currentFilter}
                                    searchTerm={commentState.searchTerm}
                                    itemIds={getCommentItems()}
                                    summaryLoadingStatus={commentState.isSummarySettingLoading}
                                    updateSummaryLoadingStatus={handleUpdateSummaryLoadingStatus}
                                    confidentialityResult={commentState.confidentialityResult}
                                    commentsNumberPerSection={commentState.commentsNumbserPerSection}
                                />
                            )}
                            {tabValue === TabOptions.ask && (isAIEnabled || user.isTalentMapAdmin) && (
                                <CommentsAsk
                                    surveyId={user.settings.comment.surveySelected}
                                    sectionOrItemTitle={getCommentSectionOrItemTitle()}
                                    currentFilter={commentState.currentFilter}
                                    itemIds={getCommentItems()}
                                    confidentialityResult={commentState.confidentialityResult}
                                    searchTerm={commentState.searchTerm}
                                />
                            )}
                        </>
                    ) : (
                        <div className={classes.pivotGridWrapper}>
                            <Typography pt={1} pl={3}>
                                {lang.noSearchResults}
                            </Typography>
                        </div>
                    )}
                </div>
                {commentState.isExportDialogOpen && (
                    <DialogExportSlide
                        dialogTitle={lang.exportToPdf}
                        isOpen={commentState.isExportDialogOpen}
                        onClose={handleExportDialogClose}
                        onSubmitCallback={handleExport}
                        extraAction={
                            <Box sx={{ paddingTop: "12px" }}>
                                <Checkbox
                                    label={lang.includeWordCloud}
                                    checked={commentState.exportIncludeWordCloud}
                                    data-testid={`checkbox-comment-include-word-cloud`}
                                    onChange={handleExportIncludeCheckboxChange}
                                />
                            </Box>
                        }
                    />
                )}
                {commentState.isSummaryExportDialogOpen && (
                    <DialogExportSlide
                        dialogTitle={lang.exportPowerPoint}
                        isOpen={commentState.isSummaryExportDialogOpen}
                        onClose={handleSummaryExportDialogClose}
                        onSubmitCallback={handleExport}
                    />
                )}
                {commentState.isBulkExportDialogOpen && (
                    <DialogBulkExportSlide
                        title={lang.bulkExportPDF}
                        isOpen={commentState.isBulkExportDialogOpen}
                        onClose={handleBulkExportDialogClose}
                        onSubmitCallback={handleBulkExport}
                        contactFields={commentState.contactFields}
                        bulkExportDemographic={commentState.bulkExportDemographic}
                        updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                        extraAction={
                            <Box sx={{ paddingLeft: "24px" }}>
                                <Checkbox
                                    label={lang.includeWordCloud}
                                    checked={commentState.exportIncludeWordCloud}
                                    data-testid={`checkbox-comment-bulk-include-word-cloud`}
                                    onChange={handleExportIncludeCheckboxChange}
                                />
                            </Box>
                        }
                    />
                )}
                <Snackbar
                    open={commentState.snackbar.isOpen}
                    message={commentState.snackbar.message}
                    handleClose={handleCloseSnackbar}
                    autohideOff
                />
                {commentState.isFilterDialogOpen && (
                    <Filters
                        onCloseFilter={handleCloseFilters}
                        isOpen={commentState.isFilterDialogOpen}
                        onFiltersApplied={updateCommentData}
                        currentFilter={commentState.currentFilter}
                        filterMode={FilterMode.comment}
                        surveyId={user.settings.comment.surveySelected}
                        contactFields={commentState.contactFields}
                    />
                )}
                {!!commentState.settingAnchorEl && (
                    <CommentSettingPopup
                        ref={commentSettingPopupRef}
                        settingAnchorEl={commentState.settingAnchorEl}
                        handleClosePopover={handleCloseSettingPopover}
                        applySetting={handleApplyCommentSetting}
                        openConfirmationDialog={(): void => showCommentConfirmDialog(true)}
                        commentSettings={userSettings}
                        surveyId={user.settings.comment.surveySelected}
                        hiddenWords={commentState.commentSettings?.hiddenWords || []}
                    />
                )}
                {!!commentState.summarySettingAnchorEl && (
                    <CommentSummarySettingPopup
                        summarySettingAnchorEl={commentState.summarySettingAnchorEl}
                        handleClosePopover={handleCloseSummarySettingPopover}
                        regenerateSummary={handleRegenerateSummary}
                        surveyId={user.settings.comment.surveySelected}
                    />
                )}
                {commentState.confirmCommentSettingsDialogOpen && (
                    <ConfirmationDialog
                        onCancelClicked={(): void => showCommentConfirmDialog(false)}
                        open={commentState.confirmCommentSettingsDialogOpen}
                        onConfirmationClicked={confirmationCommentDialogOnClick}
                        title={lang.resetHiddenWordTitle}
                        message={lang.resetWordCloudMessage}
                        confirmButtonText={lang.reset}
                        cancelButtonVariant="text"
                    />
                )}
            </>
        </ReportsLayout>
    );
};

export default Comment;

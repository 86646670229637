import React, { ReactElement, useState, useEffect } from "react";

import { useLang } from "core/hooks";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { DashboardCellType, TitleTranslation } from "../interfaces";
import ImageCellType from "./ImageCellType";
import { Box as BoxType } from "../interfaces";
import { defaultItemTitleTranslation } from "components/admin/results/dashboard/Dashboard";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { Typography } from "lib/typography";
import { Dialog, DialogTitle } from "lib/dialog";
import { SelectChangeEvent } from "lib/select";

type Props = {
    onClose: () => void;
    boxes: BoxType[];
    selectedPanelId: string;
    onCancelClicked: () => void;
    updatePanelItem: (
        column: number,
        row: number,
        type: DashboardCellType,
        updateString: string,
        titleTranslations: TitleTranslation[]
    ) => void;
};

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    width: "450px",
    height: "600px"
};

const footerStyle = {
    height: 60,
    display: "flex",
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingRight: 3,
    justifyContent: "flex-end",
    gap: 2
};

export const Image = (props: Props): ReactElement => {
    const { lang } = useLang();
    const [fileTypeMessage, setFileTypeMessage] = useState(lang.noFileAttached);
    const [imageBase64, setImageBase64] = useState<string>("");
    const [imageFileName, setImageFileName] = useState<string>("");
    const [selectedImageColumn, setSelectedImageColumn] = useState<number>(2);
    const [selectedImageRow, setSelectedImageRow] = useState<number>(2);
    const rangeNumber = (start: number, end: number): number[] => {
        const list = [];
        for (let i = start; i <= end; i++) {
            list.push(i);
        }
        return list;
    };
    const imageColumnInit = rangeNumber(2, GRID_COL_LENGTH);
    const imageRowInit = rangeNumber(1, GRID_ROW_LENGTH);
    const [imageColumn, setImageColumn] = useState<number[]>(imageColumnInit);
    const [imageRow, setImageRow] = useState<number[]>(imageRowInit);

    const convertToBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const handleFileDrop = async (files: File[]): Promise<void> => {
        if (files && files[0]) {
            setFileTypeValid(true, files[0].name);
            const base64 = (await convertToBase64(files[0])) as string;
            const name = files[0].name;
            const base64ToSave = base64.split(",")[1];
            setImageBase64(base64ToSave);
            setImageFileName(name);
        }
    };

    const handleRejectFile = (): void => {
        setFileTypeValid(false);
    };

    const setFileTypeValid = (fileValid: boolean, fileName?: string): void => {
        const message = fileValid && fileName ? fileName : lang.errorMustUploadPNG;
        setFileTypeMessage(message);
    };

    const handleImageColumnSelected = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as number;
        setSelectedImageColumn(elementValue);
    };
    const handleImageRowSelected = (event: SelectChangeEvent<unknown>): void => {
        const elementValue = event.target.value as number;
        setSelectedImageRow(elementValue);
    };

    const handleSetSelectedImageSize = (column: number, row: number): void => {
        setSelectedImageColumn(column);
        setSelectedImageRow(row);
    };

    const confirmationClicked = (): void => {
        return props.updatePanelItem(
            selectedImageColumn,
            selectedImageRow,
            "image",
            JSON.stringify({
                imageString: imageBase64,
                fileName: imageFileName
            }),
            defaultItemTitleTranslation
        );
    };

    const cancelClicked = (): void => {
        setImageBase64("");
        setImageFileName("");
        setFileTypeMessage(lang.noFileAttached);
        props.onCancelClicked();
    };

    const getConfirmButtonDisableRule = (): boolean => {
        return imageBase64 === "" || imageFileName === "";
    };

    useEffect(() => {
        setFileTypeMessage(lang.noFileAttached);
        setImageBase64("");
        setImageFileName("");
    }, [open]);

    useEffect(() => {
        const selectedBox = props.boxes.find(box => box.id === props.selectedPanelId);
        if (selectedBox) {
            setImageColumn(rangeNumber(2, GRID_COL_LENGTH - selectedBox.left / boxSize));
            setImageRow(rangeNumber(1, GRID_ROW_LENGTH - selectedBox.top / boxSize));
        }
    }, [props.boxes, open]);

    return (
        <Dialog
            open
            onClose={props.onCancelClicked}
            onKeyUp={e => {
                if (e.key === "Enter" && imageBase64 !== "" && imageFileName !== "") {
                    confirmationClicked();
                }
            }}
        >
            <Box data-testid="panel-dialog" sx={containerStyle}>
                <DialogTitle>
                    <Typography variant="h4">{lang.image}</Typography>
                </DialogTitle>
                <Divider />
                <Box height="100%">
                    <ImageCellType
                        selectedPanelId={props.selectedPanelId}
                        boxes={props.boxes}
                        imageColumn={imageColumn}
                        imageRow={imageRow}
                        selectedImageColumn={selectedImageColumn}
                        selectedImageRow={selectedImageRow}
                        fileTypeMessage={fileTypeMessage}
                        dropFile={handleFileDrop}
                        rejectFile={handleRejectFile}
                        imageColumnSelected={handleImageColumnSelected}
                        imageRowSelected={handleImageRowSelected}
                        setSelectedImageSize={handleSetSelectedImageSize}
                    />
                </Box>
                <Divider />
                <Box sx={footerStyle}>
                    <Button
                        onClick={confirmationClicked}
                        disabled={getConfirmButtonDisableRule()}
                        variant="text"
                        data-testid={"btn-ok"}
                    >
                        {lang.ok}
                    </Button>
                    <Button onClick={cancelClicked} variant="text" data-testid={"btn-cancel"}>
                        {lang.cancel}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

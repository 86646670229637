import React, { ReactElement } from "react";

import { CSSProperties, makeStyles } from "@mui/styles";

import { theme } from "lib/theme";
import { InputLabel } from "lib/input-label";
import { Typography } from "lib/typography";

type Props = {
    label: string;
    info: string;
    styleProps?: CSSProperties;
};

const useStyles = makeStyles(() => ({
    labelInfo: (styleProps: CSSProperties) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: styleProps.marginBottom ? styleProps.marginBottom : theme.spacing(2)
    })
}));

const CustomLabelInfo = (props: Props): ReactElement => {
    const classes = useStyles(props.styleProps ? props.styleProps : {});
    return (
        <div className={classes.labelInfo}>
            <InputLabel style={{ fontSize: 12 }} disabled>
                {props.label}
            </InputLabel>
            <Typography>{props.info}</Typography>
        </div>
    );
};

export default CustomLabelInfo;

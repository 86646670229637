import React, { ReactElement, MouseEvent, CSSProperties } from "react";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateIcon from "@mui/icons-material/Create";

import TruncateDisplay from "managerPortal/components/shared/TruncateDisplay";
import { ContactField, FieldGroupMappedRawData, FieldValueMappedRawData, ItemMap, SectionMap } from "./interfaces";
import { tableHeaderLight } from "core/styles/ColorsDefinition";
import { lightPink } from "core/styles/BackgroundPalette";
import { ComparisonOperator, ComparisonOperatorMap } from "components/filters/enums";
import { SelectItem } from "core/interfaces";
import { useLang } from "core/hooks";
import { theme } from "lib/theme";

import { errorColor } from "core/styles/ColorsDefinition";
import { isStringEmptyOrSpaces } from "core/helpers";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Chip } from "lib/chip";
import { Button } from "lib/button";

type rowStyleProps = {
    isExpanded: boolean;
    canExpand: boolean;
};

const useRowStyles = makeStyles(() => ({
    expandMoreIcon: (styleProps: rowStyleProps) => ({
        transform: styleProps.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        marginRight: theme.spacing(),
        color: theme.palette.action.active
    }),
    rowStyle: {
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        borderBottom: theme.border.main
    },
    cellGroupField: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        background: tableHeaderLight,
        "&:hover": {
            background: theme.palette.action.hover,
            cursor: "pointer"
        },
        boxSizing: "border-box"
    },
    cellQuestion: {
        paddingLeft: theme.spacing(4)
    },
    fieldMapRow: {
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box"
    },
    cellLeft: {
        paddingLeft: theme.spacing(4),
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderRight: theme.border.main
    },
    cellRight: {
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: theme.spacing(1)
    },
    flexSpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        alignItems: "center"
    },
    notMappedRow: {
        background: lightPink
    },
    filterParameter: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box"
    },
    marginRightHalf: {
        marginLeft: theme.spacing(0.5)
    },
    rowAction: {
        boxSizing: "border-box",
        background: tableHeaderLight,
        paddingRight: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        height: "100%"
    },
    errorIndicator: { width: 8, height: "100%", backgroundColor: errorColor, position: "absolute" },
    rowSelected: {
        border: `2px solid ${theme.palette.action.active}`
    }
}));

type Props = {
    openedPanels: string[];
    renderPanelData: (FieldGroupMappedRawData | FieldValueMappedRawData | SectionMap | ItemMap)[];
    index: number;
    style: CSSProperties;
    width: number;
    onExpand: (fieldName: string) => void;
    onSelectFieldGroup: (event: MouseEvent<HTMLButtonElement>, fieldId: ContactField["fieldId"]) => void;
    onSelectFieldItem: (event: MouseEvent<HTMLButtonElement>, fieldId: string | number, fieldValue: string) => void;
    isSurveyItem?: boolean;
    isSelected: boolean;
    isLocked: boolean;
    setLockConfirmationDialog: () => void;
    clearSectionMapping: (sectionId: string) => void;
};

const RenderTableRow = (props: Props): ReactElement => {
    const { onSelectFieldItem, isSelected } = props;
    const { lang } = useLang();

    const item = props.renderPanelData[props.index];

    const canExpand = "fieldGroup" in item || "surveySection" in item;
    let isExpanded = false;
    if (canExpand) {
        if ("fieldGroup" in item) {
            isExpanded = props.openedPanels.includes(item.fieldGroup);
        }
        if ("sectionId" in item && !isExpanded) {
            isExpanded = props.openedPanels.includes(item.sectionId);
        }
    }

    //32px: padding
    //24px: arrow icon
    //8px: arrow icon margin
    //48px: pen icon
    const totalWidth = props.width - 32 - 24 - 8 - 48;

    //half width
    //64px: padding left
    const totalWidthLeft = props.width / 2 - 64;

    //10px => is the width of one letter
    const maxLetters = totalWidth / 10;
    const maxLettersLeft = totalWidthLeft / 10;

    //half width
    //48px: pen icon
    let totalWidthRight = props.width / 2 - 48;
    if ("targetFilters" in item && !canExpand && item.targetFilters.length > 1) {
        //half width
        //48px: pen icon
        //44px: chip
        totalWidthRight = props.width / 2 - 48 - 44;
    }
    const maxLettersRight = totalWidthRight / 10;

    const rowClasses = useRowStyles({
        isExpanded,
        canExpand
    });

    const getComparisonOperatorSymbol = (comparisonOperator: ComparisonOperator): string => {
        const found = ComparisonOperatorMap(lang).find((item: SelectItem) => item.value === comparisonOperator);

        return (found && found.symbol) ?? "";
    };

    let textRightCol = "";
    if ("targetFilters" in item && !canExpand && item.targetFilters.length) {
        let targets = "";
        item.targetFilters[0].target.forEach((target: string, index: number) => {
            index > 0 ? (targets = targets + `, ${target}`) : (targets = targets + target);
        });
        textRightCol = !isStringEmptyOrSpaces(item.targetFilters[0].field.fieldName)
            ? `${item.targetFilters[0].field.fieldName} ${getComparisonOperatorSymbol(
                  item.targetFilters[0].comparisonOperator
              )}`
            : "";

        textRightCol = textRightCol + " " + targets;
    }

    return props.isSurveyItem ? (
        <div
            className={isSelected ? `${rowClasses.rowStyle} ${rowClasses.rowSelected}` : rowClasses.rowStyle}
            style={props.style}
        >
            {canExpand ? (
                <div
                    data-testid={"surveySection" in item ? `${item.surveySection}` : ""}
                    className={
                        "surveySection" in item
                            ? rowClasses.cellGroupField
                            : `${rowClasses.cellGroupField} ${rowClasses.cellQuestion}`
                    }
                    onClick={(): void => props.onExpand("surveySection" in item ? item.sectionId : "")}
                >
                    <ExpandMoreIcon className={rowClasses.expandMoreIcon} />
                    <div className={rowClasses.flexSpaceBetween}>
                        <TruncateDisplay
                            maxLabelLength={maxLetters * 2}
                            title={"surveySection" in item ? item.surveySection : ""}
                        >
                            <Typography variant="body2">
                                {"surveySection" in item
                                    ? `${item.surveySection} (${item.totalMapped}/${item.totalValues} ${lang.mapped})`
                                    : ""}
                            </Typography>
                        </TruncateDisplay>
                        <Button
                            variant="text"
                            onClick={(e): void => {
                                e.stopPropagation();
                                if ("sectionId" in item) {
                                    props.clearSectionMapping(item.sectionId);
                                }
                            }}
                            disabled={item.totalMapped == 0}
                            data-testid="btn-clear-dimension-mapping"
                        >
                            {lang.clear}
                        </Button>
                    </div>
                </div>
            ) : (
                <div
                    className={
                        "targetItemTitle" in item && item.targetItemTitle !== ""
                            ? `${rowClasses.fieldMapRow}`
                            : `${rowClasses.fieldMapRow} ${rowClasses.notMappedRow}`
                    }
                    data-testid={"sourceItemTitle" in item ? item.sourceItemTitle : "itemNoTestId"}
                >
                    {"targetItemTitle" in item && item.targetItemTitle === "" && (
                        <div className={rowClasses.errorIndicator}></div>
                    )}
                    <div className={rowClasses.cellLeft}>
                        <TruncateDisplay
                            maxLabelLength={maxLettersLeft * 2}
                            title={"sourceItemTitle" in item ? item.sourceItemTitle : ""}
                        >
                            <Typography variant="body2">
                                {"sourceItemTitle" in item ? item.sourceItemTitle : ""}
                            </Typography>
                        </TruncateDisplay>
                    </div>
                    <div className={rowClasses.cellRight}>
                        <div className={rowClasses.flexSpaceBetween}>
                            <div key={props.index} className={rowClasses.filterParameter}>
                                <TruncateDisplay
                                    maxLabelLength={maxLettersRight * 2}
                                    title={"targetItemTitle" in item ? item.targetItemTitle : ""}
                                >
                                    <Typography variant="body2" className={rowClasses.marginRightHalf}>
                                        {"targetItemTitle" in item ? item.targetItemTitle : ""}
                                    </Typography>
                                </TruncateDisplay>
                            </div>
                            <IconButton
                                data-testid={
                                    "sourceItemTitle" in item ? `fieldItemMap_${item.sourceItemTitle}` : "btn-icon"
                                }
                                onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                    if (!props.isLocked) {
                                        onSelectFieldItem(
                                            event,
                                            "sourceItemId" in item ? item.sourceItemId : "",
                                            "sourceItemTitle" in item ? item.sourceItemTitle : ""
                                        );
                                    } else {
                                        props.setLockConfirmationDialog();
                                    }
                                }}
                                style={{
                                    color: props.isLocked ? "lightgrey" : "default"
                                }}
                            >
                                <CreateIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <div
            className={rowClasses.rowStyle}
            style={props.style}
            data-testid={
                "sourceValueName" in item
                    ? `fieldValue_${item.sourceValueName}`
                    : "fieldGroup" in item
                      ? item.fieldGroup
                      : "sourceValueName"
            }
        >
            {"fieldGroup" in item && canExpand ? (
                <>
                    <div className={rowClasses.cellGroupField} onClick={(): void => props.onExpand(item.fieldGroup)}>
                        <ExpandMoreIcon className={rowClasses.expandMoreIcon} />
                        <div className={rowClasses.flexSpaceBetween}>
                            <TruncateDisplay maxLabelLength={maxLetters * 2} title={item.fieldGroup}>
                                <Typography variant="body2">
                                    {item.fieldGroup} ({item.totalMapped}/{item.totalValues} {lang.mapped})
                                </Typography>
                            </TruncateDisplay>
                        </div>
                    </div>
                    <div className={rowClasses.rowAction}>
                        <IconButton
                            style={{
                                color: props.isLocked ? "lightgrey" : "default"
                            }}
                            onClick={(event): void => {
                                if (!props.isLocked) {
                                    props.onSelectFieldGroup(event, item.fieldId);
                                } else {
                                    props.setLockConfirmationDialog();
                                }
                            }}
                        >
                            <CreateIcon />
                        </IconButton>
                    </div>
                </>
            ) : (
                <div
                    className={
                        "targetFilters" in item && item.targetFilters.length
                            ? `${rowClasses.fieldMapRow}`
                            : `${rowClasses.fieldMapRow} ${rowClasses.notMappedRow}`
                    }
                >
                    {"targetFilters" in item && !item.targetFilters.length && (
                        <div className={rowClasses.errorIndicator}></div>
                    )}
                    <div className={rowClasses.cellLeft}>
                        {"targetFilters" in item && (
                            <TruncateDisplay maxLabelLength={maxLettersLeft * 2} title={item.sourceValueName}>
                                <Typography variant="body2">{item.sourceValueName}</Typography>
                            </TruncateDisplay>
                        )}
                    </div>
                    <div className={rowClasses.cellRight}>
                        <div className={rowClasses.flexSpaceBetween}>
                            {"targetFilters" in item && (
                                <>
                                    {item.targetFilters.length ? (
                                        <>
                                            <div key={props.index} className={rowClasses.filterParameter}>
                                                <TruncateDisplay
                                                    maxLabelLength={maxLettersRight * 2}
                                                    title={textRightCol}
                                                >
                                                    <Typography variant="body2" className={rowClasses.marginRightHalf}>
                                                        {textRightCol}
                                                    </Typography>
                                                </TruncateDisplay>
                                            </div>
                                            {item.targetFilters.length > 1 && (
                                                <Chip label={`+ ${item.targetFilters.length - 1}`} />
                                            )}
                                        </>
                                    ) : (
                                        <div></div>
                                    )}
                                    <IconButton
                                        data-testid={`fieldItemMap_${item.sourceValueName}`}
                                        onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                            if (!props.isLocked) {
                                                onSelectFieldItem(event, item.sourceFieldId, item.sourceValueName);
                                            } else {
                                                props.setLockConfirmationDialog();
                                            }
                                        }}
                                        style={{
                                            color: props.isLocked ? "lightgrey" : "default"
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RenderTableRow;

import { Action } from "../../../../core/interfaces";
import { SurveyImportActions } from "../actions";
import { SurveyImportState } from "../interfaces";

export const surveyImportReducer = (state: SurveyImportState, action: Action): SurveyImportState => {
    const { type, payload } = action;
    switch (type) {
        case SurveyImportActions.SET_SURVEY_FILE_LOADED:
            return {
                ...state,
                surveyFileLoaded: payload.surveyFileLoaded
            };
        case SurveyImportActions.SET_RESPONSES_FILE_LOADED:
            return {
                ...state,
                responsesFileLoaded: payload.responsesFileLoaded
            };
        case SurveyImportActions.SET_SCALE_FILE_LOADED:
            return {
                ...state,
                scaleFileLoaded: payload.scaleFileLoaded
            };
        case SurveyImportActions.SET_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case SurveyImportActions.SET_OPEN_PREVIEW_DIALOG:
            return {
                ...state,
                isPreviewDialogOpen: payload.isPreviewDialogOpen
            };
        case SurveyImportActions.SET_SURVEY_INFO:
            return {
                ...state,
                surveyInfo: payload.surveyInfo
            };
        case SurveyImportActions.SET_FORM_VALIDATION:
            return {
                ...state,
                isFormValid: payload.isFormValid
            };

        default:
            return {
                ...state
            };
    }
};

import React from "react";

import MuiButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";

const ButtonGroup = (props: ButtonGroupProps) => {
    const { children, ...rest } = props;
    return <MuiButtonGroup {...rest}>{children}</MuiButtonGroup>;
};

export { ButtonGroup };

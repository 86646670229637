import React, { ReactElement } from "react";
import MuiBox, { BoxProps } from "@mui/material/Box";

const Box = (props: BoxProps): ReactElement => {
    return (
        <MuiBox {...props} boxSizing="border-box">
            {props.children}
        </MuiBox>
    );
};

export { Box };

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useUserDashboardStyles = makeStyles(() => ({
    infoHeader: {
        height: 56,
        display: "flex",
        borderBottom: theme.border.main
    },
    totalResponseHeader: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 22,
        borderRight: theme.border.main,
        width: 300
    },
    infoHeaderItemMsg: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 550,
        minWidth: 550,
        overflow: "hidden"
    },
    dataContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%"
    },
    dashboardTitleWrap: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 56,
        borderBottom: theme.border.main
    },
    dashboardTitleFilterChips: {
        marginLeft: 28
    },
    dashboardTitle: {
        display: "flex",
        alignItems: "center",
        height: 56,
        maxHeight: 56,
        paddingLeft: 24
    },
    dashboardTitleItem: {
        width: "100%",
        display: "inline-block",
        whiteSpace: "nowrap"
    },
    dashboardResponseCount: {
        paddingLeft: 24
    },
    pivotGridWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    infoHeaderText: {
        fontWeight: 500
    },
    colorsReferenceContainer: {
        width: 280,
        minWidth: 280,
        maxWidth: 280,
        paddingLeft: 28,
        paddingRight: 9,
        display: "flex",
        borderRight: theme.border.main
    },
    colorsReference: {
        width: 70,
        minWidth: 70,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    colorsReferenceMiddle: {
        width: 90,
        minWidth: 90,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    boxStyle: {
        width: 15,
        height: 15,
        marginRight: theme.spacing(1)
    },
    dashboardItem: {
        position: "absolute",
        top: 218,
        left: 32,
        height: "calc(100% - 250px)",
        width: "calc(100% - 64px)",
        overflow: "hidden"
    },
    actions: {
        top: 190,
        position: "absolute",
        width: "100%"
    }
}));

import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useSurveyImportStyles = makeStyles(() => ({
    formControl: {
        width: "100%"
    },
    optionsSplit: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3)
    },
    textFieldName: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    textFieldShort: {
        width: "50%"
    },
    textFieldMarginLeft: {
        marginLeft: theme.spacing(1)
    },
    loadedFile: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

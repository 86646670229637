import { SelectItem } from "core/interfaces";
import { Translation } from "core/languages/interfaces";
export enum FilterMode {
    snapshot = "Snapshot",
    previousPeriod = "PreviousPeriod",
    heatmap = "heatmap",
    comment = "comment",
    correlation = "correlation",
    responseRate = "responseRate",
    demographicOnly = "demographicOnly",
    insight = "insight"
}

export enum CombinationOperator {
    none = "",
    and = "AND",
    or = "OR"
}

export enum CombinationOperatorBackEndConnector {
    AND,
    OR
}

export enum FilterType {
    none = "",
    contactField = "ContactField",
    surveyItemPickOne = "SurveyItemPickOne",
    surveyItemPickMany = "SurveyItemPickMany",
    date = "Date"
}

export enum FilterSelectionType {
    none = "",
    contactField = "ContactField",
    surveyItem = "SurveyItem",
    date = "Date"
}

export enum FilterTypeBackEndConnector {
    ContactField,
    SurveyItemPickOne,
    SurveyItemPickMany,
    Date
}

export const FilterTypeMap = (lang: Translation): SelectItem[] => [
    { value: FilterSelectionType.contactField, text: lang.contactField },
    { value: FilterSelectionType.surveyItem, text: lang.surveyItem },
    { value: FilterSelectionType.date, text: lang.date }
];

export enum ComparisonOperator {
    none = "",
    equals = "EQUALS",
    notEqualS = "NOTEQUALS",
    greater = "GREATER",
    less = "LESS"
}

export enum ComparisonOperatorBackEndConnector {
    EQUALS,
    NOTEQUALS,
    GREATER,
    LESS
}

export const ComparisonOperatorMap = (lang: Translation, filterType?: FilterType): SelectItem[] => {
    if (filterType === FilterType.surveyItemPickMany || FilterType.surveyItemPickOne) {
        return [
            { value: ComparisonOperator.equals, text: lang.equalTo, symbol: "=" },
            { value: ComparisonOperator.notEqualS, text: lang.notEqualTo, symbol: "≠" }
        ];
    }

    return [
        { value: ComparisonOperator.equals, text: lang.equalTo, symbol: "=" },
        { value: ComparisonOperator.notEqualS, text: lang.notEqualTo, symbol: "≠" },
        { value: ComparisonOperator.greater, text: lang.greaterThan, symbol: ">" },
        { value: ComparisonOperator.less, text: lang.lessThan, symbol: "<" }
    ];
};

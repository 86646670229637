import React, { PropsWithChildren, ReactElement, createContext, useState } from "react";

import { Snackbar } from "lib/snackbar";

type Context = {
    setSnackbarMessage: (message: string, autoHideOff?: boolean) => void;
};

const SnackbarContext = createContext<Context>({
    setSnackbarMessage: () => null
});

const SnackbarContextProvider = (props: PropsWithChildren): ReactElement => {
    const [message, setMessage] = useState("");
    let autoHideOff = false;

    const setSnackbarMessage = (_message: string, _autoHideOff?: boolean): void => {
        if (!_autoHideOff) {
            autoHideOff = false;
        } else {
            autoHideOff = true;
        }
        setMessage(_message);
    };

    const handleSnackbarClose = (): void => {
        setMessage("");
    };

    return (
        <SnackbarContext.Provider
            value={{
                setSnackbarMessage
            }}
        >
            <Snackbar
                open={message.length > 0}
                handleClose={handleSnackbarClose}
                message={message}
                autohideOff={autoHideOff}
            />
            {props.children}
        </SnackbarContext.Provider>
    );
};

export { SnackbarContext, SnackbarContextProvider };

import React, { ReactElement, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import FileCopy from "@mui/icons-material/FileCopy";
import Sync from "@mui/icons-material/Sync";
import Highlighter from "react-highlight-words";
import { RegenerateCommentSummaryPayload } from "api/rest/interfaces/comments-service";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { useLang, useCommentsService } from "core/hooks";
import { useCommentSummaryStyles } from "./comments-summary.style";
import { CommentsSummaryPayload } from "api/rest/interfaces";
import { Filter } from "components/filters/interfaces";
import { CommentSummaryStatus } from "managerPortal/components/comments/interfaces";
import Loading from "components/shared/Loading";
import { CommentState } from "../interface";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import ShowTooSimilarConfidentialityMessage from "../ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "../ShowBelowConfidentialityMessage";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Snackbar } from "lib/snackbar";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Tooltip } from "lib/tooltip";

type Props = {
    surveyId: number;
    sectionOrItemTitle: string;
    currentFilter: Filter;
    searchTerm: string;
    itemIds: number[];
    summaryLoadingStatus: boolean;
    updateSummaryLoadingStatus: (status: boolean) => void;
    confidentialityResult: CommentState["confidentialityResult"];
    commentsNumberPerSection: number;
};

export type CommentSummaryHandle = {
    regenerateSummaryBasedonPromptChanges: () => void;
};

export const CommentsSummary = forwardRef<CommentSummaryHandle, Props>(
    (
        {
            surveyId,
            sectionOrItemTitle,
            currentFilter,
            searchTerm,
            itemIds,
            summaryLoadingStatus,
            updateSummaryLoadingStatus,
            confidentialityResult,
            commentsNumberPerSection
        }: Props,
        ref
    ): ReactElement => {
        const classes = useCommentSummaryStyles();
        const { lang, languageCode } = useLang();
        const commentsService = useCommentsService();
        const [opportunity, setOpportunity] = useState<string>("");
        const [suggestion, setSuggestion] = useState<string>("");
        const [strength, setStrength] = useState<string>("");
        const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
        const [opportunityId, setOpportunityId] = useState<number>(0);
        const [suggestionId, setSuggestionId] = useState<number>(0);
        const [strengthId, setStrengthId] = useState<number>(0);
        const [regenerateSummaryIndicator, setRegenerateSummaryIndicator] = useState<boolean>(false);
        const [refetchSummaryIndicator, setRefetchSummaryIndicator] = useState<boolean>(false);
        const [confirmImportDialogOpen, setConfirmImportDialogOpen] = useState(false);
        const [isCopied, setIsCopied] = useState<boolean>(false);
        const [canRegenerateSummary, setCanRegenerateSummary] = useState<boolean>(false);

        const copyToClipboard = (): void => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
            navigator.clipboard.writeText(
                //                 `${lang.mostCommonStrengths} ${sectionOrItemTitle}:
                // ${strength}
                `${lang.mostCommonOpportunities} ${sectionOrItemTitle}:
${opportunity}


${lang.commonSuggestionFrom} ${sectionOrItemTitle}:
${suggestion}`
            );
        };

        const getStatus = useCallback((): void => {
            let timerId: NodeJS.Timeout;
            const commentsSummaryPayload: CommentsSummaryPayload = {
                surveyId: surveyId,
                hierarchyId: 0,
                cardId: 0,
                filters: filterConnectorToBackEnd(currentFilter.items),
                languageCode,
                itemIds,
                title: ""
            };
            commentsService
                .getCommentSummary(commentsSummaryPayload)
                .then(status => {
                    if (
                        status &&
                        status.suggestionStatus === CommentSummaryStatus.Completed &&
                        status.opportunityStatus === CommentSummaryStatus.Completed &&
                        status.strengthStatus === CommentSummaryStatus.Completed
                    ) {
                        updateSummaryLoadingStatus(false);
                        if (timerId) {
                            clearTimeout(timerId);
                        }
                        setOpportunity(status.opportunities);
                        setSuggestion(status.suggestions);
                        setStrength(status.strengths);
                        setCanRegenerateSummary(status.canRegenerate);
                        setSuggestionId(status.suggestionId);
                        setOpportunityId(status.opportunityId);
                        setStrengthId(status.strengthId);
                    } else {
                        updateSummaryLoadingStatus(true);
                        timerId = setTimeout(() => getStatus(), 3000);
                    }
                })
                .catch(() => {
                    if (timerId) {
                        clearTimeout(timerId);
                    }
                    setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                    updateSummaryLoadingStatus(false);
                });
        }, [
            currentFilter.items,
            surveyId,
            JSON.stringify(itemIds),
            regenerateSummaryIndicator,
            refetchSummaryIndicator
        ]);

        const getHighlitedSummary = (opportunity: string): ReactElement => {
            return (
                <>
                    <Highlighter
                        highlightClassName={classes.highlightWord}
                        searchWords={searchTerm ? [searchTerm] : []}
                        caseSensitive={false}
                        autoEscape={true}
                        textToHighlight={opportunity}
                    />
                </>
            );
        };

        const handleCloseSnackbar = (): void => {
            setSnackbar({ isOpen: false, message: "" });
        };

        const regenerateSummary = (): void => {
            if (opportunityId && suggestionId && strengthId) {
                const payload: RegenerateCommentSummaryPayload = {
                    opportunityId,
                    suggestionId,
                    strengthId,
                    itemIds,
                    filters: filterConnectorToBackEnd(currentFilter.items)
                };
                updateSummaryLoadingStatus(true);
                commentsService
                    .regenerateCommentSummary(payload)
                    .then(() => {
                        setRegenerateSummaryIndicator(!regenerateSummaryIndicator);
                    })
                    .catch(() => setSnackbar({ isOpen: true, message: lang.somethingWentWrong }));
            }
        };

        useImperativeHandle(ref, () => ({
            regenerateSummaryBasedonPromptChanges(): void {
                setRefetchSummaryIndicator(!refetchSummaryIndicator);
            }
        }));

        useEffect(() => {
            if (commentsNumberPerSection > 0 && confidentialityResult === ConfidentialityResult.success) {
                getStatus();
            }
        }, [
            currentFilter.items,
            surveyId,
            JSON.stringify(itemIds),
            regenerateSummaryIndicator,
            refetchSummaryIndicator
        ]);

        if (summaryLoadingStatus) {
            return (
                <Loading styleProps={{ background: "white" }} zIndex={1000} borderLeft>
                    <Typography>{lang.loadingCommentsSummary}</Typography>
                </Loading>
            );
        }

        return (
            <>
                {confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowTooSimilarConfidentialityMessage />
                    </div>
                ) : confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowBelowConfidentialityMessage multiline={true} />
                    </div>
                ) : commentsNumberPerSection === 0 ? (
                    <Typography pt={1} pl={3}>
                        {lang.noSearchResults}
                    </Typography>
                ) : (
                    <div className={classes.summary}>
                        {false && (
                            // hide strength feature will need to bring it back
                            <>
                                <div className={classes.summaryHeader}>
                                    <Typography
                                        pl={"12px"}
                                        pr={"12px"}
                                        variant="h6"
                                        style={{ fontWeight: 500, fontSize: 16 }}
                                    >{`${lang.mostCommonStrengths} ${sectionOrItemTitle}`}</Typography>
                                    <div className={classes.summaryHeaderRight}>
                                        {canRegenerateSummary && (
                                            <div className={classes.summaryHeaderRightDetails}>
                                                <Typography>{lang.newCommentsSubmitted}</Typography>
                                                <Tooltip title={lang.regenerate} placement="bottom">
                                                    <IconButton
                                                        className={classes.settingIcon}
                                                        onClick={() => setConfirmImportDialogOpen(true)}
                                                        id="btn-opportunity-regenerate"
                                                        color="primary"
                                                    >
                                                        <Sync style={{ fontSize: "1.8rem" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )}
                                        <Tooltip
                                            title={isCopied ? lang.copied : lang.copyToClipboradCaseSensitive}
                                            placement="bottom"
                                        >
                                            <IconButton
                                                className={classes.settingIcon}
                                                onClick={copyToClipboard}
                                                id="btn-opportunity-copy-to-clipBoard"
                                                color="primary"
                                            >
                                                <FileCopy />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Typography
                                    pl={"24px"}
                                    pr={"24px"}
                                    pt={"8px"}
                                    pb={"8px"}
                                    style={{ whiteSpace: "pre-wrap" }}
                                >
                                    {getHighlitedSummary(strength)}
                                </Typography>
                            </>
                        )}
                        <div className={classes.summaryHeader}>
                            <Typography
                                pl={"12px"}
                                pr={"12px"}
                                variant="h6"
                                style={{ fontWeight: 500, fontSize: 16 }}
                            >{`${lang.mostCommonOpportunities} ${sectionOrItemTitle}`}</Typography>
                            <div className={classes.summaryHeaderRight}>
                                {canRegenerateSummary && (
                                    <div className={classes.summaryHeaderRightDetails}>
                                        <Typography>{lang.newCommentsSubmitted}</Typography>
                                        <Tooltip title={lang.regenerate} placement="bottom">
                                            <IconButton
                                                className={classes.settingIcon}
                                                onClick={() => setConfirmImportDialogOpen(true)}
                                                id="btn-opportunity-regenerate"
                                                color="primary"
                                            >
                                                <Sync style={{ fontSize: "1.8rem" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                                <Tooltip
                                    title={isCopied ? lang.copied : lang.copyToClipboradCaseSensitive}
                                    placement="bottom"
                                >
                                    <IconButton
                                        className={classes.settingIcon}
                                        onClick={copyToClipboard}
                                        id="btn-opportunity-copy-to-clipBoard"
                                        color="primary"
                                    >
                                        <FileCopy />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <Typography pl={"24px"} pr={"24px"} pt={"8px"} pb={"8px"} style={{ whiteSpace: "pre-wrap" }}>
                            {getHighlitedSummary(opportunity)}
                        </Typography>
                        <div className={classes.suggestionHeader}>
                            <Typography
                                pl={"12px"}
                                pr={"12px"}
                                variant="h6"
                                style={{ fontWeight: 500, fontSize: 16 }}
                            >{`${lang.commonSuggestionFrom} ${sectionOrItemTitle}`}</Typography>
                        </div>
                        <Typography pl={"24px"} pr={"24px"} pt={"8px"} pb={"8px"} style={{ whiteSpace: "pre-wrap" }}>
                            {getHighlitedSummary(suggestion)}
                        </Typography>
                    </div>
                )}
                <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
                {confirmImportDialogOpen && (
                    <ConfirmationDialog
                        onCancelClicked={(): void => {
                            setConfirmImportDialogOpen(false);
                        }}
                        open={confirmImportDialogOpen}
                        onConfirmationClicked={(): void => {
                            setConfirmImportDialogOpen(false);
                            regenerateSummary();
                        }}
                        title={lang.confirmAction}
                        message={lang.confirmRegenerateCommentSummary[0]}
                        submessage={lang.confirmRegenerateCommentSummary[1]}
                        confirmButtonText={lang.ok}
                        cancelButtonVariant="text"
                    />
                )}
            </>
        );
    }
);

CommentsSummary.displayName = "CommentsSummary";

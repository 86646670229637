/**
|--------------------------------------------------
| Colors as defined in the "Style Guide.pdf"
|--------------------------------------------------
*/

//Blue
export const primaryBlue = "#006394";
export const primaryBlueLight = "#4D90C4";
export const primaryBlueDark = "#003965";

//Orange
export const primaryOrange = "#E87424";
export const primaryOrangeLight = "#FFA454";
export const primaryOrangeDark = "#AF4600";

//Semantic
export const infoColor = "#0094C8";
export const successColor = "#46B978";
export const warningColor = "#FFC107";
export const errorColor = "#DC3545";

//table
export const tableHeaderLight = "rgba(0, 0, 0, 0.04)";
export const tableHeaderWhite = "#ffffff";

// favorable - unfavorable
export const unfavorableColor = "#F48C3C";
export const neutralColor = "#D6D6D6";
export const favorableColor = "#2EACE2";
export const ignoredColor = "#F0F0F0";
export const neutralPercentageColor = "#9e9e9e";

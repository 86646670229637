import React, { ReactElement } from "react";
import { PanelItem, DashboardData } from "./interface";
import CellTypeImage from "./CellTypeImage";
import CellSingleDimensionOrItem from "./CellSingleDimensionOrItem";
import CellKeyDriver from "./CellKeyDriver";
import CellOverallDimension from "./CellOverallDimension";
import CellSingleDimensionWithItem from "./CellSingleDimensionWithItem";
import CellLookdown from "./CellLookdown";

export const itemWidth = 150;
export const itemHeight = 110;
interface Props {
    key: number;
    testid: number;
    item: PanelItem;
    numberOfColumn: number;
    numberOfRow: number;
    dashboardData: DashboardData;
    responseCount: number;
}

const DashboardItem = (props: Props): ReactElement => {
    const { item, dashboardData, testid, responseCount } = props;

    const RenderDashboardItem = (panelItem: PanelItem): ReactElement => {
        switch (panelItem.type) {
            case 0:
                return <CellTypeImage item={item} testid={testid} />;
            case 1:
                return <CellSingleDimensionOrItem item={item} testid={testid} dashboardData={dashboardData} />;
            case 2:
                return <CellOverallDimension item={item} testid={testid} dashboardData={dashboardData} />;
            case 3:
                return (
                    <CellKeyDriver
                        item={item}
                        testid={testid}
                        dashboardData={dashboardData}
                        responseCount={responseCount}
                    />
                );
            case 4:
                return <CellLookdown item={item} testid={testid} dashboardData={dashboardData} />;
            case 5:
                return <CellSingleDimensionWithItem item={item} testid={testid} dashboardData={dashboardData} />;
            default:
                return <></>;
        }
    };

    return <React.Fragment>{item ? RenderDashboardItem(item) : <></>}</React.Fragment>;
};

export default DashboardItem;

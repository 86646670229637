import React from "react";
import { styled } from "@mui/material/styles";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

import { backgroundLightGrey, whiteBackground } from "../palette";

type Background = "white" | "default";

type Props = TextFieldProps & {
    background?: Background;
};

const useStyles = makeStyles(() => ({
    input: {
        backgroundColor: whiteBackground
    }
}));

const StyledTextField = styled((props: Props) => <MuiTextField {...props} />)(props => ({
    "& .MuiFilledInput-root": {
        backgroundColor: props.background === "white" ? whiteBackground : backgroundLightGrey,
        fontSize: "12px"
    },
    "& .MuiFormLabel-root": {
        fontSize: "12px"
    },
    "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-20px"
    },
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: backgroundLightGrey
    }
}));

const TextField = (props: Props) => {
    const classes = useStyles();
    return (
        <StyledTextField
            variant="filled"
            fullWidth
            inputProps={{
                classes: {
                    input: classes.input
                }
            }}
            {...props}
        />
    );
};

export { TextField };
export type { Props as TextFieldProps };
